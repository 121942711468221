import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WorkOutlineIcon from "@mui/icons-material/AccountBoxOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import TypeIcon from "@mui/icons-material/CategoryOutlined";
import LevelIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import CountryIcon from "@mui/icons-material/MapOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SortIcon from "@/components/icons/SortIcon";

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        {
            id: "name",
            label: "Name",
            icon: <PersonOutlinedIcon className="!h-5 !w-5" />,
        },
        {
            id: "companyName",
            label: "Company",
            icon: <BusinessIcon className="!h-5 !w-5" />,
        },
        {
            id: "designation",
            label: "Designation",
            icon: <WorkOutlineIcon className="!h-5 !w-5" />,
        },
        {
            id: "level",
            label: "Level",
            icon: <LevelIcon className="!h-5 !w-5" />,
        },
        {
            id: "action",
            label: "Actions",
            icon: <MoreHorizIcon className="!h-5 !w-5 rotate-90" />,
        },
        {
            id: "country",
            label: "Country",
            icon: <CountryIcon className="!h-5 !w-5" />,
        },
        {
            id: "industry",
            label: "Industry",
            icon: <TypeIcon className="!h-5 !w-5" />,
        },
    ];

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    fontFamily: "Inter",
                    fontSize: 14,
                    color: "#6D6D6D",
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
                position: "sticky",
                top: 0,
                zIndex: 4,
                backgroundColor: "#F4F3F0",
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                        position: "sticky",
                        left: 0,
                        zIndex: 4,
                        bgcolor: "#F4F3F0",
                    },
                    "& .MuiTableCell-root:nth-of-type(2)": {
                        position: "sticky",
                        left: "36px",
                        zIndex: 4,
                        bgcolor: "#F4F3F0",
                        "&::after": {
                            content: '""',
                            position: "absolute",
                            right: -8,
                            top: 0,
                            bottom: 0,
                            width: 8,
                            background: "linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)",
                            opacity: 0,
                            transition: "opacity 0.3s",
                            pointerEvents: "none",
                        },
                        ".scrolled &::after": {
                            opacity: 1,
                        },
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        padding: "8px 12px",
                        borderBottom: `1px solid ${kompassColors.light}`,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                <TableCell padding="checkbox" sx={{ width: "36px", minWidth: "36px", maxWidth: "36px" }}>
                    <Checkbox
                        checked={props.totalRowsOnPage > 0 && props.numSelectedOnPage === props.totalRowsOnPage}
                        onChange={props.onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all contacts",
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: "#2D7A89",
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: "#2D7A89",
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                            marginLeft: -1.5,
                        }}
                    />
                </TableCell>
                {props.headCells?.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id === "action" ? "center" : "left"}
                        padding="none"
                        sortDirection={headCell.id !== "action" ? (orderBy === headCell.id ? order : false) : false}
                    >
                        {headCell.id === "action" ? (
                            <div className="flex items-center gap-2">
                                {headCells[index].icon}
                                <span className="font-medium text-sm">{headCell.label}</span>
                            </div>
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    color: "#6D6D6D",
                                    width: "100%",
                                    "& .MuiTableSortLabel-icon": {
                                        opacity: 1,
                                    },
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {headCells[index].icon}
                                    <span className="font-medium text-sm">{headCell.label}</span>
                                </div>
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
