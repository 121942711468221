import { useMemo, useEffect, useState } from "react";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { Slider, styled, Box } from "@mui/material";
import { toast } from "react-toastify";
import { throttle, debounce } from "lodash";
import useBillingStore from "@/store/useBillingStore";

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#e8e7e7 ",
    "& .MuiSlider-thumb": {
        backgroundColor: "#2D7A89",
        border: "5px solid white",
        width: "24px",
        height: "24px",
        boxShadow: "0 4px 8px -2px rgba(16, 24, 40, 0.1)",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "0 4px 8px 4px rgba(16, 24, 40, 0.1)",
        },
    },
    ".MuiSlider-rail": {
        backgroundColor: "#E8E7E7",
        height: "0.5rem",
        border: "none",
    },
    ".MuiSlider-mark": {
        display: "none",
    },
    ".MuiSlider-track": {
        backgroundColor: "#2D7A89",
        height: "0.5rem",
        border: "none",
    },
}));

// Add new styled component for tooltip
const ValueTooltip = styled(Box)(({ theme }) => ({
    position: "absolute",
    backgroundColor: "#2D7A89",
    color: "white",
    padding: "8px 12px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 500,
    transform: "translateY(-150%)",
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: "-8px",
        left: "50%",
        transform: "translateX(-50%)",
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderTop: "8px solid #2D7A89",
        width: 0,
        height: 0,
    },
}));

export function UserSlider() {
    const { changeUsers, users } = useBillingStore();
    const [userSliderValue, setUserSliderValue] = useState(users || 1);
    const [toastId, setToastId] = useState(0);
    const [tooltipPosition, setTooltipPosition] = useState(0);

    const dashboardInfoFunction = useHTTPRequest(`/analytics/getLandingPageInfo`);
    const { data: dashboardInfo } = useQuery({
        queryKey: ["Dashboard"],
        queryFn: () => dashboardInfoFunction(),
    });

    const throttleToast = useMemo(() => {
        return throttle(() => {
            toast.dismiss(toastId);
            setToastId(
                toast.error(
                    `Your team currently has ${dashboardInfo?.data?.teamsInfo?.used} members, please deactivate additional team members to downgrade.`
                )
            );
        }, 2000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardInfo]);

    useEffect(() => {
        const totalUsers = dashboardInfo?.data?.teamsInfo?.total;

        if (totalUsers) {
            changeUsers(totalUsers);
            setUserSliderValue(totalUsers);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardInfo]);

    const options = new Array(100).fill(0).map((_, index) => {
        return { user: index + 1 };
    });

    const formatTicks = useMemo(() => {
        const mappedValues = options.map((option) => {
            return option.user % 10 === 0 || option.user === 1 ? { value: option.user } : { value: "" };
        });
        return mappedValues;
    }, [options]);

    const formatValues = useMemo(() => {
        const mappedValues = options.map((option) => {
            return { value: option.user };
        });
        return mappedValues;
    }, [options]);

    const debounceSetSliderValue = useMemo(() => {
        return debounce((sliderValue) => changeUsers(sliderValue), 1500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Add function to update tooltip position
    const updateTooltipPosition = (value) => {
        const sliderWidth = document.querySelector(".MuiSlider-root")?.getBoundingClientRect().width || 0;
        const percentage = (value - 1) / (options[options.length - 1].user - 1);
        const position = percentage * sliderWidth;
        setTooltipPosition(position);
    };

    // Update tooltip position when value changes
    useEffect(() => {
        updateTooltipPosition(userSliderValue);
    }, [userSliderValue]);

    const handleUserSliderValueChange = (value) => {
        if (value < dashboardInfo?.data?.teamsInfo?.used) {
            throttleToast();
        } else {
            setUserSliderValue(value);
            updateTooltipPosition(value);
        }
    };

    useEffect(() => {
        debounceSetSliderValue(userSliderValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSliderValue]);

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex h-full w-full scroll-m-[64px] flex-col"
            >
                <div className="text-stone-950 font-Inter flex h-full w-full flex-col py-7">
                    <div className="mb-7 flex flex-col justify-between gap-4">
                        <div className="text-base font-semibold">Users</div>

                        <div className="text-sm font-medium text-[#5D5D5D]">
                            Select how many seats for users you want to include in your subscription
                        </div>
                    </div>
                    <div className="mt-10 flex flex-col w-full lg:w-[90%]">
                        <div className="relative">
                            <ValueTooltip
                                sx={{
                                    left: `${tooltipPosition}px`,
                                    transform: "translate(-50%, -150%)",
                                }}
                            >
                                {userSliderValue} users
                            </ValueTooltip>
                            <StyledSlider
                                value={userSliderValue}
                                step={null}
                                min={1}
                                max={options[options.length - 1].user}
                                marks={formatValues}
                                onChange={(event, value) => {
                                    handleUserSliderValueChange(value);
                                }}
                            />
                        </div>
                        <Box
                            sx={{
                                display: "flex",
                                position: "relative",
                                width: "100%",
                                marginBottom: "1.53rem",
                            }}
                        >
                            {formatTicks.map((value, index) => {
                                return (
                                    <span
                                        className="absolute top-0 hidden cursor-pointer select-none md:block"
                                        onClick={() => handleUserSliderValueChange(value.value)}
                                        style={{
                                            left: `${(index / (formatTicks.length - 1)) * 100}%`,
                                            transform: `translateX(${!index ? "0%" : "-50%"})`,
                                            display: !index || index === formatTicks.length - 1 ? "block" : "",
                                        }}
                                    >
                                        <span className="flex items-end gap-[2px] whitespace-nowrap font-medium">
                                            <span className="text-[10px] lg:text-[12px] text-[#888888]">{value.value}</span>
                                        </span>
                                    </span>
                                );
                            })}
                        </Box>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
}
