import useAuth from "@/hooks/useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";

const useTasks = () => {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const getTasks = async () => {
        return fetchWithAuth("/api/v1/tasks/getAll", "GET");
    };

    // get tasks by opportunity id
    const getTasksByOpportunityId = async (id) => {
        return fetchWithAuth(`/api/v1/tasks/opportunity/${id}`, "GET");
    };

    // create opportunity
    const createTask = async (task) => {
        try {
            const response = await fetchWithAuth("/api/v1/tasks/", "POST", task);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // update opportunity
    const updateTask = async (id, task) => {
        try {
            const response = await fetchWithAuth(`/api/v1/tasks/${id}`, "PUT", task);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const deleteTask = async (id) => {
        try {
            const response = await fetchWithAuth(`/api/v1/tasks/${id}`, "DELETE");
            return response;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to delete task");
        }
    };

    // mark as complete
    const markAsComplete = async (id) => {
        try {
            const response = await fetchWithAuth(`/api/v1/tasks/complete/${id}`, "PATCH", { isCompleted: true });
            return response;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to mark task as complete");
        }
    };

    // upload file to a task
    const uploadFile = async (id, file, type) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            if (type == "opportunity") {
                formData.append("opportunityId", id);
            } else if (type == "task") {
                formData.append("taskId", id);
            } else {
                formData.append("noteId", id);
            }

            const response = await fetchWithAuth(`/api/v1/files/upload`, "POST", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    return {
        getTasks,
        getTasksByOpportunityId,
        markAsComplete,
        createTask,
        updateTask,
        deleteTask,
        uploadFile,
    };
};

export default useTasks;
