import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, TableRow, Tooltip } from "@mui/material";
import { kompassColors } from "@/theme/palette";
import { ReactComponent as SuccessIcon } from "@/assets/image/bulk/success.svg";
import { getFormattedDateFromTimestamp } from "@/utils/common";
import { ReactComponent as UploadIcon } from "@/assets/image/bulk/upload.svg";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

function BulkResultRow(props) {
    const {
        row,
        isItemSelected,
        labelId,
        handleClick,
        handleDeleteCsvEnrich,
        handleDeleteValidation,
        handleDownloadCsvEnrich,
        handleDownloadValidation,
    } = props;

    return (
        <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row._id}
            selected={false}
            sx={{
                cursor: "pointer",
                background: "white",
                "&:hover": {
                    background: "#f4f3f0",
                },
            }}
        >
            <TableCell
                padding="none"
                sx={{
                    "&:not(:last-child)": {
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                    width: "36px",
                    minWidth: "36px",
                    maxWidth: "36px",
                }}
            >
                <div className="flex justify-center">
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: "#2D7A89",
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        onClick={(event) => handleClick(event, row._id)}
                    />
                </div>
            </TableCell>
            <TableCell
                sx={{
                    width: "300px",
                    maxWidth: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
            >
                <Tooltip title={row?.contactTitle || row?.fileName}>
                    <span
                        style={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "100%", // Ensure it respects the parent cell width
                        }}
                    >
                        {row?.contactTitle || row?.fileName}
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell
                sx={{
                    width: "200px",
                }}
            >
                {row?.numberOfContacts || row?.emailsLength} contacts
            </TableCell>
            <TableCell
                sx={{
                    width: "220px",
                }}
            >
                {getFormattedDateFromTimestamp(row?.createdAt)}
            </TableCell>
            <TableCell>
                <div className="flex items-center gap-2">
                    <Tooltip title="Phone Enrichment">
                        <IconButton>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_1348_9052" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1348_9052)">
                                    <path
                                        d="M15.8931 16.9792C14.2126 16.8542 12.6188 16.441 11.1119 15.7396C9.60494 15.0382 8.25425 14.0903 7.0598 12.8958C5.86536 11.7014 4.92439 10.3542 4.23689 8.85417C3.54939 7.35417 3.13619 5.76389 2.9973 4.08333C2.96953 3.79167 3.05487 3.53819 3.25334 3.32292C3.45168 3.10764 3.69966 3 3.9973 3H6.83064C7.06675 3 7.27161 3.07292 7.44522 3.21875C7.61883 3.36458 7.73341 3.54861 7.78897 3.77083L8.28897 5.97917C8.31675 6.15972 8.30633 6.33333 8.25772 6.5C8.20911 6.66667 8.12925 6.80556 8.01814 6.91667L5.9973 8.95833C6.27508 9.48611 6.59453 9.99306 6.95564 10.4792C7.31675 10.9653 7.71932 11.4235 8.16334 11.8538C8.58043 12.2707 9.02508 12.6562 9.4973 13.0104C9.96953 13.3646 10.4695 13.6806 10.9973 13.9583L13.0598 11.9583C13.1709 11.8472 13.3098 11.7674 13.4765 11.7188C13.6431 11.6701 13.8167 11.6597 13.9973 11.6875L16.2265 12.1667C16.4626 12.2361 16.6501 12.3576 16.789 12.5312C16.9279 12.7049 16.9973 12.9097 16.9973 13.1458V15.9792C16.9973 16.2768 16.8862 16.5248 16.664 16.7231C16.4417 16.9216 16.1848 17.0069 15.8931 16.9792ZM5.3098 7.5L6.76814 6.04167L6.41064 4.5H4.53897C4.60841 5.02778 4.70564 5.53819 4.83064 6.03125C4.95564 6.52431 5.11536 7.01389 5.3098 7.5ZM12.4765 14.6667C12.964 14.8644 13.4608 15.0215 13.9671 15.1379C14.4733 15.2543 14.9834 15.3472 15.4973 15.4167V13.5417L13.9348 13.2083L12.4765 14.6667Z"
                                        fill={["phone", "all"].includes(row?.enrichType) ? "#050505" : "#b0b0b0"}
                                    />
                                </g>
                            </svg>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Email Enrichment">
                        <IconButton>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_1348_9065" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1348_9065)">
                                    <path
                                        d="M3.5 16C3.0875 16 2.73438 15.8531 2.44063 15.5592C2.14688 15.2653 2 14.9119 2 14.4992V5.49417C2 5.08139 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14694 17.5594 4.44083C17.8531 4.73472 18 5.08806 18 5.50083V14.5058C18 14.9186 17.8531 15.2708 17.5594 15.5625C17.2656 15.8542 16.9125 16 16.5 16H3.5ZM10 11L3.5 7.27083V14.5H16.5V7.27083L10 11ZM10 9.22917L16.5 5.5H3.5L10 9.22917ZM3.5 7.27083V5.5V14.5V7.27083Z"
                                        fill={["email", "all"].includes(row?.enrichType) ? "#050505" : "#b0b0b0"}
                                    />
                                </g>
                            </svg>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Email Validation">
                        <IconButton>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.2969 18.3359L9.75521 14.7943L10.9219 13.6276L13.2969 16.0026L18.0052 11.2943L19.1719 12.4609L13.2969 18.3359ZM10.0052 9.16927L16.6719 5.0026H3.33854L10.0052 9.16927ZM10.0052 10.8359L3.33854 6.66927V15.0026H7.63021L9.29688 16.6693H3.33854C2.88021 16.6693 2.48785 16.5061 2.16146 16.1797C1.83507 15.8533 1.67188 15.4609 1.67188 15.0026V5.0026C1.67188 4.54427 1.83507 4.15191 2.16146 3.82552C2.48785 3.49913 2.88021 3.33594 3.33854 3.33594H16.6719C17.1302 3.33594 17.5226 3.49913 17.849 3.82552C18.1753 4.15191 18.3385 4.54427 18.3385 5.0026V8.6276L16.6719 10.2943V6.66927L10.0052 10.8359Z"
                                    fill={["emailValidation"].includes(row?.enrichType) ? "#050505" : "#b0b0b0"}
                                />
                            </svg>
                        </IconButton>
                    </Tooltip>
                </div>
            </TableCell>
            <TableCell>{row?.creditsConsumed}</TableCell>
            <TableCell>
                <div className="flex flex-row items-center justify-start">
                    {row?.completed && (
                        <IconButton onClick={() => {}} disabled>
                            <SuccessIcon />
                        </IconButton>
                    )}
                    {!row?.completed && !row?.error && (
                        <IconButton disabled>
                            <CircularProgress size="1.2rem" className="mr-2" />
                        </IconButton>
                    )}
                    <p>{row?.error ? `Failed • ${row?.message}` : row?.completed ? "Completed" : `Processing ${row?.progress} %`}</p>
                </div>
            </TableCell>
            <TableCell>
                <div className="flex items-center gap-4">
                    <button
                        onClick={() => {
                            if (row?.enrichType !== "emailValidation") handleDownloadCsvEnrich(row);
                            else handleDownloadValidation(row);
                        }}
                        className={
                            "main-white-button" +
                            (!row?.completed || row?.error
                                ? " !border-stone-500 !text-stone-350 !cursor-not-allowed !pointer-events-none"
                                : " !border-stone-500 !text-stone-950")
                        }
                        disabled={!row?.completed || row?.error}
                    >
                        <UploadIcon
                            className={
                                "h-5 w-5 rotate-180 transform" + (!row?.completed || row?.error ? " fill-stone-350" : " fill-stone-950")
                            }
                        />
                        Download
                    </button>
                    <button
                        onClick={() => {
                            if (row?.enrichType === "emailValidation") {
                                handleDeleteValidation(row.fileId);
                            } else {
                                handleDeleteCsvEnrich(row._id);
                            }
                        }}
                        className={
                            "main-white-button" +
                            (!row?.completed && !row?.error
                                ? " !border-stone-500 !text-stone-350 !cursor-not-allowed !pointer-events-none"
                                : " !border-stone-500 !text-stone-950")
                        }
                        disabled={!row?.completed && !row?.error}
                    >
                        <DeleteIcon
                            sx={{ height: "20px", width: "20px" }}
                            className={!row?.completed && !row?.error ? " fill-stone-350" : " fill-stone-950"}
                        />
                        Delete
                    </button>
                </div>
            </TableCell>
        </TableRow>
    );
}

export default BulkResultRow;
