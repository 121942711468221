import BaseContainer from "@/components/BaseContainer";
import AddNewPopup from "@/components/crm/crm-tasks/AddNewPopup";
import TaskCard from "@/components/crm/crm-tasks/TaskCard";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import React, { useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "@/assets/image/crm/opportunities/addIcon.svg";
import { ReactComponent as FilterIcon } from "@/assets/image/crm/opportunities/filterIcon.svg";
import { ReactComponent as OptionsIcon } from "@/assets/image/crm/opportunities/optionsIcon.svg";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BlockIcon from "@mui/icons-material/Block";
import ChecklistIcon from "@mui/icons-material/Checklist";
import DoneIcon from "@mui/icons-material/Done";
import useTasks from "@/hooks/crm/useTasks";
import MainLoader from "@/components/MainLoader";
import { CircularProgress } from "@mui/material";

export default function TasksScene() {
    const { getTasks } = useTasks();
    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshUI, setRefreshUI] = useState(false);

    useEffect(() => {
        const fetchTasks = async () => {
            setLoading(true);
            try {
                const response = await getTasks();
                if (response) {
                    setTaskData(response.data);
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        fetchTasks();
    }, [refreshUI]);

    return (
        <>
            <div>
                <div>
                    <div className="flex justify-between items-center gap-2">
                        <div className="">
                            <AddNewPopup setRefreshUI={setRefreshUI} />
                        </div>
                        {/* <button className="py-1 px-3 ml-auto text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100">
                            <FilterIcon />
                            Filter
                        </button>
                        <button className="py-1 px-3 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100">
                            <OptionsIcon />
                            Options
                        </button> */}
                    </div>
                    <div className="border text-sm border-stone-250 rounded-[8px] my-5">
                        <div className=" bg-primary-header rounded-[8px] border-b border-stone-250 rounded-b-none flex ">
                            <div className="text-dgray-600 flex items-center gap-2 font-medium border-r border-stone-250 w-1/4 p-3">
                                <FormatListBulletedIcon className="!w-4" />
                                To Do
                            </div>
                            <div className="text-dgray-600 flex items-center gap-2 font-medium border-r border-stone-250 w-1/4 p-3">
                                <ChecklistIcon className="!w-4" />
                                In Progress
                            </div>
                            <div className="text-dgray-600 flex items-center gap-2 font-medium border-r border-stone-250 w-1/4 p-3">
                                <DoneIcon className="!w-4" />
                                Done
                            </div>
                            <div className="text-dgray-600 flex items-center gap-2 font-medium border-r border-stone-250 w-1/4 p-3">
                                <BlockIcon className="!w-4" />
                                No Value
                            </div>
                        </div>
                        {loading ? (
                            <div className="text-black h-64 flex justify-center items-center">
                                <CircularProgress color="inherit" />
                            </div>
                        ) : taskData.length > 0 ? (
                            <div className="flex ">
                                <div className="border-r space-y-4 border-stone-250 w-1/4 py-5 px-3">
                                    {taskData.map(
                                        (task) =>
                                            task.status === "To Do" && <TaskCard setRefreshUI={setRefreshUI} key={task._id} task={task} />
                                    )}
                                </div>
                                <div className="border-r space-y-4 border-stone-250 w-1/4 py-5 px-3">
                                    {taskData.map(
                                        (task) =>
                                            task.status === "In Progress" && (
                                                <TaskCard setRefreshUI={setRefreshUI} key={task._id} task={task} />
                                            )
                                    )}
                                </div>
                                <div className="border-r space-y-4 border-stone-250 w-1/4 py-5 px-3">
                                    {taskData.map(
                                        (task) =>
                                            task.status === "Done" && <TaskCard setRefreshUI={setRefreshUI} key={task._id} task={task} />
                                    )}
                                </div>
                                <div className="border-r space-y-4 border-stone-250 w-1/4 py-5 px-3">
                                    {taskData.map(
                                        (task) => !task.status && <TaskCard setRefreshUI={setRefreshUI} key={task._id} task={task} />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="text-black h-64 flex justify-center items-center">No tasks found</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
