import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useList from "@/hooks/useList";
import CreateNewListDialog from "@/components/lists/CreateNewListDialog";
import ContactListTable from "./ContactListTable";
import { CONTACT_LIST_TYPE } from "@/utils/constants";
import MainLoader from "../MainLoader";

export default function MainList({ onContactSelected }) {
    const { getContactList } = useList();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isVisibleNewList, handleVisibleNewList] = useState(false);
    const [tableData, setTableData] = useState([]);

    const reload = async () => {
        setLoading(true);

        let response = await getContactList({
                listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
            }),
            newTableData = [];
        if (response.status) {
            newTableData = [...response.data];
            setTableData(newTableData);
        } else {
            toast.error(response.message, { theme: "colored" });
        }

        setLoading(false);
    };

    useEffect(() => {
        if (loaded) return;
        reload();
        setLoaded(true);
    }, [loaded]);

    return (
        <>
            {loading && <MainLoader />}
            <ContactListTable
                setLoading={setLoading}
                tableData={tableData}
                onContactSelected={onContactSelected}
                onCreateNewList={() => handleVisibleNewList(true)}
                reload={reload}
            />

            <CreateNewListDialog
                open={isVisibleNewList}
                close={(bUpdate = false) => {
                    handleVisibleNewList(false);
                    if (bUpdate === true) reload();
                }}
            />
        </>
    );
}
