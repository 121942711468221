import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import SortIcon from "@/components/icons/SortIcon";

export default function TranscriptsTableHeader({ order, orderBy, onRequestSort, headCells }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "5px 12px",
                    fontFamily: "Inter",
                    fontSize: 14,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                        borderBottom: `1px solid ${kompassColors.light}`,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="none" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={SortIcon}
                            sx={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-between", color: "#6D6D6D" }}
                        >
                            <div className="flex items-center gap-2">
                                {headCell.icon}
                                {headCell.label}
                            </div>
                            {orderBy === headCell.id && (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TranscriptsTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};
