import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState, useContext } from "react";
import { LayoutContext } from "@/context/LayoutContext";

const tabData = [
    { label: "Analytics", value: 0 },
    { label: "Website Visitors", value: 1 },
    { label: "Profile Exports", value: 2 },
    { label: "Target Customer Persona", value: 3 },
    { label: "Account Details", value: 4 },
];

function NavigationTabs({ setTabOpen, tabOpen }) {
    const [activeTab, setActiveTab] = useState(0);

    const { open } = useContext(LayoutContext);

    return (
        // <nav className="font-Inter flex flex-col gap-4 px-[24px] items-start md:flex-row md:items-center md:gap-6 text-[16px] xl:text-[18px] text-black border-b border-[#E7E7E7]">
        //     {tabData.map((tab, index) => (
        //         <button
        //             key={index}
        //             className={`self-stretch pt-[16px] pb-[21px] border-b-[3px] border-white ${tabOpen === tab.label ? "!border-accents-blue2 !text-accents-blue2" : ""}`}
        //             onClick={() => {
        //                 setTabOpen(tab.label);
        //                 tabData.forEach(function (t) {
        //                     if (t.label === tab.label) {
        //                         t.isActive = true;
        //                     } else {
        //                         t.isActive = false;
        //                     }
        //                 });
        //             }}
        //         >
        //             {tab.label}
        //         </button>
        //     ))}
        // </nav>
        <Box sx={{ width: "100%" }}>
            {/* {loading && <BackdropLoader clsName="!z-[9999]" active={true}></BackdropLoader>} */}
            <Box
                sx={{
                    borderBottom: "1px solid #E7E7E7",
                    width: "100%", // changed from 100vw
                    fontFamily: "Inter",

                    paddingLeft: !open ? "1.8%" : "2%",
                }}
            >
                <Tabs
                    value={tabData.findIndex((tab) => tab.label === tabOpen)}
                    variant="scrollable"
                    scrollButtons="auto"
                    disableRipple
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#050505",
                            height: "3px",
                        },
                        "& .MuiTabs-flexContainer": {
                            alignItems: "center",
                        },
                    }}
                >
                    {tabData.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            onClick={() => {
                                setTabOpen(tab.label);
                                setActiveTab(tab.value);
                                tabData.forEach(function (t) {
                                    if (t.label === tab.label) {
                                        t.isActive = true;
                                    } else {
                                        t.isActive = false;
                                    }
                                });
                            }}
                            disableRipple
                            sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                margin: "0 8px 0 0",
                                padding: "0",
                                color: tabData.findIndex((tab) => tab.label === tabOpen) === index ? "#050505" : "#6D6D6D",
                                minWidth: "auto",
                                "&.Mui-selected": {
                                    color: "#050505",
                                },
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
        </Box>
    );
}

export default NavigationTabs;
