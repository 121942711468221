import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import OpportunityTableRow from "./OpportunityTableRow";
import OpportunityTableHeader from "./StageTableHeader";
import { sortTableData } from "../../utils";
import useOpportunities from "@/hooks/crm/useOpportunities";
import { CircularProgress, TableCell, TableRow } from "@mui/material";

// Function to transform the data for table display
function transformDataForTable(data) {
    const requiredColumns = ["Prospecting", "Sales Qualified", "Proposal", "Negotiation", "Onboarding", "Closed Won", "Closed Lost"];

    // Ensure all required columns exist in data
    requiredColumns.forEach((col) => {
        if (!data[col]) {
            data[col] = [];
        }
    });

    const columns = Object.keys(data); // Extract column names
    const maxLength = Math.max(...columns.map((col) => data[col].length)); // Find the max rows among columns

    // Initialize an array to hold the table rows
    const tableData = Array.from({ length: maxLength }, (_, rowIndex) => {
        const row = columns.reduce((row, col) => {
            // Add column entry if it exists; otherwise, leave it empty
            row[col] = data[col][rowIndex] || { _id: "", name: "" };
            return row;
        }, {});

        // Generate a unique _id for each row
        row._id = rowIndex + 1;

        return row;
    });

    // extract only rows which include company object
    return tableData.filter((item) => {
        return Object.values(item).some((value) => value?.company);
    });
}

function getNonEmptyIds(data, selected) {
    console.log(selected, "sele");
    const result = [];
    // Iterate through the data array
    data.forEach((item) => {
        if (selected.includes(item._id)) {
            // Iterate through the object keys (statuses)
            for (const status in item) {
                if (status !== "_id" && item[status]._id) {
                    result.push(item[status]._id);
                }
            }
        }
    });
    console.log(result, "result");
    return result;
}

export default function StageTable({ setSelectedIds, refreshUI }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("title");
    const { getStageData } = useOpportunities();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedData = sortTableData(data, property, isAsc);
        setData(sortedData);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // const visibleRows = data.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    // fetch companies
    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await getStageData();
                if (res.data) {
                    setData(transformDataForTable(res.data[0]));
                    setSelected([]);
                }
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
            setLoading(false);
        };

        fetchData();
    }, [refreshUI]);

    React.useEffect(() => {
        // selected is a custom rowId as 1,2,3, extracting the actual _id from data
        const selectedIds = getNonEmptyIds(data, selected);
        console.log(selectedIds, "selectedIds");
        setSelectedIds(selectedIds);
    }, [selected]);
    console.log(data, "data");
    return (
        <>
            <div className="border mt-5 rounded-[8px] border-primary-header">
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table
                        sx={{
                            minWidth: {
                                xs: 300,
                                lg: 750,
                            },
                            borderCollapse: "separate",
                        }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <OpportunityTableHeader
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={data.length}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    padding: "5px 12px",
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    border: "1px solid #E8E7E7",
                                },
                            }}
                        >
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8} style={{ textAlign: "center", height: "200px" }}>
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : data.length > 0 ? (
                                data.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <OpportunityTableRow
                                            isItemSelected={isItemSelected}
                                            row={row}
                                            index={index}
                                            labelId={labelId}
                                            setSelected={setSelected}
                                        />
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} style={{ textAlign: "center", height: "200px" }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
