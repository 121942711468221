import React, { useEffect } from "react";
import SelectComponent from "@/components/crm/opportunities/SelectComponent";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UploadModal from "./UploadModal";
import useFiles from "@/hooks/crm/useFiles";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { triggerDownload } from "../../utils";

export default function TimelineFiles() {
    const [openModal, setOpenModal] = React.useState(false);
    const [filesData, setFilesData] = React.useState([]);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [refreshUI, setRefreshUI] = React.useState(false);
    const [loading, setLoading] = React.useState({ initial: false, delete: [], download: false });
    const [activeFile, setActiveFile] = React.useState(null);

    const { getFiles, deleteFile, getSignedUrl } = useFiles();
    const { opportunityId } = useParams();

    useEffect(() => {
        const fetchFiles = async () => {
            setLoading({ ...loading, initial: true });
            try {
                const response = await getFiles(opportunityId, "opportunityId");
                if (response) {
                    setFilesData(response.data);
                    setSelectedFiles([]);
                }
            } catch (error) {
                console.error(error);
            }
            setLoading({ ...loading, initial: false });
        };
        if (opportunityId) {
            fetchFiles();
        }
    }, [opportunityId, refreshUI]);

    const toggleFileSelection = (file) => {
        setSelectedFiles((prev) => {
            if (prev.includes(file)) {
                return prev.filter((f) => f !== file);
            } else {
                return [...prev, file];
            }
        });
    };

    const selectAllFiles = () => {
        setSelectedFiles(filesData);
    };

    const clearAllFiles = () => {
        setSelectedFiles([]);
    };

    const deleteSelectedFiles = async () => {
        setLoading({ ...loading, delete: selectedFiles.map((file) => file._id) });
        try {
            await Promise.all(selectedFiles.map((file) => deleteFile(file._id)));
            toast.success("Selected files deleted successfully");
            setRefreshUI((prev) => !prev);
            setSelectedFiles([]);
        } catch (error) {
            console.error(error);
        }
        setLoading({ ...loading, delete: [] });
    };

    const downloadSelectedFiles = async () => {
        try {
            await Promise.all(
                selectedFiles.map(async (file) => {
                    const response = await getSignedUrl(file._id);
                    if (response && response.signedURL) {
                        triggerDownload(response.signedURL, file.name);
                    }
                })
            );
        } catch (error) {
            console.error("Error downloading selected files:", error);
        }
    };

    const isFileSelected = (file) => selectedFiles.includes(file);

    const handleIndividualDelete = async (file) => {
        setActiveFile(file);
        setLoading({ ...loading, delete: [file._id] });
        try {
            await deleteFile(file._id);
            toast.success("File deleted successfully");
            setRefreshUI((prev) => !prev);
        } catch (error) {
            console.error(error);
        }
        setLoading({ ...loading, delete: [] });
        setActiveFile(null);
    };
    const handleIndividualDownload = async (file) => {
        try {
            console.log("file", file);
            const response = await getSignedUrl(file._id);
            if (response && response.signedURL) {
                triggerDownload(response.signedURL, file.name);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <div>
            <UploadModal open={openModal} setOpen={setOpenModal} setRefreshUI={setRefreshUI} type="opportunity" typeId={opportunityId} />
            <div className="border-b flex items-center border-stone-250 p-3 py-5">
                <p className="font-semibold">Files</p>
                {/* <div className="flex gap-4 ml-auto">
                    <SelectComponent bgColor="#fff" sx={{ width: "130px" }} options={["op1"]} placeholder="All" />
                </div> */}
            </div>
            <div className="p-3 text-sm">
                <button
                    onClick={() => setOpenModal(true)}
                    className="py-1.5 px-4 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100"
                >
                    <FileUploadOutlinedIcon className="!w-5 !h-4" />
                    <p className="text-xs font-medium">Upload File</p>
                </button>
                {selectedFiles.length > 0 && (
                    <div className="flex gap-2 mt-5 items-center">
                        <div className="flex gap-4">
                            <p className="font-semibold ">{selectedFiles.length} Selected</p>
                            <p onClick={selectAllFiles} className="font-semibold text-blue-500 cursor-pointer hover:text-blue-600">
                                Select All
                            </p>
                            <p onClick={clearAllFiles} className="font-semibold text-blue-500 cursor-pointer hover:text-blue-600">
                                Clear All
                            </p>
                            <p onClick={deleteSelectedFiles} className="font-semibold text-blue-500 cursor-pointer hover:text-blue-600">
                                Delete
                            </p>
                        </div>
                        <button
                            onClick={downloadSelectedFiles}
                            className="py-1.5 px-4 ml-auto text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100"
                        >
                            <FileDownloadOutlinedIcon className="!w-5 !h-4" />
                            <p className="text-xs font-medium">Download</p>
                        </button>
                    </div>
                )}
                {loading.initial ? (
                    <div className="text-black h-64 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                    </div>
                ) : filesData.length === 0 ? (
                    <div className="flex flex-col justify-center items-center h-64">
                        <p className="font-semibold text-lg mt-5">No files yet! </p>
                        <p className="text-dgray-6 mt-3">Upload your first one now!</p>
                    </div>
                ) : (
                    <div className="flex flex-col gap-3 mt-3">
                        {filesData.map((file) => (
                            <div
                                key={file._id}
                                className="flex items-center gap-2 p-3 rounded-lg overflow-hidden border border-stone-250 pr-3 hover:border-slate-300 transition-all"
                            >
                                <input
                                    type="checkbox"
                                    checked={isFileSelected(file)}
                                    onChange={() => toggleFileSelection(file)}
                                    className="scale-105 cursor-pointer"
                                />
                                <div className="ml-2">
                                    <InsertPhotoOutlinedIcon className="!h-5 !w-5" />
                                </div>
                                <div className="w-full ml-2 space-y-1">
                                    <div className="text-sm flex justify-between">
                                        <p className="text-muted-foreground ">{file.name.slice(0, 25)}</p>
                                    </div>
                                </div>
                                <div className="text-dgray-700 flex items-center gap-4 ml-auto">
                                    <button onClick={() => handleIndividualDownload(file)} className="">
                                        <FileDownloadOutlinedIcon className="!h-5 !w-5" />
                                    </button>
                                    <button onClick={() => handleIndividualDelete(file)} className="">
                                        {loading.delete.includes(file._id) ? (
                                            <CircularProgress size={11} color="inherit" />
                                        ) : (
                                            <DeleteOutlineOutlinedIcon className="!h-5 !w-5" />
                                        )}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
