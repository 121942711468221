import useAuth from "@/hooks/useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";

const useTimeline = () => {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const getOpportunityById = async (id) => {
        return fetchWithAuth(`/api/v1/opportunities/${id}`, "GET");
    };

    const getActivities = async (id) => {
        return fetchWithAuth(`/api/v1/opportunityActivities?opportunityId=${id}&formate=true`, "GET");
    };

    return {
        getOpportunityById,
        getActivities,
    };
};

export default useTimeline;
