import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import CustomSingleSelectAutoComplete from "../CustomSingleSelectAutoComplete";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress } from "@mui/material";

export default function EmailSentChart({ marginTop = "40px" }) {
    const { getEmailMetrics } = useAIEngagement();
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null); // Reference to store chart instance
    const [chartData, setChartData] = useState([]);
    const [selectTimeRange, setSelectTimeRange] = useState("This Week");
    const timeFilter = { "This Week": "THIS_WEEK", "This Month": "THIS_MONTH", "Last Month": "LAST_MONTH", "This Year": "THIS_YEAR" };
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchEmailData = async () => {
            setLoading(true);
            try {
                const res = await getEmailMetrics(timeFilter[selectTimeRange]);
                if (res?.data) {
                    setChartData(res.data || {});
                }
            } catch (error) {
                console.error("Error while fetching email metrics: ", error);
            }
            setLoading(false);
        };
        fetchEmailData();
    }, [selectTimeRange]);

    useEffect(() => {
        // Initialize chart only once
        if (!chartRef.current && chartContainerRef.current) {
            chartRef.current = Highcharts.chart(chartContainerRef.current, {
                chart: { type: "column", height: 300 },
                title: { text: null },
                xAxis: { categories: [], gridLineWidth: 0, lineWidth: 0 },
                yAxis: { min: 0, title: { text: null }, labels: { overflow: "justify" }, gridLineWidth: 1 },
                plotOptions: {
                    column: {
                        borderRadius: 0,
                        groupPadding: 0.1,
                        dataLabels: {
                            enabled: true,
                            inside: false,
                            style: { textOutline: "none", color: null },
                        },
                    },
                },
                legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
                    shadow: false,
                    symbolRadius: 0,
                },
                credits: { enabled: false },
                series: [], // Initialize with empty series
            });
        }

        // Update chart when chartData changes
        if (chartRef.current && chartData?.series) {
            const updatedOptions = {
                xAxis: { categories: chartData.categories || [] },
                series: chartData.series.map((serie) => {
                    return {
                        name: serie.name,
                        data: serie.data.map((value) => ({ y: value, color: serie.color })),
                        color: serie.color,
                    };
                }),
            };
            chartRef.current.update(updatedOptions, true, true);
        }
    }, [chartData]);

    return (
        <div style={{ marginTop: marginTop }} className="campaign-card">
            <div className="flex">
                <div>
                    <h2 className="main-text-black-600 !text-[16px]">Emails Sent Time Series</h2>
                </div>
                <div className="ml-auto">
                    <CustomSingleSelectAutoComplete
                        id="timeZone for email sent chart"
                        placeholder="Select Time Zone"
                        options={Object.keys(timeFilter)}
                        onSelectionChange={(e, value) => setSelectTimeRange(value)}
                        selectedValue={selectTimeRange}
                        isIconVisible={false}
                        bgColor="white"
                        sx={{ width: "200px" }}
                    />
                </div>
            </div>
            <div className="mt-5">
                {loading && (
                    <div className="flex justify-center items-center h-[264px] w-full text-black">
                        <CircularProgress className="!h-8 !w-8" color="inherit" />
                    </div>
                )}
                <div ref={chartContainerRef} style={{ display: loading ? "none" : "block" }} />
            </div>
        </div>
    );
}
