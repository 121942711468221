import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import SortIcon from "@/components/icons/SortIcon";
import { ReactComponent as NameIcon } from "@/assets/image/crm/opportunities/name.svg";
import { ReactComponent as AmountIcon } from "@/assets/image/crm/opportunities/amount.svg";
import { ReactComponent as CreatedByIcon } from "@/assets/image/crm/opportunities/createdBy.svg";
import { ReactComponent as CloseDateIcon } from "@/assets/image/crm/opportunities/closeDate.svg";
import { ReactComponent as CompanyIcon } from "@/assets/image/crm/opportunities/company.svg";
import { ReactComponent as StageIcon } from "@/assets/image/crm/opportunities/stage.svg";
import { ReactComponent as ContactIcon } from "@/assets/image/crm/opportunities/contact.svg";

// Export headCells for use in OpportunityTableRow
export const headCells = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Name",
        icon: <NameIcon className="h-5 w-5" />,
    },
    {
        id: "value",
        numeric: true,
        disablePadding: false,
        label: "Amount",
        icon: <AmountIcon className="h-5 w-5" />,
    },
    {
        id: "dueDate",
        numeric: false,
        disablePadding: false,
        label: "Close Date",
        icon: <CloseDateIcon className="h-5 w-5" />,
    },
    {
        id: "company",
        numeric: false,
        disablePadding: false,
        label: "Company",
        icon: <CompanyIcon className="h-5 w-5" />,
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Stage",
        icon: <StageIcon className="h-5 w-5" />,
    },
    {
        id: "pointOfContact",
        numeric: false,
        disablePadding: false,
        label: "Point of Contact",
        icon: <ContactIcon className="h-5 w-5" />,
    },
    {
        id: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        icon: <CreatedByIcon className="h-5 w-5" />,
    },
];

function OpportunityTableHeader(props) {
    const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "5px 12px",
                    fontFamily: "Inter",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        // Exclude last cell
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                <TableCell padding="checkbox" sx={{ width: "36px", minWidth: "36px", maxWidth: "36px" }}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        sx={{
                            "&.Mui-checked": {
                                color: "#2D7A89",
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: "#2D7A89",
                                fill: "#2D7A89",
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                                marginLeft: -1.5,
                            },
                        }}
                        inputProps={{
                            "aria-label": "select all",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            width: "14.28%", // 7 columns, divide 100% equally
                        }}
                        className={headCell.id === "createdBy" ? "text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]" : ""}
                    >
                        {headCell.label && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    color: "#6D6D6D",
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {headCell.icon}

                                    <div className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">{headCell.label}</div>
                                </div>
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OpportunityTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default OpportunityTableHeader;
