import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

export default function AccountsTableRow(props) {
    const { row, isItemSelected, labelId, setSelected } = props;
    const navigate = useNavigate();

    return (
        <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={false}
            sx={{
                background: isItemSelected ? "#F6F6F6" : "white",
                "& .MuiTableCell-root:not(:last-child)": { borderRight: "1px solid #E7E7E7" },
                "& .MuiTableCell-root": {
                    border: "none",
                    borderTop: props.index === 0 ? "none" : 1,
                    borderColor: "#E7E7E7",
                    padding: "12px 16px",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
                "&:hover": {
                    background: "#f4f3f0",
                },
            }}
        >
            {/* <TableCell padding="checkbox" sx={{ width: "56px" }}>
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={() => {
                        setSelected((prev) => (isItemSelected ? prev.filter((id) => id !== row._id) : [...prev, row._id]));
                    }}
                />
            </TableCell> */}

            {/* Comapny Name */}
            <TableCell>
                <div className="flex items-center gap-2">
                    {row.website && (
                        <img
                            src={`https://logo.clearbit.com/${row.website}`}
                            width={20}
                            height={20}
                            onError={(e) => (e.target.style.display = "none")}
                        />
                    )}
                    <p
                        className="hover:underline font-medium cursor-pointer text-sm"
                        onClick={() => navigate(`/records/accounts/${row._id}`)}
                    >
                        {row.name}
                    </p>
                </div>
            </TableCell>

            {/* Account Owner */}
            <TableCell>
                <p className="text-sm">{row.type || "-"}</p>
            </TableCell>

            {/* Industry */}
            <TableCell>
                <p className="text-sm">{row.industry || "-"}</p>
            </TableCell>

            {/* Phone */}
            <TableCell>
                <p className="text-sm">{row.country === "true" ? "-" : row.country || "-"}</p>
            </TableCell>
        </TableRow>
    );
}
