import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

export default function Calendar() {
    useEffect(() => {
        (async function () {
            try {
                const cal = await getCalApi({});
                cal("ui", {
                    styles: {
                        body: { background: "#F4F3F0" },
                        eventTypeListItem: { background: "#F4F3F0", backgroundColor: "#F4F3F0" },
                        branding: { brandColor: "#F4F3F0" },
                    },
                    hideEventTypeDetails: false,
                    layout: "month_view",
                });
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);
    return (
        <Cal
            calLink="saurav-bubber-ec6oxt/15min"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
            config={{ layout: "month_view" }}
        />
    );
}
