import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { ReactComponent as ActionsIcon } from "@/assets/image/settings/actions.svg";

const ThreeDotsMenu = ({ onClickHandler }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = () => {
        handleClose();
        onClickHandler();
    };

    return (
        <div className="w-full h-full flex items-center cursor-pointer">
            {/* Three dots button */}
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    padding: 0,
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                    },
                }}
            >
                <ActionsIcon />
            </IconButton>

            {/* Dropdown menu */}
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
            >
                <MenuItem onClick={handleMenuClick}>
                    <span className="main-text-black-500">Disconnect</span>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ThreeDotsMenu;
