import React, { useState, useEffect, useContext } from "react";
import PricingComponent from "@/components/pricing/PricingComponent/PricingComponent";
import PricingTable from "@/components/pricing/PricingTable";
import { useNavigate } from "react-router-dom";
import useBillingStore from "@/store/useBillingStore";
import { AuthContext } from "@/context/AuthContext";
import { useLocation } from "react-router-dom";

const tiers = {
    actionsPerProductTier: {
        "Professional Email Enrichment": 50,
        "Personal and Professional Email Enrichment": 100,
        "Phone Enrichment": 200,
        "Email Validation": 5,
        "Email Warmup": 2500,
        "Person-Level Website Visitors": 30,
        "AI Emails": 2,
        "Non-AI Emails": 1,
        "AI Search Enrichment": 500,
        "LinkedIn Connection Request": 50,
        "LinkedIn Message": 10,
    },
    free: {
        title: "Team",
        slogan: "Test drive it",
        theme: {
            border: "border-[#E7E7E7]",
            main: "bg-[#F4F3F0]",
        },
    },
    professional: {
        title: "Professional",
        slogan: "Essential for finding your prospects",
        theme: {
            border: "border-[#D3E1DB]",
            main: "bg-[#D3E1DB]",
        },
    },
    premium: {
        title: "Premium",
        slogan: "Perfect for small teams with simple workflows",
        theme: {
            border: "border-[#FFC8C8]",
            main: "bg-[#FFC8C8]",
        },
        promo: "Most popular",
    },
    enterprise: {
        title: "Enterprise",
        slogan: "Ideal for prospecting at large scales",
        theme: {
            border: "border-[#455BFF]",
            main: "bg-[#A8B5E4]",
        },
    },
};

function FullPricingBlock(props) {
    const { tariffList, subscriptionInfo, annual, topPosition, isUpgradePage = false } = props;
    const { changePrices, setRedirect, changePlan } = useBillingStore();
    const authContext = useContext(AuthContext);
    const [signed, setSigned] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const location = useLocation();
    const hideFreePlan = location.pathname === "/upgrade" && subscriptionInfo.plan !== "Free";
    const isPricingPage = location.pathname === "/pricing";
    const navigate = useNavigate();

    //Selecting a plan by clicking a button in a table or on a Plan card
    const handleSelection = (product, price_obj) => {
        if (!signed) {
            setRedirect("/upgrade");
            navigate("/signin");
            return;
        }

        if (isPricingPage) {
            navigate("/upgrade");
        } else {
            changePrices({ main: price_obj });
            changePlan(product);

            if (subscriptionInfo.plan !== "Free") {
                navigate("/planupgrade", { state: { redirected: true } });
                return;
            } else {
                navigate("/billingpay");
            }
        }
    };

    useEffect(() => {
        (async () => {
            const signedIn = await authContext.isSignIn();
            setSigned(signedIn);
        })();
    }, []);

    return (
        <div className="pt-32 flex h-fit w-full flex-col">
            {/* <p className="mb-8 text-sm font-medium">* All features enabled until December 31, 2024 for all plans.</p> */}
            <div
                className={`w-full grid gap-x-6 xl:gap-x-10 gap-y-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${hideFreePlan ? "lg:grid-cols-3" : "pre-xl:grid-cols-4"} items-end justify-between justify-items-between xl:gap-8 self-center px-6 pre-xl:px-12`}
            >
                {tariffList?.map((product) => {
                    if (hideFreePlan && product.key === "free") return <></>;
                    return (
                        <PricingComponent
                            key={product.id}
                            isCurrent={
                                subscriptionInfo?.plan?.toLowerCase() === product?.name?.toLowerCase() &&
                                subscriptionInfo?.status !== "canceled"
                            }
                            tiers={tiers}
                            handleSelection={handleSelection}
                            product={product}
                            annual={annual}
                            expandState={[expanded, setExpanded]}
                            isPricingPage={isPricingPage}
                        />
                    );
                })}
            </div>
            {tariffList && tariffList.length > 0 && (
                <PricingTable tiers={tiers} annual={annual} products={tariffList} topPosition={topPosition} isUpgradePage={isUpgradePage} />
            )}
        </div>
    );
}

export default FullPricingBlock;
