import React from "react";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ReactComponent as Revenue } from "@/assets/image/accounts_page/revenue.svg";
import { ReactComponent as Employee } from "@/assets/image/accounts_page/employee.svg";
import { ReactComponent as Site } from "@/assets/image/accounts_page/site.svg";
import { ReactComponent as Linkedin } from "@/assets/image/accounts_page/linkedin.svg";
import { ReactComponent as Facebook } from "@/assets/image/accounts_page/facebook.svg";
import { ReactComponent as LinkedinBlack } from "@/assets/image/accounts_page/linkedin-black.svg";
import { ReactComponent as FacebookBlack } from "@/assets/image/accounts_page/facebook-black.svg";
import { useNavigate } from "react-router-dom";
import { truncateText } from "@/utils/common";
import dayjs from "dayjs";

export default function AccountOverview({ companyData, contactsData }) {
    const navigate = useNavigate();
    function formatNumber(num) {
        if (num >= 1e12) return (num / 1e12).toFixed(0) + "T"; // Trillions
        if (num >= 1e9) return (num / 1e9).toFixed(0) + "B"; // Billions
        if (num >= 1e6) return (num / 1e6).toFixed(0) + "M"; // Millions
        if (num >= 1e3) return (num / 1e3).toFixed(0) + "k"; // Thousands
        return num.toString(); // Less than 1000
    }

    const bgClasses = ["bg-accents-blue", "bg-accents-yellow", "bg-accents-purple", "bg-accents-green", "bg-accents-orange"];

    return (
        <div>
            <div className="flex gap-6">
                <div className="border border-[#e7e7e7] rounded-lg p-4 text-sm text-primary-black w-1/3">
                    <div className="flex items-center gap-2 text-base">
                        <h2 className="font-semibold">About {companyData.name}</h2>
                        {/* <button className="text-primary-teal text-sm py-1 px-2 rounded-md hover:bg-primary-header ml-auto">
                            View More
                        </button> */}
                    </div>
                    <div className="my-5 flex gap-3 items-center">
                        {companyData.website && (
                            <Site
                                className="!w-4 !h-4 cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        companyData.website.startsWith("http") ? companyData.website : `https://${companyData.website}`,
                                        "_blank"
                                    )
                                }
                            />
                        )}
                        {companyData.linkedIn && (
                            <Linkedin onClick={() => window.open(companyData.linkedIn, "_blank")} className="!w-4 !h-4 cursor-pointer" />
                        )}
                        {companyData.crunchbaseProfileUrl && (
                            <Facebook
                                onClick={() => window.open(companyData.crunchbaseProfileUrl, "_blank")}
                                className="!w-4 !h-4 cursor-pointer "
                            />
                        )}
                    </div>
                    <p className="font-medium">{truncateText(companyData.linkedInCompanyDescription, 300)}</p>
                    <table className="w-full text-sm text-left mt-5 text-dgray-6">
                        <tbody>
                            {companyData.industry && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2">
                                        <CategoryOutlinedIcon className="!w-4 !h-4" /> Industry:
                                    </td>
                                    <td className="font-medium text-black pl-2">
                                        {companyData.industry.charAt(0).toUpperCase() + companyData.industry.slice(1).toLowerCase()}
                                    </td>
                                </tr>
                            )}
                            {companyData.headQuarters && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                        <ApartmentOutlinedIcon className="!w-4 !h-4" /> HQ City:
                                    </td>
                                    <td className="font-medium text-black pl-2">{companyData.headQuarters}</td>
                                </tr>
                            )}
                            {companyData.estimatedRevenueLowerBoundUSD && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                        <Revenue className="!w-4 !h-4" /> Revenue Range:
                                    </td>
                                    <td className="font-medium text-black pl-2">
                                        {formatNumber(companyData.estimatedRevenueLowerBoundUSD)} -{" "}
                                        {formatNumber(companyData.estimatedRevenueHigherBoundUSD)}{" "}
                                    </td>
                                </tr>
                            )}
                            {companyData.employeeCountRange && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                        <Employee className="!w-4 !h-4" /> Employee Range:
                                    </td>
                                    <td className="font-medium text-black pl-2">{companyData.employeeCountRange} </td>
                                </tr>
                            )}
                            {companyData.yearFounded && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                        <EventOutlinedIcon className="!w-4 !h-4" /> Year Founded:
                                    </td>
                                    <td className="font-medium text-black pl-2">{dayjs(companyData.yearFounded).year()} </td>
                                </tr>
                            )}
                            {companyData.website && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2">
                                        <LanguageOutlinedIcon className="!w-4 !h-4" /> Website:
                                    </td>
                                    <td className="font-medium text-black pl-2">{companyData.website} </td>
                                </tr>
                            )}
                            {companyData.linkedIn && (
                                <tr>
                                    <td className="flex items-center gap-1 py-2">
                                        <LinkedinBlack className="!w-4 !h-4" /> LinkedIn:
                                    </td>
                                    <td className="font-medium text-black pl-2">{companyData.linkedIn} </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="border border-[#e7e7e7] rounded-lg p-4 text-sm text-primary-black w-1/3">
                    <h2 className="font-semibold text-base">Key Products and Services</h2>
                    <ul className="flex flex-col gap-2 mt-5 list-disc pl-4">
                        {companyData.linkedInIndustries && companyData.linkedInIndustries.length > 0 ? (
                            companyData.linkedInIndustries.slice(0, 5).map((industry, index) => (
                                <li key={index} className="font-medium text-black">
                                    {industry}
                                </li>
                            ))
                        ) : (
                            <p>No data found for this account</p>
                        )}
                    </ul>
                </div>

                <div className="border border-[#e7e7e7] rounded-lg p-4 text-sm text-primary-black w-1/3">
                    <div className="flex items-center gap-2 text-base">
                        <h2 className="font-semibold">Contacts</h2>
                        <button
                            onClick={() => navigate("/records/contacts")}
                            className="text-primary-teal text-sm py-1 px-2 rounded-md hover:bg-primary-header ml-auto"
                        >
                            View More
                        </button>
                    </div>
                    <div className="flex flex-col gap-4 mt-5">
                        {contactsData && contactsData.length > 0 ? (
                            contactsData.map((contact, index) => {
                                // Get initials
                                const initials = `${contact.firstName?.charAt(0) || ""}${contact.lastName?.charAt(0) || ""}`;
                                // Pick a random background class
                                const randomBg = bgClasses[index % bgClasses.length];
                                return (
                                    <div className="flex gap-2 items-center" key={index}>
                                        <div className={`w-12 h-12 rounded-full flex items-center justify-center font-medium ${randomBg}`}>
                                            {initials.toUpperCase()}
                                        </div>
                                        <div>
                                            <h2 className="font-medium text-sm">
                                                {contact.firstName?.charAt(0).toUpperCase() + contact.firstName?.slice(1).toLowerCase()}{" "}
                                                {contact.lastName?.charAt(0).toUpperCase() + contact.lastName?.slice(1).toLowerCase()}
                                            </h2>
                                            <p className="text-xs text-dgray-6">{contact.level}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-sm">No contacts for this account</p>
                        )}
                    </div>
                </div>
            </div>
            {companyData.newsArticles && companyData.newsArticles.length > 0 && (
                <>
                    <div className="flex items-center gap-2 text-base mt-10">
                        <h2 className="font-semibold">Articles</h2>
                        {/* <button className="text-primary-teal text-sm py-1 px-2 rounded-md hover:bg-primary-header ml-auto">
                            View More
                        </button> */}
                    </div>
                    <div className="text-sm mt-4 grid grid-cols-3 gap-3">
                        {companyData.newsArticles.slice(0, 10).map((article, index) => (
                            <div
                                className="border border-[#e7e7e7] rounded-lg p-4 text-sm text-primary-black flex flex-col gap-2"
                                key={index}
                            >
                                <h2 className="font-semibold truncate">{article.title}</h2>
                                <a href={article.url} target="_blank" rel="noopener noreferrer" className="text-primary-teal truncate">
                                    {article.url}
                                </a>
                                <p className="text-xs text-dgray-6 truncate">Published By: {article.publisherName}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}
