import React, { useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledSelect = styled(Select)(({ theme, bgColor, isBorderVisible }) => ({
    "& .MuiSelect-select": {
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "500",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: isBorderVisible ? "#D1D5DB" : "transparent",
        borderRadius: "4px !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: isBorderVisible ? "#D1D5DB" : "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: isBorderVisible ? "#D1D5DB" : "transparent",
    },
}));

const theme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "white", // Dropdown background
                    color: "black", // Text color
                    maxHeight: "300px", // Limit dropdown height
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow
                    padding: "8px", // Add padding between Paper and items
                },
                list: {
                    padding: 0, // Remove extra padding from the list
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "#F4F3F0 !important", // Background for selected item
                        borderRadius: "8px", // Rounded corners
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#F4F3F0", // Hover color for selected item
                    },
                    padding: "10px", // Add custom padding to menu items
                    fontSize: "14px", // Font size for selected item
                    borderRadius: "8px", // Rounded corners
                    fontWeight: "500", // Font weight for selected item
                },
            },
        },
    },
});

export default function SelectComponent({
    id,
    options,
    onSelectionChange,
    selectedValue,
    placeholder = "Select items",
    sx = {},
    bgColor,
    onBlur,
}) {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <ThemeProvider theme={theme}>
                <StyledSelect
                    id={id}
                    value={selectedValue || ""}
                    onChange={onSelectionChange}
                    displayEmpty
                    open={open}
                    onOpen={() => setOpen(true)}
                    sx={{
                        ...sx, // Allow additional styling to be passed in
                    }}
                    isBorderVisible={true}
                    bgColor={bgColor}
                    onBlur={onBlur ? onBlur : null}
                    onClose={() => setOpen(false)}
                    IconComponent={(props) => (
                        <ExpandMoreIcon
                            {...props}
                            className={`h-5 w-5 transition-transform duration-100 mr-2 ${open ? "rotate-180" : "rotate-0"}`}
                        />
                    )}
                >
                    {/* <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem> */}
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </ThemeProvider>
        </div>
    );
}
