import React, { useState } from "react";
import { Popper, Grow, Paper, ClickAwayListener } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ScrollArea } from "@/components/common/ScrollArea";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DoneIcon from "@mui/icons-material/Done";
import useTeam from "@/hooks/useTeam";
import { extractNameFromEmail } from "@/utils/common";
import { ReactComponent as AddIcon } from "@/assets/image/crm/opportunities/addIcon.svg";

const AssigneePopover = ({ selectedAssignee, setSelectedAssignee }) => {
    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const anchorRef = React.useRef(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    const { getTeamMembers } = useTeam();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleAssigneeToggle = (assignee) => {
        setSelectedAssignee(assignee);
        setOpen(false);
    };

    React.useEffect(() => {
        const fetchTeamMembers = async () => {
            setLoading(true);
            try {
                const res = await getTeamMembers({ searchValue: "" });
                if (res) {
                    const validData = res.data
                        .filter((member) => member.userId)
                        .map((member) => ({
                            name: extractNameFromEmail(member.email),
                            id: member.userId,
                        }));
                    setTeamMembers(validData);
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        fetchTeamMembers();
    }, []);

    const filteredAssignees = teamMembers.filter((assignee) => assignee.name?.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div>
            <button
                ref={anchorRef}
                className="py-1 h-[33px] px-8 text-sm font-medium flex gap-2 items-center rounded-lg border border-[#e7e7e7] hover:bg-primary-header"
                onClick={handleToggle}
            >
                {selectedAssignee ? (
                    <p className="">{selectedAssignee.name}</p>
                ) : (
                    <div className="flex items-center gap-2">
                        <AddIcon />
                        <p>Assignee</p>
                    </div>
                )}
            </button>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "left top",
                        }}
                    >
                        <Paper sx={{ boxShadow: "0px 4px 34px 0px #00000029", borderRadius: "8px" }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    {/* Search Input */}
                                    <div className="p-4 pb-2">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                className="p-2 pl-10 px-4 w-full border border-stone-250 rounded-lg placeholder:text-dgray-6 placeholder:text-sm"
                                                placeholder="Search assignees"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                            <SearchIcon
                                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-dgray-500"
                                                fontSize="small"
                                            />
                                        </div>
                                    </div>

                                    {/* Assignees List */}
                                    {loading ? (
                                        <div className="p-4 text-center">Loading...</div>
                                    ) : (
                                        <ScrollArea className="h-60 pb-4">
                                            <div className="px-4">
                                                <div
                                                    className="flex items-center text-sm gap-3 py-2 px-3 hover:bg-stone-100 rounded-lg cursor-pointer"
                                                    onClick={() => handleAssigneeToggle(null)}
                                                >
                                                    <NotInterestedIcon sx={{ width: "16px" }} />
                                                    No Assignee
                                                </div>
                                                {filteredAssignees.map((assignee) => (
                                                    <div
                                                        key={assignee?.id}
                                                        className={`flex items-center gap-3 py-2 px-3 hover:bg-primary-header rounded-lg cursor-pointer ${selectedAssignee?.id == assignee?.id && "bg-primary-header"}`}
                                                        onClick={() => handleAssigneeToggle(assignee)}
                                                    >
                                                        <span className="text-sm">{assignee.name}</span>
                                                        {selectedAssignee?.id == assignee?.id && (
                                                            <DoneIcon className=" text-[#2D7A89] ml-auto" sx={{ width: "20px" }} />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </ScrollArea>
                                    )}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default AssigneePopover;
