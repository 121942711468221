import { useEffect, useState } from "react";
import TrendDashboard from "./TrendDashboard";
import DailyCollectedProfiles from "./DailyCollectedProfiles";
import SelectDropdown from "@/components/common/SelectDropdown";
import CustomTable from "./Table";
import { Box, Typography } from "@mui/material";
import { CompanyIcon, VisitsIcon, ConfidenceIntervalIcon, LocationsIcon } from "./Icon";
import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import { useQuery } from "@tanstack/react-query";

// Analytics.jsx or a separate file (e.g., tableLayouts.js)

const getConfidenceStyles = (value) => {
    switch (value) {
        case "Moderate":
            return {
                bg: "bg-[#FFEEDE]",
                text: "text-[#FF782C]",
            };
        case "High":
            return {
                bg: "bg-[#ECEEFF]",
                text: "text-[#2E3DAA]",
            };
        case "Very High":
            return {
                bg: "bg-[#EAF1EE]",
                text: "text-[#1C4E38]",
            };
        default:
            return {
                bg: "bg-gray-100",
                text: "text-gray-800",
            };
    }
};

const tableLayoutData = [
    {
        label: "Top Page Visits",
        column: [
            { id: "companyName", label: "Company", width: 150, sortable: true, icon: <CompanyIcon /> },
            { id: "visitsNumber", label: "Visits", width: 100, sortable: true, icon: <VisitsIcon /> },
            {
                id: "confidenceLevel",
                label: "Confidence Interval",
                width: 150,
                sortable: true,
                renderCell: (value) => {
                    const styles = getConfidenceStyles(value);
                    return (
                        <Box display="flex" flexDirection="column">
                            <div className={`h-[29px] w-fit px-2 py-1 ${styles.bg} rounded inline-flex items-center`}>
                                <div className={`text-center ${styles.text} text-xs font-normal leading-[21px]`}>{value}</div>
                            </div>
                        </Box>
                    );
                },
                icon: <ConfidenceIntervalIcon />,
            },
        ],
    },
    {
        label: "Visitors by State",
        column: [
            { id: "companyName", label: "Company", width: 150, sortable: true, icon: <CompanyIcon /> },
            { id: "visitsNumber", label: "Visits", width: 100, sortable: true, icon: <VisitsIcon /> },
            {
                id: "locationName",
                label: "Locations",
                width: 150,
                sortable: true,
                renderCell: (value) => (
                    <Typography fontSize={14} fontWeight="medium" color="#050505" sx={{ lineHeight: "21px" }}>
                        {value}
                    </Typography>
                ),
                icon: <LocationsIcon />,
            },
        ],
    },
];

function Analytics({ analyticsData, duration, setDuration }) {
    const [newProfiles, setNewProfiles] = useState(0);
    const [returningProfiles, setReturningProfiles] = useState(0);
    const [siteViews, setSiteViews] = useState(0);
    const [tableDataTopVisits, setTableDataTopVisits] = useState([]);
    const [tableDataVisitorsByState, setTableDataVisitorsByState] = useState([]);
    const [dataByDays, setDataByDays] = useState([]);
    const [allDomains, setAllDomains] = useState([]);
    const [chosenDomain, setChosenDomain] = useState(null);
    const { getAllowedDomains } = useIPsDeanonymization();

    const { data: allowedDomains } = useQuery({
        queryFn: getAllowedDomains,
    });

    useEffect(() => {
        if (allowedDomains?.length > 0) {
            setAllDomains(allowedDomains);
            // Only set chosen domain if it's not already set
            if (!chosenDomain) {
                setChosenDomain(allowedDomains[0]);
            }
        }
    }, [allowedDomains, chosenDomain]);

    useEffect(() => {
        const analyzeData = async () => {
            if (analyticsData?.length <= 0) return;

            const domainData = analyticsData?.find((data) => data.domain === chosenDomain) || [];

            const { overallAnalytics, dailyAnalytics, topPageVisits, topVisitorsByState } = domainData;

            setNewProfiles(overallAnalytics?.nonRepeatedIPs || 0);
            setReturningProfiles(overallAnalytics?.repeatedIPs || 0);
            setSiteViews(overallAnalytics?.totalIPs || 0);
            setDataByDays(dailyAnalytics || []);
            setTableDataTopVisits(topPageVisits || []);
            setTableDataVisitorsByState(topVisitorsByState || []);
        };
        analyzeData();
    }, [analyticsData, chosenDomain]);

    return (
        <>
            <div className="flex flex-row justify-between items-center p-6 border-b border-[#E7E7E7]">
                <div className="flex flex-col gap-2">
                    <div className="text-[#050505] text-base font-semibold font-['Inter']">Analytics</div>
                    <div className="text-[#6d6d6d] text-sm font-medium font-['Inter'] leading-[21px]">
                        Generate an export to be sent to your email.
                    </div>
                    {/* <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] md:whitespace-nowrap leading-none">
                        Analysis of your website traffic for the past {duration} for {chosenDomain}
                    </p> */}
                </div>
                <div className="flex gap-4 flex-row">
                    <div className="flex flex-row justify-start items-center gap-4">
                        <div className="text-[#090c05] text-xs font-normal font-['Inter'] leading-[21px]">Filter By:</div>
                        {allDomains?.length > 0 && (
                            <SelectDropdown
                                options={allDomains.map((domain) => {
                                    return { label: domain, value: domain };
                                })}
                                selectedOption={chosenDomain}
                                defaultValue={chosenDomain}
                                onChange={setChosenDomain}
                            />
                        )}
                        <SelectDropdown
                            options={[
                                { label: "Past 7 Days", value: "week" },
                                { label: "Past 30 Days", value: "month" },
                            ]}
                            selectedOption={duration}
                            defaultValue={duration}
                            onChange={setDuration}
                        />
                    </div>
                </div>
            </div>
            <div className="p-[24px]">
                <TrendDashboard newProfiles={newProfiles} returningProfiles={returningProfiles} duration={duration} siteViews={siteViews} />
                <div className="flex flex-row mt-12 justify-between items-start">
                    {dataByDays?.length > 0 && (
                        <div className="w-[49.5%]">
                            <DailyCollectedProfiles dataByDays={dataByDays} />
                            {/* <VisitorsAnalytics /> */}
                        </div>
                    )}
                    <div className="w-[49.5%] p-6 flex flex-col border gap-10 border-[#e7e7e7] rounded-[8px]">
                        <div className="text-[#050505] text-base font-semibold font-['Inter']">At a glance</div>
                        <CustomTable tableLayout={tableLayoutData[0]} tableData={tableDataTopVisits} />
                        <CustomTable tableLayout={tableLayoutData[1]} tableData={tableDataVisitorsByState} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Analytics;
