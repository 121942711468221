import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import MainLayout from "@/components/MainLayout";
import SearchIcon from "@/assets/image/team/search.svg";
import EnhancedTable from "@/components/team/TeamTable";
import AddTeamSeatDialog from "@/components/AddTeamSeatDialog";
import InviteMemberDialog from "@/components/InviteMemberDialog";
import CreateGroupDialog from "@/components/CreateGroupDialog";
import useTeam from "@/hooks/useTeam";
import Box from "@mui/material/Box";
import MainTitleBar from "@/components/MainTitleBar";
import BaseContainer from "@/components/BaseContainer";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";
import { CustomButton } from "@/components/crm/contacts/Button";

export default function TeamScene() {
    const filterRole = "All Roles";
    const [visibleTeamSeatDialog, setVisibleTeamSeatDialog] = useState(false);
    const [visibleInviteMemberDialog, setVisibleInviteMemberDialog] = useState(false);
    const [visibleCreateGroupDialog, setVisibleCreateGroupDialog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [sort, setSort] = useState({
        sort: -1,
        sortBy: "email",
    });
    const location = useLocation();
    const [data, setData] = useState([]);
    const [creditInfo, setCreditInfo] = useState({});
    const [userInfo, setUserInfo] = useState();
    const { getUserInfo } = useAuth();
    const getUserInfoRef = useRef();
    getUserInfoRef.current = getUserInfo;

    const { getTeamMembers, getCreditsInfo } = useTeam();
    const getTeamMembersRef = useRef();
    getTeamMembersRef.current = getTeamMembers;
    const getCreditsInfoRef = useRef();
    getCreditsInfoRef.current = getCreditsInfo;

    const [filteredData, setFilteredData] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch team members
        const fetchData = async (
            searchValue = "",
            filterRole = "",
            sort = {
                sort: -1,
                sortBy: "email",
            }
        ) => {
            try {
                const response = await getTeamMembersRef.current({
                    searchValue,
                    filterRole,
                    sort,
                });
                setData(response.data || []); // Fallback to empty array
                setFilteredData(response.data || []); // Fallback to empty array
            } catch (error) {
                toast.error("Failed to fetch team members.", { theme: "colored" });
            }
        };

        fetchData("", filterRole, sort);
    }, [sort]);

    // Fetch credit info only once
    useEffect(() => {
        const fetchCreditsInfo = async () => {
            try {
                const response = await getCreditsInfoRef.current();
                setCreditInfo(response.data);
            } catch (error) {
                toast.error("Failed to fetch credit information.", { theme: "colored" });
            }
        };

        fetchCreditsInfo();
    }, []);

    // Handle location changes
    useEffect(() => {
        if (location.pathname === "/invite") {
            if (!visibleInviteMemberDialog) {
                setVisibleInviteMemberDialog(true);
            }
        }
    }, [location, visibleInviteMemberDialog]);

    // Fetch user info
    useEffect(() => {
        getUserInfoRef
            .current()
            .then((response) => {
                setUserInfo(response?.userInfo);
            })
            .catch((error) => {
                toast.error(error?.message, { theme: "colored" });
            });
    }, []);

    // Add a useEffect to synchronize filteredData with data and searchValue
    useEffect(() => {
        if (searchValue === "") {
            setFilteredData(data);
        } else {
            const filtered = data.filter(
                (item) =>
                    item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.email.toLowerCase().includes(searchValue.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }, [data, searchValue]);

    // Handle search
    const handleSearch = (value) => {
        setSearchValue(value);
        // if (value === "") {
        //     setFilteredData(data);
        //     return;
        // }
        // // Search by name or email
        // const filtered = data.filter((item) => {
        //     return item.name.toLowerCase().includes(value.toLowerCase()) || item.email.toLowerCase().includes(value.toLowerCase());
        // });
        // setFilteredData(filtered);
    };

    // Function to update creditInfo locally
    const updateCreditInfo = (assignedCredits) => {
        setCreditInfo((prev) => ({
            ...prev,
            availableCredit: prev.availableCredit - assignedCredits,
            // Adjust other fields if necessary
        }));
    };

    return (
        <>
            {userInfo?.permission !== 1 && (
                <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
                    <MainTitleBar>
                        <div className="flex items-end justify-start gap-2">
                            {/* <ReactSVG src={TeamIcon} className="w-[34px] h-[34px]" /> */}
                            <p>Team Management</p>
                        </div>
                    </MainTitleBar>
                    <div className="flex flex-col grow">
                        <div className="w-full h-full py-5">
                            {userInfo?.permission !== 0 && (
                                <>
                                    <div className="w-full flex flex-col md:flex-row px-[24px] justify-between pb-4 border-b border-[#e7e7e7] items-center gap-2">
                                        <div className="flex flex-row gap-2">
                                            <div className="flex items-baseline gap-1 bg-white text-[#5E5E5E] font-Inter rounded-full text-sm p-3">
                                                <div className="text-[#050505] text-base font-semibold font-['Inter']">Users & admins:</div>
                                                <span className="text-[#050505] text-base font-semibold font-['Inter']">
                                                    {creditInfo?.currentInvitedTeamMembers ?? 0}/{creditInfo?.subscription?.seats ?? 0}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-2 font-Inter">
                                            <CustomButton
                                                onClick={() => {
                                                    navigate("/upgrade");
                                                    setVisibleTeamSeatDialog(true);
                                                }}
                                                title="Add seats"
                                                height={32}
                                                width={117}
                                                px={12}
                                                py={6}
                                                titleTextSize={14}
                                                titleFontWeight={500}
                                                className="hover:opacity-80 transition-opacity"
                                            />
                                            <CustomButton
                                                onClick={() => {
                                                    setVisibleInviteMemberDialog(true);
                                                }}
                                                title="Invite members"
                                                height={32}
                                                width={152}
                                                px={12}
                                                py={6}
                                                titleTextSize={14}
                                                titleFontWeight={500}
                                                border="1px solid #e7e7e7"
                                                titleColor="#050505"
                                                bg="white"
                                                className="hover:!bg-stone-50 transition-colors"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-[24px]">
                                        <div className="w-full flex flex-col justify-start items-center bg-white gap-2 mt-4">
                                            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2">
                                                {/* Search Bar */}
                                                <div className="w-full flex flex-col md:flex-row md:justify-start gap-2">
                                                    <div className="w-[50%] flex flex-row p-[8px_12px] rounded-[8px] items-center bg-white border-[1px] border-[#E8E7E7]">
                                                        <ReactSVG src={SearchIcon} />
                                                        <input
                                                            type="text"
                                                            className="w-full outline-none pl-[16px] text-[#888888] font-medium font-['Inter'] leading-[21px] text-sm"
                                                            placeholder="Search for contacts or teams"
                                                            value={searchValue}
                                                            onChange={(e) => {
                                                                handleSearch(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <EnhancedTable
                                                    filterRole={filterRole}
                                                    searchValue={searchValue}
                                                    data={filteredData}
                                                    setData={setData}
                                                    sort={sort}
                                                    setSort={setSort}
                                                    creditInfo={creditInfo}
                                                    updateCreditInfo={updateCreditInfo} // Pass the function
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {userInfo?.permission === 0 && (
                                <>
                                    <div className="w-full flex flex-col md:flex-row px-[24px] justify-between pb-4 border-b border-[#e7e7e7] items-center gap-2">
                                        <div className="flex flex-row gap-2">
                                            <div className="flex items-baseline gap-1 bg-white text-[#5E5E5E] font-Inter rounded-full text-sm p-3">
                                                <div className="text-[#050505] text-base font-semibold font-['Inter']">Users & admins:</div>
                                                <span className="text-[#050505] text-base font-semibold font-['Inter']">
                                                    {creditInfo?.currentInvitedTeamMembers ?? 0}/{creditInfo?.subscription?.seats ?? 0}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-2 font-Inter">
                                            <CustomButton
                                                onClick={() => {
                                                    navigate("/upgrade");
                                                    setVisibleTeamSeatDialog(true);
                                                }}
                                                title="Add seats"
                                                height={40}
                                                width={117}
                                                px={24}
                                                py={12}
                                                titleTextSize={14}
                                                titleFontWeight={500}
                                            />
                                            <CustomButton
                                                onClick={() => {
                                                    setVisibleInviteMemberDialog(true);
                                                }}
                                                title="Invite members"
                                                height={40}
                                                width={152}
                                                px={24}
                                                py={12}
                                                titleTextSize={14}
                                                titleFontWeight={500}
                                                border="1px solid #e7e7e7"
                                                titleColor="#050505"
                                                bg="white"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-[24px]">
                                        <div className="w-full flex flex-col justify-start items-center bg-white gap-2 mt-4">
                                            <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2">
                                                {/* Search Bar */}
                                                <div className="w-full flex flex-col md:flex-row md:justify-start gap-2">
                                                    <div className="w-[50%] flex flex-row p-[8px_12px] rounded-[8px] items-center bg-white border-[1px] border-[#E8E7E7]">
                                                        <ReactSVG src={SearchIcon} />
                                                        <input
                                                            type="text"
                                                            className="w-full outline-none pl-[16px] text-[#888888] font-medium font-['Inter'] leading-[21px] text-sm"
                                                            placeholder="Search for contacts or teams"
                                                            value={searchValue}
                                                            onChange={(e) => {
                                                                handleSearch(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <EnhancedTable
                                                    filterRole={filterRole}
                                                    searchValue={searchValue}
                                                    data={[]} // Pass an empty array to show an empty table
                                                    setData={setData}
                                                    sort={sort}
                                                    setSort={setSort}
                                                    creditInfo={creditInfo}
                                                    updateCreditInfo={updateCreditInfo} // Pass the function
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <AddTeamSeatDialog
                                open={visibleTeamSeatDialog}
                                handleClose={() => {
                                    setVisibleTeamSeatDialog(false);
                                }}
                                handleAddSeat={() => {
                                    navigate("/upgrade");
                                }}
                            />
                            <InviteMemberDialog
                                open={visibleInviteMemberDialog}
                                handleClose={() => {
                                    setVisibleInviteMemberDialog(false);
                                }}
                                handleInviteMember={() => {
                                    setVisibleInviteMemberDialog(false);
                                }}
                                creditInfo={creditInfo}
                                limitAvailable={creditInfo?.currentInvitedTeamMembers < creditInfo?.subscription?.seats}
                                data={data}
                                setData={setData}
                            />
                            <CreateGroupDialog
                                open={visibleCreateGroupDialog}
                                handleClose={() => {
                                    setVisibleCreateGroupDialog(false);
                                }}
                                handleContactSales={() => {
                                    setVisibleCreateGroupDialog(false);
                                }}
                            />
                        </div>
                    </div>
                </MainLayout>
            )}

            {userInfo?.permission === 1 && (
                <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
                    <MainTitleBar>
                        <div className="flex items-end justify-start gap-2">
                            <p>Team Management</p>
                        </div>
                    </MainTitleBar>
                    <BaseContainer>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                fontWeight: "bold",
                                paddingTop: 20,
                            }}
                        >
                            You do not have access to this view.
                        </Box>
                    </BaseContainer>
                </MainLayout>
            )}
        </>
    );
}
