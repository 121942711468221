import { useContext, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import { APP_ROUTES, LOGIN_ROUTES, LANDING_ROUTES } from "@/consts/routes";
import { checkRoutesPermissions } from "@/utils/common";
import { LayoutContext } from "@/context/LayoutContext";

export default function Router() {
    const { isGuest, userInfo } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const redirectPath = isGuest ? "/signin" : "/home";

    const AVAILABLE_ROUTES = checkRoutesPermissions({
        permission: userInfo?.permission,
        routes: APP_ROUTES,
    });

    return (
        <LayoutContext.Provider value={{ open, setOpen }}>
            <Routes>
                {Object.keys(LANDING_ROUTES).map((key) => {
                    const { Component, path, props } = LANDING_ROUTES[key];
                    return (
                        // You can wrap your pages into Layout here
                        <Route key={key} path={path} element={<Component {...props} />} />
                    );
                })}
                {Object.keys(LOGIN_ROUTES).map((key) => {
                    const { Component, path, props } = LOGIN_ROUTES[key];
                    return (
                        // You can wrap your pages into Layout here
                        <Route key={key} path={path} element={<Component {...props} />} />
                    );
                })}
                {Object.keys(AVAILABLE_ROUTES).map((key) => {
                    const { Component, path, props } = AVAILABLE_ROUTES[key];
                    return (
                        // You can wrap your pages into Layout here
                        <Route key={key} path={path} element={<Component {...props} />} />
                    );
                })}
                Redirect user if he get a wrong path
                <Route path="*" element={<Navigate replace to={redirectPath} />} />
            </Routes>
        </LayoutContext.Provider>
    );
}
