import { toast } from "react-toastify";

const defaultConfig = {
    theme: "light",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        background: "#FFFFFF",
    },
    className: "custom-toast-container",
};

const defaultStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
};

const SuccessIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1815_31574" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1815_31574)">
            <path
                d="M10.6 16.6L17.65 9.55L16.25 8.15L10.6 13.8L7.75 10.95L6.35 12.35L10.6 16.6ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                fill="#18A800"
            />
        </g>
    </svg>
);

const ErrorIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_665_12595" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#EA0707" />
        </mask>
        <g mask="url(#mask0_665_12595)">
            <path
                d="M12.0001 22.0266C11.7334 22.0266 11.4793 21.9766 11.2376 21.8766C10.9959 21.7766 10.7751 21.6349 10.5751 21.4516L2.5501 13.4266C2.36676 13.2266 2.2251 13.0057 2.1251 12.7641C2.0251 12.5224 1.9751 12.2682 1.9751 12.0016C1.9751 11.7349 2.0251 11.4766 2.1251 11.2266C2.2251 10.9766 2.36676 10.7599 2.5501 10.5766L10.5751 2.55156C10.7751 2.35156 10.9959 2.20573 11.2376 2.11406C11.4793 2.0224 11.7334 1.97656 12.0001 1.97656C12.2668 1.97656 12.5251 2.0224 12.7751 2.11406C13.0251 2.20573 13.2418 2.35156 13.4251 2.55156L21.4501 10.5766C21.6501 10.7599 21.7959 10.9766 21.8876 11.2266C21.9793 11.4766 22.0251 11.7349 22.0251 12.0016C22.0251 12.2682 21.9793 12.5224 21.8876 12.7641C21.7959 13.0057 21.6501 13.2266 21.4501 13.4266L13.4251 21.4516C13.2418 21.6349 13.0251 21.7766 12.7751 21.8766C12.5251 21.9766 12.2668 22.0266 12.0001 22.0266ZM12.0001 20.0266L20.0251 12.0016L12.0001 3.97656L3.9751 12.0016L12.0001 20.0266ZM11.0001 13.0016H13.0001V7.00156H11.0001V13.0016ZM12.0001 16.0016C12.2834 16.0016 12.5209 15.9057 12.7126 15.7141C12.9043 15.5224 13.0001 15.2849 13.0001 15.0016C13.0001 14.7182 12.9043 14.4807 12.7126 14.2891C12.5209 14.0974 12.2834 14.0016 12.0001 14.0016C11.7168 14.0016 11.4793 14.0974 11.2876 14.2891C11.0959 14.4807 11.0001 14.7182 11.0001 15.0016C11.0001 15.2849 11.0959 15.5224 11.2876 15.7141C11.4793 15.9057 11.7168 16.0016 12.0001 16.0016Z"
                fill="#EA0707"
            />
        </g>
    </svg>
);

const WarningIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.5 21L12 3L22.5 21H1.5ZM4.575 19H19.425L12 6L4.575 19ZM12 18C12.2833 18 12.521 17.904 12.713 17.712C12.905 17.52 13.0007 17.2827 13 17C13 16.7167 12.904 16.479 12.712 16.287C12.52 16.095 12.2827 15.9993 12 16C11.7167 16 11.479 16.096 11.287 16.288C11.095 16.48 10.9993 16.7173 11 17C11 17.2833 11.096 17.521 11.288 17.713C11.48 17.905 11.7173 18.0007 12 18ZM11 15H13V10H11V15Z"
            fill="#F1A817"
        />
    </svg>
);

const InfoIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20ZM11 17H13V11H11V17ZM11 9H13V7H11V9Z"
            fill="#000000"
        />
    </svg>
);

const WaitIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_665_13633" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_665_13633)">
            <path
                d="M15.3 16.7L16.7 15.3L13 11.6V7H11V12.4L15.3 16.7ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2167 20 16.1042 19.2208 17.6625 17.6625C19.2208 16.1042 20 14.2167 20 12C20 9.78333 19.2208 7.89583 17.6625 6.3375C16.1042 4.77917 14.2167 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 14.2167 4.77917 16.1042 6.3375 17.6625C7.89583 19.2208 9.78333 20 12 20Z"
                fill="#050505"
            />
        </g>
    </svg>
);

// Store original toast methods
const originalToast = {
    success: toast.success,
    error: toast.error,
    warning: toast.warning,
    info: toast.info,
};

// Override toast methods with styled versions
toast.success = (message, customConfig = {}) => {
    return originalToast.success(
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <SuccessIcon />
            <div className="toast-message">{message}</div>
        </div>,
        {
            ...defaultConfig,
            style: {
                ...defaultConfig.style,
                ...defaultStyle,
                color: "#18A800",
            },
            ...customConfig,
        }
    );
};

toast.error = (message, customConfig = {}) => {
    return originalToast.error(
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <ErrorIcon />
            <div className="toast-message">{message}</div>
        </div>,
        {
            ...defaultConfig,
            style: {
                ...defaultConfig.style,
                ...defaultStyle,
                color: "#EA0707",
            },
            ...customConfig,
        }
    );
};

toast.warning = (message, customConfig = {}) => {
    return originalToast.warning(
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <WarningIcon />
            <div className="toast-message">{message}</div>
        </div>,
        {
            ...defaultConfig,
            style: {
                ...defaultConfig.style,
                ...defaultStyle,
                color: "#F1A817",
            },
            ...customConfig,
        }
    );
};

toast.info = (message, customConfig = {}) => {
    return originalToast.info(
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <InfoIcon />
            <div className="toast-message">{message}</div>
        </div>,
        {
            ...defaultConfig,
            style: {
                ...defaultConfig.style,
                ...defaultStyle,
                color: "#000000",
            },
            ...customConfig,
        }
    );
};

toast.wait = (message, customConfig = {}) => {
    const { subMessage, ...restConfig } = customConfig;
    return originalToast.info(
        <div style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "auto", marginBottom: "auto" }}>
            <WaitIcon />
            <div className="toast-message">
                <div style={{ marginBottom: subMessage ? "4px" : "0" }}>{message}</div>
                {subMessage && <div style={{ color: "#5D5D5D" }}>{subMessage}</div>}
            </div>
        </div>,
        {
            ...defaultConfig,
            style: {
                ...defaultConfig.style,
                ...defaultStyle,
                color: "#050505",
            },
            className: "custom-toast-container wait-toast-container",
            ...restConfig,
        }
    );
};

export { toast };
