import { useContext, useState, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
// import { useGoogleLogin } from "@react-oauth/google";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import AuthLayout from "@/components/AuthLayout";
// import { ReactComponent as Logo } from "@/assets/image/icons/fuse landing page/fuse-logo.svg";
import { ReactComponent as HidePassword } from "@/assets/image/icons/hide.svg";
// import GoogleIcon from "@/assets/image/icons/google.svg";
import Text from "@/components/Text";
// import { COGNITO_GOOGLE_DEFAULT_PASSWORD } from "@/utils/constants";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function SignIn() {
    const { kompassNavigate } = useKompassRouter();
    const authContext = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const captchaRef = useRef(null);
    const [isLogging, setIsLogging] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const rememberMe = true;
    const [hasAttemptedInput, setHasAttemptedInput] = useState(false);

    const handleKeyEnter = async (e) => {
        if (e.key === "Enter") {
            signIn();
        }
    };

    const forgotPassword = async () => {
        try {
            kompassNavigate("/reset");
        } catch (err) {
            toast.error(err.message, { theme: "colored" });
        }
    };

    // const gotoSignUp = () => {
    //     kompassNavigate("/signup");
    // };

    const isEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(input);
    };

    const signIn = async () => {
        setErrorMessage(null);
        const captchaToken = await captchaRef.current.executeAsync();
        try {
            setIsLogging(true);
            await authContext.signInWithEmail(
                email.toLowerCase(),
                password,
                captchaToken
                    ? {
                          captcha: captchaToken,
                      }
                    : null,
                rememberMe
            );
            setIsLogging(false);
        } catch (err) {
            setIsLogging(false);
            console.log(err);
            if (err instanceof Error) {
                if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
                    // toast.error('Recaptcha verification failed', { theme: "colored" })
                    setErrorMessage("Recaptcha verification failed");
                } else if (err.message.includes("Missing Captcha Value")) {
                    // toast.error('Missing Captcha Value', { theme: "colored" })
                    setErrorMessage("Missing Captcha Value");
                } else if (err.message.includes("User doesn't exist")) {
                    // toast.error("User doesn't exist", { theme: "colored" })
                    setErrorMessage("User doesn't exist");
                } else if (err.message.includes("Password attempts exceeded")) {
                    // toast.error("User doesn't exist", { theme: "colored" })
                    setErrorMessage("Password attempts exceeded. Try again later");
                } else {
                    // toast.error(err.message, { theme: "colored" })
                    setErrorMessage(err.message);
                }
                captchaRef.current.reset();
            }
        }
    };

    // const googleSignin = useGoogleLogin({
    //     onSuccess: async (codeResponse) => {
    //         setErrorMessage(null);
    //         const captchaToken = await captchaRef.current.executeAsync();
    //         try {
    //             setIsLogging(true);
    //             const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: `Bearer  ${codeResponse.access_token}`,
    //                     Accept: "application/json",
    //                 },
    //             });
    //             const _resData = await response.json();
    //             await authContext.signInWithEmail(
    //                 _resData.email,
    //                 COGNITO_GOOGLE_DEFAULT_PASSWORD,
    //                 captchaToken
    //                     ? {
    //                           captcha: captchaToken,
    //                       }
    //                     : null,
    //                 rememberMe
    //             );
    //             setIsLogging(false);

    //             // gotoHome();
    //         } catch (err) {
    //             setIsLogging(false);
    //             if (err instanceof Error) {
    //                 if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
    //                     // toast.error('Recaptcha verification failed', { theme: "colored" })
    //                     setErrorMessage("Recaptcha verification failed");
    //                 } else if (err.message.includes("Missing Captcha Value")) {
    //                     // toast.error('Missing Captcha Value', { theme: "colored" })
    //                     setErrorMessage("Missing Captcha Value");
    //                 } else if (err.message.includes("User doesn't exist")) {
    //                     // toast.error("User doesn't exist", { theme: "colored" })
    //                     setErrorMessage("User doesn't exist");
    //                 } else {
    //                     // toast.error(err.message, { theme: "colored" })
    //                     setErrorMessage(err.message);
    //                 }
    //                 captchaRef.current.reset();
    //             }
    //         }
    //     },
    //     onError: (error) => console.log("Login Failed:", error),
    // });

    return (
        <AuthLayout>
            <Text variant="Header4" className=" mt-20 !text-2xl text-start">
                Welcome back.
            </Text>
            <Text variant="Header4" className="!text-2xl" data-qa="signin-page-auth-title">
                Log in to your account below.
            </Text>
            <div className="text-dgray-6 text-base mt-4 mb-10">Connect with decision makers globally to scale your business.</div>
            <div className="md:w-[90%] flex flex-col">
                {/* <Button
                    onClick={googleSignin}
                    className="google-button !shadow-none my-10 !py-2 !h-[46px] hover:!bg-dgray-50"
                    style={{ textTransform: "capitalize" }}
                    disabled={isLogging}
                >
                    <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="GoogleIcon" />
                    <div className="text-[#050505] text-sm font-medium font-['Inter'] capitalize leading-tight tracking-tight">
                        Login via Google
                    </div>
                </Button> */}

                <div className="relative">
                    {email && !isEmail(email) && (
                        <span className="absolute bottom-full left-0 text-xs text-red-500">Please enter a valid email address</span>
                    )}
                    <div className=" auth-input-container mb-5">
                        <input
                            data-qa="signin-email-input"
                            onChange={(e) => setEmail(e.target.value)}
                            className={`auth-input h-[46px] ${email && !isEmail(email) ? "border border-red-500" : "border"}`}
                            placeholder="Email address"
                            disabled={isLogging ? true : false}
                            onKeyDown={handleKeyEnter}
                            autoComplete="on"
                        />
                    </div>
                </div>
                <div className="relative">
                    {hasAttemptedInput && password.length === 0 && (
                        <span className="absolute bottom-full left-0 text-xs text-red-500">Password required</span>
                    )}
                    <div className="auth-input-container">
                        <input
                            data-qa="signin-password-input"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                if (!hasAttemptedInput) {
                                    setHasAttemptedInput(true);
                                }
                            }}
                            className={`auth-input h-[46px] ${hasAttemptedInput && password.length === 0 ? "border border-red-500" : "border"}`}
                            placeholder="Password"
                            type={!showPassword ? "password" : "text"}
                            disabled={isLogging ? true : false}
                            onKeyDown={handleKeyEnter}
                            autoComplete="on"
                        />
                        <span
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            <HidePassword
                                fill={showPassword ? "#E7436A" : "#929292"}
                                className="absolute transform -translate-y-1/2 top-1/2 right-4"
                            />
                        </span>
                    </div>
                </div>
                <div className="flex justify-end w-full mt-2 select-none text-primary-teal text-sm font-medium font-['Inter'] leading-tight">
                    <p className="cursor-pointer hover:opacity-85" onClick={forgotPassword} data-qa="forgot-password-link">
                        Forgot password?
                    </p>
                </div>
                <div className="relative flex flex-row items-center justify-center w-full mt-2">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_KOMPASSAI_RECAPTCHA_SITE_KEY} size="invisible" ref={captchaRef} />
                </div>
                {errorMessage && (
                    <div className="text-sm text-red-500" data-qa="signin-error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
                <Button
                    data-qa="signin-submit-button"
                    disabled={!isEmail(email) || (isLogging ? true : false) || password.length === 0}
                    onClick={signIn}
                    style={{ textTransform: "capitalize" }}
                    className="submit-button !h-[46px] !mt-8 hover:opacity-80"
                >
                    {isLogging ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                    Sign in
                </Button>

                {/* <div className="mt-6 flex w-full justify-center text-dgray-6 text-sm  font-['Inter'] leading-tight">
                    Don’t have an account?
                    <span
                        onClick={gotoSignUp}
                        data-qa="signup-link"
                        className="ml-1 text-primary-teal hover:opacity-85 font-medium cursor-pointer select-none"
                    >
                        Sign Up
                    </span>
                </div> */}

                <div className="text-sm mt-12 font-normal font-['Inter'] w-full text-dgray-6 ">
                    By logging in, I agree to the{" "}
                    <Link
                        to="/terms-and-conditions"
                        className="text-primary-teal cursor-pointer font-medium select-none hover:opacity-85"
                        data-qa="terms-of-service-link"
                    >
                        Terms of service
                    </Link>{" "}
                    and{" "}
                    <Link
                        to="/privacy-policy"
                        data-qa="privacy-policy-link"
                        className="text-primary-teal font-medium cursor-pointer select-none hover:opacity-85"
                    >
                        Privacy policy
                    </Link>
                    . I also agree to receive emails and communication relating to FuseAI services and offers.
                </div>
            </div>
            {/* <div className="flex items-center justify-center font-Inter gap-1 mt-4 text-[12px] md:text-[14px]">
                <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                <label htmlFor="rememberMe">Remember Me</label>
            </div> */}
        </AuthLayout>
    );
}
