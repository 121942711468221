// TopOpportunitiesComponent.jsx
import Highcharts from "highcharts";
import React, { useEffect, useState, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";

export default function TopOpportunitiesComponent({ heading, chartData = [], periodFilter, setPeriodFilter }) {
    const [anchorPeriodEl, setAnchorPeriodEl] = useState(null);
    const [openPeriodFilter, setOpenPeriodFilter] = useState(false);
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);

    // Utility function to ensure label is a string
    const ensureString = (value) => (typeof value === "string" ? value : value !== undefined && value !== null ? String(value) : "");

    useEffect(() => {
        const renderChart = () => {
            const data = {
                data: chartData?.map((item) => item.value) || [],
                categories:
                    chartData?.map((item, i) => {
                        const label = ensureString(item.label);
                        // Show all labels if the period is "THIS_YEAR"
                        if (periodFilter === "THIS_YEAR") return label;

                        // If there are 8 or fewer items, show all labels
                        if (chartData.length <= 8) return label || "--";

                        // For 9 to 16 items, show every other label and the last one
                        if (chartData.length <= 16) {
                            return i % 2 === 0 || i === chartData.length - 1 ? label : "--";
                        }

                        // For more than 16 items, show every 4th label and the last one
                        return i % 4 === 0 || i === chartData.length - 1 ? label : "--";
                    }) || [],
            };

            const chartOptions = {
                chart: {
                    type: "column",
                    spacingBottom: 30,
                    height: 300, // Adjust as needed or make responsive
                },
                title: {
                    text: "",
                },
                plotOptions: {
                    column: {
                        color: "#9AB2FF",
                        borderRadius: 5,
                        states: {
                            hover: {
                                color: "#2D7A89",
                                brightness: 0.1,
                            },
                        },
                    },
                },
                xAxis: {
                    categories: data.categories,
                    labels: {
                        rotation: 0,
                        style: {
                            color: "#929292",
                            fontSize: "0.775rem",
                            fontFamily: "Inter",
                        },
                    },
                    lineColor: "#E8E7E7",
                },
                yAxis: {
                    title: {
                        text: "",
                    },
                    gridLineColor: "#E7E7E7",
                    gridLineDashStyle: "Dash",
                    tickColor: "#E8E7E7",
                    labels: {
                        style: {
                            color: "#929292",
                            fontSize: "0.775rem",
                            fontFamily: "Inter",
                        },
                    },
                    allowDecimals: false,
                },
                legend: { enabled: false },
                credits: {
                    enabled: false,
                },
                series: [
                    {
                        name: "Top Opportunities",
                        data: data.data,
                        color: "#F4F3F0",
                    },
                ],
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 600,
                            },
                            chartOptions: {
                                chart: {
                                    height: 200,
                                },
                                xAxis: {
                                    labels: {
                                        style: {
                                            fontSize: "0.65rem",
                                        },
                                    },
                                },
                                yAxis: {
                                    labels: {
                                        style: {
                                            fontSize: "0.65rem",
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            };

            // Destroy previous chart if it exists
            if (chartRef.current) {
                chartRef.current.destroy();
            }

            // Render the chart
            chartRef.current = Highcharts.chart(chartContainerRef.current, chartOptions);

            // Handle window resize to reflow the chart
            const handleResize = () => {
                if (chartRef.current) {
                    chartRef.current.reflow();
                }
            };

            window.addEventListener("resize", handleResize);

            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy();
                }
                window.removeEventListener("resize", handleResize);
            };
        };

        renderChart();
    }, [chartData, periodFilter]); // Ensure dependencies are accurate

    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="inline-flex w-full shrink basis-0 flex-col items-start justify-start gap-4">
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className="text-[#050505] text-base font-semibold font-['Inter'] shrink grow basis-0">{heading}</div>

                    <div className="flex items-center justify-start gap-4">
                        <button
                            className="flex items-center gap-1 text-sm font-medium text-[#2D7A89]"
                            onClick={(e) => {
                                setAnchorPeriodEl(e.currentTarget);
                                setOpenPeriodFilter((cur) => !cur);
                            }}
                        >
                            <div className="font-Inter">{filterOptions[periodFilter]}</div>
                            <ExpandMoreIcon
                                className={`relative h-4 w-4 transition-transform duration-200 ${openPeriodFilter ? "rotate-180" : ""}`}
                                color="inherit"
                            />
                        </button>
                    </div>
                </div>
                <div id="topOpportunities" className="w-full" ref={chartContainerRef}></div>
            </div>
            <FilterPopup
                anchorEl={anchorPeriodEl}
                open={openPeriodFilter}
                handleClose={(value) => {
                    if (value) {
                        setPeriodFilter(value);
                    }
                    setAnchorPeriodEl(null);
                    setOpenPeriodFilter(false);
                }}
            />
        </div>
    );
}
