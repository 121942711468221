import React, { useState, useMemo, useEffect } from "react";
import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";
import {
    CompanyIcon,
    VisitsIcon,
    ConfidenceIntervalIcon,
    LocationsIcon,
    CountryIcon,
    LevelIcon,
    DesignationIcon,
    IndustryIcon,
    PersonIcon,
    EmployeeRangeIcon,
} from "./analytics/Icon";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Box,
    Typography,
    TableBody,
    styled,
    TableCell,
    Pagination,
    PaginationItem,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import LinkedinIcon from "@/assets/image/search/linkedin.svg";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

// --------------------
// 1) Styled components
// --------------------
const StyledHeadCell = styled(TableCell)(({ highlight }) => ({
    backgroundColor: "#f4f3f0",
    fontWeight: "bold",
    userSelect: "none",
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis",
    cursor: "pointer",
    // ...(highlight && {
    //     backgroundColor: "#e0e0e0",
    // }),
    "&:last-child": {
        borderRight: "none",
    },
}));

const StyledBodyCell = styled(TableCell)(({ highlight }) => ({
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis",
    // ...(highlight && {
    //     backgroundColor: "#fafafa",
    // }),
    "&:last-child": {
        borderRight: "none",
    },
}));

// --------------------
// 3) Column definitions
//    We'll transform your tableCells[currentTab] into a "columns" array
//    that includes sorting. For example, each column can be "sortable: true"
//    if you want users to sort on that column
// --------------------
const tableCells = {
    [WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS]: [
        { id: "companyName", label: "Company", sortable: true, icon: <CompanyIcon /> },
        { id: "locationCountry", label: "Country", sortable: true, icon: <CountryIcon /> },
        { id: "jobLevel", label: "Department", sortable: true, icon: <LevelIcon /> },
        { id: "jobRole", label: "Job Level", sortable: true, icon: <DesignationIcon /> },
        { id: "companyIndustry", label: "Industry", sortable: true, icon: <IndustryIcon /> },
        { id: "count", label: "Visits", sortable: true, icon: <VisitsIcon /> },
        { id: "confidenceInterval", label: "Confidence Interval", sortable: false, icon: <ConfidenceIntervalIcon /> },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES]: [
        { id: "industry", label: "Industry", sortable: true, icon: <IndustryIcon /> },
        { id: "companies", label: "Companies", sortable: true, icon: <CompanyIcon /> },
        { id: "countries", label: "Countries", sortable: true, icon: <CountryIcon /> },
        { id: "locations", label: "Locations", sortable: true, icon: <LocationsIcon /> },
        { id: "visitors", label: "Visitors", sortable: true, icon: <VisitsIcon /> },
        { id: "count", label: "Visits", sortable: true, icon: <PersonIcon /> },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES]: [
        { id: "employeeRange", label: "Employees Range", sortable: true, icon: <EmployeeRangeIcon /> },
        { id: "companies", label: "Companies", sortable: true, icon: <CompanyIcon /> },
        { id: "countries", label: "Countries", sortable: true, icon: <CountryIcon /> },
        { id: "visitors", label: "Visitors", sortable: true, icon: <VisitsIcon /> },
        { id: "count", label: "Visits", sortable: true, icon: <PersonIcon /> },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS]: [
        { id: "employeeLocation", label: "Employee Location", sortable: true, icon: <LocationsIcon /> },
        { id: "companies", label: "Companies", sortable: true, icon: <CompanyIcon /> },
        { id: "industries", label: "Industries", sortable: true, icon: <IndustryIcon /> },
        { id: "visitors", label: "Visitors", sortable: true, icon: <VisitsIcon /> },
        { id: "count", label: "Visits", sortable: true, icon: <PersonIcon /> },
    ],
};

// --------------------
// 4) Main component
// --------------------
export default function ResultsTable({
    data,
    currentTab,
    filterProps,
    setFilterProps,
    checkedItemsInReport,
    setCheckedItemsInReport,
    rowsPerPage = 10, // you can make this configurable
}) {
    const [imageLoaded, setImageLoaded] = useState({});
    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    // Hover state (for highlight)
    const [hoveredCol, setHoveredCol] = useState(null);
    // Pagination
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
    }, [currentTab]);

    // --- Image load / error handlers ---
    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleImageError = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: false,
        }));
    };

    // --- Checkbox logic ---
    const setProspectFilters = (item) => {
        if (!filterProps.some((obj) => obj.companyName === item.companyName)) {
            const tempFilterProps = [...filterProps, item];
            setFilterProps(tempFilterProps);
        } else {
            const tempFilterProps = filterProps.filter((obj) => obj.companyName !== item.companyName);
            setFilterProps(tempFilterProps);
        }
    };

    const handleCheckBox = (item) => {
        setProspectFilters(item);

        if (
            currentTab === WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS &&
            checkedItemsInReport?.some((i) => i.companyName === item.companyName)
        ) {
            setCheckedItemsInReport((prev) => prev.filter((i) => i.companyName !== item.companyName));
        } else if (
            currentTab === WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES &&
            checkedItemsInReport?.some((i) => i.industry === item.industry)
        ) {
            setCheckedItemsInReport((prev) => prev.filter((i) => i.industry !== item.industry));
        } else if (
            currentTab === WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES &&
            checkedItemsInReport?.some((i) => i.employeeRange === item.employeeRange)
        ) {
            setCheckedItemsInReport((prev) => prev.filter((i) => i.employeeRange !== item.employeeRange));
        } else if (
            currentTab === WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS &&
            checkedItemsInReport?.some((i) => i.employeeLocation === item.employeeLocation)
        ) {
            setCheckedItemsInReport((prev) => prev.filter((i) => i.employeeLocation !== item.employeeLocation));
        } else {
            setCheckedItemsInReport((prev) => [...prev, item]);
        }
    };

    const checkAllRecords = (event) => {
        if (event.target.checked) {
            // Check all
            data.forEach((item) => setProspectFilters(item));
            setCheckedItemsInReport(data);
        } else {
            // Uncheck all
            setFilterProps([]);
            setCheckedItemsInReport([]);
        }
    };

    const isInputChecked = (item) => {
        if (!checkedItemsInReport) return false;

        switch (currentTab) {
            case WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS:
                return checkedItemsInReport.some((i) => i.companyName === item.companyName);

            case WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES:
                return checkedItemsInReport.some((i) => i.industry === item.industry);

            case WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES:
                return checkedItemsInReport.some((i) => i.employeeRange === item.employeeRange);

            case WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS:
                return checkedItemsInReport.some((i) => i.employeeLocation === item.employeeLocation);

            default:
                return false;
        }
    };

    // --- Confidence Interval styling ---
    const getConfidenceIntervalStyle = (val) => {
        let displayValue = val;
        let bgClass = "";

        switch (val) {
            case "high":
                bgClass = "bg-[#EAF1EE] text-[#1C4E38]";
                displayValue = "very high";
                break;
            case "moderate":
                bgClass = "bg-[#ECEEFF] text-[#2E3DAA]";
                displayValue = "high";
                break;
            case "low":
                bgClass = "bg-[#FFEEDE] text-[#FF782C]";
                displayValue = "moderate";
                break;
            case "very low":
                bgClass = "bg-[#FFC8C8] text-[#BD0000]";
                displayValue = "low";
                break;
            default:
                break;
        }

        return (
            <Box
                component="span"
                sx={{
                    px: 1.5,
                    py: 0.5,
                    borderRadius: "4px",
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                }}
                className={bgClass}
            >
                {displayValue}
            </Box>
        );
    };

    // --- Sorting logic ---
    const handleSort = (columnId) => {
        // If user clicks on a new column, default to 'asc'
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    // Sort the data. We only sort if `orderBy` is set. If it's empty, we skip sorting.
    const sortedData = useMemo(() => {
        if (!orderBy) return data;
        return [...data].sort((a, b) => {
            const valA = a[orderBy] ?? "";
            const valB = b[orderBy] ?? "";

            // Convert numeric strings to numbers if needed
            const isNumericA = !isNaN(parseFloat(valA));
            const isNumericB = !isNaN(parseFloat(valB));

            if (isNumericA && isNumericB) {
                // Numeric sort
                return order === "asc" ? parseFloat(valA) - parseFloat(valB) : parseFloat(valB) - parseFloat(valA);
            } else {
                // String sort (case-insensitive)
                const cmpA = String(valA).toLowerCase();
                const cmpB = String(valB).toLowerCase();
                if (cmpA < cmpB) return order === "asc" ? -1 : 1;
                if (cmpA > cmpB) return order === "asc" ? 1 : -1;
                return 0;
            }
        });
    }, [data, order, orderBy]);

    // --- Pagination logic ---
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const currentPageData = sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // For "select all" in the current page only, you'd check
    // if all rows in the *current page* are selected:
    // But the original logic is checking the entire dataset.
    // If you want "select all in current page," comment out below
    // and write a different condition. We'll keep your original logic.
    const isAllChecked = checkedItemsInReport?.length === data?.length && data?.length > 0;

    // 2) Sorting icons (like in BillingTable)
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    // Build columns from tableCells
    const columns = tableCells[currentTab] || [];

    return (
        <>
            {/* TABLE CONTAINER */}
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    borderTop: "1px solid #e7e7e7",
                    borderLeft: "1px solid #e7e7e7",
                    borderRight: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        marginTop: "8px",
                        marginBottom: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#aaa",
                        borderRadius: "6px",
                    },
                }}
            >
                <Table
                    sx={{
                        borderCollapse: "separate",
                        tableLayout: "fixed",
                        minWidth: 950,
                        width: "100%",
                    }}
                >
                    {/* TABLE HEAD */}
                    <TableHead>
                        <TableRow>
                            {/* Checkbox (select all) Column */}
                            <StyledHeadCell
                                key="checkbox-column"
                                highlight={hoveredCol === -1}
                                onMouseEnter={() => setHoveredCol(-1)}
                                onMouseLeave={() => setHoveredCol(null)}
                                sx={{ width: 36, padding: 0 }} // no sorting on checkbox
                            >
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={checkAllRecords}
                                    sx={{
                                        padding: 0,
                                        "&.Mui-checked": {
                                            color: "#2D7A89",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 16,
                                        },
                                        marginLeft: 1.2,
                                    }}
                                />
                            </StyledHeadCell>

                            {columns.map((col, colIdx) => (
                                <StyledHeadCell
                                    key={col.id}
                                    highlight={hoveredCol === colIdx}
                                    onMouseEnter={() => setHoveredCol(colIdx)}
                                    onMouseLeave={() => setHoveredCol(null)}
                                    onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                    style={{
                                        cursor: col.sortable ? "pointer" : "default",
                                        userSelect: "none",
                                    }}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box display="flex" alignItems="center" gap={1}>
                                            {col.icon}
                                            <Typography fontSize={14} fontWeight="medium" color="#5d5d5d" sx={{ lineHeight: "21px" }}>
                                                {col.label}
                                            </Typography>
                                        </Box>
                                        {renderSortingIcons(col)}
                                    </Box>
                                </StyledHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* TABLE BODY */}
                    <TableBody>
                        {currentPageData.length > 0 ? (
                            currentPageData.map((item, rowIndex) => {
                                // We must find the *original* index for image load logic
                                // sortedData is a copy of data, but rowIndex is the index in "currentPageData"
                                // We'll rely on the item itself or use data.indexOf(item).
                                const originalIndex = data.indexOf(item);

                                return (
                                    <TableRow key={originalIndex}>
                                        {/* Checkbox Column */}
                                        <StyledBodyCell key={`checkbox-${originalIndex}`} sx={{ width: 36, padding: 0 }}>
                                            <Checkbox
                                                checked={isInputChecked(item)}
                                                onChange={() => handleCheckBox(item)}
                                                sx={{
                                                    padding: 0,
                                                    "&.Mui-checked": {
                                                        color: "#2D7A89",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: 16,
                                                    },
                                                    marginLeft: 1.2,
                                                }}
                                            />
                                        </StyledBodyCell>

                                        {/* Other Columns */}
                                        {columns.map((col, cellIndex) => {
                                            // Map from the "id" to the actual property
                                            const cellValue = item[col.id];

                                            // Special Cases
                                            if (col.id === "companyName") {
                                                // CompanyName + Logo
                                                return (
                                                    <StyledBodyCell key={`${originalIndex}-${cellIndex}`}>
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            {/* 24×24 Logo or fallback */}
                                                            <Box
                                                                sx={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: imageLoaded[originalIndex] ? "white" : "#a99ce8",
                                                                    textTransform: "uppercase",
                                                                    overflow: "hidden",
                                                                    flexShrink: 0,
                                                                }}
                                                            >
                                                                <img
                                                                    src={`https://logo.clearbit.com/${item.companyWebsite}`}
                                                                    alt="Company Logo"
                                                                    onClick={() =>
                                                                        item.companyWebsite &&
                                                                        window.open("https://" + item.companyWebsite, "_blank")
                                                                    }
                                                                    onLoad={() => handleImageLoad(originalIndex)}
                                                                    onError={() => handleImageError(originalIndex)}
                                                                    style={{
                                                                        display: imageLoaded[originalIndex] ? "block" : "none",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "contain",
                                                                        cursor: item.companyWebsite ? "pointer" : "default",
                                                                    }}
                                                                />
                                                                {!imageLoaded[originalIndex] && (
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: "12px",
                                                                            fontWeight: "bold",
                                                                            color: "#fff",
                                                                        }}
                                                                    >
                                                                        {item.companyName?.[0]}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                            {/* Actual truncated name */}
                                                            <p
                                                                onClick={() =>
                                                                    item.companyWebsite &&
                                                                    window.open("https://" + item.companyWebsite, "_blank")
                                                                }
                                                                title={cellValue}
                                                                className={`text-[#050505] text-sm font-medium font-['Inter'] leading-[21px] capitalize truncate overflow-hidden whitespace-nowrap ${item.companyWebsite ? "cursor-pointer" : "cursor-default"}`}
                                                            >
                                                                {item.companyName || ""}
                                                            </p>
                                                            {item?.companyLinkedin && (
                                                                <Link target="_blank" to={"https://" + item?.companyLinkedin}>
                                                                    <ReactSVG src={LinkedinIcon} style={{ height: 13, width: 13 }} />
                                                                </Link>
                                                            )}
                                                        </Box>
                                                    </StyledBodyCell>
                                                );
                                            } else if (col.id === "confidenceInterval") {
                                                // Confidence Interval with color-coded styling
                                                return (
                                                    <StyledBodyCell key={`${originalIndex}-${cellIndex}`}>
                                                        {getConfidenceIntervalStyle(cellValue)}
                                                    </StyledBodyCell>
                                                );
                                            } else {
                                                // Default truncated text cell
                                                return (
                                                    <StyledBodyCell key={`${originalIndex}-${cellIndex}`}>
                                                        <div
                                                            className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px] truncate overflow-hidden whitespace-nowrap"
                                                            title={cellValue}
                                                            style={{
                                                                textTransform:
                                                                    currentTab !== WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES
                                                                        ? "capitalize"
                                                                        : "none",
                                                            }}
                                                        >
                                                            {cellValue}
                                                        </div>
                                                    </StyledBodyCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <StyledBodyCell
                                    colSpan={columns.length + 1} // +1 for the extra checkbox column
                                    sx={{ textAlign: "center" }}
                                >
                                    <Typography sx={{ fontSize: "14px", color: "#999" }}>
                                        {data?.length === 0 ? "Nothing to display" : "Loading..."}
                                    </Typography>
                                </StyledBodyCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* PAGINATION */}
            {sortedData.length > rowsPerPage && (
                <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
}
