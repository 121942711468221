import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography,
    Pagination,
    PaginationItem,
    Switch,
    MenuItem,
    Select,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import { styled } from "@mui/system";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import useAIEngagement from "@/hooks/useAIEngagement";
import useTranscripts from "@/hooks/crm/useTranscripts";

import { ReactComponent as ConnectionsIcon } from "@/assets/image/settings/link.svg";
import { ReactComponent as MessagesIcon } from "@/assets/image/settings/messages.svg";
import { ReactComponent as PersonIcon } from "@/assets/image/settings/person.svg";
import { ReactComponent as StatusIcon } from "@/assets/image/settings/status.svg";
import { ReactComponent as ConnectionIcon } from "@/assets/image/settings/connections.svg";
import { ReactComponent as LimitsIcon } from "@/assets/image/settings/limits.svg";
import { ReactComponent as ActionsIcon } from "@/assets/image/settings/actions.svg";
import { ReactComponent as MailIcon } from "@/assets/image/settings/mail.svg";
import { ReactComponent as HealthIcon } from "@/assets/image/settings/heart_check.svg";
import { ReactComponent as DeliverIcon } from "@/assets/image/settings/delivered.svg";
import { ReactComponent as RecordIcon } from "@/assets/image/settings/graphic_eq.svg";
import { ReactComponent as MeetingIcon } from "@/assets/image/settings/meeting.svg";

import ThreeDotsMenu from "./ThreeDotsMenu";
import AddNewAccountDialog from "./AddNewAccountDialog";
import UpdateCapacityDialog, { CAPACITY_MODAL_TYPE } from "./UpdateCapacityDialog";

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];
const getRandomColor = () => defaultColor[Math.floor(Math.random() * defaultColor.length)];

// ------------------ STYLED CELLS ------------------
const StyledHeadCell = styled(TableCell)(({ highlight }) => ({
    backgroundColor: "#f4f3f0",
    fontFamily: "Inter",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#e0e0e0",
    // }),
}));

const StyledBodyCell = styled(TableCell)(({ highlight }) => ({
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    fontFamily: "Inter",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#fafafa",
    // }),
}));

// ------------------ DUMMY DATA ------------------

// “LinkedIn Accounts” columns
const linkedinColumns = [
    { id: "name", label: "Name", sortable: true, width: 240 },
    { id: "status", label: "Status", sortable: true, width: 160 },
    { id: "sendingLimits", label: "Sending Limits", sortable: false, width: 200 },
    { id: "connections", label: "Connections", sortable: true, width: 180 },
    { id: "useThisAccount", label: "Use This Account", sortable: false, width: 180 },
    { id: "actions", label: "Actions", sortable: false, width: 120 },
];

// “Email Accounts” columns
const emailColumns = [
    { id: "account", label: "Account", sortable: true, width: 280 },
    // { id: "connectionStatus", label: "Connection Status", sortable: false, width: 150 },
    { id: "sendingLimits", label: "Sending Limits", sortable: false, width: 170 },
    { id: "mailboxHealth", label: "Mailbox Health", sortable: false, width: 170 },
    { id: "useThisMailbox", label: "Use This Mailbox", sortable: false, width: 180 },
    { id: "managedDeliverability", label: "Managed Deliverability", sortable: false, width: 220 },
    { id: "recordMeeting", label: "Record Meeting", sortable: false, width: 180 },
    { id: "meetingType", label: "Meeting Type", sortable: false, width: 160 },
    { id: "actions", label: "Actions", sortable: false, width: 120 },
];

export default function AccountsComponent({ searchParams }) {
    const {
        getEmailsData,
        getUnipileAuthData,
        getUnipileReConnectLink,
        updateUnipileAccount,
        disconnectUnipileAccount,
        getEmailHealth,
        updateMailBoxStatus,
        removeEmailConnection,
        fetchDataFromCallBack,
    } = useAIEngagement();
    const getEmailHealthRef = useRef();
    getEmailHealthRef.current = getEmailHealth;
    const fetchDataFromCallBackRef = useRef();
    fetchDataFromCallBackRef.current = fetchDataFromCallBack;

    const [isVisibleNewAccountDialog, setVisibleNewAccountDialog] = useState(false);
    const [isVisibleUpdateCapacityDialog, setVisiblUpdateCapacityDialog] = useState(false);
    const [dataForUpdateCapacity, setDataForUpdateCapacity] = useState(null);
    const [imageLoadStatus, setImageLoadStatus] = useState({});

    const handleImageLoad = (id, status) => {
        setImageLoadStatus((prev) => ({
            ...prev,
            [id]: status, // Update the status of the specific image
        }));
    };

    // 0 = Email Accounts, 1 = LinkedIn Accounts
    const [activeTab, setActiveTab] = useState(0);

    // Sorting states
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");

    // Pagination
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    // Hovered column index for highlight
    const [hoveredCol, setHoveredCol] = useState(null);

    // Connected accounts data
    const [linkedinAccountsData, setLinkedinAccountsData] = useState([]);
    const [isLinkedinStatusUpdating, setIsLinkedinStatusUpdating] = useState(false);
    const [emailsData, setEmailsData] = useState([]);
    const [isEmailStatusUpdating, setIsEmailStatusUpdating] = useState(false);

    // Decide which columns/data to show based on activeTab
    const columns = activeTab === 0 ? emailColumns : linkedinColumns;
    const data = activeTab === 0 ? emailsData : linkedinAccountsData;

    // Fetch Linkedin Accounts Data
    const {
        data: allLinkedinAccountsData,
        isLoading: isAllLinkedinAccountsDataLoading,
        isFetching: isAllLinkedinAccountsDataFetching,
        refetch: refetchAllLinkedinAccountsData,
    } = useQuery({
        queryKey: ["allLinkedinAccounts"],
        queryFn: () => getUnipileAuthData(),
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        if (!allLinkedinAccountsData?.data) return;

        const data = allLinkedinAccountsData.data;

        const updatedLinkedinData = data?.accounts
            ?.filter((i) => i.provider === "LINKEDIN")
            ?.map((item) => ({
                ...item,
                name: `${item?.firstName} ${item?.lastName}`,
                status: item?.isConnected ? "Connected" : "Disconnected",
                sendingLimits: {
                    messages: `${item?.availableLimit?.messages || 0}/${item?.dailyUsageLimits?.messages || 0}`,
                    invitations: `${item?.availableLimit?.invitations || 0}/${item?.dailyUsageLimits?.invitations || 0}`,
                },
                connections: item?.connectionsAmount,
                useThisAccount: !!item?.isActive,
            }));

        setLinkedinAccountsData(updatedLinkedinData);
    }, [allLinkedinAccountsData]);

    // Fetch Emails Data
    const {
        data: allEmailsData,
        isLoading: isAllEmailsDataLoading,
        isFetching: isAllEmailsDataFetching,
        refetch: refetchAllEmailsData,
    } = useQuery({
        queryKey: ["allEmails"],
        queryFn: () => getEmailsData(),
        refetchOnWindowFocus: true,
    });

    const { getUserCalendars, updateCalendarRecording, createCalendar, deleteCalendar } = useTranscripts();

    // Add query for fetching calendars
    const {
        data: userCalendars,
        isLoading: isCalendarsLoading,
        refetch: refetchCalendars,
    } = useQuery({
        queryKey: ["userCalendars"],
        queryFn: () => getUserCalendars(),
        refetchOnWindowFocus: true,
    });

    // Update emails data effect to include calendar information
    useEffect(() => {
        if (!allEmailsData?.data || !userCalendars?.data) return;

        const fetchUpdatedEmailsData = async () => {
            const data = allEmailsData?.data?.length > 0 ? allEmailsData?.data : [];
            const calendarData = userCalendars?.data || [];

            const updatedEmailsData = await Promise.all(
                data?.map(async (item) => {
                    const emailHealth = await getEmailHealthRef.current(item.emailAddress);
                    const emailHealthData = emailHealth?.data;
                    let emailHealthPoint = 0;

                    if (emailHealthData?.blacklisted) {
                        emailHealthPoint = 1;
                    } else if (emailHealthData?.ageGrade === "A") {
                        emailHealthPoint = 5;
                    } else if (emailHealthData?.hasDMARC && emailHealthData?.hasSPF) {
                        emailHealthPoint = 3;
                    }

                    // Find matching calendar
                    const calendar = calendarData.find((cal) => cal.email === item.emailAddress);

                    return {
                        ...item,
                        type: item?.account,
                        account: item?.emailAddress,
                        useThisMailbox: !!item?.isActive,
                        emailHealth: emailHealthPoint,
                        recordMeeting: !!calendar?.shouldRecord,
                        calendarId: calendar?.calendarId,
                        meetingType: calendar?.meetingType || "Internal",
                    };
                })
            );

            setEmailsData(updatedEmailsData);
        };

        fetchUpdatedEmailsData();
    }, [allEmailsData, userCalendars]);

    // Add calendar management functions
    const toggleRecordMeeting = async (email) => {
        try {
            if (!email.calendarId) {
                // Create new calendar and enable recording
                await createCalendar(email.account);
                await refetchCalendars();
                // After calendar is created, enable recording
                const calendars = await getUserCalendars();
                const newCalendar = calendars.data.find((cal) => cal.email === email.account);
                if (newCalendar) {
                    await updateCalendarRecording(newCalendar.calendarId, true);
                }
            } else {
                // Toggle existing calendar recording
                await updateCalendarRecording(email.calendarId, !email.recordMeeting);
            }
            await refetchCalendars();
        } catch (error) {
            toast.error("Failed to update recording settings", {
                theme: "colored",
            });
        }
    };

    const handleMeetingTypeChange = async (email, newType) => {
        try {
            await updateCalendarRecording(email.calendarId, email.recordMeeting, newType);
            await refetchCalendars();
            toast.success("Meeting type updated successfully");
        } catch (error) {
            toast.error("Failed to update meeting type", {
                theme: "colored",
            });
        }
    };

    // Handle Search Params
    const navigate = useNavigate();

    useEffect(() => {
        const sendCallBackData = async (type, code, scope) => {
            try {
                const response = await fetchDataFromCallBackRef.current({
                    url: `${type === "gmail" ? "/mail/gmail/oauth2callback" : "/mail/outlook/oauth2callback"}?code=${code}&scope=${scope}`,
                });
                if (response?.data?.statusCode === 200) {
                    toast.success("Email account added successfully", {
                        theme: "colored",
                    });
                    await refetchAllEmailsData();
                } else {
                    toast.error("Failed to add email account. Please try again", {
                        theme: "colored",
                    });
                }
            } catch (error) {
                console.log("Error: ", error);
            }
        };

        if (searchParams.toString()) {
            const linkedinStatus = searchParams.get("linkedin");

            if (linkedinStatus) {
                linkedinStatus === "success"
                    ? toast.success("Linkedin account added successfully")
                    : toast.error("Failed to add Linkedin account. Please try again");
                setActiveTab(1);
                refetchAllLinkedinAccountsData();
            } else {
                sendCallBackData(searchParams.get("url") ? "gmail" : "outlook", searchParams.get("code"), searchParams.get("scope"));
            }

            const newUrl = window.location.pathname;
            window.history.replaceState(null, "", newUrl);
        }
    }, [searchParams, navigate, refetchAllEmailsData, refetchAllLinkedinAccountsData]);

    // Handle sort
    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    // Sort the data if the column is numeric (for this demo)
    const sortedList = useMemo(() => {
        if (!orderBy) return data;
        return [...data].sort((a, b) => {
            const valA = a[orderBy];
            const valB = b[orderBy];
            // If it’s numeric, compare numerically. Otherwise, skip or adapt logic.
            return order === "asc" ? valA - valB : valB - valA;
        });
    }, [data, order, orderBy]);

    // Paginated data
    const totalPages = Math.ceil(sortedList?.length / rowsPerPage);
    const currentRows = sortedList.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // Tab change
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
        setPage(1); // reset pagination when switching
        setOrderBy("");
    };

    // Pagination change
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    // Render sort arrows
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    // Render head icons
    const renderHeadIcons = (col) => {
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    position: "relative",
                    top: "-1px",
                }}
            >
                {(col.id === "name" || col.id === "account") && <PersonIcon />}
                {col.id === "status" && <StatusIcon />}
                {(col.id === "connections" || col.id === "connectionStatus") && <ConnectionIcon />}
                {col.id === "sendingLimits" && <LimitsIcon />}
                {col.id === "actions" && <ActionsIcon />}
                {(col.id === "useThisAccount" || col.id === "useThisMailbox") && <MailIcon />}
                {col.id === "mailboxHealth" && <HealthIcon />}
                {col.id === "managedDeliverability" && <DeliverIcon />}
                {col.id === "recordMeeting" && <RecordIcon />}
                {col.id === "meetingType" && <MeetingIcon />}
            </Box>
        );
    };

    const toggleLinkedinAccountStatus = async (accountId, isActive) => {
        setIsLinkedinStatusUpdating(true);
        const response = await updateUnipileAccount(accountId, { isActive: !isActive });

        if (response?.status) {
            toast.success("Account updated successfully", { theme: "colored" });
            await refetchAllLinkedinAccountsData();
        } else {
            toast.error("Failed to update account, please try again", {
                theme: "colored",
            });
        }

        setIsLinkedinStatusUpdating(false);
    };

    const toggleEmailAccountStatus = async (email) => {
        setIsEmailStatusUpdating(true);

        const response = await updateMailBoxStatus({
            url: `/mail/gmail/active?is_gmail=${email?.type === "gmail" ? "true" : "false"}&isActive=${!email?.isActive}&emailAddress=${email?.account}`,
        });

        if (response?.status) {
            toast.success("Account updated successfully", { theme: "colored" });
            await refetchAllEmailsData();
        } else {
            toast.error("Failed to update account, please try again", {
                theme: "colored",
            });
        }

        setIsEmailStatusUpdating(false);
    };

    const disconnectLinkedinAccount = async (accountId) => {
        const response = await disconnectUnipileAccount(accountId);

        if (response?.status) {
            toast.success("Account disconnected successfully", { theme: "colored" });
            await refetchAllLinkedinAccountsData();
        } else {
            toast.error("Failed to disconnect account, please try again", {
                theme: "colored",
            });
        }
    };

    const disconnectEmailAccount = async (email) => {
        const response = await removeEmailConnection({
            url: `/mail/${email?.type === "gmail" ? "gmail" : "outlook"}/disconnect?emailAddress=${email?.account}`,
        });

        if (response?.status) {
            // Only delete calendar if email disconnection was successful
            if (email.calendarId) {
                try {
                    await deleteCalendar(email.calendarId);
                } catch (error) {
                    console.error("Error deleting calendar:", error);
                    // Still show success since email was disconnected
                    toast.warning("Email disconnected but failed to remove calendar settings", {
                        theme: "colored",
                    });
                    await refetchAllEmailsData();
                    return;
                }
            }

            toast.success("Account disconnected successfully", { theme: "colored" });
            await refetchAllEmailsData();
        } else {
            toast.error("Failed to disconnect account, please try again", {
                theme: "colored",
            });
        }
    };

    return (
        <Box sx={{ fontFamily: "Inter" }}>
            <Box sx={{ padding: "24px", alignItems: "center", justifyContent: "space-between", display: "flex" }}>
                <div className="flex flex-col items-start justify-start gap-2">
                    <div className="text-[#050505] text-base font-semibold font-Inter">Accounts</div>
                    <div className="text-[#6d6d6d] text-sm font-medium font-Inter leading-[21px]">
                        Create a new account or view details of an existing one
                    </div>
                </div>
                <Button
                    onClick={() => setVisibleNewAccountDialog(true)}
                    className="h-[32px] flex items-center justify-center gap-2 self-center rounded-[8px] bg-accents-mainBlack px-[12px] py-[6px] hover:opacity-80 transition-opacity"
                >
                    <span className="font-Inter text-[14px] font-medium capitalize text-white">Add account</span>
                </Button>
            </Box>
            {/* ------------------ TABS ------------------ */}
            <Box
                sx={{
                    borderBottom: "1px solid #E7E7E7",
                    width: "100%",
                    paddingLeft: "24px",
                    borderTop: "1px solid #E7E7E7",
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    disableRipple
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#050505",
                            height: "3px",
                        },
                        "& .MuiTabs-flexContainer": {
                            alignItems: "center",
                        },
                    }}
                >
                    <Tab
                        label="Email Accounts"
                        disableRipple
                        sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            margin: "0 8px 0 0",
                            padding: "0",
                            color: activeTab === 0 ? "#050505" : "#6D6D6D",
                            minWidth: "auto",
                            "&.Mui-selected": {
                                color: "#050505",
                            },
                        }}
                    />
                    <Tab
                        label="LinkedIn Accounts"
                        disableRipple
                        sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            margin: "0 8px 0 0",
                            padding: "0",
                            color: activeTab === 1 ? "#050505" : "#6D6D6D",
                            minWidth: "auto",
                            "&.Mui-selected": {
                                color: "#050505",
                            },
                        }}
                    />
                </Tabs>
            </Box>
            {/* ------------------ TABLE ------------------ */}
            <Box sx={{ padding: "24px" }}>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: "none",
                        marginLeft: "0px",
                        borderTop: "1px solid #e7e7e7",
                        borderLeft: "1px solid #e7e7e7",
                        borderRight: "1px solid #e7e7e7",
                        borderRadius: "8px",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                            height: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "#f0f0f0",
                            marginTop: "8px",
                            marginBottom: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#aaa",
                            borderRadius: "6px",
                        },
                    }}
                >
                    <Table
                        sx={{
                            borderCollapse: "separate",
                            tableLayout: "fixed",
                            minWidth: activeTab === 0 ? 1200 : 800,
                        }}
                    >
                        {/* ------------------ Table Head ------------------ */}
                        <TableHead>
                            <TableRow>
                                {columns.map((col, colIdx) => (
                                    <StyledHeadCell
                                        key={col.id}
                                        sx={{ width: col.width }}
                                        highlight={hoveredCol === colIdx}
                                        onMouseEnter={() => setHoveredCol(colIdx)}
                                        onMouseLeave={() => setHoveredCol(null)}
                                        onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                        style={{
                                            cursor: col.sortable ? "pointer" : "default",
                                            userSelect: "none",
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                            <Box display="flex" alignItems="center" gap="8px">
                                                {renderHeadIcons(col)}
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight="medium"
                                                    color="#5d5d5d"
                                                    sx={{
                                                        lineHeight: "21px",
                                                        fontFamily: "Inter",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {col.label}
                                                </Typography>
                                            </Box>
                                            {renderSortingIcons(col)}
                                        </Box>
                                    </StyledHeadCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        {/* ------------------ Table Body ------------------ */}
                        <TableBody>
                            {currentRows?.length > 0 ? (
                                currentRows.map((item, index) => (
                                    <TableRow key={index}>
                                        {columns.map((col) => {
                                            const val = item?.[col.id];

                                            if (activeTab === 0) {
                                                // Email tab
                                                switch (col.id) {
                                                    case "sendingLimits":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <div
                                                                    onClick={() => {
                                                                        setDataForUpdateCapacity({
                                                                            emailAddress: item.emailAddress,
                                                                            emailType: item.type,
                                                                            initialCapacity: item.dailyCapacity,
                                                                            capacityModalType: CAPACITY_MODAL_TYPE.EMAILS_MESSAGES,
                                                                        });
                                                                        setVisiblUpdateCapacityDialog(true);
                                                                    }}
                                                                    className="flex items-center gap-[8px] w-fit px-[8px] py-[4px] rounded-[4px] border border-[#E7E7E7] cursor-pointer"
                                                                >
                                                                    <MessagesIcon />
                                                                    <span className="main-text-black-500 !text-[12px]">
                                                                        {item.dailyCapacity}
                                                                    </span>
                                                                </div>
                                                            </StyledBodyCell>
                                                        );
                                                    case "mailboxHealth":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Typography fontSize={14} color="#050505">
                                                                    {item.emailHealth}/5
                                                                </Typography>
                                                            </StyledBodyCell>
                                                        );
                                                    case "useThisMailbox":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Switch
                                                                    sx={{
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            color: "white", // Thumb color when checked
                                                                        },
                                                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                                            opacity: 100,
                                                                            backgroundColor: "#2D7A89", // Background when checked
                                                                        },
                                                                    }}
                                                                    checked={val}
                                                                    disabled={
                                                                        isAllEmailsDataLoading ||
                                                                        isAllEmailsDataFetching ||
                                                                        isEmailStatusUpdating
                                                                    }
                                                                    onChange={() => {
                                                                        toggleEmailAccountStatus(item);
                                                                    }}
                                                                />
                                                            </StyledBodyCell>
                                                        );
                                                    case "recordMeeting":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Switch
                                                                    sx={{
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            color: "white",
                                                                        },
                                                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                                            opacity: 100,
                                                                            backgroundColor: "#2D7A89",
                                                                        },
                                                                    }}
                                                                    checked={val}
                                                                    onChange={() => toggleRecordMeeting(item)}
                                                                    disabled={isCalendarsLoading}
                                                                />
                                                            </StyledBodyCell>
                                                        );
                                                    case "managedDeliverability":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Select value={val} size="small">
                                                                    <MenuItem value="Enabled">Enabled</MenuItem>
                                                                    <MenuItem value="Disabled">Disabled</MenuItem>
                                                                </Select>
                                                            </StyledBodyCell>
                                                        );
                                                    case "meetingType":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Select
                                                                    value={val || "Internal"}
                                                                    size="small"
                                                                    onChange={(e) => handleMeetingTypeChange(item, e.target.value)}
                                                                    disabled={!item.recordMeeting || isCalendarsLoading}
                                                                >
                                                                    <MenuItem value="Internal">Internal</MenuItem>
                                                                    <MenuItem value="External">External</MenuItem>
                                                                    <MenuItem value="All">All</MenuItem>
                                                                </Select>
                                                            </StyledBodyCell>
                                                        );
                                                    case "actions":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <ThreeDotsMenu onClickHandler={() => disconnectEmailAccount(item)} />
                                                            </StyledBodyCell>
                                                        );
                                                    default:
                                                        return (
                                                            <StyledBodyCell key={col.id} title={val}>
                                                                <span className="main-text-black-500">{val}</span>
                                                            </StyledBodyCell>
                                                        );
                                                }
                                            } else {
                                                // LinkedIn tab
                                                switch (col.id) {
                                                    case "name":
                                                        return (
                                                            <StyledBodyCell key={col.id} title={val}>
                                                                <div className="flex items-center gap-[8px]">
                                                                    <div className="flex h-[20px] w-[20px] cursor-pointer shrink-0 rounded-[4px] overflow-hidden">
                                                                        {item?.pictureUrl && imageLoadStatus[item?.providerId] !== false ? (
                                                                            <img
                                                                                src={item?.pictureUrl}
                                                                                alt="Profile"
                                                                                className="relative object-contain object-center"
                                                                                onLoad={() => handleImageLoad(item?.providerId, true)}
                                                                                onError={() => handleImageLoad(item?.providerId, false)}
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        `https://www.linkedin.com/in/${item?.providerId}`,
                                                                                        "_blank"
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    display: imageLoadStatus[item?.providerId]
                                                                                        ? "block"
                                                                                        : "none",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <span
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        `https://www.linkedin.com/in/${item?.providerId}`,
                                                                                        "_blank"
                                                                                    )
                                                                                }
                                                                                className="flex items-center justify-center text-[12px] text-accents-mainBlack"
                                                                                style={{
                                                                                    backgroundColor: getRandomColor(),
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                }}
                                                                            >
                                                                                {item?.firstName?.charAt(0)?.toUpperCase() ?? ""}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <p className="main-text-black-500">{val}</p>
                                                                        <p className="font-Inter text-[12px] text-[#6D6D6D]">{val}</p>
                                                                    </div>
                                                                </div>
                                                            </StyledBodyCell>
                                                        );
                                                    case "status":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                {val === "Connected" ? (
                                                                    <div className="w-fit px-[8px] py-[4px] rounded-[4px] bg-[#EAF1EE] text-[12px] text-[#1C4E38]">
                                                                        Connected
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex items-center gap-[8px]">
                                                                        <div className="w-fit px-[8px] py-[4px] rounded-[4px] bg-[#E7E7E7] text-[12px] text-[#050505]">
                                                                            Disconnected
                                                                        </div>
                                                                        <button
                                                                            onClick={async () => {
                                                                                const { data: authLink } = await getUnipileReConnectLink();
                                                                                if (authLink) {
                                                                                    window.open(authLink, "_blank", "noopener,noreferrer");
                                                                                } else {
                                                                                    console.error("Failed to retrieve the auth link.");
                                                                                }
                                                                            }}
                                                                            className="text-[12px] text-[#1C4E38] font-medium"
                                                                        >
                                                                            Retry
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </StyledBodyCell>
                                                        );
                                                    case "sendingLimits":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <div className="flex gap-[16px]">
                                                                    <div
                                                                        onClick={() => {
                                                                            setDataForUpdateCapacity({
                                                                                accountId: item.accountId,
                                                                                initialCapacity: item.dailyUsageLimits,
                                                                                availableCapacity: item.availableLimit,
                                                                                capacityModalType: CAPACITY_MODAL_TYPE.EMAILS_MESSAGES,
                                                                            });
                                                                            setVisiblUpdateCapacityDialog(true);
                                                                        }}
                                                                        className="flex items-center gap-[8px] w-fit px-[8px] py-[4px] rounded-[4px] border border-[#E7E7E7] cursor-pointer"
                                                                    >
                                                                        <MessagesIcon />
                                                                        <span className="main-text-black-500 !text-[12px]">
                                                                            {val.messages}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => {
                                                                            setDataForUpdateCapacity({
                                                                                accountId: item.accountId,
                                                                                initialCapacity: item.dailyUsageLimits,
                                                                                availableCapacity: item.availableLimit,
                                                                                capacityModalType: CAPACITY_MODAL_TYPE.INVITATIONS,
                                                                            });
                                                                            setVisiblUpdateCapacityDialog(true);
                                                                        }}
                                                                        className="flex items-center gap-[8px] w-fit px-[8px] py-[4px] rounded-[4px] border border-[#E7E7E7] cursor-pointer"
                                                                    >
                                                                        <ConnectionsIcon />
                                                                        <span className="main-text-black-500 !text-[12px]">
                                                                            {val.invitations}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </StyledBodyCell>
                                                        );
                                                    case "useThisAccount":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <Switch
                                                                    sx={{
                                                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                                                            color: "white", // Thumb color when checked
                                                                        },
                                                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                                            opacity: 100,
                                                                            backgroundColor: "#2D7A89", // Background when checked
                                                                        },
                                                                    }}
                                                                    checked={val}
                                                                    disabled={
                                                                        isAllLinkedinAccountsDataLoading ||
                                                                        isAllLinkedinAccountsDataFetching ||
                                                                        isLinkedinStatusUpdating
                                                                    }
                                                                    onChange={() => {
                                                                        toggleLinkedinAccountStatus(item.accountId, item.isActive);
                                                                    }}
                                                                />
                                                            </StyledBodyCell>
                                                        );
                                                    case "actions":
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <ThreeDotsMenu
                                                                    onClickHandler={() => disconnectLinkedinAccount(item.accountId)}
                                                                />
                                                            </StyledBodyCell>
                                                        );
                                                    default:
                                                        return (
                                                            <StyledBodyCell key={col.id}>
                                                                <span className="main-text-black-500">{val}</span>
                                                            </StyledBodyCell>
                                                        );
                                                }
                                            }
                                        })}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns?.length}
                                        align="center"
                                        sx={{
                                            py: 3,
                                            color: "gray",
                                            borderRight: "1px solid #e7e7e7",
                                            borderBottom: "1px solid #e7e7e7",
                                        }}
                                    >
                                        {data?.length === 0 && (isAllLinkedinAccountsDataLoading || isAllEmailsDataLoading) && "Loading..."}
                                        {data?.length === 0 &&
                                            !isAllLinkedinAccountsDataLoading &&
                                            !isAllEmailsDataLoading &&
                                            "Nothing to display"}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* ------------------ Pagination ------------------ */}
            {sortedList?.length > rowsPerPage && (
                <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}

            <AddNewAccountDialog open={isVisibleNewAccountDialog} handleNo={() => setVisibleNewAccountDialog(false)} />
            <UpdateCapacityDialog
                dataForUpdateCapacity={dataForUpdateCapacity}
                refetchAllEmailsData={refetchAllEmailsData}
                refetchAllLinkedinAccountsData={refetchAllLinkedinAccountsData}
                open={isVisibleUpdateCapacityDialog}
                handleClose={() => {
                    setVisiblUpdateCapacityDialog(false);
                    setDataForUpdateCapacity(null);
                }}
            />
        </Box>
    );
}
