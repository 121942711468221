import { ConnectionsIcon, InvitationsIcon } from "@/components/icons/InboxIcon";

export const LeftMenu = ({ activeTab, handleChangeTab }) => {
    return (
        <div className="h-full border-r border-[#E7E7E7] bg-white col-span-1 sm:mb-1">
            <div className="border-b border-[#E7E7E7] flex items-center px-[24px] py-[16px] h-[76px]">
                <p className="text-[14px] font-medium font-Inter text-accents-mainBlack">My Network</p>
            </div>
            <div className="flex flex-col px-[24px] py-[12px] gap-[12px] mailList_container">
                <button
                    className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] rounded-[8px] ${activeTab === "connections" ? "bg-[#F4F3F0]" : ""}`}
                    onClick={() => handleChangeTab("connections")}
                >
                    <ConnectionsIcon />
                    <span className="text-[14px] font-medium font-Inter text-accents-mainBlack">Connections</span>
                </button>
                <button
                    className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] rounded-[8px] ${activeTab === "invitations" ? "bg-[#F4F3F0]" : ""}`}
                    onClick={() => handleChangeTab("invitations")}
                >
                    <InvitationsIcon />
                    <span className="text-[14px] font-medium font-Inter text-accents-mainBlack">Pending Requests</span>
                </button>
            </div>
        </div>
    );
};
