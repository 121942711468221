import React, { useEffect, useState } from "react";
import SelectComponent from "@/components/crm/opportunities/SelectComponent";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { ReactComponent as EmptyStateIcon } from "@/assets/image/crm/timeline/notes-empty-state.svg";
import NotesDetailsModal from "./NotesDetailsModal";
import CreateNoteModal from "./CreateNoteModal";
import { useParams } from "react-router-dom";
import useNotes from "@/hooks/crm/useNotes";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";

export default function TimelineNotes() {
    const [openModal, setOpenModal] = useState(false);
    const [isOpenCreateNoteModal, setIsOpenCreateNoteModal] = useState(false);
    const [refreshUI, setRefreshUI] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notesData, setNotesData] = useState([]);
    const [activeNote, setActiveNote] = useState(null);

    const { getNotesByOpportunityId } = useNotes();

    const { opportunityId } = useParams();

    useEffect(() => {
        const fetchNotes = async (id) => {
            setLoading(true);
            try {
                const response = await getNotesByOpportunityId(id);
                if (response) {
                    setNotesData(response.data);
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };
        if (opportunityId) {
            fetchNotes(opportunityId);
        }
    }, [opportunityId]);

    useEffect(() => {
        if (opportunityId) {
            const fetchNotes = async (id) => {
                try {
                    const response = await getNotesByOpportunityId(id);
                    if (response) {
                        setNotesData(response.data);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchNotes(opportunityId);
        }
    }, [refreshUI]);

    return (
        <div>
            <CreateNoteModal open={isOpenCreateNoteModal} setOpen={setIsOpenCreateNoteModal} setRefreshUI={setRefreshUI} />
            <NotesDetailsModal setRefreshUI={setRefreshUI} open={openModal} setOpen={setOpenModal} rowData={activeNote} />
            <div className="border-b flex items-center border-stone-250 p-3 py-5">
                <p className="font-semibold">Notes</p>
                {/* <div className="flex gap-4 ml-auto">
                    <SelectComponent bgColor="#fff" sx={{ width: "130px" }} options={["op1"]} placeholder="All" />
                    <SelectComponent bgColor="#fff" options={["op1"]} placeholder="This Week" sx={{ width: "130px" }} />
                </div> */}
            </div>
            <div className="p-3 text-sm">
                <button
                    onClick={() => setIsOpenCreateNoteModal(true)}
                    className="py-1.5 px-4 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100"
                >
                    <AddOutlinedIcon className="!w-5 !h-4" />
                    <p className="text-xs font-medium">Add Note</p>
                </button>
                {loading ? (
                    <div className="text-black h-64 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                    </div>
                ) : notesData.length === 0 ? (
                    <EmptyState />
                ) : (
                    notesData.map((note) => (
                        <div key={note.id} className="border text-sm border-stone-250 rounded-lg p-3 mt-4">
                            <div className="flex items-center gap-4">
                                <p className="font-semibold">{note.title}</p>
                                <p className="text-dgray-6 ml-auto">{dayjs(note.createdAt).format("hh:mmA")}</p>
                            </div>
                            <div className="mt-3">
                                <p>{note.description}</p>
                                <button
                                    onClick={() => {
                                        setOpenModal(true);
                                        setActiveNote(note);
                                    }}
                                    className="flex gap-2 items-center bg-stone-100 py-1 px-2 rounded-md hover:bg-dgray-200 cursor-pointer mt-3"
                                >
                                    <CheckCircleOutlineOutlinedIcon className="!w-5 !h-4" />
                                    <p>View Details</p>
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

const EmptyState = () => {
    return (
        <div className="flex flex-col justify-center items-center h-64">
            <p className="font-semibold text-lg mt-5">No notes yet! </p>
            <p className="text-dgray-6 mt-3">Every great idea starts with a note. Create your first one now!</p>
        </div>
    );
};
