import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useKompassRouter from "@/hooks/useKompassRouter";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { kompassColors } from "theme/palette";
import { formatTimestamp } from "@/utils/common";
import TeamsLogo from "@/assets/image/crm/transcript/teams-logo.svg";
import MeetLogo from "@/assets/image/crm/transcript/meet-logo.svg";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "#fff",
    "&:hover": {
        backgroundColor: "#F3F4F6",
    },
    "& .MuiTableCell-root": {
        padding: "12px",
        borderBottom: `1px solid ${kompassColors.light}`,
        fontWeight: 500,
        fontSize: 14,
        color: "#050505",
        fontFamily: "Inter",
        "&:not(:last-child)": {
            borderRight: `1px solid ${kompassColors.light}`,
        },
        cursor: "pointer",
    },
}));

export default function TranscriptsTableRow({ row }) {
    const { kompassNavigate } = useKompassRouter();

    const getPlatformInfo = (source) => {
        if (!source) {
            return {
                name: "Unknown",
                logo: null,
            };
        }

        if (source.startsWith("microsoft")) {
            return {
                name: "Microsoft Teams",
                logo: TeamsLogo,
            };
        }
        if (source.startsWith("google")) {
            return {
                name: "Google Meet",
                logo: MeetLogo,
            };
        }
        return {
            name: source,
            logo: null,
        };
    };

    const platform = getPlatformInfo(row?.source);

    return (
        <StyledTableRow hover onClick={() => kompassNavigate(`/crm/transcript/${row._id}`)}>
            <TableCell>{row.title}</TableCell>
            <TableCell>{formatTimestamp(row.length)}</TableCell>
            <TableCell>{row.participants?.join(", ") || "No participants"}</TableCell>
            <TableCell>{row.language || "Unknown"}</TableCell>
            <TableCell>
                <div className="flex items-center gap-2">
                    {platform.logo && <img src={platform.logo} alt="" className="w-5 h-5" />}
                    <span>{platform.name}</span>
                </div>
            </TableCell>
            <TableCell>{dayjs(row.createdAt).format("D MMMM, YYYY")}</TableCell>
        </StyledTableRow>
    );
}
