import { InputBase } from "@mui/material";
import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import { kompassColors } from "@/theme/palette";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",
        border: "1px solid",
        borderColor: kompassColors.light,
        borderRadius: 6,
        fontSize: 13,
        fontFamily: "Inter, sans-serif",
        padding: "10px 28px 10px 14px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 6,
            borderColor: kompassColors.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const DateRange = ({ value, onChange, downIcon = false }) => {
    const [startDate, endDate] = value && value?.length ? value : [null, null];

    const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
        <div className="relative w-full">
            <BootstrapInput value={value} className={className} onClick={onClick} ref={ref} />
            {/* SVG icon for the dropdown */}
            {downIcon && (
                <svg
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                        clipRule="evenodd"
                    />
                </svg>
            )}
        </div>
    ));

    return (
        <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            customInput={<ExampleCustomInput className="w-full" />}
            onChange={(update) => {
                onChange(update);
            }}
            isClearable={true}
        />
    );
};

export default DateRange;
