import React, { useState, useEffect, useRef } from "react";
import useExportProfiles from "@/hooks/useExportProfiles";
import { toast } from "react-toastify";
import { CustomButton } from "@/components/crm/contacts/Button";

function ExportButton({ data }) {
    const [dataToExport, setDataToExport] = useState(data);
    const { createExportProfile } = useExportProfiles();
    const createExportProfileRef = useRef();
    createExportProfileRef.current = createExportProfile;

    // Function to convert the array of objects to a CSV string
    function convertArrayToCSV(array) {
        const headers = Object.keys(array[0]).join(","); // Get headers
        const rows = array.map((obj) => Object.values(obj).join(",")); // Get rows
        return [headers, ...rows].join("\n");
    }

    // Function to download CSV file
    async function downloadCSV(csvContent) {
        const filename = new Date().getTime() + "_data.csv";
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        const response = await createExportProfileRef.current({
            filename,
            recordCount: data.length,
            csv_content: data,
            created_at: new Date().getTime(),
        });
        if (!response) {
            toast.error("Failed to export data");
        }
    }

    const startDownload = async () => {
        if (data && data.length) {
            const csvContent = convertArrayToCSV(dataToExport);
            await downloadCSV(csvContent);
        }
    };

    useEffect(() => {
        setDataToExport(data);
    }, [data]);
    return (
        <CustomButton
            onClick={startDownload}
            title="Export Profiles"
            height={36}
            width={164}
            px={24}
            py={12}
            img={true}
            imgSrc={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1094_6516" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                        <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_1094_6516)">
                        <path
                            d="M9.25 16V8.875L7.0625 11.0625L6 10L10 6L14 10L12.9375 11.0625L10.75 8.875V16H9.25ZM4 6V4.5C4 4.0875 4.14694 3.73438 4.44083 3.44063C4.73472 3.14688 5.08806 3 5.50083 3H14.5058C14.9186 3 15.2708 3.14688 15.5625 3.44063C15.8542 3.73438 16 4.0875 16 4.5V6H14.5V4.5H5.5V6H4Z"
                            fill="white"
                        />
                    </g>
                </svg>
            }
            titleTextSize={14}
            titleFontWeight={500}
        />
    );
}

export default ExportButton;
