// HomeSceneV2.jsx
import { useEffect, useState, useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import CreditsUsedByProductComponent from "@/components/home/CreditsUsedByProductComponent";
import SummaryComponent from "@/components/home/SummaryComponent";
import useBillingStore from "@/store/useBillingStore";
import useDashboard from "@/hooks/useDashboard";
import useOpportunities from "@/hooks/crm/useOpportunities";
import TopOpportunitiesComponent from "@/components/home/TopOpportunitiesComponent";
import NavigationBar from "@/components/NavigationBar";
import { HOME_PAGE_TABS } from "@/utils/constants";
import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import NameAndCountLabel from "@/components/NameAndCountLabel";
import LineChart from "@/components/LineChart";
import { filterOptions, options, webIntentFilterOptions } from "@/components/popup/FilterPopup";
import MainTitleBar from "@/components/MainTitleBar";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

// Import AI Engagement Components and Hooks
import Overview from "@/components/ai_engagement/globalAnalyticsTab/Overview";
import EmailSentChart from "@/components/ai_engagement/globalAnalyticsTab/EmailSentChart";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress } from "@mui/material";

export default function HomeSceneV2() {
    const { userInfo, isTeamMember, isTeamOwner, updateUserInfo } = useContext(AuthContext);
    const { getDashboard, getMyTeamInfo } = useDashboard();
    const { selectedPrices, setRedirect, redirect } = useBillingStore();
    const { getTopTenOpportunities } = useOpportunities();
    const { getAnalytics } = useIPsDeanonymization();
    const [data, setData] = useState(null);
    const [teamInfo, setTeamInfo] = useState({});
    const [totalCreditsUsedPeriodFilter, setTotalCreditsUsedPeriodFilter] = useState("THIS_MONTH");
    const [totalCreditsUsedProductFilter, setTotalCreditsUsedProductFilter] = useState("All Products");
    const [creditsUsedByProductPeriodFilter, setCreditsUsedByProductPeriodFilter] = useState("THIS_MONTH");
    const [creditsUsedByPeriod, setCreditsUsedByPeriod] = useState([]);
    const [creditsUsedByPeriodByProduct, setCreditsUsedByPeriodByProduct] = useState([]);
    const [opportunityTimePeriod, setOpportunityTimePeriod] = useState("THIS_MONTH");
    const [newProfilesVsReturingProfilesPeriod, setNewProfilesVsReturingProfilesPeriod] = useState("week");
    const [totalSiteViewPeriod, setTotalSiteViewPeriod] = useState("week");
    const [webIntentInfo, setWebIntentInfo] = useState({});
    const [opportunitiesInfo, setOpportunitiesInfo] = useState([]);
    const [newProfilesVsReturingProfilesData, setNewProfilesVsReturingProfilesData] = useState({});
    const [totalSiteViewChartData, setTotalSiteViewChartData] = useState([]);
    const [tabOpen, setTabOpen] = useState(HOME_PAGE_TABS.WebsiteIntent);
    const [loading, setLoading] = useState({ newProfiles: true, totalSiteViews: true });

    // AI Engagement Hooks and State
    const { getGlobalAnalytics } = useAIEngagement();
    const [globalAnalyticsData, setGlobalAnalyticsData] = useState({});

    // Add these state variables alongside existing ones
    const [emailsSentPeriodFilter, setEmailsSentPeriodFilter] = useState("This Week");
    const [emailOpenRatePeriodFilter, setEmailOpenRatePeriodFilter] = useState("This Week");

    // Define filter options if not already defined
    const emailSentFilterOptions = {
        "This Week": "THIS_WEEK",
        "This Month": "THIS_MONTH",
        "Last Month": "LAST_MONTH",
        "This Year": "THIS_YEAR",
    };

    const emailOpenRateFilterOptions = {
        "This Week": "THIS_WEEK",
        "This Month": "THIS_MONTH",
        "Last Month": "LAST_MONTH",
        "This Year": "THIS_YEAR",
    };

    const getMyTeamInfoRef = useRef();
    getMyTeamInfoRef.current = getMyTeamInfo;
    const getDashboardRef = useRef();
    getDashboardRef.current = getDashboard;
    const updateUserInfoRef = useRef();
    updateUserInfoRef.current = updateUserInfo;

    const getWebIntentData = useRef();
    getWebIntentData.current = getAnalytics;

    const getOpportunitiesInfo = useRef();
    getOpportunitiesInfo.current = getTopTenOpportunities;

    const navigate = useNavigate();

    const handleTotalActionsFilterChange = async (newFilter) => {
        const response = await getDashboardRef.current({
            totalCreditsUsedPeriodFilter: newFilter,
            totalCreditsUsedProductFilter,
        });
        setTotalCreditsUsedPeriodFilter(newFilter);
        setCreditsUsedByPeriod(response?.data?.creditsUsedByPeriod);
    };

    const handleNewProfilesFilterChange = async (newFilter) => {
        const userId = userInfo?._id;
        if (!userId) return;

        const params = { userId, period: newFilter || "all" };
        const query = new URLSearchParams(params).toString();
        setLoading((prev) => ({ ...prev, newProfiles: true }));
        const response = await getWebIntentData.current({ query });
        setLoading((prev) => ({ ...prev, newProfiles: false }));

        let newProfiles = 0;
        let returingProfiles = 0;
        let newProfileChartObj = {};
        let returningProfileChartObj = {};
        let allDailyAnalytics = [];

        response?.forEach((domainObj) => {
            if (domainObj?.dailyAnalytics) allDailyAnalytics.push(domainObj.dailyAnalytics);
            if (domainObj?.overallAnalytics?.nonRepeatedIPs) newProfiles += domainObj.overallAnalytics.nonRepeatedIPs;
            if (domainObj?.overallAnalytics?.repeatedIPs) returingProfiles += domainObj.overallAnalytics.repeatedIPs;
        });

        allDailyAnalytics = allDailyAnalytics.flat();
        allDailyAnalytics?.forEach((dateObj) => {
            if (!newProfileChartObj[dateObj.day]) {
                newProfileChartObj[dateObj.day] = 0;
            }
            newProfileChartObj[dateObj.day] += dateObj.nonRepeatedIPs || 0;

            if (!returningProfileChartObj[dateObj.day]) {
                returningProfileChartObj[dateObj.day] = 0;
            }
            returningProfileChartObj[dateObj.day] += dateObj.repeatedIPs || 0;
        });

        const newProfileChartData = Object.keys(newProfileChartObj).map((date) => ({
            label: date,
            value: newProfileChartObj[date],
        }));

        const returningProfileChartData = Object.keys(returningProfileChartObj).map((date) => ({
            label: date,
            value: returningProfileChartObj[date],
        }));

        setNewProfilesVsReturingProfilesPeriod(newFilter);
        setNewProfilesVsReturingProfilesData({
            "New Profiles": newProfileChartData,
            "Returning Profile": returningProfileChartData,
        });
        setWebIntentInfo((prev) => ({ ...prev, newProfiles, returingProfiles }));
    };

    const handleTotalSiteViewsFilterChange = async (newFilter) => {
        const userId = userInfo?._id;
        if (!userId) return;

        const params = { userId, period: newFilter || "all" };
        const query = new URLSearchParams(params).toString();
        setLoading((prev) => ({ ...prev, totalSiteViews: true }));
        const response = await getWebIntentData.current({ query });
        setLoading((prev) => ({ ...prev, totalSiteViews: false }));

        let totalSiteViews = 0;
        response?.forEach((domainObj) => {
            if (domainObj?.overallAnalytics?.totalIPs) totalSiteViews += domainObj.overallAnalytics.totalIPs;
        });

        setWebIntentInfo((prev) => ({ ...prev, totalSiteViews }));

        let totalSiteViewChartObj = {};
        const allDailyAnalytics = response?.map((webObj) => webObj?.dailyAnalytics).flat();

        allDailyAnalytics?.forEach((dateObj) => {
            if (!totalSiteViewChartObj[dateObj.day]) {
                totalSiteViewChartObj[dateObj.day] = 0;
            }
            totalSiteViewChartObj[dateObj.day] += dateObj.totalIPs || 0;
        });

        const totalSiteViewChartArray = Object.keys(totalSiteViewChartObj).map((date) => ({
            label: date,
            value: totalSiteViewChartObj[date],
        }));

        setTotalSiteViewPeriod(newFilter);
        setTotalSiteViewChartData(totalSiteViewChartArray);
    };

    const totalActionsChart = useMemo(
        () => (
            <LineChart
                chartName={"totalActionsCompleted"}
                heading={"Total Credits Completed"}
                chartData={creditsUsedByPeriod}
                setPeriodFilter={setTotalCreditsUsedPeriodFilter}
                periodFilter={totalCreditsUsedPeriodFilter}
                optionsArray={options}
                optionsLabel={filterOptions}
                lineColor="#2D7A89"
                linearGradient={[0, 0, 0, 250]}
                titleSize="base"
                onFilterChange={handleTotalActionsFilterChange}
                hideLineLegend={true}
            />
        ),
        [creditsUsedByPeriod, totalCreditsUsedPeriodFilter]
    );

    const newProfilesChart = useMemo(
        () => (
            <div className="h-full">
                <LineChart
                    linearGradient={[0, 0, 0, 0]}
                    chartName={"newProfilesVsReturingProfiles"}
                    heading={"New Profiles vs Returning Profiles"}
                    multipleChartsData={newProfilesVsReturingProfilesData}
                    setPeriodFilter={setNewProfilesVsReturingProfilesPeriod}
                    periodFilter={newProfilesVsReturingProfilesPeriod}
                    optionsArray={webIntentFilterOptions}
                    lineColor="#2D7A89"
                    titleSize="xs"
                    onFilterChange={handleNewProfilesFilterChange}
                />
            </div>
        ),
        [newProfilesVsReturingProfilesData, newProfilesVsReturingProfilesPeriod]
    );

    const totalSiteViewsChart = useMemo(
        () => (
            <div className="h-full">
                <LineChart
                    linearGradient={[0, 0, 0, 0]}
                    chartName={"totalSiteViews"}
                    heading={"Total Site Views"}
                    chartData={totalSiteViewChartData}
                    setPeriodFilter={setTotalSiteViewPeriod}
                    periodFilter={totalSiteViewPeriod}
                    optionsArray={webIntentFilterOptions}
                    lineColor={"#FFCE6B"}
                    titleSize="xs"
                    onFilterChange={handleTotalSiteViewsFilterChange}
                />
            </div>
        ),
        [totalSiteViewChartData, totalSiteViewPeriod]
    );
    useEffect(() => {
        const fetchData = async () => {
            const response = await getDashboardRef.current({});
            setData(response.data);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (!data?.planInfo?.name) return;
        updateUserInfoRef.current();
    }, [data]);

    useEffect(() => {
        const fetchData = async (filters) => {
            const response = await getDashboardRef.current(filters);
            console.log("debug => API response for filters:", filters, response?.data?.creditsUsedByPeriod);
            setCreditsUsedByPeriodByProduct(response?.data?.creditsUsedByPeriodByProduct);
        };
        fetchData({
            creditsUsedByProductPeriodFilter,
        });
    }, [creditsUsedByProductPeriodFilter]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect, {
                state: Object.values(selectedPrices).filter((val) => val !== null),
            });
            setRedirect("");
        }
    }, [navigate, redirect, selectedPrices, setRedirect]);

    useEffect(() => {
        const fetchTeamData = async () => {
            const response = await getMyTeamInfoRef.current();
            setTeamInfo(response.data);
        };
        fetchTeamData();
    }, []);

    useEffect(() => {
        const fetchTopValueOpportunities = async (timeRange) => {
            const response = await getOpportunitiesInfo.current(timeRange);
            setOpportunitiesInfo(response?.data?.chartData || []);
        };
        fetchTopValueOpportunities(opportunityTimePeriod);
    }, [opportunityTimePeriod]);

    // Fetch Global Analytics Data for Fuse Engage Tab
    useEffect(() => {
        if (tabOpen !== HOME_PAGE_TABS.FuseEngage) return;

        const fetchGlobalAnalyticsData = async () => {
            try {
                const res = await getGlobalAnalytics();
                setGlobalAnalyticsData(res?.data || {});
            } catch (error) {
                console.log("Error in fetchGlobalAnalyticsData", error);
                setGlobalAnalyticsData({});
            }
        };
        fetchGlobalAnalyticsData();
    }, [tabOpen]);

    // Add these useEffects for initial data load
    useEffect(() => {
        if (userInfo?._id) {
            handleNewProfilesFilterChange(newProfilesVsReturingProfilesPeriod);
        }
    }, [userInfo?._id]); // Only run when userInfo is available

    useEffect(() => {
        if (userInfo?._id) {
            handleTotalSiteViewsFilterChange(totalSiteViewPeriod);
        }
    }, [userInfo?._id]); // Only run when userInfo is available

    useEffect(() => {
        if (userInfo?._id) {
            handleTotalActionsFilterChange(totalCreditsUsedPeriodFilter);
        }
    }, [userInfo?._id]); // Only run when userInfo is available

    return (
        <MainLayout>
            <MainTitleBar>
                {/* Title */}
                <p className="text-lg font-semibold">Welcome, {userInfo.userName || ""}</p>
            </MainTitleBar>

            <div className="bg-white">
                <BaseContainer>
                    <div className="grid gap-10 my-6 grid-cols-1">
                        {/* 1st Row */}
                        <div className="flex flex-col items-start justify-start">
                            <div className="flex flex-col lg:flex-row justify-start gap-10 w-full">
                                <SummaryComponent
                                    creditsUsage={data?.creditsUsageInCurrentSubscription ?? {}}
                                    planInfo={data?.planInfo ?? {}}
                                    teamsInfo={data?.teamsInfo ?? {}}
                                    isTeamMember={isTeamMember}
                                    isTeamOwner={isTeamOwner}
                                    teamInfo={teamInfo}
                                    navigate={navigate}
                                />
                                <CreditsUsedByProductComponent
                                    creditsData={creditsUsedByPeriodByProduct}
                                    setFilter={setCreditsUsedByProductPeriodFilter}
                                    filter={creditsUsedByProductPeriodFilter}
                                />
                            </div>
                        </div>

                        {/* 2nd Row */}
                        <div className="flex flex-col items-start justify-start">
                            <div className="flex flex-col lg:flex-row justify-between gap-10 w-full">
                                <div className="flex-1 min-w-0">{totalActionsChart}</div>
                                <div className="flex-1 min-w-0">
                                    <TopOpportunitiesComponent
                                        heading={"Top Ten Opportunities"}
                                        chartData={opportunitiesInfo}
                                        setPeriodFilter={setOpportunityTimePeriod}
                                        periodFilter={opportunityTimePeriod}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 3rd Row */}
                        <div className="flex flex-col justify-between gap-6 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
                            <NavigationBar setTabOpen={setTabOpen} tabOpen={tabOpen} />
                            <div>
                                {tabOpen === HOME_PAGE_TABS.WebsiteIntent && (
                                    <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
                                        <div className="w-full lg:w-1/4 flex flex-col gap-6">
                                            <NameAndCountLabel count={webIntentInfo?.newProfiles || 0} name={"New Profiles"} />
                                            <NameAndCountLabel count={webIntentInfo?.returingProfiles || 0} name={"Returning Profiles"} />
                                            <NameAndCountLabel count={webIntentInfo?.totalSiteViews || 0} name={"Total Site Views"} />
                                        </div>

                                        <div className="w-full lg:w-3/4 flex flex-col lg:flex-row gap-6 lg:gap-8">
                                            <div className="flex-1 min-w-0">
                                                {loading.newProfiles ? (
                                                    <div className="flex justify-center items-center h-full text-black">
                                                        <CircularProgress className="!h-8 !w-8" color="inherit" />
                                                    </div>
                                                ) : (
                                                    newProfilesChart
                                                )}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                {loading.totalSiteViews ? (
                                                    <div className="flex justify-center items-center h-full text-black">
                                                        <CircularProgress className="!h-8 !w-8" color="inherit" />
                                                    </div>
                                                ) : (
                                                    totalSiteViewsChart
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* Fuse Engage Tab Content */}
                                {tabOpen === HOME_PAGE_TABS.FuseEngage && (
                                    <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
                                        {/* Left Side: Name and Count Labels */}
                                        <div className="w-full lg:w-1/4 flex flex-col gap-6">
                                            {/* <NameAndCountLabel
                                                count={globalAnalyticsData.totalLeadsReached || 0}
                                                name="Total Leads Reached"
                                            /> */}
                                            <NameAndCountLabel count={globalAnalyticsData.totalMailSent || 0} name="Total Mail Sent" />
                                            <NameAndCountLabel count={globalAnalyticsData.uniqueOpened || 0} name="Unique Opened" />
                                            <NameAndCountLabel count={globalAnalyticsData.totalOpened || 0} name="Total Opened" />
                                            {/* <NameAndCountLabel count={globalAnalyticsData.uniqueClicked || 0} name="Unique Clicked" /> */}
                                            {/* <NameAndCountLabel count={globalAnalyticsData.uniqueReplied || 0} name="Unique Replied" /> */}
                                            {/* <NameAndCountLabel
                                                count={globalAnalyticsData.uniquePositiveReplied || 0}
                                                name="Unique Positive Replied"
                                            />
                                            <NameAndCountLabel count={globalAnalyticsData.uniqueBounced || 0} name="Unique Bounced" /> */}
                                        </div>

                                        {/* Right Side: Line Charts */}
                                        <div className="w-full lg:w-3/4 flex flex-col lg:flex-row gap-6 lg:gap-8">
                                            <div className="flex-1 min-w-0">
                                                <EmailSentChart marginTop="0px" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </BaseContainer>
            </div>
            <FuseAgentModal />
        </MainLayout>
    );
}
