import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
    },
}));

export default function ConfirmDialog({ open, handleClose, handleOK }) {
    // const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className="text-[#090c05] text-base font-semibold font-['Inter']">Are you sure you want to validate the emails?</div>
                <button onClick={handleClose} className=" flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ minWidth: "500px", padding: 0 }}>
                <div className="flex flex-row px-6 py-3 gap-4 justify-end">
                    <Button
                        className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Inter'] bg-white 2xl:px-2 hover:bg-dgray-50 transition-colors"
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    <Button
                        className="w-[80px] flex flex-row justify-center items-center px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Inter'] bg-stone-950 2xl:px-2 hover:opacity-80 transition-opacity"
                        onClick={handleOK}
                    >
                        Yes
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
