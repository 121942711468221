// CRMUploadTableRow.jsx

import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ReactComponent as SalesforceIcon } from "@/assets/image/integrations/salesforce.svg";
import { ReactComponent as HubspotIcon } from "@/assets/image/integrations/hubspot.svg";
import StatusBadge from "./StatusBadge";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { truncateText } from "@/utils/common";

function CRMUploadTableRow(props) {
    const { row, labelId } = props;
    const [rowData] = useState(row);
    const { target } = useCRMPushResultStore();

    return (
        <TableRow
            sx={{
                cursor: "pointer",
                background: "white",
                "& .MuiTableCell-root": {
                    p: "12px",
                    borderBottom: "1px solid #E7E7E7",
                    borderRight: "1px solid #E7E7E7",
                    ":last-child": {
                        borderRight: "none",
                    },
                },
                ":last-child": {
                    "& .MuiTableCell-root": {
                        borderBottom: "none",
                    },
                },
            }}
        >
            <TableCell component="th" id={labelId} scope="row" padding="none">
                <div className="flex flex-row items-center gap-2">
                    <div className="h-fit w-fit">
                        {target === "Hubspot" && <HubspotIcon className="h-[24px] w-[24px]" />}
                        {target === "Salesforce" && <SalesforceIcon className="h-[24px] w-[24px]" />}
                    </div>
                    <div className="flex flex-col">
                        <span className="text-[#050505] text-[14px] font-medium font-['Inter'] leading-[21px]">
                            {truncateText(rowData?.name, 30)}
                        </span>
                        <div className="text-[#5d5d5d] text-[12px] font-normal font-['Inter'] leading-[21px]">
                            {truncateText(rowData?.company, 40)}
                        </div>
                    </div>
                </div>
            </TableCell>

            <TableCell align="center">
                <div className="text-[#050505] text-[14px] text-left font-medium font-['Inter'] leading-[21px]">{rowData?.entity}</div>
            </TableCell>

            <TableCell align="center">
                <div className="text-[#050505] text-[14px] text-left font-medium font-['Inter'] leading-[21px]">
                    {rowData?.currentEntity}
                </div>
            </TableCell>

            <TableCell align="center">
                <StatusBadge status={rowData?.status} text={rowData?.status}>
                    {rowData?.status}
                </StatusBadge>
            </TableCell>

            <TableCell align="center">
                <div className="text-[#050505] text-[14px] font-medium font-['Inter'] leading-[21px]">{rowData?.suggestions}</div>
            </TableCell>
        </TableRow>
    );
}

export default CRMUploadTableRow;
