import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useFiles from "@/hooks/crm/useFiles";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { triggerDownload } from "../../utils";
import UploadModal from "../timeline-files/UploadModal";

export default function FileUpload({ note }) {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [activeFile, setActiveFile] = useState(null);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { uploadFile, getFiles, deleteFile, getSignedUrl } = useFiles();
    const { opportunityId } = useParams();
    const [refreshUI, setRefreshUI] = useState(false);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const res = await getFiles(note._id, "noteId");
                if (res) {
                    setUploadedFiles(res.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        if (note) {
            fetchFiles();
        }
    }, [note, refreshUI]);

    const getFileIconAndColor = (file) => {
        if (file.type.includes("image")) {
            return {
                icon: <InsertPhotoOutlinedIcon className="!h-5 !w-5" />,
                color: "green",
            };
        }
        return {
            icon: <AttachFileOutlinedIcon className="!h-5 !w-5" />,
            color: "gray",
        };
    };

    const onDrop = useCallback((acceptedFiles) => {
        const filesWithProgress = acceptedFiles.map((file) => ({ file, progress: 0 }));
        // show error if any file contains more than 5MB
        const largeFiles = filesWithProgress.filter((file) => file.file.size > 5 * 1024 * 1024);
        if (largeFiles.length > 0) {
            toast.error("File size should be less than 5MB");
            return;
        }
        // filter any duplicate files
        const uniqueFiles = filesWithProgress.filter((file) => !filesToUpload.some((f) => f.file.name === file.file.name));
        setFilesToUpload(uniqueFiles);
    }, []);

    const onUploadProgress = (file, progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFilesToUpload((prevFiles) => prevFiles.map((f) => (f.file === file ? { ...f, progress } : f)));
    };

    const uploadFilesToServer = async () => {
        let fail = false;
        for (const { file } of filesToUpload) {
            setLoading(true);
            try {
                await uploadFile(note._id, "note", file, (event) => onUploadProgress(file, event), opportunityId);
            } catch (error) {
                fail = true;
                console.error("Error uploading file:", error);
            }
            setLoading(false);
        }
        if (fail) {
            toast.error("Failed to upload some files");
            return;
        }
        setRefreshUI((prev) => !prev);
        toast.success(`${filesToUpload.length} files uploaded successfully`);
        setFilesToUpload([]);
    };

    const removeFile = (file) => {
        setFilesToUpload((prev) => prev.filter((f) => f.file !== file));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleIndividualDelete = async (file) => {
        setActiveFile(file);
        try {
            await deleteFile(file._id);
            toast.success("File deleted successfully");
            setRefreshUI((prev) => !prev);
        } catch (error) {
            console.error(error);
        }
        setActiveFile(null);
    };
    const handleIndividualDownload = async (file) => {
        try {
            console.log("file", file);
            const response = await getSignedUrl(file._id);
            if (response && response.signedURL) {
                triggerDownload(response.signedURL, file.name);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <>
            <UploadModal
                open={isOpenUploadModal}
                setOpen={setIsOpenUploadModal}
                setRefreshUI={setRefreshUI}
                type="note"
                typeId={note._id}
            />
            <div className="flex items-center gap-2 mt-5">
                <h1 className="font-semibold">Files</h1>
                {uploadedFiles.length > 0 && (
                    <button onClick={() => setIsOpenUploadModal(true)} className="text-blue-500 text-sm font-semibold ml-auto">
                        Upload New File
                    </button>
                )}
            </div>
            <div className="flex flex-col gap-2 mt-1">
                {uploadedFiles.length > 0 ? (
                    uploadedFiles.map((file) => (
                        <div
                            key={file._id}
                            className="flex items-center gap-2 p-3 rounded-lg overflow-hidden border border-stone-250 pr-3 hover:border-slate-300 transition-all"
                        >
                            <div className="">
                                <InsertPhotoOutlinedIcon className="!h-5 !w-5" />
                            </div>
                            <div className="w-full ml-2">
                                <div className="text-sm flex justify-between">
                                    <p className="text-muted-foreground ">{file.name.slice(0, 25)}</p>
                                </div>
                            </div>
                            <div className="text-dgray-700 flex items-center gap-4 ml-auto">
                                <button onClick={() => handleIndividualDownload(file)} className="">
                                    <FileDownloadOutlinedIcon className="!h-5 !w-5" />
                                </button>
                                <button onClick={() => handleIndividualDelete(file)} className="">
                                    {activeFile?._id === file._id ? (
                                        <CircularProgress size={11} color="inherit" />
                                    ) : (
                                        <DeleteOutlineOutlinedIcon className="!h-5 !w-5" />
                                    )}
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        <div className="h-[200px]">
                            <label
                                {...getRootProps()}
                                className={`relative flex flex-col items-center justify-center w-full h-full p-6 border-2 ${
                                    isDragActive ? "border-blue-500" : "border-gray-300"
                                } border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100`}
                            >
                                <div className="text-center">
                                    <FileUploadOutlinedIcon />
                                    <p className="text-base font-semibold mt-4">
                                        <span className="text-blue-500">Click to upload</span> or Drag & drop
                                    </p>
                                    <p className="text-dgray-500 text-sm mt-2">Supports CSV, JPEG, PNG, or PDF (Max.5MB)</p>
                                </div>
                            </label>
                            <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                        </div>
                        {filesToUpload.length > 0 && (
                            <div className="space-y-2">
                                {filesToUpload.map(({ file, progress }, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-between gap-2 rounded-lg overflow-hidden border border-stone-250 pr-3"
                                    >
                                        <div className="flex items-center flex-1 p-3">
                                            <div>{getFileIconAndColor(file).icon}</div>
                                            <div className="w-full ml-4 space-y-1">
                                                <div className="text-sm flex justify-between">
                                                    <p className="text-muted-foreground">{file.name.slice(0, 25)}</p>
                                                    <span className="text-xs">{progress}%</span>
                                                </div>
                                                <LinearProgress variant="determinate" value={progress} className="!h-1" />
                                                {/* size */}
                                                <div className="flex items-center text-xs text-muted-foreground">
                                                    <p>{formatFileSize(file.size)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={() => removeFile(file)} className="text-gray-500">
                                            <CloseOutlinedIcon className="!w-5 !h-5" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
                <div className="py-2">
                    <button
                        disabled={filesToUpload.length === 0 || loading}
                        onClick={uploadFilesToServer}
                        className="flex items-center gap-2 border border-black rounded-lg py-2 px-4 bg-black text-white hover:opacity-90 disabled:opacity-50"
                    >
                        {loading && <CircularProgress size={14} color="inherit" />}
                        Upload
                    </button>
                </div>
            </div>
        </>
    );
}

const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} B`; // Bytes for very small sizes
    } else if (sizeInBytes < 1024 * 1024) {
        return `${(sizeInBytes / 1024).toFixed(0)} KB`; // Convert to KB
    } else {
        return `${(sizeInBytes / 1024 / 1024).toFixed(1)} MB`; // Convert to MB
    }
};
