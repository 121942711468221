import React from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import AiIntelligence from "./AiIntelligence";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function FuseAgent() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>Fuse Agent</p>
            </MainTitleBar>

            <div className="w-full bg-white">
                <div className="flex flex-col grow">
                    <AiIntelligence />
                </div>
            </div>
            <FuseAgentModal />
        </MainLayout>
    );
}
