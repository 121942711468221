import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import useTeam from "@/hooks/useTeam";
import useNotes from "@/hooks/crm/useNotes";

export default function ShareModal({ open, setOpen, noteId }) {
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const { getTeamMembers } = useTeam();
    const { shareNote } = useNotes();

    useEffect(() => {
        const fetchTeamMembers = async () => {
            const response = await getTeamMembers({});
            if (response?.data) {
                setTeamMembers(response.data.filter((member) => member.status === "ACTIVE"));
            }
        };
        if (open) {
            fetchTeamMembers();
        }
    }, [open]);

    const handleShare = async () => {
        setLoading(true);
        try {
            const response = await shareNote(noteId, selectedMembers);
            if (response?.data?.shareUrl) {
                await navigator.clipboard.writeText(response.data.shareUrl);
                setOpen(false);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="bg-white rounded-lg p-6 w-[500px]">
                    <Dialog.Title className="text-lg font-semibold mb-4">Share Transcript</Dialog.Title>

                    <div className="space-y-4">
                        {teamMembers.map((member) => (
                            <label key={member.userId} className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    checked={selectedMembers.includes(member.userId)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedMembers([...selectedMembers, member.userId]);
                                        } else {
                                            setSelectedMembers(selectedMembers.filter((id) => id !== member.userId));
                                        }
                                    }}
                                />
                                <span>{member.name}</span>
                            </label>
                        ))}
                    </div>

                    <div className="mt-6 flex justify-end gap-2">
                        <button onClick={() => setOpen(false)} className="px-4 py-2 text-sm border border-gray-300 rounded-lg">
                            Cancel
                        </button>
                        <button
                            onClick={handleShare}
                            disabled={loading || selectedMembers.length === 0}
                            className="px-4 py-2 text-sm bg-black text-white rounded-lg disabled:opacity-50"
                        >
                            {loading ? "Sharing..." : "Share & Copy Link"}
                        </button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}
