import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";

export default function CreditsUsedByProductComponent({ creditsData = [], filter, setFilter }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);

    useEffect(() => {
        const totalCredits = creditsData.reduce((sum, item) => sum + item.value, 0);

        const chartOptions = {
            chart: {
                type: "pie",
                height: 156,
                width: 156,
                spacingTop: 15,
                stacking: "normal",
                events: {
                    render() {
                        const chart = this;
                        const centerX = chart.plotLeft + chart.series[0].center[0];
                        const centerY = chart.plotTop + chart.series[0].center[1];

                        if (!chart.customCenterText) {
                            chart.customCenterText = chart.renderer
                                .text(
                                    `<div style="transition: opacity 0.2s" class="flex flex-col z-[0] items-center translate-y-[-8px] justify-center gap-1">
    <div class="text-[#6d6d6d] text-xs font-normal font-['Inter']">Total </div>
    <div class="text-center text-[#050505] text-base font-semibold font-['Inter']">${totalCredits.toLocaleString()} </div>
</div>`,
                                    centerX,
                                    centerY,
                                    true
                                )
                                .attr({
                                    align: "center",
                                    "text-anchor": "middle",
                                    opacity: 1,
                                })
                                .css({
                                    color: "#6d6d6d",
                                    fontSize: "0.875rem",
                                    fontWeight: "bold",
                                    fontFamily: "Inter, sans-serif",
                                    zIndex: 0,
                                })
                                .add();
                        } else {
                            chart.customCenterText.attr({
                                text: `<div style="transition: opacity 0.2s" class="flex flex-col z-[0] items-center translate-y-[-8px] justify-center gap-1">
    <div class="text-[#6d6d6d] text-xs font-normal font-['Inter']">Total </div>
    <div class="text-center text-[#050505] text-base font-semibold font-['Inter']">${totalCredits.toLocaleString()} </div>
</div>`,
                                x: centerX,
                                y: centerY,
                                opacity: 1,
                            });
                        }
                    },
                },
            },
            title: { text: "" },
            tooltip: {
                enabled: true,
                useHTML: true,
                zIndex: 100,
                hideDelay: 0,
            },
            plotOptions: {
                pie: {
                    innerSize: "85%",
                    center: ["50%", "50%"],
                    size: "136px",
                    showInLegend: false,
                    dataLabels: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            mouseOver: function () {
                                const chart = this.series.chart;
                                if (chart.customCenterText) {
                                    chart.customCenterText.attr({ opacity: 0 });
                                }
                            },
                            mouseOut: function () {
                                const chart = this.series.chart;
                                if (chart.customCenterText) {
                                    chart.customCenterText.attr({ opacity: 1 });
                                }
                            },
                        },
                    },
                },
            },
            colors: ["#a2adff", "#F9D2DB", "#D0E3DF", "#C5E3F6", "#FBE7C6"],
            series: [
                {
                    name: "Credits Used",
                    data: creditsData.map((item) => ({
                        name: item.product,
                        y: item.value,
                    })),
                },
            ],
            credits: { enabled: false },
        };

        const chart = Highcharts.chart("creditsUsedByProductChart", chartOptions);

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [creditsData]);

    return (
        <div className="flex flex-col items-center justify-start w-full bg-white rounded-xl border border-[#e7e7e7] p-4 2xl:p-6 gap-4 2xl:gap-6">
            <div className="flex items-center justify-between w-full">
                <div className="text-[#050505] text-base font-semibold font-['Inter'] shrink grow basis-0">Credits Used by Product</div>
                <button
                    className="flex items-center gap-1 text-sm font-medium text-[#2D7A89]"
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setOpenFilter((cur) => !cur);
                    }}
                >
                    {filterOptions[filter]}
                    <ExpandMoreIcon
                        className={`h-4 w-4 transition-transform duration-200 ${openFilter ? "rotate-180" : ""}`}
                        color="inherit"
                    />
                </button>
            </div>
            {/* Chart and Legend Container */}
            <div className="flex items-center justify-start gap-4 w-full">
                {/* Pie Chart */}
                <div className="flex items-center justify-center">
                    <div id="creditsUsedByProductChart"></div>
                </div>
                {/* Custom Legend */}
                <div className="flex flex-wrap items-center justify-center gap-4">
                    {creditsData.map((item, index) => (
                        <div key={index} className="flex items-center gap-2">
                            <span
                                style={{
                                    backgroundColor: ["#a2adff", "#F9D2DB", "#D0E3DF", "#C5E3F6", "#FBE7C6"][index],
                                    width: 12,
                                    height: 12,
                                    borderRadius: "50%",
                                    display: "inline-block",
                                }}
                            ></span>
                            <span className="text-sm font-medium text-black">{item.product}</span>
                        </div>
                    ))}
                </div>
            </div>
            <FilterPopup
                anchorEl={anchorEl}
                open={openFilter}
                handleClose={(value) => {
                    if (value) {
                        setFilter(value);
                    }
                    setAnchorEl(null);
                    setOpenFilter(false);
                }}
            />
        </div>
    );
}
