export default function Overview({ payload }) {
    const {
        totalLeadsReached,
        totalMailSent,
        uniqueOpened,
        totalOpened,
        uniqueClicked,
        uniqueReplied,
        uniquePositiveReplied,
        uniqueBounced,
        uniqueOpenedRate,
        uniqueClickedRate,
        uniqueRepliedRate,
        uniqueBouncedRate,
    } = payload;

    const data = [
        { label: "Total Sent", value: totalMailSent || 0 },
        { label: "Total Opened", value: totalOpened || 0 },
        { label: "Unique Opened", value: uniqueOpened || 0, subscript: uniqueOpenedRate },
        { label: "Unique Clicked", value: uniqueClicked || 0, subscript: uniqueClickedRate },
        { label: "Unique Replied", value: uniqueReplied || 0, subscript: uniqueRepliedRate },
        { label: "Unique Positive Replied", value: uniquePositiveReplied || 0 },
        { label: "Unique Bounced", value: uniqueBounced || 0, subscript: uniqueBouncedRate },
    ];
    return (
        <>
            <div className="rounded-[8px] bg-[#F4F3F0] p-[24px] text-center w-full">
                <h1 className="text-[20px] font-semibold font-Inter text-accents-mainBlack">{totalLeadsReached}</h1>
                <p className="mt-[16px] main-text-black-500 leading-[21px]">Total Leads Reached</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 xl:gap-x-16 gap-y-5 mt-[24px]">
                {data.map((item, index) => (
                    <div key={index} className="campaign-card !p-[16px]">
                        <p className="main-text-black-600 !text-[16px]">
                            {item.value}
                            {/* {item.subscript && <sub className="text-blue-500">({item.subscript})</sub>} */}
                        </p>
                        <hr className="bg-[#E7E7E7] my-[16px]" />
                        <div className="flex items-center text-dgray-500 text gap-x-2">
                            <p className="text-[#6D6D6D] font-Inter text-[14px] font-medium leading-[21px]">{item.label}</p>
                            {/* <InfoOutlinedIcon className="!h-4 !w-4" /> */}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
