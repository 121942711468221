import React, { useEffect } from "react";
import SelectComponent from "@/components/crm/opportunities/SelectComponent";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { ReactComponent as EmptyStateIcon } from "@/assets/image/crm/timeline/empty.svg";
import RouteIcon from "@mui/icons-material/Route";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import useTimeline from "@/hooks/crm/useTimeline";
import { useParams } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CircularProgress } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function Activitities() {
    const [select, setSelect] = React.useState("All");
    const [activities, setActivities] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const { opportunityId } = useParams();

    const { getActivities } = useTimeline();

    dayjs.extend(customParseFormat);
    useEffect(() => {
        const fetchActivities = async () => {
            setLoading(true);
            try {
                const res = await getActivities(opportunityId);
                if (res) {
                    setActivities(res.data);
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        fetchActivities();
    }, []);

    if (!loading && activities.length === 0) {
        return <EmptyState />;
    }

    const getIcon = (eventType) => {
        const typeIcons = {
            update: <BorderColorOutlinedIcon className="!h-5 !w-5" color="inherit" />,
            delete: <DeleteOutlineOutlinedIcon className="!h-5 !w-5" color="inherit" />,
            create: <AddCircleOutlineOutlinedIcon className="!h-5 !w-5" color="inherit" />,
        };
        return typeIcons[eventType];
    };

    function DynamicRender({ heading, newData }) {
        const manipulatedData = newData ? manipulateNewData(newData) : [];

        return (
            heading &&
            newData && (
                <div className="mt-5 grid grid-cols-2 gap-x-5 w-fit">
                    <div className="text-dgray-6 text-sm space-y-2">
                        {manipulatedData.map((item, index) => (
                            <p key={index} className="flex items-center gap-2">
                                {item.icon}
                                {item.key}:
                            </p>
                        ))}
                    </div>
                    <div className="text-sm space-y-2 font-medium">
                        {manipulatedData.map((item, index) => (
                            <p key={index}>{item.value}</p>
                        ))}
                    </div>
                </div>
            )
        );
    }

    const renderActivity = (activity) => {
        const { time, event, eventType, newData, oldData, day, heading } = activity;
        return (
            <div>
                <div key={activity.createdAt} className="border text-sm border-stone-250 rounded-lg p-3">
                    <div className="flex items-center gap-2">
                        <div className="text-dgray-6">{getIcon(eventType)}</div>
                        <p>{heading ? heading : event}</p>
                        <p className="text-dgray-6 ml-auto">
                            {day} - {time}
                        </p>
                    </div>
                    <DynamicRender heading={heading} newData={newData} />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="border-b flex items-center border-stone-250 p-3 py-5">
                <p className="font-semibold">Activities</p>
                {/* <div className="flex gap-4 ml-auto">
                    <SelectComponent bgColor="#fff" sx={{ width: "130px" }} options={["op1"]} placeholder="All" />
                    <SelectComponent bgColor="#fff" options={["op1"]} placeholder="This Week" sx={{ width: "130px" }} />
                </div> */}
            </div>
            {loading ? (
                <div className="text-black h-64 flex justify-center items-center">
                    <CircularProgress color="inherit" />
                </div>
            ) : (
                <div className="p-3 mt-3 flex flex-col gap-4">
                    {Object.keys(activities).map((key) => (
                        <div>
                            <p className="text-sm text-dgray-6 mb-1.5">{dayjs(key, "DD-MM-YYYY").format("D MMMM, YYYY")}</p>
                            <div className="flex flex-col gap-2">{activities[key].map((activity) => renderActivity(activity))}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const EmptyState = () => {
    return (
        <div className="flex flex-col justify-center items-center mt-28">
            <EmptyStateIcon className="" />
            <p className="font-semibold mt-10">Nothing here yet! Add your first activity!</p>
            <p className="text-dgray-6 mt-3">Start by creating a task, adding a note, or uploading a file to see your activity here</p>
        </div>
    );
};

function manipulateNewData(newData) {
    // Define icons for specific keys
    const icons = {
        completed: <CheckCircleOutlineOutlinedIcon className="!h-4 !w-4" color="inherit" />,
        amount: <PaidOutlinedIcon className="!h-4 !w-4" color="inherit" />,
        "close date": <CalendarTodayOutlinedIcon className="!h-4 !w-4" color="inherit" />,
        default: <TextSnippetOutlinedIcon className="!h-4 !w-4" color="inherit" />,
    };

    // Helper function to transform keys
    const formatKey = (key) => {
        if (key === "isCompleted") return "Completed";
        if (key === "value") return "Amount";
        if (key === "dueDate") return "Close Date";
        if (key === "address") return "Country";

        // Add space before capital letters and capitalize first letter
        return key.replace(/([A-Z])/g, " $1").replace(/^./, (char) => char.toUpperCase());
    };

    // Iterate through newData keys to create the manipulated array
    const manipulatedData = Object.entries(newData).map(([key, value]) => {
        const formattedKey = formatKey(key);

        let displayValue = value;
        if (key === "isCompleted") displayValue = value ? "Yes" : "No";
        if (key === "address") displayValue = value.country;
        if (key === "value") displayValue = `$${value}`;

        return {
            key: formattedKey,
            value: displayValue,
            icon: icons[formattedKey.toLowerCase()] || icons.default,
        };
    });

    // filter out Company and Completed
    const filteredData = manipulatedData.filter((item) => item.key !== "Company" && item.key !== "Completed");
    return filteredData;
}
