import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import { ReactSVG } from "react-svg";
import BackIcon from "@/assets/image/crm/transcript/back-arrow.svg";
import VideoPlayer from "@/components/crm/transcript/VideoPlayer";
import TranscriptTabs from "@/components/crm/transcript/TranscriptTabs";
import useKompassRouter from "@/hooks/useKompassRouter";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useNotes from "@/hooks/crm/useNotes";
import MainLoader from "@/components/MainLoader";
import DownloadIcon from "@/assets/image/lists/download.svg";
import ShareIcon from "@/assets/image/crm/transcript/share.svg";
import useTranscripts from "@/hooks/crm/useTranscripts";
import ShareModal from "@/components/crm/transcript/ShareModal";
import MainTitleBar from "@/components/MainTitleBar";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function TranscriptScene() {
    const { noteId } = useParams();
    const [loading, setLoading] = useState(true);
    const [transcriptData, setTranscriptData] = useState(null);
    const { getNoteById } = useNotes();
    const { downloadTranscript } = useTranscripts();
    const { kompassNavigate } = useKompassRouter();
    const [shareModalOpen, setShareModalOpen] = useState(false);

    useEffect(() => {
        const fetchTranscript = async () => {
            setLoading(true);
            try {
                const response = await getNoteById(noteId);
                if (response?.data?.[0]?.transcript) {
                    setTranscriptData(response.data[0]);
                } else {
                    // Handle case where note doesn't have transcript
                    kompassNavigate("/crm/transcripts");
                }
            } catch (error) {
                console.error(error);
                kompassNavigate("/crm/transcripts");
            }
            setLoading(false);
        };

        if (noteId) {
            fetchTranscript();
        }
    }, [noteId]);

    if (loading) {
        return <MainLoader />;
    }

    return (
        <MainLayout>
            <MainTitleBar>
                <p>Transcript</p>
            </MainTitleBar>
            <BaseContainer>
                <div className="flex font-Inter items-center h-[52px]">
                    <button onClick={() => kompassNavigate(-1)} className="flex gap-1 items-center justify-center text-gray-600">
                        <ReactSVG src={BackIcon} className="w-5 h-5" />
                        <span className="text-sm">Back</span>
                    </button>
                </div>

                <div className="flex font-Inter justify-between items-center mb-6 h-[64px]">
                    <h1 className="text-xl font-semibold">{transcriptData?.title}</h1>
                    <div className="flex gap-2">
                        <div className="px-3 py-1.5 text-sm rounded-lg border border-accents-gray">
                            <span>{transcriptData?.language}</span>
                        </div>
                        <button onClick={() => downloadTranscript(noteId)} className="main-black-button">
                            <img src={DownloadIcon} alt="" className="w-5 h-5" />
                            <span>Download</span>
                        </button>
                        <button onClick={() => setShareModalOpen(true)} className="main-black-button">
                            <img src={ShareIcon} alt="" className="w-5 h-5" />
                            <span>Share</span>
                        </button>
                    </div>
                </div>

                <div className="flex font-Inter gap-6">
                    <VideoPlayer videoUrl={transcriptData?.recordingUrl} />
                    <TranscriptTabs transcriptData={transcriptData} />
                </div>
            </BaseContainer>
            <ShareModal open={shareModalOpen} setOpen={setShareModalOpen} noteId={noteId} />
            <FuseAgentModal />
        </MainLayout>
    );
}
