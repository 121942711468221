import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import { PaginationItem } from "@mui/material";
import useProfile from "@/hooks/useProfile";
import DirectoryTable from "../directory-table/DirectoryTable";

function ContactList({ selectedLetter }) {
    const { getKompassProfile } = useProfile();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [profiles, setProfiles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const totalPages = Math.ceil(data?.noOfProfiles / itemsPerPage);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const res = await getKompassProfile({
                type: "all",
                userName: "",
                userId: "",
                url: "",
                initial: selectedLetter,
                skip: (currentPage - 1) * itemsPerPage,
            });
            setData(res.data);
            setProfiles(res.data.profiles);
            setLoading(false);
        };
        fetchData();
    }, [currentPage, selectedLetter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedLetter]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    // --- Sorting ---
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("firstName");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedData = sortTableData(profiles, property, isAsc);
        setProfiles(sortedData);
    };

    const sortTableData = (data, property, isAsc) => {
        return [...data].sort((a, b) => {
            const valueA = a[property] ?? ""; // Default to empty string if missing
            const valueB = b[property] ?? ""; // Default to empty string if missing

            // If both are missing, keep original order
            if (valueA === "" && valueB === "") return 0;
            // If only valueA is missing, push it to the end
            if (valueA === "") return 1;
            // If only valueB is missing, push it to the end
            if (valueB === "") return -1;

            if (property === "company") {
                return isAsc ? valueA.name.localeCompare(valueB.name) : valueB.name.localeCompare(valueA.name);
            }
            if (typeof valueA === "string" && typeof valueB === "string") {
                return isAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            }
            if (typeof valueA === "number" && typeof valueB === "number") {
                return isAsc ? valueA - valueB : valueB - valueA;
            }
            return 0;
        });
    };

    return (
        <>
            <div className="flex flex-col mx-6 lg:mx-16 mt-10 mb-20">
                {data?.noOfProfiles && (
                    <p className="ml-4 sm:ml-0 text-sm capitalize text-dgray-6">
                        {itemsPerPage * (currentPage - 1) + 1}-{Math.min(itemsPerPage * currentPage, data?.noOfProfiles)} of{" "}
                        {data?.noOfProfiles} contacts
                    </p>
                )}
                <DirectoryTable data={profiles} loading={loading} handleRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
                <div className="flex w-full justify-center py-6 sm:py-14">
                    <Pagination
                        className="font-Inter"
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        renderItem={(item) => <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                                backgroundColor: "#ffffff",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                            "& .MuiPagination-ul": {
                                gap: "16px 0",
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default ContactList;
