import { Button } from "@material-tailwind/react";
import { useState, useContext } from "react";
import SwitchButton from "@/components/common/SwitchButton";
import { Link } from "react-router-dom";
import LandingLayout from "@/components/LandingLayout";
import { useResizeDetector } from "react-resize-detector";
import { ReactComponent as TextDecorIcon } from "@/assets/image/icons/text-decor.svg";
import Datapoints from "@/components/landing/common/Datapoints";
import TrustedUser from "@/components/landing/base/TrustedUser";
import { Box } from "@mui/material";
import FullPricingBlock from "@/components/pricing/FullPricingBlock";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import BaseContainer from "@/components/BaseContainer";
import { AuthContext } from "@/context/AuthContext";
import MainLoader from "@/components/MainLoader";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useKompassRouter from "@/hooks/useKompassRouter";
import { Helmet } from "react-helmet";
import { ReactComponent as HighLight } from "@/assets/image/base/highlight.svg";

export default function PricingScene() {
    const [annual, setAnnual] = useState(true);
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const { kompassNavigate } = useKompassRouter();

    const { ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: "debounce",
        refreshRate: 1000,
    });
    //Selecting a plan by clicking a button in a table or on a Plan card
    const subscriptionInfoFunction = useHTTPRequest(`/billing/getSubscriptionInfo`);

    const tariffsInfoFunction = useHTTPRequest(`/billing/getTariffs`, "GET", true);

    const { data: subscriptionInfo, isLoading: isLoadingSubscription } = useQuery({
        queryKey: ["Subscription"],
        queryFn: async () => {
            const signedIn = await authContext.isSignIn();
            if (signedIn) return subscriptionInfoFunction();
            else
                return {
                    data: [],
                    isLoading: false,
                };
        },
    });

    const { data: tariffsList, isLoading: isLoadingTariffs } = useQuery({
        queryKey: ["Tariffs"],
        queryFn: () => tariffsInfoFunction(),
    });

    const loading = isLoadingSubscription || isLoadingTariffs;

    return (
        <>
            <Helmet>
                <title>Pricing | FuseAI</title>
                <link rel="canonical" href="https://kompassai.com/pricing" />
            </Helmet>
            <LandingLayout>
                {loading && <MainLoader />}
                {!loading && (
                    <div className=" bg-primary-header">
                        <div ref={ref} className="flex w-full flex-col items-center pt-28 px-6 pre-xl:px-12">
                            <Box className="h-fit relative">
                                {/* <HighLight className="absolute w-[18px] h-[18px] md:w-[24px] md:h-[24px] top-[-14px] left-[-14px] md:-top-4 md:-left-5 2xl:-top-3 2xl:-left-5" /> */}
                                <h1 className="text-primary-black text-center text-[27px] sm:text-[30px] md:text-[40px] xl:text-[46px] leading-[1.1] font-bold">
                                    Accelerate your growth.
                                </h1>
                            </Box>
                            <p className="mb-20 mt-6 text-[16px] md:text-[20px] lg:text-2xl text-center md:max-w-[760px] lg:max-w-[860px] 2xl:max-w-[1350px] font-normal font-Inter leading-[1.4] text-neutral-500">
                                Start free. Only pay for agent actions.
                            </p>
                            <SwitchButton switchState={[annual, setAnnual]} />
                        </div>
                        <FullPricingBlock
                            subscriptionInfo={subscriptionInfo?.data}
                            annual={annual}
                            tariffList={tariffsList?.data}
                            topPosition={isTablet ? 0 : 75}
                        />
                        <div className="flex flex-col gap-20">
                            <Datapoints />
                        </div>
                    </div>
                )}
            </LandingLayout>
        </>
    );
}
