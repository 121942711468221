import React, { useState, useMemo, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Stack,
    InputBase,
    styled,
} from "@mui/material";

import useAuth from "@/hooks/useAuth";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { CustomButton } from "@/components/crm/contacts/Button";
import {
    BulkEnrichIcon,
    CRMIcon,
    EnrichIcon,
    FilterIcon,
    NameIcon,
    EmailIcon,
    CompanyIcon,
    IndustryIcon,
    PhoneIcon,
    CreatedDateIcon,
    CountryIcon,
    CreatedByIcon,
    OptionsIcon,
    SaveToListIcon,
} from "@/components/crm/contacts/Icon";

import FilterModal from "@/components/crm/contacts/FilterModal";
import { CRM_BACKEND_URL } from "@/utils/constants";
import ContactsCRMPushMenu from "./ContactsCRMPushMenu";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import { motion, AnimatePresence } from "framer-motion";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { Backdrop } from "@mui/material";
import CRMPushMenu from "@/components/common/CRMElements/CRMPushMenu";
import MainLoader from "@/components/MainLoader";

const columns = [
    { id: "select", label: "", width: 50, sortable: false, sticky: true },
    { id: "name", label: "Name", width: 200, sortable: true, icon: <NameIcon />, sticky: true },
    { id: "email", label: "Email", width: 280, sortable: true, icon: <EmailIcon /> },
    { id: "company", label: "Company", width: 200, sortable: true, icon: <CompanyIcon /> },
    { id: "industry", label: "Industry", width: 200, sortable: true, icon: <IndustryIcon /> },
    { id: "phone", label: "Phone", width: 200, sortable: true, icon: <PhoneIcon /> },
    { id: "createdDate", label: "Created Date", width: 200, sortable: true, icon: <CreatedDateIcon /> },
    { id: "country", label: "Country", width: 200, sortable: true, icon: <CountryIcon /> },
    { id: "createdBy", label: "Created By", width: 180, sortable: true, icon: <CreatedByIcon /> },
];

// 1) HEAD CELLS
const StyledHeadCell = styled(TableCell)(({ sticky, left }) => ({
    borderRight: "1px solid #e7e7e7",
    borderBottom: "1px solid #e7e7e7",
    backgroundColor: "#f4f3f0",
    color: "#6d6d6d",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "0.5rem",
    height: "45px",
    padding: "10px 12px",
    position: sticky ? "sticky" : "relative",
    left: sticky ? left : "auto",
    background: sticky ? "#f4f3f0" : "#f4f3f0",
    zIndex: sticky ? 3 : "auto",
    boxShadow: sticky ? "2px 0 5px -2px rgba(0,0,0,0.1)" : "none",
}));

// 2) BODY CELLS
const StyledBodyCell = styled(TableCell)(({ sticky, left }) => ({
    borderRight: "1px solid #e7e7e7",
    borderBottom: "1px solid #e7e7e7",
    backgroundColor: "#fff",
    color: "#050505",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    height: "53px",
    padding: "12px 12px",
    position: sticky ? "sticky" : "relative",
    left: sticky ? left : "auto",
    background: sticky ? "#fff" : "#fff",
    zIndex: sticky ? 2 : "auto",
    boxShadow: sticky ? "2px 0 5px -2px rgba(0,0,0,0.1)" : "none",
    "&:hover": !sticky && {
        backgroundColor: "#f1f1f1",
    },
}));

function ascendingSort(a, b) {
    if (a == null && b == null) return 0;
    if (a == null) return -1;
    if (b == null) return 1;
    return a.toString().localeCompare(b.toString());
}

export default function ContactsScene() {
    const { getAuthToken } = useAuth();

    // ----------------------------
    // STATE
    // ----------------------------
    const [contacts, setContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const { show, loading: crmLoading } = useCRMPushResultStore();

    // Pagination
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    // Filter states
    const [filters, setFilters] = useState({
        name: "",
        jobTitle: [],
        level: [],
        dept: [],
        subDept: [],
        country: [],
        state: [],
        personalLocation: [],
        companyName: [],
        website: [],
        industry: [],
        revenue: [],
        numberOfEmployees: [],
        companyLocation: [],
        linkedinUrl: "",
    });

    // Because your `allOptions` will be derived from the data or from a separate endpoint:
    const [allOptions, setAllOptions] = useState({
        country: [],
        state: [],
        personalLocation: [],
        title: [],
        level: [],
        dept: [],
        subDepartment: [],
        companyName: [],
        website: [],
        industryName: [],
        revenue: [],
        numberOfEmployees: [],
        companyLocation: [],
    });

    // For the Filter Modal
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // For Options menu
    const [menuAnchor, setMenuAnchor] = useState(null);

    // ----------------------------
    // FETCH DATA
    // ----------------------------

    const buildQueryParams = (filters) => {
        const params = new URLSearchParams();

        if (filters.name) {
            params.append("firstName", filters.name);
        }
        if (filters.jobTitle && filters.jobTitle.length > 0) {
            filters.jobTitle.forEach((jt) => {
                params.append("jobTitle", jt);
            });
        }
        if (filters.country && filters.country.length > 0) {
            filters.country.forEach((cty) => {
                params.append("country", cty);
            });
        }
        if (filters.linkedinUrl) {
            params.append("linkedinUrl", filters.linkedinUrl);
        }
        if (filters.companyName && filters.companyName.length > 0) {
            filters.companyName.forEach((cName) => {
                params.append("companyName", cName);
            });
        }

        // Always show only non-archived
        params.append("isArchived", "false");

        // Page & limit
        params.append("pageNum", "1");
        params.append("limit", "100");

        return params.toString();
    };

    const fetchContacts = async (applyFilters = false) => {
        try {
            const token = await getAuthToken();
            let url = `${CRM_BACKEND_URL}/api/v1/people/getAll?createdBy=true`;

            // If user hits "Search" in the modal
            if (applyFilters) {
                const queryString = buildQueryParams(filters);
                url += `&${queryString}`; // Changed from ? to & since ?createdBy=true is already present
            }

            const res = await fetch(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const result = await res.json();

            if (result.code === 200 && Array.isArray(result.data)) {
                const mappedContacts = result.data.map((person) => ({
                    id: person._id,
                    name: `${person.firstName || ""} ${person.lastName || ""}`.trim(),
                    email: person.primaryEmail || "",
                    company: person.company?.name || "",
                    industry: person.company?.industry || "",
                    phone: person.primaryPhone || "",
                    createdDate: person.createdAt?.slice(0, 10) || "",
                    country: person.address?.country || person.company?.address?.country || "",
                    createdBy: person.createdBy?.name || "",
                    jobTitle: person.jobTitle || "",

                    // 👇 preserve meta fields if they exist
                    meta: {
                        listId: person.meta?.listId,
                        pdlId: person.meta?.pdlId,
                        title: person.meta?.title,
                        // etc. if you want more subfields
                    },
                    linkedIn: person?.linkedIn,
                }));

                setContacts(mappedContacts);

                // Build "allOptions" from the fetched data
                const countries = new Set();
                const jobTitles = new Set();
                const companyNames = new Set();
                const industries = new Set();

                result.data.forEach((person) => {
                    if (person.address?.country) countries.add(person.address.country);
                    if (person.jobTitle) jobTitles.add(person.jobTitle);
                    if (person.company?.name) companyNames.add(person.company.name);
                    if (person.company?.industry) industries.add(person.company.industry);
                });

                setAllOptions((prev) => ({
                    ...prev,
                    country: Array.from(countries).map((c) => ({ label: c, value: c })),
                    title: Array.from(jobTitles).map((t) => ({ label: t, value: t })),
                    companyName: Array.from(companyNames).map((cn) => ({ label: cn, value: cn })),
                    industryName: Array.from(industries).map((ind) => ({ label: ind, value: ind })),
                }));
            } else {
                console.error("API returned an unexpected result", result);
            }
        } catch (error) {
            console.error("Error fetching contacts", error);
        }
    };

    useEffect(() => {
        // By default, fetch all contacts unfiltered.
        fetchContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----------------------------
    // SEARCH & FILTER
    // ----------------------------
    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleFilterSearch = () => {
        setFilterModalOpen(false);
        setAnchorEl(null);

        fetchContacts(true);
    };

    // ----------------------------
    // SORTING
    // ----------------------------
    const handleSort = (colId) => {
        if (sortColumn === colId) {
            setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
        } else {
            setSortColumn(colId);
            setSortDirection("asc");
        }
        setPage(1);
    };

    const sortedContacts = useMemo(() => {
        const data = [...contacts];
        if (sortColumn) {
            data.sort((a, b) => ascendingSort(a[sortColumn], b[sortColumn]));
            if (sortDirection === "desc") data.reverse();
        }
        return data;
    }, [contacts, sortColumn, sortDirection]);

    // Simple local name filtering for the search box:
    const filteredContactsByName = useMemo(() => {
        return sortedContacts.filter((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [sortedContacts, searchTerm]);

    // ----------------------------
    // PAGINATION
    // ----------------------------
    const totalPages = Math.ceil(filteredContactsByName.length / rowsPerPage);
    const startIndex = (page - 1) * rowsPerPage;
    const currentContacts = filteredContactsByName.slice(startIndex, startIndex + rowsPerPage);

    const handleChangePage = (event, newValue) => {
        setPage(newValue);
    };

    // ----------------------------
    // CHECKBOX SELECTION
    // ----------------------------
    const handleSelectAll = (checked) => {
        if (checked) {
            const newIds = currentContacts.map((item) => item.id);
            setSelected((prev) => Array.from(new Set([...prev, ...newIds])));
        } else {
            const currentIds = currentContacts.map((item) => item.id);
            setSelected((prev) => prev.filter((id) => !currentIds.includes(id)));
        }
    };

    const handleRowSelect = (id) => {
        if (selected.includes(id)) {
            setSelected((prev) => prev.filter((sid) => sid !== id));
        } else {
            setSelected((prev) => [...prev, id]);
        }
    };
    const allSelectedThisPage = currentContacts.length > 0 && currentContacts.every((c) => selected.includes(c.id));

    // ----------------------------
    // MENU
    // ----------------------------
    const handleMenuOpen = (e) => {
        setMenuAnchor(e.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleDeleteSelected = () => {
        alert(`Delete these IDs: ${selected.join(", ")}`);
        setSelected([]);
    };
    const handleClearAll = () => {
        setSelected([]);
    };

    // ----------------------------
    // FILTER MODAL
    // ----------------------------
    const handleOpenFilter = (e) => {
        setAnchorEl(e.currentTarget);
        setFilterModalOpen(true);
    };
    const handleCloseFilter = () => {
        setFilterModalOpen(false);
        setAnchorEl(null);
    };

    // ----------------------------
    // RENDER
    // ----------------------------
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = sortColumn === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && sortDirection === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && sortDirection === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    // Calculate left offsets for sticky columns
    const stickyColumns = columns.filter((col) => col.sticky);
    let cumulativeLeft = 0;

    const columnsWithOffsets = columns.map((col) => {
        if (col.sticky) {
            const colWithOffset = { ...col, left: cumulativeLeft };
            cumulativeLeft += col.width;
            return colWithOffset;
        }
        return { ...col, left: "auto" };
    });

    return (
        /**
         * This top-level Box ensures that the entire ContactsScene
         * can be scrolled horizontally if needed, and that no
         * child exceeds the parent width.
         */
        <>
            <AnimatePresence>
                {show && (
                    <motion.div className="w-full" initial={{ opacity: 0, zIndex: 11 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <Backdrop
                            open
                            className="!absolute"
                            sx={{
                                backgroundColor: "rgba(0,0,0,0.18)",
                                backdropFilter: "blur(2px)",
                                zIndex: 1100,
                            }}
                        >
                            <CRMUploadResultsTable></CRMUploadResultsTable>
                        </Backdrop>
                    </motion.div>
                )}
            </AnimatePresence>
            {crmLoading ? (
                <div className="relative w-full main-loader-wrapper">
                    <MainLoader isAllPage={false} />
                </div>
            ) : (
                <Box sx={{ width: "100%", overflowX: "auto" }}>
                    {/* Search */}
                    <Box className="flex flex-col gap-3 px-[0px] pt-0 pb-2" sx={{ backgroundColor: "#fff", maxWidth: "100%" }}>
                        <Box className="relative flex w-full items-center rounded-md shadow-sm">
                            <InputBase
                                placeholder="Search by name"
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                className="w-full border rounded-md border-[#e7e7e7] text-sm focus:outline-none px-3 py-2"
                            />
                        </Box>
                    </Box>

                    {/* Secondary Toolbar */}
                    <Toolbar
                        className="flex flex-row !items-end !min-h-[36px] !px-0 py-2 justify-between"
                        sx={{
                            backgroundColor: "#fff",
                            maxWidth: "100%",
                        }}
                    >
                        <Box className="flex items-center gap-4">
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontSize: "0.875rem",
                                    color: "#050505",
                                    fontWeight: 600,
                                }}
                            >
                                {selected.length} Selected
                            </Typography>
                            <Button
                                onClick={handleClearAll}
                                className="!capitalize !p-0 !text-accents-mainGreen font-medium disabled:!text-gray-400"
                                disabled={selected.length === 0}
                                sx={{ minWidth: "auto", fontFamily: "Inter, sans-serif" }}
                            >
                                Clear All
                            </Button>
                            <Button
                                onClick={handleDeleteSelected}
                                className={
                                    "!capitalize !p-0 font-medium " + (selected.length === 0 ? "!text-gray-400" : "!text-accents-mainGreen")
                                }
                                disabled={selected.length === 0}
                                sx={{ minWidth: "auto", fontFamily: "Inter, sans-serif" }}
                            >
                                Delete
                            </Button>
                        </Box>

                        <Stack direction="row" spacing={1} className="flex-wrap">
                            <CRMPushMenu
                                selected={contacts.filter((contact) => selected.includes(contact.id))}
                                isDisabled={false} // Adjust based on your logic
                                isFuseCRM={true}
                                profileType={"profile_search"}
                                className=""
                            />
                            {/* <CustomButton
                        onClick={() => alert("Save To List")}
                        title="Save To List"
                        height={36}
                        width={138}
                        px={16}
                        py={8}
                        titleTextSize={14}
                        titleFontWeight={500}
                        img
                        imgSrc={<SaveToListIcon />}
                        imgHeight={20}
                        imgWidth={20}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    />
                    <CustomButton
                        onClick={() => alert("Enrich")}
                        title="Enrich"
                        height={36}
                        width={100}
                        px={16}
                        py={8}
                        titleTextSize={14}
                        titleFontWeight={500}
                        img
                        imgSrc={<EnrichIcon />}
                        imgHeight={20}
                        imgWidth={20}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    />
                    <CustomButton
                        onClick={() => alert("Bulk Enrich")}
                        title="Bulk Enrich"
                        height={36}
                        width={131}
                        px={16}
                        py={8}
                        titleTextSize={14}
                        titleFontWeight={500}
                        img
                        imgSrc={<BulkEnrichIcon />}
                        imgHeight={20}
                        imgWidth={20}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    /> */}

                            {/* Filter Button */}
                            {/* <CustomButton
                        onClick={handleOpenFilter}
                        title="Filter"
                        height={36}
                        width={90}
                        px={16}
                        py={8}
                        titleTextSize={14}
                        titleFontWeight={500}
                        img
                        imgSrc={<FilterIcon />}
                        imgHeight={20}
                        imgWidth={20}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    />
                    {filterModalOpen && (
                        <FilterModal
                            anchorEl={anchorEl}
                            onClose={handleCloseFilter}
                            filters={filters}
                            setFilters={setFilters}
                            onSearch={handleFilterSearch}
                            allOptions={allOptions}
                        />
                    )} */}

                            {/* Options Button */}
                            {/* <CustomButton
                        onClick={handleMenuOpen}
                        title="Options"
                        height={36}
                        width={109}
                        px={16}
                        py={8}
                        titleTextSize={14}
                        titleFontWeight={500}
                        img
                        imgSrc={<OptionsIcon />}
                        imgHeight={20}
                        imgWidth={20}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    />
                    <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={handleMenuClose}>
                        <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
                    </Menu> */}
                        </Stack>
                    </Toolbar>

                    {/* Table */}
                    <TableContainer
                        component={Paper}
                        sx={{
                            boxShadow: "none",
                            marginLeft: "0px",
                            marginTop: "24px",
                            border: "1px solid #e7e7e7",
                            borderRadius: "8px",
                            overflowX: "auto",
                            paddingBottom: "8px",
                            maxWidth: "100%",
                            position: "relative",
                            "&::-webkit-scrollbar": {
                                height: "6px",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f0f0f0",
                                marginTop: "8px",
                                marginBottom: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#aaa",
                                borderRadius: "6px",
                            },
                            borderCollapse: "separate",
                            tableLayout: "fixed",
                            minWidth: 1200,
                        }}
                    >
                        <Table
                            sx={{
                                borderCollapse: "separate",
                                tableLayout: "fixed",
                                minWidth: 1200,
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    {columnsWithOffsets.map((col, colIdx) => (
                                        <StyledHeadCell
                                            key={col.id}
                                            sx={{
                                                width: col.width,
                                                ...(col.id === "select" && { padding: 0 }),
                                            }}
                                            align={col.id === "select" ? "center" : "left"}
                                            sticky={col.sticky}
                                            left={col.left}
                                            onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                            style={{
                                                cursor: col.sortable ? "pointer" : "default",
                                                userSelect: "none",
                                            }}
                                        >
                                            {col.id === "select" ? (
                                                <Checkbox
                                                    checked={allSelectedThisPage}
                                                    onChange={(e) => handleSelectAll(e.target.checked)}
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "#2D7A89",
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            fontSize: 16,
                                                        },
                                                        marginX: "auto",
                                                    }}
                                                />
                                            ) : (
                                                <Box
                                                    display="inline-flex"
                                                    width="100%"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    gap={0.5}
                                                >
                                                    <Box className="flex flex-row items-center justify-start gap-2">
                                                        {col.icon}
                                                        {col.label}
                                                    </Box>
                                                    {renderSortingIcons(col)}
                                                </Box>
                                            )}
                                        </StyledHeadCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentContacts.length > 0 ? (
                                    currentContacts.map((row) => (
                                        <TableRow key={row.id}>
                                            {columnsWithOffsets.map((col, colIdx) => {
                                                if (col.id === "select") {
                                                    const isSelected = selected.includes(row.id);
                                                    return (
                                                        <StyledBodyCell
                                                            key={`${row.id}-select`}
                                                            align="center"
                                                            sticky={col.sticky}
                                                            left={col.left}
                                                            sx={{
                                                                padding: 0,
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <Checkbox
                                                                checked={isSelected}
                                                                onChange={() => handleRowSelect(row.id)}
                                                                sx={{
                                                                    "&.Mui-checked": {
                                                                        color: "#2D7A89",
                                                                    },
                                                                    "& .MuiSvgIcon-root": {
                                                                        fontSize: 16,
                                                                    },
                                                                    marginX: "auto",
                                                                }}
                                                            />
                                                        </StyledBodyCell>
                                                    );
                                                } else if (col.id === "name") {
                                                    return (
                                                        <StyledBodyCell key={`${row.id}-${col.id}`} sticky={col.sticky} left={col.left}>
                                                            <Box className="flex items-center gap-2">
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "0.875rem",
                                                                        fontFamily: "Inter, sans-serif",
                                                                    }}
                                                                >
                                                                    {row.name}
                                                                </Typography>
                                                            </Box>
                                                        </StyledBodyCell>
                                                    );
                                                } else {
                                                    return (
                                                        <StyledBodyCell key={`${row.id}-${col.id}`} sticky={col.sticky} left={col.left}>
                                                            {row[col.id] || ""}
                                                        </StyledBodyCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            align="center"
                                            sx={{
                                                py: 3,
                                                color: "gray",
                                                borderRight: "1px solid #e7e7e7",
                                                borderBottom: "1px solid #e7e7e7",
                                            }}
                                        >
                                            No contacts found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    {filteredContactsByName.length > 0 && (
                        <Box className="mt-4 flex justify-end pb-4 mr-[24px]" sx={{ maxWidth: "100%" }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                                siblingCount={1}
                                boundaryCount={1}
                                shape="rounded"
                                renderItem={(item) => (
                                    <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />
                                )}
                                sx={{
                                    "& .MuiPaginationItem-root": {
                                        fontFamily: "Inter, sans-serif",
                                        color: "#050505",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "4px",
                                    },
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        backgroundColor: "#2D7A89",
                                        color: "#fff",
                                        borderRadius: "4px",
                                    },
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}
