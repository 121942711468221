import { useNavigate } from "react-router-dom";

const points = {
    row1: ["name", "company", "job title", "location", "work email", "personal email", "mobile number", "more"],
    // row2: ["personal email", "phone number", "more"],
};

export default function Datapoints() {
    const navigate = useNavigate();
    return (
        <div className={`flex flex-col items-center text-primary-black w-full gap-8 py-24 px-6`}>
            <h2 className="border border-[#e7e7e7] text-[#5D5D5D] rounded-lg text-sm py-2 px-4 bg-white text-center w-fit">
                WHAT DATA DO I GET?
            </h2>
            <div className="flex flex-wrap capitalize items-center font-medium mt-6 justify-center max-w-[880px] gap-4">
                {points.row1.map((item, ind) => {
                    return (
                        <div className={`flex rounded border border-[#e7e7e7] px-4 py-2 `}>
                            <p className="font-Inter font-normal leading-[1.4]">{item}</p>
                        </div>
                    );
                })}
            </div>

            <div className="mt-12 flex flex-col items-center  text-center">
                <h1 className="text-4xl font-semibold">Need more credits?</h1>
                <p className="font-medium text-md opacity-70 mt-7">We'll be happy to discuss a tailored packaged that matches your needs</p>
                <button
                    onClick={() => navigate("/signin")}
                    className="bg-primary-black text-white py-4 px-16  rounded-lg mt-14 hover:opacity-80"
                >
                    Try Fuse AI Free
                </button>
            </div>
        </div>
    );
}
