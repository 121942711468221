import React, { useState, useMemo } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Box,
    Typography,
    Pagination,
    PaginationItem,
    Checkbox,
} from "@mui/material";

// 1) Import the arrow & pagination icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

// 2) Import styled from MUI system
import { styled } from "@mui/system";
import { DateIcon, FileIcon, VisitsIcon } from "../analytics/Icon";
import { kompassColors } from "@/theme/palette";
import ExportButton from "./ExportButton";
import { toast } from "react-toastify";
import { CustomButton } from "@/components/crm/contacts/Button";

// --------------------
// 3) Styled components
// --------------------
const StyledHeadCell = styled(TableCell)(({ highlight }) => ({
    backgroundColor: "#f4f3f0",
    fontWeight: "bold",
    userSelect: "none",
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    cursor: "pointer",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#e0e0e0",
    // }),
}));

const StyledBodyCell = styled(TableCell)(({ highlight }) => ({
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#fafafa",
    // }),
}));

// ----------------------------
// 4) Example column definitions
// ----------------------------
/**
 * We create an array of column objects, each describing:
 *  - id (the key in each csv object)
 *  - label (the column header)
 *  - width (optional)
 *  - sortable (boolean)
 */
const columns = [
    {
        id: "filename",
        label: "File",
        width: 300,
        sortable: true,
        icon: <FileIcon />,
    },
    {
        id: "record_count",
        // Example: "Record Count (Includes new and repeat visitors)"
        // We'll store the extra text in the label, or you can split it into
        // multiple lines with e.g. <Typography> blocks if you prefer.
        label: "Record Count (Includes new and repeat visitors)",
        width: 350,
        sortable: true,
        icon: <VisitsIcon />,
    },
    {
        id: "created_at",
        label: "Created Date",
        width: 200,
        sortable: true,
        icon: <DateIcon />,
    },
];

// ----------------------------
// 5) Main component
// ----------------------------
export default function ExportsTable({ csvs, rowsPerPage = 10 }) {
    const [selected, setSelected] = useState([]);

    // ---- Original CSV Download Logic ----
    function convertArrayToCSV(array) {
        if (!array || array.length === 0) return "";
        const headers = Object.keys(array[0]).join(",");
        const rows = array.map((obj) => Object.values(obj).join(","));
        return [headers, ...rows].join("\n");
    }

    async function downloadCSV(csvContent, filename) {
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const startDownload = async () => {
        if (!selected.length) {
            toast.error("Select profiles to export");
        }

        for (const id of selected) {
            const csvItem = csvs.find((item) => item.created_at === id);
            if (csvItem?.csv_content && csvItem.csv_content.length) {
                const csvContent = convertArrayToCSV(csvItem.csv_content);
                await downloadCSV(csvContent, csvItem.filename);
            }
        }
    };

    // ---- Table Sorting State & Logic ----
    const [order, setOrder] = useState("asc"); // "asc" or "desc"
    const [orderBy, setOrderBy] = useState(""); // which column is sorted
    const [hoveredCol, setHoveredCol] = useState(null); // for highlight effect

    const handleSort = (columnId) => {
        // If user clicks on the same column, toggle asc/desc
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    // "sortedCsvs" is re-computed only when csvs, order, or orderBy changes.
    const sortedCsvs = useMemo(() => {
        if (!csvs) return [];
        if (!orderBy) return csvs; // if no sort column chosen yet, skip sorting

        return [...csvs].sort((a, b) => {
            const valA = a[orderBy];
            const valB = b[orderBy];

            if (orderBy === "record_count") {
                // If sorting by numeric field
                const numA = Number(valA) || 0;
                const numB = Number(valB) || 0;
                return order === "asc" ? numA - numB : numB - numA;
            } else if (orderBy === "created_at") {
                // If sorting by date
                const dateA = new Date(valA).getTime();
                const dateB = new Date(valB).getTime();
                return order === "asc" ? dateA - dateB : dateB - dateA;
            } else {
                // Otherwise, treat as string
                const strA = String(valA ?? "").toLowerCase();
                const strB = String(valB ?? "").toLowerCase();
                if (strA < strB) return order === "asc" ? -1 : 1;
                if (strA > strB) return order === "asc" ? 1 : -1;
                return 0;
            }
        });
    }, [csvs, order, orderBy]);

    // ---- Pagination State & Logic ----
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(sortedCsvs.length / rowsPerPage);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    // Grab only the CSVs on the current page
    const currentCsvs = sortedCsvs.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // ---- Selection State & Logic ----
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = csvs.map((n) => n.created_at);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };
    const handleSelectClick = (id) => {
        setSelected((prev) => (prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]));
    };

    // ---- Helper: Sorting Icon Rendering ----
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    return (
        <>
            <div className="flex flex-wrap gap-10 justify-between items-center p-[24px] border-b border-[#E7E7E7]">
                <div className="flex flex-col gap-2">
                    <div className="text-[#050505] text-base font-semibold font-['Inter']">Profile Export</div>
                    <div className="text-[#6d6d6d] text-sm font-medium font-['Inter'] leading-[21px]">
                        Generate an export to be downloaded to your device.
                    </div>
                </div>
                <div className="flex gap-4 items-center">
                    {selected.length > 0 && (
                        <>
                            <p className="text-sm font-medium">Selected: {selected.length}</p>
                        </>
                    )}
                    {/* <div className="flex gap-10 items-center px-4 py-2 text-base leading-tight rounded border border-solid border-black border-opacity-10 text-stone-950 text-opacity-50">
                <div className="flex gap-2 justify-center items-center self-stretch my-auto">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/341c291c385adfac83a7137336bf4e7b5b8c0ca7c23cd0ceca6460d52cece012?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79" alt=""
                        className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                    />
                    <span>Select a date range</span>
                </div>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8869c21b02a8f09f51e28541e50d254aea4b87cae988e5209ee24d0e284029e?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79" alt=""
                    className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                />
            </div> */}
                    <CustomButton
                        onClick={startDownload}
                        title="Export Profiles"
                        height={36}
                        width={164}
                        px={24}
                        py={12}
                        img={true}
                        imgSrc={
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_1094_6516" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1094_6516)">
                                    <path
                                        d="M9.25 16V8.875L7.0625 11.0625L6 10L10 6L14 10L12.9375 11.0625L10.75 8.875V16H9.25ZM4 6V4.5C4 4.0875 4.14694 3.73438 4.44083 3.44063C4.73472 3.14688 5.08806 3 5.50083 3H14.5058C14.9186 3 15.2708 3.14688 15.5625 3.44063C15.8542 3.73438 16 4.0875 16 4.5V6H14.5V4.5H5.5V6H4Z"
                                        fill="white"
                                    />
                                </g>
                            </svg>
                        }
                        titleTextSize={14}
                        titleFontWeight={500}
                    />
                </div>
            </div>
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    borderTop: "1px solid #e7e7e7",
                    borderLeft: "1px solid #e7e7e7",
                    borderRight: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    margin: "24px",
                    width: "96.5% !important",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        marginTop: "8px",
                        marginBottom: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#aaa",
                        borderRadius: "6px",
                    },
                }}
            >
                <Table sx={{ borderCollapse: "separate", tableLayout: "fixed" }}>
                    {/* TABLE HEAD */}
                    <TableHead>
                        <TableRow>
                            <StyledHeadCell sx={{ width: 36, padding: 0, textAlign: "center" }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < csvs.length}
                                    checked={csvs.length > 0 && selected.length === csvs.length}
                                    onChange={handleSelectAllClick}
                                    sx={{
                                        padding: 0,
                                        "&.Mui-checked": {
                                            color: "#2D7A89",
                                        },
                                        "&.MuiCheckbox-indeterminate": {
                                            color: "#2D7A89",
                                            fill: "#2D7A89",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 16,
                                        },
                                    }}
                                />
                            </StyledHeadCell>
                            {columns.map((col, colIdx) => (
                                <StyledHeadCell
                                    key={col.id}
                                    sx={{ width: col.width }}
                                    highlight={hoveredCol === colIdx}
                                    onMouseEnter={() => setHoveredCol(colIdx)}
                                    onMouseLeave={() => setHoveredCol(null)}
                                    onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                    style={{
                                        cursor: col.sortable ? "pointer" : "default",
                                        userSelect: "none",
                                    }}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box display="flex" alignItems="center" gap={1}>
                                            {col.icon}
                                            <Typography fontSize={14} fontWeight="medium" color="#5d5d5d" sx={{ lineHeight: "21px" }}>
                                                {col.label}
                                            </Typography>
                                        </Box>
                                        {renderSortingIcons(col)}
                                    </Box>
                                </StyledHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* TABLE BODY */}
                    <TableBody>
                        {currentCsvs && currentCsvs.length > 0 ? (
                            currentCsvs.map((csv, index) => {
                                // We want the 'absolute' index in sortedCsvs if needed
                                // But if you just need an unique key, use index or some ID from csv
                                const absoluteIndex = sortedCsvs.indexOf(csv);
                                const isSelectedRow = isSelected(csv.created_at);

                                return (
                                    <TableRow
                                        key={absoluteIndex}
                                        sx={{
                                            background: isSelectedRow ? "#F4F3F0" : "white",
                                            "&:hover": {
                                                background: "#F4F3F0",
                                            },
                                        }}
                                    >
                                        <StyledBodyCell sx={{ width: 36, padding: 0, textAlign: "center" }}>
                                            <Checkbox
                                                checked={isSelectedRow}
                                                onChange={() => handleSelectClick(csv.created_at)}
                                                sx={{
                                                    padding: 0,
                                                    "&.Mui-checked": {
                                                        color: "#2D7A89",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                        </StyledBodyCell>

                                        {columns.map((col) => {
                                            if (col.id === "filename") {
                                                // "File" column => clickable for download
                                                return (
                                                    <StyledBodyCell key={col.id}>
                                                        <div
                                                            className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]"
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {csv.filename}
                                                        </div>
                                                    </StyledBodyCell>
                                                );
                                            } else if (col.id === "record_count") {
                                                return (
                                                    <StyledBodyCell key={col.id}>
                                                        <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">
                                                            {csv.record_count}
                                                        </div>
                                                    </StyledBodyCell>
                                                );
                                            } else if (col.id === "created_at") {
                                                // Create a new Date object
                                                const dateObj = new Date(csv.created_at);
                                                const day = dateObj.getDate();
                                                const year = dateObj.getFullYear();

                                                // By default, `toLocaleString('en-GB', { month: 'short' })` gives 'Sep' for September,
                                                // but you specifically want 'Sept'. We'll handle that manually:
                                                const months = [
                                                    "Jan",
                                                    "Feb",
                                                    "Mar",
                                                    "Apr",
                                                    "May",
                                                    "Jun",
                                                    "Jul",
                                                    "Aug",
                                                    "Sept",
                                                    "Oct",
                                                    "Nov",
                                                    "Dec",
                                                ];
                                                const monthShort = months[dateObj.getMonth()]; // 0-based index

                                                // Construct the desired format => "12 Sept 2024"
                                                const formattedDate = `${day} ${monthShort} ${year}`;

                                                return (
                                                    <StyledBodyCell key={col.id}>
                                                        <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">
                                                            {formattedDate}
                                                        </div>
                                                    </StyledBodyCell>
                                                );
                                            }
                                            // Fallback if you add more columns
                                            return (
                                                <StyledBodyCell key={col.id}>
                                                    <Typography sx={{ color: "#5D5D5D", fontSize: "14px", lineHeight: "20px" }}>
                                                        {String(csv[col.id] ?? "")}
                                                    </Typography>
                                                </StyledBodyCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    align="center"
                                    sx={{
                                        py: 3,
                                        color: "gray",
                                        borderRight: "1px solid #e7e7e7",
                                        borderBottom: "1px solid #e7e7e7",
                                    }}
                                >
                                    {csvs && csvs.length === 0 ? "Nothing to display" : "Loading..."}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* PAGINATION */}
            {sortedCsvs.length > rowsPerPage && (
                <div className="mr-[12px] 2xl:mr-[24px] mb-4">
                    <Box className="mt-4 flex w-full justify-end pb-4">
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            siblingCount={1}
                            boundaryCount={1}
                            shape="rounded"
                            renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                            sx={{
                                "& .MuiPaginationItem-root": {
                                    fontFamily: "Inter, sans-serif",
                                    color: "#050505",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "4px",
                                },
                                "& .MuiPaginationItem-root.Mui-selected": {
                                    backgroundColor: "#2D7A89",
                                    color: "#fff",
                                    borderRadius: "4px",
                                },
                            }}
                        />
                    </Box>
                </div>
            )}
        </>
    );
}
