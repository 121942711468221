import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueries, useMutation, useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress, Pagination, PaginationItem } from "@mui/material";
import { toast } from "react-toastify";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import MainLoader from "../MainLoader";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import useBulk from "@/hooks/useBulk";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import SortIcon from "@/components/icons/SortIcon";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";
import { useNavigate } from "react-router-dom";
import { downloadProfilesCSV } from "@/utils/common";
import { utils, writeFile, write } from "xlsx";
import BulkResultRow from "./BulkResultRow";
import { ReactComponent as UploadIcon } from "@/assets/image/bulk/upload.svg";
import { ReactComponent as ValidateIcon } from "@/assets/image/bulk/email_validate.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import GroupIcon from "@mui/icons-material/UpcomingOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import TaskAltIcon from "@mui/icons-material/VisibilityOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JSZip from "jszip";

function descendingComparator(a, b, orderBy) {
    let valueA, valueB;

    switch (orderBy) {
        case "status":
            valueA = getStatusValue(a);
            valueB = getStatusValue(b);
            break;
        case "contactTitle": // Sorting by Name column
            valueA = a.contactTitle || a.fileName || "Untitled";
            valueB = b.contactTitle || b.fileName || "Untitled";
            break;
        default:
            valueA = a[orderBy];
            valueB = b[orderBy];
            break;
    }

    if (valueB == null && valueA == null) {
        return 0;
    } else if (valueB == null) {
        return -1;
    } else if (valueA == null) {
        return 1;
    } else if (valueB < valueA) {
        return -1;
    } else if (valueB > valueA) {
        return 1;
    } else {
        return 0;
    }
}

function getStatusValue(row) {
    if (row.error) {
        return 0; // Failed
    } else if (row.completed) {
        return 2; // Completed
    } else {
        return 1; // Processing
    }
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
        const orderResult = comparator(a[0], b[0]);
        if (orderResult !== 0) return orderResult;
        return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
}

// Head cells for the table
const headCells = [
    {
        id: "contactTitle",
        numeric: false,
        disablePadding: true,
        label: "Name",
        icon: <DescriptionOutlinedIcon className="!h-5 !w-5" />,
    },
    {
        id: "numberOfContacts",
        numeric: false,
        disablePadding: true,
        label: "Total Records",
        icon: <GroupIcon className="!h-5 !w-5" />,
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: true,
        label: "Date",
        icon: <CalendarTodayIcon className="!h-4 !w-4" />,
    },
    {
        id: "fileUploadType",
        numeric: false,
        disablePadding: true,
        label: "File Upload Type",
        icon: <UploadIcon className="!h-5 !w-5" />,
    },
    {
        id: "creditsUsed",
        numeric: false,
        disablePadding: true,
        label: "Credits Used",
        icon: <GroupIcon className="!h-5 !w-5" />,
    },
    {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status",
        icon: <TaskAltIcon className="!h-5 !w-5" />,
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        sortable: false,
        icon: <MoreHorizIcon className="!h-5 !w-5 rotate-90 transform" />,
    },
];

// EnhancedTableHead component
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "12px",
                    fontFamily: "Inter",
                    fontSize: 14,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                        width: "36px",
                        padding: 0,
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                        borderBottom: `1px solid ${kompassColors.light}`,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                <TableCell
                    padding="none"
                    sx={{
                        width: "36px",
                        minWidth: "36px",
                        maxWidth: "36px",
                    }}
                >
                    <div className="flex justify-center">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            sx={{
                                "&.Mui-checked": {
                                    color: "#2D7A89",
                                },
                                "&.MuiCheckbox-indeterminate": {
                                    color: "#2D7A89",
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: 16,
                                },
                            }}
                            inputProps={{
                                "aria-label": "select all results",
                            }}
                        />
                    </div>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="none">
                        {headCell.sortable === false ? (
                            <div className="flex items-center gap-2 text-[#6D6D6D]">
                                {headCell.icon}
                                {headCell.label}
                            </div>
                        ) : (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    color: "#6D6D6D",
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {headCell.icon}
                                    {headCell.label}
                                </div>
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// EnhancedTableToolbar component
function EnhancedTableToolbar(props) {
    const {
        numSelected,
        setSelected,
        onDownloadSelected,
        isDisabled,
        selectedData,
        downloadLoading,
        handleVisibleDeleteDialog,
        currentDeleteId,
        currentDeleteType,
    } = props;
    const navigate = useNavigate();

    const handleBulkDelete = () => {
        // Set the IDs and types for all selected items
        const firstItem = selectedData[0];
        currentDeleteId.current = selectedData.map((item) => (item.enrichType === "emailValidation" ? item.fileId : item._id));
        currentDeleteType.current = firstItem.enrichType === "emailValidation" ? "emailValidation" : "bulkEnrich";
        handleVisibleDeleteDialog(true);
    };

    return (
        <>
            <div className="flex items-center justify-between w-full">
                <div className="text-sm select-none font-InterMedium">
                    {numSelected > 0 ? (
                        <>
                            {`${numSelected} Selected`}
                            <span
                                className="text-stone-350 ml-2 hover:underline cursor-pointer whitespace-nowrap"
                                onClick={() => setSelected([])}
                            >
                                Clear All
                            </span>
                            <span
                                className="text-stone-350 ml-2 hover:underline cursor-pointer whitespace-nowrap"
                                onClick={handleBulkDelete}
                            >
                                Delete
                            </span>
                        </>
                    ) : null}
                </div>
                <div className="flex flex-row items-center gap-2">
                    <button onClick={() => navigate("/bulkenrich/upload")} className="main-white-button">
                        <UploadIcon className="h-5 w-5 fill-current text-stone-950" />
                        Upload Contacts
                    </button>
                    <button onClick={() => navigate("/bulkenrich/validate-emails")} className="main-white-button">
                        <ValidateIcon className="h-5 w-5 fill-current text-stone-950" />
                        Validate Emails
                    </button>
                    <CRMPushMenu selected={selectedData} profileType={"bulk"} isDisabled={isDisabled} />
                    <button
                        onClick={onDownloadSelected}
                        className={
                            "main-white-button" +
                            (numSelected > 0 && !isDisabled
                                ? " !border-stone-500 !text-stone-950"
                                : " !border-stone-500 !text-stone-350 !cursor-not-allowed !pointer-events-none")
                        }
                        disabled={numSelected === 0 || isDisabled}
                    >
                        {downloadLoading ? (
                            <CircularProgress className="!h-4 !w-4" color="inherit" />
                        ) : (
                            <UploadIcon
                                className={
                                    "h-5 w-5 rotate-180 transform" +
                                    (numSelected > 0 && !isDisabled ? " fill-stone-950" : " fill-stone-350")
                                }
                            />
                        )}
                        Download Result
                    </button>
                </div>
            </div>
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

// BulkResultTable component
export default function BulkResultTable() {
    const { bulkEnrichResults, bulkEnrichDeleteResult, emailValidationResults, bulkEnrichOutputs } = useBulk();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [visibleDeleteDialog, handleVisibleDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [isDisabledPushAndSave, setIsDisabledPushAndSave] = useState(false);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdAt");
    const rowsPerPage = 10;
    const currentDeleteId = useRef(null);
    const currentDeleteType = useRef(null);

    const queryResults = useQueries({
        queries: [
            {
                queryKey: ["bulkEnrichResults"],
                queryFn: async () => {
                    const response = await bulkEnrichResults();
                    if (response?.status) {
                        return response.data;
                    } else {
                        toast.error(response?.message, { theme: "colored" });
                        return [];
                    }
                },
                refetchInterval: () => {
                    const needsReload = tableData.some((row) => row.enrichType !== "emailValidation" && !row?.error && !row?.completed);
                    return needsReload ? 3000 : false;
                },
                refetchIntervalInBackground: true,
                enabled: true,
            },
            {
                queryKey: ["emailValidationResults"],
                queryFn: async () => {
                    const response = await emailValidationResults();
                    if (response?.status) {
                        return response.data;
                    } else {
                        toast.error(response?.message, { theme: "colored" });
                        return [];
                    }
                },
                refetchInterval: () => {
                    const needsReload = tableData.some((row) => row.enrichType === "emailValidation" && !row?.error && !row?.completed);
                    return needsReload ? 6000 : false;
                },
                refetchIntervalInBackground: true,
                enabled: true,
            },
        ],
    });

    const isLoading = queryResults.some((query) => query.isLoading);

    const [bulkEnrichResultsData, emailValidationResultsData] = queryResults.map((query) => query.data || []);

    useEffect(() => {
        queryResults.forEach((query) => {
            if (query.isError) {
                toast.error(query.error.message, { theme: "colored" });
            }
        });
    }, [queryResults]);

    useEffect(() => {
        if (!isLoading) {
            const mappedValidationData = emailValidationResultsData.map((i) => {
                return { ...i, enrichType: "emailValidation", createdAt: i.created_at };
            });

            const mergedData = [...bulkEnrichResultsData, ...mappedValidationData];

            // Sort the merged data based on a property, e.g., 'date' or any other key

            const sortedData = mergedData.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            setTableData(sortedData);

            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [bulkEnrichResultsData, emailValidationResultsData, isLoading]);

    useEffect(() => {
        const isDisableButtonsForExport = () => {
            const noCompletedOrErroredData = tableData.filter((tableItem) => tableItem.error || !tableItem.completed);

            return noCompletedOrErroredData.some((tableItem) => {
                return selected.includes(tableItem._id);
            });
        };

        setIsDisabledPushAndSave(isDisableButtonsForExport());
    }, [selected, selected.length, tableData]);

    const reload = async () => {
        setLoading(true);
        const bulkEnrichResultsData = await bulkEnrichResults();

        const emailValidationResultsData = await emailValidationResults();

        const mappedValidationData = emailValidationResultsData.data.map((i) => {
            return { ...i, enrichType: "emailValidation", createdAt: i.created_at };
        });

        const mergedData = [...bulkEnrichResultsData.data, ...mappedValidationData];

        const sortedData = mergedData.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setTableData(sortedData);

        const maxPage = Math.max(0, Math.ceil(sortedData.length / rowsPerPage) - 1);

        if (page > maxPage) {
            setPage(maxPage);
        }

        setLoading(false);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onDownloadSelected = async () => {
        if (selected.length === 0) {
            toast.info("Please select at least one record to download");
            return;
        }
        setDownloadLoading(true);

        try {
            // If only one item is selected, use existing direct download logic
            if (selected.length === 1) {
                const itemId = selected[0];
                const item = tableData.find((item) => item._id === itemId);
                if (item?.enrichType !== "emailValidation") {
                    const content = await bulkEnrichOutputs(itemId);
                    downloadProfilesCSV(item?.contactTitle, content?.data);
                } else {
                    const content = tableData.find((item) => item._id === itemId);
                    const transformedData = content.emails.map((email) => ({
                        Email: email.email,
                        Status: email["ZB Status"],
                    }));
                    const sheet = utils.json_to_sheet(transformedData);
                    const wb = utils.book_new();
                    utils.book_append_sheet(wb, sheet, "Results");
                    writeFile(wb, `${content.fileName}.xlsx`);
                }
                return;
            }

            const zip = new JSZip();

            for (const itemId of selected) {
                const item = tableData.find((item) => item._id === itemId);

                if (item?.enrichType !== "emailValidation") {
                    const content = await bulkEnrichOutputs(itemId);
                    const csvData = await downloadProfilesCSV(item?.contactTitle, content?.data, true);
                    zip.file(csvData.filename, csvData.content);
                } else {
                    const content = tableData.find((item) => item._id === itemId);
                    const transformedData = content.emails.map((email) => ({
                        Email: email.email,
                        Status: email["ZB Status"],
                    }));
                    const sheet = utils.json_to_sheet(transformedData);
                    const wb = utils.book_new();
                    utils.book_append_sheet(wb, sheet, "Results");

                    const wbout = write(wb, { bookType: "xlsx", type: "binary" });
                    const buf = new ArrayBuffer(wbout.length);
                    const view = new Uint8Array(buf);
                    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;
                    zip.file(`${content.fileName}.xlsx`, buf);
                }
            }

            const zipContent = await zip.generateAsync({ type: "blob" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(zipContent);
            link.download = `bulk_download_${new Date().toISOString().slice(0, 10)}.zip`;
            link.click();
            URL.revokeObjectURL(link.href);
        } catch (error) {
            toast.error(error?.message || "Something went wrong, try again");
            console.log(error);
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleDeleteCsvEnrich = (id) => {
        currentDeleteId.current = id;
        currentDeleteType.current = "bulkEnrich";
        handleVisibleDeleteDialog(true);
    };

    const handleDeleteValidation = (id) => {
        currentDeleteId.current = id;
        currentDeleteType.current = "emailValidation";
        handleVisibleDeleteDialog(true);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const sortedData = useMemo(() => {
        return stableSort(tableData, getComparator(order, orderBy));
    }, [tableData, order, orderBy]);

    const visibleRows = useMemo(
        () => sortedData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
        [page, sortedData, rowsPerPage]
    );

    const queryClient = useQueryClient();

    const deleteFilesMutation = async (params) => {
        setSelected([]);
        const res = await deleteFiles(JSON.stringify(params));
        return res;
    };

    const { mutateAsync: deleteFilesTrigger } = useMutation({
        mutationFn: deleteFilesMutation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["ValidationFiles"] });
        },
    });

    const deleteFiles = useHTTPRequest("/email-validation?", "DELETE");

    const handleDownloadValidation = async (row) => {
        try {
            const content = tableData.find((item) => item._id === row._id);
            if (!content) {
                return;
            }

            const transformedData = content.emails.map((email) => ({
                Email: email.email,
                Status: email["ZB Status"],
            }));

            const sheet = utils.json_to_sheet(transformedData);

            const wb = utils.book_new();

            utils.book_append_sheet(wb, sheet, "Results");

            writeFile(wb, `${content.fileName}.xlsx`);
        } catch (error) {
            toast.error(error?.message ? error?.message : "Something went wrong", {
                theme: "colored",
            });
        }
    };

    const handleDownloadCsvEnrich = async (rowData) => {
        try {
            const response = await bulkEnrichOutputs(rowData?._id);
            downloadProfilesCSV(rowData?.contactTitle, response?.data);
        } catch (error) {
            toast.error(error?.message ? error?.message : "Something went wrong", {
                theme: "colored",
            });
        }
    };

    return (
        <>
            {loading && <MainLoader />}
            <Box sx={{ width: "100%", mt: "10px" }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    setSelected={setSelected}
                    onDownloadSelected={onDownloadSelected}
                    isDisabled={isDisabledPushAndSave}
                    selectedData={selected.map((id) => tableData.find((item) => item._id === id))}
                    downloadLoading={downloadLoading}
                    handleVisibleDeleteDialog={handleVisibleDeleteDialog}
                    currentDeleteId={currentDeleteId}
                    currentDeleteType={currentDeleteType}
                />
                <div className="border rounded-[8px] border-stone-250 mt-5">
                    <TableContainer sx={{ borderRadius: "8px" }}>
                        <Table
                            sx={{
                                minWidth: {
                                    xs: 300,
                                    lg: 750,
                                },
                                borderCollapse: "separate",
                            }}
                            aria-labelledby="tableTitle"
                            size={"medium"}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={tableData.length}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody
                                sx={{
                                    "& .MuiTableCell-root": {
                                        padding: "12px",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        color: kompassColors.black,
                                        fontWeight: 500,
                                    },
                                    "& .MuiTableCell-root:not(:last-child)": {
                                        borderRight: `1px solid ${kompassColors.light}`,
                                    },
                                }}
                            >
                                {visibleRows.map((row, index) => (
                                    <BulkResultRow
                                        key={row._id}
                                        isItemSelected={isSelected(row._id)}
                                        setTableData={setTableData}
                                        row={row}
                                        labelId={`enhanced-table-checkbox-${index}`}
                                        handleClick={handleClick}
                                        handleDeleteCsvEnrich={handleDeleteCsvEnrich}
                                        handleDeleteValidation={handleDeleteValidation}
                                        handleDownloadValidation={handleDownloadValidation}
                                        handleDownloadCsvEnrich={handleDownloadCsvEnrich}
                                        index={index}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="flex justify-end w-full pt-4 pb-20">
                    <Pagination
                        className="font-Inter"
                        count={Math.ceil(tableData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        boundaryCount={1}
                        siblingCount={0}
                        renderItem={(item) => <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </div>
            </Box>
            <ConfirmDeleteDialog
                open={visibleDeleteDialog}
                handleClose={() => {
                    handleVisibleDeleteDialog(false);
                    currentDeleteId.current = null;
                }}
                handleYes={async () => {
                    handleVisibleDeleteDialog(false);
                    setLoading(true);

                    try {
                        if (currentDeleteType.current === "emailValidation") {
                            // Delete email validation data - now handles array of IDs
                            await deleteFilesTrigger({
                                fileIds: Array.isArray(currentDeleteId.current) ? currentDeleteId.current : [currentDeleteId.current],
                            });
                            toast.success("Successfully removed!", { theme: "colored" });
                        } else {
                            // Delete bulk enrichment data - now handles array of IDs
                            const ids = Array.isArray(currentDeleteId.current) ? currentDeleteId.current : [currentDeleteId.current];
                            for (const id of ids) {
                                const response = await bulkEnrichDeleteResult(id);
                                if (!response?.status) {
                                    toast.error(response?.message, { theme: "colored" });
                                }
                            }
                            toast.success("Successfully removed!", { theme: "colored" });
                        }
                        setSelected([]);
                        await reload();
                    } catch (error) {
                        toast.error(error?.message || "Something went wrong!", { theme: "colored" });
                    } finally {
                        setLoading(false);
                        currentDeleteId.current = null;
                        currentDeleteType.current = null;
                    }
                }}
            />
        </>
    );
}
