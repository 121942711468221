import useAuth from "@/hooks/useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";
import axios from "axios";

const useTranscripts = () => {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const getTranscriptNotes = async ({ pageNum = 1, limit = 10 } = {}) => {
        return fetchWithAuth(`/api/v1/notes/transcripts?pageNum=${pageNum}&limit=${limit}`, "GET");
    };

    const downloadTranscript = async (noteId) => {
        try {
            const authToken = await getAuthToken();
            const response = await axios.get(`${apiUrl}/api/v1/meetingBots/transcript/${noteId}/download`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `transcript.docx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading transcript:", error);
        }
    };

    const getUserCalendars = async () => {
        return fetchWithAuth("/api/v1/meetingBots/calendar/user", "GET");
    };

    const updateCalendarRecording = async (calendarId, shouldRecord, meetingType = "Internal") => {
        return fetchWithAuth("/api/v1/meetingBots/calendar/recording", "POST", {
            calendarId,
            shouldRecord,
            meetingType,
        });
    };

    const createCalendar = async (email) => {
        return fetchWithAuth("/api/v1/meetingBots/calendar", "POST", {
            email,
        });
    };

    const deleteCalendar = async (calendarId) => {
        return fetchWithAuth("/api/v1/meetingBots/calendar/delete", "POST", {
            calendarId,
        });
    };

    return {
        getTranscriptNotes,
        downloadTranscript,
        getUserCalendars,
        updateCalendarRecording,
        createCalendar,
        deleteCalendar,
    };
};

export default useTranscripts;
