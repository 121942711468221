import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import DirectoryTableHeader from "./DirectoryTableHeader";
import DirectoryTableRow from "./DirectoryTableRow";

export default function DirectoryTable({ data, loading, handleRequestSort, order, orderBy }) {
    return (
        <>
            <div className="border rounded-[8px] border-[#e7e7e7] mt-3">
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table
                        sx={{
                            minWidth: {
                                xs: 300,
                                lg: 750,
                            },
                            borderCollapse: "separate",
                        }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        <DirectoryTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                        <TableBody
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    padding: "5px 12px",
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    border: "1px solid #E8E7E7",
                                },
                            }}
                        >
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} className="text-black" style={{ textAlign: "center", height: "200px" }}>
                                        <CircularProgress size={24} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            ) : data.length > 0 ? (
                                data.map((row, index) => {
                                    return <DirectoryTableRow key={row._id} row={row} index={index} />;
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: "center", height: "200px" }}>
                                        No results found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
