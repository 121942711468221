import React, { useMemo } from "react";
import { Affix } from "rsuite";
import { PRODUCT_TYPE } from "@/utils/constants";
import { formatNumberWithCommas, formatNumberWithLetters } from "@/utils/common";

export default function PricingTable({ tiers, annual, products, topPosition, isUpgradePage = false }) {
    const container = React.useRef();

    const productPrices = useMemo(() => {
        return products.reduce((result, product) => {
            result[product.key] = product["creditsPerProduct"];
            return result;
        }, {});
    }, [products]);

    return (
        <div className={`w-full mt-28 font-Inter flex-col bg-white px-6 pre-xl:px-12 hidden lg:flex ${!isUpgradePage && "py-28"}`}>
            {/* Div with Affix */}
            <Affix top={topPosition || 0} container={() => container.current}>
                <div className="flex w-full justify-between border-b border-[#e7e7e7] bg-white">
                    <div className="font-InterSemiBold text-2xl mb-2 font-bold flex items-center w-48">
                        Total monthly usage limits by product
                    </div>
                    <div className="flex rounded-tl-lg rounded-tr-lg border border-b-0 border-[#e7e7e7]">
                        <div className="flex h-full 2xs:w-[60px] xs:w-[80px] md:w-[120px] lg:w-[160px] xl:w-[180px] 2xl:w-[200px] flex-col items-center justify-center border-r-[1px] border-[#e7e7e7] p-2 md:p-4 xl:p-8">
                            <div className="font-InterSemiBold text-sm xs:text-md md:text-2xl">Credits per Product</div>
                        </div>
                        {products.map((product) => {
                            const price_obj =
                                product.variations.length > 1
                                    ? product.variations.find((x) => x.interval === (annual ? "year" : "month"))
                                    : product.variations[0];
                            return (
                                <div
                                    key={product.key}
                                    className={`${
                                        tiers[product.key].theme.main
                                    } font-Inter flex h-full 2xs:w-[60px] xs:w-[80px] md:w-[120px] lg:w-[160px] xl:w-[180px] 2xl:w-[200px] flex-col items-center justify-center p-2 md:p-4 xl:p-8 last:rounded-tr-[6px]`}
                                >
                                    <>
                                        <div className="font-InterSemiBold text-2xl leading-[1.2]">
                                            {product.name === "Professional" ? "Starter" : product.name}
                                        </div>
                                        <div className="font-Inter flex items-end text-black mb-10">
                                            <span className="font-Inter mr-2 mt-4 text-base font-semibold text-black">
                                                ${annual ? price_obj.price / 1200 : price_obj.price / 100}
                                            </span>
                                            <span className="text-sm">/month</span>
                                        </div>
                                        <div className="w-full py-2.5 bg-primary-black rounded-lg">
                                            <p className="font-Inter text-white text-sm leading-[1.4] text-center">
                                                {product.name === "Free"
                                                    ? annual
                                                        ? formatNumberWithLetters(Math.ceil(price_obj.credits) * 12)
                                                        : formatNumberWithLetters(Math.ceil(price_obj.credits))
                                                    : formatNumberWithLetters(Math.ceil(price_obj.credits))}{" "}
                                                Credits
                                            </p>
                                        </div>
                                    </>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Affix>

            {/* Benefits table */}
            <div ref={container} className="mt-8 mb-44 flex w-full flex-col">
                {Object.entries(PRODUCT_TYPE).map(([key, value]) => {
                    return (
                        <div
                            key={key}
                            className="my-0.5 py-1 grid w-full 2xs:grid-cols-[auto_repeat(4,44px)] xs:grid-cols-[auto_repeat(4,64px)] md:grid-cols-[auto_repeat(4,83px)] lg:grid-cols-[auto_repeat(5,160px)] xl:grid-cols-[auto_repeat(5,180px)] 2xl:grid-cols-[auto_repeat(5,200px)] gap-y-3 odd:bg-primary-header rounded-lg"
                        >
                            <div className="mr-auto font-Inter my-auto py-1.5 px-5 font-medium text-sm">{value}</div>
                            {value === PRODUCT_TYPE.CALL_RECORDING
                                ? ["30/per min", "166", "2,500", "5,000", "10,000"].map((tier) => {
                                      return (
                                          <div key={tier} className="font-Inter flex px-5 py-3 text-[#050505] justify-center text-sm">
                                              {tier}
                                          </div>
                                      );
                                  })
                                : value === PRODUCT_TYPE.AGENT_RESEARCH_QUERY
                                  ? ["5/search", "1000", "15,000", "30,000", "60,000"].map((tier) => {
                                        return (
                                            <div key={tier} className="font-Inter flex px-5 py-3 text-[#050505] justify-center text-sm">
                                                {tier}
                                            </div>
                                        );
                                    })
                                  : value === PRODUCT_TYPE.AGENT_LIST_BUILDER
                                    ? ["10/contact", "500", "7,500", "15,000", "30,000"].map((tier) => {
                                          return (
                                              <div key={tier} className="font-Inter flex px-5 py-3 text-[#050505] justify-center text-sm">
                                                  {tier}
                                              </div>
                                          );
                                      })
                                    : Object.keys(tiers).map((tier) => {
                                          const priceObj = productPrices[tier]?.find((product) => product.name === value);
                                          return (
                                              <div key={tier} className="font-Inter flex px-5 py-3 text-[#050505] justify-center text-sm">
                                                  {formatNumberWithCommas(
                                                      tier === "actionsPerProductTier" ? tiers[tier][value] : priceObj?.value
                                                  )}
                                              </div>
                                          );
                                      })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
