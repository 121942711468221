import dayjs from "dayjs";

export default function SummaryComponent({ creditsUsage, planInfo, teamsInfo, isTeamMember, isTeamOwner, teamInfo, navigate }) {
    const canBuyCredits = isTeamOwner;

    return (
        <div className="flex w-full flex-col items-center justify-start gap-10 p-4 2xl:p-6 rounded-xl border border-stone-250 bg-white">
            <div className="text-[#050505] w-full text-base font-semibold font-['Inter'] shrink grow basis-0">Summary</div>
            <div className="flex w-full flex-col items-center justify-end gap-4">
                <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex w-full flex-col items-center justify-start gap-2 self-stretch">
                        <div className="flex h-12 flex-col items-start justify-start gap-2 self-stretch">
                            <div className="text-[#050505] text-sm font-semibold font-['Inter'] px-3 py-1 rounded-md bg-[#F4F3F0]">
                                {isTeamMember ? (
                                    <>
                                        You are part of <b>{teamInfo?.parentUser?.userName}</b>'s Team{" "}
                                    </>
                                ) : (
                                    `${planInfo?.name ?? "Free"}${planInfo?.isPlannedForCancellation ? " (Cancelled)" : ""}`
                                )}
                            </div>
                            {!isTeamMember && (
                                <div className="inline-flex items-start justify-start gap-1 mt-5 self-stretch">
                                    <div className="text-[#6d6d6d] text-sm font-medium font-['Inter'] leading-[0.6]">
                                        {planInfo?.isPlannedForCancellation ? "Cancellation on" : "Renewal on"}
                                    </div>
                                    <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[0.6]">
                                        {dayjs(planInfo?.renewalDate).format("MMM D, YYYY h:mm A")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {!isTeamMember && (
                        <div className="w-full flex flex-col justify-end gap-4 self-stretch">
                            <div className="flex gap-2 justify-end text-left self-stretch text-[#050505] text-sm font-medium font-['Inter'] leading-[0.6]">
                                {teamsInfo?.used} / {teamsInfo?.total}
                                <p>Team members</p>
                            </div>
                            <div className="flex h-12 flex-col items-end gap-2 self-stretch">
                                <button
                                    className="cursor-pointer font-['Inter'] capitalize text-sm font-medium leading-md text-[#2d7a89] justify-end"
                                    onClick={() => {
                                        navigate(teamsInfo?.total - teamsInfo?.used >= 1 ? "/team" : "/upgrade");
                                    }}
                                >
                                    {teamsInfo?.total - teamsInfo?.used >= 1 ? "Invite Members To Your Team" : "Upgrade To Invite Members"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex flex-col items-center justify-end gap-2 self-stretch">
                    <div className="flex h-9 flex-col items-center justify-end gap-2 self-stretch">
                        <div className="inline-flex items-center justify-between self-stretch">
                            <div className="font-['Inter'] text-sm font-medium text-black capitalize">Credits used</div>
                            <div className="font-['Inter'] text-right text-sm font-medium text-[#173320]">
                                {creditsUsage?.used} / {creditsUsage?.total}
                            </div>
                        </div>
                        <div className="relative h-1.5 self-stretch rounded bg-stone-250">
                            <div
                                className="absolute left-0 top-0 h-1.5 rounded bg-[#050505]"
                                style={{
                                    width: `${(creditsUsage?.used / creditsUsage?.total) * 100}%`,
                                }}
                            ></div>
                        </div>
                    </div>

                    {canBuyCredits && (
                        <button
                            className="font-Inter leading-tight self-start text-sm font-normal text-[#2D7A89]"
                            onClick={() => {
                                navigate("/credits");
                            }}
                        >
                            Buy more credits
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
