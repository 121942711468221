import React from "react";

function LetterBox({ letter, isHighlighted = false, onClick }) {
    const baseClasses =
        "flex justify-center font-Inter items-center font-semibold p-2 w-[44px] h-[44px] rounded-lg border border-[#e7e7e7] cursor-pointer";
    const highlightedClasses = isHighlighted ? "text-white bg-primary-black" : "bg-white text-[#6D6D6D]";

    return (
        <div
            className={`${baseClasses} ${highlightedClasses}`}
            role="button"
            tabIndex="0"
            aria-label={`Letter ${letter}`}
            onClick={onClick}
        >
            {letter}
        </div>
    );
}

export default LetterBox;
