import React, { useState, useRef } from "react";
import DialogContent from "@mui/material/DialogContent";
import { TextField } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { BASE_BUTTON_STYLES } from "@/utils/common";
import useScheduledEmails from "@/hooks/useScheduledEmails";

const DialogWindow = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
        minWidth: "400px",
    },
}));

export const EditEmailModal = ({ data, onClose, className = "", open, handleEditEmail }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { editScheduledEmail } = useScheduledEmails();
    const editScheduledEmailRef = useRef();
    editScheduledEmailRef.current = editScheduledEmail;

    // States to store form inputs
    const [subject, setSubject] = useState(data.subject);
    const [body, setBody] = useState(data.body);
    const [scheduledDateUTC, setScheduledDateUTC] = useState(data.scheduledDateUTC);

    const handleConfirm = async () => {
        const response = await editScheduledEmailRef.current(data.id, subject, body, scheduledDateUTC);
        handleEditEmail({ ...data, subject, body, scheduledDateUTC });
        onClose();
    };

    return (
        <DialogWindow fullScreen={fullScreen} open={open} onClose={onClose} className={className}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className="text-[#090c05] text-[18px] font-semibold font-['Inter'] capitalize">Schedule Email</div>
                <button onClick={onClose} className="flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    padding: "24px",
                }}
            >
                <label className="text-sm">Subject of Email</label>
                <TextField variant="outlined" fullWidth value={subject} onChange={(e) => setSubject(e.target.value)} />
                <label className="text-sm">Body of Email</label>
                <TextField variant="outlined" fullWidth multiline rows={4} value={body} onChange={(e) => setBody(e.target.value)} />
                <label className="text-xs">Scheduled Date</label>
                <TextField
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }} // Only needed for datetime-local
                    value={scheduledDateUTC.slice(0, 16)}
                    onChange={(e) => setScheduledDateUTC(e.target.value)}
                />
                <div className="flex w-full justify-center gap-2 mt-4">
                    <Button
                        sx={{
                            ...BASE_BUTTON_STYLES,
                            backgroundColor: "transparent",
                            color: "#090C05",
                            ":hover": { backgroundColor: "#090C05", color: "#fff" },
                        }}
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                    <Button
                        sx={{
                            ...BASE_BUTTON_STYLES,
                            backgroundColor: "transparent",
                            color: "#090C05",
                            ":hover": { backgroundColor: "#090C05", color: "#fff" },
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </div>
            </DialogContent>
        </DialogWindow>
    );
};
