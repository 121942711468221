import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";

// Tab details configuration
const TAB_DETAILS = [
    { label: "Opportunities", path: "/records/opportunities" },
    { label: "Contacts", path: "/records/contacts" },
    { label: "Accounts", path: "/records/accounts" },
];

// Map paths to tab indices
const PATH_TO_TAB_INDEX = {
    "/records/opportunities": 0,
    "/records/contacts": 1,
    "/records/accounts": 2,
};

export default function RecordsTabs() {
    const navigate = useNavigate();
    const location = useLocation();

    // Determine the initial active tab based on the current path
    const getActiveTabIndex = (pathname) => {
        if (pathname.startsWith("/records/accounts")) {
            return 2; // Accounts tab
        }
        return PATH_TO_TAB_INDEX[pathname] ?? 0;
    };

    const [activeTab, setActiveTab] = useState(getActiveTabIndex(location.pathname));

    useEffect(() => {
        setActiveTab(getActiveTabIndex(location.pathname));
    }, [location.pathname]);

    const handleTabChange = (_, newValue) => {
        setActiveTab(newValue);
        navigate(TAB_DETAILS[newValue]?.path || "/");
    };

    return (
        <Box
            sx={{
                borderBottom: "1px solid #E7E7E7",
                width: "100%",
                fontFamily: "Inter",
                paddingLeft: "24px",
            }}
        >
            <Tabs
                value={activeTab}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTabChange}
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#050505",
                        height: "3px",
                    },
                    "& .MuiTab-root": {
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        marginRight: "8px",
                        padding: "0",
                        minWidth: "auto",
                        color: "#6D6D6D",
                        "&.Mui-selected": {
                            color: "#050505",
                        },
                    },
                    "& .MuiTab-root:last-child": {
                        marginRight: "0",
                    },
                }}
            >
                {TAB_DETAILS.map((tab) => (
                    <Tab key={tab.label} label={tab.label} />
                ))}
            </Tabs>
        </Box>
    );
}
