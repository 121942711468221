import BackdropLoader from "@/components/common/BackdropLoader";
import { NoPreview } from "@/components/icons/InboxIcon";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import NoMailBoxButton from "../NoMailBoxButton";
import useAIEngagement from "@/hooks/useAIEngagement";
import useLinkedinAutomation from "@/hooks/useLinkedinAutomation";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SingleChat } from "./SingleChat";
import { LeftMenu } from "./LeftMenu";
import { AllChats } from "./AllChats";
import { NewChat } from "./NewChat";

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

export const formatDate = (dateString, type = "both") => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
    const monthNumber = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear(); // Get the year in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const period = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    return type === "dateonly" ? `${year}-${monthNumber}-${day}` : `${day} ${month}, ${hour12}:${minutes} ${period}`;
};

const LinkedinInbox = () => {
    const { getAllChats, getSpecificChat, sendMessage, updateChat } = useLinkedinAutomation();
    const { getUnipileAuthData, getEmailCampain } = useAIEngagement();
    const [searchParams] = useSearchParams();

    const [chatsData, setChatsData] = useState([]);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const [allLinkedinAccounts, setAllLinkedinAccounts] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [singleChatGeneralData, setSingleChatGeneralData] = useState(null);
    const [activeChatId, setActiveChatId] = useState(null);
    const [activeTab, setActiveTab] = useState("inbox");
    const [isComposeNewChatOpen, setComposeNewChatOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [isSendButtonDisabled, setSendButtonDisabled] = useState(false);
    const queryClient = useQueryClient();

    const getUnipileAuthDataRef = useRef();
    getUnipileAuthDataRef.current = getUnipileAuthData;
    const getEmailCampainRef = useRef();
    getEmailCampainRef.current = getEmailCampain;
    const updateChatRef = useRef();
    updateChatRef.current = updateChat;

    useEffect(() => {
        const fetchActiveLinkedinAccounts = async () => {
            const { data: unipileData } = await getUnipileAuthDataRef.current();

            if (unipileData && unipileData?.accounts?.length > 0) {
                setAllLinkedinAccounts(unipileData?.accounts?.filter((i) => i.provider === "LINKEDIN"));
            } else {
                setAllLinkedinAccounts([]);
                setIsNoMailBox(true);
            }
        };
        const fetchEmailCampaigns = async () => {
            try {
                const res = await getEmailCampainRef.current();
                const campaignsList = res?.data?.response;

                if (campaignsList?.length > 0) {
                    setAllCampaigns(campaignsList);
                }
            } catch (error) {
                console.log("Error: ", error);
            }
        };

        fetchActiveLinkedinAccounts();
        fetchEmailCampaigns();
    }, []);

    useEffect(() => {
        const isNewChat = searchParams.get("newChat");
        const profileId = searchParams.get("profileId");

        if (isNewChat) {
            if (profileId && chatsData?.length > 0) {
                const existingChat = chatsData?.find((chat) => chat.senderProfileId === profileId);
                if (existingChat) {
                    setActiveChatId(existingChat.chatId);
                    setSingleChatGeneralData({
                        senderProfileUrl: existingChat?.senderProfileUrl,
                        senderProfilePicture: existingChat?.senderProfilePicture,
                        senderName: existingChat?.senderName,
                        bgColor: existingChat?.bgColor,
                        subject: existingChat?.subject,
                        chatName: existingChat?.chatName,
                    });
                    setComposeNewChatOpen(false);
                } else {
                    setComposeNewChatOpen(true);
                }
            } else if (!profileId || (profileId && chatsData?.length <= 0)) {
                setComposeNewChatOpen(true);
            }
        }
    }, [chatsData, searchParams]);

    const handleChangeTab = (tabName) => {
        setActiveTab(tabName);
        setActiveChatId(null);
        setSingleChatGeneralData(null);

        queryClient.removeQueries(["singleChat"]);
    };

    const {
        data: allChatsData,
        isLoading: isLoadingAllChats,
        refetch: refetchAllChats,
    } = useQuery({
        queryKey: ["allChats"],
        queryFn: () => getAllChats(),
        refetchInterval: 60000,
        refetchOnWindowFocus: true,
    });

    const refetchChatsList = async () => {
        await refetchAllChats();
    };
    const refetchChatsListRef = useRef();
    refetchChatsListRef.current = refetchChatsList;

    useEffect(() => {
        const convertInStandardStructureData = (allChats = []) => {
            const updatedChats = allChats?.map((chat) => {
                const campaignName = allCampaigns?.find((e) => e._id === chat?.campaignId)?.campaignName || "";
                const { accountId, chatId, chatName, campaignId, participants, lastMessage, unreadMessagesCount } = chat;

                return {
                    accountId,
                    chatId,
                    chatName,
                    senderName: participants?.[0]?.name,
                    senderProfileUrl: participants?.[0]?.profileUrl,
                    senderProfilePicture: participants?.[0]?.pictureUrl,
                    senderProfileId: participants?.[0]?.profileId,
                    areOtherSenders: participants?.length > 0,
                    subject: lastMessage?.subject
                        ? lastMessage?.subject
                        : lastMessage?.text?.length > 160
                          ? lastMessage.text.slice(0, 155) + "..."
                          : (lastMessage?.text ?? ""),
                    sentDateAndTime: formatDate(lastMessage?.date ?? ""),
                    timestamp: lastMessage?.date,
                    bodyText: lastMessage?.text,
                    bgColor: defaultColor[Math.floor(Math.random() * 10)],
                    campaignName: campaignName || "",
                    campaignId: campaignId || "",
                    unreadMessagesCount,
                    isRead: unreadMessagesCount > 0 ? false : true,
                };
            });
            return updatedChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        };

        const finalData = convertInStandardStructureData(allChatsData?.data);

        if (activeTab === "unread") {
            setChatsData(finalData?.filter((item) => item.unreadMessagesCount > 0));
        } else {
            setChatsData(finalData);
        }
    }, [allChatsData, allCampaigns, activeTab]);

    const {
        data: singleChatData,
        isLoading: isChatLoading,
        error: isChatError,
        refetch: refetchSingleChat,
    } = useQuery({
        queryKey: ["singleChat", activeChatId],
        queryFn: () => getSpecificChat(activeChatId),
        enabled: !!activeChatId,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });

    const sortedMessages = singleChatData?.data?.messages?.slice().sort((a, b) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });

    const sendNewMessageInChat = async (accountId, chatId, text) => {
        setSendButtonDisabled(true);

        const pollUntilConditionMet = async (initialMessagesLength) => {
            const MAX_ATTEMPTS = 10;
            let attempts = 0;

            while (attempts < MAX_ATTEMPTS) {
                await new Promise((resolve) => setTimeout(resolve, 500));

                const refreshedData = await refetchSingleChat();
                const updatedMessagesLength = refreshedData?.data?.data?.messages?.length;

                if (updatedMessagesLength === initialMessagesLength + 1) {
                    return true;
                }

                attempts++;
            }

            return false;
        };

        try {
            const res = await sendMessage(accountId, chatId, text);
            const currentMessagesLength = singleChatData?.data?.messages?.length;

            if (res.status) {
                const success = await pollUntilConditionMet(currentMessagesLength);

                if (!success) {
                    toast.error("Message confirmation timed out. Try refreshing.");
                }
            } else {
                toast.error("Something went wrong, try again");
            }
        } catch (error) {
            console.log("Error: ", error);
            toast.error(error?.message || "Something went wrong, try again");
        } finally {
            setSendButtonDisabled(false);
            setMessage("");
        }
    };

    const handleSendMessage = async () => {
        const accountId = chatsData?.find((chat) => chat.chatId === activeChatId)?.accountId;

        if (message.trim() && activeChatId && accountId) {
            await sendNewMessageInChat(accountId, activeChatId, message);
        }
    };

    useEffect(() => {
        const markChatAsRead = async (chatData) => {
            await updateChatRef.current(chatData.chatId, chatData.accountId, { isRead: true });
            await refetchChatsListRef.current();
        };

        if (!activeChatId || chatsData?.length <= 0) return;
        const chatData = chatsData.find((chat) => chat.chatId === activeChatId);

        if (chatData && !chatData.isRead) {
            markChatAsRead(chatData);
        }
    }, [activeChatId, chatsData]);

    return (
        <>
            {isLoadingAllChats ? (
                <BackdropLoader active={true} />
            ) : isNoMailBox ? (
                <NoMailBoxButton />
            ) : (
                <div className="grid grid-cols-[264px_1fr_1fr_1fr_1fr_1fr] gap-0">
                    <LeftMenu activeTab={activeTab} handleChangeTab={handleChangeTab} setComposeNewChatOpen={setComposeNewChatOpen} />

                    <AllChats
                        refetchChatsList={refetchChatsList}
                        allCampaigns={allCampaigns}
                        allLinkedinAccounts={allLinkedinAccounts}
                        chatsData={chatsData}
                        setActiveChatId={setActiveChatId}
                        setSingleChatGeneralData={setSingleChatGeneralData}
                        isLoading={isLoadingAllChats}
                        setComposeNewChatOpen={setComposeNewChatOpen}
                    />

                    {!isComposeNewChatOpen && activeChatId && singleChatGeneralData && (
                        <SingleChat
                            singleChatGeneralData={singleChatGeneralData}
                            handleSendMessage={handleSendMessage}
                            isChatLoading={isChatLoading}
                            isChatError={isChatError}
                            message={message}
                            setMessage={setMessage}
                            sortedMessages={sortedMessages}
                            isSendButtonDisabled={isSendButtonDisabled}
                        />
                    )}

                    {!isComposeNewChatOpen && !activeChatId && (
                        <div className="mb-4 h-full border-r border-stone-250 pt-4 sm:mb-1 p-2 col-span-3">
                            <div className="no_mail_found flex justify-center items-center">
                                <NoPreview />
                            </div>
                        </div>
                    )}

                    {isComposeNewChatOpen && (
                        <NewChat
                            setComposeNewChatOpen={setComposeNewChatOpen}
                            allLinkedinAccounts={allLinkedinAccounts}
                            refetchChatsList={refetchChatsList}
                            setActiveChatId={setActiveChatId}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default LinkedinInbox;
