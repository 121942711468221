const BaseContainer = ({ children, className }) => {
    return (
        <div
            className={`flex flex-col grow px-[24px] ${className}`}
            style={{
                color: "#050505",
                fontSize: "1.25rem",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
            }}
        >
            {children}
        </div>
    );
};

export default BaseContainer;
