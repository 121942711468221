import { useMemo, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useLinkedinAutomation from "@/hooks/useLinkedinAutomation";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import { PaginationItem } from "@mui/material";

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

export const PendingConnections = ({ allConnectionsData, allLinkedinAccounts, isLoading, refetchAllInvitations, refetchAllRelations }) => {
    const [activeTab, setActiveTab] = useState("received");
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [chosenLinkedinAccountId, setChosenLinkedinAccountId] = useState("");
    const { updateReceivedInvitation, cancelSentInvitation } = useLinkedinAutomation();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredData = useMemo(() => {
        if (activeTab === "received") {
            return allConnectionsData?.received?.filter((item) =>
                chosenLinkedinAccountId ? item.accountId === chosenLinkedinAccountId : true
            );
        }
        if (activeTab === "sent") {
            return allConnectionsData?.sent?.filter((item) =>
                chosenLinkedinAccountId ? item.accountId === chosenLinkedinAccountId : true
            );
        }
    }, [activeTab, chosenLinkedinAccountId, allConnectionsData]);

    const accountOptions =
        allLinkedinAccounts?.map((account) => ({
            label: account.email,
            value: account.accountId,
        })) || [];

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData.slice(startIndex, endIndex); // Slice data for the current page
    }, [currentPage, filteredData]);

    const handleReceivedInvitation = async (invitation, action) => {
        const { id, accountId, sharedSecret, provider } = invitation;

        try {
            setButtonClicked(true);
            const response = await updateReceivedInvitation(id, accountId, sharedSecret, provider, action);

            if (response.status) {
                toast.success(response.data.message);
                await refetchAllInvitations();
                if (action === "accept") await refetchAllInvitations();
            } else {
                toast.error("Error while trying to update invitation, please reload the page and try again");
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.message || "Error while trying to update invitation, please reload the page and try again");
        } finally {
            setButtonClicked(false);
        }
    };

    const withdrawSentInvitation = async (invitation) => {
        const { id, accountId } = invitation;
        try {
            setButtonClicked(true);
            const response = await cancelSentInvitation(id, accountId);

            if (response.status) {
                toast.success(response.data.message);
                await refetchAllInvitations();
            } else {
                toast.error("Error while trying to update invitation, please reload the page and try again");
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.message || "Error while trying to update invitation, please reload the page and try again");
        } finally {
            setButtonClicked(false);
        }
    };

    return (
        <div className="mb-4 h-full border-r border-[#E7E7E7] sm:mb-1 col-span-5">
            <div className="border-b border-[#E7E7E7] flex items-center px-[24px] py-[16px] h-[76px]">
                <p className="text-[14px] font-medium font-Inter text-accents-mainBlack">Pending Connection Requests</p>
            </div>
            <div className="mailList_container">
                <div className="px-[16px] w-full flex items-center justify-between h-[48px]">
                    <div className="h-full font-Inter">
                        <button
                            onClick={() => {
                                setActiveTab("received");
                                setButtonClicked(false);
                            }}
                            className={`h-full px-[8px] font-Inter text-[14px] font-medium border-white border-y-[3px] ${activeTab === "received" ? "border-b-accents-mainBlack text-accents-mainBlack" : "border-b-[#FFF] text-[#6D6D6D]"}`}
                        >
                            Received
                        </button>
                        <button
                            onClick={() => {
                                setActiveTab("sent");
                                setButtonClicked(false);
                            }}
                            className={`h-full px-[8px] font-Inter text-[14px] font-medium border-white border-y-[3px] ${activeTab === "sent" ? "border-b-accents-mainBlack text-accents-mainBlack" : "border-b-[#FFF] text-[#6D6D6D]"}`}
                        >
                            Sent
                        </button>
                    </div>
                    <div className="flex gap-[16px] items-center">
                        <p className="font-Inter text-[14px] text-[#6D6D6D] whitespace-nowrap">Filter by LinkedIn account: </p>
                        <Select
                            value={chosenLinkedinAccountId || ""}
                            onChange={(event) => {
                                const selected = accountOptions?.find((opt) => opt.value === event.target.value);
                                setChosenLinkedinAccountId(selected?.value || null);
                            }}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span style={{ color: "#b2b2b2" }}>Select an account</span>; // Placeholder styling
                                }
                                return accountOptions.find((option) => option.value === selected)?.label || "";
                            }}
                            sx={{
                                fontFamily: "Inter, sans-serif",
                                fontSize: "14px",
                                padding: 0,
                                paddingBottom: "1px",
                                "& .MuiSelect-select": {
                                    padding: "0px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                        >
                            {accountOptions?.map((option) => (
                                <MenuItem
                                    sx={{
                                        fontFamily: "Inter, sans-serif",
                                        fontSize: "14px",
                                    }}
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[24px] p-[24px]">
                    {paginatedData?.length > 0 &&
                        paginatedData.map((invitation) => {
                            return (
                                <div className="pb-[24px] w-full border-b border-b-[#E7E7E7] flex justify-between items-center">
                                    <div className="flex items-center gap-[16px]">
                                        {invitation?.pictureUrl ? (
                                            <img
                                                onClick={() => {
                                                    if (!invitation.inviterId) return;
                                                    window.open(`https://www.linkedin.com/in/${invitation?.inviterId}`, "_blank");
                                                }}
                                                src={invitation?.pictureUrl}
                                                alt={invitation.inviterName}
                                                style={{
                                                    width: "48px",
                                                    height: "48px",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    if (!invitation.inviterId) return;
                                                    window.open(`https://www.linkedin.com/in/${invitation?.inviterId}`, "_blank");
                                                }}
                                                className="w-[48px] h-[48px] inline-flex items-center justify-center text-white rounded-[4px] cursor-pointer"
                                                style={{
                                                    backgroundColor: defaultColor[Math.floor(Math.random() * 10)],
                                                }}
                                            >
                                                {invitation.inviterName?.charAt(0)?.toUpperCase() ?? ""}
                                            </span>
                                        )}
                                        <div className="flex flex-col justify-between">
                                            <p className="font-Inter text-[14px] leading-[1.5] font-medium text-accents-mainBlack">
                                                {invitation.inviterName}
                                            </p>
                                            <p className="font-Inter text-[12px] leading-[1.75] text-[#6D6D6D]">
                                                {invitation?.inviterDescription || ""}
                                            </p>
                                        </div>
                                    </div>
                                    {activeTab === "received" && (
                                        <div className="flex gap-[16px]">
                                            <button
                                                disabled={isButtonClicked}
                                                onClick={() => handleReceivedInvitation(invitation, "accept")}
                                                className="font-Inter px-[12px] py-[6px] border border-accents-mainBlack bg-accents-mainBlack rounded-[8px] flex items-center justify-center text-[14px] font-medium leading-none text-white"
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                disabled={isButtonClicked}
                                                onClick={() => handleReceivedInvitation(invitation, "decline")}
                                                className="font-Inter px-[12px] py-[6px] border border-[#E7E7E7] rounded-[8px] flex items-center justify-center text-[14px] font-medium leading-none text-accents-mainBlack"
                                            >
                                                Ignore
                                            </button>
                                        </div>
                                    )}
                                    {activeTab === "sent" && (
                                        <button
                                            disabled={isButtonClicked}
                                            onClick={() => withdrawSentInvitation(invitation)}
                                            className="font-Inter px-[12px] py-[6px] border border-[#E7E7E7] rounded-[8px] flex items-center justify-center text-[14px] font-medium leading-none text-accents-mainBlack"
                                        >
                                            Withdraw Request
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    {!isLoading && filteredData?.length <= 0 && <p className="font-Inter p-[12px]">No invitations found</p>}
                    {!isLoading && filteredData?.length > itemsPerPage && (
                        <div className="flex w-full justify-center py-4 sm:py-8">
                            <Pagination
                                className="font-Inter"
                                count={Math.ceil(filteredData?.length / itemsPerPage)}
                                page={currentPage}
                                onChange={(event, newPage) => {
                                    setCurrentPage(newPage);
                                }}
                                showFirstButton
                                showLastButton
                                renderItem={(item) => (
                                    <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />
                                )}
                                sx={{
                                    "& .MuiPaginationItem-root": {
                                        fontFamily: "Inter, sans-serif",
                                        color: "#050505",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "4px",
                                    },
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        backgroundColor: "#2D7A89",
                                        color: "#fff",
                                        borderRadius: "4px",
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
