import React from "react";

export const CustomButton = ({
    onClick,
    title,
    titleTextSize = 14,
    titleFontWeight = 400,
    height = 48,
    width = 328,
    px = 24,
    py = 12,
    bg = "#090c05",
    rounded = 8,
    titleColor = "white",
    border = "none",
    className = "",
    disabled = false,
    img = false,
    imgSrc,
    imgWidth,
    imgHeight,
    endIcon,
}) => {
    return (
        <button
            className={`flex flex-row justify-between hover:${titleColor === "white" ? "opacity-80" : "!bg-dgray-50"} transition-all items-center  ${className}`}
            style={{
                height: `${height}px`,
                width: `${width}px`,
                paddingLeft: `${px}px`,
                paddingRight: `${px}px`,
                paddingTop: `${py}px`,
                paddingBottom: `${py}px`,
                backgroundColor: bg,
                borderRadius: `${rounded}px`,
                border: `${border}`,
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {img && imgSrc && (
                <div
                    alt="Button"
                    style={{
                        width: `${imgWidth}px`,
                        height: `${imgHeight}px`,
                    }}
                >
                    {imgSrc}
                </div>
            )}
            <span
                className={`text-center w-full font-['Inter'] text-nowrap leading-tight tracking-tight`}
                style={{
                    color: titleColor,
                    fontSize: `${titleTextSize}px`,
                    fontWeight: titleFontWeight,
                }}
            >
                {title}
            </span>
            {endIcon}
        </button>
    );
};
