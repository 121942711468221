import React, { useState, useEffect } from "react";
import CustomSingleSelectAutoComplete from "../CustomSingleSelectAutoComplete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";
import { isValidUrl } from "@/utils/common";
import { CircularProgress } from "@mui/material";
import useAIEngagement from "@/hooks/useAIEngagement";
import { useNavigate } from "react-router-dom";

const defaultDays = {
    Sunday: false,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
};

const editDays = {
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
};

export default function CampaignSchedule({ campaignId, setCampaignId, editCampaignData }) {
    const [timeZone, setTimeZone] = useState(null);
    const [startHours, setStartHours] = useState("09:00");
    const [endHours, setEndHours] = useState("17:00");
    const [selectedDays, setSelectedDays] = useState(defaultDays);
    const [calendarLink, setCalendarLink] = useState("");
    const [startDate, setStartDate] = useState("");
    const [maxEmails, setMaxEmails] = useState(5);
    const [loading, setLoading] = useState(false);

    const { scheduleCampaign, generateTargetContacts, activateCampaign } = useAIEngagement();
    const navigate = useNavigate();

    // If the campaign is in edit mode, set the existing data
    useEffect(() => {
        if (editCampaignData) {
            setTimeZone(editCampaignData.timezone);
            setStartHours(editCampaignData.emailStartHour);
            setEndHours(editCampaignData.emailEndHour);
            setCalendarLink(editCampaignData.ctaLink);
            setStartDate(formatDate(editCampaignData.campaignStartDate));
            setMaxEmails(editCampaignData.maxEmailsPerDay);

            // Pre-fill the selectedDays state based on editCampaignData.emailDays
            const updatedDays = { ...editDays };
            editCampaignData.emailDays.forEach((day) => {
                updatedDays[day] = true;
            });
            setSelectedDays(updatedDays);
        }
    }, [editCampaignData]);

    // selected days
    const handleDayChange = (event) => {
        setSelectedDays({
            ...selectedDays,
            [event.target.name]: event.target.checked,
        });
    };

    // Get all timezones
    const allTimeZones = Intl.supportedValuesOf("timeZone");

    // list of 24 hours e.g. 00:00, 01:00, 02:00
    const hours = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, "0");
        return `${hour}:00`;
    });

    const handleSubmit = async () => {
        if (!timeZone) {
            toast.error("Please select a timezone");
            return;
        }
        if (!startHours) {
            toast.error("Please select a start hour");
            return;
        }
        if (!endHours) {
            toast.error("Please select an end hour");
            return;
        }
        if (!calendarLink) {
            toast.error("Please enter a calendar link");
            return;
        }
        if (!isValidUrl(calendarLink)) {
            toast.error("Invalid Calendar Url Link. Please enter a valid calendar link");
            return;
        }
        if (!startDate) {
            toast.error("Please select a campaign start date");
            return;
        }
        if (!maxEmails) {
            toast.error("Please enter the maximum number of emails per day");
            return;
        }

        if (Object.values(selectedDays).every((day) => !day)) {
            toast.error("Please select at least one sending day");
            return;
        }

        const emailDays = Object.keys(selectedDays).filter((day) => selectedDays[day]);
        const formData = {
            campaignId: campaignId,
            timezone: timeZone,
            emailStartHour: startHours,
            emailEndHour: endHours,
            emailDays,
            ctaLink: calendarLink,
            campaignStartDate: startDate,
            maxEmailsPerDay: maxEmails,
        };

        setLoading(true);
        await scheduleCampaign(formData);
        const response = await activateCampaign(campaignId); // activate campaign
        await generateTargetContacts(campaignId); // generate contact target list (background process)
        if (response.status) {
            toast.success(response.message);
            navigate("/ai-engagement/campaigns");
            setCampaignId(null);
        } else {
            toast.error("Something went wrong!");
        }
        setLoading(false);
    };

    return (
        <>
            <div className="campaign-card">
                <h2 className="mb-[16px] main-text-black-600">Timezone</h2>
                <CustomSingleSelectAutoComplete
                    id="timezone"
                    options={allTimeZones}
                    onSelectionChange={(e, value) => setTimeZone(value)}
                    selectedValue={timeZone}
                    isIconVisible={false}
                    isBorderVisible={false}
                    placeholder="Select Timezone"
                    sx={{ width: "100%" }}
                />

                <h2 className="main-text-black-600 mt-[40px] capitalize">Send these Days</h2>
                <div className="flex gap-[24px] items-center mt-[24px]">
                    {Object.keys(selectedDays).map((day) => (
                        <div key={day} className="flex items-center gap-[8px]">
                            <input
                                type="checkbox"
                                id={day}
                                name={day}
                                checked={selectedDays[day]}
                                onChange={handleDayChange}
                                className="cursor-pointer transform scale-110 accent-[#2D7A89]"
                            />
                            <label htmlFor={day} className="main-text-black-500">
                                {day}
                            </label>
                        </div>
                    ))}
                </div>

                <h2 className="main-text-black-600 mt-[40px]">Time Period Between Sequences</h2>
                <div className="flex gap-3 items-center mt-3">
                    <div>
                        <p className="mb-1 main-text-black-500">From</p>
                        <CustomSingleSelectAutoComplete
                            id="start-hours"
                            options={hours}
                            onSelectionChange={(e, value) => setStartHours(value)}
                            selectedValue={startHours}
                            placeholder="from"
                            isIconVisible={false}
                            isBorderVisible={true}
                            bgColor="#f9f9f9"
                            sx={{ width: "120px", cursor: "pointer" }}
                        />
                    </div>

                    <hr className="w-5 border border-black mt-[26px]" />
                    <div>
                        <p className="mb-1 main-text-black-500">To</p>
                        <CustomSingleSelectAutoComplete
                            id="end-hours"
                            options={hours}
                            onSelectionChange={(e, value) => setEndHours(value)}
                            selectedValue={endHours}
                            placeholder="to"
                            isIconVisible={false}
                            isBorderVisible={true}
                            bgColor="#f9f9f9"
                            sx={{ width: "120px", cursor: "pointer" }}
                        />
                    </div>
                </div>

                <div className="bg-[#F4F3F0] rounded-[4px] px-[16px] py-[8px] flex gap-x-3 my-[40px] w-fit">
                    <InfoOutlinedIcon />
                    <p className="main-text-black-500">
                        Our AI introduces a variance trigger, adding a human touch to enhance deliverability
                    </p>
                </div>

                <h2 className="main-text-black-600 mt-[40px]">Your Calendar Meeting Link</h2>
                <input
                    type="text"
                    value={calendarLink}
                    onChange={(e) => setCalendarLink(e.target.value)}
                    className="campaign-card-input mt-3 !bg-transparent !py-[8px] !px-[16px] text-[#2D7A89] text-[14px] font-medium capitalize"
                />

                <div className="flex gap-x-3 items-center mt-[40px]">
                    <h2 className="main-text-black-600 capitalize">Set Campaign Start Date</h2>
                    <InfoOutlinedIcon />
                </div>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    min={new Date().toISOString().split("T")[0]} // Sets the minimum date to today
                    className="campaign-card-input !bg-transparent mt-[16px] !w-[200px]"
                />

                <h2 className="main-text-black-600 mt-[40px] capitalize">Max Number of emails per day per campaign</h2>
                <input
                    type="number"
                    value={maxEmails}
                    onChange={(e) => {
                        const value = e.target.value;
                        // Allow only positive numbers (no negatives, commas, or 0)
                        if (/^[1-9]\d*$/.test(value) && value <= 500) {
                            setMaxEmails(value);
                        }
                    }}
                    className="campaign-card-input mt-[16px] !bg-transparent !w-[200px]"
                />
                <p className="font-Inter text-[12px] text-[#6D6D6D]">Maximum Limit 500 Emails per day</p>

                <button
                    className="mt-[40px] font-Inter flex h-[34px] px-[24px] items-center justify-center rounded-[8px] bg-accents-mainBlack capitalize text-white font-medium text-[14px] hover:opacity-80 transition-all"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? (
                        <div className="flex items-center gap-3">
                            <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Saving...</span>
                        </div>
                    ) : (
                        <span className="whitespace-nowrap">Save</span>
                    )}
                </button>
            </div>
        </>
    );
}

// Convert date string to YYYY-MM-DD format
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};
