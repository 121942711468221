import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterPopup, { webIntentFilterLabels } from "@/components/popup/FilterPopup";

export default function ChartFilterPopup({ periodFilter, setPeriodFilter, optionsLabel, optionsArray, titleSize = "base" }) {
    const [anchorPeriodEl, setAnchorPeriodEl] = useState(null);
    const [openPeriodFilter, setOpenPeriodFilter] = useState(false);

    return (
        <>
            <button
                className="flex items-center gap-1 text-sm font-medium text-[#2D7A89]"
                onClick={(e) => {
                    setAnchorPeriodEl(e.currentTarget);
                    setOpenPeriodFilter((cur) => !cur);
                }}
            >
                <div className={`font-Inter`}>{optionsLabel ? optionsLabel[periodFilter] : webIntentFilterLabels[periodFilter]}</div>
                <ExpandMoreIcon
                    color="inherit"
                    className={`relative h-4 w-4 transition-transform duration-200 ${openPeriodFilter ? "rotate-180" : ""}`}
                />
            </button>
            <FilterPopup
                anchorEl={anchorPeriodEl}
                open={openPeriodFilter}
                handleClose={(value) => {
                    if (value) {
                        setPeriodFilter(value);
                    }
                    setAnchorPeriodEl(null);
                    setOpenPeriodFilter(false);
                }}
                optionsArray={optionsArray}
            />
        </>
    );
}
