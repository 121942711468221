import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 8,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#e7e7e7",
        border: "none",
        fontSize: 14,
        fontFamily: "Inter",
        padding: "1px 50px 1px 8px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 8,
            borderColor: "#e7e7e7",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    fontFamily: "Inter",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E7E7E7",
            fontFamily: "Inter",
        },
        "&:hover fieldset": {
            borderColor: "#E7E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E7E7E7",
        },
    },
});

export default function CustomAutocomplete({ value = null, options, onChange, placeholder = null, disabled = false }) {
    const handleChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <StyledAutocomplete
            sx={{ padding: 0 }}
            value={value}
            options={options}
            onChange={handleChange}
            clearIcon={<ClearIcon className="!h-4 !w-4" />}
            disablePortal
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    placeholder={placeholder}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important", fontFamily: "Inter" }}
                />
            )}
            disabled={disabled}
            renderOption={(props, option, { selected }) => {
                const { key, className, ...restProps } = props;
                const selectedOption = value === option;
                return (
                    <li
                        {...props}
                        className={`${className} ${selectedOption && "!bg-dgray-100 font-medium"} !cursor-pointer hover:!bg-dgray-100 rounded-lg px-2 ml-2 mr-0.5 text-sm !font-normal`}
                    >
                        {option}
                    </li>
                );
            }}
        />
    );
}
