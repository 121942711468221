import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
export default function Crunchbase({ companyData }) {
    return (
        <div>
            {companyData.crunchbaseTotalInvestmentUSD || companyData.daysSinceLastFundraise || companyData.lastFundingRoundInvestmentUSD ? (
                <div>
                    <div className="border border-[#e7e7e7] rounded-lg p-6 text-sm text-primary-black">
                        <div className="flex gap-10 items-center text-dgray-6">
                            {companyData.crunchbaseCategories && companyData.crunchbaseCategories.length > 0 && (
                                <div className="flex items-center gap-2">
                                    <CategoryOutlinedIcon className="!w-4 !h-4" color="inherit" />
                                    <p>
                                        {" "}
                                        Category:{" "}
                                        <span className="font-medium text-primary-black">{companyData.crunchbaseCategories[0]}</span>{" "}
                                    </p>
                                </div>
                            )}
                            {companyData.companyType && (
                                <div className="flex items-center gap-2">
                                    <VisibilityOutlinedIcon className="!w-4 !h-4" color="inherit" />
                                    <p>
                                        {" "}
                                        Status: <span className="font-medium text-primary-black">{companyData.companyType}</span>{" "}
                                    </p>
                                </div>
                            )}
                        </div>

                        <h2 className="text-base font-medium mt-8 mb-5">Details</h2>
                        <div className="flex items-center gap-10">
                            {companyData.crunchbaseTotalInvestmentUSD && (
                                <div className="border border-[#e7e7e7] p-4 rounded-lg min-w-[220px]">
                                    <h2 className="text-lg font-medium">${companyData.crunchbaseTotalInvestmentUSD.toLocaleString()}</h2>
                                    <p className="text-dgray-6 mt-4">Crunchbase total investment</p>
                                </div>
                            )}
                            {companyData.daysSinceLastFundraise && (
                                <div className="border border-[#e7e7e7] p-4 rounded-lg min-w-[220px]">
                                    <h2 className="text-lg font-medium">{companyData.daysSinceLastFundraise}</h2>
                                    <p className="text-dgray-6 mt-4">Days Since Last Fundraise</p>
                                </div>
                            )}
                            {companyData.lastFundingRoundInvestmentUSD && (
                                <div className="border border-[#e7e7e7] p-4 rounded-lg min-w-[220px]">
                                    <h2 className="text-lg font-medium">${companyData.lastFundingRoundInvestmentUSD.toLocaleString()}</h2>
                                    <p className="text-dgray-6 mt-4">Last Funding Round Investment</p>
                                </div>
                            )}
                        </div>

                        {companyData.fundingMilestonesTimeseries && companyData.fundingMilestonesTimeseries.length > 0 && (
                            <>
                                <h2 className="text-base font-medium mt-8 mb-5">Funding Milestones</h2>
                                <div className="flex items-center gap-5 flex-wrap">
                                    {companyData.fundingMilestonesTimeseries.slice(0, 20).map((milestone, index) => (
                                        <div className="border border-[#e7e7e7] p-4 rounded-lg min-w-[220px]">
                                            <h2 className="text-lg font-medium">
                                                {milestone.funding_milestone_amount_usd
                                                    ? `$${milestone.funding_milestone_amount_usd?.toLocaleString()}`
                                                    : milestone.funding_round}
                                            </h2>
                                            <p className="text-dgray-6 mt-4">
                                                {milestone.funding_milestone_amount_usd ? "Funding Amount" : "Funding Round Type"}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center h-[350px]">
                    <p className="font-medium mt-2">No Crunchbase Data Found</p>
                </div>
            )}
        </div>
    );
}
