// ContactsCRMPushMenu.jsx
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import { MenuItem, MenuList, Accordion, AccordionSummary, AccordionDetails, Grow, Popper, ClickAwayListener, styled } from "@mui/material";
import { ReactComponent as CrmIcon } from "@/assets/image/search/group_search.svg";

import useAuth from "@/hooks/useAuth";
import { useAvailableIntegrations } from "@/hooks/useAvailableIntegrations";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";

const StyledMenu = styled(React.forwardRef((props, ref) => <MenuList ref={ref} {...props} />))(() => ({
    padding: 0,
    "& .MuiMenuItem-root": {
        fontFamily: "Inter",
        display: "flex",
        alignItems: "center",
        gap: 4,
        marginTop: 4,
        minWidth: 180,
        padding: 0,
        overflow: "hidden",
        boxShadow: "none",
        backgroundColor: "white",
    },
}));

function ContactsCRMPushMenu({ selectedContacts, isDisabled = false, className }) {
    const { toggleShow, setResults, setUploads, setTarget, setTargetEntity, toggleLoading } = useCRMPushResultStore();
    const [integrationMenu, setIntegrationMenu] = useState(false);
    const anchorRef = useRef();
    const availableIntegrations = useAvailableIntegrations();
    const navigate = useNavigate();
    const numSelected = selectedContacts.length;
    const { getAuthToken } = useAuth();

    const handleIntegrationPush = async (func, targetEntity, target, ui = "table") => {
        // Transform the selectedContacts data to match CRM push requirements
        const transformedData = selectedContacts.map((contact) => ({
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            phones: contact.phone ? [contact.phone] : [],
            jobTitle: contact.jobTitle,
            address: contact.address,
            company: contact.company || "",
            industry: contact.industry,
            linkedIn: contact.linkedIn,
            // Add any additional fields as required by your CRM
        }));

        // Filter out contacts without necessary information
        const filteredData = transformedData.filter((contact) => contact.email || (contact.phones && contact.phones.length > 0));

        if (filteredData.length === 0) {
            toast.error("No valid contacts to push. Ensure contacts have at least an email or phone number.", {
                theme: "colored",
            });
            return;
        }

        setUploads(filteredData);
        toggleLoading();

        let res;

        try {
            res = await func(filteredData, "contacts", await getAuthToken());
        } catch (error) {
            console.error("Error pushing to CRM:", error);
            toast.error("Failed to push contacts to CRM. Please try again later.", { theme: "colored" });
            toggleLoading();
            return;
        }

        toggleLoading();

        if (ui === "table") {
            toggleShow();
        } else if (ui === "notification") {
            if (res.success) {
                toast.success("Successfully pushed contacts to CRM.");
                if (res.extraMessage) {
                    toast.info(res.extraMessage);
                }
            } else {
                toast.error("There was an error pushing contacts to CRM. Please try again later.");
            }
            return;
        }

        if (res.body) {
            try {
                const parsed = JSON.parse(res.body);
                setResults(parsed);
            } catch (parseError) {
                console.error("Error parsing CRM response:", parseError);
                toast.error("Received invalid response from CRM.", { theme: "colored" });
            }
        }
    };

    return (
        <>
            <div
                ref={anchorRef}
                className={
                    "font-Inter min-h-[30px] border border-[#d1d1d1] font-medium px-3 py-1.5 flex items-center gap-2 text-black  whitespace-nowrap rounded-lg text-sm select-none hover:bg-dgray-50 transition-all bg-white " +
                    (numSelected > 0
                        ? " !border-stone-500 !text-stone-950 cursor-pointer "
                        : " !border-stone-500 !text-stone-350 !cursor-not-allowed !pointer-events-none") +
                    className
                }
                onClick={() => {
                    if (selectedContacts.length === 0) {
                        toast.info("Please select at least one contact to push to CRM");
                        return;
                    }
                    if (selectedContacts.find((contact) => contact.meta?.title === "emailValidation")) {
                        toast.error("Email validation records cannot be pushed to CRM", {
                            theme: "colored",
                        });
                        return;
                    } else if (!selectedContacts.length || isDisabled) return;

                    // Ensure contacts have necessary data
                    const hasValidData = selectedContacts.some(
                        (contact) => contact.email || (contact.phone && contact.phone.trim() !== "")
                    );
                    if (!hasValidData) {
                        toast.error("Selected contacts do not have valid emails or phone numbers.", { theme: "colored" });
                        return;
                    }

                    setIntegrationMenu(!integrationMenu);
                }}
            >
                <CrmIcon
                    className={
                        "h-5 w-5" +
                        (numSelected > 0 ? " !fill-current !text-stone-950" : " !fill-current !text-stone-350 disabled:cursor-not-allowed")
                    }
                />
                CRM Push
            </div>
            <Popper
                open={integrationMenu}
                anchorEl={anchorRef.current}
                sx={{ zIndex: 10 }}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                    >
                        <ul>
                            <ClickAwayListener disableReactTree={true} onClickAway={() => setIntegrationMenu(false)}>
                                <StyledMenu
                                    sx={{
                                        padding: 0,
                                        marginTop: "4px",
                                        boxShadow: "0 1px 4px 2px rgb(0 0 0 / 0.1)",
                                    }}
                                    className="overflow-hidden rounded-lg bg-white"
                                >
                                    {availableIntegrations.map((item) => {
                                        return item.available ? (
                                            <MenuItem
                                                key={item.label}
                                                sx={{
                                                    padding: 0,
                                                    margin: 0,
                                                    boxShadow: "0 1px 2px 4px rgb(0 0 0 / 0.1)",
                                                }}
                                            >
                                                <Accordion
                                                    sx={{
                                                        borderRadius: 0,
                                                        boxShadow: "none",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        sx={{
                                                            borderRadius: 0,
                                                            boxShadow: "none",
                                                        }}
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        Push to {item.label}
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        sx={{
                                                            padding: 0,
                                                            backgroundColor: "#3b82f6",
                                                            color: "white",
                                                        }}
                                                        className="flex flex-col gap-1"
                                                    >
                                                        {item.pushOptions.map((option) => (
                                                            <button
                                                                key={option.label}
                                                                className="flex w-full gap-1 px-6 py-2 transition-all hover:bg-[#126dff]"
                                                                onClick={() => {
                                                                    handleIntegrationPush(
                                                                        option.handler,
                                                                        option.targetEntity,
                                                                        item.label,
                                                                        option.ui
                                                                    );
                                                                    setIntegrationMenu(false);
                                                                }}
                                                            >
                                                                {option.label}
                                                            </button>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </MenuItem>
                                        ) : (
                                            <Accordion
                                                key={item.label}
                                                sx={{
                                                    borderRadius: 0,
                                                    boxShadow: "none",
                                                }}
                                                onClick={() => navigate("/integrate")}
                                            >
                                                <AccordionSummary
                                                    sx={{
                                                        borderRadius: 0,
                                                        boxShadow: "none",
                                                    }}
                                                >
                                                    {item.label === "Zapier" ? "Setup Zapier" : `Connect to ${item.label}`}
                                                </AccordionSummary>
                                            </Accordion>
                                        );
                                    })}
                                </StyledMenu>
                            </ClickAwayListener>
                        </ul>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default ContactsCRMPushMenu;
