import LandingLayout from "@/components/LandingLayout";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Typewriter from "typewriter-effect";
import mainArrowSvg from "@/assets/image/base/mainArrow.svg";
import HeroImage from "@/assets/image/fuse-hero-new.png";
import { ReactComponent as B2B } from "@/assets/image/icons/fuse landing page/b2b.svg";
import { ReactComponent as Settings } from "@/assets/image/icons/fuse landing page/settings.svg";
import { ReactComponent as Autopilot } from "@/assets/image/icons/fuse landing page/autopilot.svg";
import { ReactComponent as CombinatorLogo } from "@/assets/image/icons/fuse landing page/combinator.svg";
import { numberOfEmployeesList, industryList } from "@/utils/filter";
import CustomAutocomplete from "./CustomAutocomplete";
import { toast } from "react-toastify";
import axios from "axios";

const ColorChangingButton = () => {
    return (
        <HashLink
            to="#getInTouch"
            id="changeButton"
            className="relative font-InterMedium flex text-sm items-center rounded-lg bg-black p-2 pl-7 !pr-[3rem] text-white "
        >
            <p>Try AI Search</p>
            <div id="firstIcon" className="flex items-center justify-center w-5 h-5">
                <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
            </div>
            <div id="secondIcon" className="flex items-center justify-center w-5 h-5">
                <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
            </div>
        </HashLink>
    );
};

export default function LandingStatic() {
    const location = useLocation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [website, setWebsite] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (!firstName || !lastName || !email) {
            toast.error("Please fill all the required fields");
            return;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Invalid email format");
            return;
        }
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/contact-us`, {
                firstName,
                lastName,
                email,
                phone,
                company,
                website,
            });
            toast.success("Request sent successfully");
            // Clear form fields
            [setFirstName, setLastName, setEmail, setPhone, setCompany, setWebsite].forEach((fn) => fn(""));
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const offers = [
        {
            title: "Conversational CRM Interface",
            description:
                "Ask our Fuse Agent any command imaginable to simplify your workflow. Learning more about a customer or company? Validating B2B emails? Sending emails at scale? Simply ask and you will receive in seconds.",
            icon: <Settings />,
        },
        {
            title: "Live B2B Contact & Account Database",
            description:
                "We've created the world's first “live” database that plugs into 10+ data sources to provided you with real-time, fresh, and accurate data.",
            icon: <B2B />,
        },
        {
            title: "Engagement on autopilot",
            description:
                "Fully automated outreach from the first message all the way up-to booking a demos in your calendar, enabling your top performers to focus on closing deals in a demo.",
            icon: <Autopilot />,
        },
        {
            title: "Buyer Intent Signals",
            description:
                "Our platform plugs directly into your website to denanonymize website traffic, so your sales and marketing professionals have real-time data on who to prospect.",
            icon: <Autopilot />,
        },
    ];

    return (
        <div id="main">
            <LandingLayout>
                <div className="w-full antialiased text-gray-600 bg-primary-header">
                    <Box className="flex items-center justify-center sm:justify-start">
                        <Box className="relative flex flex-col items-center justify-center w-full gap-16 pt-24 pb-24 px-6 mx-auto my-0 kompass-animation 2xl:pt-32 2xl:pb-32 sm:flex-row sm:gap-4 md:gap-8 lg:gap-12 xl:gap-32 2xl:gap-40">
                            <Box className="relative flex flex-col items-center gap-0.5">
                                <Box className="h-fit flex items-center gap-0.5">
                                    <span className="font-Inter text-[15px] text-[#050505] font-medium">Backed by</span> <CombinatorLogo />
                                </Box>
                                <Box className="mt-10 h-fit">
                                    <h1 className="text-primary-black text-center text-[20px] xs:text-[27px] sm:text-[30px] md:text-[40px] xl:text-[46px] leading-[1.1] font-bold">
                                        Scale revenue with your personal AI Agent.
                                    </h1>
                                </Box>
                                <Box className="mt-6 xl:mt-8 h-fit">
                                    <p className="text-[14px] sm:text-[18px] md:text-[20px] lg:text-2xl text-center md:max-w-[760px] lg:max-w-[860px] 2xl:max-w-[1350px] font-normal font-Inter leading-[1.4] text-neutral-500">
                                        Find out exactly who visited your website, then plug in to our live database connected to 10+ data
                                        vendors to convert your most valuable buyers in seconds.
                                    </p>
                                </Box>
                                <Box className="relative !bg-white w-full mt-16 flex max-w-[306px] xs:max-w-[380px] lg:max-w-[440px] xl:max-w-[500px] items-center justify-between rounded-lg border border-[#e7e7e7]">
                                    <Box className="ml-6 flex w-36 lg:w-[200px] flex-1 items-center justify-start">
                                        <Typewriter
                                            options={{
                                                strings: ["Elon Musk", "Mark Zuckerberg", "Warren Buffett", "Jay Z", "Stephen Curry"],
                                                autoStart: true,
                                                loop: true,
                                                skipAddStyles: true,
                                                wrapperClassName: "font-Inter text-stone-350",
                                            }}
                                        />
                                    </Box>
                                    <Box className="py-2 pr-4">
                                        <ColorChangingButton />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="flex items-center justify-center w-full">
                        <img src={HeroImage} className="w-full" alt="LandingStaticSvg" />
                    </Box>

                    <div className="flex flex-col items-center mt-20 px-6 md:px-16">
                        <h2 className="border border-[#e7e7e7] text-[#5D5D5D] rounded-lg py-2 px-4 bg-white text-sm">WHAT WE DO</h2>
                        <p className="mt-10 text-base sm:text-md text-primary-black font-semibold text-center">
                            We enable you to run your entire sales deal cycle through a simple conversational interface, built on-top of an
                            end-to-end product suite; use natural language to manage customer relationships, enrich B2B contacts, find
                            high-intent leads, and engage with clients in seconds.
                        </p>
                    </div>

                    <div id="product" className="flex flex-col items-center mt-28 px-6 md:px-16">
                        <h2 className="border border-[#e7e7e7] text-[#5D5D5D] rounded-lg py-2 px-4 bg-white text-sm">WHAT WE OFFER</h2>
                        <p className="mt-10 sm:text-md text-primary-black font-semibold text-center">
                            Fuse AI empowers your entire GTM team
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 pre-xl:grid-cols-4 gap-3 px-6 md:px-16 mt-10">
                        {offers.map((offer, index) => (
                            <div key={index} className="border border-[#e7e7e7] rounded-xl p-6 bg-white">
                                <div className="bg-[#F6F6F6] w-fit rounded-xl p-3 flex items-center justify-center">{offer.icon}</div>
                                <h1 className="font-semibold text-primary-black mt-5">{offer.title}</h1>
                                <p className="text-[#6D6D6D] font-normal mt-5 text-sm">{offer.description}</p>
                            </div>
                        ))}
                    </div>

                    <div className="w-full bg-primary-black">
                        <div className="flex flex-col items-center justify-start mt-20 w-full p-5 py-14 md:p-10 lg:p-20 md:pt-20 h-auto">
                            <h2 className="border border-[#e7e7e7] text-[#5D5D5D] rounded-lg py-2 px-4 bg-white text-sm">MANIFESTO</h2>
                            <div className="max-w-[1250px]">
                                <p className="text-[#E7E7E7] font-medium font-Inter md:text-lg leading-[110%] mt-20">
                                    Every company requires customers. Without customers, sales simply can't happen. Nowadays go-to-market
                                    teams struggle with manual tasks, disconnected tools, and fragmented data due to the archaic software
                                    they are operating on. This chaotic approach to go-to-market makes it increasingly challenging to manage
                                    and generate leads that convert into high quality customers.
                                </p>
                                <p className="text-[#B0B0B0] text-sm font-Inter mt-10">
                                    FuseAI isn’t just another sales software tool. We're building the next-gen operating system for revenue
                                    teams. We're not claiming to replace your SDRs with terrible email automations or providing you false
                                    promises of 100xing your revenue. Our focus is on human amplification - our mission is simple, let's
                                    make the best companies and sales professionals 5X better and build a platform that everyone actually
                                    enjoys using. We're building for user experience and productivity so your best reps can close 5M in
                                    deals annually vs. the traditional 1M target for most companies.
                                </p>

                                <p className="text-[#B0B0B0] text-sm font-Inter mt-10">
                                    Here a few of the many pain-points we're tackling head on: What if businesses didn't have to spend 6
                                    months evaluating multiple vendors to setup their sales tech stacks? What if they always had access to
                                    real-time B2B contact and account data? What if emails never bounced? What if they always knew who their
                                    high-intent leads where? What if they didn't have to toggle across several different software tools
                                    during the day? What if “Salesforce hygiene” wasn't their number one priority and it was self-updating?
                                    What if companies didn't need to spend millions on technical systems architects, and software was
                                    actually simple to use, instead of an expensive headache? What if their CRMs automatically recorded,
                                    analyzed, and integrated sales call recordings?
                                </p>

                                <p className="text-[#B0B0B0] text-sm font-Inter mt-10">
                                    Our category defining software is making functionalities such as Real-time B2B Contact and Account Data,
                                    Customer Intent, and Multi-Channel Engagement out-of-the-box features within CRM. We're building
                                    simplified agentic workflows to re-imagine traditional manual workflows, so you can focus on closing
                                    deals.
                                </p>

                                <p className="text-[#B0B0B0] text-sm font-Inter mt-10">
                                    It's amazing how Salesforce, a $300B empire, has largely grown due to path dependence, with 90% of users
                                    dreading to use it daily. We're at the beginning of a techno-paradigm shift with agentic software
                                    leading the charge. So here we are, with a bold vision and a relentless drive to make it a reality.
                                    We're still writing the story of FuseAI, and I couldn't be prouder of how far we've come—or more excited
                                    about where we're going.
                                </p>

                                <p className="text-[#B0B0B0] text-sm font-Inter mt-10">
                                    If you're reading this, you're already part of the journey.{" "}
                                    <span className="font-semibold text-[#E7E7E7]">Let's change the game, together.</span>{" "}
                                </p>
                                <div className="flex flex-col items-center justify-center mt-24">
                                    <h2 className="text-[#E7E7E7] font-medium font-Inter md:text-lg leading-[110%] ">
                                        Sincerely, <br /> FuseAI Team
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="getInTouch" className="py-20 flex flex-col items-center text-primary-black px-6 md:px-16">
                        <div className="max-w-[1024px] flex flex-col items-center w-full">
                            <h2 className="border border-[#e7e7e7] text-[#5D5D5D] rounded-lg text-sm py-2 px-4 bg-white text-center w-fit">
                                GET IN TOUCH
                            </h2>
                            <div className="grid sm:grid-cols-2 gap-x-5 gap-y-5 sm:gap-y-10 w-full mt-16 text-sm">
                                <div className="w-full">
                                    <p>First Name*</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Your first name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="w-full">
                                    <p>Last Name*</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Your last name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>

                                <div className="w-full">
                                    <p>Email*</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Your work email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="w-full">
                                    <p>Phone</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Your phone number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>

                                <div className="w-full">
                                    <p>Company</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Company name"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="w-full">
                                    <p>Website</p>
                                    <input
                                        className="bg-white border border-[#e7e7e7] py-2 px-4 mt-2 rounded-lg outline-none w-full placeholder:text-[#B0B0B0]"
                                        type="text"
                                        placeholder="Company website"
                                        value={website}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                {/* <div className="w-full">
                                    <p className="mb-2">Company Size*</p>
                                    <CustomAutocomplete
                                        value={companySize}
                                        onChange={(value) => setCompanySize(value)}
                                        sx={{ width: "100%", marginTop: "8px" }}
                                        placeholder="Select company size"
                                        options={numberOfEmployeesList.map((e) => e.label)}
                                    />
                                </div> */}
                                {/* <div className="w-full">
                                    <p className="mb-2">Industry*</p>
                                    <CustomAutocomplete
                                        sx={{ width: "100%", marginTop: "8px" }}
                                        value={industry}
                                        onChange={(value) => setIndustry(value)}
                                        placeholder="Select company industry"
                                        options={industryList.map((e) => e.label)}
                                    />
                                </div> */}
                                <button
                                    disabled={loading}
                                    onClick={handleSubmit}
                                    className="bg-primary-black text-white flex items-center gap-2 rounded-lg py-2 px-4 w-fit mt-5 hover:opacity-80 disabled:opacity-50"
                                >
                                    {loading && <CircularProgress size={14} color="inherit" />}
                                    Send Request
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </LandingLayout>
        </div>
    );
}
