import { formatTimestamp } from "@/utils/common";
import { useState } from "react";

const parseContent = (content) => {
    // Split into metadata and sections
    const sections = content.split("### ");
    if (sections.length < 2) return { metadata: content, notes: "", insights: "" };

    // First part is metadata (before any ### markers)
    const metadata = sections[0].trim();

    // Find NOTES and INSIGHTS sections
    const notesSection = sections.find((s) => s.startsWith("NOTES:"));
    const insightsSection = sections.find((s) => s.startsWith("INSIGHTS:"));

    // Extract and process notes
    let notes = "";
    if (notesSection) {
        notes = notesSection
            .replace("NOTES:", "")
            .trim()
            .split("\n")
            .map((line) => {
                if (!line.trim()) return "";
                // Remove leading dash and clean up the line
                const cleanLine = line.replace(/^-\s*/, "").trim();
                // Check if the line contains a colon
                if (!cleanLine.includes(":")) return cleanLine;
                // Split only on the first colon
                const [label, ...contentParts] = cleanLine.split(":");
                const content = contentParts.join(":").trim();
                return `**${label}**: ${content}`;
            })
            .filter(Boolean)
            .join("\n");
    }

    // Extract content from insights section
    const insights = insightsSection ? insightsSection.replace("INSIGHTS:", "").trim() : "";

    return { metadata, notes, insights };
};

export default function TranscriptTabs({ transcriptData }) {
    const [activeTab, setActiveTab] = useState("transcript");
    const { metadata, notes, insights } = parseContent(transcriptData?.content);

    const renderTabContent = () => {
        switch (activeTab) {
            case "transcript":
                return (
                    <div className="flex flex-col gap-4 mt-4 h-[calc(100vh-280px)] overflow-y-auto custom-scrollbar-section">
                        {transcriptData?.transcript.map((entry, index) => (
                            <div key={index} className="flex gap-4">
                                <div className="w-48 flex flex-col gap-2">
                                    <p className="font-semibold text-gray-950 text-sm">{entry.speaker}</p>
                                    <p className="text-xs text-gray-500">{formatTimestamp(entry.timestamp)}</p>
                                </div>
                                <p className="flex-1 text-sm text-gray-950 font-medium">{entry.text}</p>
                            </div>
                        ))}
                    </div>
                );
            case "notes":
                return (
                    <div className="mt-4">
                        <div className="space-y-2">
                            <p className="font-semibold text-gray-950 text-sm">Meeting Details</p>
                            <div className="p-6 border border-accents-gray rounded-lg">
                                <div className="text-gray-950 text-sm font-medium">
                                    {metadata.split("\n").map((line, index) => {
                                        if (!line.trim()) return null;
                                        const [label, value] = line.split(": ");
                                        return (
                                            <div key={index} className="mb-2">
                                                <span className="font-semibold">{label}</span>
                                                {value ? ": " + value : ""}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <p className="font-semibold text-gray-950 text-sm mt-2">Meeting Notes</p>
                            <div className="p-6 border border-accents-gray rounded-lg">
                                <div className="text-gray-950 text-sm font-medium">
                                    {notes.split("\n").map((line, index) => {
                                        if (!line.trim()) return null;

                                        // Remove markdown formatting
                                        const cleanLine = line.replace(/\*\*/g, "");
                                        const [label, content] = cleanLine.split(": ");

                                        return (
                                            <div key={index} className="mb-2">
                                                <span className="font-semibold">{label}</span>
                                                {content ? ": " + content : ""}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "insights":
                return (
                    <div className="mt-4">
                        <p className="font-semibold text-gray-950 text-sm mb-2">Key Takeaways</p>
                        <div className="border border-accents-gray rounded-lg p-6">
                            <div className="text-gray-950 text-sm font-medium">
                                {insights.split("\n").map((line, index) => {
                                    if (!line.trim()) return null;

                                    // Remove markdown formatting and leading dash
                                    const cleanLine = line.replace(/^\-\s*/, "").replace(/\*\*/g, "");

                                    // Split into label and content
                                    const [label, content] = cleanLine.split(":");

                                    return (
                                        <div key={index} className="mb-2">
                                            <span className="font-semibold">{label.trim()}</span>
                                            {content ? ": " + content.trim() : ""}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex-1 flex flex-col">
            <div className="border-b border-accents-gray">
                <div className="flex gap-2">
                    {["transcript", "notes", "insights"].map((tab) => (
                        <button
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`py-2 font-Inter font-medium text-sm transition-all border-b-[3px] ${
                                activeTab === tab ? "border-gray-950" : "border-transparent text-gray-500 hover:text-black"
                            }`}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                    ))}
                </div>
            </div>
            {renderTabContent()}
        </div>
    );
}
