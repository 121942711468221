import React, { useEffect, useRef } from "react";

const RelativeModal = ({ open, onClose, children, className }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, onClose]);

    if (!open) return null;

    return (
        <div ref={modalRef} className={`absolute top-full right-0 mt-2 z-50 ${className}`}>
            <div className="bg-white border rounded shadow-lg">{children}</div>
        </div>
    );
};

export default RelativeModal;
