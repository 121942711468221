import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import { PaginationItem } from "@mui/material";
import { ReactComponent as SearchIcon } from "@/assets/image/icons/search.svg";

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

const textFieldStyles = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
        padding: "0 !important",
    },
    "& .MuiInputBase-root": {
        paddingLeft: "16px !important",
        paddingRight: "24px !important",
    },
    "& .MuiInputBase-input": {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        height: "36px",
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#E7E7E7",
        borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#E7E7E7",
        borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#E7E7E7",
        borderRadius: "8px",
    },
};

export const AllConnections = ({ allConnectionsData, allLinkedinAccounts, isLoading }) => {
    const [filter, setFilter] = useState("");
    const [chosenLinkedinAccountId, setChosenLinkedinAccountId] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const itemsPerPage = 10;

    const filteredData = useMemo(() => {
        return allConnectionsData.filter((item) => {
            // Check if filter matches firstName or lastName
            const matchesFilter = filter
                ? item.firstName.toLowerCase().includes(filter.toLowerCase()) || item.lastName.toLowerCase().includes(filter.toLowerCase())
                : true;

            // Check if chosenLinkedinAccountId matches accountId
            const matchesAccountId = chosenLinkedinAccountId ? item.accountId === chosenLinkedinAccountId : true;

            return matchesFilter && matchesAccountId;
        });
    }, [filter, chosenLinkedinAccountId, allConnectionsData]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData.slice(startIndex, endIndex); // Slice data for the current page
    }, [currentPage, filteredData]);

    const accountOptions =
        allLinkedinAccounts?.map((account) => ({
            label: account.email,
            value: account.accountId,
        })) || [];

    return (
        <div className="mb-4 h-full border-r border-[#E7E7E7] sm:mb-1 col-span-5">
            <div className="border-b border-[#E7E7E7] flex items-center px-[24px] py-[16px] h-[76px]">
                <p className="text-[14px] font-medium font-Inter text-accents-mainBlack">{`${allConnectionsData?.length || 0} Connections`}</p>
            </div>
            <div className="mailList_container">
                <div className="px-[24px] py-[16px] w-full flex gap-[40px]">
                    <TextField
                        variant="outlined"
                        value={filter}
                        placeholder="Search by name"
                        onChange={(e) => setFilter(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={textFieldStyles}
                    />
                    <div className="flex gap-[16px] items-center">
                        <p className="text-[14px] text-[#6D6D6D] font-Inter whitespace-nowrap">Filter by LinkedIn account: </p>
                        <Select
                            value={chosenLinkedinAccountId || ""}
                            onChange={(event) => {
                                const selected = accountOptions?.find((opt) => opt.value === event.target.value);
                                setChosenLinkedinAccountId(selected?.value || null);
                            }}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span style={{ color: "#b2b2b2" }}>Select an account</span>; // Placeholder styling
                                }
                                return accountOptions.find((option) => option.value === selected)?.label || "";
                            }}
                            sx={{
                                fontFamily: "Inter, sans-serif",
                                fontSize: "14px",
                                padding: 0,
                                paddingBottom: "1px",
                                "& .MuiSelect-select": {
                                    padding: "0px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            }}
                        >
                            {accountOptions?.map((option) => (
                                <MenuItem
                                    sx={{
                                        fontFamily: "Inter, sans-serif",
                                        fontSize: "14px",
                                    }}
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[24px] px-[24px] py-[16px]">
                    {paginatedData?.length > 0 &&
                        paginatedData.map((connection) => {
                            return (
                                <div className="p-[16px] w-full border border-[#E7E7E7] rounded-[8px] flex justify-between items-center">
                                    <div className="flex items-center gap-[16px]">
                                        {connection?.pictureUrl ? (
                                            <img
                                                onClick={() => window.open(connection?.profileUrl, "_blank")}
                                                src={connection?.pictureUrl}
                                                alt={`${connection?.firstName} ${connection?.lastName}`}
                                                style={{
                                                    width: "48px",
                                                    height: "48px",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        ) : (
                                            <span
                                                onClick={() => window.open(connection?.profileUrl, "_blank")}
                                                className="w-[48px] h-[48px] inline-flex items-center justify-center text-white rounded-[4px] cursor-pointer"
                                                style={{
                                                    backgroundColor: defaultColor[Math.floor(Math.random() * 10)],
                                                }}
                                            >
                                                {connection?.firstName?.charAt(0)?.toUpperCase() ?? ""}
                                            </span>
                                        )}
                                        <div className="flex flex-col justify-between">
                                            <p className="main-text-black-500 leading-[1.5]">{`${connection?.firstName} ${connection?.lastName}`}</p>
                                            <p className="font-Inter text-[12px] leading-[1.75] text-[#6D6D6D]">
                                                {connection?.headline || ""}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            navigate(`/ai-engagement/linkedin-inbox?newChat=true&profileId=${connection.profileId}`, {
                                                state: { from: location.pathname },
                                            });
                                        }}
                                        className="font-Inter px-[12px] py-[6px] border border-[#E7E7E7] rounded-[8px] flex items-center justify-center text-[14px] font-medium leading-none text-accents-mainBlack"
                                    >
                                        Message
                                    </button>
                                </div>
                            );
                        })}
                    {!isLoading && filteredData?.length <= 0 && <p className="font-Inter p-[12px]">No connections found</p>}
                    {!isLoading && filteredData?.length > itemsPerPage && (
                        <div className="flex w-full justify-center py-4 sm:py-8">
                            <Pagination
                                className="font-Inter"
                                count={Math.ceil(filteredData?.length / itemsPerPage)}
                                page={currentPage}
                                onChange={(event, newPage) => {
                                    setCurrentPage(newPage);
                                }}
                                showFirstButton
                                showLastButton
                                renderItem={(item) => (
                                    <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />
                                )}
                                sx={{
                                    "& .MuiPaginationItem-root": {
                                        fontFamily: "Inter, sans-serif",
                                        color: "#050505",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "4px",
                                    },
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        backgroundColor: "#2D7A89",
                                        color: "#fff",
                                        borderRadius: "4px",
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
