import React from "react";
import { ReactComponent as AddIcon } from "@/assets/image/crm/opportunities/addIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import useNotes from "@/hooks/crm/useNotes";

export default function Actions({ selected, setSelected, onAddRow, setRefreshUI }) {
    const { deleteNotes } = useNotes();
    const [loading, setLoading] = React.useState(false);
    const handleDelete = async () => {
        setLoading(true);
        try {
            await deleteNotes(selected);
            setSelected([]);
            setRefreshUI((prev) => !prev);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };
    return (
        <div className="flex items-center gap-4">
            {selected.length > 0 && (
                <button
                    onClick={handleDelete}
                    disabled={loading}
                    className="py-1 px-3 text-red-500 flex justify-center text-sm gap-1 min-h-[28px] min-w-[65px] text-center items-center rounded-md border border-red-300 hover:bg-red-100"
                >
                    {loading ? <CircularProgress size={16} /> : "Delete"}
                </button>
            )}
            <button
                onClick={onAddRow}
                className="py-1 px-3 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100"
            >
                <AddIcon />
                Add New
            </button>
            {/* <button className="py-1 px-3 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100">
                <FilterIcon />
                Filter
            </button>
            <button className="py-1 px-3 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100">
                <OptionsIcon />
                Options
            </button> */}
        </div>
    );
}
