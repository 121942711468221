import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

const THIS_MONTH = "This Month";
const LAST_MONTH = "Last Month";
const THIS_YEAR = "This Year";

export const filterOptions = {
    THIS_MONTH,
    LAST_MONTH,
    THIS_YEAR,
};

export const webIntentFilterLabels = {
    week: "Past 7 Days",
    month: "Past 30 Days",
    all: "All",
};

export const webIntentFilterOptions = [
    { label: "Past 7 Days", value: "week" },
    { label: "Past 30 Days", value: "month" },
    { label: "All", value: "all" },
];

export const options = [
    {
        label: THIS_MONTH,
        value: "THIS_MONTH",
    },
    {
        label: LAST_MONTH,
        value: "LAST_MONTH",
    },
    {
        label: THIS_YEAR,
        value: "THIS_YEAR",
    },
];

export default function FilterPopup({ anchorEl, handleClose, open, optionsArray }) {
    const optionsItems = optionsArray || options;
    return (
        <Popper anchorEl={anchorEl} open={open} transition placement="bottom-end">
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper
                        className="mt-2"
                        sx={{
                            boxShadow: "0px 4px 34px 0px #00000029",
                            borderRadius: "8px",
                            padding: "12px",
                        }}
                    >
                        {optionsItems.map((item) => {
                            return (
                                <MenuItem
                                    key={item.value}
                                    value={item.value || "THIS_MONTH"}
                                    onClick={() => handleClose(item.value)}
                                    sx={{
                                        "&.MuiMenuItem-root": {
                                            fontFamily: "Inter",
                                            textAlign: "start",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            color: "#050505",
                                            borderRadius: "8px",
                                        },
                                        "&.MuiMenuItem-root:hover": {
                                            backgroundColor: "#F4F3F0",
                                        },
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}
