// src/components/sidebar/SidebarCreditsUsageItem.jsx

import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

function formatNumber(num = 0) {
    // Return up to two decimal places, with K (thousands) or M (millions)
    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(0) + "M";
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(0) + "K";
    } else {
        return num.toFixed(0);
    }
}

export function SidebarCreditsUsageItem({ data, open }) {
    const used = data?.data?.used ?? 0;
    const total = data?.data?.total ?? 0;
    const usagePercentage = total > 0 ? ((total - used) / total) * 100 : 0;

    const usedFormatted = formatNumber(total - used);

    return (
        <Box
            sx={{
                // Use a column layout when closed (show number below),
                // and a row layout when open (show number on the right).
                display: "flex",
                flexDirection: open ? "row" : "column",
                alignItems: open ? "start" : "center",
                justifyContent: open ? "start" : "center",
                gap: open ? 1.5 : 0,
                p: "0px 8px",
                marginTop: open ? "24px" : "40px",
                borderRadius: 2,
                transition: "all 150ms",
            }}
        >
            {/* Parent Box holding the CircularProgress stack */}
            <Box position="relative" display="inline-flex" sx={{ mb: open ? 0 : 1 }}>
                {/* Grey background circle (always 100%) */}
                <CircularProgress variant="determinate" value={100} thickness={5} size={20} sx={{ color: "#d1d1d1" }} />
                {/* Blue circle for the used portion */}
                <CircularProgress
                    variant="determinate"
                    value={usagePercentage}
                    size={20}
                    thickness={5}
                    sx={{
                        color: "#2D7A89",
                        position: "absolute",
                        left: 0,
                    }}
                />
            </Box>

            {/* If open: "XX credits used" to the right */}
            {/* If closed: XX below the progress circle */}
            {open ? (
                <Typography className="text-center text-[#888888] text-[14px] font-semibold font-['Inter']">
                    {usedFormatted} credits
                </Typography>
            ) : (
                <Typography className="text-center text-[#888888] text-[14px] font-semibold font-['Inter']">{usedFormatted}</Typography>
            )}
        </Box>
    );
}
