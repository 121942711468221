import React, { useState } from "react";
import AffirmationModal from "./AffirmationModal";

function SelectAll({ handleSelectAll, handleDeleteAll, getSelectedMailNumber }) {
    const [isChecked, setIsChecked] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        handleSelectAll(newCheckedState);
    };

    return (
        <div className="flex items-center">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="form-checkbox h-4 w-4 text-accents-mainGreen"
            />
            <label className="ml-2 text-black-900 font-bold">Select All </label>
            <button
                className="mx-4 text-sm text-blue-500 underline cursor-pointer"
                aria-label="Clear All"
                onClick={() => setIsModalVisible(true)}
            >
                Clear All
            </button>
            {getSelectedMailNumber() > 0 && <label className="ml-2 text-black-900 text-sm">{getSelectedMailNumber()} Selected</label>}
            {isModalVisible && (
                <AffirmationModal
                    message={`Are you sure you want to decline ${getSelectedMailNumber()} ${getSelectedMailNumber() > 1 ? "emails" : "email"}?`}
                    handleConfirm={() => {
                        handleDeleteAll();
                        setIsModalVisible(false);
                    }}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
        </div>
    );
}

export default SelectAll;
