import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const SidebarItem = ({
    icon: Icon,
    label,
    open,
    width = "20px",
    height = "20px",
    router = "#",
    pathname,
    marginBottom = "0px",
    btnStyles = "",
    textStyles = "",
    labelFontSize = "14px",
    labelFontWeight = 400,
    iconLabelGap = 1,
    id,
    onClick = () => {},
    isComingSoon = false,
}) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        handleClose();
    }, [open]);

    const handleClose = () => {
        setIsTooltipOpen(false);
    };

    const handleOpen = () => {
        if (!open) {
            setIsTooltipOpen(true);
        } else if (open && isComingSoon) {
            setIsTooltipOpen(true);
        }
    };

    // for both the base route and any sub-routes. For example, this will return true
    // for both '/lists' and any paths like '/lists/any' that start with '/lists'.
    const isActivePath = pathname.startsWith(router);

    return (
        <ListItem
            disablePadding
            sx={{
                display: "block",
                "&:not(:last-child)": {
                    // Example of a style applied to all but the last child
                },
            }}
        >
            <Tooltip
                title={`${open ? "" : label} ${isComingSoon ? "(Coming Soon)" : ""}`}
                placement="right"
                open={isTooltipOpen}
                onOpen={handleOpen}
                onClose={handleClose}
            >
                <ListItemButton
                    component={"a"}
                    sx={{
                        fontFamily: "Inter",
                        height: "fit-content",
                        padding: "4px 8px !important",
                        rowGap: open ? 0 : 2,
                        marginBottom: marginBottom,
                        columnGap: open ? iconLabelGap : 0,
                        overflow: "hidden",
                        justifyContent: "center",
                        transition: "all 100ms cubic-bezier(0.1, 0.03, 0.4, 0.97)",
                        backgroundColor: isActivePath ? "rgba(188, 188, 188, 0.26)" : "transparent",
                        borderRadius: 2,
                        "&:hover": {
                            backgroundColor: "rgba(188, 188, 188, 0.26)",
                            "& .mui-item-text, & .mui-icon": {
                                color: "#050505",
                            },
                        },
                    }}
                    className={btnStyles}
                    onClick={(e) => {
                        if (label === "FuseAI") {
                            onClick(e);
                            return;
                        }
                        e.stopPropagation();
                        navigate(router);
                    }}
                    id={id}
                >
                    <Icon
                        style={{ width: width, height: height }}
                        className={` mui-icon shrink-0 inline-flex justify-center ${isActivePath ? "text-[#050505]" : "text-[#888888]"} transition-all`}
                    ></Icon>
                    <ListItemText
                        className={`mui-item-text !text-[20px] flex ${isActivePath ? "text-[#050505]" : "text-[#888888]"} ${textStyles}`}
                        primaryTypographyProps={{
                            style: {
                                fontWeight: labelFontWeight,
                                fontFamily: "Inter",
                                fontSize: labelFontSize,
                                margin: 0,
                            },
                        }}
                        primary={label}
                        sx={{
                            opacity: open ? 1 : 0,
                            fontWeight: 500,
                            margin: 0,
                            transition: "all 150ms cubic-bezier(0.1, 0.03, 0.4, 0.97)",
                        }}
                    />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
};
