import React, { useEffect, useState } from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { ReactComponent as PathIcon } from "@/assets/image/crm/timeline/path.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import Activitities from "@/components/crm/timeline/Activitities";
import TimelineTasks from "@/components/crm/timeline/timeline-tasks/TimelineTasks";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TimelineNotes from "@/components/crm/timeline/timeline-notes/TimelineNotes";
import TimelineFiles from "@/components/crm/timeline/timeline-files/TimelineFiles";
import MainLoader from "@/components/MainLoader";
import { useNavigate, useParams } from "react-router-dom";
import useTimeline from "@/hooks/crm/useTimeline";
import dayjs from "dayjs";

export default function OpportunityTimelineScene() {
    const [selectedTab, setSelectedTab] = useState("timeline"); // timeline, tasks, notes, files
    const [opportunity, setOpportunity] = useState(null);
    const [loading, setLoading] = useState(true);
    const { opportunityId } = useParams();
    const { getOpportunityById } = useTimeline();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchOpportunity = async () => {
            setLoading(true);
            try {
                const res = await getOpportunityById(opportunityId);
                if (res) {
                    setOpportunity(res.data[0]);
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        fetchOpportunity();
    }, []);

    const handleOnTabChange = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <MainLayout>
            <MainTitleBar>
                <p>Opportunities</p>
            </MainTitleBar>
            {loading ? (
                <MainLoader />
            ) : !opportunity ? (
                <BaseContainer>
                    <div className="flex items-center justify-center h-96">
                        <p className="text-lg text-dgray-6">Opportunity not found</p>
                    </div>
                </BaseContainer>
            ) : (
                <BaseContainer>
                    <div className="flex items-center space-x-2 mt-5 font-semibold">
                        <img
                            className="h-6 w-6 rounded-md"
                            src={`https://logo.clearbit.com/${opportunity?.company?.website}`}
                            alt="company"
                            onError={(e) => (e.target.style.display = "none")}
                        />
                        <p
                            onClick={() => navigate(`/records/accounts/${opportunity?.company?._id}`)}
                            className="text-lg hover:underline cursor-pointer"
                        >
                            {opportunity.company.name}
                        </p>
                    </div>
                    <p className="text-sm text-dgray-500 mt-2">Created At {dayjs(opportunity.createdAt).format("D MMMM, YYYY")}</p>
                    <div className="flex items-start gap-4 mt-5 mb-10">
                        <div className="border border-stone-250 rounded-lg pb-5 min-h-96 w-3/4">
                            <div className="border-b px-6 border-stone-250 flex gap-7">
                                <button
                                    onClick={() => handleOnTabChange("timeline")}
                                    className={`${selectedTab === "timeline" ? "text-black border-b-2 border-black" : "text-dgray-6"} pt-4 pb-2 flex gap-1 text-sm items-center font-semibold `}
                                >
                                    <PathIcon className="h-5 w-5 !text-inherit" />
                                    Timeline
                                </button>
                                <button
                                    onClick={() => handleOnTabChange("tasks")}
                                    className={`${selectedTab === "tasks" ? "text-black border-b-2 border-black" : "text-dgray-6"} pt-4 pb-2 flex gap-1 text-sm items-center font-semibold `}
                                >
                                    <LibraryAddCheckOutlinedIcon className="!h-5 !w-5" />
                                    Tasks
                                </button>
                                <button
                                    onClick={() => handleOnTabChange("notes")}
                                    className={`${selectedTab === "notes" ? "text-black border-b-2 border-black" : "text-dgray-6"} pt-4 pb-2 flex gap-1 text-sm items-center font-semibold `}
                                >
                                    <DescriptionOutlinedIcon className="!h-5 !w-5" />
                                    Notes
                                </button>
                                <button
                                    onClick={() => handleOnTabChange("files")}
                                    className={`${selectedTab === "files" ? "text-black border-b-2 border-black" : "text-dgray-6"} pt-4 pb-2 flex gap-1 text-sm items-center font-semibold `}
                                >
                                    <FolderOutlinedIcon className="!h-5 !w-5" />
                                    Files
                                </button>
                            </div>
                            {selectedTab === "timeline" && <Activitities />}
                            {selectedTab === "tasks" && <TimelineTasks />}
                            {selectedTab === "notes" && <TimelineNotes />}
                            {selectedTab === "files" && <TimelineFiles />}
                        </div>

                        <div className="border border-stone-250 rounded-lg w-1/4 min-w-[400px]">
                            <div className="border-b p-3 border-stone-250">Details</div>
                            <div className="p-3">
                                <div className="flex items-center rounded-lg space-x-2 bg-primary-header p-2 font-semibold">
                                    <img
                                        className="h-5 w-5 rounded-md"
                                        src={`https://logo.clearbit.com/${opportunity?.company?.website}`}
                                        alt="company"
                                        onError={(e) => (e.target.style.display = "none")}
                                    />
                                    <p
                                        onClick={() => navigate(`/records/accounts/${opportunity?.company?._id}`)}
                                        className="hover:underline cursor-pointer"
                                    >
                                        {opportunity.company?.name}
                                    </p>
                                </div>
                                <div className="mt-2">
                                    <table className="w-full text-sm text-left text-dgray-6">
                                        <tbody>
                                            {opportunity?.company?.address?.country && (
                                                <tr className="">
                                                    <td className="flex items-center gap-1 py-2">
                                                        <MapOutlinedIcon className="!w-4 !h-4" /> Country:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">{opportunity.company.address.country}</td>
                                                </tr>
                                            )}
                                            <tr className="">
                                                <td className="flex items-center gap-1 py-2">
                                                    <PaidOutlinedIcon className="!w-4 !h-4" /> Amount:
                                                </td>
                                                <td className="font-medium text-black pl-2">${opportunity.value}</td>
                                            </tr>

                                            {opportunity?.createdBy?.name && (
                                                <tr className="">
                                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                                        <ModeEditOutlineOutlinedIcon className="!w-4 !h-4" /> Created By:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">{opportunity.createdBy?.name}</td>
                                                </tr>
                                            )}
                                            {opportunity?.dueDate && (
                                                <tr className="border-b">
                                                    <td className="flex items-center gap-1 py-2">
                                                        <CalendarTodayOutlinedIcon className="!w-4 !h-4" /> Close Date:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">
                                                        {dayjs(opportunity.dueDate).format("D MMMM, YYYY")}
                                                    </td>
                                                </tr>
                                            )}
                                            {opportunity?.status && (
                                                <tr className="">
                                                    <td className="flex items-center gap-1 py-2">
                                                        <CorporateFareOutlinedIcon className="!w-4 !h-4" /> Stage:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">
                                                        <span className="bg-[#EAF1EE] rounded-md py-1.5 px-4 text-[#1C4E38]">
                                                            {opportunity.status}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                            {opportunity?.updatedAt && (
                                                <tr className="">
                                                    <td className="flex items-center gap-1 py-2">
                                                        <CalendarTodayOutlinedIcon className="!w-4 !h-4" /> Last Update:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">
                                                        {dayjs(opportunity.updatedAt).format("D MMMM, YYYY")}
                                                    </td>
                                                </tr>
                                            )}
                                            {opportunity?.pointOfContact?.firstName && (
                                                <tr>
                                                    <td className="flex items-center gap-1 py-2 whitespace-nowrap">
                                                        <GroupOutlinedIcon className="!w-4 !h-4" /> Point of Contact:
                                                    </td>
                                                    <td className="font-medium text-black pl-2">
                                                        {opportunity?.pointOfContact?.firstName +
                                                            " " +
                                                            opportunity?.pointOfContact?.lastName}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </BaseContainer>
            )}
        </MainLayout>
    );
}
