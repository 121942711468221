import { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { Formik, Form } from "formik";
import FileUploaderWidget from "@/components/email_validation/FileUploaderWidget";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import { toast } from "react-toastify";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation } from "@tanstack/react-query";
import { emailValidationTemplateHeader, parseStringCSVtoArray } from "@/utils/common";
import { useNavigate } from "react-router-dom";
import { useCredits } from "@/hooks/useCredits";

function FileUploadValidationWidget({ setCurrentWidgetIndex, setIsLimitModalOpen }) {
    const [attachedFileInfo, setAttachedFileInfo] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { credits } = useCredits();
    const navigate = useNavigate();

    const sendDataAsList = useHTTPRequest("/email-validation", "PUT", false, {});
    const { mutateAsync: sendDataAsListMutation } = useMutation({
        mutationFn: sendDataAsList,
    });

    const initialValues = {
        file: {},
        fileName: "",
    };

    const handleFileContent = (fileName, fileContent) => {
        let attachedInfo = {
            name: fileName,
            content: fileContent,
        };

        const inputs = [];
        for (let i = 0; i < fileContent.length; i++) {
            if (emailValidationTemplateHeader !== fileContent[i][0] && fileContent[i][0] !== "") {
                inputs.push({ email: fileContent[i][0] });
            }
        }
        attachedInfo.content = inputs;
        setAttachedFileInfo(attachedInfo);
    };

    const handleSubmit = async (values) => {
        try {
            // Validate file name
            if (!values.fileName) {
                toast.error("Please fill in the list name you'd like to validate");
                return;
            }
            if (values.fileName.length > 80) {
                toast.error("List name is too long!");
                return;
            }
            // Validate file
            if (!values.file?.name) {
                toast.error("Please select a file");
                return;
            }
            if (values.file.size >= 5000000) {
                toast.error("File size is too large (max 5MB)");
                return;
            }

            setLoading(true);
            var reader = new FileReader();
            reader.readAsText(values.file, "UTF-8");
            reader.onload = async function (evt) {
                const res = await parseStringCSVtoArray(evt?.currentTarget?.result);
                if (credits?.data?.available <= res.length) {
                    toast.error("You don't have enough email validation credits, please refill your credits");
                    setIsLimitModalOpen(true);
                    setLoading(false);
                    return;
                }

                await sendDataAsListMutation(
                    JSON.stringify({
                        emails: attachedFileInfo.content,
                        fileName: values.fileName,
                    })
                );
                toast.success("Successfully uploaded!");
                setLoading(false);
                navigate("/bulkenrich");
            };
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong, try again");
        }
    };

    useEffect(() => {
        setAttachedFileInfo(null);
    }, []);

    return (
        <>
            <div className="w-full flex flex-col items-center justify-center">
                <div className="flex w-full flex-col gap-8">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ setFieldValue }) => (
                            <Form className="flex flex-col gap-8">
                                <div className="flex flex-col gap-8 px-6">
                                    <div className="flex flex-col gap-2">
                                        <span className="font-Inter text-left text-[#050505] text-sm font-semibold leading-tight">
                                            List name
                                        </span>
                                        <input
                                            name="fileName"
                                            placeholder="List name"
                                            onChange={(e) => setFieldValue("fileName", e.target.value)}
                                            className="outline-none max-w-[630px] py-3 px-6 font-Inter text-sm w-full placeholder:text-[#B0B0B0] font-medium border-[#e7e7e7] border bg-white rounded-[8px]"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <FileUploaderWidget
                                            onChange={(file) => setFieldValue("file", file)}
                                            attachedFileName={attachedFileInfo?.name}
                                            onFileContent={handleFileContent}
                                            name="file"
                                            reset={() => {
                                                setAttachedFileInfo(null);
                                            }}
                                        />
                                        <div className="flex flex-row items-center gap-1">
                                            <InformationSVG />
                                            <span className="text-[#888888] text-xs font-normal font-Inter leading-none">
                                                The column name in uploaded file must precisely match the one specified in the provided
                                                template.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-fit p-2 bg-[#f6f6f6] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                        <div className="flex-col justify-center items-start gap-2 flex">
                                            <div className="text-[#090c05] text-xs font-normal font-Inter leading-[16.80px]">
                                                Note:
                                                <ul className="list-disc pl-4">
                                                    <li>You can only verify up to 200 email addresses per domain name every 24 hours.</li>
                                                    <li>You can verify up to 50,000 email addresses per bulk.</li>
                                                    <li>Additional rows will be skipped.</li>
                                                    <li>Duplicate email addresses will be skipped.</li>
                                                    <li>File size max is 5 MB</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 border-t border-t-[#e7e7e7] w-full mt-5">
                                    <div className="flex flex-row mt-6 ml-6 justify-start gap-4">
                                        <Button
                                            onClick={() => setCurrentWidgetIndex(1)}
                                            className="bg-white w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-[#888888] text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-stone-50 transition-opacity"
                                        >
                                            Go back
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isLoading}
                                            className="w-[110px] text-white bg-[#050505] font-Inter rounded-lg text-sm font-medium leading-tight py-2 px-6 normal-case hover:opacity-80 transition-opacity duration-300"
                                        >
                                            {isLoading ? "Loading..." : "Finish"}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default FileUploadValidationWidget;
