import { useEffect, useState } from "react";
import MainLayout from "@/components/MainLayout";
import useTeam from "@/hooks/useTeam";
import { toast } from "react-toastify";
import { useAuthContext } from "@/context/AuthContext";
import MainTitleBar from "@/components/MainTitleBar";
import MainLoader from "@/components/MainLoader";

export default function TeamScene() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const authContext = useAuthContext();
    const { getInvitations, acceptInvitation, rejectInvitation } = useTeam();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await getInvitations();
            setData(response.data);
            setIsLoading(false);
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAcceptInvitation = async (id) => {
        setIsButtonClicked(true);
        const response = await acceptInvitation({ id });
        setIsButtonClicked(false);
        if (response?.status === true) {
            await authContext.signOut();
            toast.info("Invitation accepted. Please re-login", { theme: "colored" });
        } else {
            toast.error(response?.message, { theme: "colored" });
        }
    };

    const handleRejectInvitation = async (id) => {
        setIsButtonClicked(true);
        await rejectInvitation({ id });
        setData(data?.filter((i) => i._id !== id));
        setIsButtonClicked(false);
    };

    return (
        <MainLayout>
            <MainTitleBar>
                <div className="flex items-end justify-start gap-2">
                    <p>Invitation management</p>
                </div>
            </MainTitleBar>
            {isLoading && <MainLoader />}
            {!isLoading && (
                <div className="p-[24px] w-full h-full flex gap-10">
                    {data?.length > 0 &&
                        data.map((item, index) => (
                            <div className="p-[16px] min-w-[493px] h-fit flex flex-col gap-[24px] rounded-[8px] border border-[#E7E7E7]">
                                <p className="font-Inter text-[12px] text-[#6D6D6D]">Invitation from</p>
                                <div className="flex flex-col gap-[4px]">
                                    <p className="main-text-black-600">{item.ownerName}</p>
                                    <p className="font-Inter text-[12px] text-[#050505]">You have been invited to join the team</p>
                                </div>
                                <div className="flex gap-[16px]">
                                    <button
                                        disabled={isButtonClicked}
                                        className="main-black-button"
                                        onClick={() => handleAcceptInvitation(item._id)}
                                    >
                                        Accept
                                    </button>
                                    <button
                                        disabled={isButtonClicked}
                                        className="main-white-button"
                                        onClick={() => handleRejectInvitation(item._id)}
                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        ))}
                    {data?.length === 0 && <p className="main-text-black-500">No invitations available.</p>}
                </div>
            )}
        </MainLayout>
    );
}
