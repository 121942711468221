import { CustomButton } from "@/components/crm/contacts/Button";
import React from "react";

function ActionButtons({ saveICPFilter, discardChanges, isDisabled }) {
    return (
        <div className="flex flex-col gap-6 p-6 border-t border-[#e7e7e7] justify-start">
            <div>
                <div className="text-[#050505] text-base font-semibold font-['Inter']">Apply filter to Integration(s)</div>
                <div className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">
                    If applied, only the leads that match your ICP will sync to the integration(s) you choose, else all profiles will sync
                    to the integration(s).
                </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-6 items-center">
                <CustomButton
                    onClick={discardChanges}
                    title="Cancel"
                    height={40}
                    width={95}
                    px={24}
                    py={12}
                    titleTextSize={14}
                    titleFontWeight={500}
                    border="1px solid #e7e7e7"
                    titleColor="#050505"
                    bg="white"
                />
                <CustomButton
                    onClick={saveICPFilter}
                    disabled={isDisabled}
                    title="Apply Filters"
                    height={40}
                    width={124}
                    px={24}
                    py={12}
                    titleTextSize={14}
                    titleFontWeight={500}
                />
            </div>
        </div>
    );
}

export default ActionButtons;
