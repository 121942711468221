// CRMUploadTable.jsx

import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Pagination,
    Dialog,
    DialogContent,
    IconButton,
    Button,
    DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sumBy } from "lodash";

import CRMUploadTableHeader from "./CRMUploadTableHeader";
import CRMUploadTableRow from "./CRMUploadTableRow";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { useFormatUploadResults } from "@/hooks/useFormatUploadResults";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { Person } from "@mui/icons-material";
import { CurrentEntity, NameIcon, StatusIcon, SuggestionIcon, TargetEntity } from "./TableIcons";

const headCells = [
    { id: "name", label: "Name", align: "left", width: "25%", icon: <NameIcon /> },
    { id: "entity", label: "Target Entity", align: "center", width: "15%", icon: <TargetEntity /> },
    { id: "currentEntity", label: "Current Entity", align: "center", width: "15%", icon: <CurrentEntity /> },
    { id: "status", label: "Status", align: "center", width: "15%", icon: <StatusIcon /> },
    { id: "suggestions", label: "Suggestions", align: "left", width: "30%", icon: <SuggestionIcon /> },
];

export default function CRMUploadTable() {
    const formattedResult = useFormatUploadResults();
    const { toggleShow, target, targetEntity, uploads } = useCRMPushResultStore();

    const [totalCount, setTotalCount] = useState(formattedResult.length);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("status");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Adjust as needed
    const [currentPageResults, setCurrentPageResults] = useState(formattedResult);

    // Calculate how many were not pushed
    const totalContactsCount = uploads.length ? sumBy(uploads, "numberOfContacts") : 0;
    const contactNotPushed = totalContactsCount - totalCount;

    // Close dialog if user clicks outside
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
        toggleShow();
    });

    // Sort + paginate
    useEffect(() => {
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const sorted = [...formattedResult].sort((a, b) => {
            if (a[orderBy] === b[orderBy]) return 0;
            return order === "asc" ? (a[orderBy] > b[orderBy] ? 1 : -1) : a[orderBy] < b[orderBy] ? 1 : -1;
        });
        setCurrentPageResults(sorted.slice(startIndex, endIndex));
    }, [formattedResult, page, rowsPerPage, order, orderBy]);

    useEffect(() => {
        setTotalCount(formattedResult.length);
    }, [formattedResult]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const updateSorting = (cell) => {
        if (cell.id === orderBy) {
            setOrder(order === "asc" ? "desc" : "asc");
        } else {
            setOrderBy(cell.id);
            setOrder("asc");
        }
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="lg"
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: "16px",

                    boxShadow: "0px 4px 34px rgba(0,0,0,0.16)",
                },
            }}
        >
            {/* Header area */}
            <DialogContent
                sx={{
                    borderBottom: "1px solid #E7E7E7",
                    pb: 2,
                    pt: 2,
                }}
            >
                <div className="flex justify-between w-full items-center">
                    <div>
                        <div className="text-base text-[#050505] font-semibold">Upload completed</div>
                    </div>
                    <IconButton
                        aria-label="close"
                        onClick={() => toggleShow()}
                        sx={{
                            p: 0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogContent>

            {/* Body area */}
            <DialogContent sx={{ pt: 3, pb: 3 }}>
                {contactNotPushed > 0 && (
                    <div className="text-sm mb-4 text-[#5D5D5D]">{contactNotPushed} contact(s) were not pushed due to the lack of data</div>
                )}

                <Box sx={{ width: "100%" }}>
                    <div className=" flex flex-row gap-2 pb-[16px]">
                        <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">
                            {target} {targetEntity}
                        </div>
                    </div>

                    <div className="text-[#5d5d5d] text-sm font-normal font-['Inter'] mb-[24px]">
                        Please review the results in the table below.
                    </div>

                    <TableContainer
                        sx={{
                            border: "1px solid #E7E7E7",
                            borderRadius: "8px",
                        }}
                    >
                        <Table
                            sx={{
                                minWidth: 650,
                                backgroundColor: "white",
                                "& .MuiTableCell-root": {
                                    fontSize: "14px",
                                    color: "#050505",
                                },
                            }}
                        >
                            <CRMUploadTableHeader order={order} orderBy={orderBy} headCells={headCells} onChangeSorting={updateSorting} />
                            <TableBody>
                                {!formattedResult.length && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center" sx={{ py: 4 }}>
                                            <div className="text-xl font-semibold">No uploads</div>
                                            <div className="text-md text-[#5D5D5D]">Select different profiles / bulk enrichment</div>
                                        </TableCell>
                                    </TableRow>
                                )}

                                {currentPageResults.map((row, idx) => (
                                    <CRMUploadTableRow key={`${row.name}-${idx}`} row={row} labelId={`upload-table-row-${idx}`} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination at bottom */}
                    <div className="mt-3 flex justify-end items-center gap-2">
                        <Pagination
                            count={Math.ceil(totalCount / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            sx={{
                                "& .MuiPaginationItem-root": {
                                    fontFamily: "Inter, sans-serif",
                                    color: "#050505",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "4px",
                                },
                                "& .MuiPaginationItem-root.Mui-selected": {
                                    backgroundColor: "#2D7A89",
                                    color: "#fff",
                                },
                            }}
                        />
                    </div>
                </Box>
            </DialogContent>

            {/* Cancel / Finish buttons in dialog actions */}
            {/* <DialogActions sx={{ borderTop: "1px solid #E7E7E7", pt: 2, pb: 2 }}>
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderColor: "#E7E7E7",
                        color: "#050505",
                        px: "12px",
                        py: "6px",
                        "&:hover": { borderColor: "#E7E7E7", backgroundColor: "#F4F3F0" },
                    }}
                    onClick={() => toggleShow()}
                >
                    <div className="text-center text-[#050505] text-sm font-medium font-['Inter'] capitalize">Cancel</div>
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: "none",
                        backgroundColor: "#050505",
                        "&:hover": { backgroundColor: "#050505" },
                        px: "12px",
                        py: "6px",
                    }}
                    onClick={() => {
                        // do something on finish
                        toggleShow();
                    }}
                >
                    <div className="text-center text-white text-sm font-medium font-['Inter'] capitalize">Finish</div>
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
