import ReactPlayer from "react-player";
import { useState, useRef } from "react";
import PlayIcon from "../../../assets/image/crm/transcript/play_circle.svg";
import ForwardIcon from "../../../assets/image/crm/transcript/forward_10.svg";
import ReplayIcon from "../../../assets/image/crm/transcript/replay_10.svg";
import PauseIcon from "../../../assets/image/crm/transcript/pause_circle.svg";

export default function VideoPlayer({ videoUrl }) {
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [played, setPlayed] = useState(0);
    const playerRef = useRef(null);
    const [error, setError] = useState(null);

    // Format time in seconds to MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleProgress = (state) => {
        setProgress(state.played);
        setPlayed(state.playedSeconds);
    };

    const handleDuration = (duration) => {
        setDuration(duration);
    };

    const handleSeek = (e) => {
        const bounds = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const width = bounds.width;
        const percentage = x / width;
        playerRef.current.seekTo(percentage, "fraction");
    };

    const handleRewind = () => {
        const newTime = Math.max(played - 10, 0);
        playerRef.current.seekTo(newTime, "seconds");
    };

    const handleFastForward = () => {
        const newTime = Math.min(played + 10, duration);
        playerRef.current.seekTo(newTime, "seconds");
    };

    const handleError = (e) => {
        console.error("Error playing video:", e);
        setError("Failed to load video");
    };

    return (
        <div className="flex flex-col items-center justify-center w-[648px] h-[531px] p-6 border border-accents-gray rounded-lg">
            {/* Video container */}
            <div className="w-[607px] h-[400px] rounded-lg bg-black  flex-shrink-0 overflow-hidden">
                <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    width="100%"
                    height="100%"
                    playing={playing}
                    controls={false}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onError={handleError}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload",
                                style: {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                },
                            },
                        },
                    }}
                />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {/* Controls below the video */}
            <div className="mt-4">
                {/* Timeline */}
                <div className="w-[600px] h-[6px] bg-gray-200 rounded-full cursor-pointer mb-1" onClick={handleSeek}>
                    <div className="h-full bg-gray-950 rounded-full" style={{ width: `${progress * 100}%` }} />
                </div>

                {/* Time display */}
                <div className="flex justify-between text-sm text-gray-950 mb-1">
                    <span>{formatTime(played)}</span>
                    <span>{formatTime(duration)}</span>
                </div>

                {/* Playback controls */}
                <div className="flex items-center justify-center gap-4">
                    <button className=" hover:bg-gray-300 rounded-full transition-colors" onClick={handleRewind}>
                        <img src={ReplayIcon} alt="Rewind 10 seconds" className="w-6 h-6" />
                    </button>
                    <button className=" rounded-full hover:bg-gray-300 transition-colors" onClick={handlePlayPause}>
                        <img src={playing ? PauseIcon : PlayIcon} alt={playing ? "Pause" : "Play"} className="w-10 h-10" />
                    </button>
                    <button className=" hover:bg-gray-300 rounded-full transition-colors" onClick={handleFastForward}>
                        <img src={ForwardIcon} alt="Forward 10 seconds" className="w-6 h-6" />
                    </button>
                </div>
            </div>
        </div>
    );
}
