// using axios
import axios from "axios";

import useAuth from "../useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";

export default function useFiles() {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const uploadFile = async (id, type, file, onUploadProgress, opportunityId) => {
        const authToken = await getAuthToken();
        try {
            const formData = new FormData();
            formData.append("file", file);
            if (type == "opportunity") {
                formData.append("opportunityId", id);
            } else if (type == "task") {
                formData.append("taskId", id);
                formData.append("opportunityId", opportunityId);
            } else {
                formData.append("noteId", id);
                formData.append("opportunityId", opportunityId);
            }

            const response = await axios.post(`${apiUrl}/api/v1/files/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress,
            });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // get all files, type can be opportunityId, taskId or noteId
    const getFiles = async (id, type) => {
        const authToken = await getAuthToken();
        try {
            const response = await axios.get(`${apiUrl}/api/v1/files/getAll?${type}=${id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // delete a file
    const deleteFile = async (id) => {
        const authToken = await getAuthToken();
        try {
            const response = await axios.delete(`${apiUrl}/api/v1/files/${id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // get signed url of a file
    const getSignedUrl = async (id) => {
        const authToken = await getAuthToken();
        try {
            const response = await axios.get(`${apiUrl}/api/v1/files/signedURL/${id}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    return {
        uploadFile,
        getFiles,
        deleteFile,
        getSignedUrl,
    };
}
