import React from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import ContactsScene from "./ContactsScene"; // <-- Import directly
import RecordsTabs from "@/components/crm/RecordsTabs";
import MainTitle from "@/components/MainTitle";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function ContactsPage() {
    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                <p>Records</p>
            </MainTitleBar>
            <RecordsTabs />
            <div className="p-6">
                <ContactsScene />
            </div>
            <FuseAgentModal />
        </MainLayout>
    );
}
