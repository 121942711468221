import React, { useEffect } from "react";
import Highcharts from "highcharts";

export default function HeadCountChart({ countData, id, title }) {
    const chartColors = ["#A2ADFF", "#D3E1DB", "#D1D1D1", "#FFC8C8", "#FFCE6B", "#C4D9F5", "#F7E3D4"];
    const totalCount = Object.keys(countData)
        .slice(0, 7)
        .reduce((sum, key) => sum + countData[key], 0);

    function formatNumber(num) {
        if (num >= 1e12) return (num / 1e12).toFixed(0) + "T"; // Trillions
        if (num >= 1e9) return (num / 1e9).toFixed(0) + "B"; // Billions
        if (num >= 1e6) return (num / 1e6).toFixed(0) + "M"; // Millions
        if (num >= 1e3) return (num / 1e3).toFixed(0) + "k"; // Thousands
        return num.toString(); // Less than 1000
    }

    useEffect(() => {
        const chartOptions = {
            chart: {
                type: "pie",
                height: 200,
                width: 200,
                spacingBottom: 20,
            },
            title: { text: "" },
            plotOptions: {
                pie: {
                    innerSize: "80%",
                    center: ["50%", "50%"],
                    size: "100%",
                    borderWidth: 4,
                    borderColor: "#ffffff", // White space between segments
                    showInLegend: false,
                    dataLabels: {
                        enabled: false,
                    },
                    borderRadius: "50%", // Makes the edges soft
                },
            },
            colors: chartColors,
            series: [
                {
                    name: "Count",
                    data: Object.keys(countData)
                        .slice(0, 7)
                        .map((key) => ({
                            name: key,
                            y: countData[key],
                        })),
                },
            ],
            credits: { enabled: false },
            tooltip: {
                enabled: false, // Disable the tooltip
            },
        };

        const chart = Highcharts.chart(id, chartOptions);

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [countData]);

    return (
        <div className="w-full font-Inter">
            <div className="flex justify-center">
                <div className="relative w-fit">
                    <div id={id} className=""></div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                        <h2 className="text-dgray-6 text-sm">Total</h2>
                        <h1 className="font-medium text-lg mt-1">{formatNumber(totalCount)}</h1>
                    </div>
                </div>
            </div>

            <table className="w-full text-left text-sm text-dgray-6 ">
                <thead>
                    <tr>
                        <th className="py-2 font-normal">{title}</th>
                        <th className="py-2 text-right font-normal">Headcount</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(countData)
                        .slice(0, 7)
                        .map((key, index) => (
                            <tr key={index} className="">
                                <td className="py-2 flex items-center text-black font-medium text-sm">
                                    <span
                                        className="w-2 h-2 rounded-full mr-2"
                                        style={{ backgroundColor: chartColors[index] }} // Assign chart color to the dot
                                    ></span>
                                    {key}
                                </td>
                                <td className="py-2 text-right text-black">{countData[key]}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
