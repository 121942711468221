import { Slider, styled, Box } from "@mui/material";
import { formatNumberWithLetters } from "@/utils/common";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#e8e7e7 ",
    "& .MuiSlider-thumb": {
        // Make sure the thumb is above the track so the shadow is visible
        position: "relative",
        zIndex: 2, // important if shadow is getting clipped behind track

        backgroundColor: "#2D7A89",
        width: "20px",
        height: "20px",
        border: "4px solid white",
        // Combine your "ring" with a typical drop-shadow
        boxShadow: `
          0 0 0 4px #F4F3F0,         /* the ring */
          0 4px 12px rgba(0, 0, 0, 0.75) /* the drop-shadow */
        `,
    },
    ".MuiSlider-rail": {
        backgroundColor: "#F4F3F0 ",
        height: "8px",
        border: "none",
    },
    ".MuiSlider-mark": {
        display: "none",
    },
    ".MuiSlider-track": {
        backgroundColor: "#2D7A89 ",
        height: "8px",
        border: "none",
    },
}));

function IpCreditSlider({ options, state }) {
    const [priceIndex, setPriceIndex] = state;
    const mappedValues = options.map((option, index) => {
        return { value: index };
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const showPrice = (index) => {
        const value = options[index];
        return value === 0 || (value === 50000 && !fullScreen) || value === 100000 || index === priceIndex;
    };

    return (
        <div className="w-full py-7 max-w-[1096px]">
            <div className="flex w-full flex-col">
                <div className="w-full h-[36px] relative mb-3">
                    {options.map((value, index) => {
                        return (
                            <>
                                {index === priceIndex && index !== 0 && (
                                    <div
                                        className="absolute top-0 w-fit px-3 py-2 min-h-[37px] bg-[#2D7A89] rounded-[8px] flex items-center justify-center"
                                        style={{
                                            left: `${(index / (options.length - 1)) * 100}%`,
                                            transform: `translateX(${!index ? "0%" : "-50%"})`,
                                        }}
                                    >
                                        <span className="text-center text-white text-sm font-medium font-['Inter'] leading-[21px] whitespace-nowrap">
                                            {formatNumberWithLetters(Math.floor(options[index] / 30)) + " visitors"}
                                        </span>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="6"
                                            viewBox="0 0 12 6"
                                            fill="none"
                                            style={{
                                                position: "absolute",
                                                bottom: "-6px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                            }}
                                        >
                                            <path
                                                d="M5.25259 5.67752C5.65038 6.10749 6.34962 6.10749 6.74741 5.67752L12 0H0L5.25259 5.67752Z"
                                                fill="#2D7A89"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
                <StyledSlider
                    value={priceIndex}
                    step={null}
                    min={0}
                    max={options.length - 1}
                    marks={mappedValues}
                    onChange={(event, value) => {
                        setPriceIndex(value);
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        marginBottom: "25px",
                    }}
                >
                    {options.map((value, index) => {
                        return (
                            <>
                                {showPrice(index) && (
                                    <span
                                        className="absolute top-0 cursor-pointer select-none"
                                        onClick={() => setPriceIndex(index)}
                                        style={{
                                            left: `${(index / (options.length - 1)) * 100}%`,
                                            transform: `translateX(${!index ? "0%" : index === options.length - 1 ? "-100%" : "-50%"})`,
                                            display: !index || index === options.length - 1 ? "block" : "",
                                        }}
                                    >
                                        <span className=" font-Inter flex items-end gap-[2px] whitespace-nowrap ">
                                            <div className="text-center text-[#888888] text-base font-semibold font-['Inter']">
                                                {formatNumberWithLetters(value)}
                                            </div>
                                        </span>
                                    </span>
                                )}
                            </>
                        );
                    })}
                </Box>
            </div>
        </div>
    );
}

export default IpCreditSlider;
