import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { sortTableData } from "../utils";
import TranscriptsTableHeader from "./TranscriptsTableHeader";
import TranscriptsTableRow from "./TranscriptsTableRow";
import TitleIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupIcon from "@mui/icons-material/PersonOutlined";
import LanguageIcon from "@mui/icons-material/Translate";
import SourceIcon from "@mui/icons-material/SubscriptionsOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { kompassColors } from "@/theme/palette";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

const headCells = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Name",
        icon: <TitleIcon className="!h-5 !w-5" />,
    },
    {
        id: "length",
        numeric: false,
        disablePadding: false,
        label: "Length",
        icon: <AccessTimeIcon className="!h-5 !w-5" />,
    },
    {
        id: "participants",
        numeric: false,
        disablePadding: false,
        label: "Participants",
        icon: <GroupIcon className="!h-5 !w-5" />,
    },
    {
        id: "language",
        numeric: false,
        disablePadding: false,
        label: "Language",
        icon: <LanguageIcon className="!h-5 !w-5" />,
    },
    {
        id: "source",
        numeric: false,
        disablePadding: false,
        label: "Source",
        icon: <SourceIcon className="!h-5 !w-5" />,
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
        icon: <CalendarTodayIcon className="!h-4 !w-4" />,
    },
];

export default function TranscriptsTable({ data, setData, page, totalPages, onPageChange, rowsPerPage }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("title");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedData = sortTableData(data, property, isAsc);
        setData(sortedData);
    };

    const handleChangePage = (event, value) => {
        onPageChange(value);
    };

    return (
        <>
            <TableContainer sx={{ border: `1px solid ${kompassColors.light}`, borderRadius: "8px" }}>
                <Table
                    sx={{
                        minWidth: 750,
                        borderCollapse: "separate",
                        borderSpacing: "0",
                        "& .MuiTableCell-root": {
                            fontFamily: "Outfit",
                        },
                    }}
                >
                    <TranscriptsTableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                    <TableBody>
                        {data.map((row, index) => (
                            <TranscriptsTableRow key={row._id} row={row} labelId={`enhanced-table-checkbox-${index}`} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                    siblingCount={1}
                    boundaryCount={1}
                    shape="rounded"
                    renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                    sx={{
                        "& .MuiPaginationItem-root": {
                            fontFamily: "Inter, sans-serif",
                            color: "#050505",
                            border: "1px solid #e7e7e7",
                            borderRadius: "4px",
                        },
                        "& .MuiPaginationItem-root.Mui-selected": {
                            backgroundColor: "#2D7A89",
                            color: "#fff",
                            borderRadius: "4px",
                        },
                    }}
                />
            </Box>
        </>
    );
}
