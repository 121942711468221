import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import SortIcon from "@/components/icons/SortIcon";
import ShortTextIcon from "@mui/icons-material/ShortText";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Company Name",
        icon: <ShortTextIcon className="!h-5 !w-5" />,
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        icon: <CategoryOutlinedIcon className="!h-4 !w-4" />,
    },
    {
        id: "industry",
        numeric: false,
        disablePadding: false,
        label: "Industry",
        icon: <CorporateFareOutlinedIcon className="!h-4 !w-4" />,
    },
    {
        id: "country",
        numeric: false,
        disablePadding: false,
        label: "Country",
        icon: <PublicOutlinedIcon className="!h-4 !w-4" />,
    },
];

function AccountsTableHeader(props) {
    const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "12px 16px",
                    fontFamily: "Inter",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        // Exclude last cell
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.black,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.black,
                                fill: kompassColors.black,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            width: "25%",
                        }}
                    >
                        {headCell.label && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    color: "#6D6D6D",
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {headCell.icon}
                                    {headCell.label}
                                </div>
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

AccountsTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default AccountsTableHeader;
