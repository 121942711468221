import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as MailIcon } from "@/assets/image/ai_engagement/mail.svg";
import { ReactComponent as MailWaitIcon } from "@/assets/image/ai_engagement/hourglass.svg";
import { ReactComponent as DeleteIcon } from "@/assets/image/ai_engagement/delete.svg";
import { ReactComponent as AddIcon } from "@/assets/image/ai_engagement/add-new.svg";
import { ReactComponent as ChartIcon } from "@/assets/image/ai_engagement/chart.svg";
import { ReactComponent as LinkedInIconBlue } from "@/assets/image/ai_engagement/linkedin-icon-blue.svg";
import { ReactComponent as LinkedInIconBlack } from "@/assets/image/ai_engagement/linkedin-icon-black.svg";
import { ReactComponent as EmailIcon } from "@/assets/image/ai_engagement/mark_email_unread.svg";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/Done";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress, Switch, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { truncateText } from "@/utils/common";

export default function CampaignSequence({ setSelectedStep, campaignId, selectedStep }) {
    const [sequenceData, setSequenceData] = useState([]);
    const [activeSequence, setActiveSequence] = useState(null);
    const [sampleMail, setSampleMail] = useState(null);
    const [loading, setLoading] = useState({
        data: false,
        sampleMail: false,
        deleting: false,
        savingSequenceData: false,
        savingStep: false,
        addingSequence: false,
    });
    const [sequenceListHeight, setSequenceListHeight] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [newDaysBetween, setNewDaysBetween] = useState(0);
    const [aiTopic, setAiTopic] = useState("");
    const [isAutomatic, setIsAutomatic] = useState(true);
    const [isAiTopicEditable, setIsAiTopicEditable] = useState(false);
    const [allLinkedinAccounts, setAllLinkedinAccounts] = useState([]);

    const { getAiSequences, getSampleMail, deleteSequence, addSequence, updateAllSequenceSteps, updateSingleStep, getUnipileAuthData } =
        useAIEngagement();

    const sequenceListRef = useRef(null);
    const getUnipileAuthDataRef = useRef();
    getUnipileAuthDataRef.current = getUnipileAuthData;

    useEffect(() => {
        const fetchActiveLinkedinAccounts = async () => {
            const { data: unipileData } = await getUnipileAuthDataRef.current();

            if (unipileData && unipileData?.accounts?.length > 0) {
                setAllLinkedinAccounts(unipileData?.accounts?.filter((i) => i.provider === "LINKEDIN" && i.isActive));
            } else {
                setAllLinkedinAccounts([]);
            }
        };

        fetchActiveLinkedinAccounts();
    }, []);

    const getAiSequencesRef = useRef();
    getAiSequencesRef.current = getAiSequences;
    const getSampleMailRef = useRef();
    getSampleMailRef.current = getSampleMail;

    useEffect(() => {
        const fetchSequenceData = async () => {
            setLoading((prev) => ({ ...prev, data: true }));
            const response = await getAiSequencesRef.current(campaignId);
            if (response.status && response.data.length > 0) {
                setSequenceData(response.data?.sort((a, b) => a.sequenceNumber - b.sequenceNumber));
                setActiveSequence(response.data[0]);
            }
            setLoading((prev) => ({ ...prev, data: false }));
        };
        // fetch sample mail for first sequence step
        const fetchSampleMail = async () => {
            setLoading((prev) => ({ ...prev, sampleMail: true }));
            const response = await getSampleMailRef.current(campaignId, 0);
            if (response.status) {
                setSampleMail(response.data);
            }
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        };
        if (selectedStep === "3. Sequence") {
            fetchSequenceData();
            fetchSampleMail();
        }
    }, [selectedStep, campaignId]);

    // for setting the height of line between icons
    useEffect(() => {
        if (sequenceListRef.current) {
            setSequenceListHeight(sequenceListRef.current.clientHeight);
        }
    }, [sequenceData.length]);

    const handleNewSampleMail = async (signal) => {
        if (!activeSequence) return;
        setLoading((prev) => ({ ...prev, sampleMail: true }));
        const response = await getSampleMail(campaignId, activeSequence.sequenceNumber, signal);
        if (response.status) {
            setSampleMail(response.data);
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        } else if (response.aborted) {
            setLoading((prev) => ({ ...prev, sampleMail: true }));
        } else {
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        }
    };

    const handleNewSampleMailRef = useRef();
    handleNewSampleMailRef.current = handleNewSampleMail;

    // handle toggling for active sequence change
    useEffect(() => {
        if (activeSequence) {
            setIsAutomatic(activeSequence.templateType === "ai_topic");
            setAiTopic(activeSequence.topic || "");
            setIsAiTopicEditable(false);

            const controller = new AbortController();
            const signal = controller.signal;

            handleNewSampleMailRef.current(signal);

            return () => controller.abort();
        } else {
            setAiTopic("");
        }
    }, [activeSequence]);

    // handle delete sequence
    const handleDeleteSequence = async (sequenceId) => {
        const deletePromise = deleteSequence(sequenceId);
        setLoading({ ...loading, deleting: true });
        toast
            .promise(deletePromise, {
                pending: "Deleting sequence...",
                success: "Sequence deleted successfully!",
                error: "Failed to delete sequence.",
            })
            .then((response) => {
                if (response.status) {
                    const newSequenceData = sequenceData.filter((sequence) => sequence._id !== sequenceId);
                    setSequenceData(newSequenceData);

                    // check if last sequence is deleted
                    if (newSequenceData.length === 0) {
                        setActiveSequence(null);
                        setSampleMail(null);
                    } else {
                        // check if active sequence is deleted
                        if (activeSequence._id === sequenceId) {
                            setActiveSequence(newSequenceData[0]);
                        }
                    }
                }
            });
        setLoading({ ...loading, deleting: false });
    };

    // handle add sequence
    const handleAddSequence = async (source) => {
        setLoading((prev) => ({ ...prev, addingSequence: true }));

        let channel;
        if (source === "email") {
            channel = "email";
        } else {
            const isAnyPaidLinkedinAccount = allLinkedinAccounts?.some(
                (account) => account.accountType === "recruiter" || account.accountType === "sales_navigator" || account.isPremium
            );
            if (isAnyPaidLinkedinAccount) {
                channel = "inmail";
            } else {
                channel = "linkedin_connection";
            }
        }

        const maxDaysBetween = sequenceData.length > 0 ? Math.max(...sequenceData.map((sequence) => sequence.daysBetween)) + 3 : 0;
        const payload = {
            campaignId: campaignId,
            sequenceNumber: sequenceData.length,
            daysBetween: maxDaysBetween,
            templateType: "ai_topic",
            channel,
        };
        const addPromise = addSequence(payload);
        toast
            .promise(addPromise, {
                pending: "Adding sequence...",
                success: "Sequence added successfully!",
                error: "Failed to add sequence.",
            })
            .then((response) => {
                if (response.status) {
                    setSequenceData([...sequenceData, response.data]);
                    setActiveSequence(response.data);
                }
            })
            .finally(() => {
                setLoading((prev) => ({ ...prev, addingSequence: false }));
            });
    };

    // handle click on step
    const handleStepClick = (sequence) => {
        if (activeSequence._id !== sequence._id) {
            // check if ai topic is changed
            const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
            if (activeStep.topic !== activeSequence.topic) {
                updateSingleSequenceStep(sequenceData);
            }
            setActiveSequence(sequence);
        }
    };

    // handle switch for automatic and manual mode
    const handleSwitch = (e) => {
        const newIsAutomatic = e.target.checked;
        setIsAutomatic(newIsAutomatic);
        const updatedSequenceData = sequenceData.map((sequence) => {
            if (sequence._id === activeSequence._id) {
                if (newIsAutomatic) {
                    return {
                        ...sequence,
                        templateType: "ai_topic",
                    };
                }
                if (!sequence.template) {
                    return {
                        ...sequence,
                        templateType: "template",
                        template: {
                            subject: "",
                            body: "",
                        },
                    };
                }
                return {
                    ...sequence,
                    templateType: "template",
                };
            }
            return sequence;
        });
        setSequenceData(updatedSequenceData);
        if (newIsAutomatic) {
            setIsAiTopicEditable(false);
        } else {
            const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
            if (activeStep.topic === activeSequence.topic && activeStep?.template?.subject === activeSequence?.template?.subject) return;
            updateSingleSequenceStep(updatedSequenceData);
        }
    };

    // handle onChange for subject and body inputs for manual mode
    const handleTemplateDataChange = (e, field) => {
        if (activeSequence) {
            const updatedSequenceData = sequenceData.map((sequence) => {
                if (sequence._id === activeSequence._id) {
                    const updatedSequence = { ...sequence };
                    if (!updatedSequence.template) {
                        updatedSequence.template = {};
                    }
                    if (field === "subject") {
                        updatedSequence.template.subject = e.target.value;
                    } else {
                        updatedSequence.template.body = e.target.value;
                    }
                }
                return sequence;
            });
            setSequenceData(updatedSequenceData);
            // setActiveSequence(updatedSequenceData.find(sequence => sequence._id === activeSequence._id));
        }
    };

    // handle edit days
    const handleEditDays = (index) => {
        const updatedSequenceData = [...sequenceData];
        const newValue = Math.min(Math.max(parseInt(newDaysBetween, 10) || 0, 0), 120);

        updatedSequenceData[index] = {
            ...updatedSequenceData[index],
            daysBetween: newValue,
        };

        const sortedData = updatedSequenceData.sort((a, b) => a.daysBetween - b.daysBetween);

        sortedData.forEach((sequence, idx) => {
            sequence.sequenceNumber = idx;
        });

        setSequenceData(sortedData);
        setEditIndex(null);
    };

    // save all sequence steps and go to next stage
    const handleSaveAllSequenceSteps = async () => {
        if (sequenceData.length === 0) {
            toast.error("Please add at least one sequence step.");
            return;
        }
        setLoading({ ...loading, savingSequenceData: true });
        const response = await updateAllSequenceSteps(sequenceData);
        if (response.status) {
            toast.success("All sequence steps saved successfully!");
            setSelectedStep("4. Schedule");
        } else {
            toast.error("Failed to save sequence steps.");
        }
        setLoading({ ...loading, savingSequenceData: false });
    };

    const updateSingleSequenceStep = async (sequenceData) => {
        const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
        const response = await updateSingleStep(activeStep);
        if (response.status) {
            const title = response.title;
            const updatedSequenceData = sequenceData.map((sequence) => {
                if (sequence._id === activeSequence._id) {
                    return {
                        ...sequence,
                        title,
                    };
                }
                return sequence;
            });
            setSequenceData(updatedSequenceData);
        } else {
            console.log("Failed to update sequence step");
        }
    };

    const handleAiTopicSave = async () => {
        setIsAiTopicEditable(false);
        const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
        if (activeStep.topic === activeSequence.topic) return;
        setLoading({ ...loading, savingStep: true });
        await updateSingleSequenceStep(sequenceData);
        setLoading({ ...loading, savingStep: false });
        handleNewSampleMail();
    };

    if (loading.data) {
        return (
            <div className="flex justify-center items-center h-[400px]">
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col md:flex-row gap-5 font-Inter">
                {/* left side */}
                <div className="w-full md:w-[35%] min-w-[300px] relative h-full max-h-[500px] overflow-y-auto custom-scrollbar-section pr-5">
                    {/* Vertical Line */}
                    <div
                        className={`absolute left-[17px] top-9 border-l-2 border-stone-200`}
                        style={{ height: sequenceListHeight + 5 }}
                    ></div>
                    {sequenceData.length > 0 &&
                        sequenceData.map((sequence, index) => (
                            <div ref={sequenceListRef} className="mb-10 ">
                                <div className={`flex items-center mt-10 relative z-10 ${index === 0 && "!mt-0"}`}>
                                    <div className="flex items-center justify-center p-[6px] h-[36px] w-[36px] bg-[#E7E7E7] rounded-full">
                                        <MailWaitIcon />
                                    </div>
                                    {editIndex === index ? (
                                        <input
                                            type="number"
                                            value={newDaysBetween}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                // Ensure value does not exceed 120
                                                if (value > 120) value = 120;
                                                setNewDaysBetween(value);
                                            }}
                                            onBlur={() => handleEditDays(index)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleEditDays(index);
                                                }
                                                if (e.key === "-" || e.key === "e" || e.key === "," || e.key === ".") {
                                                    e.preventDefault(); // Prevent negative or scientific notation
                                                }
                                            }}
                                            className="ml-10 p-2 pl-4 bg-transparent outline-none no-spinner-input rounded-[8px] border border-[#E7E7E7] min-w-56"
                                            autoFocus
                                        />
                                    ) : (
                                        <p className="ml-10 p-2 pl-4 flex items-center rounded-[8px] border border-[#E7E7E7] min-w-56">
                                            <span className="main-text-black-500 leading-[21px]">
                                                Wait for {sequence.daysBetween} days then{" "}
                                            </span>
                                            <ModeEditOutlinedIcon
                                                className="ml-auto cursor-pointer hover:scale-110 transition-all !h-[16px] !w-[16px]"
                                                onClick={() => {
                                                    if (!loading.deleting) {
                                                        setEditIndex(index);
                                                        setNewDaysBetween(sequenceData[index].daysBetween);
                                                    }
                                                }}
                                            />
                                        </p>
                                    )}
                                </div>

                                <div className="flex items-center mt-10 relative z-10">
                                    <div className="flex items-center justify-center p-[6px] h-[36px] w-[36px] bg-[#FDF197] rounded-full">
                                        {!sequence?.channel || sequence?.channel === "email" ? <MailIcon /> : <LinkedInIconBlack />}
                                    </div>
                                    <p className="main-text-black-500 ml-10">
                                        {!sequence?.channel || sequence?.channel === "email" ? "Email follow up" : "LinkedIn follow up"}
                                    </p>
                                </div>

                                <div
                                    onClick={() => handleStepClick(sequence)}
                                    className={`ml-[68px] p-5 mt-3 cursor-pointer border ${activeSequence?._id === sequence._id ? "border-[#2D7A89]" : "border-[#E7E7E7]"} rounded-[8px]`}
                                >
                                    <div className="flex items-center">
                                        <p className="main-text-black-500">{sequence?.channel === "email" ? "Email" : "Message"}</p>
                                        <DeleteIcon
                                            className="ml-auto cursor-pointer hover:scale-110 transition-all"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteSequence(sequence._id);
                                            }}
                                        />
                                    </div>
                                    {(sequence?.title || sequence?.template?.subject) && (
                                        <p className="mt-[10px] main-text-black-500">
                                            {sequence.templateType === "ai_topic"
                                                ? truncateText(sequence?.title)
                                                : truncateText(sequence?.template?.subject)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ))}
                    <div className="flex items-start relative z-10">
                        <div className="flex items-center justify-center cursor-pointer p-[9px] h-[36px] w-[36px] bg-[#2D7A89] rounded-full">
                            <AddIcon />
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center ml-10 h-10">
                                <p className="main-text-black-500">Add Step</p>
                            </div>
                            <div className="flex gap-4 ml-10 items-center">
                                <button
                                    disabled={loading.addingSequence}
                                    onClick={() => handleAddSequence("email")}
                                    className="px-[24px] py-[12px] flex gap-2 items-center rounded-[8px] border border-[#E7E7E7] hover:bg-[#F4F3F0] transition-all"
                                >
                                    <EmailIcon />
                                    <span className="main-text-black-500">Email</span>
                                </button>
                                {allLinkedinAccounts?.length > 0 && (
                                    <button
                                        disabled={loading.addingSequence}
                                        onClick={() => handleAddSequence("linkedin")}
                                        className="px-[24px] py-[12px] flex gap-2 items-center rounded-[8px] border border-[#E7E7E7] hover:bg-[#F4F3F0] transition-all"
                                    >
                                        <LinkedInIconBlue />
                                        <span className="main-text-black-500">LinkedIn</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* right side */}
                {activeSequence && (
                    <div className="w-full md:w-[65%]">
                        <div className="flex items-center mb-[24px]">
                            <p className="main-text-black-500">{`Stage ${activeSequence?.sequenceNumber + 1}: ${!activeSequence?.channel || activeSequence?.channel === "email" ? "Email" : "LinkedIn"}`}</p>
                            <div className="ml-auto flex gap-1 items-center">
                                <Switch
                                    sx={{
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                            color: "white", // Thumb color when checked
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            opacity: 100,
                                            backgroundColor: "#2D7A89", // Background when checked
                                        },
                                    }}
                                    checked={isAutomatic}
                                    onChange={(e) => handleSwitch(e)}
                                />{" "}
                                <p className="main-text-black-500"> Automatic </p>{" "}
                            </div>
                        </div>

                        {isAutomatic && (
                            <div className="flex w-full relative mb-[24px]">
                                <div className="flex items-center bg-[#F6F6F6] rounded-[8px] mr-2 px-[8px]">
                                    <p className="whitespace-nowrap text-[14px] font-Inter font-semibold text-[#2D7A89]">AI Topic:</p>
                                </div>
                                {!isAutomatic || sequenceData.length === 0 || !isAiTopicEditable ? (
                                    <div className="box-border w-full border border-[#E8E7E7] h-[80px] overflow-y-auto p-[16px] rounded-[8px] pr-10">
                                        <p className="m-0 main-text-black-500 leading-[21px]">
                                            {aiTopic || "Please enter your topic here"}
                                        </p>
                                    </div>
                                ) : (
                                    <textarea
                                        rows="3"
                                        onChange={(e) => {
                                            setAiTopic(e.target.value);
                                            if (activeSequence) {
                                                setSequenceData(
                                                    sequenceData.map((sequence) => {
                                                        if (sequence._id === activeSequence._id) {
                                                            return { ...sequence, topic: e.target.value };
                                                        }
                                                        return sequence;
                                                    })
                                                );
                                            }
                                        }}
                                        value={aiTopic}
                                        disabled={!isAutomatic || sequenceData.length === 0 || !isAiTopicEditable}
                                        className="campaign-card-input main-text-black-500 leading-[21px] !bg-transparent !p-4 !rounded-[8px] !w-full resize-none !pr-10"
                                    ></textarea>
                                )}

                                {isAutomatic && (
                                    <div className="absolute top-1.5 right-2">
                                        {loading.savingStep ? (
                                            <CircularProgress className="text-black !h-[18px] !w-[18px]" />
                                        ) : isAiTopicEditable ? (
                                            <CheckCircleOutlineIcon
                                                onClick={handleAiTopicSave}
                                                className="text-black !h-[18px] !w-[18px] cursor-pointer"
                                            />
                                        ) : (
                                            <ModeEditOutlinedIcon
                                                onClick={() => setIsAiTopicEditable(true)}
                                                className="text-black !h-[18px] !w-[18px] cursor-pointer"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="border border-[#E7E7E7] rounded-[8px]">
                            <div className="px-[24px] py-[16px] bg-[#F6F6F6] flex items-center rounded-t-[8px]">
                                <span className="main-text-black-600 mr-2">Subject:</span>
                                <input
                                    readOnly={isAutomatic || sequenceData.length === 0}
                                    onChange={(e) => handleTemplateDataChange(e, "subject")}
                                    type="text"
                                    value={
                                        isAutomatic
                                            ? sampleMail?.subject || ""
                                            : sequenceData.find((seq) => seq._id === activeSequence._id)?.template?.subject
                                    }
                                    className="border-none bg-transparent outline-none w-full main-text-black-500"
                                />
                                {!isAutomatic && (
                                    <Tooltip
                                        title={
                                            <>
                                                Supported variables:
                                                <br />
                                                {`{contact_name}`}
                                                <br />
                                                {`{contact_company}`}
                                                <br />
                                                {`{contact_job_title}`}
                                                <br />
                                                {`{contact_industry}`}
                                            </>
                                        }
                                        placement="bottom"
                                    >
                                        <span className="main-text-black-500 !text-[#2D7A89] ml-2">{"Variables"}</span>
                                    </Tooltip>
                                )}
                            </div>
                            <textarea
                                readOnly={isAutomatic || sequenceData.length === 0}
                                onChange={(e) => handleTemplateDataChange(e, "body")}
                                value={
                                    isAutomatic
                                        ? sampleMail?.body || ""
                                        : sequenceData.find((seq) => seq._id === activeSequence._id)?.template?.body
                                }
                                rows="12"
                                className="px-[24px] py-[16px] w-full h-full outline-none resize-none main-text-black-500 leading-[21px]"
                            ></textarea>
                        </div>

                        <div className="mt-[24px] flex flex-col sm:flex-row items-start sm:items-center">
                            {isAutomatic && (
                                <button
                                    onClick={() => handleNewSampleMail()}
                                    disabled={loading.sampleMail}
                                    className="flex items-center gap-3 border border-[#E7E7E7] px-[16px] h-[36px] rounded-[8px] text-[14px] text-[#2D7A89] font-Inter font-medium hover:bg-dgray-50 transition-all"
                                >
                                    {loading.sampleMail ? (
                                        <>
                                            <CircularProgress size={14} /> <span> Refreshing... </span>
                                        </>
                                    ) : (
                                        <>
                                            <ChartIcon /> <span> Refresh Sample Contact with AI </span>
                                        </>
                                    )}
                                </button>
                            )}
                            <button
                                disabled={loading.savingSequenceData}
                                onClick={handleSaveAllSequenceSteps}
                                className="sm:ml-auto sm:mt-0 mt-5 font-Inter flex h-[34px] px-[24px] items-center justify-center rounded-[8px] bg-accents-mainBlack capitalize text-white font-medium text-[14px] hover:opacity-80 transition-all"
                            >
                                {loading.savingSequenceData ? (
                                    <div className="flex items-center gap-3">
                                        <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Saving...</span>
                                    </div>
                                ) : (
                                    <span className="whitespace-nowrap">Next</span>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
