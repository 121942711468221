import { ReactComponent as CustomCheckBox } from "@/assets/image/icons/checkbox_free.svg";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import cn from "classnames";
import { Button } from "@material-tailwind/react";
import Text from "@/components/Text";
import PlanDetails from "./PlanDetails";

export default function PricingComponent(props) {
    const { tiers, isCurrent, product, annual, expandState, handleSelection, isPricingPage } = props;
    const theme = tiers[product.key].theme;
    const promo = product.key === "premium";
    const isFree = product.key === "free";
    const [showBenefits, setShowBenefits] = expandState;
    const tierIndex = Object.keys(tiers).indexOf(product.key);
    const prevTier = tierIndex === 0 ? null : Object.keys(tiers)[tierIndex - 1];
    const features = tierIndex ? product.additionalFeatures : product.features;
    const price_obj =
        product.variations.length > 1 ? product.variations.find((x) => x.interval === (annual ? "year" : "month")) : product.variations[0];

    const handleSelectPlan = () => {
        handleSelection(product, price_obj);
    };

    // Note: The comparing feature is hidden for now
    return (
        <div className={`flex h-full w-full flex-col rounded-lg justify-between`}>
            {promo ? (
                <>
                    <div
                        className={`w-full ${theme.main} ${theme.border} justify-center rounded-lg rounded-bl-none rounded-br-none text-black`}
                    >
                        <Text variant="Caption" className={`flex p-5 text-lg uppercase text-black drop-shadow-lg`}>
                            Most popular
                        </Text>
                    </div>
                    <div className={`${theme.border} ${theme.main} border rounded-b-lg h-full`}>
                        <div className={`p-6 w-full h-full flex flex-col bg-white rounded-lg `}>
                            <div
                                className={`relative flex w-full flex-col rounded-lg rounded-bl-none rounded-br-none ${theme.text} !bg-white`}
                            >
                                <h2 className="font-InterBold text-2xl">{product.name === "Professional" ? "Starter" : product.name}</h2>
                                <Text variant="CaptionRegular" className="text-sm leading-[1.4] mt-4 text-dgray-6 h-[32px]">
                                    {tiers[product.key].slogan}
                                </Text>
                                <div className="mt-10 mb-4 flex gap-2">
                                    <span className="font-InterSemiBold text-5xl leading-[3.45rem] tracking-normal text-primary-black">
                                        $
                                        {product.key === "free"
                                            ? product.variations[0].price / 100
                                            : annual
                                              ? price_obj.price / 1200
                                              : price_obj.price / 100}
                                    </span>
                                    <div className="mb-2 flex items-end text-base text-[#090C05]">
                                        <span className="font-InterBold">/month</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <PlanDetails stripePrice={price_obj} annual={annual} />
                                <Button
                                    onClick={handleSelectPlan}
                                    disabled={!isPricingPage && isFree}
                                    className={cn(
                                        "text-base leading-[1.2] font-Inter mt-12 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 px-6 py-4 font-bold capitalize text-white transition-all hover:opacity-80"
                                    )}
                                >
                                    {isPricingPage ? "Get started" : isFree ? "Current plan" : isCurrent ? "Customize plan" : "Get started"}
                                </Button>
                                {/* <div className={`mt-12 flex-col overflow-hidden transition-[height] ${showBenefits ? "flex" : "hidden"}`}>
                                    {prevTier && (
                                        <div className="font-InterBold mb-4 text-base text-stone-950">{`Everything in ${
                                            prevTier.charAt(0).toLocaleUpperCase() + prevTier.slice(1)
                                        }, plus`}</div>
                                    )}
                                    {features.map((benefit) => (
                                        <div key={benefit} className="flex items-center mb-3">
                                            <CustomCheckBox className={`w-[22px] h-[22px] fill-zinc-100 stroke-stone-950 shrink-0`} />
                                            <div className="font-InterLight ml-2 text-base leading-md text-stone-950">{benefit}</div>
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                            {/* <div className="flex w-full mt-auto">
                                <div
                                    onClick={() => setShowBenefits(!showBenefits)}
                                    className="flex cursor-pointer select-none mt-10 group "
                                >
                                    <div className="font-Inter leading-[1.35rem] group-hover:scale-[1.01] ">Show plan comparison</div>
                                    <div className="ml-4 flex items-center transition-all group-hover:rotate-180">
                                        <DownSVGIcon />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </>
            ) : (
                <div className={`border-[#E7E7E7] rounded-lg border bg-white lg:mt-[53px] 2xl:mt-[60px] h-full`}>
                    <div className={`p-6 w-full h-full flex flex-col `}>
                        <div className={`relative flex w-full flex-col rounded-lg rounded-bl-none rounded-br-none ${theme.text} !bg-white`}>
                            <h2 className="font-InterBold text-2xl">{product.name === "Professional" ? "Starter" : product.name}</h2>
                            <Text variant="CaptionRegular" className="text-sm leading-[1.4] text-dgray-6 mt-4 h-[32px]">
                                {tiers[product.key].slogan}
                            </Text>
                            <div className="mt-10 mb-4 flex gap-2">
                                <span className="font-InterSemiBold text-5xl leading-[3.45rem] tracking-normal text-primary-black">
                                    $
                                    {product.key === "free"
                                        ? product.variations[0].price / 100
                                        : annual
                                          ? price_obj.price / 1200
                                          : price_obj.price / 100}
                                </span>
                                <div className="mb-2 flex items-end text-base text-[#090C05]">
                                    <span className="font-InterBold">/month</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <PlanDetails stripePrice={price_obj} annual={annual} />
                            <Button
                                onClick={handleSelectPlan}
                                disabled={!isPricingPage && isFree}
                                className={cn(
                                    "text-base leading-[1.2] font-Inter mt-12 flex w-full items-center justify-center rounded-lg border-1 border-stone-950 bg-stone-950 px-6 py-4 font-bold capitalize text-white transition-all hover:opacity-80"
                                )}
                            >
                                {isPricingPage ? "Get started" : isFree ? "Current plan" : isCurrent ? "Customize plan" : "Get started"}
                            </Button>
                            {/* <div className={`mt-12 flex-col overflow-hidden transition-[height] ${showBenefits ? "flex" : "hidden"}`}>
                                {prevTier && (
                                    <div className="font-InterBold mb-4 text-base text-stone-950">{`Everything in ${
                                        prevTier.charAt(0).toLocaleUpperCase() + prevTier.slice(1)
                                    }, plus`}</div>
                                )}
                                {features.map((benefit) => (
                                    <div key={benefit} className="flex items-center mb-3">
                                        <CustomCheckBox className={`w-[22px] h-[22px] fill-zinc-100 stroke-stone-950 shrink-0`} />
                                        <div className="font-InterLight ml-2 text-base leading-md text-stone-950">{benefit}</div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                        {/* <div className="flex w-full mt-auto">
                            <div onClick={() => setShowBenefits(!showBenefits)} className="flex cursor-pointer select-none mt-10 group ">
                                <div className="font-Inter leading-[1.35rem] group-hover:scale-[1.01] ">Show plan comparison</div>
                                <div className="ml-4 flex items-center transition-all group-hover:rotate-180">
                                    <DownSVGIcon />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
}
