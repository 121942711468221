import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useNotes from "@/hooks/crm/useNotes";

export default function CreateNoteModal({ open, setOpen, setRefreshUI }) {
    const [noteTitle, setNoteTitle] = useState("");
    const [noteDescription, setNoteDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const { opportunityId } = useParams();

    const { createTimelineNote } = useNotes();

    const handleCreateNote = async () => {
        if (!noteTitle) {
            toast.error("Task Title is required");
            return;
        }
        setLoading(true);
        try {
            const response = await createTimelineNote({
                opportunity: opportunityId,
                title: noteTitle,
                ...(noteDescription && { content: noteDescription }),
            });
            if (response) {
                toast.success("Task created successfully");
                setRefreshUI((prev) => !prev);
                setOpen(false);
                // reset
                setNoteTitle("");
                setNoteDescription("");
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to create task");
        }
        setLoading(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs" scroll="body">
                <DialogTitle sx={{ m: 0, p: 2 }} className="!text-2xl !font-semibold" id="customized-dialog-title">
                    Create Note
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="!p-0">
                    <div className="flex flex-col my-2 mb-10 p-5">
                        <p className=" text-dgray-6 text-sm">Enter Note details</p>
                        <h2 className="font-semibold mt-5">Title</h2>
                        <input
                            type="text"
                            value={noteTitle}
                            onChange={(e) => setNoteTitle(e.target.value)}
                            className="py-2 px-4 rounded-lg border mt-1 w-full border-stone-250"
                            onKeyDown={(e) => e.key === "Enter" && handleCreateNote()}
                        />
                        <h2 className="font-semibold mt-5">Description</h2>
                        <input
                            type="text"
                            value={noteDescription}
                            onChange={(e) => setNoteDescription(e.target.value)}
                            className="py-2 px-4 rounded-lg border mt-1 w-full border-stone-250"
                            onKeyDown={(e) => e.key === "Enter" && handleCreateNote()}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="flex items-center gap-5">
                        <button
                            onClick={() => setOpen(false)}
                            className="border border-stone-250 rounded-lg py-2 px-4 bg-dgray-200 hover:opacity-90"
                        >
                            Close
                        </button>
                        <button
                            onClick={handleCreateNote}
                            disabled={loading}
                            className="border flex gap-2 items-center border-black rounded-lg py-2 px-4 bg-black text-white hover:opacity-90 disabled:opacity-50"
                        >
                            {loading && <CircularProgress size={14} color="inherit" />}
                            Save
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
