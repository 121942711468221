import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import DatePickerPopover from "./DatePickerPopover";
import AssigneePopover from "./AssigneePopover";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ChecklistIcon from "@mui/icons-material/Checklist";
import useTasks from "@/hooks/crm/useTasks";
import { toast } from "react-toastify";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CircularProgress } from "@mui/material";
import { ReactComponent as AddIcon } from "@/assets/image/crm/opportunities/addIcon.svg";

export default function AddNewPopup({ setRefreshUI }) {
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [dueDate, setDueDate] = useState(null);
    const [status, setStatus] = useState(null); // State for selected status
    const [showStatusOptions, setShowStatusOptions] = useState(false); // State for showing status options
    const [assignedTo, setAssignedTo] = useState(null);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);

    const { createTask } = useTasks();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleStatusClick = (newStatus) => {
        setStatus(newStatus);
        setShowStatusOptions(false); // Close the status options
    };

    const handleSave = async () => {
        try {
            if (!title) {
                toast.error("Title is required");
                return;
            }
            if (dueDate && new Date(dueDate) < new Date()) {
                toast.error("Due Date should be greater than today's date");
                return;
            }
            const taskData = {
                title,
                ...(status && { status }),
                ...(dueDate && { dueDate }),
                ...(assignedTo && { assignedTo: assignedTo?.id }),
            };
            setLoading(true);
            const response = await createTask(taskData);
            if (response) {
                setRefreshUI((pre) => !pre);
                setOpen(false);
                // reset all the states
                setTitle("");
                setStatus(null);
                setDueDate(null);
                setAssignedTo(null);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const statusIcons = {
        "To Do": <FormatListBulletedIcon className="!w-4" />,
        "In Progress": <ChecklistIcon className="!w-4" />,
        Done: <CheckCircleOutlineOutlinedIcon className="!w-4" />,
    };

    const handleCancel = () => {
        setOpen(false);
        setTitle("");
        setStatus(null);
        setDueDate(null);
        setAssignedTo(null);
    };

    return (
        <Stack direction="row" spacing={2}>
            <div>
                <button
                    className="py-1.5 px-3 font-Inter text-sm font-medium flex gap-2 items-center rounded-lg border border-[#e7e7e7] hover:bg-primary-header"
                    ref={anchorRef}
                    id="composition-button"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <AddIcon />
                    Add New
                </button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                            }}
                        >
                            <Paper sx={{ boxShadow: "0px 4px 34px 0px #00000029", borderRadius: "8px" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div>
                                        <div className="p-5 pb-0 z-50">
                                            <input
                                                type="text"
                                                className="p-2 px-4 w-full border border-[#e7e7e7] rounded-lg placeholder:text-dgray-6 placeholder:text-sm"
                                                placeholder="Enter title here"
                                                value={title}
                                                onChange={(e) => {
                                                    if (e.target.value.length >= 101) {
                                                        return;
                                                    } else {
                                                        setTitle(e.target.value);
                                                    }
                                                }}
                                                onKeyDown={(e) => e.key === "Enter" && handleSave()}
                                            />
                                            {title && title.length >= 100 && (
                                                <p className="text-xs mt-1 text-red-500">Maximum 100 characters are allowed.</p>
                                            )}
                                            <div className="flex items-center gap-2 mt-5">
                                                <div>
                                                    {status ? (
                                                        <div
                                                            className={`py-1 h-[33px] px-8 text-sm font-medium flex gap-1 cursor-pointer items-center rounded-lg border ${
                                                                status === "To Do"
                                                                    ? "text-[#2E3DAA] bg-[#ECEEFF] border-[#2E3DAA]"
                                                                    : status === "In Progress"
                                                                      ? "text-[#FF782C] bg-[#FFF6E5] border-[#FF782C]"
                                                                      : "text-[#2D7A89] bg-[#E1ECEE] border-[#2D7A89]"
                                                            }`}
                                                            onClick={() => setShowStatusOptions(true)}
                                                        >
                                                            {statusIcons[status]}
                                                            {status}
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className="py-1 h-[33px] px-8 text-sm font-medium flex gap-2 items-center rounded-lg border border-[#e7e7e7] hover:bg-primary-header"
                                                            onClick={() => setShowStatusOptions(true)}
                                                        >
                                                            <AddIcon />
                                                            Status
                                                        </button>
                                                    )}
                                                </div>
                                                <DatePickerPopover
                                                    buttonLabel="Due Date"
                                                    selectedDate={dueDate}
                                                    onDateChange={(date) => setDueDate(date)}
                                                />
                                                <AssigneePopover selectedAssignee={assignedTo} setSelectedAssignee={setAssignedTo} />
                                            </div>
                                        </div>
                                        {showStatusOptions && (
                                            <div className="mt-3 px-5 flex gap-2">
                                                <div
                                                    onClick={() => handleStatusClick("To Do")}
                                                    className="text-[#2E3DAA] bg-[#ECEEFF] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#2E3DAA] "
                                                >
                                                    <FormatListBulletedIcon className="!w-4" />
                                                    To Do
                                                </div>
                                                <div
                                                    onClick={() => handleStatusClick("In Progress")}
                                                    className="text-[#FF782C] bg-[#FFF6E5] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#FF782C]"
                                                >
                                                    <ChecklistIcon className="!w-4" />
                                                    In Progress
                                                </div>
                                                <div
                                                    onClick={() => handleStatusClick("Done")}
                                                    className="text-[#2D7A89] bg-[#E1ECEE] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#2D7A89]"
                                                >
                                                    <CheckCircleOutlineOutlinedIcon className="!w-4" />
                                                    Done
                                                </div>
                                            </div>
                                        )}
                                        <hr className="w-full border-t-2 my-5 border-stone-250" />
                                        <div className="flex gap-2.5 justify-end px-5 pb-5 text-sm">
                                            <button
                                                onClick={handleCancel}
                                                className="border border-[#e7e7e7] font-medium px-3 py-1.5 rounded-lg hover:bg-primary-header"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleSave}
                                                disabled={loading}
                                                className="bg-primary-black flex items-center hover:opacity-80 disabled:opacity-50 gap-2 text-white px-5 font-medium py-1.5 rounded-lg"
                                            >
                                                {loading && <CircularProgress size={15} color="inherit" />}
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </Stack>
    );
}
