import React, { useState } from "react";
import { Popper, Grow, Paper, ClickAwayListener } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { ReactComponent as AddIcon } from "@/assets/image/crm/opportunities/addIcon.svg";

const DatePickerPopover = ({ buttonLabel = "Select Date", selectedDate, onDateChange }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCancel = () => {
        onDateChange(null);
        setOpen(false);
    };

    const handleSave = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleDateChange = (newValue) => {
        onDateChange(newValue);
    };

    console.log("selected date", selectedDate);

    return (
        <div>
            <button
                ref={anchorRef}
                className="py-1 h-[33px] px-8 text-sm font-medium flex gap-2 items-center rounded-lg border border-[#e7e7e7] hover:bg-primary-header"
                onClick={handleToggle}
            >
                {!selectedDate && <AddIcon />}
                {selectedDate ? dayjs(selectedDate).format("D MMMM, YYYY") : buttonLabel}
            </button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal={false} // Ensures the Popper is rendered at the top-most layer
                sx={{ zIndex: 1300 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: "left top",
                        }}
                    >
                        <Paper sx={{ boxShadow: "0px 4px 34px 0px #00000029", borderRadius: "8px" }}>
                            <ClickAwayListener onClickAway={handleSave}>
                                <div>
                                    <div className="p-4 pb-0">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateCalendar
                                                value={selectedDate ? dayjs(selectedDate) : selectedDate}
                                                onChange={handleDateChange}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="flex gap-5 justify-end p-4  border-t border-stone-250">
                                        <button
                                            className="border border-stone-250 font-medium px-5 py-1.5 rounded-lg hover:bg-dgray-50"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button className="bg-black text-white px-5 font-medium py-1.5 rounded-lg" onClick={handleSave}>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default DatePickerPopover;
