import SwitchButton from "@/components/common/SwitchButton";
import SupportPanel from "@/components/landing/SupportPanel";
import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import MainLayout from "@/components/MainLayout";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import FullPricingBlock from "@/components/pricing/FullPricingBlock";
import BaseContainer from "@/components/BaseContainer";
import MainLoader from "@/components/MainLoader";

export default function UpgradeScene() {
    const [annual, setAnnual] = useState(false);
    const { ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: "debounce",
        refreshRate: 1000,
    });
    const subscriptionInfoFunction = useHTTPRequest(`/billing/getSubscriptionInfo`);

    const tariffsInfoFunction = useHTTPRequest(`/billing/getTariffs`);

    const { data: subscriptionInfo, isLoading: isLoadingSubscription } = useQuery({
        queryKey: ["Subscription"],
        queryFn: () => subscriptionInfoFunction(),
    });
    const { data: tariffsList, isLoading: isLoadingTariffs } = useQuery({
        queryKey: ["Tariffs"],
        queryFn: () => tariffsInfoFunction(),
    });

    const loading = isLoadingSubscription || isLoadingTariffs;

    return (
        <MainLayout>
            {loading && <MainLoader />}
            {!loading && (
                <>
                    <div ref={ref} className="flex w-full flex-col gap-2.5 items-center px-6 mt-24">
                        <h1 className="text-primary-black text-center text-[27px] sm:text-[30px] md:text-[40px] xl:text-[46px] leading-[1.1] font-bold">
                            Select a Plan
                        </h1>
                        <p className="text-[16px] mb-20 mt-6 sm:text-[18px] md:text-[20px] lg:text-2xl text-center md:max-w-[760px] lg:max-w-[860px] 2xl:max-w-[1350px] font-normal font-Inter leading-[1.4] text-neutral-500">
                            Reach millions of decision makers and their teams
                        </p>
                        <SwitchButton switchState={[annual, setAnnual]} isUpgradePage={true} />
                    </div>
                    <div className="w-full pb-16 lg:pb-0">
                        <FullPricingBlock
                            subscriptionInfo={subscriptionInfo?.data}
                            annual={annual}
                            tariffList={tariffsList?.data}
                            isUpgradePage={true}
                        />
                    </div>
                    {/* <SupportPanel title={false} /> */}
                </>
            )}
        </MainLayout>
    );
}
