import React from "react";

const tabData = [{ label: "Website Intent" }, { label: "Fuse Engage" }];

function NavigationBar({ setTabOpen, tabOpen }) {
    return (
        <nav className="text-center text-[#050505] text-sm font-medium font-['Inter'] capitalize flex flex-col gap-4 items-start md:flex-row md:items-center md:gap-6 xl:text-sm border-b border-[#E7E7E7]">
            {tabData.map((tab, index) => (
                <button
                    key={index}
                    className={`self-stretch h-12 border-b-2 transition-colors duration-300 ${
                        tabOpen === tab.label ? "border-[#050505] text-[#050505]" : "border-transparent text-dgray-6 hover:border-[#050505]"
                    }`}
                    onClick={() => setTabOpen(tab.label)}
                >
                    {tab.label}
                </button>
            ))}
        </nav>
    );
}

export default NavigationBar;
