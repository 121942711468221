import AllTabs from "@/components/ai_engagement/AllTabs";
import React from "react";
import MainLayout from "@/components/MainLayout";
import LinkedinInbox from "@/components/ai_engagement/linkedin-inbox/LinkedinInbox";
import MainTitleBar from "@/components/MainTitleBar";

export default function LinkedinInboxScene() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white font-Inter">
                <div className="flex flex-col grow">
                    <AllTabs className="px-[24px]" />
                    <LinkedinInbox />
                </div>
            </div>
        </MainLayout>
    );
}
