import cn from "classnames";
export default function SwitchButton({ switchState, isUpgradePage = false }) {
    const [isAnnual, setIsAnnual] = switchState;

    const onAnnualClick = () => {
        setIsAnnual(!isAnnual);
    };
    const options = [
        {
            text: "Annual billing",
            annual: true,
        },
        { text: "Monthly billing", annual: false },
    ];
    return (
        <div className="relative flex w-[294px] items-center justify-center md:w-[380px]">
            <div className="relative flex w-full flex-row">
                {options.map((el) => {
                    return (
                        <div
                            onClick={onAnnualClick}
                            className={`
                                font-Inter cursor-pointer z-[2] flex h-full w-1/2 items-center justify-center py-2.5 px-4 rounded-lg 
                                ${el.annual ? " rounded-r-none" : "rounded-l-none"} 
                                ${el.annual === isAnnual ? "bg-primary-black text-white" : isUpgradePage ? "bg-primary-header text-primary-black" : "bg-white text-primary-black"}`}
                        >
                            <span
                                className={cn("text-gray-950", {
                                    "text-white": !(!isAnnual === el.annual),
                                })}
                            >
                                {el.text}
                            </span>
                        </div>
                    );
                })}

                <div className="absolute left-[25%] top-[100%] z-10 flex translate-x-[-50%] translate-y-[-50%] items-center justify-center rounded bg-[#FFC8C8] p-[2px_12px] text-[12px] leading-[1.4]">
                    Save 20%
                </div>
            </div>
        </div>
    );
}
