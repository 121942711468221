import React from "react";

function Header() {
    return (
        <>
            <h1 className="text-primary-black text-center mt-28 text-[27px] sm:text-[30px] md:text-[40px] xl:text-[46px] leading-[1.1] font-bold">
                Person Directory
            </h1>
        </>
    );
}

export default Header;
