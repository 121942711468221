import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactComponent as FuseAiIcon } from "@/assets/image/sidebar-icons/fuse-ai.svg";
import { ReactComponent as FuseAgent } from "@/assets/image/sidebar-icons/fuse-agent.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/sidebar-icons/home.svg";
import { ACTIONS_SIDEBAR_LIST, SEARCH_SIDEBAR_LIST, ENGAGE_SIDEBAR_LIST, INTENT_SIDEBAR_LIST } from "@/utils/common";
import { SidebarItem } from "@/components/sidebar/SidebarItem";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import { SidebarUserItem } from "@/components/sidebar/SideBarUserItem";
import { SidebarCreditsUsageItem } from "./SidebarCreditsUsageItem";

const drawerWidth = 199;
const widestScreenDrawerWidth = 199;

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#f4f3f0",
    height: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",

    "@media (min-width: 1536px)": {
        width: widestScreenDrawerWidth,
        height: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
    },

    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    paddingY: 2,
    overflowX: "hidden",
    height: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    width: `72px`,

    "@media (min-width: 1536px)": {
        width: "72px",
        height: "100%",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        paddingBottom: "16px",
    },

    backgroundColor: "#f4f3f0",
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    height: "100%",
    "@media (min-width: 1536px)": {
        width: widestScreenDrawerWidth,
        height: "100%",
    },
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
    ".MuiDrawer-paper": {
        border: "none",
    },
}));

export function MainSidebar({ showProfilePopup, data, open, setOpen }) {
    const { pathname } = useLocation();
    const authContext = useContext(AuthContext);

    const handleDrawerChange = (e) => {
        e.stopPropagation();
    };

    return (
        <Box className="hidden lg:flex">
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <Box
                    sx={{
                        height: "100%",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                    onClick={handleDrawerChange}
                    id="sideBarContainer"
                >
                    <List sx={{ paddingY: 0 }}>
                        <SidebarItem
                            data-qa="sidebar-toggle-icon"
                            open={open}
                            label="FuseAI"
                            onClick={handleDrawerChange}
                            icon={FuseAiIcon}
                            pathname={pathname}
                            id="sideBarBurger"
                            width="20px"
                            height="20px"
                            marginBottom="24px"
                            labelFontSize="16px"
                            iconLabelGap={1}
                            labelFontWeight={600}
                        />
                        <SidebarItem
                            label={"Home"}
                            open={open}
                            icon={HomeIcon}
                            marginBottom="8px"
                            onClick={handleDrawerChange}
                            pathname={pathname}
                            router="/home"
                        />
                        <SidebarItem
                            label={"Fuse Agent"}
                            open={open}
                            icon={FuseAgent}
                            onClick={handleDrawerChange}
                            pathname={pathname}
                            router="/ai-intelligence"
                        />
                        {open && (
                            <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                                Manage
                            </div>
                        )}
                        <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                            {ACTIONS_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                                <SidebarItem
                                    open={open}
                                    key={label}
                                    label={label}
                                    onClick={handleDrawerChange}
                                    icon={Icon}
                                    router={router}
                                    pathname={pathname}
                                    isComingSoon={isComingSoon}
                                />
                            ))}
                        </div>
                        {/* {open && (
                            <div className="text-[#888888] text-[12px] mt-[16px] ml-[16px] mb-[8px] font-semibold font-['Inter'] capitalize">
                                Records
                            </div>
                        )}
                        {RECORDS_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                            <SidebarItem
                                open={open}
                                key={label}
                                label={label}
                                onClick={handleDrawerChange}
                                icon={Icon}
                                router={router}
                                pathname={pathname}
                                isComingSoon={isComingSoon}
                            />
                        ))} */}
                        {open && (
                            <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                                Search
                            </div>
                        )}
                        <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                            {SEARCH_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon, dataQA }, index) => (
                                <SidebarItem
                                    open={open}
                                    key={label}
                                    label={label}
                                    onClick={handleDrawerChange}
                                    icon={Icon}
                                    router={router}
                                    pathname={pathname}
                                    isComingSoon={isComingSoon}
                                    data-qa={dataQA}
                                />
                            ))}
                        </div>
                        {open && (
                            <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                                Engage
                            </div>
                        )}
                        <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                            {ENGAGE_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                                <SidebarItem
                                    open={open}
                                    key={label}
                                    label={label}
                                    onClick={handleDrawerChange}
                                    icon={Icon}
                                    router={router}
                                    pathname={pathname}
                                    isComingSoon={isComingSoon}
                                />
                            ))}
                        </div>
                        {open && (
                            <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                                Intent
                            </div>
                        )}
                        <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                            {INTENT_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                                <SidebarItem
                                    open={open}
                                    key={label}
                                    label={label}
                                    onClick={handleDrawerChange}
                                    icon={Icon}
                                    router={router}
                                    pathname={pathname}
                                    isComingSoon={isComingSoon}
                                />
                            ))}
                        </div>
                    </List>
                    <List sx={{ paddingY: 0 }}>
                        <SidebarCreditsUsageItem data={data} open={open} />
                        <SidebarUserItem
                            label={(authContext.userInfo?.userName ?? "").split(" ")[0]}
                            open={open}
                            setOpen={setOpen}
                            authContext={authContext}
                            onClick={(event) => {
                                showProfilePopup(event.currentTarget);
                            }}
                        />
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}
