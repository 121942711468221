import AllTabs from "@/components/ai_engagement/AllTabs";
import React from "react";
import MainLayout from "@/components/MainLayout";
import LinkedinConnections from "@/components/ai_engagement/linkedin-connections/LinkedinConnections";
import MainTitleBar from "@/components/MainTitleBar";

export default function LinkedinConnectionsScene() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white font-Inter">
                <div className="flex flex-col grow">
                    <AllTabs className="px-[24px]" />
                    <LinkedinConnections />
                </div>
            </div>
        </MainLayout>
    );
}
