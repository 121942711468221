import LandingLayout from "@/components/LandingLayout";
import React from "react";

export default function SendingPolicy() {
    return (
        <LandingLayout>
            <div className="bg-primary-header">
                <div className="max-w-[1050px] mx-auto py-5 p-4 md:p-10 flex flex-col gap-y-4 font-Inter">
                    <h1 className="text-xl font-semibold">FuseAI Sending Policy</h1>
                    <p className="text-xl font-semibold">Last updated: October 24, 2024</p>
                    <p>
                        Throughout FuseAI's journey, we have actively partnered with the global anti-spam community, including Internet
                        Service Providers, Email Service Providers, and anti-spam organizations, in the ongoing fight against spam.
                    </p>
                    <p>
                        Daily, we encounter email senders impersonating reputable brands and attempting to phish for personal information.
                        To protect our clients and their recipients, we have established our own set of guidelines, complementing existing
                        spam laws in various countries.
                    </p>
                    <p>
                        These guidelines not only help us identify spammers but also improve your email deliverability and contribute to a
                        strong sending reputation. Failure to adhere to these rules may affect your ability to send emails and use our
                        services, potentially resulting in rate limitations or temporary or permanent account suspension.
                    </p>

                    <h2 className="text-xl font-semibold">Personal Data We Collect</h2>
                    <ul className="list-disc ml-5 flex flex-col gap-y-1">
                        <li>
                            <p>
                                <strong>Bounces: </strong>When an email can't be delivered and is returned to the sender, typically due to
                                an incorrect or inactive email address.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Unsubscribes: </strong>Recipients who opt out of further communication for various reasons, such as
                                lack of interest or content mismatch.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Blocks: </strong>Emails that cannot leave FuseAI's servers due to permanent errors, including
                                non-existent or invalid email addresses, or those reported as spam.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Spam Complaints: </strong>The number of recipients who mark your message as spam.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Spamtrap Hits: </strong>Webmail providers convert inactive email addresses into spam traps to
                                identify senders using outdated or purchased lists.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Unjustified Abuse Complaint: </strong>When a recipient reports a sender for sending unsolicited
                                messages.
                            </p>
                        </li>
                    </ul>

                    <h2 className="text-xl font-semibold">Rules:</h2>
                    <p>
                        To optimize your deliverability rates, prevent account suspension, and assist in the fight against spam, please
                        adhere to the following five rules:
                    </p>
                    <ul className="list-disc ml-5 flex flex-col gap-y-1">
                        <li>
                            <p>
                                <strong>Include an Unsubscribe Link in Every Campaign: </strong>Each campaign must have a clear and
                                accessible opt-out link. Once a recipient unsubscribes, take immediate action to stop further emails to that
                                individual.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>CAN-SPAM Act Compliance: </strong>Ensure all your email campaigns comply with the CAN-SPAM Act and
                                any similar regulations applicable in your contacts' countries.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Clear Communication of Sender Name and Status: </strong>The "From," "To," and "Reply-To" fields must
                                accurately reflect the sender's domain name and email address. Emails should be sent from domains that are
                                at least a month old with transparent public records. If emailing on behalf of a third-party organization,
                                the email body must clarify that it's sent via a third-party domain.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Restriction to Legal and Legitimate Information: </strong>FuseAI prohibits messages that contain,
                                promote, or link to illegal or harmful content. We generally do not work with senders promoting gambling,
                                adult content, weapons, drugs, political campaigns, and other sensitive topics without prior approval. Your
                                emails must not contain content deemed unsuitable by FuseAI.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Compliance with Applicable Laws and Our Terms & Policies: </strong>Your use of our services must
                                comply with all relevant laws and our terms, including the Privacy Policy, Microsoft and Gmail Program
                                Policies if applicable. It's your responsibility to ensure compliance, and we reserve the right to adjust
                                your rate limits and take other measures if compliance risks arise.
                            </p>
                        </li>
                    </ul>
                    <p>
                        For businesses regulated by authorities (e.g., in finance or healthcare), please contact our sales department to
                        discuss custom account options rather than using a self-service account. FuseAI reserves the right to request
                        relevant documents and licenses related to your business activities.
                    </p>
                </div>
            </div>
        </LandingLayout>
    );
}
