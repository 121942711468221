import React, { useEffect, useRef } from "react";
import MainLayout from "@/components/MainLayout";
import AllTabs from "@/components/ai_engagement/AllTabs";
import ContactDetails from "@/components/ai_engagement/contactActivity/ContactDetails";
import AllActivities from "@/components/ai_engagement/contactActivity/AllActivities";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MainTitleBar from "@/components/MainTitleBar";

export default function ContactActivityScene() {
    const [activities, setActivities] = React.useState([]);
    const [targetContact, setTargetContact] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    // get path params
    const { activityId, campaignId } = useParams();
    const navigate = useNavigate();

    const { getContactActivities, getTargetContacts } = useAIEngagement();

    const getContactActivitiesRef = useRef();
    getContactActivitiesRef.current = getContactActivities;
    const getTargetContactsRef = useRef();
    getTargetContactsRef.current = getTargetContacts;

    useEffect(() => {
        const fetchActivities = async () => {
            const response = await getContactActivitiesRef.current(activityId, campaignId);
            setActivities(response.data);
            const res = await getTargetContactsRef.current(campaignId);
            const result = res.data.filter((contact) => contact._id === activityId);
            setTargetContact(result);
            setLoading(false);
        };
        fetchActivities();
    }, [activityId, campaignId]);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full">
                <AllTabs />
                <div className="p-[24px]">
                    <p className="main-text-black-600 !text-dgray-500 flex gap-1">
                        <span onClick={() => navigate("/ai-engagement/campaigns")} className="cursor-pointer">
                            Campaigns
                        </span>
                        /
                        <span onClick={() => navigate(`/campaigns/${campaignId}`)} className="cursor-pointer">
                            Leads
                        </span>
                        /<span className="text-[#2D7A89]">Activity</span>
                    </p>
                    {loading && (
                        <div className="flex justify-center items-center h-[400px]">
                            <CircularProgress />
                        </div>
                    )}
                    {!loading && activities?.length > 0 && (
                        <div className="flex flex-col pre-lg:flex-row mt-[40px]">
                            <div className="w-full lg:w-[32%]">
                                <ContactDetails targetContact={targetContact[0]} />
                            </div>
                            <div className="justify-center hidden lg:flex w-[4%]">
                                <div className="border-l border-stone-400"></div>
                            </div>
                            <div className="w-full lg:w-[62%] mt-10 pre-lg:mt-0 ">
                                <AllActivities activities={activities} setActivities={setActivities} />
                            </div>
                        </div>
                    )}
                    {!loading && activities?.length <= 0 && (
                        <div className="flex justify-center items-center h-[400px]">
                            <p>No activities found</p>
                        </div>
                    )}
                </div>
            </div>
        </MainLayout>
    );
}
