import * as React from "react";
import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { SidebarItem } from "@/components/sidebar/SidebarItem";
import { ACTIONS_SIDEBAR_LIST, RECORDS_SIDEBAR_LIST, SEARCH_SIDEBAR_LIST, ENGAGE_SIDEBAR_LIST, INTENT_SIDEBAR_LIST } from "@/utils/common";
import { SidebarUserItem } from "@/components/sidebar/SideBarUserItem";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "@/context/AuthContext";
import { ReactComponent as FuseAiIcon } from "@/assets/image/sidebar-icons/fuse-ai.svg";
import { ReactComponent as FuseAgent } from "@/assets/image/sidebar-icons/fuse-agent.svg";
import { ReactComponent as HomeIcon } from "@/assets/image/sidebar-icons/home.svg";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { SidebarCreditsUsageItem } from "../sidebar/SidebarCreditsUsageItem";

const drawerWidth = 300;

const Drawer = styled(MuiDrawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiDrawer-paper": {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        backgroundColor: "#f4f3f0",
    },
}));

export function MobileHeader({ showProfilePopup, data }) {
    const [open, setOpen] = React.useState(false);
    const { pathname } = useLocation();
    const authContext = useContext(AuthContext);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box
            sx={{
                width: 250,
                height: "100%",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
        >
            <List sx={{ paddingY: 0 }}>
                <SidebarItem
                    open={open}
                    label="FuseAI"
                    onClick={toggleDrawer(false)}
                    icon={FuseAiIcon}
                    pathname={pathname}
                    textStyles="!text-[#050505]"
                    id="sideBarBurger"
                    width="32px"
                    height="32px"
                    marginBottom="40px"
                    labelFontSize="24px"
                    iconLabelGap={2}
                    labelFontWeight={600}
                />
                <SidebarItem
                    label={"Home"}
                    open={open}
                    icon={HomeIcon}
                    marginBottom="8px"
                    onClick={toggleDrawer(false)}
                    pathname={pathname}
                    router="/home"
                />
                <SidebarItem
                    label={"Fuse Agent"}
                    open={open}
                    icon={FuseAgent}
                    onClick={toggleDrawer(false)}
                    pathname={pathname}
                    router="/ai-intelligence"
                />
                {open && (
                    <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                        Manage
                    </div>
                )}
                <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                    {ACTIONS_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                        <SidebarItem
                            open={open}
                            key={label}
                            label={label}
                            onClick={toggleDrawer(false)}
                            icon={Icon}
                            router={router}
                            pathname={pathname}
                            isComingSoon={isComingSoon}
                        />
                    ))}
                </div>
                {/* {open && (
                    <div className="text-[#888888] text-[12px] mt-[16px] ml-[16px] mb-[8px] font-semibold font-['Inter'] capitalize">
                        Records
                    </div>
                )}
                {RECORDS_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                    <SidebarItem
                        open={open}
                        key={label}
                        label={label}
                        onClick={toggleDrawer(false)}
                        icon={Icon}
                        router={router}
                        pathname={pathname}
                        isComingSoon={isComingSoon}
                    />
                ))} */}
                {open && (
                    <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                        Search
                    </div>
                )}

                <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                    {SEARCH_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                        <SidebarItem
                            open={open}
                            key={label}
                            label={label}
                            onClick={toggleDrawer(false)}
                            icon={Icon}
                            router={router}
                            pathname={pathname}
                            isComingSoon={isComingSoon}
                        />
                    ))}
                </div>

                {open && (
                    <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                        Engage
                    </div>
                )}

                <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                    {ENGAGE_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                        <SidebarItem
                            open={open}
                            key={label}
                            label={label}
                            onClick={toggleDrawer(false)}
                            icon={Icon}
                            router={router}
                            pathname={pathname}
                            isComingSoon={isComingSoon}
                        />
                    ))}
                </div>
                {open && (
                    <div className="text-[#888888] text-[12px] mt-[24px] ml-[8px] mb-[16px] font-semibold font-['Inter'] capitalize">
                        Intent
                    </div>
                )}

                <div className={`flex flex-col gap-2 mt-[${open ? "0px" : "24px"}]`}>
                    {INTENT_SIDEBAR_LIST.map(({ label, icon: Icon, router, isComingSoon }, index) => (
                        <SidebarItem
                            open={open}
                            key={label}
                            label={label}
                            onClick={toggleDrawer(false)}
                            icon={Icon}
                            router={router}
                            pathname={pathname}
                            isComingSoon={isComingSoon}
                        />
                    ))}
                </div>

                {/* <SidebarItem label={"Home"} open={open} icon={HomeIcon} pathname={pathname} router="/home" />
                <Divider
                    sx={{
                        backgroundColor: "#E8E7E7",
                        opacity: 0.3,
                        marginY: 2,
                    }}
                />
                {MAIN_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
                    <SidebarItem open={open} key={label} label={label} icon={Icon} router={router} pathname={pathname} />
                ))}
            </List>
            <Divider sx={{ backgroundColor: "#E8E7E7", opacity: 0.3, marginY: 2 }} />
            <List sx={{ paddingY: 0 }}>
                {SECONDARY_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
                    <SidebarItem open={open} key={label} label={label} icon={Icon} router={router} pathname={pathname} />
                ))}
            </List>
            <Divider sx={{ backgroundColor: "#E8E7E7", opacity: 0.3, marginY: 2 }} />
            <List sx={{ paddingY: 0 }}>
                {TERTIARY_SIDEBAR_LIST.map(({ label, icon: Icon, router }, index) => (
                    <SidebarItem open={open} key={label} label={label} icon={Icon} pathname={pathname} router={router} />
                ))}
            </List>
            <List sx={{ marginTop: "auto" }}> */}
                {/*<SidebarItem*/}
                {/*  open={open}*/}
                {/*  pathname={pathname}*/}
                {/*  label="Search"*/}
                {/*  icon={SearchIcon}*/}
                {/*  router="#"*/}
                {/*/>*/}
            </List>
            <List sx={{ paddingY: 0 }}>
                <SidebarCreditsUsageItem data={data} open={open} />
                <SidebarUserItem
                    label={authContext.userInfo?.userName || ""}
                    open={open}
                    authContext={authContext}
                    onClick={(event) => {
                        showProfilePopup(event.currentTarget);
                    }}
                />
            </List>
        </Box>
    );

    return (
        <div className="lg:hidden">
            <Button onClick={toggleDrawer(true)}>
                <FuseAiIcon className="text-black" />
            </Button>
            <Drawer open={open}>{DrawerList}</Drawer>
        </div>
    );
}
