import { formatNumberWithCommas } from "@/utils/common";

function PlanDetails(props) {
    const { stripePrice, annual } = props;
    console.log("This is stripe: ", stripePrice);

    return (
        <div className="font-Inter text-sm leading-[1.4] flex flex-col gap-4 h-[76px] text-[#454545] font-medium">
            <div className="flex justify-between ">
                <p>Price per seat</p>
                <p className="font-semibold text-[#050505]">${annual ? stripePrice.price / 1200 : stripePrice.price / 100}</p>
            </div>
            <div className="flex justify-between">
                <p>Total credits included</p>
                <p className="font-semibold text-[#050505]">
                    {annual
                        ? stripePrice.price === 0
                            ? formatNumberWithCommas(Math.ceil(stripePrice.credits * 12))
                            : formatNumberWithCommas(Math.ceil(stripePrice.credits))
                        : formatNumberWithCommas(Math.ceil(stripePrice.credits))}
                </p>
            </div>
        </div>
    );
}

export default PlanDetails;
