import LandingLayout from "@/components/LandingLayout";
import React from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
    return (
        <LandingLayout>
            <div className="bg-primary-header">
                <div className="max-w-[1050px] mx-auto py-5 p-4 md:p-10 flex flex-col gap-y-4 font-Inter">
                    <h1 className="text-xl font-semibold">FuseAI Terms of Service</h1>
                    <p className="text-xl font-semibold">Last updated: August 31, 2024</p>
                    <p>
                        Welcome to FuseAI! These Terms of Service (the "Terms" or the "Agreement") constitute a legal contract between you
                        ("you" or the "User") and FuseAI. This Agreement governs your use of FuseAI's proprietary platform, including its
                        hosted services and downloadable plugins, which help you locate contact information for individuals or entities
                        (each search is a “Lookup,” and the information obtained is “Lookup Information”). This document outlines the nature
                        of our business relationship, the services we provide, and the permitted uses of those services and their products.
                        If you use our Application Program Interface (API), these Terms also apply to both you and the end users of your
                        website or application. By accessing or using FuseAI's website, platform, APIs, products, or services (collectively,
                        the "Services"), or any Lookup Information, you agree to be bound by these Terms and any policies incorporated by
                        reference.
                    </p>

                    <h2 className="text-xl font-semibold">PLEASE READ THESE TERMS CAREFULLY:</h2>
                    <p>
                        By registering for the Services or by downloading, installing, or otherwise accessing or using them, you confirm
                        that you have read and understood these Terms, and you agree to be legally bound by them, including FuseAI’s Privacy
                        Policy.
                    </p>

                    <p>
                        <span className="text-base font-semibold">ARBITRATION NOTICE. </span>
                        Disputes under these Terms will be resolved by binding, individual arbitration as outlined in Section 10. By
                        accepting these Terms, you and FuseAI each waive the right to a jury trial or to participate in any class action or
                        representative proceeding. You give up your right to go to court, except in small claims court. Your rights will be
                        determined by a neutral arbitrator, not a judge or jury.
                    </p>
                    <p>
                        If you do not agree to all terms and conditions of this Agreement, you must not access or use the Services or Lookup
                        Information.
                    </p>
                    <p>
                        The Services and Lookup Information are only available to individuals who are at least 18 years old. By using any of
                        the Services, you confirm that you are at least 18. If you are under 18, you must stop using the Services and Lookup
                        Information immediately. Our Privacy Policy explains how we handle personal information related to our Services.
                    </p>

                    <h2 className="text-xl font-semibold">Your FuseAI Account and Use of the Services and Lookup Information</h2>
                    <h2 className="text-xl font-semibold">Your FuseAI Account</h2>
                    <p>
                        If you create an account, you must use the Services responsibly and are responsible for maintaining account security
                        and all activities under your account. You must provide your full legal name, a valid email address, and any other
                        required information to complete the signup process. Notify FuseAI immediately of any unauthorized use or security
                        breaches. FuseAI is not liable for damages resulting from such acts or omissions. Accounts created by bots or
                        automated methods are not permitted.
                    </p>

                    <h2>Your Use of the Services and Lookup Information</h2>
                    <p>
                        You must use the Services and Lookup Information responsibly and professionally, in accordance with these Terms,
                        their intended use, and standard industry practices. Specifically, you agree to:
                    </p>
                    <ul className="list-disc ml-5 flex flex-col gap-y-1">
                        <li>
                            Use Lookup Information only to identify sales opportunities, candidates for recruitment, or research existing
                            customers and prospects, related to their profession, business, or employment.
                        </li>
                        <li>
                            Verify that Lookup Information is accurate and up-to-date, and not listed as opted out in FuseAI’s records
                            before using it.
                        </li>
                        <li>Cease all use of Lookup Information upon termination of these Terms.</li>
                    </ul>
                    <p>You may not:</p>
                    <ul className="list-disc ml-5 flex flex-col gap-y-1">
                        <li>
                            Use the Services or Lookup Information for illegal or unauthorized purposes, or in ways that are unlawful,
                            fraudulent, or harmful.
                        </li>
                        <li>
                            Violate any applicable laws, including those related to copyright, spam, privacy, data protection, or
                            trademarks.
                        </li>
                        <li>Harm or impair access to or enjoyment of the Services or Lookup Information.</li>
                        <li>
                            Use the Services to distribute malicious software or engage in excessive data extraction without explicit
                            written consent.
                        </li>
                        <li>Replicate, sell, or exploit Lookup Information for commercial purposes without permission.</li>
                        <li>Use automated systems or scripts to extract content from the Services.</li>
                        <li>Alter or remove copyright, trademark, or legal notices from the Services.</li>
                        <li>Attempt to breach security or gain unauthorized access to the Services or Lookup Information.</li>
                    </ul>

                    <p>
                        Abuse or excessive use of the Services may result in suspension or termination of access. FuseAI will attempt to
                        warn the account owner before suspension, where feasible.
                    </p>

                    <h2 className="text-xl font-semibold">Free and Paid Subscription Services; Teams</h2>
                    <p>
                        These Terms apply to all Users of the Services, whether free or paid. Current pricing is available at{" "}
                        <Link to="/pricing" className="text-blue-500">
                            https://tryfuse.ai/pricing.
                        </Link>{" "}
                        Subscribers must review and agree to our Subscription Agreement at sign-up and when modifying their subscription.
                    </p>

                    <p>
                        Subscribers prepay for Lookups and Lookup Information exports, which expire at the end of the Subscription Term.
                        Subscribers may invite others to join or be part of a Team, managed by the Team creator. If you join a Team, the
                        Team manager must purchase additional Lookups and Exports on your behalf. You or the Team manager can remove you
                        from the Team at any time by emailing support@tryfuse.ai.
                    </p>

                    <h2 className="text-xl font-semibold">Intellectual Property Rights</h2>
                    <p>
                        This Agreement does not transfer any intellectual property rights from FuseAI to you. FuseAI retains all rights,
                        title, and interest in its intellectual property.
                    </p>
                    <p>FuseAI does not claim ownership of any content you provide to it.</p>

                    <h2 className="text-xl font-semibold">Use License</h2>
                    <p>
                        Subject to compliance with these Terms, FuseAI grants you a limited, non-exclusive, non-transferable, revocable
                        license to: (i) access and use the Services and Lookup Information in accordance with these Terms; and (ii) install
                        and use downloadable software provided by FuseAI.
                    </p>
                    <p>
                        You may use the Services for Lookups and viewing Lookup Information solely for: (i) communicating with an individual
                        or entity about their profession, business, or employment; and (ii) identifying sales opportunities, researching
                        customers, and analyzing Lookup Information for legitimate internal business activities.
                    </p>
                    <h2 className="text-xl font-semibold">License Restrictions</h2>
                    <p>You may not:</p>
                    <ul className="list-disc ml-5 flex flex-col gap-y-1">
                        <li>
                            Duplicate, copy, resell, reuse, or reverse engineer Lookup Information or any part of the Services without
                            written consent from FuseAI.
                        </li>
                        <li>Use automated systems or scripts to extract content from the Services.</li>
                        <li>Commercially exploit data incorporating or using Lookup Information without permission.</li>
                        <li>Transfer or disclose Lookup Information to third parties.</li>
                        <li>Violate any third-party agreements with your use of the Services.</li>
                        <li>Use the Services or Lookup Information unlawfully or against applicable laws, including GDPR compliance.</li>
                    </ul>
                    <h2 className="text-xl font-semibold">Disclaimer of Warranties</h2>
                    <p>
                        Except as stated, the Services and Lookup Information are provided “as is.” FuseAI may update or modify Lookup
                        Information but does not guarantee its accuracy, completeness, or uninterrupted operation.
                    </p>
                    <h2 className="text-xl font-semibold">Limitation of Liability</h2>
                    <p>
                        FuseAI and its affiliates are not liable for: (i) special, incidental, or consequential damages; (ii) costs of
                        substitute products or services; (iii) data loss or corruption; (iv) amounts exceeding fees paid in the last three
                        months.
                    </p>
                    <h2 className="text-xl font-semibold">Indemnification</h2>
                    <p>
                        You agree to indemnify FuseAI and its affiliates from claims arising from: (i) your use of the Services or Lookup
                        Information; (ii) violations of these Terms; (iii) breaches of third-party rights or laws; (iv) unauthorized access
                        with your credentials.
                    </p>
                    <h2 className="text-xl font-semibold">Arbitration</h2>
                    <p>
                        Disputes will be resolved by binding arbitration under the Federal Arbitration Act and administered by the American
                        Arbitration Association (AAA). Arbitration will be confidential, and the arbitrator can award the same relief as a
                        court. You and FuseAI waive the right to a jury trial or class action.
                    </p>
                    <p>
                        You may opt out of arbitration within 30 days of agreeing to these Terms by sending an Opt-Out Notice to FuseAI. If
                        you opt out, disputes will be resolved under Section 14(ii) (“Governing Law”).
                    </p>
                    <h2 className="text-xl font-semibold">Marketing and Feedback</h2>
                    <p>
                        If using the Services for a company, you grant FuseAI the right to use your company's name and logos for marketing.
                        You may revoke this license by providing written notice to FuseAI
                    </p>
                    <p>Any feedback you provide becomes FuseAI’s property, and we may use it without compensation.</p>
                    <h2 className="text-xl font-semibold">Changes</h2>
                    <p>
                        FuseAI may modify or replace these Terms at its discretion. Continued use of the Services signifies acceptance of
                        any changes. New services or features will be subject to these Terms.
                    </p>
                    <h2 className="text-xl font-semibold">Contact</h2>
                    <p>FuseAI, LLC Email: support@tryfuse.ai</p>
                    <h2 className="text-xl font-semibold">Miscellaneous</h2>
                    <p>
                        These Terms, along with the Privacy Policy and any incorporated agreements, represent the entire understanding
                        between you and FuseAI. You may not transfer these Terms without written consent from FuseAI. Failure to enforce any
                        provision does not waive our right to enforce it later. If any part of these Terms is invalid, the remaining parts
                        remain effective.
                    </p>
                    <h2 className="text-xl font-semibold">Governing Law</h2>
                    <p>
                        These Terms are governed by the laws of the State of Washington. Disputes will be resolved in state or federal
                        courts in King County, Washington.
                    </p>
                    <h2 className="text-xl font-semibold">Additional Terms</h2>
                    <p>Your use of the Services is subject to any additional terms posted or linked from the Services.</p>
                    <h2 className="text-xl font-semibold">Consent to Electronic Communications</h2>
                    <p>
                        By using the Services, you consent to receiving electronic communications from us, as detailed in our Privacy
                        Policy. These communications satisfy any legal requirements for written notices.
                    </p>
                    <h2 className="text-xl font-semibold">Notice to California Residents</h2>
                    <p>
                        California residents can contact the Complaint Assistance Unit of the California Department of Consumer Affairs for
                        complaints or additional information.
                    </p>
                    <h2 className="text-xl font-semibold">No Support</h2>
                    <p>
                        We are not obligated to provide support for the Services, but any provided support will be subject to published
                        policies.
                    </p>
                </div>
            </div>
        </LandingLayout>
    );
}
