import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const HtmlEditor = ({ value, onChange = () => {} }) => {
    const handleChange = (event, editor) => {
        const data = editor.getData();
        onChange(data);
    };

    return (
        <CKEditor
            editor={ClassicEditor}
            data={value}
            onChange={(event, editor) => handleChange(event, editor)}
            config={{
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                ],
            }}
        />
    );
};

export default HtmlEditor;
