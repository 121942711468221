import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { EmailWarmupContext } from "../../../pages/main/EmailWarmupScene";
import { capitalizeString } from "@/utils/common";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import { Paper } from "@mui/material";
import classNames from "classnames";
import { getFormattedDate } from "@/utils/common";

export const EmailWarmupTable = ({ campaigns }) => {
    const navigate = useNavigate();
    const { setEmailAccount } = useContext(EmailWarmupContext);

    const handleEmailClick = (emailInfo) => {
        setEmailAccount(emailInfo); // Optional
        navigate(`email/${emailInfo.id}`);
    };

    const headCellStyles = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "1.4",
        color: "#000",
        textAlign: "center",
    };

    const infoCellStyle = {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        borderBottom: "none",
        textAlign: "center",
    };

    return (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} aria-label="Email Warmup Table">
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: "#F9F9F9",
                            py: "14px",
                            borderTop: "1px solid #DDD",
                            borderBottom: "1px solid #DDD",
                        }}
                    >
                        <TableCell align="left" sx={headCellStyles}>
                            Email Address
                        </TableCell>
                        <TableCell align="left" sx={headCellStyles}>
                            Email/Day
                        </TableCell>
                        <TableCell align="left" sx={headCellStyles}>
                            Reply Rate
                        </TableCell>
                        <TableCell align="left" sx={headCellStyles}>
                            Spam Rate
                        </TableCell>
                        <TableCell align="left" sx={headCellStyles}>
                            Saved From Spam
                        </TableCell>
                        <TableCell align="left" sx={headCellStyles}>
                            Status
                        </TableCell>
                        <TableCell align="center" sx={headCellStyles}>
                            DNS Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campaigns.map((row) => {
                        return (
                            <TableRow key={row.email} onClick={() => handleEmailClick(row)} style={{ cursor: "pointer" }}>
                                <TableCell
                                    align="left"
                                    sx={{
                                        ...infoCellStyle,
                                        color: "#4873FA",
                                        width: "200px",
                                        maxWidth: "200px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        "&:hover .warmup-email": {
                                            borderBottom: "1px solid #4287FA",
                                        },
                                    }}
                                >
                                    <Tooltip title={row?.email} arrow>
                                        <span className="warmup-email transition-all">{row?.email}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="left" sx={{ ...infoCellStyle, color: "#0D0D0D" }}>
                                    {row.metrics[getFormattedDate()]
                                        ? row.metrics[getFormattedDate()]["Warm Up"]?.Total +
                                          row.metrics[getFormattedDate()]?.Replies?.Total
                                        : 0}
                                    /{row.email_per_day}
                                </TableCell>
                                <TableCell align="left" sx={{ ...infoCellStyle, color: "#0D0D0D" }}>
                                    {row.response_rate}%
                                </TableCell>
                                <TableCell align="left" sx={{ ...infoCellStyle, color: "#0D0D0D" }}>
                                    {row.spam_rate_in_last_14_days}%
                                </TableCell>
                                <TableCell align="left" sx={{ ...infoCellStyle, color: "#0D0D0D" }}>
                                    {row.spam_in_last_14_days}
                                </TableCell>
                                <TableCell align="left" sx={{ ...infoCellStyle, color: "#0D0D0D" }}>
                                    <div
                                        className={classNames("warmup-email-status-text font-Inter mx-auto", {
                                            "bg-[#FEF0C7] text-[#DC6803]": row.status_code === "paused",
                                            "bg-[#2e7d32] text-white": row.status_code === "active",
                                            "bg-[#D4D4D4]": row.status_code === "pending",
                                        })}
                                    >
                                        {capitalizeString(row.status_code)}
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        ...infoCellStyle,
                                        color: "#0D0D0D",
                                    }}
                                >
                                    <div
                                        className={classNames("w-[11px] h-[11px] rounded-full mx-auto", {
                                            "bg-[#1BD82E]": row.dnsStatus,
                                            "bg-[#FEF0C7]": !row.dnsStatus,
                                        })}
                                    ></div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
