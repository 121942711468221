import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DirectoryTableRow(props) {
    const { row, index } = props;
    const navigate = useNavigate();

    const initials = (row.firstName?.[0] || "") + (row.lastName?.[0] || "");
    const name = [row.firstName, row.lastName].filter(Boolean).join(" ");

    return (
        <TableRow
            sx={{
                "& .MuiTableCell-root:not(:last-child)": { borderRight: "1px solid #E7E7E7" },
                "& .MuiTableCell-root": {
                    border: "none",
                    borderTop: index === 0 ? "none" : 1,
                    borderColor: "#E7E7E7",
                    padding: "12px 16px",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
            }}
        >
            {/* Name */}
            <TableCell>
                <div className="flex gap-2 items-center font-medium text-sm">
                    <p className="text-xs flex font-semibold h-8 w-8 justify-center items-center p-2 my-auto leading-none text-center whitespace-nowrap bg-orange-300 rounded-full">
                        {initials}
                    </p>
                    <p onClick={() => navigate(`/profile/${row.username}`)} className="hover:underline cursor-pointer">
                        {name}
                    </p>
                </div>
            </TableCell>

            {/* Info */}
            <TableCell>
                <Tooltip
                    title="Contact Info"
                    className="flex gap-4 items-center cursor-pointer"
                    onClick={() => {
                        navigate("/signin");
                    }}
                >
                    <div className="flex items-center gap-4">
                        <div className="flex gap-1 items-center self-stretch my-auto">
                            <EmailOutlinedIcon className="!h-5 !w-5" />
                            <span className="">Email</span>
                        </div>
                        <div className="flex gap-1 items-center self-stretch my-auto">
                            <PhoneOutlinedIcon className="!h-5 !w-5" />
                            <span className="">Phone</span>
                        </div>
                    </div>
                </Tooltip>
            </TableCell>

            {/* Designation */}
            <TableCell>
                <p>{row.title || "-"}</p>
            </TableCell>

            {/* Company */}
            <TableCell>
                <div className="flex gap-2 items-center">
                    <img
                        loading="lazy"
                        onError={(e) => (e.target.style.display = "none")}
                        src={row.company?.logo}
                        className="w-6 h-6 rounded-full"
                        alt={`${row.company?.name} logo`}
                    />
                    <p>{row.company?.name}</p>
                </div>
            </TableCell>

            {/* Country */}
            <TableCell>
                <p>{row.country || "-"}</p>
            </TableCell>
        </TableRow>
    );
}
