import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function DailyCollectedProfiles({ dataByDays }) {
    // Function to format the date labels
    const getDays = () => {
        return dataByDays?.map((item) => {
            const date = new Date(item.day);
            return date.toLocaleDateString(undefined, {
                month: "short",
                day: "numeric",
            });
        });
    };

    const getNewProfilesByDays = () => {
        return dataByDays?.map((item) => item.nonRepeatedIPs);
    };

    const getReturningProfilesByDays = () => {
        return dataByDays?.map((item) => item.repeatedIPs);
    };

    const calculateAverageNewPerDay = () => {
        let totalDays = 0;
        let totalNewProfiles = 0;

        dataByDays?.forEach((item) => {
            if (item.nonRepeatedIPs > 0) {
                totalNewProfiles += item.nonRepeatedIPs; // Sum up new profiles
                totalDays += 1; // Count the days
            }
        });

        const averageNewPerDay = totalDays > 0 ? totalNewProfiles / totalDays : 0;

        return averageNewPerDay.toFixed(0);
    };

    const legendItems = [
        { color: "bg-[#2D7A89]", label: "New Profiles" },
        { color: "bg-[#F4F3F0]", label: "Returning Profiles" },
    ];

    const data = {
        labels: getDays(),
        datasets: [
            {
                label: "New Profiles",
                data: getNewProfilesByDays(),
                backgroundColor: "#2D7A89",
                borderColor: "#2D7A89",
                borderRadius: 0,
                barThickness: 40,
            },
            {
                label: "Returning Profiles",
                data: getReturningProfilesByDays(),
                backgroundColor: "#F4F3F0",
                borderColor: "#F4F3F0",
                borderRadius: { topLeft: 4, topRight: 4, bottomLeft: 0, bottomRight: 0 },
                barThickness: 40,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    font: {
                        family: "Inter",
                        size: 10,
                        weight: "400",
                    },
                    color: "#767676",
                },
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                ticks: {
                    font: {
                        family: "Inter",
                        size: 10,
                        weight: "400",
                    },
                    color: "#767676",
                    maxRotation: 0, // Rotate labels if they overlap
                    minRotation: 0,
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        // Format the tooltip title as a readable date
                        const date = context[0].label; // Already formatted
                        return date;
                    },
                },
            },
        },
        responsive: true,
    };

    return (
        <section className="font-Inter flex-1 rounded-[8px] border border-[#E7E7E7] p-6">
            <div className="flex items-center justify-between">
                <div className="text-[#050505] text-base font-semibold font-['Inter']">Daily Collected Profiles</div>

                <div className="text-[#2d7a89] text-sm font-medium font-['Inter'] capitalize">
                    {calculateAverageNewPerDay()} Avg new per day
                </div>
            </div>
            <div className="flex flex-col mt-6 max-md:max-w-full">
                <div className="flex flex-col gap-10 md:flex-row md:items-center">
                    {legendItems.map((item, index) => (
                        <div key={index} className="flex gap-3 items-center">
                            <div className={`shrink-0 w-2.5 h-2.5 ${item.color} rounded-full`} />

                            <div className="text-[#3d3d3d] text-sm font-medium font-['Inter']">{item.label}</div>
                        </div>
                    ))}
                </div>
                <div className="mt-6 w-full bg-white">
                    <Bar data={data} options={options} />
                </div>
            </div>
        </section>
    );
}

export default DailyCollectedProfiles;
