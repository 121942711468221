import React, { useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Checkbox,
    Box,
    Typography,
    Button,
    Pagination,
    PaginationItem,
} from "@mui/material";

// 1) Import the arrow icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/system";
import { ReactComponent as Description } from "@/assets/image/billing/description.svg";
import { ReactComponent as DateIcon } from "@/assets/image/billing/date.svg";
import { ReactComponent as Amount } from "@/assets/image/billing/amount.svg";
import { ReactComponent as Status } from "@/assets/image/billing/status.svg";
import { ReactComponent as Action } from "@/assets/image/billing/action.svg";

// Styled components for header and body cells
const StyledHeadCell = styled(TableCell)(({ highlight }) => ({
    backgroundColor: "#f4f3f0",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle", // <-- key for vertical centering in a <td/>
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#e0e0e0",
    // }),
}));

const StyledBodyCell = styled(TableCell)(({ highlight }) => ({
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#fafafa",
    // }),
}));

const columns = [
    // If you'd like a "select all" column, you can add it here:
    // { id: "select", label: "", width: 50, sortable: false },
    { id: "description", label: "Description", width: 300, sortable: true, icon: <Description /> },
    { id: "date", label: "Date", width: 150, sortable: true, icon: <DateIcon /> },
    { id: "amount", label: "Amount", width: 150, sortable: true, icon: <Amount /> },
    { id: "status", label: "Status", width: 150, sortable: false, icon: <Status /> },
    { id: "action", label: "Action", width: 150, sortable: false, icon: <Action /> },
];

const BillingTable = ({ billingList, rowsPerPage = 10 }) => {
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [hoveredCol, setHoveredCol] = useState(null);

    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    const sortedBillingList = React.useMemo(() => {
        if (!orderBy) return billingList;
        return [...billingList].sort((a, b) => {
            if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
            return 0;
        });
    }, [billingList, order, orderBy]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleSelectAll = (checked) => {
        if (checked) {
            const newSelected = sortedBillingList.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((item) => item.id);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleRowSelect = (id) => {
        setSelected((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((selectedId) => selectedId !== id) : [...prevSelected, id]
        );
    };

    const isAllSelected = selected.length === sortedBillingList.slice((page - 1) * rowsPerPage, page * rowsPerPage).length;

    const totalPages = Math.ceil(sortedBillingList.length / rowsPerPage);
    const currentBilling = sortedBillingList.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // 2) Match style & rendering of sorting icons
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    marginLeft: "0px",
                    borderTop: "1px solid #e7e7e7",
                    borderLeft: "1px solid #e7e7e7",
                    borderRight: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        marginTop: "8px",
                        marginBottom: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#aaa",
                        borderRadius: "6px",
                    },
                }}
            >
                <Table sx={{ borderCollapse: "separate", tableLayout: "fixed", minWidth: 1200 }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((col, colIdx) => (
                                <StyledHeadCell
                                    key={col.id}
                                    sx={{ width: col.width }}
                                    align={col.id === "select" ? "center" : "left"}
                                    highlight={hoveredCol === colIdx}
                                    onMouseEnter={() => setHoveredCol(colIdx)}
                                    onMouseLeave={() => setHoveredCol(null)}
                                    onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                    style={{
                                        cursor: col.sortable ? "pointer" : "default",
                                        userSelect: "none",
                                    }}
                                >
                                    {col.id === "select" ? (
                                        <Checkbox
                                            checked={isAllSelected}
                                            onChange={(e) => handleSelectAll(e.target.checked)}
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#2D7A89",
                                                },
                                                padding: 0,
                                                height: "12px",
                                                width: "12px",
                                            }}
                                        />
                                    ) : (
                                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                            {/* Left side: label & icon */}
                                            <Box display="flex" alignItems="center" gap={1}>
                                                {col.icon}
                                                <Typography fontSize={14} fontWeight="medium" color="#5d5d5d" sx={{ lineHeight: "21px" }}>
                                                    {col.label}
                                                </Typography>
                                            </Box>

                                            {/* Right side: the sorting icons (if sortable) */}
                                            {renderSortingIcons(col)}
                                        </Box>
                                    )}
                                </StyledHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentBilling.length > 0 ? (
                            currentBilling.map((item) => (
                                <TableRow key={item.id}>
                                    {columns.map((col) => {
                                        if (col.id === "select") {
                                            return (
                                                <StyledBodyCell key={`${item.id}-select`} align="center">
                                                    <Checkbox
                                                        checked={selected.includes(item.id)}
                                                        onChange={() => handleRowSelect(item.id)}
                                                        sx={{
                                                            "&.Mui-checked": {
                                                                color: "#2D7A89",
                                                            },
                                                            padding: 0,
                                                            height: "12px",
                                                            width: "12px",
                                                        }}
                                                    />
                                                </StyledBodyCell>
                                            );
                                        } else if (col.id === "description") {
                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight="medium"
                                                        color="#050505"
                                                        sx={{ lineHeight: "21px" }}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.description,
                                                            }}
                                                        ></span>
                                                    </Typography>
                                                </StyledBodyCell>
                                            );
                                        } else if (col.id === "amount") {
                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight="medium"
                                                        color="#050505"
                                                        sx={{ lineHeight: "21px" }}
                                                    >
                                                        {item.amount.toLocaleString("en-US", {
                                                            style: "currency",
                                                            currency: item.currency,
                                                        })}
                                                    </Typography>
                                                </StyledBodyCell>
                                            );
                                        } else if (col.id === "status") {
                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight="medium"
                                                            color="#050505"
                                                            sx={{ lineHeight: "21px" }}
                                                            textTransform="capitalize"
                                                        >
                                                            {item.status}
                                                        </Typography>
                                                    </Box>
                                                </StyledBodyCell>
                                            );
                                        } else if (col.id === "action") {
                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <a
                                                        href={item.link_to_invoice}
                                                        style={{
                                                            color: "#050505",
                                                            textDecoration: "none",
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            fontFamily: "Inter",
                                                            lineHeight: "21px",
                                                        }}
                                                    >
                                                        View invoice
                                                    </a>
                                                </StyledBodyCell>
                                            );
                                        } else if (col.id === "date") {
                                            // 1) Convert the ISO string to a Date object
                                            const dateObj = new Date(item.date);

                                            // 2) Format the date portion (e.g., "November 11, 2024")
                                            const datePart = dateObj.toLocaleDateString("en-US", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                            });

                                            // 3) Format the time portion (e.g., "05:08:44")
                                            //    - hour12: false => 24-hour clock
                                            //    - hour12: true  => 12-hour clock
                                            const timePart = dateObj.toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                                hour12: false,
                                            });

                                            // 4) Combine
                                            const formattedDate = `${datePart} ${timePart}`;

                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight="medium"
                                                        color="#050505"
                                                        sx={{ lineHeight: "21px" }}
                                                    >
                                                        {formattedDate}
                                                    </Typography>
                                                </StyledBodyCell>
                                            );
                                        } else {
                                            return (
                                                <StyledBodyCell key={`${item.id}-${col.id}`}>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight="medium"
                                                        color="#050505"
                                                        sx={{ lineHeight: "21px" }}
                                                    >
                                                        {item[col.id]}
                                                    </Typography>
                                                </StyledBodyCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    align="center"
                                    sx={{
                                        py: 3,
                                        color: "gray",
                                        borderRight: "1px solid #e7e7e7",
                                        borderBottom: "1px solid #e7e7e7",
                                    }}
                                >
                                    {billingList.length === 0 ? "Nothing to display" : "Loading..."}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Pagination */}
            {sortedBillingList.length > rowsPerPage && (
                <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default BillingTable;
