import { Slider, styled, Box } from "@mui/material";
import { formatNumberWithLetters } from "@/utils/common";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#e8e7e7 ",
    "& .MuiSlider-thumb": {
        backgroundColor: "#2D7A89",
        border: "5px solid white",
        width: "24px",
        height: "24px",
        boxShadow: "0 4px 8px -2px rgba(16, 24, 40, 0.1)",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "0 4px 8px 4px rgba(16, 24, 40, 0.1)",
        },
    },
    ".MuiSlider-rail": {
        backgroundColor: "#E8E7E7",
        height: "0.5rem",
        border: "none",
    },
    ".MuiSlider-mark": {
        display: "none",
    },
    ".MuiSlider-track": {
        backgroundColor: "#2D7A89",
        height: "0.5rem",
        border: "none",
    },
}));

function CreditSlider({ options, state }) {
    const [priceIndex, setPriceIndex] = state;
    const mappedValues = options.map((option, index) => {
        return { value: index };
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const showPrice = (index) => {
        const value = options[index];
        // only show the first, 3rd, 5th etc
        if (fullScreen) {
            return value === 0 || value === 500000 || value === 1000000;
        }
        return index % 2 === 0;
    };

    return (
        <div className="lg:w-[90%] w-full">
            <div className="flex w-full flex-col">
                <div className="w-full h-[76px] relative">
                    {options.map((value, index) => {
                        return (
                            <>
                                {index === priceIndex && index !== 0 && (
                                    <div
                                        className="absolute top-5 w-[76px] h-[42px] bg-accents-mainGreen rounded-[8px] flex items-center justify-center"
                                        style={{
                                            left: `${(index / (options.length - 1)) * 100}%`,
                                            transform: `translateX(${!index ? "0%" : "-50%"})`,
                                        }}
                                    >
                                        <span className="font-Inter text-sm text-white whitespace-nowrap ">
                                            {"$" + options[index] / 1000}
                                        </span>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="6"
                                            viewBox="0 0 12 6"
                                            fill="none"
                                            style={{
                                                position: "absolute",
                                                bottom: "-6px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                            }}
                                        >
                                            <path
                                                d="M5.25259 5.67752C5.65038 6.10749 6.34962 6.10749 6.74741 5.67752L12 0H0L5.25259 5.67752Z"
                                                fill="#2d7a89"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
                <StyledSlider
                    value={priceIndex}
                    step={null}
                    min={0}
                    max={options.length - 1}
                    marks={mappedValues}
                    onChange={(event, value) => {
                        setPriceIndex(value);
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        width: "100%",
                        marginBottom: "25px",
                    }}
                >
                    {options.map((value, index) => {
                        return (
                            <>
                                {showPrice(index) && (
                                    <span
                                        className="absolute top-0 cursor-pointer select-none"
                                        onClick={() => setPriceIndex(index)}
                                        style={{
                                            left: `${(index / (options.length - 1)) * 100}%`,
                                            transform: `translateX(${!index ? "0%" : index === options.length - 1 ? "-100%" : "-50%"})`,
                                            display: !index || index === options.length - 1 ? "block" : "",
                                        }}
                                    >
                                        <span className=" font-Inter flex items-end gap-[2px] whitespace-nowrap ">
                                            <span className="text-sm text-[#888]">{formatNumberWithLetters(value)}</span>
                                        </span>
                                    </span>
                                )}
                            </>
                        );
                    })}
                </Box>
            </div>
        </div>
    );
}

export default CreditSlider;
