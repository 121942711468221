import useAuth from "@/hooks/useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";

const useNotes = () => {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const getNotes = async () => {
        return fetchWithAuth(`/api/v1/notes/getAll`, "GET");
    };

    // get notes by opportunity id
    const getNotesByOpportunityId = async (id) => {
        return fetchWithAuth(`/api/v1/notes/opportunity/${id}`, "GET");
    };

    // create opportunity
    const createNote = async (note) => {
        try {
            // keep only the fields that has values
            delete note._id;
            Object.keys(note).forEach((key) => note[key] === "" && delete note[key]);
            if (note.opportunity) {
                note.opportunity = note.opportunity._id;
            }
            const response = await fetchWithAuth("/api/v1/notes/", "POST", note);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // update opportunity
    const updateNote = async (note) => {
        try {
            // remove any empty fields
            const id = note._id;
            delete note._id;
            delete note.createdBy;
            delete note.createdAt;
            delete note.updatedAt;
            if (note.opportunity) {
                note.opportunity = note.opportunity._id;
            }
            Object.keys(note).forEach((key) => note[key] === "" && delete note[key]);
            if (note.description) {
                note.content = note.description;
                delete note.description;
            }
            const response = await fetchWithAuth(`/api/v1/notes/${id}`, "PUT", note);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const deleteNotes = async (ids) => {
        try {
            const response = await fetchWithAuth(`/api/v1/notes`, "DELETE", { noteIds: ids });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getOpportunities = async () => {
        return fetchWithAuth("/api/v1/opportunities/getAll", "GET");
    };

    // create timeline note
    const createTimelineNote = async (note) => {
        try {
            const response = await fetchWithAuth("/api/v1/notes", "POST", note);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // update timeline note
    const updateTimelineNote = async (id, note) => {
        try {
            if (note.description) {
                note.content = note.description;
                delete note.description;
            }
            const response = await fetchWithAuth(`/api/v1/notes/${id}`, "PUT", note);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // get note by id
    const getNoteById = async (id) => {
        try {
            const response = await fetchWithAuth(`/api/v1/notes/${id}`, "GET");
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getTranscriptNotes = async () => {
        return fetchWithAuth(`/api/v1/notes/transcripts`, "GET");
    };

    const shareNote = async (noteId, teamMemberIds) => {
        try {
            const response = await fetchWithAuth(`/api/v1/notes/${noteId}/share`, "POST", { teamMemberIds });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    return {
        getNotes,
        createNote,
        updateNote,
        deleteNotes,
        getOpportunities,
        getNotesByOpportunityId,
        createTimelineNote,
        updateTimelineNote,
        getNoteById,
        getTranscriptNotes,
        shareNote,
    };
};

export default useNotes;
