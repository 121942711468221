import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import cn from "classnames";
import { useRef } from "react";
import { toast } from "react-toastify";
import { ReactComponent as UploadSVG } from "@/assets/image/bulk/upload.svg";

const FileUploaderWidget = ({ attachedFileName, onFileContent, onChange, reset, error, name }) => {
    const inputRef = useRef(null);

    const isValidateContent = (content, fileType) => {
        if (fileType === "txt") return true;
        if (fileType === "csv") {
            const headers = content[0];

            if (headers?.length && headers.find((val) => val.toLowerCase().includes("email"))) return true;
            return false;
        }
        return false;
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            try {
                if (acceptedFiles?.length > 0) {
                    const file = acceptedFiles[0];
                    const fileType = file.name.split(".").pop().toLowerCase();

                    const reader = new FileReader();

                    reader.onabort = () => {
                        throw new Error("file reading was aborted");
                    };
                    reader.onerror = () => {
                        throw new Error("file reading has failed");
                    };
                    reader.onload = (e) => {
                        onChange(file);
                        const fileContent = e.target.result;
                        switch (fileType) {
                            case "csv":
                                Papa.parse(fileContent, {
                                    complete: (content) => {
                                        if (isValidateContent(content.data, fileType)) onFileContent(file.name, content.data, fileType);
                                        else toast.error("Wrong formatted file", { theme: "colored" });
                                    },
                                });

                                break;
                            case "txt":
                                Papa.parse(fileContent, {
                                    complete: (content) => {
                                        if (isValidateContent(content.data, fileType)) onFileContent(file.name, content.data, fileType);
                                        else toast.error("Wrong formatted file", { theme: "colored" });
                                    },
                                });
                                break;
                            default:
                                throw new Error(`Unsupported file type:${fileType}`);
                        }
                    };

                    if (fileType === "csv" || fileType === "txt") {
                        reader.readAsText(file);
                    } else {
                        throw new Error(`Unsupported file type: ${fileType}`);
                    }
                } else {
                    throw new Error("Something went wrong!");
                }
            } catch (error) {
                toast.error(error?.message, { theme: "colored" });
            }
        },
        [onFileContent, onChange]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ".csv, .txt",
        multiple: false, // This will restrict to only one file
    });

    if (attachedFileName) {
        return (
            <>
                <div
                    onClick={reset}
                    className="flex flex-col items-center justify-center w-full p-4 cursor-pointer rounded-lg border border-dashed border-[#E8E7E7] text-center text-lg font-light select-none max-w-[630px] h-[200px]"
                >
                    <div className="text-base text-black font-medium text-opacity-40 font-Inter">Attached</div>
                    <div className="text-sm text-black font-medium font-Inter">{attachedFileName}</div>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                {...getRootProps()}
                className={cn(
                    "flex flex-col items-center justify-center w-full p-4 cursor-pointer rounded-lg border border-dashed border-[#e7e7e7] select-none max-w-[630px] h-[200px]",
                    error && "border-red-500"
                )}
            >
                <input ref={inputRef} {...getInputProps()} name={name} />
                <UploadSVG />
                <div className="font-Inter flex flex-row text-gray-950 text-sm leading-md">
                    <p className="text-accents-mainGreen text-sm font-medium font-Inter cursor-pointer leading-tight">Click To Upload</p>
                    <p className="font-normal">&nbsp; or drag and drop</p>
                </div>
                <div className="font-Inter text-gray-950 text-opacity-40 text-xs">Uploader supports CSV or TXT (Max. 5 MB)</div>
            </div>
        </>
    );
};

export default FileUploaderWidget;
