import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import DatePickerPopover from "./DatePickerPopover";
import AssigneePopover from "./AssigneePopover";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ChecklistIcon from "@mui/icons-material/Checklist";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import useTasks from "@/hooks/crm/useTasks";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

export default function EditTaskPopover({ handleClose, open, anchorRef, taskData, setRefreshUI }) {
    const [title, setTitle] = useState(taskData.title || "");
    const [dueDate, setDueDate] = useState(taskData.dueDate || null);
    const [creationDate, setCreationDate] = useState(taskData.createdAt || null);
    const [status, setStatus] = useState(taskData.status || null); // State for selected status
    const [showStatusOptions, setShowStatusOptions] = useState(false); // State for showing status options
    const [assignedTo, setAssignedTo] = useState(taskData.assignedTo || null);
    const [loading, setLoading] = useState(false);

    const { updateTask } = useTasks();
    const handleStatusClick = (newStatus) => {
        setStatus(newStatus);
        setShowStatusOptions(false); // Close the status options
    };
    const statusIcons = {
        "To Do": <FormatListBulletedIcon className="!w-4" />,
        "In Progress": <ChecklistIcon className="!w-4" />,
        Done: <CheckCircleOutlineOutlinedIcon className="!w-4" />,
    };
    const handleCancel = () => {
        setTitle("");
        setStatus(null);
        setDueDate(null);
        setAssignedTo(null);
        handleClose();
    };

    const handleUpdate = async () => {
        if (!title) {
            toast.error("Title is required");
            return;
        }
        const taskDataToUpdate = {
            title,
            ...(status && { status }),
            ...(dueDate && { dueDate }),
            ...(assignedTo && { assignedTo: assignedTo.id }),
        };
        try {
            setLoading(true);
            const res = await updateTask(taskData._id, taskDataToUpdate);
            if (res) {
                toast.success("Task updated successfully");
                setRefreshUI((prev) => !prev);
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    console.log("assignedTo", assignedTo);
    console.log("status", taskData);

    return (
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                    }}
                >
                    <Paper sx={{ boxShadow: "0px 4px 34px 0px #00000029", borderRadius: "8px" }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <div>
                                <div className="p-5 pb-0">
                                    <input
                                        type="text"
                                        className="p-2 px-4 w-full border border-[#e7e7e7] rounded-lg placeholder:text-dgray-6 placeholder:text-sm"
                                        placeholder="Enter title here"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        onKeyDown={(e) => e.key === "Enter" && handleUpdate()}
                                    />
                                    <div className="flex items-center gap-2 mt-5">
                                        <div>
                                            {status ? (
                                                <div
                                                    className={`py-1 h-[33px] px-4 text-sm font-medium flex cursor-pointer items-center  gap-1 rounded-lg border ${
                                                        status === "To Do"
                                                            ? "text-[#2E3DAA] bg-[#ECEEFF] border-[#2E3DAA]"
                                                            : status === "In Progress"
                                                              ? "text-[#FF782C] bg-[#FFF6E5] border-[#FF782C]"
                                                              : "text-[#2D7A89] bg-[#E1ECEE] border-[#2D7A89]"
                                                    }`}
                                                    onClick={() => setShowStatusOptions(true)}
                                                >
                                                    {statusIcons[status]}
                                                    {status}
                                                </div>
                                            ) : (
                                                <button
                                                    className="py-1 h-[33px] px-8 text-sm font-medium flex gap-2 items-center rounded-lg border border-stone-250 hover:bg-dgray-100"
                                                    onClick={() => setShowStatusOptions(true)}
                                                >
                                                    <span className="text-base">+</span>
                                                    Status
                                                </button>
                                            )}
                                        </div>
                                        <DatePickerPopover
                                            buttonLabel="Due Date"
                                            selectedDate={dueDate}
                                            onDateChange={(date) => setDueDate(date)}
                                        />
                                        <AssigneePopover selectedAssignee={assignedTo} setSelectedAssignee={setAssignedTo} />
                                    </div>
                                </div>
                                {showStatusOptions && (
                                    <div className="mt-3 px-5 flex gap-2">
                                        <div
                                            onClick={() => handleStatusClick("To Do")}
                                            className="text-[#2E3DAA] bg-[#ECEEFF] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#2E3DAA]"
                                        >
                                            <FormatListBulletedIcon className="!w-4" />
                                            To Do
                                        </div>
                                        <div
                                            onClick={() => handleStatusClick("In Progress")}
                                            className="text-[#FF782C] bg-[#FFF6E5] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#FF782C]"
                                        >
                                            <ChecklistIcon className="!w-4" />
                                            In Progress
                                        </div>
                                        <div
                                            onClick={() => handleStatusClick("Done")}
                                            className="text-[#2D7A89] bg-[#E1ECEE] flex items-center gap-1 text-xs py-0.5 px-3 rounded-lg font-medium cursor-pointer border hover:border-[#2D7A89] "
                                        >
                                            <CheckCircleOutlineOutlinedIcon className="!w-4" />
                                            Done
                                        </div>
                                    </div>
                                )}
                                <hr className="w-full border-t-2 my-5 border-stone-250" />
                                <div className="flex gap-2 justify-end px-5 pb-5">
                                    <button
                                        onClick={handleCancel}
                                        className="border border-[#e7e7e7] font-medium px-3 py-1.5 rounded-lg hover:bg-primary-header"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={loading}
                                        onClick={handleUpdate}
                                        className="bg-primary-black disabled:opacity-50 hover:opacity-80 flex items-center gap-2 text-white px-5 font-medium py-1.5 rounded-lg"
                                    >
                                        {loading && <CircularProgress size={15} color="inherit" />}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
