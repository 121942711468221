import * as React from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import UploadFile from "./UploadFile";
import useTasks from "@/hooks/crm/useTasks";
import { debounce } from "lodash";

export default function TaskDetailsModal({ open, setOpen, rowData, setRefreshUI }) {
    const [taskTitle, setTaskTitle] = React.useState(rowData?.title || "");
    const [taskDescription, setTaskDescription] = React.useState(rowData?.description || "");
    const { updateTask } = useTasks();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    React.useEffect(() => {
        if (rowData) {
            setTaskTitle(rowData.title || "");
            setTaskDescription(rowData.description || "");
        }
    }, [rowData]);

    // Debounced save function
    const saveTaskDetails = React.useCallback(
        debounce(async (field, value) => {
            try {
                await updateTask(rowData._id, { [field]: value });
                setRefreshUI((prev) => !prev);
            } catch (error) {
                console.error(`Failed to update ${field}`, error);
            }
        }, 500),
        [rowData?._id]
    );

    // Handle input change
    const handleInputChange = (field, value) => {
        if (field === "title") setTaskTitle(value);
        if (field === "description") setTaskDescription(value);
        saveTaskDetails(field, value); // Call debounced save function
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <div className="min-w-[600px]">
                    <div className="flex items-center justify-between p-4 border-b border-[#E7E7E7]">
                        <h2 className="font-semibold">Task Details</h2>
                        <div onClick={toggleDrawer(false)}>
                            <CloseIcon className="text-dgray-500 cursor-pointer hover:text-dgray-600" />
                        </div>
                    </div>
                    <div className="p-4">
                        <h2 className="font-semibold">Task Title</h2>
                        <input
                            type="text"
                            value={taskTitle}
                            onChange={(e) => handleInputChange("title", e.target.value)}
                            className="py-2 px-4 rounded-lg border mt-1 w-full border-stone-250"
                        />
                        <h2 className="font-semibold mt-5">Description</h2>
                        <textarea
                            type="text"
                            rows={4}
                            value={taskDescription}
                            onChange={(e) => handleInputChange("description", e.target.value)}
                            className="py-2 px-4 rounded-lg border mt-1 w-full border-stone-250"
                        ></textarea>

                        <h1 className="font-semibold mt-5">Details</h1>
                        <div className="border p-3 border-stone-250 rounded-lg mt-1 text-sm">
                            <div className="flex items-center rounded-lg text-sm space-x-2 bg-dgray-100 p-2 font-semibold">
                                <img
                                    className="h-5 w-5 rounded-md"
                                    src={`https://logo.clearbit.com/airbnb.com`}
                                    alt="company"
                                    onError={(e) => (e.target.style.display = "none")}
                                />
                                <p>Airbnb</p>
                            </div>
                            <div className="mt-4 grid grid-cols-2 gap-x-5">
                                <div className="text-dgray-6 text-sm space-y-4 ">
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <MapOutlinedIcon className="!w-4 !h-4" /> Country:{" "}
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <PaidOutlinedIcon className="!w-4 !h-4" /> Amount
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <ModeEditOutlineOutlinedIcon className="!w-4 !h-4" /> Created By
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <CalendarTodayOutlinedIcon className="!w-4 !h-4" /> Close Date
                                    </p>
                                    <p className="flex items-center gap-1 ">
                                        {" "}
                                        <CorporateFareOutlinedIcon className="!w-4 !h-4" /> Stage
                                    </p>
                                    <p className="flex items-center gap-1 !mt-6">
                                        {" "}
                                        <CalendarTodayOutlinedIcon className="!w-4 !h-4" /> Last Update
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <GroupOutlinedIcon className="!w-4 !h-4" /> Point of contact
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <AdminPanelSettingsOutlinedIcon className="!w-4 !h-4" /> Opportunity Owner
                                    </p>
                                </div>
                                <div className="text-sm space-y-4 font-medium">
                                    <p>United States</p>
                                    <p>$100</p>
                                    <p>John Doe</p>
                                    <p>July 21, 2024</p>
                                    <p className="bg-blue-100 w-fit rounded-md font-medium py-1 px-4 text-blue-600">New</p>
                                    <p>12/12/2021</p>
                                    <p>John Doe</p>
                                    <p>John Doe</p>
                                </div>
                            </div>
                        </div>
                        <div className=" mb-6">
                            <UploadFile task={rowData} />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
