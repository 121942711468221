import React from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import RecordsTabs from "@/components/crm/RecordsTabs";
import useOpportunities from "@/hooks/crm/useOpportunities";
import AccountsTable from "../../../components/crm/accounts/accounts-table/AccountsTable";
import MainTitle from "@/components/MainTitle";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function AccountsScene() {
    const { getCompanies } = useOpportunities();
    const [companies, setCompanies] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selected, setSelected] = React.useState([]);

    React.useEffect(() => {
        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const opportunities = await getCompanies();
                if (opportunities) {
                    setCompanies(opportunities.data);
                }
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
            setLoading(false);
        };
        fetchCompanies();
    }, []);

    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                <p>Records</p>
            </MainTitleBar>
            <RecordsTabs />
            <div className="p-6">
                <AccountsTable data={companies} setData={setCompanies} selected={selected} setSelected={setSelected} loading={loading} />
            </div>
            <FuseAgentModal />
        </MainLayout>
    );
}
