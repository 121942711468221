const { default: useAuth } = require("./useAuth");

const useLinkedinAutomation = () => {
    const { getAuthToken } = useAuth();

    const getAllChats = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/all-chats`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const getUnreadMessages = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/unread-messages`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const getSpecificChat = async (chatId) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/single-chat/${chatId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const sendMessage = async (accountId, chatId, messageText) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/send-message`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ accountId, chatId, messageText }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const startChat = async (accountId, messageText, attendeesIds) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/start-chat`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ accountId, messageText, attendeesIds }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const getAccountRelations = async (accountId) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/account-relations/${accountId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const getAllRelations = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/all-relations`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const updateChat = async (chatId, accountId, params) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/update-chat`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ chatId, accountId, ...params }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const getAllInvitations = async () => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/all-invitations`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const updateReceivedInvitation = async (invitationId, accountId, sharedSecret, provider, action) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/update-invitation`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ invitationId, accountId, sharedSecret, provider, action }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const cancelSentInvitation = async (invitationId, accountId) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/withdraw-invitation`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ invitationId, accountId }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    const sendInvitation = async (linkedinUrl, accountId, invitationText) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_LINKEDIN_AUTOMATION_BACKEND_ENDPOINT}/linkedin/send-invitation`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ linkedinUrl, accountId, invitationText }),
            });

            if (!_resData.ok) {
                throw new Error("Failed to fetch data");
            }

            response = await _resData.json();
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        getAllChats,
        getSpecificChat,
        getUnreadMessages,
        sendMessage,
        startChat,
        updateChat,
        getAccountRelations,
        getAllRelations,
        getAllInvitations,
        updateReceivedInvitation,
        cancelSentInvitation,
        sendInvitation,
    };
};

export default useLinkedinAutomation;
