import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { BASE_BUTTON_STYLES } from "@/utils/common";
import { WarmupModal } from "../email_warmup/summary/WarmupModal";
import Button from "@mui/material/Button";
import useAIEngagement from "@/hooks/useAIEngagement";
import BackdropLoader from "../common/BackdropLoader";

export default function AllTabs() {
    const navigate = useNavigate();
    const location = useLocation();

    // Mapping of paths to tab indexes
    const pathToTabIndex = {
        "/ai-engagement/inbox": 0,
        "/ai-engagement/linkedin-inbox": 1,
        "/ai-engagement/linkedin-connections": 2,
        "/ai-engagement/campaigns": 3,
        "/ai-engagement/global-analytics": 4,
        "/ai-engagement/pending-approval": 5,
    };

    // Determine the initial active tab based on the current path
    const initialTab = pathToTabIndex[location.pathname] ?? false;
    const [activeTab, setActiveTab] = useState(initialTab);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const { connectWithThirdPartyService, getUnipileConnectLink } = useAIEngagement();
    const tabDetails = [
        {
            label: "Unibox",
            path: "/ai-engagement/inbox",
        },
        {
            label: "LinkedIn Unibox",
            path: "/ai-engagement/linkedin-inbox",
        },
        {
            label: "LinkedIn Connections",
            path: "/ai-engagement/linkedin-connections",
        },
        {
            label: "Campaigns",
            path: "/ai-engagement/campaigns",
        },
        {
            label: "Global Analytics",
            path: "/ai-engagement/global-analytics",
        },
        {
            label: "Pending Approval",
            path: "/ai-engagement/pending-approval",
        },
    ];

    useEffect(() => {
        setActiveTab(pathToTabIndex[location.pathname] ?? false);
    }, [location.pathname]);

    const handleChange = (_, newValue) => {
        setActiveTab(newValue);

        if (newValue < tabDetails.length) {
            navigate(tabDetails[newValue].path);
        }
    };

    const getConnectedWithService = async (type) => {
        try {
            setLoading(true);
            const response = await connectWithThirdPartyService({ authType: type });
            console.log("response", response);
            if ((response?.data || false) && response?.data?.authUrl) {
                const a = document.createElement("a");
                a.href = response?.data?.authUrl || "";
                a.target = "_blank";
                a.click();
                a.remove();
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setLoading(false);
        }
    };
    // const fetchAllEmails = async () => {
    //     try {
    //         const response = await reFetchAllEmail();
    //         // if (response?.status === true) {
    //         //   toast.success(response?.message, {
    //         //     theme: "colored",
    //         //   })
    //         // } else {
    //         //   toast.error(response?.message, {
    //         //     theme: "colored",
    //         //   })
    //         // }
    //     } catch (error) {
    //         console.log("Error", error);
    //     }
    // };

    // useEffect(() => {
    //     if (activeTab == 0) fetchAllEmails();
    // }, [activeTab]);

    return (
        <Box sx={{ width: "100%" }}>
            {loading && <BackdropLoader clsName="!z-[9999]" active={true}></BackdropLoader>}
            <Box
                sx={{
                    borderBottom: "1px solid #E7E7E7",
                    px: 3,
                    height: 48,
                    fontFamily: "Inter, sans-serif",
                }}
            >
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    disableRipple
                    sx={{
                        ".MuiTabs-indicator": {
                            backgroundColor: "#050505",
                            height: "3px",
                        },
                        ".MuiTabs-flexContainer": {
                            alignItems: "center",
                            gap: 1,
                        },
                    }}
                >
                    {tabDetails.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            disableRipple
                            // onClick={() => {
                            //     if (tab === "Unibox" && activeTab == 0) fetchAllEmails();
                            // }}
                            sx={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: "Inter, sans-serif",
                                padding: "0",
                                color: activeTab === index ? "#050505" : "#6D6D6D",
                                minWidth: "auto",
                                "&.Mui-selected": {
                                    color: "#050505",
                                },
                            }}
                        />
                    ))}

                    <WarmupModal
                        open={openModal}
                        onClose={() => {
                            setOpenModal(false);
                        }}
                        title={"Choose your email provider"}
                    >
                        <div className="flex flex-row gap-6">
                            <Button
                                type="button"
                                sx={{
                                    ...BASE_BUTTON_STYLES,
                                    backgroundColor: "transparent",
                                    color: "#090C05",
                                    ":hover": {
                                        backgroundColor: "#090C05",
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => getConnectedWithService("outlook")}
                            >
                                Outlook
                            </Button>{" "}
                            <Button
                                type="button"
                                sx={{
                                    ...BASE_BUTTON_STYLES,
                                    backgroundColor: "transparent",
                                    color: "#090C05",
                                    ":hover": {
                                        backgroundColor: "#090C05",
                                        color: "#fff",
                                    },
                                }}
                                onClick={() => getConnectedWithService("gmail")}
                            >
                                Gmail
                            </Button>
                            <Button
                                type="button"
                                sx={{
                                    ...BASE_BUTTON_STYLES,
                                    backgroundColor: "transparent",
                                    color: "#090C05",
                                    ":hover": {
                                        backgroundColor: "#090C05",
                                        color: "#fff",
                                    },
                                }}
                                onClick={async () => {
                                    const { data: authLink } = await getUnipileConnectLink();
                                    if (authLink) {
                                        window.open(authLink, "_blank", "noopener,noreferrer");
                                    } else {
                                        console.error("Failed to retrieve the auth link.");
                                    }
                                }}
                            >
                                Linkedin
                            </Button>
                        </div>
                    </WarmupModal>
                </Tabs>
            </Box>
        </Box>
    );
}
