import React, { useEffect, useRef, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import clsx from "clsx";

export default function Jobs({ companyData }) {
    const [expanded, setExpanded] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const swiperRef = useRef(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const filteredJobs = companyData.recentJobOpenings
        ?.slice(0, 100)
        .filter(
            (job) => job.title.toLowerCase().includes(searchValue.toLowerCase()) && (!selectedCategory || job.category === selectedCategory)
        );

    const categories = [...new Set(companyData.recentJobOpenings?.slice(0, 100)?.map((job) => job.category))];
    const categoriesWithoutSet = companyData.recentJobOpenings?.slice(0, 100)?.map((job) => job.category);
    const categoryCount = categoriesWithoutSet.reduce((acc, category) => {
        acc[category] = (acc[category] || 0) + 1;
        return acc;
    }, {});

    useEffect(() => {
        // Update swiper on selectedCategory change
        if (swiperRef.current) {
            const index = categories.indexOf(selectedCategory);
            swiperRef.current.swiper.slideTo(index, 500, false);
        }
    }, [selectedCategory, categories]);

    return (
        <div className="text-primary-black ">
            <h1 className="font-semibold">Summary</h1>
            <div className="flex items-center gap-6 w-full mt-4">
                <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                    <h1 className="font-semibold text-md">
                        {companyData.recentJobOpenings && companyData.recentJobOpenings.slice(0, 100).length}
                    </h1>
                    <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                    <p className="text-sm text-dgray-6">Jobs Openings</p>
                </div>
                {companyData.momLinkedInHeadCountGrowth && (
                    <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                        <div className="flex justify-between items-center">
                            <h1 className="font-semibold text-md">{companyData.momLinkedInHeadCountGrowth}</h1>
                            {companyData.linkedinFollowersMOMPercent && (
                                <div className="bg-[#D3E1DB] py-1 px-2 text-black flex items-center gap-1 rounded-md">
                                    <NorthEastIcon className="!w-3 !h-3" color="inherit" />
                                    <p className="text-sm">{companyData.linkedinFollowersMOMPercent}%</p>
                                </div>
                            )}
                        </div>
                        <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                        <p className="text-sm text-dgray-6 mt-3">MoM Growth</p>
                    </div>
                )}
                {companyData.qoqLinkedInHeadCountGrowth && (
                    <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                        <div className="flex justify-between items-center">
                            <h1 className="font-semibold text-md">{companyData.qoqLinkedInHeadCountGrowth}</h1>
                            {companyData.linkedinFollowersQOQPercent && (
                                <div className="bg-[#D3E1DB] py-1 px-2 text-black flex items-center gap-1 rounded-md">
                                    <NorthEastIcon className="!w-3 !h-3" color="inherit" />
                                    <p className="text-sm">{companyData.linkedinFollowersQOQPercent}%</p>
                                </div>
                            )}
                        </div>
                        <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                        <p className="text-sm text-dgray-6">QoQ Growth</p>
                    </div>
                )}
            </div>
            <h1 className="font-semibold mt-5">Job Openings ({companyData.recentJobOpenings?.slice(0, 100)?.length})</h1>
            <div className="max-w-[1024px]">
                <div className={`flex p-2 pl-2 mt-5 rounded-lg w-full text-dgray-6 items-center bg-white border border-stone-250`}>
                    <SearchOutlinedIcon className="!w-5 !h-5" color="inherit" />
                    <input
                        type="text"
                        className="w-full rounded-lg outline-none pl-2 font-Inter font-normal text-base placeholder-dgray-6"
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                </div>
                {/* <div className="text-sm mt-5 font-medium flex items-center gap-2">
                    <button className="border border-[#e7e7e7] py-1.5 px-2.5 rounded-lg">HR Manager (2)</button>
                    <button className="border border-[#e7e7e7] py-1.5 px-2.5 rounded-lg">Manager (2)</button>
                </div> */}

                {/* Swiper */}
                <div className="mt-5 mr-1">
                    <Swiper ref={swiperRef} slidesPerView={"auto"} spaceBetween={10}>
                        <SwiperSlide className="!w-auto">
                            <p
                                onClick={() => setSelectedCategory("")}
                                className={clsx(
                                    {
                                        "bg-[#D3E1DB]": selectedCategory === "",
                                        "hover:bg-dgray-100": selectedCategory !== "",
                                    },
                                    "!py-1 cursor-pointer !px-2 text-sm rounded-lg border border-[#e7e7e7]"
                                )}
                            >
                                All ({companyData.recentJobOpenings?.slice(0, 100)?.length})
                            </p>
                        </SwiperSlide>
                        {categories.map((category, index) => (
                            <SwiperSlide className="!w-auto" key={index}>
                                <p
                                    onClick={() => setSelectedCategory(category)}
                                    className={clsx(
                                        {
                                            "bg-[#D3E1DB]": selectedCategory === category,
                                            "hover:bg-dgray-100": selectedCategory !== category,
                                        },
                                        "!py-1 cursor-pointer !px-2 text-sm rounded-lg border border-[#e7e7e7]"
                                    )}
                                >
                                    {category} ({categoryCount[category]})
                                </p>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Accordion Section */}
                <div className="w-full mt-6">
                    {filteredJobs && filteredJobs.length > 0 ? (
                        filteredJobs.map((job, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === index}
                                onChange={handleAccordionChange(index)}
                                sx={{
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "8px !important",
                                    marginBottom: "16px",
                                    boxShadow: "none",
                                    "&:before": { display: "none" },
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        fontSize: "14px",
                                        padding: 0,
                                        fontFamily: "Inter",
                                        fontWeight: "500",
                                        "& .MuiAccordionSummary-content": {
                                            margin: "0px !important",
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <div className="w-full text-sm">
                                        <div className="flex items-center justify-between w-full p-4 pb-0">
                                            <p className="font-semibold">{job.title}</p>
                                            <ExpandMoreIcon
                                                className={`ml-auto transition-transform duration-300 ${
                                                    expanded === index ? "rotate-180" : ""
                                                }`}
                                            />
                                        </div>
                                        <hr className="border-t my-4 border-[#e7e7e7]" />
                                        <div className=" text-dgray-6 p-4 pt-0 flex items-center gap-10 text-sm">
                                            <div className="flex items-center gap-2">
                                                <CategoryOutlinedIcon className="!w-4 !h-4" color="inherit" />
                                                <p>
                                                    Category: <span className="font-medium text-primary-black">{job.category}</span>
                                                </p>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <FmdGoodOutlinedIcon className="!w-4 !h-4" color="inherit" />
                                                <p>
                                                    Location: <span className="font-medium text-primary-black">{job.location}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "16px", paddingTop: "6px" }}>
                                    <p className="text-sm mb-4">{job.description}</p>
                                    {job.url && (
                                        <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-primary-teal text-sm">
                                            {job.url}
                                        </a>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <p className="text-sm text-dgray-500">No jobs found.</p>
                    )}
                </div>
            </div>
        </div>
    );
}
