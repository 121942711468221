import Text from "./Text";

function NameAndCountLabel({ count = 0, name = "--" }) {
    return (
        <div className="w-full px-4 py-2 bg-white rounded-lg border border-[#e7e7e7] justify-start items-center gap-4 inline-flex">
            <div className="p-2 bg-[#f4f3f0] rounded flex-col justify-center items-center gap-2.5 inline-flex">
                <div className="text-center text-[#050505] text-sm font-semibold font-['Inter']">{count}</div>
            </div>
            <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">{name}</div>
        </div>
    );
}

export default NameAndCountLabel;
