import { toStylelessDocument } from "@/utils/common";
import parse from "html-react-parser";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as SendIcon } from "@/assets/image/icons/sendMessage.svg";
import { formatDate } from "./LinkedinInbox";

const textAreaStyles = {
    "& .MuiOutlinedInput-root": {
        padding: "12px",
        fontSize: "14px",
        fontWeight: 500,
        height: "64px",
        fontFamily: "Inter, sans-serif",
        border: "1px solid #E7E7E7",
        borderRadius: "8px",
        backgroundColor: "#fff",
        "&:hover fieldset": {
            border: "1px solid #E7E7E7",
            boxShadow: "none",
        },
        "&.Mui-focused fieldset": {
            border: "1px solid #E7E7E7",
            boxShadow: "none",
        },
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
};

export const SingleChat = ({
    singleChatGeneralData,
    handleSendMessage,
    isChatLoading,
    isChatError,
    message,
    setMessage,
    sortedMessages,
    isSendButtonDisabled,
}) => {
    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey && !isSendButtonDisabled) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className="w-full col-span-3">
            <div className="px-[24px] flex items-center gap-[8px] h-[76px] border-b border-[#E7E7E7]">
                {singleChatGeneralData?.senderProfilePicture ? (
                    <img
                        onClick={() => window.open(singleChatGeneralData?.senderProfileUrl, "_blank")}
                        src={singleChatGeneralData?.senderProfilePicture}
                        alt={singleChatGeneralData?.senderName}
                        style={{
                            width: "28px",
                            height: "28px",
                            borderRadius: "50%",
                            cursor: "pointer",
                        }}
                    />
                ) : (
                    <span
                        style={{
                            backgroundColor: singleChatGeneralData?.bgColor,
                            width: "28px",
                            height: "28px",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            color: "#fff",
                        }}
                    >
                        {singleChatGeneralData?.senderName?.charAt(0)?.toUpperCase() ?? ""}
                    </span>
                )}
                <div>
                    <p className="sender">{singleChatGeneralData?.senderName || ""}</p>
                    <p className="max-w-[255px] title">{singleChatGeneralData?.chatName || ""}</p>
                </div>
            </div>
            <div className="p-[24px] mailList_container">
                {sortedMessages && !isChatLoading && (
                    <>
                        {sortedMessages?.map((message, index) => {
                            return (
                                <div className="" key={index}>
                                    <div
                                        className={`rounded-[8px] text-[#050505] p-[12px] font-Inter text-[14px] font-medium ${message.isSender ? "ml-20 bg-[#F4F3F0]" : "bg-[#F6F6F6]"}`}
                                    >
                                        <p className="font-Inter leading-[21px] text-[14px]">{parse(toStylelessDocument(message.text))}</p>
                                    </div>
                                    <p
                                        className={`text-[#6D6D6D] text-[12px] font-Inter mt-[5px] mb-[24px] ${message.isSender ? "text-right" : "text-left"}`}
                                    >
                                        {formatDate(message.timestamp)}
                                    </p>
                                </div>
                            );
                        })}

                        <TextField
                            placeholder="Type your message here"
                            multiline
                            rows={1}
                            fullWidth
                            sx={textAreaStyles}
                            onKeyDown={handleKeyDown}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            InputProps={{
                                // startAdornment: (
                                //     <InputAdornment position="start">
                                //         <IconButton onClick={() => {}} edge="start">
                                //             <AttachFile />
                                //         </IconButton>
                                //     </InputAdornment>
                                // ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={isSendButtonDisabled} onClick={handleSendMessage} edge="end">
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </>
                )}
                {isChatLoading && <p className="font-Inter">Fetching messages...</p>}
                {isChatError && <p className="font-Inter">Error loading messages. Please reload the page</p>}
            </div>
        </div>
    );
};
