import { ReactComponent as ArrowIcon } from "@/assets/image/crm/transcript/back-arrow.svg";
import { useState, useEffect } from "react";

export default function EmailDetailsDropdown({ mailData }) {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setIsOpen(false);
    }, [mailData]);
    return (
        <>
            <div className="flex flex-row items-center justify-start gap-1 relative" onClick={() => setIsOpen(!isOpen)}>
                <p className="text-[#888888] text-sm font-Inter">To {mailData?.receiverEmail}</p>
                <ArrowIcon className={`w-5 h-5 transition-all duration-300 ${isOpen ? "rotate-90" : "-rotate-90"}`} />
                {isOpen && (
                    <div className="absolute top-full left-0 w-[448px] bg-white p-4 shadow-lg rounded-lg">
                        <div className="flex flex-col gap-2 text-sm">
                            <div className="flex gap-2 items-center">
                                <span className="text-gray-500 w-[52px] text-xs">from:</span>
                                <div>
                                    <span className="font-medium">{mailData?.senderName}</span>
                                    <span className="text-gray-500"> &lt;{mailData?.senderEmail}&gt;</span>
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <span className="text-gray-500 w-[52px] text-xs">to:</span>
                                <div>
                                    <span className="font-medium">{mailData?.receiverEmail}</span>
                                </div>
                            </div>
                            {mailData?.ccRecepient && (
                                <div className="flex gap-2 items-center">
                                    <span className="text-gray-500 w-[52px] text-xs">cc:</span>
                                    <div>
                                        <span className="font-medium">{mailData?.ccRecepient}</span>
                                    </div>
                                </div>
                            )}
                            {mailData?.bccRecepient && (
                                <div className="flex gap-2 items-center">
                                    <span className="text-gray-500 w-[52px] text-xs">bcc:</span>
                                    <div>
                                        <span className="font-medium">{mailData?.bccRecepient}</span>
                                    </div>
                                </div>
                            )}
                            <div className="flex gap-2 items-center">
                                <span className="text-gray-500 w-[52px] text-xs">date:</span>
                                <span className="font-medium">{mailData?.sentDateAndTime}</span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <span className="text-gray-500 w-[52px] text-xs">subject:</span>
                                <span className="font-medium">{mailData?.subject}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
