import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { headCells } from "./NotesTableHeader"; // Ensure headCells is exported

// Reusable EditableAutocomplete Component
function EditableAutocomplete({ options, value, onChange, onBlur }) {
    return (
        <Autocomplete
            options={options}
            value={value}
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => onChange(newValue)}
            sx={{
                "& .MuiAutocomplete-inputRoot": {
                    padding: "2px 40px 2px 2px !important",
                },
                "& .MuiOutlinedInput-root": {
                    minHeight: "unset",
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    autoFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            border: "1px solid #D1D5DB",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            fontSize: "0.875rem",
                            transition: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                />
            )}
            componentsProps={{
                popper: {
                    sx: {
                        "& .MuiAutocomplete-paper": {
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            fontSize: "0.875rem",
                            fontFamily: "inherit",
                        },
                    },
                },
            }}
            onBlur={onBlur}
        />
    );
}

EditableAutocomplete.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
};

export default function NotesTableRow(props) {
    const { row, isItemSelected, labelId, onSave, focusOnField, opportunityOptions, setSelected, headCells } = props;

    const [editMode, setEditMode] = React.useState({});
    const [editableRow, setEditableRow] = React.useState({});
    const [limitReached, setLimitReached] = React.useState({
        title: false,
        content: false,
    });

    const handleDoubleClick = (field) => {
        if (["createdBy", "createdAt", "updatedAt"].includes(field)) return;
        setEditMode((prev) => ({ ...prev, [field]: true }));
    };

    const handleChange = (field, value) => {
        if (field === "title" && value.length >= 101) {
            setLimitReached((prev) => ({ ...prev, title: true }));
            return;
        }
        if (field === "content" && value.length >= 1001) {
            setLimitReached((prev) => ({ ...prev, content: true }));
            return;
        }

        // Reset limitReached state
        if (limitReached.title || limitReached.content) {
            setLimitReached({ title: false, content: false });
        }
        setEditableRow((prev) => ({ ...prev, [field]: value }));
    };

    const handleBlur = (field) => {
        if (limitReached.title || limitReached.content) {
            setLimitReached({ title: false, content: false });
        }

        if (editableRow[field] === row[field]) return setEditMode((prev) => ({ ...prev, [field]: false }));
        setEditMode((prev) => ({ ...prev, [field]: false }));
        if (onSave) {
            const updatedRow = { ...editableRow };
            onSave(updatedRow);
        }
    };

    React.useEffect(() => {
        if (focusOnField && !editMode[focusOnField]) {
            setEditMode({ [focusOnField]: true });
        }
    }, [focusOnField]);

    React.useEffect(() => {
        setEditableRow(row);
    }, [row]);

    return (
        <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={false}
            sx={{
                background: isItemSelected ? "#F6F6F6" : "white",
                "& .MuiTableCell-root:not(:last-child)": { borderRight: "1px solid #E7E7E7" },
                "& .MuiTableCell-root": {
                    border: "none",
                    borderTop: props.index === 0 ? "none" : 1,
                    borderColor: "#E7E7E7",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
            }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    sx={{
                        "&.Mui-checked": {
                            color: "#2D7A89",
                        },
                    }}
                    onChange={() => {
                        setSelected((prev) => (isItemSelected ? prev.filter((id) => id !== row._id) : [...prev, row._id]));
                    }}
                />
            </TableCell>

            {headCells.map((headCell) => {
                const key = headCell.id;
                if (key === "_id") return null;

                return (
                    <TableCell
                        key={key}
                        align="left"
                        className={editMode[key] && "shadow-lg"}
                        onDoubleClick={() => handleDoubleClick(key)}
                        sx={{
                            "&:hover": {
                                background: "#f4f3f0",
                            },
                        }}
                    >
                        {editMode[key] ? (
                            key === "opportunity" ? (
                                <EditableAutocomplete
                                    options={opportunityOptions}
                                    value={editableRow[key]}
                                    onChange={(value) => handleChange(key, value)}
                                    onBlur={() => handleBlur(key)}
                                />
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        value={editableRow[key]}
                                        onChange={(e) => handleChange(key, e.target.value)}
                                        onBlur={() => handleBlur(key)}
                                        className="outline-none rounded px-2 py-1 w-full"
                                        autoFocus
                                        onKeyDown={(e) => e.key === "Enter" && handleBlur(key)}
                                    />
                                    {key === "title" && limitReached.title && (
                                        <p className="text-[10px] mt-1 text-red-500">Maximum 100 characters are allowed.</p>
                                    )}
                                    {key === "content" && limitReached.content && (
                                        <p className="text-[10px] mt-1 text-red-500">Maximum 1000 characters are allowed.</p>
                                    )}
                                </>
                            )
                        ) : (
                            <p
                                className={`font-Inter text-sm !break-all text-stone-950 outline-none flex items-center gap-2 leading-[1.4]!
                        ${!["createdBy", "createdAt", "updatedAt"].includes(key) && "cursor-pointer "}
                        ${key === "pointOfContact" && !editableRow.company && "!text-gray-600 !cursor-default"}
                      `}
                            >
                                {(key === "createdAt" || key === "updatedAt") && editableRow[key]
                                    ? dayjs(editableRow[key]).format("D MMMM, YYYY")
                                    : key === "opportunity" && editableRow[key]
                                      ? editableRow[key].title
                                      : key === "createdBy" && editableRow[key]
                                        ? editableRow[key].name
                                        : editableRow[key]}
                            </p>
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

NotesTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    isItemSelected: PropTypes.bool.isRequired,
    labelId: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    focusOnField: PropTypes.string,
    opportunityOptions: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    headCells: PropTypes.array.isRequired,
};
