// CRMUploadTableHeader.jsx

import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

function CRMUploadTableHeader(props) {
    const { order, orderBy, headCells, onChangeSorting } = props;

    return (
        <TableHead>
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        bgcolor: "#F4F3F0",
                        color: "#5D5D5D",
                        p: "12px",
                        borderBottom: "1px solid #E7E7E7",
                        borderRight: "1px solid #E7E7E7",
                        ":last-child": {
                            borderRight: "none",
                        },
                    },
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align || "left"}
                        padding="none"
                        width={headCell.width}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={() => onChangeSorting(headCell)}
                            sx={{
                                "&.Mui-active": { color: "#5D5D5D" },
                                "&:hover": { color: "#050505" },
                                "& .MuiSvgIcon-root": { color: "#5D5D5D !important" },
                            }}
                        > */}
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[20px] h-[21px]">{headCell.icon}</div>
                            <div className="text-[#5d5d5d] text-[14px] font-medium flex font-['Inter'] leading-[21px]">
                                {headCell.label}
                            </div>
                        </div>
                        {/* </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default CRMUploadTableHeader;
