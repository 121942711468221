import * as React from "react";
import { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";
import useOpportunities from "@/hooks/crm/useOpportunities";
import SelectComponent from "../SelectComponent";
import PropTypes from "prop-types";
import { headCells } from "./OpportunityTableHeader"; // Import headCells

// Reusable EditableAutocomplete Component
function EditableAutocomplete({ options, value, onChange, onBlur, isContact }) {
    return (
        <Autocomplete
            options={options}
            value={value}
            getOptionLabel={(option) => (isContact ? option.fullName : option.name)}
            onChange={(event, newValue) => onChange(newValue)}
            sx={{
                "& .MuiAutocomplete-inputRoot": {
                    padding: "2px 40px 2px 2px !important",
                },
                "& .MuiOutlinedInput-root": {
                    minHeight: "unset",
                },
                width: "100%",
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    autoFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            border: "1px solid #D1D5DB",
                            borderRadius: "4px",
                            padding: "4px 8px",
                            fontSize: "0.75rem",
                            transition: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        width: "100%",
                    }}
                />
            )}
            componentsProps={{
                popper: {
                    sx: {
                        "& .MuiAutocomplete-paper": {
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "4px",
                            fontSize: "0.875rem",
                            fontFamily: "inherit",
                        },
                    },
                },
            }}
            onBlur={onBlur}
        />
    );
}

EditableAutocomplete.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    isContact: PropTypes.bool,
};

// Reusable EditableDateField Component
function EditableDateField({ value, onChange, onBlur }) {
    const handleDateChange = (newValue) => {
        const selectedDate = newValue ? dayjs(newValue) : null;
        const today = dayjs().startOf("day"); // Start of the current day
        if (selectedDate && selectedDate.isBefore(today)) {
            // Prevent past dates
            onChange(null); // Clear the input or handle it as required
            return;
        }
        onChange(selectedDate ? selectedDate.format("YYYY-MM-DD") : null);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
                value={value ? dayjs(value, "YYYY-MM-DD") : dayjs()}
                onChange={handleDateChange}
                onBlur={onBlur}
                onKeyDown={(e) => e.key === "Enter" && onBlur()}
                format="YYYY-MM-DD"
                minDate={dayjs()}
                slotProps={{
                    textField: {
                        size: "small",
                        fullWidth: true,
                        autoFocus: true,
                        sx: {
                            "& .MuiOutlinedInput-root": {
                                border: "1px solid #D1D5DB",
                                borderRadius: "4px",
                                fontSize: "0.75rem",
                                fontFamily: "inherit",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            width: "100%",
                            "& .MuiInputBase-input": {
                                padding: "4px 8px",
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}

EditableDateField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
};

// Reusable function to display status with styles
function getStatusElement(status) {
    const statusStyles = {
        Prospecting: "bg-gray-100 text-gray-700",
        "Sales Qualified": "bg-blue-100 text-blue-800",
        Proposal: "bg-yellow-100 text-yellow-800",
        Negotiation: "bg-orange-100 text-orange-800",
        Onboarding: "bg-teal-100 text-teal-800",
        "Closed Won": "bg-green-100 text-green-800",
        "Closed Lost": "bg-red-100 text-red-800",
    };
    const style = statusStyles[status] || "bg-gray-100 text-gray-800"; // Default style for unknown status
    return (
        <span className={`py-1.5 px-3 font-medium text-xs rounded ${style}`} style={{ whiteSpace: "nowrap" }}>
            {status}
        </span>
    );
}

OpportunityTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    isItemSelected: PropTypes.bool.isRequired,
    labelId: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    focusOnField: PropTypes.string,
    companyOptions: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    headCells: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
};

export default function OpportunityTableRow(props) {
    const { row, isItemSelected, labelId, onSave, focusOnField, companyOptions, setSelected, headCells, index } = props;

    const [editMode, setEditMode] = useState({});
    const [editableRow, setEditableRow] = useState({});
    const [contactOptions, setContactOptions] = useState([]);
    const [limitReached, setLimitReached] = useState({
        title: false,
        value: false,
    });
    const { getPeople, updatePointOfContact } = useOpportunities();

    const handleDoubleClick = (field) => {
        if (field === "pointOfContact" && !editableRow.company) return;
        if (field !== "createdBy") {
            if (field === "dueDate" && !editableRow[field]) {
                setEditableRow((prev) => ({
                    ...prev,
                    [field]: dayjs().format("YYYY-MM-DD"),
                }));
            }
            setEditMode((prev) => ({ ...prev, [field]: true }));
        }
    };

    const handleChange = (field, value) => {
        if (field === "title" && value.length >= 101) {
            setLimitReached((prev) => ({ ...prev, title: true }));
            return;
        }
        if (field === "value" && (value < 0 || value >= 1000000000000)) {
            setLimitReached((prev) => ({ ...prev, value: true }));
            return;
        }
        // Reset limitReached state
        if (limitReached.title || limitReached.value) {
            setLimitReached({ title: false, value: false });
        }
        setEditableRow((prev) => ({ ...prev, [field]: value }));
    };

    const handleBlur = (field) => {
        // Reset the limitReached state
        if (limitReached.title || limitReached.value) {
            setLimitReached({ title: false, value: false });
        }
        if (editableRow[field] === row[field]) return setEditMode((prev) => ({ ...prev, [field]: false }));
        // Validate dueDate
        if (field === "dueDate" && editableRow[field] && !dayjs(editableRow[field]).isValid()) {
            setEditableRow((prev) => ({ ...prev, dueDate: row.dueDate }));
            setEditMode((prev) => ({ ...prev, [field]: false }));
            return;
        }
        setEditMode((prev) => ({ ...prev, [field]: false }));
        if (onSave) {
            const updatedRow = { ...editableRow };
            onSave(updatedRow);
        }
    };

    const handlePOCBlur = async (field) => {
        if (editableRow[field] === row[field]) return setEditMode((prev) => ({ ...prev, [field]: false }));
        setEditMode((prev) => ({ ...prev, [field]: false }));

        try {
            await updatePointOfContact(row._id, editableRow[field]._id);
        } catch (error) {
            console.error("Error updating point of contact:", error);
        }
    };

    useEffect(() => {
        if (focusOnField && !editMode[focusOnField]) {
            setEditMode({ [focusOnField]: true });
        }
    }, [focusOnField]);

    useEffect(() => {
        setEditableRow(row);
    }, [row]);

    const fetchPeople = async (companyId) => {
        try {
            const people = await getPeople(companyId);
            if (people) {
                setContactOptions(people.data.map((person) => ({ _id: person._id, fullName: `${person.firstName} ${person.lastName}` })));
            }
        } catch (error) {
            console.error("Error fetching people:", error);
        }
    };

    useEffect(() => {
        if (row.company?._id) {
            fetchPeople(row.company._id);
        }
    }, [row.company]);

    useEffect(() => {
        if (editableRow.company?._id) {
            fetchPeople(editableRow.company._id);
        }
    }, [editableRow.company]);

    return (
        <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={false}
            sx={{
                background: isItemSelected ? "#F6F6F6" : "white",
                "& .MuiTableCell-root:not(:last-child)": { borderRight: "1px solid #e7e7e7" },
                "& .MuiTableCell-root": {
                    border: "none",
                    borderTop: index === 0 ? "none" : 1,
                    borderColor: "#e7e7e7",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
            }}
        >
            <TableCell padding="checkbox" sx={{ width: "36px", minWidth: "36px", maxWidth: "36px", padding: 0 }}>
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    sx={{
                        "&.Mui-checked": {
                            color: "#2D7A89",
                        },
                        marginLeft: -1.5,
                    }}
                    inputProps={{ "aria-labelledby": labelId }}
                    onChange={() => {
                        setSelected((prev) => (isItemSelected ? prev.filter((id) => id !== row._id) : [...prev, row._id]));
                    }}
                />
            </TableCell>

            {headCells.map((headCell) => {
                const key = headCell.id;
                if (key === "_id") return null;

                return (
                    <TableCell
                        key={key}
                        align="left"
                        className={editMode[key] ? "shadow-lg" : ""}
                        onDoubleClick={() => handleDoubleClick(key)}
                        sx={{
                            "&:hover": {
                                background: "#f4f3f0",
                            },
                        }}
                    >
                        {editMode[key] ? (
                            key === "company" || key === "pointOfContact" ? (
                                <EditableAutocomplete
                                    options={key === "company" ? companyOptions : contactOptions}
                                    value={editableRow[key] ? editableRow[key] : null}
                                    onChange={(value) => handleChange(key, value)}
                                    onBlur={() => (key === "company" ? handleBlur(key) : handlePOCBlur(key))}
                                    isContact={key === "pointOfContact"}
                                />
                            ) : key === "dueDate" ? (
                                <EditableDateField
                                    value={editableRow[key]}
                                    onChange={(value) => handleChange(key, value)}
                                    onBlur={() => handleBlur(key)}
                                />
                            ) : key === "status" ? (
                                <SelectComponent
                                    options={[
                                        "Prospecting",
                                        "Sales Qualified",
                                        "Proposal",
                                        "Negotiation",
                                        "Onboarding",
                                        "Closed Won",
                                        "Closed Lost",
                                    ]}
                                    onBlur={() => handleBlur(key)}
                                    id="opp-select"
                                    selectedValue={editableRow[key]}
                                    onSelectionChange={(e) => handleChange(key, e.target.value)}
                                    bgColor="#fff"
                                    sx={{ width: "100%", borderRadius: "4px" }}
                                />
                            ) : (
                                <>
                                    <input
                                        type={key === "value" ? "number" : "text"}
                                        value={editableRow[key]}
                                        onChange={(e) => handleChange(key, e.target.value)}
                                        onBlur={() => handleBlur(key)}
                                        className={`outline-none py-1 w-full ${key === "value" && "opportunity-amount"}`}
                                        autoFocus
                                        onKeyDown={(e) => e.key === "Enter" && handleBlur(key)}
                                    />
                                    {key === "title" && limitReached.title && (
                                        <p className="text-[10px] mt-1 text-red-500">Maximum 100 characters are allowed.</p>
                                    )}
                                    {key === "value" && limitReached.value && (
                                        <p className="text-[10px] mt-1 text-red-500">Maximum 999,999,999,999 amount is allowed.</p>
                                    )}
                                </>
                            )
                        ) : (
                            <p
                                className={`font-Inter text-sm text-stone-950 outline-none break-all flex items-center gap-2 leading-[1.4]
                        ${key !== "createdBy" ? "cursor-pointer" : ""}
                        ${key === "pointOfContact" && !editableRow.company ? "!text-gray-600 !cursor-default" : ""}`}
                            >
                                {key === "company" && editableRow[key] && (
                                    <img
                                        className="h-5 w-5 rounded-md"
                                        src={`https://logo.clearbit.com/${editableRow[key].website}`}
                                        alt="company"
                                        onError={(e) => (e.target.style.display = "none")}
                                    />
                                )}

                                {key === "pointOfContact" && !editableRow.company
                                    ? "No Company"
                                    : key === "dueDate" && editableRow[key]
                                      ? dayjs(editableRow[key]).format("D MMMM, YYYY")
                                      : key === "company" && editableRow[key]
                                        ? editableRow[key].name
                                        : key === "status" && editableRow[key]
                                          ? getStatusElement(editableRow[key])
                                          : key === "pointOfContact" && editableRow[key]
                                            ? editableRow[key].fullName
                                            : key === "createdBy" && editableRow[key]
                                              ? editableRow[key].name
                                              : key === "value" && editableRow[key]
                                                ? `$${Number(editableRow[key])?.toLocaleString()}`
                                                : editableRow[key]}
                            </p>
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
