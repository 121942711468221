import BackdropLoader from "@/components/common/BackdropLoader";
import DateRange from "@/components/common/DateRange";
import HtmlEditor from "@/components/common/HtmlEditor";
import SelectDropdown from "@/components/ai_engagement/inbox/SelectDropdown";
import { WarmupModal } from "@/components/email_warmup/summary/WarmupModal";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";
import RelativeModal from "./RelativeModal";
import {
    CampainSvg,
    Compose,
    Engaged,
    FilterSvg,
    ForwardSvg,
    ImportantIcon,
    NoPreview,
    ReloadEmails,
    ReplySvg,
    Sent,
    UnReadIcon,
    CampaignIcon,
    EmailAccountIcon,
    DateRangeIcon,
    BackIcon,
} from "@/components/icons/InboxIcon";
import useAiEngagement from "@/hooks/useAIEngagement";
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import NoMailBoxButton from "../NoMailBoxButton";
import { toStylelessDocument } from "@/utils/common";
import EmailInput from "./EmailInput";
import EmailDetailsDropdown from "./EmailDetailsDropdown";

const initialDefaultEmail = {
    emailBody: "",
    emailSubject: "",
    toEmail: "",
    from: "",
    ccEmail: "",
    bccEmail: "",
};

const initialState = {
    activeState: "engaged",
    apiEndPoint: "inbox",
    isFilter: false,
    loading: false,
    mailData: {},
    mailList: [],
    fetchEmails: false,
    appendProperty: false,
    currentPageNo: 1,
    isMoreDataToLoad: true,
    isComposeEmail: false,
    emailBody: "",
    isCCOn: false,
    isBCCOn: false,
    currentSendType: "",
    scheduledDateTime: null,
    draftMailData: {
        ...initialDefaultEmail,
    },
};

const defaultColor = ["#eaf180", "#fdce94", "#fdcf8c", "#dbd186", "#e6d788", "#eaef76", "#b4eeb4", "#cfead9", "#ffb2d7", "#cbb2ff"];

const stringCheck = (str) => {
    let i = 0;
    while (i < str.length) {
        if (/[a-zA-Z]/.test(str[i])) {
            return str[i];
        }
        i++;
    }
    return false;
};

const retrieveStringName = (senderName) => {
    const splitedName = (senderName || "").split(" ");
    let firstChar = "",
        secondChar = "";
    if (splitedName[0]) {
        const reult = stringCheck(splitedName[0]);
        if (reult) {
            firstChar = reult;
        }
    }
    if (splitedName[1]) {
        const reult = stringCheck(splitedName[1]);
        if (reult) {
            secondChar = reult;
        }
    }

    return firstChar + secondChar;
};

const retrieveEmailAddressFromName = (name) => {
    const startingIndex = (name || "").indexOf("<") || "";
    const endingIndex = (name || "").indexOf(">") || "";

    return (name || "").substring(startingIndex + 1, endingIndex);
};

function formatDate(dateString, type = "both") {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
    const monthNumber = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear(); // Get the year in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const period = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    return type === "dateonly" ? `${year}-${monthNumber}-${day}` : `${day} ${month}, ${hour12}:${minutes} ${period}`;
}

const initialSearchState = {
    dateRang: [null, null],
    campainId: "",
    emailAddress: "",
    emailList: [],
    campainList: [],
};
const Inbox = () => {
    const { getEmailListAccordingToType, sendEmail, getEmailsData, getEmailCampain, reFetchAllEmail, markEmailAsRead } = useAiEngagement();
    const [state, setState] = useState(initialState);
    const [openFilter, setOpenFilter] = useState(false);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const [fiterOption, setFilterOption] = useState(initialSearchState);
    const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
    const { dateRang, emailAddress, emailList, campainList } = fiterOption || {};
    const {
        activeState,
        loading,
        mailList,
        mailData,
        apiEndPoint,
        isMoreDataToLoad,
        fetchEmails,
        currentPageNo,
        isComposeEmail,
        draftMailData,
        isBCCOn,
        isCCOn,
        currentSendType,
        isFilter,
        scheduledDateTime,
    } = state || {};

    const { emailBody, toEmail, ccEmail, bccEmail, emailSubject, from } = draftMailData || {};

    const setActiveStateData = (type, endPoint) => {
        setState((prevState) => ({
            ...prevState,
            activeState: type,
            apiEndPoint: endPoint,
            mailList: [],
            mailData: {},
            fetchEmails: true,
            appendProperty: false,
            currentPageNo: 1,
            isMoreDataToLoad: true,
        }));
    };

    const convertInStandardStructureData = (emailArray = []) => {
        let updatedEmailList = [];
        (emailArray || [])?.forEach((i) => {
            let updateObj = {};
            const cName = (campainList || [])?.find((e) => e._id === i?.campaignId)?.campaignName || "";
            if (i?.emailAccountType !== "gmail") {
                updateObj = {
                    id: i?._id,
                    senderEmail: i?.sender?.emailAddress?.address || "",
                    senderName: i?.sender?.emailAddress?.name || "",
                    subject: i?.subject,
                    sentDateAndTime: formatDate(i?.sentDateTime ?? ""),
                    bodyText: i?.body.content,
                    bgColor: defaultColor[Math.floor(Math.random() * 10)],
                    receiverEmail:
                        i?.toRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    ccRecepient:
                        i?.ccRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    bccRecepient:
                        i?.bccRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    campaignName: cName ? cName : "",
                    campaignId: i?.campaignId ? i?.campaignId : "",
                    shortName: retrieveStringName(i?.sender?.emailAddress?.address || ""),
                    isGmail: false,
                    isRead: i?.isRead === true || false,
                };
            } else {
                const result = retrieveEmailAddressFromName(i?.headers?.from);
                updateObj = {
                    id: i?._id,
                    senderEmail: result || "",
                    senderName: i?.headers?.from || "",
                    subject: i?.headers?.subject,
                    sentDateAndTime: formatDate(i?.headers?.date ?? ""),
                    bodyText: i?.body,
                    bgColor: defaultColor[Math.floor(Math.random() * 10)],
                    receiverEmail:
                        i?.toRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    ccRecepient:
                        i?.ccRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    bccRecepient:
                        i?.bccRecipients
                            ?.map((r) => r?.emailAddress?.address)
                            .filter(Boolean)
                            .join(", ") || "",
                    campaignName: cName ? cName : "",
                    campaignId: i?.campaignId ? i?.campaignId : "",
                    shortName: retrieveStringName(i?.headers?.from || ""),
                    isGmail: true,
                    isRead: i?.isRead === true || false,
                };
            }
            updatedEmailList.push(updateObj);
        });
        return updatedEmailList;
    };

    const fetchEmailListAccordingToType = async () => {
        try {
            // setState((prevState) => ({ ...prevState, fetchEmails: true }));
            let limit = Math.round(window.innerHeight / 64);
            const response = await getEmailListAccordingToType({
                queryString: `?page=${currentPageNo}&limit=${limit}&campaignId=&mailBoxAccount=${emailAddress}&fromDate=${dateRang?.[0] ? formatDate(dateRang?.[0], "dateonly") : ""}&toDate=${dateRang?.[1] ? formatDate(dateRang?.[1], "dateonly") : ""}&is_gmail=${emailAddress?.split("@")[1] ? emailAddress?.split("@")[1] : ""}&filter=${isFilter}`,
                endPoint: apiEndPoint,
            });
            if ((response?.data?.emails || [])?.length > 0) {
                const updatedList = convertInStandardStructureData(response?.data?.emails);
                setState((prevState) => {
                    let updatedEmailList = cloneDeep(updatedList);

                    if (prevState?.appendProperty) {
                        updatedEmailList = (prevState?.mailList || []).concat(updatedEmailList || []);
                    }

                    return {
                        ...prevState,
                        mailList: updatedEmailList || [],
                        isMoreDataToLoad: prevState.currentPageNo < response?.data?.totalPages,
                    };
                });
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, fetchEmails: false }));
        }
    };

    const reFetchEmails = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await reFetchAllEmail();
            if (response?.status === true) {
                toast.success(response?.message, {
                    theme: "colored",
                });
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false, fetchEmails: true }));
        }
    };

    const mailSend = async () => {
        if (!toEmail) {
            toast.error("Recipient email is required.", {
                theme: "colored",
            });
            return;
        }
        if (!emailSubject) {
            toast.error("Email subject is required.", {
                theme: "colored",
            });
            return;
        }
        const fromEmail = currentSendType === "forward" ? mailData?.reciverEmail : from;
        if (!fromEmail) {
            toast.error("Sender is required.", {
                theme: "colored",
            });
            return;
        }

        try {
            const isGmail = emailList.find((emailAcc) => emailAcc?.emailAddress === fromEmail).account === "gmail";
            const mailInfo = {
                from: fromEmail,
                to: [...toEmail],
                cc: [...ccEmail],
                bcc: [...bccEmail],
                subject: emailSubject,
                content: emailBody,
                is_gmail: isGmail,
                scheduledDateTime: scheduledDateTime ? scheduledDateTime.toISOString() : undefined,
            };
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await sendEmail(mailInfo);

            if (response?.status === true) {
                toast.success(response?.message, {
                    theme: "colored",
                });
                setState((prevState) => ({
                    ...prevState,
                    isComposeEmail: false,
                    isBCCOn: false,
                    isCCOn: false,
                    draftMailData: { ...initialDefaultEmail },
                }));
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });

                // Wait for 2 seconds before re-fetching emails
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await reFetchEmails();
            }
        } catch (error) {
            console.log("Error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const fetchEmailList = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const listData = await getEmailsData();
            if (listData.data && !listData.data.length > 0) {
                setIsNoMailBox(true);
            }
            let updatedListData = listData?.data;
            if (listData?.data || false) {
                setFilterOption((prevState) => {
                    return {
                        ...prevState,
                        emailList: updatedListData || [],
                    };
                });
            }
            if (updatedListData) {
                setState((prevState) => ({
                    ...prevState,
                    draftMailData: { ...prevState.draftMailData, from: updatedListData[0]?.emailAddress },
                }));
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const fetchEmailCampain = async () => {
        setOpenFilter(true);
        try {
            // setState((prevState) => ({ ...prevState, loading: true }));
            const listData = await getEmailCampain();
            if (listData?.data?.response || false) {
                setFilterOption((prevState) => {
                    let updatedListData = listData?.data?.response;
                    return {
                        ...prevState,
                        campainList: updatedListData || [],
                    };
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            // setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const scrollEvent = (e) => {
        if (e?.currentTarget?.contains(e?.target)) {
            const tolerance = 1; // Allowable tolerance in pixels
            const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= tolerance;
            if (bottom && isMoreDataToLoad && fetchEmails === false) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        fetchEmails: true,
                        appendProperty: true,
                        currentPageNo: prevState.currentPageNo + 1,
                    };
                });
            }
        }
    };

    const clearScheduledTimeAndCloseModal = () => {
        setOpenDateTimePicker(false);
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, scheduledDateTime: null }));
        }, 1000);
    };

    const handleMailClick = async (mailData) => {
        if (mailData?.isRead === false) {
            mailData.isRead = true;
            await markEmailAsRead(mailData);
        }
        setState((prevState) => ({
            ...prevState,
            mailData: { ...mailData, bodyText: mailData?.bodyText },
            isComposeEmail: false,
        }));
    };

    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const [isCampaignOpen, setIsCampaignOpen] = useState(false);

    const fetchEmailListRef = useRef();
    fetchEmailListRef.current = fetchEmailList;
    const fetchEmailListAccordingToTypeRef = useRef();
    fetchEmailListAccordingToTypeRef.current = fetchEmailListAccordingToType;

    useEffect(() => {
        if (fetchEmails) {
            fetchEmailListAccordingToTypeRef.current();
        }
    }, [fetchEmails]);

    useEffect(() => {
        fetchEmailListRef.current();
        fetchEmailListAccordingToTypeRef.current();
    }, []);

    return (
        <>
            {loading ? (
                <BackdropLoader active={true} />
            ) : isNoMailBox ? (
                <NoMailBoxButton />
            ) : (
                <div className="grid grid-cols-[264px_1fr_1fr_1fr_1fr_1fr] gap-0">
                    <div className="h-full border-r border-[#E7E7E7] bg-white col-span-1 sm:mb-1">
                        <div className="border-b border-[#E7E7E7] flex items-center justify-center px-[24px] py-[16px] h-[76px]">
                            <button
                                className="w-full bg-[#090C05] cursor-pointer flex items-center justify-center px-[24px] py-[12px] rounded-[8px] gap-[8px] hover:opacity-80 transition-opacity"
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isComposeEmail: true,
                                        currentSendType: "new",
                                        draftMailData: { ...initialDefaultEmail },
                                    }))
                                }
                            >
                                <Compose width={20} height={20} />
                                <span className="text-white font-Inter text-[14px] font-medium">Compose Email</span>
                            </button>
                        </div>
                        <div className="flex flex-col px-[24px] py-[12px] gap-[12px] mailList_container">
                            <button
                                className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] hover:bg-[#F4F3F0] transition-colors rounded-[8px] ${activeState === "engaged" ? "bg-[#F4F3F0]" : ""}`}
                                onClick={() => setActiveStateData("engaged", "inbox")}
                            >
                                <Engaged />
                                <span className="main-text-black-500">Inbox</span>
                            </button>
                            <button
                                className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] hover:bg-[#F4F3F0] transition-colors rounded-[8px] ${activeState === "unread" ? "bg-[#F4F3F0]" : ""}`}
                                onClick={() => setActiveStateData("unread", "unread")}
                            >
                                <UnReadIcon />
                                <span className="text-[14px] font-medium font-Inter text-accents-mainBlack">Unread Replies</span>
                            </button>
                            <button
                                className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] hover:bg-[#F4F3F0] transition-colors rounded-[8px] ${activeState === "sent" ? "bg-[#F4F3F0]" : ""}`}
                                onClick={() => setActiveStateData("sent", "sent")}
                            >
                                <Sent />
                                <span className="text-[14px] font-medium font-Inter text-accents-mainBlack"> Sent</span>
                            </button>
                            <button
                                className={`h-[36px] w-full px-[12px] flex items-center gap-[8px] hover:bg-[#F4F3F0] transition-colors rounded-[8px] ${activeState === "important" ? "bg-[#F4F3F0]" : ""}`}
                                onClick={() => setActiveStateData("important", "important")}
                            >
                                <ImportantIcon />
                                <span className="text-[14px] font-medium font-Inter text-accents-mainBlack">Important</span>
                            </button>
                            {/* <p className={`mail_service${activeState === "snoozed" ? " active" : ""}`} onClick={() => setActiveStateData("snoozed", "snoozed")}><Snoozed />Snoozed</p> */}
                            {/* <p className={`mail_service${activeState === "reminder" ? " active" : ""}`} onClick={() => setActiveStateData("reminder", "inbox")}><Reminder />Reminders</p> */}
                            {/* <p className={`mail_service${activeState === "schedule" ? " active" : ""}`} onClick={() => setActiveStateData("schedule", "scheduled")}><Scheduled />Scheduled</p> */}
                            {/* <p className={`mail_service${activeState === "archived" ? " active" : ""}`} onClick={() => setActiveStateData("archived", "archived")}><Archived />Archived</p> */}
                        </div>
                    </div>

                    <div className="mb-4 h-full border-r border-[#E7E7E7] sm:mb-1 col-span-2">
                        <div className="border-b border-[#E7E7E7] px-[24px] h-[76px] flex items-center justify-between">
                            <span className="text-accents-mainBlack text-[14px] font-medium font-Inter">Unibox</span>
                            {/* <span onClick={() => setOpenFilter(true)}><FilterSvg /></span> */}
                            <div className="flex gap-[16px] items-center">
                                <button
                                    className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center"
                                    onClick={() => reFetchEmails()}
                                >
                                    <ReloadEmails />
                                </button>
                                <div className="relative w-[20px] h-[20px] flex items-center justify-center">
                                    <button className="cursor-pointer" onClick={() => fetchEmailCampain()}>
                                        <FilterSvg />
                                    </button>
                                    <RelativeModal open={openFilter} onClose={() => setOpenFilter(false)} className="min-w-[377px] inbox">
                                        <div className="px-[24px] py-[16px] w-full flex items-center justify-between">
                                            <span className="text-accents-mainBlack text-[14px] font-semibold font-Inter">
                                                Filter Options
                                            </span>
                                            <button
                                                className="cursor-pointer text-[#2D7A89] text-[14px] hover:text-accents-mainGreen transition-colors font-medium font-Inter"
                                                onClick={() =>
                                                    setFilterOption((prevState) => ({
                                                        ...prevState,
                                                        campainId: "",
                                                        emailAddress: "",
                                                        dateRang: [null, null],
                                                    }))
                                                }
                                            >
                                                Clear
                                            </button>
                                        </div>
                                        <div className="flex flex-col items-center justify-center gap-4 pt-[24px]">
                                            <div className="w-full px-[24px]">
                                                <div className="mb-2 flex items-center gap-[8px]">
                                                    <CampaignIcon />
                                                    <p className="text-[14px] font-medium font-Inter ">Email Campaign</p>
                                                </div>
                                                <div className="relative w-full">
                                                    <div
                                                        className="border rounded-md px-3 pt-2 pb-3 flex justify-between items-center cursor-pointer"
                                                        style={{ borderColor: "#E8E7E7" }}
                                                        onClick={() => setIsCampaignOpen((prev) => !prev)}
                                                    >
                                                        <span className="text-gray-700 font-Inter text-[14px]">
                                                            {campainList?.find((e) => e._id === fiterOption.campainId)?.campaignName}
                                                        </span>
                                                        <svg
                                                            className="h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                    {isCampaignOpen && campainList?.length && (
                                                        <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg z-10">
                                                            {campainList.map(
                                                                (option, index) =>
                                                                    option?._id && (
                                                                        <li
                                                                            key={index}
                                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-Inter text-[14px]"
                                                                            onClick={() => {
                                                                                setFilterOption((prevState) => ({
                                                                                    ...prevState,
                                                                                    campainId: option?._id,
                                                                                }));
                                                                                setIsCampaignOpen(false);
                                                                            }}
                                                                        >
                                                                            {option?.campaignName}
                                                                        </li>
                                                                    )
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full px-[24px]">
                                                <div className="mb-2 flex items-center gap-[8px]">
                                                    <EmailAccountIcon />
                                                    <p className="text-[14px] font-medium font-Inter ">Email Account</p>
                                                </div>
                                                <div className="relative w-full">
                                                    <div
                                                        className="border rounded-md px-3 pt-2 pb-3 flex justify-between items-center cursor-pointer"
                                                        style={{ borderColor: "#E8E7E7" }}
                                                        onClick={() => setIsEmailOpen((prev) => !prev)}
                                                    >
                                                        <span className="text-gray-700 font-Inter text-[14px]">
                                                            {fiterOption?.emailAddress}
                                                        </span>
                                                        <svg
                                                            className="h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                    {isEmailOpen && emailList?.length && (
                                                        <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg z-10">
                                                            {emailList.map(
                                                                (option, index) =>
                                                                    option?.emailAddress && (
                                                                        <li
                                                                            key={index}
                                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-Inter text-[14px]"
                                                                            onClick={() => {
                                                                                setFilterOption((prevState) => ({
                                                                                    ...prevState,
                                                                                    emailAddress: option?.emailAddress,
                                                                                }));
                                                                                setIsEmailOpen(false);
                                                                            }}
                                                                        >
                                                                            {option?.emailAddress}
                                                                        </li>
                                                                    )
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full px-[24px]">
                                                <div className="mb-2 flex items-center gap-[8px]">
                                                    <DateRangeIcon />
                                                    <p className="text-[14px] font-medium font-Inter ">Reply Date Range</p>
                                                </div>
                                                <DateRange
                                                    value={dateRang}
                                                    onChange={(value) =>
                                                        setFilterOption((prevState) => ({ ...prevState, dateRang: value }))
                                                    }
                                                    downIcon={true}
                                                />
                                            </div>
                                            <div className="w-full p-[16px]">
                                                <button
                                                    className="capitalize w-full bg-accents-mainBlack h-[48px] px-[24px] py-[12px] rounded-[8px] text-white text-inter text-[14px] font-medium hover:opacity-80 transition-opacity"
                                                    onClick={() => {
                                                        setState((prevState) => ({
                                                            ...initialState,
                                                            activeState: prevState?.activeState,
                                                            apiEndPoint: prevState?.apiEndPoint,
                                                            isFilter: true,
                                                            fetchEmails: true,
                                                        }));
                                                        // fetchEmailListAccordingToType()
                                                        setOpenFilter(false);
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </RelativeModal>
                                </div>
                            </div>
                        </div>
                        <div className="mailList_container" onScrollCapture={scrollEvent}>
                            {(mailList || []).map((i, ind) => {
                                return (
                                    <div
                                        className={`p-[24px] flex gap-[8px] w-full cursor-pointer border-b border-[#E8E7E7] hover:bg-[#F4F3F0] ${i?.isRead ? "bg-[#F4F3F0]" : "bg-white"}`}
                                        key={ind}
                                        onClick={() => handleMailClick(i)}
                                    >
                                        <div>
                                            <div
                                                style={{ backgroundColor: i?.bgColor }}
                                                className="w-[28px] h-[28px] rounded-full flex items-center justify-center capitalize text-[12px] text-accents-mainBlack"
                                            >
                                                {i?.shortName ?? ""}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[8px] grow">
                                            {i?.senderName && (
                                                <div className="h-[28px] flex items-center">
                                                    <p
                                                        className={`${i?.isRead ? "text-[14px] font-medium font-Inter text-accents-mainBlack" : "sender"}`}
                                                    >
                                                        {i?.senderName || ""}
                                                    </p>
                                                </div>
                                            )}
                                            <div className="min-h-[28px] flex items-center">
                                                <p
                                                    className={`max-w-[255px] text-[14px] font-medium font-Inter text-accents-mainBlack title`}
                                                >
                                                    {i?.subject || ""}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`flex flex-col items-end ${i?.campaignName ? "justify-between" : "justify-end"}`}>
                                            {i?.campaignName && (
                                                <div className="rounded-[4px] bg-[#D3E1DB] p-[4px] flex gap-[8px]">
                                                    <CampainSvg />
                                                    <span className="font-Inter text-[10px] text-accents-mainBlack" title={i?.campaignName}>
                                                        {i?.campaignName || "Campaign"}
                                                    </span>
                                                </div>
                                            )}
                                            <span className="text-[#5D5D5D] text-[12px] font-Inter self-end">
                                                {i?.sentDateAndTime || ""}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                            {fetchEmails && (
                                <div className="w-full mt-10 flex justify-center text-[14px] font-Inter">Fetching Email Data ...</div>
                            )}
                            {/* <Pagination
                                className="font-Inter"
                                count={Math.ceil(mailData.length / 10)}
                                page={page}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                                boundaryCount={1}
                                siblingCount={0}
                                renderItem={(item) => (
                                    <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />
                                )}
                                sx={{
                                    "& .MuiPaginationItem-text": {
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: 1.43,
                                        color: "#090C05",
                                    },
                                    "& .MuiPaginationItem-text.Mui-selected": {
                                        color: "#fff",
                                        backgroundColor: "black",
                                        "&:hover": {
                                            backgroundColor: "gray",
                                        },
                                    },
                                }}
                            /> */}
                        </div>
                    </div>

                    {isComposeEmail && (
                        <div className="w-full col-span-3">
                            <div
                                className="flex p-[24px] h-[76px] items-center gap-[4px]"
                                onClick={() =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        isComposeEmail: false,
                                        currentSendType: "new",
                                        draftMailData: { ...initialDefaultEmail },
                                    }))
                                }
                            >
                                <BackIcon />
                                <span className="cursor-pointer text-[#2D7A89] text-[14px] font-medium font-Inter">Back</span>
                            </div>
                            <div className="mailList_container">
                                <div className="px-[24px] py-[16px] flex justify-between items-center">
                                    <p
                                        className=" text-accents-mainBlack text-[16px] font-semibold font-Inter w-full truncate"
                                        title={mailData?.subject || ""}
                                    >
                                        {mailData?.subject || ""}
                                    </p>
                                    <div className="flex justify-start w-fit gap-2">
                                        <button
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...initialDefaultEmail,
                                                        from: mailData?.reciverEmail,
                                                        toEmail: mailData?.senderEmail,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <ReplySvg iconColor={"#050505"} />
                                        </button>
                                        <button
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...prevState.draftMailData,
                                                        from: mailData?.reciverEmail,
                                                        emailBody: mailData?.bodyText,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <ForwardSvg iconColor={"#050505"} />
                                        </button>
                                    </div>
                                </div>
                                {mailData?.senderName && (
                                    <div className="flex p-[24px] justify-between items-center gap-2">
                                        <div className="flex flex-row justify-start items-center gap-[12px]">
                                            <div
                                                style={{ backgroundColor: mailData?.bgColor }}
                                                className="w-[28px] h-[28px] rounded-full flex items-center justify-center capitalize text-[12px] text-accents-mainBlack"
                                            >
                                                {mailData?.shortName ?? ""}
                                            </div>
                                            <p className="text-accents-mainBlack text-[16px] font-semibold font-Inter">
                                                {mailData?.senderName}
                                            </p>
                                        </div>
                                        <div className="flex flex-row items-center justify-start gap-[2px] font-Inter">
                                            <span className="text-[12px] text-[#6D6D6D] capitalize">
                                                {mailData?.sentDateAndTime?.split(",")[0]}
                                            </span>
                                            <span>,</span>
                                            <span className="text-[12px] text-[#6D6D6D] uppercase">
                                                {mailData?.sentDateAndTime?.split(",")[1]}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className="w-full p-[24px]">
                                    <div className="w-full h-full rounded-[8px] border border-[#E7E7E7]">
                                        <div className="px-[16px] py-[12px] w-full bg-[#f6f6f6]">
                                            <p className="text-accents-mainBlack text-[16px] font-semibold font-Inter">New Email</p>
                                        </div>
                                        <div className="w-full py-[24px]">
                                            <div className="min-h-[38px] border-b border-[#E7E7E7] flex justify-between px-[16px] items-center">
                                                <div className="h-full flex w-full items-center">
                                                    <span className="text-[#888] text-[14px] font-medium font-Inter">To</span>
                                                    <div className="h-full w-full">
                                                        <EmailInput
                                                            onEmailsChange={(updatedEmails) =>
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    draftMailData: { ...prevState.draftMailData, toEmail: updatedEmails },
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex justify-end gap-x-3 items-center p-1 pr-2">
                                                    <span
                                                        className={`cursor-pointer text-[#888] text-[14px] font-medium font-Inter ${isCCOn ? "!font-bold" : ""}`}
                                                        onClick={() => setState((prevState) => ({ ...prevState, isCCOn: !isCCOn }))}
                                                    >
                                                        Cc
                                                    </span>
                                                    <span
                                                        className={`cursor-pointer text-[#888] text-[14px] font-medium font-Inter ${isBCCOn ? "!font-bold" : ""}`}
                                                        onClick={() => setState((prevState) => ({ ...prevState, isBCCOn: !isBCCOn }))}
                                                    >
                                                        Bcc
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="h-[38px] border-b border-[#E7E7E7] flex px-[16px] items-center">
                                                <span className="text-[#888] text-[14px] font-medium font-Inter">From</span>
                                                <div className="p-0">
                                                    <SelectDropdown
                                                        options={
                                                            emailList?.length
                                                                ? (emailList || []).map((i) => {
                                                                      return {
                                                                          label: i?.emailAddress,
                                                                          value: i?.emailAddress,
                                                                      };
                                                                  })
                                                                : []
                                                        }
                                                        selectedOption={from}
                                                        disabled={currentSendType === "forward"}
                                                        onChange={(fromEmail) =>
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                draftMailData: { ...prevState.draftMailData, from: fromEmail },
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {isCCOn && (
                                                <div className="min-h-[38px] border-b border-[#E7E7E7] flex items-center px-[16px]">
                                                    <span className="text-[#888] text-[14px] font-medium font-Inter">CC</span>
                                                    <div className="h-full w-full">
                                                        <EmailInput
                                                            onEmailsChange={(updatedEmails) =>
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    draftMailData: { ...prevState.draftMailData, ccEmail: updatedEmails },
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {isBCCOn && (
                                                <div className="min-h-[38px] border-b border-[#E7E7E7] flex items-center px-[16px]">
                                                    <span className="text-[#888] text-[14px] font-medium font-Inter">BCC</span>
                                                    <div className="h-full w-full">
                                                        <EmailInput
                                                            onEmailsChange={(updatedEmails) =>
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    draftMailData: { ...prevState.draftMailData, bccEmail: updatedEmails },
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="h-[38px] border-b border-[#E7E7E7] flex items-center px-[16px]">
                                                <span className="text-[#888] text-[14px] font-medium font-Inter">Subject</span>
                                                <div className="h-full w-full">
                                                    <input
                                                        className="w-full focus:outline-none items-center main-text-black-500 pl-3 h-full"
                                                        value={emailSubject}
                                                        onChange={(e) =>
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                draftMailData: { ...prevState.draftMailData, emailSubject: e.target.value },
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="min-h-[208px]">
                                            <HtmlEditor
                                                value={emailBody}
                                                onChange={(value) =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        draftMailData: { ...prevState.draftMailData, emailBody: value },
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="mt-[14px] w-[117px] h-[36px] flex items-center justify-center bg-accents-mainBlack rounded-[8px] hover:opacity-80 transition-opacity"
                                        onClick={() => mailSend("new")}
                                    >
                                        <span className="font-Inter text-[14px] font-medium text-white">Send</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {Object.keys(mailData || {})?.length && !isComposeEmail ? (
                        <div className="w-full col-span-3">
                            <div
                                className="flex p-[24px] h-[76px] items-center gap-[4px]"
                                onClick={() => setState((prevState) => ({ ...prevState, mailData: {} }))}
                            >
                                <BackIcon />
                                <span className="cursor-pointer text-[#2D7A89] text-[14px] font-medium font-Inter">Back</span>
                            </div>
                            <div className="mailList_container">
                                <div className="px-[24px] py-[16px] flex justify-between items-center">
                                    <p
                                        className=" text-accents-mainBlack text-[16px] font-semibold font-Inter w-full truncate"
                                        title={mailData?.subject || ""}
                                    >
                                        {mailData?.subject || ""}
                                    </p>
                                    <div className="flex justify-start w-fit gap-2">
                                        <button
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...initialDefaultEmail,
                                                        from: mailData?.reciverEmail,
                                                        toEmail: mailData?.senderEmail,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <ReplySvg iconColor={"#050505"} />
                                        </button>
                                        <button
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...prevState.draftMailData,
                                                        from: mailData?.reciverEmail,
                                                        emailBody: mailData?.bodyText,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <ForwardSvg iconColor={"#050505"} />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    {mailData?.senderName && (
                                        <div className="flex p-[24px] justify-between items-center gap-2">
                                            <div className="flex flex-row justify-start items-center gap-[12px]">
                                                <div
                                                    style={{ backgroundColor: mailData?.bgColor }}
                                                    className="w-[48px] h-[48px] rounded-lg flex items-center justify-center capitalize text-[12px] text-accents-mainBlack"
                                                >
                                                    {mailData?.shortName ?? ""}
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className="text-accents-mainBlack text-[16px] font-semibold font-Inter">
                                                        {mailData?.senderName ?? ""}
                                                    </p>
                                                    <EmailDetailsDropdown mailData={mailData} />
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center justify-start gap-[2px] font-Inter">
                                                <span className="text-[12px] text-[#6D6D6D] capitalize">
                                                    {mailData?.sentDateAndTime?.split(",")[0]},
                                                </span>
                                                <span className="text-[12px] text-[#6D6D6D] uppercase">
                                                    {mailData?.sentDateAndTime?.split(",")[1]}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-[24px] text-[14px] font-Inter break-words">
                                        {parse(toStylelessDocument(mailData.bodyText))}
                                    </div>
                                    <div className="p-[24px] flex gap-2">
                                        <button
                                            className="w-[101px] h-[36px] border border-accents-mainBlack flex items-center justify-center gap-[8px] rounded-[8px] bg-accents-mainBlack hover:opacity-80 transition-opacity"
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...initialDefaultEmail,
                                                        from: mailData?.reciverEmail,
                                                        toEmail: mailData?.senderEmail,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <ReplySvg iconColor={"white"} />
                                            <span className="text-white font-medium text-[14px] font-Inter">Reply</span>
                                        </button>
                                        <button
                                            className="w-[117px] h-[36px] border border-[#E7E7E7] flex items-center justify-center gap-[8px] rounded-[8px] bg-white hover:bg-stone-50 transition-colors"
                                            onClick={() =>
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isComposeEmail: true,
                                                    currentSendType: "forward",
                                                    draftMailData: {
                                                        ...prevState.draftMailData,
                                                        from: mailData?.reciverEmail,
                                                        emailBody: mailData?.bodyText,
                                                        emailSubject: mailData?.subject,
                                                    },
                                                }))
                                            }
                                        >
                                            <span className="text-accents-mainBlack font-medium text-[14px] font-Inter">Forward</span>
                                            <ForwardSvg iconColor={"#050505"} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : isComposeEmail === false ? (
                        <div className="mb-4 h-full border-r border-[#E7E7E7] pt-4 sm:mb-1 p-2 col-span-3 mailList_container">
                            <div className="no_mail_found flex justify-center items-center">
                                <NoPreview />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
            <WarmupModal
                open={openDateTimePicker}
                onClose={clearScheduledTimeAndCloseModal}
                titleClassName="w-full border-b-2 border-gray-300"
                title={<div>Schedule Reply</div>}
            >
                <div className="h-72">
                    <DatePicker
                        shouldCloseOnSelect={false}
                        open={true}
                        timeIntervals={5}
                        showTimeSelect
                        dateFormat="MM-dd-yyyy hh:mm a"
                        startDate={new Date()}
                        selected={new Date(scheduledDateTime || "")}
                        timeFormat="hh:mm a"
                        value={scheduledDateTime}
                        onChange={(value) => setState((prevState) => ({ ...prevState, scheduledDateTime: value || new Date() }))}
                        isClearable={true}
                        maxDate={new Date().setDate(new Date().getDate() + 14)}
                        minDate={new Date()}
                        clearButtonTitle="Reset"
                    />
                </div>
                <div className="flex justify-around w-fit mt-2 gap-6">
                    <button
                        className="rounded-lg py-1 text-base flex items-center gap-3 px-4 border focus:bg-white font-Inter leading-[1.4] ml-auto focus:text-[#4873FA]  text-[#fff] bg-[#4873FA] border-[#4873FA] focus:border-[#4873FA] hover:text-[rgb(255,255,255)]"
                        onClick={() => {
                            setOpenDateTimePicker(false);
                            mailSend("new");
                        }}
                    >
                        Schedule Send
                    </button>
                    <button
                        className="rounded-lg flex text-base items-center gap-3 py-1 px-4  focus:bg-white font-Inter leading-[1.4] ml-auto border-[#4873FA] border  text-[#4873FA] bg-white hover:text-[#4873FA]"
                        onClick={clearScheduledTimeAndCloseModal}
                    >
                        Cancel
                    </button>
                </div>
            </WarmupModal>
        </>
    );
};

export default Inbox;
