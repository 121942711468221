import BackdropLoader from "@/components/common/BackdropLoader";
import { useEffect, useRef, useState } from "react";
import useLinkedinAutomation from "@/hooks/useLinkedinAutomation";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LeftMenu } from "./LeftMenu";
import { AllConnections } from "./AllConnections";
import { PendingConnections } from "./PendingConnections";
import useAIEngagement from "@/hooks/useAIEngagement";
import NoMailBoxButton from "../NoMailBoxButton";

const LinkedinConnections = () => {
    const { getAllRelations, getAllInvitations } = useLinkedinAutomation();
    const [activeTab, setActiveTab] = useState("connections");
    const [allLinkedinAccounts, setAllLinkedinAccounts] = useState([]);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const { getUnipileAuthData } = useAIEngagement();

    const getUnipileAuthDataRef = useRef();
    getUnipileAuthDataRef.current = getUnipileAuthData;

    const queryClient = useQueryClient();

    const handleChangeTab = (tabName) => {
        setActiveTab(tabName);

        queryClient.removeQueries(["allRelations"]);
        queryClient.removeQueries(["allInvitations"]);
    };

    const {
        data: allRelationsData,
        isLoading: isRelationsDataLoading,
        refetch: refetchAllRelations,
    } = useQuery({
        queryKey: ["allRelations", activeTab],
        queryFn: () => getAllRelations(),
        enabled: activeTab === "connections",
        refetchOnWindowFocus: true,
    });

    const {
        data: allInvitationsData,
        isLoading: isInvitationsDataLoading,
        refetch: refetchAllInvitations,
    } = useQuery({
        queryKey: ["allInvitations", activeTab],
        queryFn: () => getAllInvitations(),
        enabled: activeTab === "invitations",
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        const fetchActiveLinkedinAccounts = async () => {
            const { data: unipileData } = await getUnipileAuthDataRef.current();

            if (unipileData && unipileData?.accounts?.length > 0) {
                setAllLinkedinAccounts(unipileData?.accounts?.filter((i) => i.provider === "LINKEDIN"));
            } else {
                setAllLinkedinAccounts([]);
                setIsNoMailBox(true);
            }
        };

        fetchActiveLinkedinAccounts();
    }, []);

    return (
        <>
            {isRelationsDataLoading || isInvitationsDataLoading ? (
                <BackdropLoader active={true} />
            ) : isNoMailBox ? (
                <NoMailBoxButton />
            ) : (
                <div className="grid grid-cols-[264px_1fr_1fr_1fr_1fr_1fr] gap-0">
                    <LeftMenu activeTab={activeTab} handleChangeTab={handleChangeTab} />
                    {activeTab === "connections" && (
                        <AllConnections
                            allConnectionsData={allRelationsData?.data || []}
                            isLoading={isRelationsDataLoading}
                            allLinkedinAccounts={allLinkedinAccounts}
                        />
                    )}
                    {activeTab === "invitations" && (
                        <PendingConnections
                            allConnectionsData={allInvitationsData?.data || []}
                            isLoading={isInvitationsDataLoading}
                            allLinkedinAccounts={allLinkedinAccounts}
                            refetchAllInvitations={refetchAllInvitations}
                            refetchAllRelations={refetchAllRelations}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default LinkedinConnections;
