import { useEffect, useRef, useState, useContext } from "react";
import { Button } from "@material-tailwind/react";
import { AuthContext } from "@/context/AuthContext";
import { useNavigate } from "react-router-dom";
import MainLayout from "@/components/MainLayout";
import useStripe from "@/hooks/useStripe";
import CreditSlider from "@/components/credits/CreditSlider";
import BaseContainer from "@/components/BaseContainer";
import { pageContentWidth } from "@/utils/common";
import { AnimatePresence, motion } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import MainLoader from "@/components/MainLoader";
import MainTitleBar from "@/components/MainTitleBar";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const options = [
    0, 50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000, 550000, 600000, 650000, 700000, 750000, 800000,
    850000, 900000, 950000, 1000000,
];

export default function CreditsScene() {
    const navigate = useNavigate();
    const { isTeamOwner } = useContext(AuthContext);
    const [clientSecret, setClientSecret] = useState("");
    const [stripeError, setStripeError] = useState("");
    const [priceIndex, setPriceIndex] = useState(0);

    const canBuyCredits = isTeamOwner;

    useEffect(() => {
        if (!canBuyCredits) {
            navigate("/home");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canBuyCredits]);

    const { getCreditPaymentLink } = useStripe();
    const [loading, setLoading] = useState(false);
    const loadStripe = async (data) => {
        setLoading(true);
        const res = await getCreditPaymentLink(data);
        if (res.status) {
            setClientSecret(res.data.client_secret);
        } else {
            setStripeError(res.message);
        }
        setLoading(false);
    };

    const redirectToSuccessPage = () => {
        navigate("/home");
    };

    const handleCustomSelection = () => {
        const constructedPrice = [
            {
                lookup_key: "additional_credits_key",
                quantity: options[priceIndex] / 1000,
            },
        ];
        loadStripe(constructedPrice);
    };

    const fieldRef = useRef(null);

    const params = new URLSearchParams();
    params.set("mode", "payment");

    const clearStripe = () => {
        setClientSecret("");
        setStripeError("");
    };

    if (!canBuyCredits) {
        return null;
    }

    return (
        <MainLayout>
            {loading && <MainLoader />}
            {!loading && (
                <>
                    <MainTitleBar>
                        <p>Buy extra credits</p>
                    </MainTitleBar>
                    <BaseContainer>
                        <p className="text-base text-[#6D6D6D] mt-6">Select how many credits you would like to buy for your team below.</p>
                        {!clientSecret && (
                            <div className="mb-6">
                                <AnimatePresence>
                                    <motion.div
                                        ref={fieldRef}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        className="mb-[30px] mt-12 flex w-full flex-col"
                                    >
                                        <div className="flex flex-col gap-12">
                                            <CreditSlider state={[priceIndex, setPriceIndex]} options={options}></CreditSlider>
                                        </div>
                                    </motion.div>

                                    {priceIndex > 0 && (
                                        <Button
                                            onClick={handleCustomSelection}
                                            className={
                                                "font-Inter capitalize font-normal mt-16 flex items-center justify-center rounded-lg bg-black p-[12px_24px] tracking-wide text-white transition-all hover:opacity-80"
                                            }
                                        >
                                            Buy Credits
                                        </Button>
                                    )}
                                </AnimatePresence>
                            </div>
                        )}

                        {clientSecret && (
                            <BaseContainer width={pageContentWidth}>
                                <Button
                                    onClick={() => clearStripe()}
                                    className="font-Inter my-10 ml-auto mr-auto h-11 w-56 rounded-md bg-accents-mainGreen px-3 py-1 text-lg font-medium text-white hover:opacity-80"
                                >
                                    Go back
                                </Button>
                                <EmbeddedCheckoutProvider
                                    stripe={stripePromise}
                                    options={{
                                        clientSecret,
                                    }}
                                >
                                    <EmbeddedCheckout
                                        onSuccess={() => redirectToSuccessPage()}
                                        successUrl={`${window.location.origin}/home`}
                                    />
                                </EmbeddedCheckoutProvider>
                            </BaseContainer>
                        )}

                        {stripeError && (
                            <div className="flex w-full flex-col items-center justify-center">
                                <div className="my-24 flex w-5/12 flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                                    <div className="font-Inter flex flex-col self-stretch text-center text-3xl font-extrabold">
                                        Sorry, we got problems
                                    </div>
                                    <div className="font-Inter flex-wrap text-center text-lg">{stripeError}</div>
                                    <div className="font-Inter my-10 flex-wrap text-center text-lg">
                                        <Button
                                            onClick={() => navigate(-1)}
                                            className="font-Inter h-11 w-56 rounded-md bg-accents-mainGreen px-3 py-1 text-lg font-medium uppercase text-white hover:opacity-80"
                                        >
                                            Go back
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </BaseContainer>
                </>
            )}
        </MainLayout>
    );
}
