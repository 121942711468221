import ExportsTable from "./ExportsTable";
// import ExportButton from "./ExportButton";

function ProfileExport({ data, csvs }) {
    return (
        <div>
            <ExportsTable csvs={csvs} />
        </div>
    );
}

export default ProfileExport;
