import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import useKompassRouter from "@/hooks/useKompassRouter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        width: "500px",
    },
}));

export default function LimitModal({ isOpen, onClose, type }) {
    const { kompassNavigate } = useKompassRouter();
    return (
        <BootstrapDialog fullScreen={false} open={isOpen} onClose={onClose}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className="text-[#090c05] text-[18px] font-semibold font-['Inter'] ">
                    {type === "freeSearch" && "You have run out of searches on your free trial. Please upgrade your account."}
                    {type === "teamMember" && "Your credits are fully used. Please, contact your team owner to request additional credits."}
                    {type === "default" && "Your credits are fully used. Please, upgrade your plan to get more credits."}
                </div>
                <button onClick={onClose} className=" flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <div className="flex flex-row px-6 py-4 gap-4 justify-end">
                    <Button className="main-white-button normal-case" onClick={onClose}>
                        Go back
                    </Button>
                    {type !== "teamMember" && (
                        <Button
                            onClick={() => kompassNavigate(type === "freeSearch" ? "/upgrade" : "/credits")}
                            className="main-black-button normal-case"
                        >
                            {type === "freeSearch" ? "Upgrade plan" : "Buy credits"}
                        </Button>
                    )}
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
