import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Popper, Grow, ClickAwayListener } from "@mui/material";
import { toast } from "react-toastify";

// Hooks
import useAuth from "@/hooks/useAuth";
import { useAvailableIntegrations } from "@/hooks/useAvailableIntegrations";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";

// Icon
import { ReactComponent as CrmIcon } from "@/assets/image/search/group_search.svg";

function CRMPushMenu({ selected, profileType, isFuseCRM, isDisabled = false, className = "" }) {
    // ---------- Hooks / Store ----------
    const { toggleShow, setResults, setUploads, setTarget, setTargetEntity, setProfileType, toggleLoading } = useCRMPushResultStore();
    const { getAuthToken } = useAuth();
    const navigate = useNavigate();
    const availableIntegrations = useAvailableIntegrations();

    // ---------- State for poppers ----------
    const [mainMenuOpen, setMainMenuOpen] = useState(false);
    const mainMenuAnchor = useRef(null);

    // The sub‐menu for "As Lead / As Contact"
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [subMenuOptions, setSubMenuOptions] = useState([]);
    const [subMenuIntegration, setSubMenuIntegration] = useState("");

    const numSelected = selected.length;

    // ---------- Same handleIntegrationPush logic ----------
    const handleIntegrationPush = async (func, targetEntity, target, ui = "table") => {
        let filteredData;

        if (isFuseCRM) {
            const transformedData = selected.map((contact) => ({
                name: contact.name,
                listId: contact.meta?.listId,
                pdlId: contact.meta?.pdlId,
                _source: "CRM",
                linkedIn: contact?.linkedIn,
            }));
            filteredData = transformedData.filter((item) => item.listId && (item.pdlId || item.linkedIn));
            if (filteredData.length === 0) {
                toast.error("No valid contacts found with required meta fields.");
                return;
            }
            setUploads(filteredData);
        } else {
            if (profileType === "profile_list" || profileType === "profile_search") {
                filteredData = selected.filter((item) => {
                    return (
                        (item?.validPhones && item.validPhones.length > 0) ||
                        (item?.personalEmails && item.personalEmails.length > 0) ||
                        (item?.workEmails && item.workEmails.length > 0) ||
                        (item?.doNotEmails && item.doNotEmails.length > 0) ||
                        (item?.riskyEmails && item.riskyEmails.length > 0)
                    );
                });
            } else {
                filteredData = selected;
            }
            setUploads(filteredData);
        }

        toggleLoading();
        setProfileType(profileType);
        setTarget(target);
        setTargetEntity(targetEntity);

        let res;
        const token = await getAuthToken();
        if (profileType === "profile_list" || profileType === "profile_search") {
            res = await func(filteredData, profileType, token);
        } else {
            const ids = filteredData.map((item) => (item.kompassProfileId ? item.kompassProfileId : item._id));
            res = await func(ids, profileType, token);
        }

        toggleLoading();

        if (ui === "table") {
            toggleShow();
        } else if (ui === "notification") {
            if (res.success) {
                toast.success("Successfully sent data to webhooks, check Zapier for results");
                if (res.extraMessage) {
                    toast.info(res.extraMessage);
                }
            } else {
                toast.error("There was some error handling request, try again later");
            }
            return;
        }

        const parsed = JSON.parse(res.body);
        setResults(parsed);
    };

    // ---------- Main button click logic ----------
    const handleButtonClick = () => {
        if (!numSelected) {
            toast.info("Please select at least one record to push to CRM");
            return;
        }
        if (selected.some((item) => item?.enrichType === "emailValidation")) {
            toast.error("Email validation records cannot be pushed to CRM", {
                theme: "colored",
            });
            return;
        }
        if (!isFuseCRM && profileType !== "bulk") {
            const enriched = selected.filter(
                (item) =>
                    item?.isEmailFound ||
                    item?.isPhoneFound ||
                    (item?.validPhones && item.validPhones.length > 0) ||
                    (item?.personalEmails && item.personalEmails.length > 0) ||
                    (item?.workEmails && item.workEmails.length > 0) ||
                    (item?.doNotEmails && item.doNotEmails.length > 0) ||
                    (item?.riskyEmails && item.riskyEmails.length > 0)
            );
            if (enriched.length <= 0) {
                toast.error("There is no data to save. Only enriched records can be pushed to CRM", { theme: "colored" });
                return;
            }
        }
        if (isDisabled) return;

        setMainMenuOpen((prev) => !prev);
        setSubMenuOpen(false); // close sub menu if main is toggled
    };

    // ---------- Show the sub menu for multiple pushOptions ----------
    const handleOpenSubMenu = (event, pushOptions, integrationLabel) => {
        if (!pushOptions?.length) return; // no sub‐options, do nothing
        setSubMenuAnchor(event.currentTarget);
        setSubMenuOptions(pushOptions);
        setSubMenuIntegration(integrationLabel);
        setSubMenuOpen(true);
    };

    const handleCloseSubMenu = () => {
        setSubMenuOpen(false);
        setSubMenuAnchor(null);
    };

    // ---------- Render ----------
    return (
        <>
            {/* 1) The main "CRM Push" button */}
            <div
                ref={mainMenuAnchor}
                className={
                    "main-white-button" +
                    (numSelected > 0 && !isDisabled
                        ? " !border-stone-500 !text-stone-950 "
                        : " !border-stone-500 !text-stone-350 !bg-white !cursor-not-allowed !pointer-events-none") +
                    " flex items-center gap-2 " +
                    className
                }
                onClick={handleButtonClick}
            >
                <CrmIcon
                    className={
                        "h-5 w-5" +
                        (numSelected > 0 && !isDisabled
                            ? " !fill-current !text-stone-950"
                            : " !fill-current !text-stone-350 disabled:cursor-not-allowed")
                    }
                />
                <span>CRM Push</span>
            </div>

            {/* 2) Main Popper (Figma style #1) */}
            <Popper open={mainMenuOpen} anchorEl={mainMenuAnchor.current} placement="bottom-start" transition style={{ zIndex: 9999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        {/* 
              The outer container styled per your Figma snippet.
              We'll do a fixed width of 193px to match your design.
            */}
                        <div
                            className="
                h-[151px]
                w-[220px] /* can tweak slightly from your original 193px if needed */
                p-4
                bg-white
                rounded-lg
                shadow-[0px_4px_34px_0px_rgba(0,0,0,0.16)]
                inline-flex
                flex-col
                justify-start
                items-start
                gap-2
              "
                        >
                            <ClickAwayListener
                                onClickAway={() => {
                                    setMainMenuOpen(false);
                                    handleCloseSubMenu();
                                }}
                            >
                                <div className="flex-col justify-start items-start gap-4 inline-flex w-full h-full">
                                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                        {/* 
                      We loop over your availableIntegrations
                      and replicate each row from the Figma
                    */}
                                        {availableIntegrations.map((item) => {
                                            // 1) If integration is not yet connected => show "Connect" / "Setup" row
                                            if (!item.available) {
                                                return (
                                                    <div
                                                        key={item.label}
                                                        className="
                                                                    self-stretch
                                                                    px-4
                                                                    py-2
                                                                    rounded-lg
                                                                    flex
                                                                    justify-start
                                                                    items-center
                                                                    gap-2
                                                                    cursor-pointer
                                                                    hover:bg-gray-50
                                                                    "
                                                        onClick={() => {
                                                            // Navigate to Integrations page
                                                            navigate("/integrate");
                                                            setMainMenuOpen(false);
                                                        }}
                                                    >
                                                        <div className="text-[#050505] text-sm font-medium leading-[21px]">
                                                            {item.label === "Zapier" ? "Setup Zapier" : `Connect to ${item.label}`}
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            // 2) If the integration is connected => show "Push to X" row
                                            return (
                                                <div
                                                    key={item.label}
                                                    className="
        w-full
        px-4
        py-2
        rounded-lg
        inline-flex
        justify-between
        items-center
        cursor-pointer
        hover:bg-gray-50
      "
                                                    onMouseEnter={(e) => {
                                                        // Only show sub-menu if more than 1 push option
                                                        if (item.pushOptions && item.pushOptions.length > 1) {
                                                            handleOpenSubMenu(e, item.pushOptions, item.label);
                                                        } else {
                                                            // No sub-menu needed, or just 1 option => close sub menu
                                                            setSubMenuOpen(false);
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        // 0 or 1 push option => push immediately
                                                        if (item.pushOptions && item.pushOptions.length === 1) {
                                                            const singleOption = item.pushOptions[0];
                                                            handleIntegrationPush(
                                                                singleOption.handler,
                                                                singleOption.targetEntity,
                                                                item.label, // pass along the integration label
                                                                singleOption.ui
                                                            );
                                                            setMainMenuOpen(false);
                                                        } else if (item.pushOptions && item.pushOptions.length > 1) {
                                                            // multiple push options => open sub menu
                                                            handleOpenSubMenu(e, item.pushOptions, item.label);
                                                        } else {
                                                            // No pushOptions array or empty => direct push if there's a handler
                                                            if (item.handler) {
                                                                handleIntegrationPush(
                                                                    item.handler,
                                                                    item.targetEntity,
                                                                    item.label,
                                                                    item.ui || "table" // default to the "table" UI if not specified
                                                                );
                                                            }
                                                            setMainMenuOpen(false);
                                                        }
                                                    }}
                                                >
                                                    <div className="text-[#050505] text-sm font-medium leading-[21px]">
                                                        {`Push to ${item.label}`}
                                                    </div>

                                                    {/* Show the arrow only if there's more than 1 push option */}
                                                    {item.pushOptions && item.pushOptions.length > 1 && (
                                                        <div className="w-[20px] h-[20px] flex justify-center items-center">
                                                            <svg
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <mask
                                                                    id="mask0_2469_14270"
                                                                    maskUnits="userSpaceOnUse"
                                                                    x="0"
                                                                    y="0"
                                                                    width="20"
                                                                    height="21"
                                                                >
                                                                    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                                                                </mask>
                                                                <g mask="url(#mask0_2469_14270)">
                                                                    <path
                                                                        d="M10.875 10.5L6.9375 6.5625L8 5.5L13 10.5L8 15.5L6.9375 14.4375L10.875 10.5Z"
                                                                        fill="#1C1B1F"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>

            {/* 3) Sub-Menu Popper for "As Lead" / "As Contact" (Figma style #2) */}
            <Popper open={subMenuOpen} anchorEl={subMenuAnchor} placement="right-start" transition style={{ zIndex: 10000 }}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ marginLeft: "16px", marginRight: "16px" }}>
                        {/* 
              The second container styled per your second Figma snippet:
              h-[110px], p-4, white, shadow, etc. 
            */}
                        <div
                            className="
                h-fit
                p-4
                bg-white
                rounded-lg
                shadow-[0px_4px_34px_0px_rgba(0,0,0,0.16)]
                inline-flex
                flex-col
                justify-start
                items-start
                gap-2
              "
                        >
                            <ClickAwayListener onClickAway={handleCloseSubMenu}>
                                <div className="flex-col justify-start items-start gap-4 inline-flex w-full">
                                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                        {/* 
                      We assume subMenuOptions are something like:
                      [ { label: 'As Lead', handler, targetEntity, ui }, { label: 'As Contact', ... } ]
                    */}
                                        {subMenuOptions.map((option, index) => {
                                            return (
                                                <div
                                                    key={option.label}
                                                    // For the first item, background #f4f3f0 per your snippet
                                                    className={`
                            px-4 py-2
                            rounded-lg
                            flex
                            justify-start
                            items-center
                            gap-2
                            cursor-pointer
                            ${index === 0 ? "bg-[#f4f3f0]" : "bg-white hover:bg-gray-50"}
                          `}
                                                    onClick={() => {
                                                        handleIntegrationPush(
                                                            option.handler,
                                                            option.targetEntity,
                                                            subMenuIntegration,
                                                            option.ui
                                                        );
                                                        setMainMenuOpen(false);
                                                        setSubMenuOpen(false);
                                                    }}
                                                >
                                                    <div className="text-[#050505] text-sm font-medium leading-[21px]">{option.label}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default CRMPushMenu;
