import ResultsTable from "./ResultsTable";

export default function WebsiteReport({
    analyzedData,
    currentTab,
    filterProps,
    setFilterProps,
    checkedItemsInReport,
    setCheckedItemsInReport,
}) {
    return (
        <div className="p-6">
            {analyzedData && analyzedData.length > 0 && (
                <div className="overflow-x-scroll overflow-y-scroll">
                    <ResultsTable
                        data={analyzedData}
                        currentTab={currentTab}
                        filterProps={filterProps}
                        setFilterProps={setFilterProps}
                        checkedItemsInReport={checkedItemsInReport}
                        setCheckedItemsInReport={setCheckedItemsInReport}
                    />
                </div>
            )}
        </div>
    );
}
