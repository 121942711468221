import { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import useDashboard from "@/hooks/useDashboard";
import { AuthContext } from "@/context/AuthContext";
import { debounce } from "lodash";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";

import useStripe from "@/hooks/useStripe";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import usePlan from "@/hooks/usePlan";
import BackIcon from "@/assets/image/crm/transcript/back-arrow.svg";
import MainLayout from "@/components/MainLayout";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import useBillingStore from "@/store/useBillingStore";
import BackdropLoader from "@/components/common/BackdropLoader";
import { UserSlider } from "@/components/usersslider/UserSlider";
import MainTitleBar from "@/components/MainTitleBar";
import { ReactSVG } from "react-svg";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function PlanUpgradeScene() {
    const { kompassNavigate } = useKompassRouter();
    const location = useLocation();
    const { userInfo } = useContext(AuthContext);
    const { selectedPrices, plan, users } = useBillingStore();
    const { getDashboard } = useDashboard();
    const getDashboardRef = useRef();
    getDashboardRef.current = getDashboard;

    const [loading, setLoading] = useState(false);
    const [stripeError, setStripeError] = useState("");
    const [cost, setCost] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [isCurrentPlan, setIsCurrentPlan] = useState(false);

    useEffect(() => {
        if (!location?.state?.redirected) {
            kompassNavigate("/upgrade", { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const checkIfCurrentPlan = async () => {
            const response = await getDashboardRef.current({});
            const planInfo = response?.data?.planInfo;
            const teamsInfo = response?.data?.teamsInfo;

            if (planInfo?.name === plan?.name && teamsInfo?.total === users) {
                setIsCurrentPlan(true);
            } else {
                setIsCurrentPlan(false);
            }
        };
        checkIfCurrentPlan();
    }, [plan, users]);

    useEffect(() => {
        if (!updated) {
            return;
        }

        const debouncedNavigate = debounce((value) => {
            kompassNavigate(value);
        }, 3000);

        debouncedNavigate("/home");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated]);

    const lookupKeys = Object.values(selectedPrices)
        .map((t) => t?.lookup_key)
        .filter(Boolean);

    const { getPaymentLink } = useStripe();
    const { getPlanPreview } = usePlan();
    const getPaymentMethods = useHTTPRequestObject({
        link: "/billing/getPaymentMethods",
    });

    const { data: paymentMethodsData } = useQuery({
        queryKey: ["PaymentMethods"],
        queryFn: () => getPaymentMethods(),
    });

    const fetchPlanPreview = async () => {
        if (!lookupKeys) {
            return;
        }

        try {
            const requestData = {
                lookupKeys,
                userId: userInfo?._id,
                quantity: users,
            };

            setLoading(true);
            const planPreview = await getPlanPreview(requestData);

            const { dueToday, totalCost, isUpgrade } = planPreview.data;
            setCost({ dueToday: dueToday / 100, totalCost: totalCost / 100, isUpgrade });
        } catch (error) {
            toast.error(`Failed. ${error.message}`, {
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!lookupKeys?.length || !users) {
            return;
        }

        fetchPlanPreview();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, selectedPrices]);

    const getCardData = () => {
        if (!paymentMethodsData) {
            return null;
        }

        const paymentData = paymentMethodsData?.data;

        if (!paymentData?.length) {
            return null;
        }

        return {
            card: paymentData[0]?.card?.last4,
            paymentSystem: paymentData[0]?.card?.brand,
        };
    };

    const handleUpgrade = async () => {
        if (!selectedPrices) {
            return;
        }

        try {
            setLoading(true);
            setStripeError("");

            if (selectedPrices) {
                const filteredPrices = Object.values(selectedPrices).filter((t) => !!t?.lookup_key);

                let res = await getPaymentLink(filteredPrices, users);

                if (!res.status) {
                    setStripeError(res.message);
                    toast.error(`Failed. ${res.message}`, {
                        theme: "colored",
                    });
                } else {
                    setUpdated(true);
                    toast.success("Plan updated successfully. You will be redirected in 3 seconds", { theme: "colored" });
                }
            }
        } catch (error) {
            toast.error(`Failed. ${error.message}`, {
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const cardData = getCardData();

    return (
        <MainLayout>
            <MainTitleBar>
                <p>Payment</p>
            </MainTitleBar>

            {loading && <BackdropLoader active={true}></BackdropLoader>}

            <div className="mx-6">
                <button
                    onClick={() => kompassNavigate(-1)}
                    className="flex items-center text-primary-teal text-sm py-1 px-2 mt-5 rounded-lg hover:bg-primary-header"
                >
                    <ChevronLeftOutlinedIcon color="inherit" />
                    Back
                </button>

                <div className="w-full">
                    <UserSlider />
                </div>

                <div className="mt-7 mb-16 max-w-[300px] text-primary-black">
                    <p className="text-sm text-[#5D5D5D]">Summary</p>
                    <div className="mb-4 flex gap-2">
                        <span className="font-InterSemiBold text-3xl leading-[3.45rem] tracking-normal text-primary-black">
                            {cost ? `$${cost.totalCost?.toLocaleString()}` : null}
                        </span>
                        <div className="mb-2 flex items-end text-base text-[#090C05]">
                            <span className="font-InterBold">{`/${selectedPrices.main?.interval}`}</span>
                        </div>
                    </div>
                    <div className="flex mt-4 items-center justify-between font-semibold">
                        <h2>{plan?.name}</h2>
                        <p>{cost ? `$${cost.totalCost?.toLocaleString()}` : null}</p>
                    </div>
                    <div className="flex mt-2 items-center text-sm justify-between font-medium text-dgray-6 ">
                        <h2>{users} users</h2>
                        <p>{`$${(cost?.totalCost / users).toFixed(2).toLocaleString()}`} each</p>
                    </div>
                    <hr className="border-t border-[#E7E7E7] text-[#E7E7E7] bg-[#E7E7E7] mt-4" />
                    <div className="flex my-4 items-center justify-between font-semibold">
                        <h2>Total</h2>
                        <p>{`$${cost?.totalCost?.toLocaleString()}/${selectedPrices.main?.interval}`}</p>
                    </div>
                    <hr className="border-t border-[#E7E7E7] text-[#E7E7E7] bg-[#E7E7E7]" />
                    <div className="flex my-4 items-center justify-between font-semibold">
                        <h2>Total due today</h2>
                        <p>{`$${cost?.dueToday.toLocaleString()}`}</p>
                    </div>
                    {paymentMethodsData?.length ? (
                        <Button
                            onClick={() => kompassNavigate("/settings")}
                            className="font-Inter capitalize font-normal mt-8 flex w-full items-center justify-center rounded-lg bg-black p-[12px_24px] tracking-wide text-white transition-all hover:opacity-80"
                        >
                            Add payment method
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={handleUpgrade}
                                disabled={isCurrentPlan}
                                className="font-Inter capitalize font-normal mt-8 flex w-full items-center justify-center rounded-lg bg-black p-[12px_24px] tracking-wide text-white transition-all hover:opacity-80"
                            >
                                {isCurrentPlan ? "Current" : cost?.isUpgrade ? "Upgrade" : "Downgrade"}
                            </Button>
                            {cardData && (
                                <div className="mt-2 text-center font-Inter text-xs 2xl:text-sm text-stone-350">
                                    Using {cardData?.paymentSystem} card ending in {cardData?.card}
                                </div>
                            )}
                        </>
                    )}
                </div>
                {(stripeError.length || !selectedPrices) && (
                    <div className="flex w-full  flex-col items-center justify-center">
                        <div className="my-24 flex w-5/12 flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                            <div className="font-Inter flex flex-col self-stretch text-center text-3xl font-extrabold">
                                Sorry, we got problems
                            </div>
                            <div className="font-Inter flex-wrap text-center text-lg">{stripeError}</div>
                            <div className="font-Inter mt-10 flex-wrap text-center text-lg">
                                <Button
                                    onClick={() => kompassNavigate(-1)}
                                    className="font-Inter h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-[20px] font-medium capitalize text-white"
                                >
                                    Go back
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </MainLayout>
    );
}
