import { useEffect, useState } from "react";
import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function LeftBar({ currentTab, setCurrentTab, summary }) {
    // Create an array of the tab labels from your constants
    const items = Object.values(WEBSITE_VISITORS_REPORT_ITEMS);

    // Keep track of which tab is selected
    const [activeTab, setActiveTab] = useState(items.indexOf(currentTab));

    // Update state whenever the parent changes `currentTab`
    useEffect(() => {
        setActiveTab(items.indexOf(currentTab));
    }, [currentTab, items]);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        setCurrentTab(items[newValue]);
    };

    return (
        <Box sx={{ width: "100%", fontFamily: "Inter" }}>
            {/* Optional: Display summary text above tabs */}
            {summary && (
                <Box
                    sx={{
                        fontSize: "14px",
                        color: "#050505",
                        fontWeight: 500,
                        marginBottom: "24px",
                        lineHeight: 1.4,
                    }}
                >
                    {summary}
                </Box>
            )}

            {/* Horizontal tabs row */}
            <Box sx={{ borderBottom: "1px solid #E7E7E7", width: "100%", fontFamily: "Inter" }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#050505",
                            height: "3px",
                        },
                    }}
                    sx={{
                        "& .MuiTab-root": {
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#6D6D6D",
                            fontFamily: "Inter",
                            minWidth: "auto",
                            margin: "0 8px 0 0",
                            padding: 0,
                            "&.Mui-selected": {
                                color: "#050505",
                            },
                        },
                    }}
                >
                    {items.map((item) => (
                        <Tab key={item} label={item} />
                    ))}
                </Tabs>
            </Box>
        </Box>
    );
}
