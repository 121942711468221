import React, { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import useImage from "@/hooks/useImage";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmationModal from "@/components/payment/ConfirmationModal";

const modalPresets = {
    delete: {
        icon: <WarningRoundedIcon className={" !h-full !w-full !fill-white"} />,
        title: "Confirm deletion",
        message: "You are about to delete payment method, you may add it again later, proceed? ",
        footnotes: [
            "If you don't add active payment methods before next subscription payment, your subscription might be suspended",
            "If it was default payment method, another one will be chosen automatically",
        ],
    },
    setDefault: {
        icon: <WarningRoundedIcon className={" !h-full !w-full !fill-white"} />,
        title: "Confirm operation",
        message: "You are setting card as default payment method, this card will be used by default during future payments",
    },
};

function CardDetailsCard({ id, card, customer }) {
    const { last4, brand, exp_month, exp_year } = card;
    const def = customer?.invoice_settings?.default_payment_method === id;
    const { loading, image } = useImage(`banks/${brand}.svg`);
    const [over, setOver] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modal, setModal] = useState({});
    const queryClient = useQueryClient();

    const deleteFiles = useHTTPRequestObject({
        link: "/billing/removePaymentMethod",
        method: "DELETE",
    });

    const deleteMethodMutation = async (params) => {
        const res = await deleteFiles(JSON.stringify(params));
        return res;
    };
    const { mutateAsync: deleteMethodTrigger, isPending: pendingDeleteMethod } = useMutation({
        mutationFn: deleteMethodMutation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["PaymentMethods"] });
        },
    });
    const handleCardDelete = () => {
        setModal({
            ...modalPresets.delete,
            handleConfirm: handleConfirmCardDelete,
        });
        setModalShow(true);
    };
    const handleConfirmCardDelete = async () => {
        await deleteMethodTrigger({ cardId: id });
    };

    const setAsDefault = useHTTPRequestObject({
        link: "/billing/setPaymentMethodAsDefault",
        method: "POST",
    });
    const setAsDefaultMutation = async (params) => {
        const res = await setAsDefault(JSON.stringify(params));
        return res;
    };
    const { mutateAsync: setAsDefaultTrigger, isPending: pendingSetAsDefault } = useMutation({
        mutationFn: setAsDefaultMutation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["PaymentMethods"] });
        },
    });
    const handleSetDefault = () => {
        setModal({
            ...modalPresets.setDefault,
            handleConfirm: handleConfirmSettingAsDefault,
        });
        setModalShow(true);
    };
    const handleConfirmSettingAsDefault = async () => {
        await setAsDefaultTrigger({ cardId: id });
    };

    return (
        <div className="flex flex-col w-fit items-center" onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
            {!loading && (
                <div className="flex flex-col border border-[#e7e7e7] w-full items-center rounded-lg bg-white px-5 py-3 transition ">
                    <div className="flex flex-row items-center justify-start">
                        <img className="mr-4 flex h-8 w-8 items-center justify-center" src={image} alt="" />
                        <div className="flex flex-col gap-2 justify-end items-center">
                            <div className="w-fit text-[#050505] text-sm font-medium font-['Inter'] ml-4 mr-auto flex items-center gap-4">
                                <div className="flex flex-row justify-start gap-2 items-center">
                                    {new Array(3).fill(0, 0, 3).map(() => {
                                        return <span className="translate-y-[2px]">****</span>;
                                    })}
                                    <span>{last4}</span>
                                </div>
                            </div>

                            <div className="w-full flex justify-end text-[#050505] text-sm font-medium font-['Inter'] opacity-40">
                                Valid through {exp_month} / {exp_year.toString().slice(-2)}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-row pt-2 text-xs items-center gap-4 w-full">
                {!def && (
                    <div className=" flex cursor-pointer items-center" onClick={handleSetDefault}>
                        <span className="font-Inter text-stone-450 text-center text-[#2D7A89] text-xs leading-[100%] hover:text-accents-mainBlack transition-colors">
                            Set as default
                        </span>
                    </div>
                )}
                {def && <span className="color-grey-450 font-Inter text-center text-xs leading-[100%] opacity-50">Default</span>}
                <div className="flex items-center gap-1">
                    <div onClick={handleCardDelete} className="cursor-pointer">
                        <span className="font-Inter text-stone-450 text-center text-[#2D7A89] text-xs leading-[100%] hover:text-accents-mainBlack transition-colors">
                            Delete
                        </span>
                    </div>
                </div>
            </div>
            <ConfirmationModal showState={[modalShow, setModalShow]} modal={modal} loading={pendingDeleteMethod || pendingSetAsDefault} />
        </div>
    );
}

export default CardDetailsCard;
