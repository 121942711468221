import React, { useState } from "react";
import { Box, Typography, Select, MenuItem, Button, TextField, styled, OutlinedInput } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { CustomButton } from "@/components/crm/contacts/Button";

// Example data for the "Why did you reach this page?" dropdown
const reasons = [
    "I received an email notice",
    "I received an SMS notice",
    "Too many sales calls",
    "Found my email on your online directory",
    "Found my phone on your online directory",
    "Heard about Fuse AI on the news",
    "A friend told me about Fuse AI",
    "Other reasons",
];

// Example data for the "Country of residence" dropdown
const countries = ["United States", "Canada", "United Kingdom", "Australia", "India", "Germany", "France", "Other"];

/**
 * Custom styled Select input to better match the Figma placeholder coloring.
 * We use MUI's renderValue to show a placeholder if nothing is selected.
 */
const StyledSelect = styled(Select)(() => ({
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e7e7e7",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e7e7e7",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e7e7e7",
    },
    "& .MuiSelect-select": {
        padding: "12px 24px",
        borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
    },
    "& fieldset": {
        borderRadius: "8px",
    },
}));

/**
 * Custom MenuProps for the dropdown so that the popup has a matching
 * shadow/border like in the Figma.
 */
const menuProps = {
    sx: {
        "&& .MuiPaper-root": {
            boxShadow: "0px 4px 34px rgba(0, 0, 0, 0.16)",
            border: "1px solid #e7e7e7",
            borderRadius: "8px",
            maxHeight: 300,
        },
        "&& .MuiMenu-list": {
            padding: 0,
        },
    },
};

export default function OptOutForm() {
    const [reason, setReason] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    /**
     * Submits the form by sending a GET request to the specified API with
     * reason, country, email, and recaptchaValue as query parameters.
     */
    const handleSubmit = async () => {
        try {
            // Build the query string
            const baseUrl = "https://wc486q4xk0.execute-api.us-east-1.amazonaws.com/dev/user/optOutForm";
            const queryParams = new URLSearchParams({
                reason,
                country,
                email,
                recaptchaValue: recaptchaValue || "",
            });

            const finalUrl = `${baseUrl}?${queryParams.toString()}`;

            const response = await fetch(finalUrl, {
                method: "GET",
                headers: {
                    Authorization: "Basic $2b$20$uQBqVcPpxsTf1KQkcZuHj.3ZmMvRgXTfz1RjQ054wj6JJv1BWrWhC",
                },
            });

            if (!response.ok) {
                // Handle non-2xx status codes
                throw new Error(`Request failed with status ${response.status}`);
            }

            // If the response is JSON
            const data = await response.json();
            console.log("API response:", data);

            // Clear out the fields or show a success message, etc.
            alert("Successfully submitted your request!");
            setReason("");
            setCountry("");
            setEmail("");
            setRecaptchaValue(null);
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred while submitting the form.");
        }
    };

    return (
        <Box className="flex flex-col min-h-screen items-center justify-center bg-white">
            {/* Header / Navbar */}
            <div className="w-full  px-6 py-4 bg-white border-b border-[#e7e7e7] flex-col justify-start items-start inline-flex">
                <div className="text-[#050505] text-xl font-semibold font-['Inter']">Opt-Out Webform</div>
            </div>

            {/* Main Content Container */}
            <Box className="flex flex-col max-w-[980px] items-center justify-start pt-[72px]">
                <div className="w-full flex-col justify-start items-start gap-10 inline-flex">
                    {/* Welcome Section */}
                    <div className="text-center w-full text-[#050505] text-xl font-semibold font-['Inter']">
                        Welcome to the Opt-Out Webform!
                    </div>
                    {/* Introductory Text */}
                    <div className="self-stretch text-center text-black text-base font-medium font-['Inter'] leading-[21px]">
                        We value your privacy and take every measure to protect your personal information.
                        <br />
                        For details on how we handle and use your personal data during our service delivery, please review our Privacy
                        Policy.
                    </div>
                    <div className="self-stretch text-center text-black text-base font-medium font-['Inter'] leading-[21px]">
                        Our technology routinely reviews a wide range of data sources. To ensure your contact information (such as emails
                        and phone numbers) is excluded from our database, we request that you provide these details so we can add them to
                        our exclusion list.
                        <br />
                        <br />
                        The information you submit will be used solely to confirm your ownership of the email address and assist in
                        processing your request for removal.
                        <br />
                        <br />
                        We will only use the details you provide to manage your privacy request and ensure your information is excluded from
                        our system.
                    </div>
                </div>

                {/* Form Container */}
                <Box className="bg-white w-full max-w-[635px] mt-[64px] flex flex-col gap-10 mx-auto">
                    {/* Reason Dropdown */}
                    <Box className="flex flex-col gap-2">
                        <div className="text-[#050505] text-sm font-semibold font-['Inter'] capitalize">Why did you reach this page?</div>
                        <StyledSelect
                            value={reason}
                            onChange={handleReasonChange}
                            displayEmpty
                            variant="outlined"
                            input={<OutlinedInput />}
                            MenuProps={menuProps}
                            InputProps={{ className: "text-sm text-[#050505]" }}
                            className="bg-white rounded-lg text-sm px-0 py-0"
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span className="text-[#b0b0b0]">Select a reason</span>;
                                }
                                return selected;
                            }}
                        >
                            {reasons.map((option) => (
                                <MenuItem key={option} value={option} className="text-sm">
                                    {option}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Box>

                    {/* Country Dropdown */}
                    <Box className="flex flex-col gap-2">
                        <div className="text-[#050505] text-sm font-semibold font-['Inter'] capitalize">Country of residence</div>
                        <StyledSelect
                            value={country}
                            onChange={handleCountryChange}
                            displayEmpty
                            variant="outlined"
                            input={<OutlinedInput />}
                            MenuProps={menuProps}
                            className="bg-white rounded-lg text-sm px-0 py-0"
                            InputProps={{ className: "text-sm text-[#050505]" }}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span className="text-[#b0b0b0]">Select your country</span>;
                                }
                                return selected;
                            }}
                        >
                            {countries.map((option) => (
                                <MenuItem key={option} value={option} className="text-sm">
                                    {option}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Box>

                    {/* Email Field */}
                    <Box className="flex flex-col gap-2">
                        <div className="text-[#050505] text-sm font-semibold font-['Inter'] capitalize">Email</div>
                        <TextField
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email address"
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#E7E7E7",
                                    },

                                    backgroundColor: "#fff", // Matches bg-stone-100
                                    padding: "0px !important",
                                    borderRadius: "0.375rem", // Matches rounded-md
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E7E7E7", // Maintain border color when focused
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E7E7E7", // Maintain border color on hover
                                    },
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "12px 24px",
                                },
                                "& .MuiAutocomplete-input": {
                                    padding: 0,
                                },
                                "& .MuiAutocomplete-popupIndicator": {
                                    display: "none",
                                },
                            }}
                            InputProps={{ className: "px-4 py-3 text-sm text-[#050505]" }}
                        />
                    </Box>

                    {/* reCAPTCHA */}
                    {/* Replace with your real site key */}
                    <ReCAPTCHA
                        className="w-full justify-center items-center flex"
                        sitekey={process.env.REACT_APP_KOMPASSAI_RECAPTCHA_SITE_KEY_CHECKBOX}
                        onChange={(value) => setRecaptchaValue(value)}
                    />

                    {/* Submit Button */}
                    <Box className="mb-10">
                        <CustomButton
                            variant="contained"
                            onClick={handleSubmit}
                            title="Submit"
                            height={48}
                            width={635}
                            px={24}
                            py={12}
                            titleTextSize={14}
                            titleFontWeight={500}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
