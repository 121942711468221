import useAuth from "@/hooks/useAuth";
import { CRM_BACKEND_URL } from "@/utils/constants";

const useOpportunities = () => {
    const apiUrl = CRM_BACKEND_URL;
    const { getAuthToken } = useAuth();

    const fetchWithAuth = async (endpoint, method = "POST", body = null, options = {}) => {
        const authToken = await getAuthToken();
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : null,
            ...options,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "API request failed");
        }
        return response.json();
    };

    const getOpportunities = async () => {
        return fetchWithAuth("/api/v1/opportunities/getAll", "GET");
    };

    // create opportunity
    const createOpportunity = async (opportunity) => {
        try {
            // keep only the fields that has values
            delete opportunity._id;
            Object.keys(opportunity).forEach((key) => opportunity[key] === "" && delete opportunity[key]);
            if (opportunity.company) {
                opportunity.company = opportunity.company._id;
            }
            const response = await fetchWithAuth("/api/v1/opportunities/", "POST", opportunity);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // update opportunity
    const updateOpportunity = async (opportunity) => {
        try {
            // remove any empty fields
            const id = opportunity._id;
            delete opportunity._id;
            delete opportunity.createdBy;
            delete opportunity.pointOfContact;
            if (opportunity.company) {
                opportunity.company = opportunity.company._id;
            }
            Object.keys(opportunity).forEach((key) => opportunity[key] === "" && delete opportunity[key]);
            const response = await fetchWithAuth(`/api/v1/opportunities/${id}`, "PUT", opportunity);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    // update point of contact
    const updatePointOfContact = async (opportunityId, pointOfContact) => {
        try {
            const response = await fetchWithAuth(`/api/v1/opportunities/pointOfContact/${opportunityId}`, "PATCH", { pointOfContact });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const deleteOpportunities = async (ids) => {
        try {
            const response = await fetchWithAuth(`/api/v1/opportunities`, "DELETE", { opportunityIds: ids });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getCompanies = async () => {
        return fetchWithAuth("/api/v1/companies/getAll?limitKeys=true", "GET");
    };

    // get single company
    const getCompany = async (companyId) => {
        return fetchWithAuth(`/api/v1/companies/${companyId}`, "GET");
    };

    // get people
    const getPeople = async (companyId) => {
        return fetchWithAuth(`/api/v1/people/getAll?company=${companyId}`, "GET");
    };

    // get stage data
    const getStageData = async () => {
        return fetchWithAuth("/api/v1/opportunities/getAll?groupBy=status", "GET");
    };

    const getTopTenOpportunities = async (timeRange = "THIS_MONTH") => {
        return fetchWithAuth(`/api/v1/opportunities/analytics?sortBy=value&sortOrder=desc&limit=10&timeRange=${timeRange}`, "GET");
    };

    return {
        getOpportunities,
        createOpportunity,
        updateOpportunity,
        deleteOpportunities,
        getCompanies,
        getCompany,
        getPeople,
        updatePointOfContact,
        getStageData,
        getTopTenOpportunities,
    };
};

export default useOpportunities;
