import { useState } from "react";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";
import ConfirmDialog from "@/components/email_validation/ConfirmDialog";
import { ReactComponent as TableEmailSVG } from "@/assets/image/bulk/table_email.svg";
import { ReactComponent as TableTXTSVG } from "@/assets/image/bulk/table_TXT.svg";
import FileUploadValidationWidget from "./FileUploadValidationWidget";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation } from "@tanstack/react-query";
import { S3_URL } from "@/utils/constants";
import { useNavigate } from "react-router-dom";
import { useCredits } from "@/hooks/useCredits";
import LimitModal from "../search/LimitModal";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function EmailUploadWidget({ userInfo }) {
    const [currentWidgetIndex, setCurrentWidgetIndex] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [validateOption, setValidateOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
    const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
    const { credits } = useCredits();

    const options = [
        {
            icon: "csv",
            header: "CSV Upload",
            content: "Upload your e-mails in a CSV file",
        },
        {
            icon: "contact",
            header: "Emails",
            content: "Give us a list of e-mails to validate",
        },
    ];
    const sendDataAsList = useHTTPRequest("/email-validation?", "PUT");
    const { mutateAsync: sendDataAsListMutation } = useMutation({
        mutationFn: sendDataAsList,
    });

    const onOptionSelected = (option) => {
        setValidateOption(option);
    };

    const handleInitiateLoading = async (values) => {
        // Validate list name
        if (!values.emailListName) {
            toast.error("Please fill in the list name you'd like to validate");
            return;
        }
        if (values.emailListName.length > 80) {
            toast.error("List name is too long!");
            return;
        }

        // Validate email list
        if (!values.list) {
            toast.error("Please add at least one email");
            return;
        }

        // Validate email format
        const emails = values.list.split(",").map((value) => value.trim());
        const invalidEmails = emails.filter((email) => !emailRegex.test(email));

        if (invalidEmails.length > 0) {
            toast.error("Email list formatting is invalid");
            return;
        }

        setFormValues(values);
        setVisibleConfirmDlg(true);
    };

    const handleForm = async () => {
        try {
            setLoading(true);
            const formattedEmails = formValues.list.split(",").map((value) => {
                return { email: value.trim() };
            });
            if (credits?.data?.available < formattedEmails.length || credits?.data?.available === 0) {
                setIsLimitModalOpen(true);
                toast.error("You don't have enough email validation credits, please refill your credits");
                setLoading(false);
                return;
            }
            await sendDataAsListMutation(
                JSON.stringify({
                    emails: formattedEmails,
                    fileName: formValues.emailListName,
                })
            );
            toast.success("Successfully uploaded!");
            navigate("/bulkenrich");
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong, try again");
        } finally {
            setLoading(false);
        }
    };

    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex flex-col md:flex-row font-Inter mt-2 text-center text-lg justify-start gap-2 border-b border-b-accents-gray">
                {options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                onOptionSelected(index);
                            }}
                            className={
                                "group text-sm font-medium text-[#888888] border-b-2 border-b-white p-2 cursor-pointer aria-selected:text-black aria-selected:border-b-black" +
                                (index === 0 ? " ml-6" : "")
                            }
                            aria-selected={index === validateOption}
                        >
                            {option.header}
                        </div>
                    );
                })}
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-10">
                <div className="flex flex-col w-full">
                    {validateOption === 0 && currentWidgetIndex === 1 && (
                        <div className="flex flex-col gap-8 px-6">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2 font-Inter text-[#5d5d5d] text-sm font-semibold leading-tight">
                                    <p>Please upload your contact list in CSV or TXT format</p>
                                    <p>
                                        CSV file must include a column named "Email" where each cell in that column contains an individual
                                        email address.
                                    </p>
                                    <p>TXT file should contain list of emails separated by comma</p>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full md:space-between">
                                <TableEmailSVG />
                                <div className="my-auto flex items-center justify-center">
                                    <p className="font-Inter text-[#050505] text-base font-normal  leading-tight">OR</p>
                                </div>
                                <TableTXTSVG />
                            </div>
                            <div className="flex p-6 justify-between items-center rounded-lg border border-[#e7e7e7] w-fit gap-10">
                                <div className="flex flex-col gap-2">
                                    <p className="font-Inter text-left text-[#050505] text-sm font-semibold leading-normal">
                                        Use our template for the most accurate results
                                    </p>
                                    <span className="font-Inter text-left text-[#5d5d5d] text-sm font-medium leading-tight">
                                        Download our CSV template for correct column format.
                                    </span>
                                </div>
                                <button
                                    onClick={() => {
                                        window.open(S3_URL.KOMPASS_EMAIL_TEMPLATE, "_blank");
                                    }}
                                    className="w-fit py-2 font-InterMedium px-4 flex items-center gap-2 text-white bg-[#050505] whitespace-nowrap rounded-lg text-sm hover:opacity-80 transition-opacity duration-300"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1348_8973" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                            <rect width="20" height="20" fill="#ffffff" />
                                        </mask>
                                        <g mask="url(#mask0_1348_8973)">
                                            <path
                                                d="M10 13L6 9L7.0625 7.9375L9.25 10.125V3H10.75V10.125L12.9375 7.9375L14 9L10 13ZM5.49417 16C5.08139 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.8531 15.2656 15.5592 15.5594C15.2653 15.8531 14.9119 16 14.4992 16H5.49417Z"
                                                fill="#ffffff"
                                            />
                                        </g>
                                    </svg>
                                    Fuse AI CSV Template
                                </button>
                            </div>
                        </div>
                    )}

                    {validateOption === 0 && currentWidgetIndex === 2 && (
                        <FileUploadValidationWidget
                            setCurrentWidgetIndex={setCurrentWidgetIndex}
                            setIsLimitModalOpen={setIsLimitModalOpen}
                        ></FileUploadValidationWidget>
                    )}

                    {validateOption === 1 && (
                        <div className="w-full flex flex-col items-center justify-center text-gray-950">
                            <form
                                className="flex w-full flex-col gap-6"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    const formData = {
                                        emailListName: e.target.emailListName.value,
                                        list: e.target.list.value,
                                    };
                                    handleInitiateLoading(formData);
                                }}
                            >
                                <div className="flex flex-col gap-2 px-6">
                                    <span className="font-Inter text-left text-[#050505] text-sm font-semibold leading-tight">
                                        List name
                                    </span>
                                    <input
                                        name="emailListName"
                                        className="outline-none max-w-[630px] py-3 px-6 font-Inter text-sm w-full placeholder:text-[#B0B0B0] font-medium border-[#e7e7e7] border bg-white rounded-[8px]"
                                        placeholder="List name"
                                    />
                                </div>
                                <div className="flex flex-col border border-[#e7e7e7] rounded-lg max-w-[630px] ml-6">
                                    <div className="text-sm font-medium text-[#5D5D5D] rounded-t-lg border-b border-b-[#e7e7e7] font-Inter bg-[#F4F3F0] p-3 w-full">
                                        Paste e-mail addresses separated by a comma (,) up to 100 addresses
                                    </div>
                                    <textarea
                                        name="list"
                                        className="outline-none resize-none font-Inter p-3 text-sm font-medium w-full placeholder:text-[#B0B0B0] bg-white rounded-b-lg"
                                        rows={7}
                                        placeholder="youremail1@gmail.com, youremail2@gmail.com"
                                    />
                                </div>
                                <div className="flex flex-row mt-6 border-t pt-6 border-t-[#e7e7e7] justify-start gap-4">
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => {
                                            navigate("/bulkenrich");
                                        }}
                                        className="bg-white ml-6 w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-[#888888] text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-stone-50 transition-opacity"
                                    >
                                        Go back
                                    </Button>
                                    <Button
                                        disabled={isLoading}
                                        type="submit"
                                        className="w-[110px] text-white bg-[#050505] font-Inter rounded-lg text-sm font-medium leading-tight py-2 px-6 normal-case hover:opacity-80 transition-opacity duration-300"
                                    >
                                        Finish
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                    {currentWidgetIndex !== 2 && validateOption === 0 && (
                        <div className="flex flex-col gap-4 border-t border-t-[#e7e7e7] w-full mt-5">
                            {validateOption === 0 && currentWidgetIndex === 1 && (
                                <div className="flex flex-row mt-6 ml-6 justify-start gap-4">
                                    <Button
                                        onClick={() => {
                                            navigate("/bulkenrich");
                                        }}
                                        className="bg-white w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-[#888888] text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-stone-50 transition-opacity"
                                    >
                                        Go back
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setCurrentWidgetIndex(2);
                                        }}
                                        className="text-white bg-[#050505] font-Inter rounded-lg text-sm font-medium leading-tight py-2 px-6 normal-case hover:opacity-80 transition-opacity duration-300"
                                    >
                                        Proceed to upload
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <ConfirmDialog
                open={visibleConfirmDlg}
                handleClose={() => {
                    setVisibleConfirmDlg(false);
                }}
                handleOK={() => {
                    setVisibleConfirmDlg(false);
                    handleForm();
                }}
            />
            <LimitModal
                isOpen={isLimitModalOpen}
                onClose={() => {
                    setIsLimitModalOpen(false);
                }}
                type={userInfo?.permission === 1 ? "teamMember" : "default"}
            />
        </>
    );
}
