import { Compose, Engaged, UnReadIcon } from "@/components/icons/InboxIcon";

export const LeftMenu = ({ activeTab, handleChangeTab, setComposeNewChatOpen }) => {
    return (
        <div className="h-full border-r border-[#E7E7E7] bg-white col-span-1 sm:mb-1">
            <div className="border-b border-[#E7E7E7] flex items-center justify-center px-[24px] py-[16px] h-[76px]">
                <button
                    className="w-full bg-[#090C05] cursor-pointer flex items-center justify-center px-[24px] py-[12px] rounded-[8px] gap-[8px] hover:opacity-80 transition-all"
                    onClick={() => setComposeNewChatOpen(true)}
                >
                    <Compose width={20} height={20} />
                    <span className="text-white font-Inter text-[14px] font-medium">Compose Message</span>
                </button>
            </div>
            <div className="flex flex-col px-[24px] py-[12px] gap-[12px] mailList_container">
                <button
                    className={`h-[36px] w-full px-[12px] flex items-center hover:bg-[#F4F3F0] transition-colors gap-[8px] rounded-[8px] ${activeTab === "inbox" ? "bg-[#F4F3F0]" : ""}`}
                    onClick={() => handleChangeTab("inbox")}
                >
                    <Engaged />
                    <span className="text-[14px] font-medium font-Inter text-[#050505]">InMail</span>
                </button>
                <button
                    className={`h-[36px] w-full px-[12px] flex items-center hover:bg-[#F4F3F0] transition-colors gap-[8px] rounded-[8px] ${activeTab === "unread" ? "bg-[#F4F3F0]" : ""}`}
                    onClick={() => handleChangeTab("unread")}
                >
                    <UnReadIcon />
                    <span className="text-[14px] font-medium font-Inter text-[#050505]">Unread</span>
                </button>
            </div>
        </div>
    );
};
