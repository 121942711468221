import * as React from "react";
import { Button } from "@material-tailwind/react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function NoMailBoxButton() {
    const navigate = useNavigate();

    return (
        <div className="flex justify-center items-center h-[500px]">
            <div className="p-7">
                <div className="font-Inter p-3 text-xl font-bold text-black">Please add at least one Mailbox to proceed</div>
                <div className="font-Inter mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                    <Button
                        className="main-black-button !capitalize"
                        onClick={() => {
                            navigate("/settings");
                        }}
                    >
                        Add Mailbox
                    </Button>
                </div>
            </div>
        </div>
    );
}
