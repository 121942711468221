import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import IpCreditSlider from "./IPCreditSlider";
import { CircularProgress } from "@mui/material";
import { CustomButton } from "../crm/contacts/Button";
import { useCredits } from "@/hooks/useCredits";
import { useQuery } from "@tanstack/react-query";

const options = [
    0, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000,
    100000,
];

export default function AccountDetails({ userId }) {
    const [availableCredits, setAvailableCredits] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [usedCredits, setUsedCredits] = useState(0);
    const [priceIndex, setPriceIndex] = useState(0);
    const [newCreditsAmount, setNewCreditsAmount] = useState(0);
    const [isAllocating, setIsAllocating] = useState(false);
    const [isUpdatingDomains, setIsUpdatingDomains] = useState(false);
    const { getAvailableCredits, allocateCredits, getAllowedDomains, updateAllowedDomains } = useIPsDeanonymization();
    const getAvailableCreditsRef = useRef();
    getAvailableCreditsRef.current = getAvailableCredits;
    const { credits, refetchCredits } = useCredits();

    const {
        data: allowedDomains,
        refetch: refetchAllowedDomains,
        isLoading: isLoadingDomains,
    } = useQuery({
        queryFn: getAllowedDomains,
    });

    useEffect(() => {
        setIsLoading(true);

        const getCredits = async () => {
            const credits = await getAvailableCreditsRef.current();
            setIsLoading(false);
            setAvailableCredits(credits?.availableCredits || 0);
            setUsedCredits(credits?.usedCredits || 0);
        };
        getCredits();
    }, []);

    useEffect(() => {
        setNewCreditsAmount(options[priceIndex]);
    }, [priceIndex]);

    const handleAllocateCredits = async () => {
        if (credits?.data?.available && newCreditsAmount > credits?.data?.available) {
            toast.error("You don't have enough credits to allocate");
            return;
        }

        const params = { amount: newCreditsAmount };
        setIsAllocating(true);

        const response = await allocateCredits(params);

        setIsAllocating(false);

        if (response.status) {
            toast.success("Credits allocated succesfully");
            setIsLoading(true);
            const credits = await getAvailableCredits();
            setIsLoading(false);
            setAvailableCredits(credits?.availableCredits || 0);
            await refetchCredits();
        } else {
            toast.error(response?.message || "Error while allocating credits. Please try again");
        }
    };

    const updateDomains = async (newDomains) => {
        if (userId) {
            const response = await updateAllowedDomains({
                userId,
                domain: newDomains,
            });

            if (response === "Domains list updated successfully.") {
                await refetchAllowedDomains();
            }
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            domain: "",
        },
        validationSchema: Yup.object({
            domain: Yup.string()
                .matches(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/, "Invalid format. Example: domain.com")
                .required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                setIsUpdatingDomains(true);
                const newDomain = values.domain?.trim()?.toLowerCase();
                if (!allowedDomains.includes(newDomain)) {
                    const updatedDomains = [...allowedDomains, newDomain];
                    await updateDomains(updatedDomains);
                } else {
                    toast.error("Domain already exists");
                }
                formik.resetForm();
            } catch (error) {
                console.log("Error while updating domains: ", error);
            } finally {
                setIsUpdatingDomains(false);
            }
        },
    });

    const handleDomainDelete = async (domain) => {
        try {
            setIsUpdatingDomains(true);
            const updatedDomains = allowedDomains.filter((d) => d !== domain);
            await updateDomains(updatedDomains);
        } catch (error) {
            console.log("Error while deleting domain: ", error);
        } finally {
            setIsUpdatingDomains(false);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                if (!formik.isValid) {
                    toast.error("Please enter a valid domain");
                } else {
                    formik.submitForm();
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [formik]);

    return (
        <div className="font-Inter">
            <div className="w-full h-[125px] border-b border-[#e7e7e7] p-6 bg-white flex-col justify-center items-start gap-4 inline-flex">
                <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="self-stretch text-[#050505] text-base font-semibold font-['Inter']">Website Visitor Report Setup</div>
                </div>
                <div className="self-stretch text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">
                    To enable the IP deanonymization feature, please copy the script from the field above and paste it into the head tag of
                    the website where IP parsing should be enabled. Additionally, to ensure security, you should add the domain of this
                    website to the list of allowed domains in the field above.
                </div>
            </div>
            <div className="p-[24px]">
                <div className="flex flex-col gap-2 mb-10 text-[#050505]">
                    <label>
                        <div className=" justify-start items-center gap-2 inline-flex">
                            <div className="text-[#050505] text-sm font-semibold font-['Inter'] capitalize">Script Tag</div>
                            <div className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">
                                (copy the script and paste it into the head tag of the website where IP parsing should be enabled)
                            </div>
                        </div>
                    </label>
                    <textarea
                        rows={1}
                        className="max-w-[1000px] p-2 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[12px] xl:text-[14px] resize-none"
                        value={`<script async src="${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/collect-data?userId=${userId}"></script>`}
                        readOnly
                    />
                </div>
                <div className="flex flex-col gap-2 mb-12 w-full">
                    <label>
                        <div className=" justify-start items-center gap-2 inline-flex">
                            <div className="text-[#050505] text-sm font-semibold font-['Inter'] capitalize">Allowed domains</div>
                            <div className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">
                                (add domain of the website where IP parsing should be enabled to the list of allowed domains)
                            </div>
                        </div>
                    </label>
                    {!isUpdatingDomains && !isLoadingDomains && allowedDomains && allowedDomains.length > 0 && (
                        <div className="flex gap-3 max-w-[800px]">
                            {allowedDomains.map((domain) => (
                                <div key={domain} className="relative bg-[#f6f6f6] rounded-[4px] px-3 py-2">
                                    <p className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">{domain}</p>
                                    <button
                                        className="absolute right-[1px] top-[1px]"
                                        onClick={async () => await handleDomainDelete(domain)}
                                    >
                                        <svg width="12" height="12" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_125_1944" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                                                <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_125_1944)">
                                                <path
                                                    d="M4.19014 13.0898L3.41248 12.3121L7.22348 8.50094L3.41248 4.68978L4.19014 3.91211L8.00131 7.72311L11.8125 3.91211L12.5901 4.68978L8.77914 8.50094L12.5901 12.3121L11.8125 13.0898L8.00131 9.27878L4.19014 13.0898Z"
                                                    fill="#1C1B1F"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    {(isUpdatingDomains || isLoadingDomains) && (
                        <div className="flex items-center min-h-[37px]">
                            <p className="text-[#5d5d5d] text-sm font-['Inter'] leading-[21px]">Loading...</p>
                        </div>
                    )}
                    <div className="w-full relative pb-5">
                        <input
                            value={formik.values.domain}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                            name="domain"
                            disabled={isUpdatingDomains || isLoadingDomains}
                            placeholder="domain.com"
                            className="w-full max-w-[1000px] px-4 py-2 rounded-[8px] border border-stone-250 focus:outline-none opacity-90 text-[12px] xl:text-[14px] font-light leading-[1.4] resize-none	"
                        />
                        {formik.touched.domain && formik.errors.domain && (
                            <p className="w-full absolute text-red-500 text-xs mt-1">{formik.errors.domain}</p>
                        )}
                    </div>
                </div>
                <div className="mb-10">
                    <div className="text-[#050505] text-base mb-6 font-semibold font-['Inter']">Credits Summary</div>
                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="w-full flex flex-col gap-2 max-w-[536px] px-6 py-4 border border-[#E7E7E7] rounded-[8px]">
                            {isLoading && <p className="text-left text-[#050505] text-base font-semibold font-['Inter']">Loading...</p>}
                            {!isLoading && (
                                <p className="text-left text-[#050505] text-base font-semibold font-['Inter']">{availableCredits}</p>
                            )}
                            <hr className="border-[#e7e7e7]"></hr>
                            <p className="text-[#6d6d6d] text-sm font-medium font-['Inter'] leading-[21px]">Remaining credits</p>
                        </div>
                        <div className="w-full flex flex-col gap-2 max-w-[536px] px-6 py-4 border border-[#E7E7E7] rounded-[8px]">
                            {isLoading && <p className="text-left text-[#050505] text-base font-semibold font-['Inter']">Loading...</p>}
                            {!isLoading && <p className="text-left text-[#050505] text-base font-semibold font-['Inter']">{usedCredits}</p>}
                            <hr className="border-[#e7e7e7]"></hr>
                            <p className="text-[#6d6d6d] text-sm font-medium font-['Inter'] leading-[21px]">Used credits</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="text-[#050505] text-base font-semibold font-['Inter']">Allocate Credits for Website Visitors</div>
                    <div className="px-3">
                        <IpCreditSlider options={options} state={[priceIndex, setPriceIndex]} />
                    </div>

                    <CustomButton
                        onClick={handleAllocateCredits}
                        disabled={isAllocating || newCreditsAmount <= 0}
                        title={isAllocating ? <CircularProgress size={20} color="inherit" /> : "Allocate Credits"}
                        height={40}
                        width={144}
                        px={24}
                        py={12}
                        titleTextSize={14}
                        titleFontWeight={500}
                    />
                </div>
            </div>
        </div>
    );
}
