import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as MailIcon } from "@/assets/image/ai_engagement/mailIcon.svg";
import { ReactComponent as LinkedIcon } from "@/assets/image/ai_engagement/linkedinIcon.svg";
import { ReactComponent as InternetIcon } from "@/assets/image/ai_engagement/internetIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress, Pagination } from "@mui/material";
import AllTabs from "@/components/ai_engagement/AllTabs";
import MainLayout from "@/components/MainLayout";
import cx from "classnames";
import MainTitleBar from "@/components/MainTitleBar";

export default function CampaignContacts() {
    const [targetContacts, setTargetContacts] = useState([]);
    const [campaignName, setCampaignName] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    const navigate = useNavigate();
    const { campaignId } = useParams();

    const { getTargetContacts, getCampaignData } = useAIEngagement();

    const getTargetContactsRef = useRef();
    getTargetContactsRef.current = getTargetContacts;
    const getCampaignDataRef = useRef();
    getCampaignDataRef.current = getCampaignData;

    useEffect(() => {
        const fetchContacts = async () => {
            setLoading(true);
            try {
                const res = await getTargetContactsRef.current(campaignId);
                setTargetContacts(res.data);
                const campaignRes = await getCampaignDataRef.current(campaignId);
                setCampaignName(campaignRes.data?.campaignName || "");
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };

        fetchContacts();
    }, [campaignId]);

    const handleChangePage = (_, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleTitleClick = (name) => {
        navigate(`/campaigns/${campaignId}/contacts/${name}`);
    };

    const paginatedData = Array.isArray(targetContacts)
        ? targetContacts.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((targetContact) => ({
              ...targetContact,
              status: targetContact.outcome ?? targetContact.schedulerStatus,
          }))
        : [];

    const startItem = (page - 1) * rowsPerPage + 1;
    const endItem = Math.min(page * rowsPerPage, targetContacts?.length || 0);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full">
                <AllTabs />
                <div className="p-[24px]">
                    <p className="main-text-black-600 !text-dgray-500">
                        {" "}
                        <span onClick={() => navigate("/ai-engagement/campaigns")} className="cursor-pointer">
                            Campaigns
                        </span>{" "}
                        / <span className="text-[#2D7A89]">Leads</span>
                    </p>
                    {loading && (
                        <div className="flex justify-center items-center h-[400px]">
                            <CircularProgress />
                        </div>
                    )}
                    {!loading && targetContacts?.length <= 0 && (
                        <div className="flex justify-center items-center h-[400px]">
                            <p className="text-lg">Your campaign is activating, check back soon...</p>
                        </div>
                    )}
                    {!loading && targetContacts?.length > 0 && (
                        <>
                            <h1 className="mt-[40px] main-text-black-600 !text-[20px]">{campaignName}</h1>
                            <div className="mt-5">
                                <div className="relative campaign-card overflow-x-auto custom-scrollbar-section">
                                    <div className="min-w-[900px]">
                                        <div className="grid grid-cols-7 gap-x-4 bg-dgray-100 p-2 px-6 rounded-lg">
                                            <div className="main-text-black-600 col-span-3">Lead Details</div>
                                            <div className="main-text-black-600 col-span-2">Other Details</div>
                                            <div className="main-text-black-600 col-span-2 text-center">Status</div>
                                        </div>
                                        {paginatedData.map((contact, index) => {
                                            const selectedSeq = contact.activities
                                                .filter((activity) => activity.type === "sequence") // Retain only 'ai_topic' template types
                                                .reduce((acc, activity, idx) => {
                                                    const seqName = `${idx + 1}. ${activity.channel === "email" ? "Email" : "LinkedIn"}`;
                                                    acc[seqName] = activity.status === "sent";
                                                    return acc;
                                                }, {});

                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="grid grid-cols-7 mt-4 gap-x-4 bg-white p-3 px-6">
                                                        <div className="col-span-3">
                                                            <h2
                                                                className="font-semibold cursor-pointer main-text-black-600 !text-[16px]"
                                                                onClick={() => handleTitleClick(contact._id)}
                                                            >
                                                                {contact.fullName}
                                                            </h2>
                                                            <div className="text-dgray-500 flex items-center">
                                                                <MailIcon className="w-4 h-4 mr-1" />
                                                                <p className="text-sm">{contact.primaryEmail}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-2">
                                                            {contact.linkedinUrl && (
                                                                <div className="text-dgray-500 flex items-center">
                                                                    <LinkedIcon className="w-4 h-4 mr-1" />
                                                                    <p className="text-sm">{contact.linkedinUrl}</p>
                                                                </div>
                                                            )}

                                                            {contact.companyUrl && (
                                                                <div className="text-[#2D7A89] flex items-center mt-0.5">
                                                                    <InternetIcon className="w-4 h-4 mr-1" />
                                                                    <p className="font-medium text-sm">{contact.companyUrl}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-span-2 flex items-center justify-center">
                                                            <p
                                                                className={cx({
                                                                    "font-medium capitalize text-green-500": [
                                                                        "replied",
                                                                        "interested",
                                                                        "calender_booked",
                                                                    ].includes(contact.status),
                                                                    "font-medium capitalize text-yellow-500": [
                                                                        "active",
                                                                        "initializing",
                                                                    ].includes(contact.status),
                                                                    "font-medium capitalize text-red-500": [
                                                                        "stopped",
                                                                        "no_response",
                                                                        "unsubscribed",
                                                                        "bad_timing",
                                                                        "wrong_contact",
                                                                    ].includes(contact.status),
                                                                })}
                                                            >
                                                                {contact.status.replaceAll("_", " ")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center mt-4 gap-3 w-full py-4 px-6 border-t border-b border-dgray-300">
                                                        <div className="flex items-center gap-4">
                                                            <p className="whitespace-nowrap text-sm">Sequence status</p>
                                                            {Object.keys(selectedSeq).map((seq) => (
                                                                <div key={seq} className="flex items-center gap-x-1.5">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={seq}
                                                                        name={seq}
                                                                        checked={selectedSeq[seq]}
                                                                        readOnly
                                                                        className="transform scale-105 pointer-events-none accent-[#2D7A89]"
                                                                    />
                                                                    <label htmlFor={seq} style={{ fontSize: "12px", marginTop: "1px" }}>
                                                                        {seq}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <p className="text-dgray-500 ml-auto whitespace-normal text-sm">
                                                            Email used: {contact.inboxId}
                                                        </p>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                                {targetContacts?.length > 0 && (
                                    <div className="flex items-center gap-4 mt-7 mb-16">
                                        <p className="whitespace-nowrap main-text-black-500 !text-[12px]">
                                            Showing {startItem}-{endItem} of {targetContacts?.length} items
                                        </p>
                                        <Pagination
                                            count={Math.ceil(targetContacts?.length / rowsPerPage)}
                                            page={page}
                                            onChange={handleChangePage}
                                            sx={{
                                                ml: "auto",
                                                "& .MuiPaginationItem-root": {
                                                    fontFamily: "Inter, sans-serif",
                                                    color: "#050505",
                                                    border: "1px solid #e7e7e7",
                                                    borderRadius: "4px",
                                                },
                                                "& .MuiPaginationItem-root.Mui-selected": {
                                                    backgroundColor: "#2D7A89",
                                                    color: "#fff",
                                                    borderRadius: "4px",
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </MainLayout>
    );
}
