import * as React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import SortIcon from "@/components/icons/SortIcon";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const headCells = [
    {
        id: "firstName",
        label: "Name",
        icon: <PersonOutlineOutlinedIcon className="!h-5 !w-5" />,
    },
    {
        id: "info",
        label: "Contact Info",
        icon: <PhoneOutlinedIcon className="!h-4 !w-4" />,
    },
    {
        id: "title",
        label: "Designation",
        icon: <AccountBoxOutlinedIcon className="!h-4 !w-4" />,
    },
    {
        id: "company",
        label: "Company",
        icon: <CorporateFareOutlinedIcon className="!h-4 !w-4" />,
    },
    {
        id: "country",
        label: "Country",
        icon: <MapOutlinedIcon className="!h-4 !w-4" />,
    },
];

function DirectoryTableHeader(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "12px 16px",
                    fontFamily: "Inter",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        // Exclude last cell
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"none"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            width: "20%",
                        }}
                    >
                        {headCell.label && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    color: "#6D6D6D",
                                }}
                            >
                                <div className="flex items-center gap-2">
                                    {headCell.icon}
                                    {headCell.label}
                                </div>
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default DirectoryTableHeader;
