import AllTabs from "@/components/ai_engagement/AllTabs";
import MainLayout from "@/components/MainLayout";
import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import TargetCustomerPersona from "@/components/ai_engagement/campaignsTab/TargetCustomerPersona";
import CampaignPitch from "@/components/ai_engagement/campaignsTab/CampaignPitch";
import CampaignSequence from "@/components/ai_engagement/campaignsTab/CampaignSequence";
import CampaignSchedule from "@/components/ai_engagement/campaignsTab/CampaignSchedule";
import { useLocation } from "react-router-dom";
import useAIEngagement from "@/hooks/useAIEngagement";
import MainTitleBar from "@/components/MainTitleBar";

const steps = ["1. Target Customer Persona", "2. Campaign Pitch", "3. Sequence", "4. Schedule"];

export default function CreateNewCampaign() {
    const [selectedStep, setSelectedStep] = useState(steps[0]);
    const [campaignId, setCampaignId] = useState(null);
    const [editCampaignData, setEditCampaignData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const swiperRef = useRef(null);

    const { getCampaignById } = useAIEngagement();
    const getCampaignByIdRef = useRef();
    getCampaignByIdRef.current = getCampaignById;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const editCampaignId = query.get("campaignId");

    useEffect(() => {
        const getEditCampaignData = async () => {
            if (editCampaignId) {
                setIsEdit(true);
                const res = await getCampaignByIdRef.current(editCampaignId);
                if (res.status) {
                    setEditCampaignData(res.data);
                    setCampaignId(editCampaignId);
                }
                setIsEdit(false);
            }
        };
        getEditCampaignData();
    }, [editCampaignId]);

    // Effect to sync the swiper with the selected step
    useEffect(() => {
        if (swiperRef.current) {
            const index = steps.indexOf(selectedStep);
            swiperRef.current?.swiper?.slideTo(index, 500, false);
        }
    }, [selectedStep]);

    const handleBackClick = () => {
        const index = steps.indexOf(selectedStep);
        if (index > 0) {
            setSelectedStep(steps[index - 1]);
        }
    };

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>
            <AllTabs />
            <div className="w-full bg-white p-[24px]">
                <div className="mb-[24px] flex gap-x-4 items-center justify-between">
                    <Swiper ref={swiperRef} slidesPerView={"auto"} spaceBetween={10} className="!mx-0">
                        {steps.map((step, index) => (
                            <SwiperSlide className="!w-auto" key={index}>
                                <p
                                    className={clsx(
                                        {
                                            "bg-[#F4F3F0] text-accents-mainBlack": selectedStep === step,
                                        },
                                        "main-text-black-500 py-[6px] px-[12px] rounded-[8px] !text-[#5D5D5D]"
                                    )}
                                >
                                    {step}
                                </p>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {steps.indexOf(selectedStep) > 0 && (
                        <button onClick={handleBackClick} className="main-white-button">
                            Go Back
                        </button>
                    )}
                </div>
                {/* Conditionally render components based on selected step */}
                <div className={`${selectedStep === "1. Target Customer Persona" ? "block" : "hidden"}`}>
                    <TargetCustomerPersona
                        setSelectedStep={setSelectedStep}
                        editCampaignData={editCampaignData}
                        campaignId={campaignId}
                        setCampaignId={setCampaignId}
                        isEdit={isEdit}
                    />
                </div>
                <div className={`${selectedStep === "2. Campaign Pitch" ? "block" : "hidden"}`}>
                    <CampaignPitch editCampaignData={editCampaignData} setSelectedStep={setSelectedStep} campaignId={campaignId} />
                </div>
                <div className={`${selectedStep === "3. Sequence" ? "block" : "hidden"}`}>
                    <CampaignSequence
                        editCampaignData={editCampaignData}
                        setSelectedStep={setSelectedStep}
                        selectedStep={selectedStep}
                        campaignId={campaignId}
                    />
                </div>
                <div className={`${selectedStep === "4. Schedule" ? "block" : "hidden"}`}>
                    <CampaignSchedule editCampaignData={editCampaignData} campaignId={campaignId} setCampaignId={setCampaignId} />
                </div>
            </div>
        </MainLayout>
    );
}
