import React, { useState, useEffect } from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import TranscriptsTable from "@/components/crm/transcript/TranscriptsTable";
import { ReactComponent as EmptyStateIcon } from "@/assets/image/crm/notes/emptyState.svg";
import MainLoader from "@/components/MainLoader";
import useTranscripts from "@/hooks/crm/useTranscripts";
import MainTitleBar from "@/components/MainTitleBar";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function TranscriptsScene() {
    const [transcripts, setTranscripts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { getTranscriptNotes } = useTranscripts();
    const rowsPerPage = 10;

    const fetchTranscripts = async (pageNum) => {
        setLoading(true);
        try {
            const response = await getTranscriptNotes({
                pageNum,
                limit: rowsPerPage,
            });
            if (response?.data) {
                setTranscripts(response.data);
                if (response.totalCount) {
                    setTotalPages(Math.ceil(response.totalCount / rowsPerPage));
                }
            }
        } catch (error) {
            console.error("Error fetching transcripts:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTranscripts(page);
    }, [page]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    if (loading) {
        return (
            <MainLayout>
                <MainLoader />
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <MainTitleBar>
                <p>Transcript</p>
            </MainTitleBar>
            <BaseContainer>
                {transcripts.length > 0 ? (
                    <div className="pb-20 mt-6">
                        <TranscriptsTable
                            data={transcripts}
                            setData={setTranscripts}
                            page={page}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            rowsPerPage={rowsPerPage}
                        />
                    </div>
                ) : (
                    <div className="flex flex-col items-center mt-10">
                        <EmptyStateIcon />
                        <h2 className="font-semibold mt-4">No Transcripts Yet!</h2>
                        <p className="mt-5 text-[#5D5D5D] font-medium text-sm">Your meeting transcripts will appear here.</p>
                    </div>
                )}
            </BaseContainer>
            <FuseAgentModal />
        </MainLayout>
    );
}
