import React from "react";
import { menuProductList, menuResourceLearnList } from "@/utils/common";
import { Link } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import { Box } from "@mui/material";
import RightArrow from "@/components/svgComponent/RightArrow";
import { ReactComponent as Overview } from "@/assets/image/icons/Overview.svg";
import { ReactComponent as LearnSVG } from "@/assets/image/base/learn.svg";

export function MobileNavBar(props) {
    return (
        <div className="w-full">
            {props?.isProduct && <MobileMenuProducts />}
            {props?.isResources && <MobileMenuResources />}
        </div>
    );
}

const MobileMenuProducts = () => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4 w-full px-2 py-3 bg-[#E9E9E9] rounded-2xl">
                <Overview className="h-6 w-6" />
                <p className="text-base font-Inter text-gray-950">Products Overview</p>
            </div>
            <div className="flex flex-col items-start gap-4">
                {menuProductList.map((item, ind) => {
                    return (
                        <Link
                            key={ind}
                            to={item.router}
                            className="flex items-center gap-2 self-stretch"
                            onClick={() => {
                                document.body.style.overflow = "";
                            }}
                        >
                            <Typography className="peer font-Inter text-base text-stone-950 hover:text-[#0a85ea]">{item.label}</Typography>
                            <Box className="peer-hover:translate-x-1">
                                <RightArrow color="#0A2E31" />
                            </Box>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

const MobileMenuResources = () => {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4 w-full px-2 py-3 bg-[#E9E9E9] rounded-2xl">
                <LearnSVG className="h-6 w-6 fill-current text-gray-950" />
                <p className="text-base font-Inter text-gray-950">Learn</p>
            </div>
            <div className="flex flex-col items-start gap-4">
                {menuResourceLearnList.map((item, ind) => {
                    return (
                        <Link
                            key={ind}
                            to={item.router}
                            className="flex items-center gap-2 self-stretch"
                            onClick={() => {
                                document.body.style.overflow = "";
                            }}
                        >
                            <Typography className="peer font-Inter text-base text-stone-950 hover:text-[#0a85ea]">{item.label}</Typography>
                            <Box className="peer-hover:translate-x-1">
                                <RightArrow color="#0A2E31" />
                            </Box>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};
