import React, { useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { CircularProgress, TableCell, TableRow, Box, Pagination, PaginationItem } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import NotesTableHeader from "./AccountsTableHeader";
import NotesTableRow from "./AccountsTableRow";
import { sortTableData } from "@/components/crm/utils";

export default function AccountsTable({ data, setData, selected, setSelected, loading }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("title");
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const rowsPerPage = 10;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedData = sortTableData(data, property, isAsc);
        setData(sortedData);
        setPage(1); // Reset to first page on sort
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = filteredData.map((n) => n._id);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
        setPage(1); // Reset to the first page when searching
    };

    // Filter data based on search query
    const filteredData = useMemo(() => {
        return data.filter((item) => item.name?.toLowerCase().includes(searchQuery));
    }, [data, searchQuery]);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const visibleRows = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return filteredData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredData, page, rowsPerPage]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <div className="flex p-2 pl-2 rounded-lg max-w-[400px] text-dgray-6 items-center bg-white border border-stone-250">
                <SearchOutlinedIcon className="!w-5 !h-5" color="inherit" />
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-full rounded-lg outline-none pl-2 font-Inter font-normal text-base placeholder-dgray-400"
                    placeholder="Search by Company Name"
                />
            </div>

            <div className="border mt-5 rounded-[8px] border-[#e7e7e7]">
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table
                        sx={{
                            minWidth: {
                                xs: 300,
                                lg: 750,
                            },
                            borderCollapse: "separate",
                        }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        <NotesTableHeader
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={filteredData.length}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    padding: "5px 12px",
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    border: "1px solid #E8E7E7",
                                },
                            }}
                        >
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} className="text-black" style={{ textAlign: "center", height: "200px" }}>
                                        <CircularProgress size={24} color="inherit" />
                                    </TableCell>
                                </TableRow>
                            ) : visibleRows.length > 0 ? (
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <NotesTableRow
                                            key={row._id}
                                            isItemSelected={isItemSelected}
                                            row={row}
                                            index={index}
                                            labelId={labelId}
                                            setSelected={setSelected}
                                        />
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: "center", height: "200px" }}>
                                        No results found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {filteredData.length > rowsPerPage && (
                <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
}
