import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Button } from "@material-tailwind/react";

export const AdvancedFilters = ({ open, onClose, children }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}
            className="!rounded-2xl"
        >
            <DialogTitle sx={{ m: 0, p: 2, fontFamily: "Inter" }} className="!text-base !font-semibold" id="customized-dialog-title">
                Advanced Search Filters
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon className="!h-4 !w-4" />
            </IconButton>
            <DialogContent dividers sx={{ padding: 0 }}>
                {children}
            </DialogContent>
            <DialogActions>
                <div className="w-full flex gap-2 justify-end bg-white">
                    <Button
                        onClick={onClose}
                        className="font-InterMedium rounded-lg bg-white px-3 py-1.5 text-sm font-medium capitalize text-stone-950 border border-stone-250"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onClose}
                        className="font-InterMedium rounded-lg bg-stone-950 px-3 py-1.5 text-sm font-medium capitalize text-white "
                    >
                        Save Filters
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
