// AssignCreditsModal.jsx
import React, { useState, useEffect } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from "@mui/material"; // Updated import
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "@/assets/image/team/close.svg";
import { CustomButton } from "../crm/contacts/Button";

export default function AssignCreditsModal({ open, handleClose, member, handleAssignCredits, isLoading }) {
    const [credits, setCredits] = useState("");

    useEffect(() => {
        // Reset credits when the modal opens
        if (open) {
            setCredits("");
        }
    }, [open]);

    const handleSubmit = () => {
        if (!credits) {
            toast.error("Please enter a credits amount.");
            return;
        }
        // Validate if needed, e.g., check for numeric value
        const numericCredits = parseInt(credits, 10);
        if (isNaN(numericCredits) || numericCredits < 0) {
            toast.error("Please enter a valid, non-negative number for credits.");
            return;
        }
        // Pass up the "credits" and the "member" to the parent
        handleAssignCredits(member, numericCredits);
    };

    return (
        <Dialog
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: "16px",
                    width: "498px",
                },
                "& .MuiDialogTitle-root": {
                    padding: 0,
                },
                "& .MuiDialogContent-root": {
                    padding: 0,
                },
                "& .MuiDialogActions-root": {
                    padding: 0,
                },
            }}
            open={!!open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle>
                {" "}
                <div className="px-6 w-full py-4 border-b border-[#e7e7e7] justify-between items-center inline-flex">
                    <div className="w-80 text-[#090c05] text-sm font-semibold font-['Inter']">Assign Credits</div>
                    <div className="w-4 h-4 ">
                        <CloseIcon onClick={handleClose} />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent sx={{ padding: "24px !important" }}>
                <Box mb="40px">
                    <div className="text-[#050505] mb-2 text-sm font-medium font-['Inter']">Assign to</div>
                    <TextField disabled value={member?.name || ""} fullWidth variant="outlined" size="small" />
                </Box>
                <Box>
                    <div className="text-[#050505] mb-2 text-sm font-semibold font-['Inter'] capitalize">Enter Credits Amount</div>
                    <TextField
                        type="number"
                        placeholder="e.g., 100"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={credits}
                        onChange={(e) => setCredits(e.target.value)}
                        inputProps={{ min: 0 }}
                    />
                </Box>
            </DialogContent>

            <div className="px-6 border-t border-[#e7e7e7] py-4 w-full flex flex-row justify-end items-center gap-[10px]">
                <CustomButton
                    onClick={handleClose}
                    title="Cancel"
                    height={29}
                    width={79}
                    px={16}
                    py={6}
                    titleTextSize={14}
                    titleFontWeight={500}
                    border="1px solid #e7e7e7"
                    titleColor="#050505"
                    bg="white"
                />
                <CustomButton
                    onClick={handleSubmit}
                    title="Assign"
                    height={29}
                    width={78}
                    px={16}
                    py={6}
                    titleTextSize={14}
                    titleFontWeight={500}
                />
            </div>
        </Dialog>
    );
}
