import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { AuthContext } from "@/context/AuthContext";

const CreditsContext = createContext();

export const CreditsProvider = ({ children }) => {
    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed", "GET");
    const { userInfo } = useContext(AuthContext);

    const { data: credits, refetch: refetchCredits } = useQuery({
        queryKey: ["CreditsUsage", userInfo?.email],
        queryFn: () => getUsageData(),
        staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    });

    return <CreditsContext.Provider value={{ credits, refetchCredits }}>{children}</CreditsContext.Provider>;
};

export const useCredits = () => useContext(CreditsContext);
