import React, { useState, useEffect, useRef, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import useAuth from "@/hooks/useAuth";
import { ReactComponent as FuseAILogo } from "@/assets/image/fuse-agent-scene.svg";

import { ChatIcon, ContentCopyIcon, KeyboardIcon, RefreshIcon, UploadIcon, VolumeUpIcon } from "@/components/ai_intelligence/Icons";
import { AuthContext } from "@/context/AuthContext";

// Loading Indicator Component
const LoadingIndicator = () => (
    <div className="flex space-x-1">
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></span>
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-400"></span>
    </div>
);

/**
 * A two-page chat UI:
 *
 * Page 1:
 *  - "Fuse Agent" top bar
 *  - A welcome screen ("Hey there!") with a single input box
 * Page 2:
 *  - The same top bar
 *  - A chat history area
 *  - Input at bottom for new messages
 */
export default function AiIntelligence() {
    const [view, setView] = useState(1); // 1 = initial (landing) screen, 2 = chat screen
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Loading state for API calls
    const { getAuthToken } = useAuth();
    const { userInfo } = useContext(AuthContext);

    const [threadId, setThreadId] = useState(null); // State to store threadId

    const messageEndRef = useRef(null);

    // Auto-scroll to the bottom of the conversation whenever messages change
    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    // Handler for text-to-speech, copying, re-fetch, etc.
    const handleSpeak = (msgText) => {
        console.log("Speak clicked for:", msgText);
        // Add TTS logic here if desired
    };

    const handleCopy = (msgText) => {
        navigator.clipboard.writeText(msgText).catch(() => console.error("Failed to copy to clipboard."));
    };

    const handleRefresh = (msgId) => {
        console.log("Refresh clicked for bot message ID:", msgId);
        // Add re-fetch or re-generate logic here if needed
    };

    // Function to send a POST request to the API
    const sendMessageToAPI = async (query) => {
        const userId = "1234567"; // Replace with dynamic user ID as needed
        const authToken = await getAuthToken();
        let API_URL = "";
        let body = {
            query: query,
            userId: userId,
        };

        if (!threadId) {
            // If there's no threadId, start a new conversation
            API_URL = "https://ma0u0t3rk2.execute-api.us-east-1.amazonaws.com/prod/agents/chat/new";
        } else {
            // Continue the existing conversation using threadId
            API_URL = `https://ma0u0t3rk2.execute-api.us-east-1.amazonaws.com/prod/agents/chat/${threadId}`;
        }

        try {
            const response = await fetch(API_URL, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }

            const data = await response.json();

            // If it's a new conversation, set the threadId
            if (!threadId && data.threadId) {
                setThreadId(data.threadId);
            }

            return data.message;
        } catch (error) {
            console.error("Error sending message to API:", error);
            return "Sorry, there was an error processing your request.";
        }
    };

    // Send the user’s message
    const handleSendMessage = async () => {
        const cleanInput = userInput.trim();
        if (!cleanInput) return;

        // Add new user message
        const newUserMessage = {
            id: Date.now(),
            sender: "user",
            text: cleanInput,
        };
        setMessages((prev) => [...prev, newUserMessage]);
        setUserInput("");

        // Switch to chat page if on the landing page
        if (view === 1) {
            setView(2);
        }

        // Create a placeholder bot message with a loading state
        const placeholderId = Date.now() + 1;
        const placeholderMessage = {
            id: placeholderId,
            sender: "bot",
            text: "", // Empty text initially
            isLoading: true, // Flag to indicate loading state
        };
        setMessages((prev) => [...prev, placeholderMessage]);

        // Set loading state
        setIsLoading(true);

        // Send the message to the API and get the response
        const botResponse = await sendMessageToAPI(cleanInput);

        // Replace the placeholder with the actual bot response
        setMessages((prev) => prev.map((msg) => (msg.id === placeholderId ? { ...msg, text: botResponse, isLoading: false } : msg)));

        // Reset loading state
        setIsLoading(false);
    };

    // Function to animate word-by-word display
    const animateResponse = (text) => {
        if (typeof text !== "string") return null;

        // This will hold the final chunks of text, with correct bolding and link rendering
        const segments = [];
        let currentSegment = "";
        let isInBold = false;

        // Loop through the text character by character
        for (let i = 0; i < text.length; i++) {
            const char = text[i];

            // If we encounter ** (bold marker), toggle the bold state
            if (char === "*" && text[i + 1] === "*") {
                // Flush any text accumulated so far
                if (currentSegment) {
                    segments.push({ text: currentSegment, isBold: isInBold });
                    currentSegment = "";
                }
                isInBold = !isInBold; // Toggle the bold state
                i++; // Skip the next '*' as we've already processed it
            } else if (char === "[") {
                // Flush any text accumulated before processing the link
                if (currentSegment) {
                    segments.push({ text: currentSegment, isBold: isInBold });
                    currentSegment = "";
                }

                // Check for a hyperlink in the format [text](link)
                const linkStart = i + 1;
                const closingBracketIndex = text.indexOf("]", linkStart);
                const openingParenthesisIndex = text.indexOf("(", closingBracketIndex);
                const closingParenthesisIndex = text.indexOf(")", openingParenthesisIndex);

                if (
                    closingBracketIndex > linkStart &&
                    openingParenthesisIndex > closingBracketIndex &&
                    closingParenthesisIndex > openingParenthesisIndex
                ) {
                    const linkText = text.slice(linkStart, closingBracketIndex);
                    const linkUrl = text.slice(openingParenthesisIndex + 1, closingParenthesisIndex);

                    // Add the link segment
                    segments.push({ text: linkText, isLink: linkUrl });
                    // Move the index past the entire link markup
                    i = closingParenthesisIndex;
                } else {
                    // If the format isn't correct, treat the "[" as normal text
                    currentSegment += char;
                }
            } else {
                currentSegment += char;
            }
        }

        // Push the final segment if it exists
        if (currentSegment) {
            segments.push({ text: currentSegment, isBold: isInBold });
        }

        // Animate each segment
        return segments.map((segment, index) => (
            <motion.span key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.1, duration: 0.3 }}>
                {segment.isBold ? (
                    <strong>{segment.text}</strong>
                ) : segment.isLink ? (
                    <a href={segment.isLink} target="_blank" rel="noopener noreferrer" className="text-blue-600">
                        {segment.text}
                    </a>
                ) : (
                    segment.text
                )}
            </motion.span>
        ));
    };

    return (
        <>
            <p className="m-2 py-1 px-2 bg-[#9AB70057] w-fit rounded-md text-xs font-medium">Fuse Agent Product in Beta*</p>
            <div className="w-full min-h-screen my-6 bg-[#F3F3F3] flex flex-col items-center">
                {/* Outer container with max width of 920px */}
                <div className="w-full min-h-screen bg-white flex flex-col">
                    {/* Main Content */}
                    <div className="flex-1 flex items-center justify-center">
                        <AnimatePresence mode="wait" className="max-w-[920px]">
                            {view === 1 ? (
                                // Landing Page
                                <motion.div
                                    key="landing"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.4 }}
                                    className="flex-1 flex flex-col max-w-[920px]"
                                >
                                    <div className="flex-1 flex flex-col items-center justify-center p-4">
                                        <div className="text-center flex flex-col gap-4 mb-10">
                                            <div className="text-center text-[#050505] text-[1.15rem] font-semibold font-['Inter']">
                                                <FuseAILogo className="w-[40px] h-[40px] mx-auto" />
                                                <h1 className="mt-4">Hey {userInfo.userName}!</h1>
                                            </div>
                                            <div className="text-center text-dgray-6 text-sm font-medium font-['Inter'] leading-[21px]">
                                                Ask me to either complete a task or provide insights on your customer data.
                                            </div>
                                        </div>

                                        {/* Input + Send Button */}
                                        <div className="w-full max-w-[920px] px-4">
                                            <div className="relative w-full">
                                                {/* Upload Icon wrapper */}
                                                {/* <div className="absolute left-4 top-1/2 -translate-y-1/2 flex items-center">
                                                <UploadIcon className="text-gray-400" />
                                            </div> */}

                                                <input
                                                    type="text"
                                                    placeholder="Ask anything"
                                                    className="w-full border h-[64px] border-[#E7E7E7] rounded-[8px] pl-12 pr-14 py-2 outline-none text-[1rem]"
                                                    value={userInput}
                                                    onChange={(e) => setUserInput(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") handleSendMessage();
                                                    }}
                                                    disabled={isLoading} // Disable input while loading
                                                />

                                                <IconButton
                                                    onClick={handleSendMessage}
                                                    sx={{
                                                        position: "absolute",
                                                        right: 8,
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                        width: 36,
                                                        height: 36,
                                                        backgroundColor: "#050505",
                                                        borderRadius: "9999px",
                                                        "&:hover": { backgroundColor: "#333" },
                                                        color: "white",
                                                    }}
                                                    disabled={isLoading} // Disable button while loading
                                                >
                                                    <SendIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </div>

                                        {/* Loading Indicator */}
                                        {isLoading && <div className="mt-4 text-gray-500">Processing your request...</div>}
                                    </div>
                                </motion.div>
                            ) : (
                                // Chat Page
                                <motion.div
                                    key="chat"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.4 }}
                                    className="flex-1 flex flex-col max-w-[920px]"
                                >
                                    {/* Messages */}
                                    <div className="flex-1 flex flex-col space-y-4 overflow-y-auto p-4">
                                        {messages.map((msg) => {
                                            const isBot = msg.sender === "bot";
                                            return (
                                                <div key={msg.id} className="flex flex-col">
                                                    {isBot ? (
                                                        /* Bot Bubble */
                                                        <div className="flex items-start space-x-2">
                                                            <ChatIcon />
                                                            <div className="max-w-[70%] p-3 pt-2 2xl:pt-1.5 rounded-[8px] text-[1rem] leading-[21px] bg-white">
                                                                <div className="text-[#050505] whitespace-pre-line">
                                                                    {msg.isLoading ? (
                                                                        // Display Loading Indicator if the bot is typing
                                                                        <LoadingIndicator />
                                                                    ) : (
                                                                        animateResponse(msg.text)
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        /* User Bubble */
                                                        <div className="max-w-[70%] p-3 rounded-[8px] text-[1rem] leading-[21px] bg-[#F6F6F6] self-end">
                                                            <div className="text-[#050505]">{msg.text}</div>
                                                        </div>
                                                    )}

                                                    {/* Action row for bot messages */}
                                                    {isBot && !msg.isLoading && (
                                                        <div className="flex items-center space-x-2 ml-10 mt-1">
                                                            {/* <IconButton
                                                            size="small"
                                                            onClick={() => handleSpeak(msg.text)}
                                                            sx={{
                                                                width: 28,
                                                                height: 28,
                                                                backgroundColor: "#FFFFFF",
                                                                "&:hover": { backgroundColor: "#EAEAEA" },
                                                                color: "#333",
                                                                fontSize: "0.9rem",
                                                            }}
                                                        >
                                                            <VolumeUpIcon />
                                                        </IconButton> */}
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleCopy(msg.text)}
                                                                sx={{
                                                                    width: 28,
                                                                    height: 28,
                                                                    backgroundColor: "#FFFFFF",
                                                                    "&:hover": { backgroundColor: "#EAEAEA" },
                                                                    color: "#333",
                                                                    fontSize: "0.9rem",
                                                                }}
                                                            >
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                            {/* <IconButton
                                                            size="small"
                                                            onClick={() => handleRefresh(msg.id)}
                                                            sx={{
                                                                width: 28,
                                                                height: 28,
                                                                backgroundColor: "#FFFFFF",
                                                                "&:hover": { backgroundColor: "#EAEAEA" },
                                                                color: "#333",
                                                                fontSize: "0.9rem",
                                                            }}
                                                        >
                                                            <RefreshIcon />
                                                        </IconButton> */}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                        <div ref={messageEndRef} />
                                    </div>

                                    {/* Input Row */}
                                    <div className="w-full flex-shrink-0 p-4">
                                        <div className="relative w-full">
                                            {/* Upload Icon on the left */}
                                            {/* <div className="absolute left-4 top-1/2 -translate-y-1/2 flex items-center">
                                            <UploadIcon className="text-gray-400" />
                                        </div> */}

                                            {/* Input Field */}
                                            <input
                                                type="text"
                                                placeholder="Ask anything"
                                                className="w-full border h-[64px] border-[#E7E7E7] rounded-[8px] pl-6 pr-14 py-2 outline-none text-[1rem]"
                                                value={userInput}
                                                onChange={(e) => setUserInput(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") handleSendMessage();
                                                }}
                                                disabled={isLoading} // Disable input while loading
                                            />

                                            {/* Send button */}
                                            <IconButton
                                                onClick={handleSendMessage}
                                                sx={{
                                                    position: "absolute",
                                                    right: 8,
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    width: 36,
                                                    height: 36,
                                                    backgroundColor: "#050505",
                                                    borderRadius: "9999px",
                                                    "&:hover": { backgroundColor: "#333" },
                                                    color: "white",
                                                }}
                                                disabled={isLoading} // Disable button while loading
                                            >
                                                <SendIcon fontSize="small" />
                                            </IconButton>
                                        </div>

                                        {/* Loading Indicator */}
                                        {isLoading && <div className="mt-2 text-gray-500">Processing your request...</div>}
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </>
    );
}
