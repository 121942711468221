import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { Button } from "@material-tailwind/react";
import { CircularProgress, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { FilterInputProps } from "@/theme/typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
    },
}));

const GrayTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#E8E7E7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#E8E7E7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
    "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        boxShadow: "inset 0 0 0px 1000px transparent",
    },
});

export const SaveSearchDialog = ({ open, close, isSaving, title, setTitle, onSave }) => {
    return (
        <BootstrapDialog open={open} onClose={close}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className="text-[#090c05] text-base font-semibold font-['Inter'] capitalize">Save search</div>
                <button onClick={close} className=" flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ minWidth: "500px", padding: 0 }}>
                <div className="flex flex-col justify-start items-start px-6 py-4 border-b border-[#e7e7e7]">
                    <div className="w-full">
                        <div className="text-sm font-medium mb-2 font-['Inter'] text-[#090c05]">Search Name</div>
                        <GrayTextField
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full text-sm font-normal"
                            placeholder="Name"
                            InputProps={FilterInputProps}
                        />
                    </div>
                </div>
                <div className="flex flex-row px-6 py-3 gap-4 justify-end">
                    <Button
                        className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Inter'] bg-white 2xl:px-2"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isSaving || !title.trim()}
                        className="w-[80px] flex flex-row justify-center items-center px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Inter'] bg-stone-950 2xl:px-2"
                        onClick={() => {
                            onSave();
                            setTitle("");
                        }}
                    >
                        {isSaving ? <CircularProgress size="16px" className="mr-2" style={{ color: "white" }} /> : null}
                        Save
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
};
