import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import { AccordianComponent } from "@/components/search/AccordianComponents";
import SingleListAutoComplete from "@/components/lists/singleListPage/SingleListAutoComplete";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

// MUI icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Example icons
import UserIcon from "@/assets/image/search/user.svg";
import CountryIcon from "@/assets/image/search/country.svg";
import StateIcon from "@/assets/image/search/state.svg";
import PLocationIcon from "@/assets/image/search/p-location.svg";
import JobTitleIcon from "@/assets/image/search/jobtitle.svg";
import JobLevelIcon from "@/assets/image/search/level-icon.svg";
import DepartmentIcon from "@/assets/image/search/department.svg";
import SubDepartmentIcon from "@/assets/image/search/sub-department.svg";
import LinkIcon from "@/assets/image/search/base-link.svg";
import WebsiteAdvancedIcon from "@/assets/image/search/website.svg";
import HomeIcon from "@/assets/image/search/home.svg";
import LocationIcon from "@/assets/image/search/location.svg";
import USDIcon from "@/assets/image/search/usd.svg";
import { BackIcon, ForwardIcon } from "./Icon";

// Example MUI-styled TextField
const GrayTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#E8E7E7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#E8E7E7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
    "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        boxShadow: "inset 0 0 0px 1000px transparent",
    },
});

// For optional consistent input styling
const FilterInputProps = {
    style: {
        fontSize: 14,
    },
};

function FilterModal({ anchorEl, onClose, filters, setFilters, onSearch, allOptions }) {
    // which step are we on? 0 -> choose; 1 -> contact details; 2 -> company details
    const [step, setStep] = useState(0);

    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const { styles, attributes } = usePopper(anchorEl, popperElement, {
        placement: "bottom-end",
        modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    });

    useEffect(() => {
        if (anchorEl) {
            setStep(0);
        }
    }, [anchorEl]);

    const handleBackClick = () => {
        setStep(0);
    };

    return createPortal(
        anchorEl && (
            <div
                className="fixed inset-0 z-50"
                onClick={() => {
                    // If user clicks anywhere in the backdrop, close the modal
                    onClose();
                }}
            >
                <div
                    ref={setPopperElement}
                    style={{ zIndex: 9999, ...styles.popper }}
                    {...attributes.popper}
                    onClick={(e) => e.stopPropagation()} // Prevent closing if clicked inside the modal
                    className="bg-white shadow-[0px_4px_34px_0px_rgba(0,0,0,0.16)] rounded-lg max-h-[80vh] overflow-auto relative"
                >
                    {/* Step 0: Choose Contact or Company */}
                    {step === 0 && (
                        <div className="w-[377px] flex flex-col border border-[#e7e7e7] rounded-lg">
                            <div className="flex-col w-full h-full">
                                <div className="px-4 py-3 border-b border-[#e7e7e7]">
                                    <div
                                        className="flex flex-row items-center justify-between bg-[#f6f6f6] p-2 rounded-lg cursor-pointer"
                                        onClick={() => setStep(1)}
                                    >
                                        <p className="text-sm font-medium text-[#050505]">Contact Details</p>
                                        <ForwardIcon />
                                    </div>
                                </div>
                                <div className="px-4 py-3">
                                    <div
                                        className="flex flex-row items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-100"
                                        onClick={() => setStep(2)}
                                    >
                                        <p className="text-sm font-medium text-[#050505]">Company Details</p>
                                        <ForwardIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Step 1: Contact Details */}
                    {step === 1 && (
                        <div className="w-[377px] flex flex-col border border-[#e7e7e7] rounded-lg">
                            <div className="flex flex-col w-full">
                                <div className="p-4 w-fit flex flex-row items-center gap-2" onClick={handleBackClick}>
                                    <BackIcon fontSize="small" className="cursor-pointer" />
                                    <div className="text-[#0655ff] text-sm font-medium capitalize cursor-pointer">Back</div>
                                </div>
                                <div className="px-4 text-start mb-2">
                                    <p className="text-[#6d6d6d] text-sm font-medium">Contact Details</p>
                                </div>

                                <div className="px-4 pb-4">
                                    {/* Full Name */}
                                    <AccordianComponent title="Full Name" icon={UserIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <GrayTextField
                                                value={filters.name || ""}
                                                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                                                className="w-full"
                                                placeholder="First and last name"
                                                InputProps={FilterInputProps}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Country */}
                                    <AccordianComponent title="Country" icon={CountryIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.country || []}
                                                options={allOptions.country || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, country: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* State */}
                                    <AccordianComponent title="State/Province" icon={StateIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            {/* If you have real states, populate them. If not, keep it as is or remove. */}
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.state || []}
                                                options={allOptions.state || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, state: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* City (Personal Location) */}
                                    <AccordianComponent
                                        title="City (Personal Location)"
                                        icon={PLocationIcon}
                                        isAddBorder={true}
                                        expandAll={true}
                                    >
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.personalLocation || []}
                                                options={allOptions.personalLocation || []}
                                                onFilterChange={(selected) => setFilters({ ...filters, personalLocation: selected })}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Job Title */}
                                    <AccordianComponent title="Job Title" icon={JobTitleIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.jobTitle || []}
                                                options={allOptions.title || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, jobTitle: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Job Level */}
                                    <AccordianComponent title="Job Level" icon={JobLevelIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.level || []}
                                                options={allOptions.level || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, level: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Department */}
                                    <AccordianComponent title="Department" icon={DepartmentIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.dept || []}
                                                options={allOptions.dept || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, dept: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Sub Department */}
                                    <AccordianComponent title="Sub-Department" icon={SubDepartmentIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.subDept || []}
                                                options={allOptions.subDepartment || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, subDept: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* LinkedIn URL */}
                                    <AccordianComponent title="Linkedin URL" icon={LinkIcon} isAddBorder={false} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <GrayTextField
                                                value={filters.linkedinUrl || ""}
                                                onChange={(e) => setFilters({ ...filters, linkedinUrl: e.target.value })}
                                                className="w-full"
                                                placeholder="linkedin.com/in/username"
                                                InputProps={FilterInputProps}
                                            />
                                        </div>
                                    </AccordianComponent>
                                </div>

                                {/* Footer with Search Button */}
                                <div className="px-4 pb-4">
                                    <button
                                        className="w-full h-12 bg-[#050505] rounded-lg flex items-center justify-center"
                                        onClick={onSearch}
                                    >
                                        <span className="text-white text-sm font-medium capitalize">Search</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Step 2: Company Details */}
                    {step === 2 && (
                        <div className="w-[377px] flex flex-col border border-[#e7e7e7] rounded-lg">
                            <div className="flex flex-col w-full">
                                <div className="p-4 w-fit flex flex-row items-center gap-2" onClick={handleBackClick}>
                                    <BackIcon fontSize="small" className="cursor-pointer" />
                                    <div className="text-[#0655ff] text-sm font-medium capitalize cursor-pointer">Back</div>
                                </div>
                                <div className="px-4 text-start mb-2">
                                    <p className="text-[#6d6d6d] text-sm font-medium">Company</p>
                                </div>

                                <div className="px-4 pb-4">
                                    {/* Company Name */}
                                    <AccordianComponent title="Company Name" icon={HomeIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.companyName || []}
                                                options={allOptions.companyName || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, companyName: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Website */}
                                    <AccordianComponent title="Website" icon={WebsiteAdvancedIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.website || []}
                                                options={allOptions.website || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, website: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Industry */}
                                    <AccordianComponent title="Industry" icon={LocationIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.industry || []}
                                                options={allOptions.industryName || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, industry: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Company Revenue */}
                                    <AccordianComponent title="Company Revenue" icon={USDIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.revenue || []}
                                                options={allOptions.revenue || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, revenue: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Company Location */}
                                    <AccordianComponent title="Company Location" icon={PLocationIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.companyLocation || []}
                                                options={allOptions.companyLocation || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, companyLocation: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>

                                    {/* Headcount */}
                                    <AccordianComponent title="Headcount" icon={DepartmentIcon} isAddBorder={true} expandAll={true}>
                                        <div className="mt-1 w-full">
                                            <SingleListAutoComplete
                                                multiple
                                                value={filters.numberOfEmployees || []}
                                                options={allOptions.numberOfEmployees || []}
                                                onFilterChange={(selected) => {
                                                    setFilters({ ...filters, numberOfEmployees: selected });
                                                }}
                                            />
                                        </div>
                                    </AccordianComponent>
                                </div>

                                <div className="px-4 pb-4">
                                    <button
                                        className="w-full h-12 bg-[#050505] rounded-lg flex items-center justify-center"
                                        onClick={onSearch}
                                    >
                                        <span className="text-white text-sm font-medium capitalize">Search</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div ref={setArrowElement} style={styles.arrow} />
                </div>
            </div>
        ),
        document.body
    );
}

export default FilterModal;
