import React from "react";
import { ReactComponent as CopyIcon } from "@/assets/image/ai_engagement/copyIcon.svg";
import { ReactComponent as MailBlackIcon } from "@/assets/image/ai_engagement/mailBlackIcon.svg";
import { ReactComponent as LinkedinIcon } from "@/assets/image/ai_engagement/linkedinIcon.svg";
import { toast } from "react-toastify";

export default function ContactDetails({ targetContact }) {
    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success("Copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                toast.error("Failed to copy to clipboard!");
            });
    };

    const getInitials = (fullName) => {
        if (!fullName) return "";
        const nameParts = fullName.trim().split(" ");
        const firstNameInitial = nameParts[0]?.[0] || "";
        const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : "";
        return (firstNameInitial + lastNameInitial).toUpperCase();
    };

    const openMailClient = (email) => {
        // Open the email client
        window.location.href = `mailto:${email?.trim()}`;
    };

    const openLinkedInProfile = (linkedinUrl) => {
        // Ensure the URL starts with "https://"
        const validUrl = linkedinUrl.startsWith("http://") || linkedinUrl.startsWith("https://") ? linkedinUrl : `https://${linkedinUrl}`;

        window.open(validUrl, "_blank");
    };

    return (
        <div className="flex h-full">
            <div className="w-full">
                <div className="bg-dgray-100 py-2 px-4 flex items-center rounded-lg">
                    <h2 className="main-text-black-600">Contact</h2>
                </div>

                <div className="campaign-card mt-2 !p-4">
                    <div className="flex gap-2 flex-shrink-0">
                        <p className="bg-[#2D7A89] h-12 w-12 rounded-full text-white text-xl flex items-center justify-center flex-shrink-0 capitalize">
                            {getInitials(targetContact?.fullName)}
                        </p>
                        <div className="flex flex-col justify-between">
                            <div className="flex flex-col gap-1">
                                <p className="capitalize main-text-black-600 !text-[16px]">{targetContact?.fullName}</p>
                                <p className="text-dgray-500 text-sm capitalize">{targetContact?.jobTitle}</p>
                            </div>
                            <div
                                className="flex items-center gap-2 text-[#2D7A89] hover:opacity-90 cursor-pointer"
                                onClick={() => copyToClipboard(targetContact?.primaryEmail)}
                            >
                                <p className="text-sm">{targetContact?.primaryEmail}</p>
                                <CopyIcon className="w-3 h-3" />
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-stone-400 my-4"></div>
                    <div className="flex gap-7">
                        <MailBlackIcon
                            className="w-5 h-5 cursor-pointer hover:opacity-90"
                            onClick={() => openMailClient(targetContact?.primaryEmail)}
                        />
                        <LinkedinIcon
                            className="w-5 h-5 cursor-pointer hover:opacity-90"
                            onClick={() => openLinkedInProfile(targetContact?.linkedinUrl)}
                        />
                    </div>
                </div>

                <div className="bg-dgray-100 py-2 px-4 flex items-center rounded-lg mt-8">
                    <h2 className="main-text-black-600">Company</h2>
                </div>

                <div className="campaign-card mt-2 !p-4">
                    <span className="bg-[#e8e7e7] px-3 py-1.5 rounded font-Inter text-[14px]">Primary</span>
                    <div className="flex gap-5 mt-4 items-center">
                        <img
                            src={`https://logo.clearbit.com/${targetContact?.companyName}`}
                            className="w-[70px] h-[70px]"
                            alt={`${targetContact?.companyName} logo`}
                            onError={(e) => (e.target.style.display = "none")}
                        />
                        <div>
                            <p className="main-text-black-600 capitalize !text-[16px]">{targetContact?.companyName}</p>
                            <div
                                className="flex items-center flex-wrap gap-2 cursor-pointer hover:opacity-90"
                                onClick={() => copyToClipboard(targetContact?.companyUrl)}
                            >
                                <p className="main-text-black-500 break-all break-after-all !text-[#2D7A89]">{targetContact?.companyUrl}</p>
                                <CopyIcon className="w-4 h-4" />
                            </div>
                        </div>
                    </div>
                    {targetContact?.companyDescription && <p className="mt-2 text-sm">{targetContact?.companyDescription}</p>}
                </div>
            </div>
        </div>
    );
}
