// LineChart.jsx
import Highcharts from "highcharts";
import React, { useEffect, useRef, useState } from "react";
import ChartFilterPopup from "./ChartFilterPopup";

export default React.memo(function LineChart({
    chartName,
    heading,
    chartData = [],
    periodFilter,
    setPeriodFilter,
    optionsArray,
    lineColor = "#2D7A89", // Default line color
    optionsLabel,
    linearGradient,
    multipleChartsData = {},
    titleSize = "base",
    onFilterChange,
    hideLineLegend = false,
}) {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const lineColorsCodes = ["#FFC8C8", "#2D7A89", "#FFCE6B"];

    // Utility function to ensure label is a string
    // const ensureString = (value) => (typeof value === "string" ? value : value !== undefined && value !== null ? String(value) : "");

    /**
     * Converts date string to timestamp.
     *
     * @param {string} dateStr - Date string in "YYYY-MM-DD" format.
     * @returns {number} - Timestamp in milliseconds.
     */
    const convertToTimestamp = (dateStr) => {
        const [year, month, day] = dateStr.split("-").map(Number);
        return new Date(year, month - 1, day).getTime();
    };

    // Function to generate a single series with datetime x-values
    const fetchSingleSeries = (seriesName, seriesData, seriesColor) => [
        {
            name: seriesName || heading,
            data: seriesData.map((point) => ({
                x: convertToTimestamp(point.label),
                y: point.value,
            })),
            color: seriesColor,
            // Create a gradient fill under the curve
            fillColor: {
                linearGradient: linearGradient || [0, 0, 0, 250],
                stops: [
                    [0, "rgba(45, 122, 137, 0.4)"], // Start color (near the line)
                    [1, "rgba(45, 122, 137, 0)"], // Fade out to transparent
                ],
            },
            marker: {
                enabled: false, // Disable point markers for cleaner look
            },
        },
    ];

    /**
     * Processes single data set to extract data points with timestamps.
     *
     * @param {Array} payloadData - Array of data objects with 'value' and 'label'.
     * @returns {Array} - Array of data points with x (timestamp) and y (value).
     */
    const fetchForSingleData = (payloadData) => {
        return payloadData?.map((item) => ({
            x: convertToTimestamp(item.label),
            y: item.value,
        }));
    };

    /**
     * Processes multiple data sets to extract unique timestamps and series.
     *
     * @returns {Object} - Contains 'series' array.
     */
    const fetchForMultipleData = () => {
        let seriesArray = [];

        Object.keys(multipleChartsData).forEach((key, index) => {
            const payload = multipleChartsData[key];
            const seriesData = fetchForSingleData(payload);
            const lineCol = lineColorsCodes[index % lineColorsCodes.length];
            const seriesPayload = fetchSingleSeries(key, payload, lineCol);
            seriesArray.push(seriesPayload[0] || {});
        });

        return { series: seriesArray };
    };

    let seriesArray = [];
    let processedData = [];

    if (chartData.length) {
        const singleData = fetchForSingleData(chartData);
        seriesArray = fetchSingleSeries(heading, chartData, lineColor);
        processedData = singleData;
    } else if (Object.keys(multipleChartsData).length) {
        const multipleData = fetchForMultipleData();
        seriesArray = multipleData.series;
    }

    const series = seriesArray.length ? seriesArray : [];

    // Memoize the chart options to prevent unnecessary recalculations
    const chartOptions = React.useMemo(
        () => ({
            chart: {
                type: "areaspline",
                spacingBottom: 10,
                height: 300,
                animation: false,
                zoomType: "x",
            },
            title: {
                text: "",
            },
            tooltip: {
                shared: true,
                xDateFormat: "%b %d, %Y", // Format for the x-axis in tooltip
                pointFormat: "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.3, // Adjust opacity to taste
                    marker: {
                        enabled: false, // Disable point markers
                    },
                },
            },
            xAxis: {
                type: "datetime",
                dateTimeLabelFormats: {
                    day: "%b %d",
                    week: "%b %d",
                    month: "%b %Y",
                    year: "%Y",
                },
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Inter",
                    },
                },
                lineColor: "#E8E7E7",
                tickPixelInterval: 100, // Adjust to control label density
            },
            yAxis: {
                title: {
                    text: "",
                },
                gridLineColor: "#E7E7E7",
                gridLineDashStyle: "Dash",
                tickColor: "#E8E7E7",
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Inter",
                    },
                },
                allowDecimals: true,
            },
            legend: {
                ...(linearGradient && {
                    enabled: !hideLineLegend,
                    align: "left",
                    verticalAlign: "top",
                    itemMarginLeft: 0,
                    borderRadius: 7,
                    itemStyle: {
                        fontSize: "0.875rem",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        color: "#3d3d3d",
                    },
                }),
            },
            credits: {
                enabled: false,
            },
            series,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 600,
                        },
                        chartOptions: {
                            chart: {
                                height: 200,
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: "0.65rem",
                                    },
                                },
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: "0.65rem",
                                    },
                                },
                            },
                            legend: {
                                itemStyle: {
                                    fontSize: "0.75rem",
                                },
                            },
                        },
                    },
                ],
            },
        }),
        [chartData, multipleChartsData, linearGradient, heading, chartName, periodFilter]
    );

    // Memoize the chart rendering function
    const renderChart = React.useCallback(() => {
        // Destroy previous chart if it exists
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        // Render the chart
        chartRef.current = Highcharts.chart(chartContainerRef.current, chartOptions);

        // Handle window resize to reflow the chart
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.reflow();
            }
        };

        window.addEventListener("resize", handleResize);

        // Cleanup function to destroy chart and remove event listener
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
            window.removeEventListener("resize", handleResize);
        };
    }, [chartOptions]);

    const handleFilterChange = async (value) => {
        setIsLoading(true);
        if (onFilterChange) {
            await onFilterChange(value);
        } else {
            setPeriodFilter(value);
        }
        setIsLoading(false);
    };

    // Don't render chart while loading
    useEffect(() => {
        if (!isLoading) {
            renderChart();
        }
    }, [renderChart, isLoading]);

    return (
        <div className="w-full flex flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="inline-flex w-full shrink basis-0 flex-col items-start justify-start gap-4">
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className={`text-[#050505] text-${titleSize} font-semibold font-['Inter'] shrink grow basis-0`}>{heading}</div>
                    <div className="flex items-center justify-start gap-4">
                        <ChartFilterPopup
                            periodFilter={periodFilter}
                            setPeriodFilter={handleFilterChange}
                            optionsLabel={optionsLabel}
                            optionsArray={optionsArray}
                            titleSize={titleSize === "base" ? "sm" : titleSize}
                        />
                    </div>
                </div>
                <div id={chartName || heading} className="w-full" ref={chartContainerRef}></div>
            </div>
        </div>
    );
});
