import { useState } from "react";
import DownBtnIcon from "@/assets/image/icons/downBtn.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function QuestionTag(props) {
    const [showContent, setShowContent] = useState(false);
    const handleShowContent = () => {
        setShowContent(!showContent);
    };

    return (
        <div className="w-full px-5 cursor-pointer" onClick={handleShowContent}>
            <div
                className={`w-full py-6 border-b border-[#e7e7e7]  transition-transform duration-1000 ${props.isLast ? "border-b-0" : ""}`}
            >
                <div className="flex flex-row">
                    <p className="w-full text-start !font-semibold">{props.question}</p>
                    <button>
                        <ExpandMoreIcon
                            className={`h-4 w-4 transition-transform duration-200 ${showContent ? "rotate-180" : ""}`}
                            color="inherit"
                        />
                    </button>
                </div>
                {showContent && props.answer.map((line) => <p className="text-dgray-6 text-sm mt-4 font-medium ">{line}</p>)}
            </div>
        </div>
    );
}
