import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as CloseIcon } from "@/assets/image/team/close.svg";
import { CustomButton } from "../crm/contacts/Button";

export default function ConfirmDelete({ open, handleClose, handleConfirm, isLoading }) {
    return (
        <>
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "16px",
                        width: "498px",
                    },
                    "& .MuiDialogTitle-root": {
                        padding: 0,
                    },
                    "& .MuiDialogContent-root": {
                        padding: 0,
                    },
                    "& .MuiDialogActions-root": {
                        padding: 0,
                    },
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="px-6 w-full py-4 border-b border-[#e7e7e7] justify-between items-center inline-flex">
                        <div className="w-80 text-[#090c05] text-sm font-semibold font-['Inter']">Remove Member</div>
                        <div className="w-4 h-4 ">
                            <CloseIcon onClick={handleClose} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            gap: "16px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            padding: "24px",
                        }}
                        id="alert-dialog-description"
                    >
                        <div className="text-[#050505] text-sm font-medium font-['Inter']">
                            Are you sure you want to remove this member?
                        </div>
                        <div className="text-[#6d6d6d] text-sm font-normal font-['Inter']">This action cannot be undone</div>
                    </DialogContentText>
                </DialogContent>

                <div className="px-6 border-t border-[#e7e7e7] py-4 w-full flex flex-row justify-end items-center gap-[10px]">
                    <CustomButton
                        onClick={handleClose}
                        disabled={isLoading}
                        title="Cancel"
                        height={29}
                        width={79}
                        px={16}
                        py={6}
                        titleTextSize={14}
                        titleFontWeight={500}
                        border="1px solid #e7e7e7"
                        titleColor="#050505"
                        bg="white"
                    />
                    <CustomButton
                        onClick={handleConfirm}
                        autoFocus
                        disabled={isLoading}
                        title="Confirm"
                        height={29}
                        width={86}
                        px={16}
                        py={6}
                        titleTextSize={14}
                        titleFontWeight={500}
                    />
                </div>
            </Dialog>
        </>
    );
}
