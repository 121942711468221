import React from "react";
import LetterBox from "./LetterBox";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

function AlphabetGrid({ selectedLetter, setSelectedLetter }) {
    const handleLetterClick = (letter) => {
        setSelectedLetter(letter);
    };

    return (
        <main className="mt-20 mb-28 flex flex-col justify-center items-center text-center whitespace-nowrap">
            <section className="grid grid-cols-5 xs:grid-cols-7 md:grid-cols-[repeat(13,minmax(0,1fr))] gap-y-10 gap-x-7 justify-center items-center mx-6">
                {alphabet.map((letter) => (
                    <LetterBox
                        key={letter}
                        letter={letter}
                        isHighlighted={letter === selectedLetter}
                        onClick={() => handleLetterClick(letter)}
                    />
                ))}
            </section>
        </main>
    );
}

export default AlphabetGrid;
