import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { ReactComponent as WarningIcon } from "@/assets/image/email-validation/warning.svg";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import BackdropLoader from "../common/BackdropLoader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        width: "600px",
    },
}));

//TODO: Create a base modal for both this and PaymentResultModal
export default function ConfirmationModal(props) {
    const { showState, modal, loading } = props;
    const { icon, title, message, footnotes, handleConfirm } = modal;
    const [show, setShow] = showState;
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={show} className="relative">
            <BackdropLoader active={loading}></BackdropLoader>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className=" text-[#050505] text-[18px] font-semibold font-['Inter']">{title}</div>
                <button
                    onClick={() => {
                        setShow(false);
                    }}
                    className=" flex flex-row justify-end"
                >
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            {/* <button
                onClick={() => {
                    setShow(false);
                }}
                className="absolute right-4 top-4 flex flex-row justify-end"
            >
                <CloseSVGIcon className="relative h-4 w-4" />
            </button> */}
            <DialogContent sx={{ padding: 0 }}>
                {/* <div className="flex flex-row justify-center">
                    <div className={"flex h-16 w-16 items-center justify-center rounded-[50%] bg-black p-3"}>{icon}</div>
                </div> */}

                {/* <div className="font-InterBold leading-loose self-stretch text-center text-3xl  text-stone-950">{title}</div> */}
                <div className="flex flex-col gap-4 px-6 py-10 justify-start items-start border-b border-[#e7e7e7]">
                    <div className=" text-[#050505] text-[14px] font-normal font-['Inter'] leading-snug self-stretch">{message}</div>

                    {footnotes?.length && (
                        <div className=" flex flex-col items-start gap-1 self-start">
                            {footnotes.map((note) => {
                                return <span className="font-Inter flex items-center justify-center text-[12px] opacity-50">{note}</span>;
                            })}
                        </div>
                    )}
                </div>
                <div className="flex flex-row px-6 py-4 gap-4 justify-end">
                    {/*TODO: Add states */}
                    <Button
                        className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Inter'] bg-white 2xl:px-2"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="w-[80px] px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Inter'] bg-stone-950 2xl:px-2"
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
