import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import SortIcon from "@/components/icons/SortIcon";

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const setThWidth = (index) => {
        if (index === 0) {
            return 300;
        } else if (index === 1) {
            return 500;
        } else {
            return "200px";
        }
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    fontFamily: "Inter",
                    fontSize: 14,
                    color: "#6D6D6D",
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F4F3F0",
                },
                position: "sticky",
                top: 0,
                zIndex: 4,
                backgroundColor: "#F4F3F0",
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                        position: "sticky",
                        left: 0,
                        zIndex: 4,
                        bgcolor: "#F4F3F0",
                    },
                    "& .MuiTableCell-root:nth-of-type(2)": {
                        position: "sticky",
                        left: "59px",
                        zIndex: 4,
                        bgcolor: "#F4F3F0",
                        "&::after": {
                            content: '""',
                            position: "absolute",
                            right: -8,
                            top: 0,
                            bottom: 0,
                            width: 8,
                            background: "linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)",
                            opacity: 0,
                            transition: "opacity 0.3s",
                            pointerEvents: "none",
                        },
                        ".scrolled &::after": {
                            opacity: 1,
                        },
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        padding: "8px 12px",
                        borderBottom: `1px solid ${kompassColors.light}`,
                    },
                    "& .MuiTableCell-root:not(:last-child)": {
                        borderRight: `1px solid ${kompassColors.light}`,
                    },
                }}
            >
                <TableCell padding="checkbox" className="rounded-tl-[6px] border-r border-[#e7e7e7]">
                    <Checkbox
                        checked={props.totalRowsOnPage > 0 && props.numSelectedOnPage === props.totalRowsOnPage}
                        onChange={props.onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all contacts",
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: "#2D7A89",
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: "#2D7A89",
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                    />
                </TableCell>
                {props &&
                    props.headCells?.length > 0 &&
                    props.headCells.map((headCell, index) => (
                        <TableCell
                            width={setThWidth(index)}
                            key={headCell.id}
                            align={headCell.id === "action" ? "center" : "left"}
                            padding={"none"}
                            sx={{ borderTop: "none" }}
                            className={`!font-Inter !text-sm border-r border-[#e7e7e7] last:border-r-0 ${index === props.headCells?.length - 1 ? "rounded-tr-[6px]" : ""}`}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.id === "action" ? (
                                <div className="flex items-center gap-2">
                                    {headCell.icon}
                                    <span className="font-medium text-sm">{headCell.label}</span>
                                </div>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    IconComponent={SortIcon}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        justifyContent: "space-between",
                                        color: "#6D6D6D",
                                    }}
                                >
                                    <div className="flex items-center gap-2">
                                        {headCell.icon}
                                        {headCell.label}
                                    </div>
                                    {orderBy === headCell.id ?? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    )}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
