import React, { useState } from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import AccountOverview from "@/components/crm/accounts/AccountOverview";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import Jobs from "@/components/crm/accounts/Jobs";
import Crunchbase from "@/components/crm/accounts/Crunchbase";
import LinkedIn from "@/components/crm/accounts/LinkedIn";
import MainLayout from "@/components/MainLayout";
import RecordsTabs from "@/components/crm/RecordsTabs";
import MainTitle from "@/components/MainTitle";
import { useNavigate, useParams } from "react-router-dom";
import useOpportunities from "@/hooks/crm/useOpportunities";
import dayjs from "dayjs";
import MainTitleBar from "@/components/MainTitleBar";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function SingleAccountScene() {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [contactsData, setContactsData] = useState([]);
    const [errorFetchingData, setErrorFetchingData] = useState(false);

    const { accountId } = useParams();
    const { getCompany, getPeople } = useOpportunities();

    const navigate = useNavigate();

    const handleChange = (_event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [companyResponse, contactsResponse] = await Promise.all([getCompany(accountId), getPeople(accountId)]);

            setCompanyData(companyResponse.data);
            setContactsData(contactsResponse.data.slice(0, 6));
        } catch (error) {
            setErrorFetchingData(true);
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                <p>Records</p>
            </MainTitleBar>
            <RecordsTabs />
            {loading ? (
                <div className="flex justify-center items-center h-[calc(100vh-120px)] text-black">
                    <CircularProgress color="inherit" />
                </div>
            ) : errorFetchingData ? (
                <div className="flex justify-center items-center h-[calc(100vh-120px)]">
                    <div className="flex flex-col items-center space-y-4">
                        <p className="text-lg font-semibold">No Data Found</p>
                        <button
                            onClick={() => navigate("/records/accounts")}
                            className="flex items-center text-primary-teal py-1 px-2 rounded-lg hover:bg-primary-header"
                        >
                            <ChevronLeftOutlinedIcon color="inherit" />
                            Back
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="mx-6 my-3">
                        <button
                            onClick={() => navigate("/records/accounts")}
                            className="flex items-center text-primary-teal py-1 px-2 rounded-lg hover:bg-primary-header"
                        >
                            <ChevronLeftOutlinedIcon color="inherit" />
                            Back
                        </button>
                        <div className="flex items-center space-x-2 mt-3 font-semibold">
                            <img
                                className="rounded-md"
                                src={`https://logo.clearbit.com/${companyData.website}`}
                                alt="company"
                                width={25}
                                height={25}
                                onError={(e) => (e.target.style.display = "none")}
                            />
                            <p className="">{companyData.name}</p>
                        </div>
                        <p className="text-sm text-dgray-500 mt-3">Last Updated on {dayjs(companyData.updatedAt).format("D MMMM, YYYY")}</p>
                    </div>

                    {/* Tabs */}
                    <Box sx={{ borderBottom: "1px solid #E7E7E7", width: "100%", fontFamily: "Inter", pl: "24px" }}>
                        <Tabs
                            value={activeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={handleChange}
                            sx={{
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#050505",
                                    height: "3px",
                                },
                                "& .MuiTab-root": {
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "Inter",
                                    margin: "0 8px 0 0",
                                    padding: "0",
                                    minWidth: "auto",
                                    color: "#6D6D6D",
                                    "&.Mui-selected": {
                                        color: "#050505",
                                    },
                                },
                            }}
                        >
                            <Tab label="Account Overview" sx={{ marginRight: "40px" }} />
                            <Tab label="Jobs" sx={{ marginRight: "40px" }} />
                            <Tab label="Crunchbase" sx={{ marginRight: "40px" }} />
                            <Tab label="LinkedIn" />
                        </Tabs>
                    </Box>

                    {/* Here: make a container that can scroll if needed */}
                    <Box>
                        <Box
                            sx={{
                                margin: 3,
                                marginBottom: 7,
                                maxWidth: "100%", // do not exceed parent width
                                overflowX: "auto", // horizontal scroll
                                overflowY: "auto", // vertical scroll if you also want vertical
                            }}
                        >
                            {activeTab === 0 && <AccountOverview companyData={companyData} contactsData={contactsData} />}
                            {activeTab === 1 && <Jobs companyData={companyData} />}
                            {activeTab === 2 && <Crunchbase companyData={companyData} />}
                            {activeTab === 3 && <LinkedIn companyData={companyData} />}
                        </Box>
                    </Box>
                </div>
            )}
            <FuseAgentModal />
        </MainLayout>
    );
}
