import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { BASE_BUTTON_STYLES } from "@/utils/common";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

const DialogWindow = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
        minWidth: "250px",
    },
}));

export const DeleteConfirmationWindow = ({ open, onClose, onSubmit, isLoading }) => {
    return (
        <DialogWindow open={open} onClose={onClose}>
            <button onClick={onClose} className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4">
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                <div className="font-Inter p-3 text-center text-2xl font-bold text-black">Are you sure you want to delete it?</div>
                <div className="flex justify-center gap-4 items-center w-full">
                    <Button
                        disabled={isLoading}
                        sx={{
                            ...BASE_BUTTON_STYLES,
                            backgroundColor: "#090C05",
                            color: "#fff",
                            ":hover": {
                                backgroundColor: "transparent",
                                color: "#090C05",
                            },
                        }}
                        onClick={onClose}
                    >
                        No
                    </Button>
                    <Button
                        disabled={isLoading}
                        sx={{
                            ...BASE_BUTTON_STYLES,
                            backgroundColor: "transparent",
                            color: "#090C05",
                            ":hover": {
                                backgroundColor: "#090C05",
                                color: "#fff",
                            },
                        }}
                        onClick={onSubmit}
                    >
                        {isLoading ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "#090C05" }} /> : null}
                        Yes
                    </Button>
                </div>
            </DialogContent>
        </DialogWindow>
    );
};
