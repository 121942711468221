import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MainLayout from "@/components/MainLayout";
import { Backdrop } from "@mui/material";
import { ReactComponent as GoogleIcon } from "@/assets/image/search/chrome.svg";
import { ReactComponent as DoubleArrow } from "@/assets/image/double_arrow.svg";
import { ReactComponent as DoubleArrowRight } from "@/assets/image/double_arrow_2.svg";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import MainTitleBar from "@/components/MainTitleBar";
import { delay, extractLocationsFromStr, getAllQueryParams } from "@/utils/common";
import { KompassFiltersProvider } from "@/hooks/useKompassFilters";
import SingleListFilters from "@/components/lists/singleListPage/SingleListFilters";
import { useParams } from "react-router-dom";
import useList from "@/hooks/useList";
import SingleListContactsTable from "@/components/lists/singleListPage/SingleListContactsTable";
import { ReactComponent as BackArrow } from "@/assets/image/crm/transcript/back-arrow.svg";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";
import { toast } from "react-toastify";
import useKompassSearch from "@/hooks/useKompassSearch";

export default function SingleListScene() {
    const [currentTab, setCurrentTab] = useState(0);
    const { show, loading: crmLoading } = useCRMPushResultStore();
    const [loading, setLoading] = useState(false);

    // Local states for controlling data, filters, UI, etc.
    const [contactFilter, setContactFilter] = useState(null);
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [openRowIndex, setOpenRowIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Table data states
    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);

    // Title and filter options
    const [listTitle, setListTitle] = useState("");
    const [filters, setFilters] = useState({
        name: "",
        website: [],
        title: [],
        companyName: [],
        companyLocation: [],
        personalLocation: [],
        dept: [],
        subDepartment: [],
        level: [],
        numberOfEmployees: [],
        revenue: [],
        linkedinUrl: "",
        industryName: [],
        country: [],
        state: [],
    });
    const [allOptions, setAllOptions] = useState({
        website: [],
        title: [],
        revenue: [],
        companyName: [],
        companyLocation: [],
        personalLocation: [],
        dept: [],
        subDepartment: [],
        level: [],
        numberOfEmployees: [],
        industryName: [],
        country: [],
        state: [],
    });

    const { listId } = useParams();
    const { getProfilesByContactId } = useList();

    const lastApiCallRef = useRef(0);
    const extensionLogoUrl = "chrome-extension://imkmjfajnjfpfkdojdmabcphojonjjjf/logo.svg";
    const [crxStatus, setCrxStatus] = useState(null);

    const { postKompassSearchContactsByFilters } = useKompassSearch();

    // 1. Check extension installation on mount
    useEffect(() => {
        const checkIfExtensionInstalled = async () => {
            try {
                const res = await fetch(extensionLogoUrl, { method: "HEAD" });
                setIsExtensionInstalled(res.ok);
            } catch (e) {
                setIsExtensionInstalled(false);
            }
        };
        checkIfExtensionInstalled();
    }, []);

    // 2. Listen for extension pings
    useEffect(() => {
        let timeoutId;
        const handleMessage = (event) => {
            const { action, params } = event.data;
            if (action === "kompassai-extension-ping") {
                setCrxStatus({
                    isInstalled: true,
                    timestamp: params.timestamp,
                });
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setCrxStatus(null);
                }, 10000);
            }
        };
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
            clearTimeout(timeoutId);
        };
    }, []);

    // 3. Handle query params in URL (e.g., /single-list?name=John)
    useEffect(() => {
        const queryParams = getAllQueryParams(window.location.search);
        if (Object.keys(queryParams).length) {
            setContactFilter({
                name: [queryParams?.name],
                companyName: [queryParams?.companyName],
                nameExactMatch: true,
            });
            // remove these params from the URL
            const url = new URL(window.location.href);
            for (const key of Object.keys(queryParams)) {
                url.searchParams.delete(key);
            }
            window.history.replaceState(null, "", url.toString());
        }
    }, []);

    // 4. Fetch list contacts by listId
    const fetchContactsByListId = async () => {
        setIsLoading(true);
        const response = await getProfilesByContactId({ contactId: listId });
        if (response.status) {
            // Transform the data to unify the fields
            const newTableData = response.data.map((contact) => ({
                ...contact,
                // unify or transform as needed
                job_company_website: contact.job_company_website?.toLowerCase() || "",
                job_title: contact.job_title?.toLowerCase() || "",
                job_company_name: contact.job_company_name?.toLowerCase() || "",
                location_name: contact.location_name?.toLowerCase() || "",
                location: contact.location?.toLowerCase() || "",
                job_title_role: contact.job_title_role?.toLowerCase() || "",
                job_title_sub_role: contact.job_title_sub_role?.toLowerCase() || "",
                industry: contact.industry?.toLowerCase() || "",
                company: {
                    ...contact.company,
                    headCount: contact.company?.headCount?.toString().toLowerCase() || "",
                    revenue: contact.company?.revenue?.toString().toLowerCase() || "",
                },
            }));

            setTableData(newTableData);
            setFilteredData(newTableData);
            setTotalCount(newTableData.length);
            setListTitle(response.listTitle);

            // Prepare filter dropdown options
            const websiteSet = new Set();
            const titleSet = new Set();
            const companyNameSet = new Set();
            const companyLocationSet = new Set();
            const personalLocationSet = new Set();
            const deptSet = new Set();
            const subDepartmentSet = new Set();
            const levelSet = new Set();
            const numberOfEmployeesSet = new Set();
            const revenueSet = new Set();
            const industryNameSet = new Set();
            const countrySet = new Set();
            const stateSet = new Set();

            newTableData.forEach((contact) => {
                if (contact.job_company_website) websiteSet.add(contact.job_company_website.toLowerCase());
                if (contact.job_title) titleSet.add(contact.job_title.toLowerCase());
                if (contact.job_company_name) companyNameSet.add(contact.job_company_name.toLowerCase());
                if (contact.location_name) companyLocationSet.add(contact.location_name.toLowerCase());
                if (contact.location) personalLocationSet.add(contact.location.toLowerCase());
                if (contact.job_title_role) deptSet.add(contact.job_title_role.toLowerCase());
                if (contact.job_title_sub_role) subDepartmentSet.add(contact.job_title_sub_role.toLowerCase());
                if (contact.level) levelSet.add(contact.level);
                if (contact.company?.headCount) numberOfEmployeesSet.add(contact.company.headCount.toString().toLowerCase());
                if (contact.company?.revenue) revenueSet.add(contact.company.revenue.toString().toLowerCase());
                if (contact.industry) industryNameSet.add(contact.industry.toLowerCase());
                if (contact.location) {
                    const parts = contact.location.split(",").map((part) => part.trim());
                    const country = parts[parts.length - 1];
                    const state = parts[parts.length - 2];
                    if (country) countrySet.add(country.toLowerCase());
                    if (state) stateSet.add(state?.toLowerCase());
                }
            });

            const capitalizeLabel = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());

            const toLabelValueArray = (set) =>
                Array.from(set).map((item) => ({
                    label: capitalizeLabel(item),
                    value: item,
                }));

            setAllOptions({
                website: toLabelValueArray(websiteSet),
                title: toLabelValueArray(titleSet),
                companyName: toLabelValueArray(companyNameSet),
                companyLocation: toLabelValueArray(companyLocationSet),
                personalLocation: toLabelValueArray(personalLocationSet),
                dept: toLabelValueArray(deptSet),
                subDepartment: toLabelValueArray(subDepartmentSet),
                level: toLabelValueArray(levelSet),
                numberOfEmployees: toLabelValueArray(numberOfEmployeesSet),
                revenue: toLabelValueArray(revenueSet),
                industryName: toLabelValueArray(industryNameSet),
                country: toLabelValueArray(countrySet),
                state: toLabelValueArray(stateSet),
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        // Fetch the list data right away on mount
        fetchContactsByListId();
    }, [listId]);

    // 5. Filter table data whenever filters or tableData changes
    useEffect(() => {
        filterTableData();
    }, [filters, tableData]); // <-- Also watch tableData

    const filterTableData = () => {
        const data = tableData.filter((row) => {
            if (filters.name && !row.full_name?.toLowerCase().includes(filters.name.toLowerCase())) return false;
            if (filters.website?.length > 0 && !filters.website.includes(row.job_company_website)) return false;
            if (filters.title?.length > 0 && !filters.title.includes(row.job_title)) return false;
            if (filters.linkedinUrl && !row.linkedin_url?.includes(filters.linkedinUrl)) return false;

            if (filters.companyName?.length > 0 && !filters.companyName.includes(row.job_company_name)) return false;
            if (filters.companyLocation?.length > 0 && !filters.companyLocation.includes(row.location_name)) return false;
            if (filters.personalLocation?.length > 0 && !filters.personalLocation.includes(row.location)) return false;

            if (filters.dept?.length > 0 && !filters.dept.includes(row.job_title_role)) return false;
            if (filters.subDepartment?.length > 0 && !filters.subDepartment.includes(row.job_title_sub_role)) return false;

            if (filters.level?.length > 0 && !filters.level.includes(row.level)) return false;

            if (filters.numberOfEmployees?.length > 0 && !filters.numberOfEmployees.includes(row.company?.headCount)) return false;
            if (filters.revenue?.length > 0 && !filters.revenue.includes(row.company?.revenue)) return false;

            if (filters.country?.length > 0) {
                const countryFromLocation = row.location?.split(",").at(-1)?.trim().toLowerCase();
                const matchCountry = filters.country.some((c) => c.toLowerCase() === countryFromLocation);
                if (!matchCountry) return false;
            }
            if (filters.state?.length > 0) {
                const stateFromLocation = row.location?.split(",").at(-2)?.trim().toLowerCase();
                const matchState = filters.state.some((s) => s.toLowerCase() === stateFromLocation);
                if (!matchState) return false;
            }

            if (filters.industryName?.length > 0 && !filters.industryName.includes(row.industry)) return false;
            return true;
        });

        setFilteredData(data);
        setTotalCount(data.length);
        setPage(0);
    };

    // =====================
    // NEW METHOD (unchanged existing code, just appended):
    // =====================
    function applyExtractFiltersPDL(filterResponse) {
        // Example: your API might return { industry: ["automotive"], ... }
        // Merge into existing filters.  If you have more mappings, add them here:
        setFilters((prev) => ({
            ...prev,
            industryName: filterResponse.industry || prev.industryName,
            job_title: filterResponse.job_title || prev.title, // if needed
            // map job_title_levels -> level, etc. if relevant
            // job_company_inferred_revenue -> revenue
            // job_company_size -> numberOfEmployees
            // location_country -> country
            // location_region -> state (if you want)
            // etc.
        }));

        // Because we have:
        //    useEffect(() => { filterTableData(); }, [filters, tableData]);
        // it will auto-run the local in-list search upon filter update.
    }

    return (
        <KompassFiltersProvider
            onSearchByFilter={(filter) => setContactFilter(filter)}
            isFiltersSubpage={currentTab > 0}
            setCurrentTab={setCurrentTab}
        >
            <MainLayout loading={loading || crmLoading}>
                <>
                    <AnimatePresence>
                        {show && (
                            <motion.div
                                className="w-full"
                                initial={{ opacity: 0, zIndex: 11 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <Backdrop
                                    open
                                    className="!absolute"
                                    sx={{
                                        backgroundColor: "rgba(0,0,0,0.18)",
                                        backdropFilter: "blur(2px)",
                                        zIndex: 99999,
                                    }}
                                >
                                    <CRMUploadResultsTable />
                                </Backdrop>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
                <MainTitleBar>
                    <div className="flex flex-col justify-between sm:flex-row sm:items-end gap-[4px]">
                        <p>Prospect Lists</p>
                        {/* <div className="flex flex-row gap-2">
                            {isExtensionInstalled ? (
                                <a
                                    href="https://www.linkedin.com/profile/preview?locale=en_US"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Inter leading-[1.4] font-normal">Launch Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            ) : (
                                <a
                                    href="https://chromewebstore.google.com/detail/kompassai/imkmjfajnjfpfkdojdmabcphojonjjjf?hl=en&authuser=2"
                                    className="main-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className="font-Inter leading-[1.4] font-normal">Install Chrome Extension</p>
                                    <GoogleIcon className="ml-1 h-5 w-5 fill-current text-white" />
                                </a>
                            )}
                        </div> */}
                    </div>
                </MainTitleBar>

                <div className="grid w-full grid-cols-1 lg:grid-cols-12 relative">
                    {/* Left sidebar for filters */}
                    <div
                        className={`col-span-1 lg:col-span-3 border-r border-stone-250 bg-white relative flex-col ${
                            filtersOpen ? "flex" : "hidden"
                        }`}
                    >
                        <div
                            onClick={() => setFiltersOpen(false)}
                            className="absolute cursor-pointer lg:block hidden lg:right-[-24px] 2xl:right-[-30px] top-0 bg-white shadow-lg rounded-r-lg p-1"
                        >
                            <BackArrow className="h-5 w-5" />
                        </div>
                        <SingleListFilters
                            filters={filters}
                            setFilters={setFilters}
                            setOpenRowIndex={setOpenRowIndex}
                            filterTableData={filterTableData}
                            allOptions={allOptions}
                        />
                    </div>

                    {/* Main content - the table */}
                    <div className={`col-span-1 h-full rounded-lg ${filtersOpen ? "lg:col-span-9" : "lg:col-span-12"}`}>
                        <SingleListContactsTable
                            tableData={filteredData}
                            totalCount={totalCount}
                            setTableData={setFilteredData}
                            setTotalCount={setTotalCount}
                            page={page}
                            setPage={setPage}
                            filter={contactFilter}
                            filtersOpen={filtersOpen}
                            setOpenRowIndex={setOpenRowIndex}
                            openRowIndex={openRowIndex}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            isSearchClicked={isSearchClicked}
                            setIsSearchClicked={setIsSearchClicked}
                            listTitle={listTitle}
                        />
                    </div>

                    {/* Show/hide filter sidebar */}
                    <div
                        onClick={() => setFiltersOpen(true)}
                        className={`absolute cursor-pointer lg:block hidden left-0 top-0 bg-white shadow-lg rounded-r-lg p-1 ${
                            filtersOpen && "lg:hidden"
                        }`}
                    >
                        <BackArrow className="h-5 w-5 rotate-180" />
                    </div>
                </div>

                {/* The AI / Fuse Agent Modal Button & Modal */}
                <FuseAgentModal
                    flexSearch={true}
                    setIsSearchClicked={setIsSearchClicked}
                    setContactFilter={setContactFilter}
                    listFlex={true}
                    applyExtractFiltersPDL={applyExtractFiltersPDL}
                />
            </MainLayout>
        </KompassFiltersProvider>
    );
}
