import { Box, Typography } from "@mui/material";

const MainTitle = ({ text }) => {
    return (
        <Box
            sx={{
                height: 56,
                px: 3,
                py: 2,
                backgroundColor: "#fff",
                borderBottom: "1px solid #e7e7e7",
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography
                sx={{
                    color: "#050505",
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default MainTitle;
