import LandingLayout from "@/components/LandingLayout";
import BaseContainer from "@/components/BaseContainer";
import { ReactComponent as TextDecorIcon } from "@/assets/image/icons/text-decor.svg";
import QuestionPanel from "@/components/landing/QuestionPanel";
import { Helmet } from "react-helmet";

export default function FAQScene() {
    return (
        <>
            <Helmet>
                <title>FAQ | FuseAI</title>
                <link rel="canonical" href="https://tryfuse.ai/faq" />
            </Helmet>
            <LandingLayout pageName={"faq"}>
                <div className="bg-primary-header py-28 px-6 pre-xl:px-12">
                    <div className="flex w-full flex-col items-center ">
                        <h1 className="text-primary-black text-center text-[27px] sm:text-[30px] md:text-[40px] xl:text-[46px] leading-[1.1] font-bold">
                            Questions you might have.
                        </h1>
                        <p className="mb-20 mt-6 text-[16px] md:text-[20px] lg:text-2xl text-center md:max-w-[760px] lg:max-w-[860px] 2xl:max-w-[1350px] font-normal font-Inter leading-[1.4] text-neutral-500">
                            Discover answers to common queries.
                        </p>
                    </div>
                    <QuestionPanel />
                </div>
            </LandingLayout>
        </>
    );
}
