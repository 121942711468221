import React from "react";
import HeadCountChart from "./HeadCountChart";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import WebsiteTrafficChart from "./WebsiteTrafficChart";
import MonthlyVisitorsChart from "./MonthlyVisitorsChart";
import NorthEastIcon from "@mui/icons-material/NorthEast";

export default function LinkedIn({ companyData }) {
    return (
        <div>
            <div className="border border-[#e7e7e7] rounded-lg p-4 text-primary-black">
                <h1 className="font-semibold">Summary</h1>
                <div className="flex items-center gap-6 w-full mt-4">
                    <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                        <h1 className="font-semibold text-md">
                            {companyData.recentJobOpenings && companyData.recentJobOpenings.slice(0, 100).length}
                        </h1>
                        <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                        <p className="text-sm text-dgray-6">Jobs Openings</p>
                    </div>
                    {companyData.momLinkedInHeadCountGrowth && (
                        <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                            <div className="flex justify-between items-center">
                                <h1 className="font-semibold text-md">{companyData.momLinkedInHeadCountGrowth}</h1>
                                {companyData.linkedinFollowersMOMPercent && (
                                    <div className="bg-[#D3E1DB] py-1 px-2 text-black flex items-center gap-1 rounded-md">
                                        <NorthEastIcon className="!w-3 !h-3" color="inherit" />
                                        <p className="text-sm">{companyData.linkedinFollowersMOMPercent}%</p>
                                    </div>
                                )}
                            </div>
                            <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                            <p className="text-sm text-dgray-6 mt-3">MoM Growth</p>
                        </div>
                    )}
                    {companyData.qoqLinkedInHeadCountGrowth && (
                        <div className="border border-[#e7e7e7] rounded-lg p-4 w-1/3">
                            <div className="flex justify-between items-center">
                                <h1 className="font-semibold text-md">{companyData.qoqLinkedInHeadCountGrowth}</h1>
                                {companyData.linkedinFollowersQOQPercent && (
                                    <div className="bg-[#D3E1DB] py-1 px-2 text-black flex items-center gap-1 rounded-md">
                                        <NorthEastIcon className="!w-3 !h-3" color="inherit" />
                                        <p className="text-sm">{companyData.linkedinFollowersQOQPercent}%</p>
                                    </div>
                                )}
                            </div>
                            <hr className="text-[#e7e7e7] border-t border-[#e7e7e7] my-3" />
                            <p className="text-sm text-dgray-6">QoQ Growth</p>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex gap-5 w-full">
                {companyData.linkedInHeadcountByRoleAbsolute && Object.keys(companyData.linkedInHeadcountByRoleAbsolute).length > 0 && (
                    <div className="border border-[#e7e7e7] rounded-lg p-4 mt-4 w-1/3">
                        <div className="flex items-center gap-2 ">
                            <h1 className="font-semibold">Headcount By Role</h1>
                        </div>
                        <div className="w-full">
                            <HeadCountChart title="Role" id="HeadCountByRole" countData={companyData.linkedInHeadcountByRoleAbsolute} />
                        </div>
                    </div>
                )}

                {companyData.linkedInHeadcountByRegionAbsolute && Object.keys(companyData.linkedInHeadcountByRegionAbsolute).length > 0 && (
                    <div className="border border-[#e7e7e7] rounded-lg p-4 mt-4 w-1/3">
                        <div className="flex items-center gap-2 ">
                            <h1 className="font-semibold">Headcount By Role</h1>
                        </div>
                        <div className="w-full">
                            <HeadCountChart
                                title="Region"
                                id="HeadCountByRegion"
                                countData={companyData.linkedInHeadcountByRegionAbsolute}
                            />
                        </div>
                    </div>
                )}

                {companyData.linkedInHeadcountBySkillAbsolute && Object.keys(companyData.linkedInHeadcountBySkillAbsolute).length > 0 && (
                    <div className="border border-[#e7e7e7] rounded-lg p-4 mt-4 w-1/3">
                        <div className="flex items-center gap-2 ">
                            <h1 className="font-semibold">Headcount By Role</h1>
                        </div>
                        <div className="w-full">
                            <HeadCountChart title="Skill" id="HeadCountBySkill" countData={companyData.linkedInHeadcountBySkillAbsolute} />
                        </div>
                    </div>
                )}

                {/* <div className="border border-[#e7e7e7] rounded-lg p-4 mt-4 w-2/3 flex flex-col">
                    <div className="flex items-center gap-2 mb-auto">
                        <h1 className="font-semibold">Website Traffic</h1>
                        <div className="flex items-center gap-2 ml-auto text-primary-teal text-sm font-medium">
                            <p>This Year</p>
                            <KeyboardArrowDownOutlinedIcon className="!w-5 !h-5" color="inherit" />
                        </div>
                    </div>
                    <WebsiteTrafficChart />
                </div> */}
            </div>

            {/* <div className="border border-[#e7e7e7] rounded-lg p-4 pb-0 mt-4 flex flex-col w-full">
                <div className="flex items-center gap-2 mb-5">
                    <h1 className="font-semibold">Website Traffic</h1>
                    <div className="flex items-center gap-2 ml-auto text-primary-teal text-sm font-medium">
                        <p>This Year</p>
                        <KeyboardArrowDownOutlinedIcon className="!w-5 !h-5" color="inherit" />
                    </div>
                </div>
                <MonthlyVisitorsChart />
            </div> */}
        </div>
    );
}
