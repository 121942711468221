import { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import useAIEngagement from "@/hooks/useAIEngagement";
import { BaseInputProps } from "@/theme/typography";
import { toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CircularProgress, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

export const CAPACITY_MODAL_TYPE = {
    INVITATIONS: "invitations",
    EMAILS_MESSAGES: "emails/messages",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        width: "458px",
    },
}));

export default function UpdateCapacityDialog({
    open,
    handleClose,
    dataForUpdateCapacity,
    refetchAllLinkedinAccountsData,
    refetchAllEmailsData,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { updateCapacityData, updateUnipileAccount } = useAIEngagement();
    const [capacity, setCapacity] = useState("");
    const [loading, setLoading] = useState(false);
    const [capacityError, setCapacityError] = useState(false);

    const updateCapacity = async () => {
        try {
            setLoading(true);

            const { accountId, emailAddress, emailType, initialCapacity, capacityModalType } = dataForUpdateCapacity || {};

            let response;
            if (accountId) {
                const newCapacity =
                    capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS ? { invitations: capacity } : { messages: capacity };
                response = await updateUnipileAccount(accountId, {
                    dailyUsageLimits: { ...initialCapacity, ...newCapacity },
                });
                response.message = response?.data?.message;
            } else {
                response = await updateCapacityData({
                    url: `/mail/gmail/capacity?is_gmail=${emailType === "gmail" ? "true" : "false"}&emailAddress=${emailAddress}&capacity=${capacity}`,
                });
            }

            if (response?.status === true) {
                handleClose();
                toast.success(response?.message, { theme: "colored" });
                accountId ? await refetchAllLinkedinAccountsData() : await refetchAllEmailsData();
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("Error: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (dataForUpdateCapacity) {
            const { accountId, initialCapacity, capacityModalType } = dataForUpdateCapacity || {};

            if (accountId && capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS) {
                setCapacity(Number(initialCapacity.invitations));
            } else if (accountId && capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES) {
                setCapacity(Number(initialCapacity.messages));
            } else {
                setCapacity(Number(initialCapacity));
            }
        }
    }, [dataForUpdateCapacity]);

    const getCapacityError = (newCapacity) => {
        const { accountId, availableCapacity, capacityModalType } = dataForUpdateCapacity || {};

        return (
            newCapacity < 1 ||
            (newCapacity > 100 && capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES) ||
            (newCapacity > 20 && capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS) ||
            (newCapacity < availableCapacity?.invitations && accountId && capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS) ||
            (newCapacity < availableCapacity?.messages && accountId && capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES)
        );
    };

    const getHelperText = (capacityError, newCapacity) => {
        const { availableCapacity, capacityModalType } = dataForUpdateCapacity || {};

        if (capacityError) {
            if (newCapacity < 1) {
                return "The value must be at least 1.";
            }
            if (newCapacity > 100 && capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES) {
                return "The value cannot exceed 100 for Emails/Messages.";
            }
            if (newCapacity > 20 && capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS) {
                return "The value cannot exceed 20 for Invitations.";
            }
            if (newCapacity < availableCapacity?.invitations && capacityModalType === CAPACITY_MODAL_TYPE.INVITATIONS) {
                return `The value cannot be less than the available invitations capacity: ${availableCapacity.invitations}.`;
            }
            if (newCapacity < availableCapacity?.messages && capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES) {
                return `The value cannot be less than the available messages capacity: ${availableCapacity.messages}.`;
            }
            return "Invalid value.";
        }

        return `The value should be between 1 and ${capacityModalType === CAPACITY_MODAL_TYPE.EMAILS_MESSAGES ? "100" : "20"}`;
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
            <div className="flex gap-[16px] justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className=" text-[#050505] text-[18px] font-semibold font-['Inter']">Please enter new capacity</div>
                <button onClick={handleClose} className="flex justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <div>
                    <TextField
                        sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                                fontFamily: "Inter",
                                fontSize: 14,
                                "& fieldset": {
                                    border: "none",
                                },
                            },
                        }}
                        placeholder="Enter Capacity"
                        value={capacity}
                        type="number"
                        helperText={getHelperText(capacityError, capacity)}
                        error={capacityError}
                        onChange={(e) => {
                            const newCapacity = e.target.value;
                            const isError = getCapacityError(newCapacity);
                            setCapacityError(isError);
                            setCapacity(newCapacity);
                        }}
                        InputProps={BaseInputProps}
                    />
                </div>
                <div className="flex justify-center px-6 py-4 gap-4">
                    <Button
                        disabled={loading}
                        className="px-[16px] py-[8px] border border-[#e7e7e7] text-center capitalize rounded-[8px] main-text-black-500 bg-white 2xl:px-2"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={loading || capacityError}
                        className="px-[16px] py-[8px] border border-[#e7e7e7] text-center capitalize rounded-[8px] main-text-black-500 bg-white 2xl:px-2"
                        onClick={updateCapacity}
                    >
                        {loading ? <CircularProgress size="1.2rem" className="mr-2" /> : null}
                        Save
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
