// CustomTable.jsx
import React, { useState, useMemo } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Box,
    Typography,
    Pagination,
    PaginationItem,
} from "@mui/material";

// Import icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/system";

// Styled components for header and body cells
const StyledHeadCell = styled(TableCell)(({ highlight }) => ({
    backgroundColor: "#f4f3f0",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#e0e0e0",
    // }),
}));

const StyledBodyCell = styled(TableCell)(({ highlight }) => ({
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
    // ...(highlight && {
    //     backgroundColor: "#fafafa",
    // }),
}));

const CustomTable = ({ tableLayout, tableData, rowsPerPage = 10 }) => {
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");

    // Handle sorting
    const handleSort = (columnId) => {
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    // Sort data based on order and orderBy
    const sortedData = useMemo(() => {
        if (!orderBy) return tableData;
        return [...tableData].sort((a, b) => {
            if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
            if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
            return 0;
        });
    }, [tableData, order, orderBy]);

    // Handle page change
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const currentData = sortedData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // Render sorting icons
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;
        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "2px",
                    cursor: "pointer",
                    position: "relative",
                    top: "-1px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    return (
        <div>
            <div className="text-[#050505] text-sm font-medium font-['Inter'] mb-6">{tableLayout.label}</div>
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    marginLeft: "0px",
                    borderTop: "1px solid #e7e7e7",
                    borderLeft: "1px solid #e7e7e7",
                    borderRight: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                        height: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                        marginTop: "8px",
                        marginBottom: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#aaa",
                        borderRadius: "6px",
                    },
                }}
            >
                <Table sx={{ borderCollapse: "separate", tableLayout: "fixed" }}>
                    <TableHead>
                        <TableRow>
                            {tableLayout.column.map((col) => (
                                <StyledHeadCell
                                    key={col.id}
                                    sx={{ width: col.width }}
                                    align="left"
                                    highlight={false} // Adjust if needed
                                    onClick={col.sortable ? () => handleSort(col.id) : undefined}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Box display="flex" alignItems="center" gap={1}>
                                            {col.icon}
                                            <Typography fontSize={14} fontWeight="medium" color="#5d5d5d" sx={{ lineHeight: "21px" }}>
                                                {col.label}
                                            </Typography>
                                        </Box>
                                        {renderSortingIcons(col)}
                                    </Box>
                                </StyledHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentData.length > 0 ? (
                            currentData.map((row) => (
                                <TableRow key={row.id}>
                                    {tableLayout.column.map((col) => {
                                        let cellContent;
                                        if (col.renderCell) {
                                            cellContent = col.renderCell(row[col.id] || "N/A");
                                        } else {
                                            cellContent = (
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight="medium"
                                                    color="#050505"
                                                    sx={{ lineHeight: "21px", textTransform: "capitalize" }}
                                                >
                                                    {row[col.id] || "N/A"}
                                                </Typography>
                                            );
                                        }
                                        return (
                                            <StyledBodyCell sx={{ textTransform: "capitalize" }} key={`${row.id}-${col.id}`}>
                                                {cellContent}
                                            </StyledBodyCell>
                                        );
                                    })}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={tableLayout.column.length}
                                    align="center"
                                    sx={{
                                        py: 3,
                                        color: "gray",
                                        borderRight: "1px solid #e7e7e7",
                                        borderBottom: "1px solid #e7e7e7",
                                    }}
                                >
                                    {tableData.length === 0 ? "Nothing to display" : "Loading..."}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            {sortedData.length > rowsPerPage && (
                <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}
        </div>
    );
};

export default CustomTable;
