import React, { useState, useRef, useEffect } from "react";
import SelectAll from "./SelectAll";
import ApprovalCard from "./approvalCard/ApprovalCard";
import useScheduledEmails from "@/hooks/useScheduledEmails";

function PendingEmails() {
    const { getPendingEmails, getTargetContacts } = useScheduledEmails();
    const getPendingEmailsRef = useRef();
    getPendingEmailsRef.current = getPendingEmails;

    const getTargetContactsRef = useRef();
    getTargetContactsRef.current = getTargetContacts;

    const { approveEmails } = useScheduledEmails();
    const approveEmailsRef = useRef();
    approveEmailsRef.current = approveEmails;

    const [cards, setCards] = useState([]);

    useEffect(() => {
        const fetchPendingEmails = async () => {
            try {
                const response = await getPendingEmailsRef.current();
                const mailCards = [];
                for (let r in response) {
                    let cardObject = {
                        id: response[r]._id,
                        subject: response[r].subject,
                        body: response[r].body,
                        scheduledDateUTC: response[r].scheduledDateUTC,
                    };
                    let campaignContactsResponse = await getTargetContactsRef.current(response[r].campaignId);
                    let allCampaignContacts = campaignContactsResponse.response;
                    for (let a in allCampaignContacts) {
                        if (allCampaignContacts[a]._id === response[r].targetContactId) {
                            cardObject.name = allCampaignContacts[a].fullName;
                            cardObject.email = allCampaignContacts[a].primaryEmail;
                            break;
                        }
                    }
                    mailCards.push(cardObject);
                    console.log(mailCards);
                }
                setCards(mailCards);
            } catch (e) {
                console.log(e);
            }
        };
        fetchPendingEmails();
    }, []);

    const handleSelectAll = (isSelected) => {
        const updatedCards = cards.map((card) => ({
            ...card,
            isSelected: isSelected,
        }));
        setCards(updatedCards);
    };

    const handleDeleteAll = async () => {
        const selectedIds = cards.filter((item) => item.isSelected).map((item) => item.id);
        const response = await approveEmailsRef.current(selectedIds, false);
        console.log("Delete response: ", response);
        for (let i in selectedIds) {
            removeCard(selectedIds[i]);
        }
    };

    const handleCardSelect = (id) => {
        const updatedCards = cards.map((card) => (card.id === id ? { ...card, isSelected: !card.isSelected } : card));
        setCards(updatedCards);
    };

    const removeCard = (id) => {
        setCards((prevCards) => prevCards.filter((card) => card.id !== id));
    };

    const handleEditEmail = (editedEmail) => {
        const updatedCards = cards.map((card) => (card.id === editedEmail.id ? { ...card, ...editedEmail } : card));
        setCards(updatedCards);
    };

    const getSelectedMailNumber = () => {
        return cards.filter((item) => item.isSelected).map((item) => item.id).length;
    };

    return (
        <div className="py-10 px-6">
            {cards.length > 0 && (
                <SelectAll
                    handleSelectAll={handleSelectAll}
                    handleDeleteAll={handleDeleteAll}
                    getSelectedMailNumber={getSelectedMailNumber}
                />
            )}
            {cards.map((card) => (
                <ApprovalCard
                    key={card.id}
                    card={card}
                    onCardSelect={handleCardSelect}
                    onCardRemove={removeCard}
                    handleEditEmail={handleEditEmail}
                />
            ))}
        </div>
    );
}

export default PendingEmails;
