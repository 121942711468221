import React from "react";
import Badge from "./Badge";
import DateDisplay from "./DateDisplay";
import Recipient from "./Recipient";
import MessagePreview from "./MessagePreview";
import ActionButton from "./ActionButton";
import EditDropdown from "../EditDropdown";
import { formatDateTime } from "@/utils/common";

function ApprovalCard({ card, onCardSelect, onCardRemove, handleEditEmail }) {
    const { id, name, email, subject, body, scheduledDateUTC, isSelected } = card;

    const formattedDate = formatDateTime(scheduledDateUTC);

    const handleCheckboxChange = () => {
        onCardSelect(id);
    };

    return (
        <article className="flex flex-wrap gap-5 items-start text-sm my-8 -mx-6">
            <input
                type="checkbox"
                checked={isSelected}
                onChange={handleCheckboxChange}
                className="form-checkbox h-4 w-4 text-accents-mainGreen"
            />
            <section className="flex flex-col flex-1 shrink p-4 bg-white rounded-lg border border-solid basis-0 border-neutral-200 min-w-[240px] max-md:max-w-full">
                <header className="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
                    <div className="flex gap-2 self-stretch my-auto">
                        <Badge status="Pending" />
                        <DateDisplay date={formattedDate} />
                    </div>
                    <EditDropdown
                        data={card}
                        onDecline={() => onCardRemove(id)}
                        scheduledEmailId={id}
                        emailStatus={"approval_required"}
                        handleEditEmail={handleEditEmail}
                    />
                </header>
                <Recipient name={name} email={email} />
                <MessagePreview title={subject} content={body} />
                <footer className="flex gap-2 items-start self-start mt-6 font-medium leading-snug whitespace-nowrap">
                    <ActionButton type="approve" scheduledEmailId={id} onApproved={() => onCardRemove(id)} />
                    <ActionButton type="decline" scheduledEmailId={id} onApproved={() => onCardRemove(id)} />
                </footer>
            </section>
        </article>
    );
}

export default ApprovalCard;
