import React, { useEffect, useState } from "react";
import SelectComponent from "@/components/crm/opportunities/SelectComponent";
import { ReactComponent as EmptyStateIcon } from "@/assets/image/crm/timeline/empty.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TaskDetailsModal from "./TaskDetailsModal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import useTasks from "@/hooks/crm/useTasks";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import CreateTaskModal from "./CreateTaskModal";
import { CircularProgress } from "@mui/material";

export default function TimelineTasks() {
    const { getTasksByOpportunityId, markAsComplete } = useTasks();
    const [openModal, setOpenModal] = useState(false);
    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshUI, setRefreshUI] = useState(false);
    const [activeTask, setActiveTask] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [isOpenCreateTaskModal, setIsOpenCreateTaskModal] = useState(false);
    const [completingLoading, setCompletingLoading] = useState(null);

    const { opportunityId } = useParams();

    const isEmpty = taskData.length === 0;

    useEffect(() => {
        const fetchTasks = async (id) => {
            setLoading(true);
            try {
                const response = await getTasksByOpportunityId(id);
                if (response) {
                    setTaskData(response.data);
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };
        if (opportunityId) {
            fetchTasks(opportunityId);
        }
    }, [opportunityId]);

    useEffect(() => {
        if (opportunityId) {
            const fetchTasks = async (id) => {
                try {
                    const response = await getTasksByOpportunityId(id);
                    if (response) {
                        setTaskData(response.data);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchTasks(opportunityId);
        }
    }, [refreshUI]);

    const handleMarkAsComplete = async (taskIds) => {
        setCompletingLoading(taskIds);
        try {
            const promises = taskIds.map((taskId) => markAsComplete(taskId));
            await Promise.all(promises);
            setRefreshUI((prev) => !prev); // Refresh UI to reflect changes
        } catch (error) {
            console.error("Error marking tasks as complete", error);
        }
        setCompletingLoading(null);
        setSelectedTasks([]);
    };

    const handleSelectAll = () => {
        const allTaskIds = taskData.filter((task) => !task.isCompleted).map((task) => task._id);
        setSelectedTasks(allTaskIds);
    };

    const handleClearAll = () => {
        setSelectedTasks([]);
    };

    const toggleTaskSelection = (taskId) => {
        setSelectedTasks((prev) => (prev.includes(taskId) ? prev.filter((id) => id !== taskId) : [...prev, taskId]));
    };

    return (
        <div>
            <CreateTaskModal setRefreshUI={setRefreshUI} open={isOpenCreateTaskModal} setOpen={setIsOpenCreateTaskModal} />
            <TaskDetailsModal setRefreshUI={setRefreshUI} open={openModal} setOpen={setOpenModal} rowData={activeTask} />
            <div className="border-b flex items-center border-stone-250 p-3 py-5">
                <p className="font-semibold">Tasks</p>
                {/* <div className="flex gap-4 ml-auto">
                    <SelectComponent bgColor="#fff" sx={{ width: "130px" }} options={["op1"]} placeholder="All" />
                    <SelectComponent bgColor="#fff" options={["op1"]} placeholder="This Week" sx={{ width: "130px" }} />
                </div> */}
            </div>
            <div className="p-3 text-sm">
                <button
                    onClick={() => setIsOpenCreateTaskModal(true)}
                    className="py-1.5 px-4 text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100"
                >
                    <AddOutlinedIcon className="!w-5 !h-4" />
                    <p className="text-xs font-medium">Add Task</p>
                </button>
                {loading ? (
                    <div className="text-black h-64 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                    </div>
                ) : taskData.length === 0 ? (
                    <EmptyState />
                ) : (
                    <>
                        {selectedTasks.length > 0 && (
                            <div className="flex gap-2 mt-5">
                                <div className="flex gap-4 items-center">
                                    <p className="font-semibold">{selectedTasks.length} Selected</p>
                                    <button onClick={handleSelectAll} className="font-semibold text-blue-500 hover:text-blue-600">
                                        Select All
                                    </button>
                                    {selectedTasks.length > 0 && (
                                        <button onClick={handleClearAll} className="font-semibold text-blue-500 hover:text-blue-600">
                                            Clear All
                                        </button>
                                    )}
                                    {selectedTasks.length > 0 && (
                                        <button
                                            onClick={() => handleMarkAsComplete(selectedTasks)}
                                            disabled={selectedTasks.length === 0 || completingLoading}
                                            className="font-semibold text-blue-500 hover:text-blue-600"
                                        >
                                            Mark As Complete
                                        </button>
                                    )}
                                </div>

                                {/* <button className="py-1.5 px-4 ml-auto text-sm flex gap-1 items-center rounded-md border border-stone-250 hover:bg-dgray-100">
                                    <FileDownloadOutlinedIcon className="!w-5 !h-4" />
                                    <p className="text-xs font-medium">Download</p>
                                </button> */}
                            </div>
                        )}
                        <div className="flex flex-col gap-4 mt-4">
                            {taskData.map((task) => (
                                <div key={task._id} className="border text-sm border-stone-250 rounded-lg p-3 mt-1">
                                    <div className="flex items-center gap-4">
                                        <input
                                            type="checkbox"
                                            className="scale-105 cursor-pointer"
                                            checked={task.isCompleted ? false : selectedTasks.includes(task._id)}
                                            onChange={() => toggleTaskSelection(task._id)}
                                            disabled={task.isCompleted}
                                        />
                                        <p className="font-semibold">{task.title}</p>
                                        <p className="text-dgray-6 ml-auto">{dayjs(task.createdAt).format("hh:mmA")}</p>
                                    </div>
                                    <div className="ml-8 mt-3">
                                        <p>{task.description}</p>
                                        <div className="mt-3 flex gap-2 items-center">
                                            {task.isCompleted ? (
                                                <div className="flex gap-2 items-center bg-green-50 py-1 px-2 rounded-md">
                                                    <CheckCircleOutlineOutlinedIcon className="!w-5 !h-4" />
                                                    <p>Completed</p>
                                                </div>
                                            ) : (
                                                <button
                                                    onClick={() => handleMarkAsComplete([task._id])}
                                                    disabled={completingLoading?.includes(task._id)}
                                                    className="flex gap-2 disabled:bg-dgray-300 items-center bg-stone-100 py-1 px-2 rounded-md hover:bg-dgray-200 cursor-pointer"
                                                >
                                                    {completingLoading?.includes(task._id) && (
                                                        <CircularProgress size={10} color="inherit" />
                                                    )}
                                                    <CheckCircleOutlineOutlinedIcon className="!w-5 !h-4" />
                                                    <p>Mark As Complete</p>
                                                </button>
                                            )}
                                            <div
                                                onClick={() => {
                                                    setOpenModal(true);
                                                    setActiveTask(task);
                                                }}
                                                className="flex gap-2 items-center bg-stone-100 py-1 px-2 rounded-md hover:bg-dgray-200 cursor-pointer"
                                            >
                                                <VisibilityOutlinedIcon className="!w-5 !h-4" />
                                                <p>View Details</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const EmptyState = () => (
    <div className="flex flex-col justify-center items-center h-64">
        {/* <EmptyStateIcon /> */}
        <p className="font-semibold text-lg mt-5">No task yet! </p>
        <p className="text-dgray-6 mt-3">Add your first task now!</p>
    </div>
);
