import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import IconButton from "@mui/material/IconButton";
import KeyboardIcon from "@mui/icons-material/Keyboard";

import { useModal } from "@/context/ModalContext";
import AiIntelligenceModal from "./AiIntelligenceModal";

export default function FuseAgentModal({
    flexSearch = false,
    setIsSearchClicked = false,
    setContactFilter = () => {},
    handleSearchByFilter = () => {},

    // NEW PROP FOR EXTRACT FILTERS:
    listFlex = false,
    applyExtractFiltersPDL = () => {},
}) {
    const { isOpen, toggleModal, closeModal } = useModal();

    // Close the modal on "Escape" key press or if "Command + I" is pressed
    useEffect(() => {
        const handleKeyPress = (e) => {
            if ((e.metaKey || e.ctrlKey) && e.key === "i") {
                toggleModal();
            } else if (e.key === "Escape") {
                closeModal();
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [toggleModal, closeModal]);

    return (
        <div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="fixed bottom-4 right-4 z-50"
                    >
                        <AiIntelligenceModal
                            toggleModal={toggleModal}
                            handleSearchByFilter={handleSearchByFilter}
                            setIsSearchClicked={setIsSearchClicked}
                            flexSearch={flexSearch}
                            setContactFilter={setContactFilter}
                            // PASS IT DOWN
                            listFlex={listFlex}
                            applyExtractFiltersPDL={applyExtractFiltersPDL}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            {/* 
                The button that toggles the modal was commented out in your snippet,
                so we leave it as is (commented). If you want the button, just uncomment.
            */}
            {/* {!isOpen && (
                <div className="fixed bottom-4 right-4 z-50">
                    <IconButton
                        onClick={toggleModal}
                        sx={{
                            backgroundColor: "#050505",
                            color: "white",
                            borderRadius: "9999px",
                            "&:hover": { backgroundColor: "#333" },
                        }}
                    >
                        <KeyboardIcon />
                    </IconButton>
                </div>
            )} */}
        </div>
    );
}
