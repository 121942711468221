import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button } from "@material-tailwind/react";
import cl from "classnames";
import useAuth from "@/hooks/useAuth";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import BackdropLoader from "../common/BackdropLoader";
import ConfirmationModal from "../payment/ConfirmationModal";

const modalPresets = {
    disconnect: {
        icon: <WarningRoundedIcon className={" !h-full !w-full !fill-white"} />,
        title: "Confirm disconnect",
        message: "You are about to disconnect from the service, proceed?",
        footnotes: [
            "Contacts that were uploaded through Kompass might need to be removed on the service and added again after reconnection",
        ],
    },
};

export default function IntegrateCard({ info, onConnect, method, getTokens, checkConnect, disconnect, service, checkAuth, getCode }) {
    const { getAuthToken } = useAuth();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const handleConnectionCheck = async () => {
        const token = await getAuthToken();
        let res = null;
        const { code } = getCode();
        const tokens = getTokens();

        if (method === "code" && code) {
            res = await checkConnect(method, code, token, service);
            await checkAuth(token, service);
            navigate("/integrate");
        } else if (method === "token" && tokens) {
            res = await checkConnect(method, tokens, token, service);
            await checkAuth(token, service);
            navigate("/integrate");
        } else {
            res = await checkAuth(token, service);
        }
        return res;
    };

    const handleConnection = async () => {
        const token = await getAuthToken();
        let res = await onConnect(token, service);
        return res;
    };

    const connectMutation = useMutation({
        mutationFn: handleConnection,
    });

    const handleDisconnect = async () => {
        const token = await getAuthToken();
        let res = await disconnect(token, service);
        queryClient.invalidateQueries({ queryKey: [info.label] });
        setConfirmDisconnect(false);
        return res;
    };
    const { mutateAsync: handleDisconnectTrigger, isPending } = useMutation({
        mutationFn: handleDisconnect,
    });

    const [confirmDisconnect, setConfirmDisconnect] = useState(false);

    const { data: authCheck, isFetching } = useQuery({
        queryKey: [info.label],
        refetchOnWindowFocus: false,
        retry: 0,
        queryFn: handleConnectionCheck,
    });

    return (
        <div className="h-full relative flex flex-col gap-4 self-stretch rounded-lg border border-accents-gray bg-white p-4 transition-all duration-300 hover:scale-[1.01] hover:border-blue-500 hover:drop-shadow-lg">
            <ConfirmationModal
                showState={[confirmDisconnect, setConfirmDisconnect]}
                modal={{
                    ...modalPresets.disconnect,
                    handleConfirm: () => handleDisconnectTrigger(),
                }}
                loading={isPending}
            ></ConfirmationModal>
            <BackdropLoader active={connectMutation.isPending} />
            <div className="flex flex-row justify-between">
                <div className="font-Inter flex cursor-pointer flex-row items-center gap-2 text-left text-sm font-semibold text-gray-950">
                    <ReactSVG src={info.icon} />
                    <span>{info.label}</span>
                </div>
            </div>
            <hr className="w-full rounded-full bg-stone-250" />
            <div className="font-Inter font-medium text-dgray-6 cursor-pointer text-sm">{info.description}</div>
            <div className="w-full mt-auto gap-4 flex flex-row justify-start">
                {authCheck && !isFetching && (
                    <Button
                        className={cl(
                            "font-Inter font-medium capitalize flex items-center justify-center gap-2 rounded-lg bg-red-500 px-3 py-2 text-sm leading-[100%] text-white hover:opacity-80 transition-opacity"
                        )}
                        onClick={() => setConfirmDisconnect(true)}
                    >
                        disconnect
                    </Button>
                )}
                <div className="flex justify-end">
                    <Button
                        className={cl(
                            "font-Inter font-medium flex items-center justify-center gap-2 rounded-lg bg-stone-950 px-3 py-2 text-sm capitalize leading-[100%] text-white hover:opacity-80 transition-opacity",
                            {
                                "border-1 border-black bg-white text-black ": authCheck && !isFetching,
                            }
                        )}
                        onClick={() => {
                            if (!authCheck) connectMutation.mutateAsync();
                        }}
                    >
                        {isFetching && (
                            <>
                                <CircularProgress style={{ width: "14px", height: "14px", color: "white" }} />
                                checking
                            </>
                        )}
                        {!authCheck && !isFetching && <>connect</>}
                        {!!authCheck && !isFetching && "connected"}
                    </Button>
                </div>
            </div>
        </div>
    );
}
