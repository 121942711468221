import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        maxWidth: "none",

        fontFamily: "Inter",

        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "532px",
        },
    },
}));

export const BulkPopup = ({ open, onClose, children }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={onClose}>
            <div className="flex px-6 py-4 flex-row justify-between items-center">
                <h3 className="text-[#090c05] text-lg font-semibold font-['Inter'] leading-snug 2xl:text-[28px]">Bulk Enrich</h3>
                <button onClick={onClose} className=" flex flex-row justify-end z-10">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <hr className="bg-[#e7e7e7]" />
            <DialogContent sx={{ padding: 0, position: "relative" }}>{children}</DialogContent>
        </BootstrapDialog>
    );
};
