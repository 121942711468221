import { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import useBulk from "@/hooks/useBulk";
import ConfirmDialog from "@/components/bulk/ConfirmDialog";
import FileUploaderWidget from "@/components/bulk/FileUploaderWidget";
import { ReactComponent as TableSearchSVG } from "@/assets/image/bulk/table_search.svg";
import { ReactComponent as TableLinkedinSVG } from "@/assets/image/bulk/table_linkedin.svg";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import { ENRICHMENT_TYPE, KompassString, S3_URL } from "@/utils/constants";
import { goToTop, trimKeys } from "@/utils/common";
import { useNavigate } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useCredits } from "@/hooks/useCredits";
import LimitModal from "../search/LimitModal";

export default function BulkUploadWidget({ userInfo }) {
    const [currentWidgetIndex, setCurrentWidgetIndex] = useState(1);
    const { bulkEnrichByLinkedin, bulkEnrichBySearch } = useBulk();
    const [enrichOption, setEnrichOption] = useState(0); // 0: csv/xls/xlsx, 1: linkedin profile
    const [visibleConfirmDlg, setVisibleConfirmDlg] = useState(false);
    const [alertConfirmDlg, setVisibleAlertDlg] = useState(false);
    const [listName, setListName] = useState("");
    const [linkedinUrls, setLinkedinUrls] = useState([]);
    const [attachedFileInfo, setAttachedFileInfo] = useState(null);
    const [isEmailIncluded, setEmailIncluded] = useState(true);
    const [isPhoneIncluded, setPhoneIncluded] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const { credits } = useCredits();

    const alertMessage = () => {
        if (!userInfo) return;
        const { arePersonalEmailsPriority, databaseType } = userInfo;
        const emailPrice = arePersonalEmailsPriority ? 100 : databaseType === "premium" ? 50 : 10;
        const phonePrice = 200;

        if (isEmailIncluded && !isPhoneIncluded) {
            return `${emailPrice} credits for each found email will be charged.`;
        }
        if (!isEmailIncluded && isPhoneIncluded) {
            return `${phonePrice} credits for each found phone will be charged.`;
        }
        if (isEmailIncluded && isPhoneIncluded) {
            return `${phonePrice} credits for each found phone  & ${emailPrice} credits for each found email will be charged.`;
        }
    };

    const options = [
        {
            icon: "csv",
            header: "CSV Upload",
        },
        {
            icon: "contact",
            header: "List of LinkedIn URL’s",
        },
    ];

    useEffect(() => {
        setAttachedFileInfo(null);
    }, [currentWidgetIndex]);

    const onOptionSelected = (option) => {
        setEnrichOption(option);
        setCurrentWidgetIndex(1);
    };

    const onFinishClicked = async () => {
        if (listName?.length === 0) {
            toast.error(KompassString.BukUploadError("fill the list name"), {
                theme: "colored",
            });
        } else if (!linkedinUrls && currentWidgetIndex === 1) {
            toast.error(KompassString.BukUploadError("enter linkedin urls"), {
                theme: "colored",
            });
        } else if (!attachedFileInfo && currentWidgetIndex === 2) {
            toast.error(KompassString.BukUploadError("select a file"), {
                theme: "colored",
            });
        } else if (linkedinUrls?.length === 0 && enrichOption === 1) {
            toast.error(KompassString.BukUploadError("provide the LinkedIn URLs"), {
                theme: "colored",
            });
        } else if (attachedFileInfo?.inputs?.length > 500 && enrichOption === 0) {
            toast.error("Uploaded file contains more than 500 entries", {
                theme: "colored",
            });
        } else if (linkedinUrls?.length > 25 && enrichOption === 1) {
            toast.error("You have exceeded the limit of 25 LinkedIn URLs. Please remove the extra URLs to proceed", {
                theme: "colored",
            });
        } else if (attachedFileInfo?.inputs?.length === 0 && enrichOption === 0) {
            toast.error(KompassString.BukUploadError("attach the File"), {
                theme: "colored",
            });
        } else if (!isEmailIncluded && !isPhoneIncluded) {
            toast.error("Please choose which field to enrich", {
                theme: "colored",
            });
        } else {
            if (attachedFileInfo?.inputs?.length > credits?.data?.emails?.available || credits?.data?.available === 0) {
                setVisibleAlertDlg(true);
                setVisibleConfirmDlg(false);
            } else {
                setVisibleConfirmDlg(true);
            }
        }
    };

    const onBulkConfirmed = async () => {
        goToTop();
        setLoading(true);

        let enrichType;
        if (isEmailIncluded && !isPhoneIncluded) enrichType = ENRICHMENT_TYPE.EMAIL_ONLY;
        if (!isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.PHONE_ONLY;
        if (isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.ALL;
        if (!isEmailIncluded && !isPhoneIncluded) return;

        if (enrichOption === 0) {
            if (attachedFileInfo?.bulkType === "linkedin") {
                bulkEnrichByLinkedin({
                    contactTitle: listName,
                    inputs: attachedFileInfo?.inputs?.map((input) => ({ url: input })),
                    enrichType,
                });
            }
            if (attachedFileInfo?.bulkType === "search") {
                bulkEnrichBySearch({
                    contactTitle: listName,
                    inputs: attachedFileInfo?.inputs,
                    enrichType,
                });
            }
        } else if (enrichOption === 1) {
            bulkEnrichByLinkedin({
                contactTitle: listName,
                inputs: linkedinUrls?.map((url) => ({ url })),
                enrichType,
            });
        }

        setTimeout(() => {
            setLoading(false);
            navigate("/bulkenrich");
        }, 2000);
    };

    const extractLinkedinUrlsFromText = (value) => {
        const linkedinUrls = value
            .split("\n")
            .map((line) => line.trim())
            .filter((line, index, self) => line && self.indexOf(line) === index);

        setLinkedinUrls(linkedinUrls);
    };

    const handleFileContent = (fileName, bulkType, fileContent) => {
        let attachedInfo = {
            name: fileName,
            content: fileContent,
            bulkType,
        };

        const inputs = [];
        if (bulkType === "linkedin") {
            for (const input of fileContent) {
                const trimmedInput = trimKeys(input);
                inputs.push(trimmedInput?.LinkedinURLs);
            }
        }
        if (bulkType === "search") {
            for (const input of fileContent) {
                const trimmedInput = trimKeys(input);
                inputs.push({
                    name: trimmedInput?.Name,
                    company: trimmedInput?.Company,
                    location: trimmedInput?.Location,
                });
            }
        }
        attachedInfo["inputs"] = inputs;

        setAttachedFileInfo(attachedInfo);
    };

    const navigate = useNavigate();

    return (
        <>
            <div className="w-full flex flex-col md:flex-row font-Inter mt-2 text-center text-lg justify-start gap-2 border-b border-b-accents-gray">
                {options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                onOptionSelected(index);
                            }}
                            className={
                                "group text-sm font-medium text-[#888888] border-b-2 border-b-white p-2 cursor-pointer aria-selected:text-black aria-selected:border-b-black" +
                                (index === 0 ? " ml-6" : "")
                            }
                            aria-selected={index === enrichOption}
                        >
                            {option.header}
                        </div>
                    );
                })}
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-10">
                <div className="flex flex-col w-full">
                    {enrichOption === 0 && currentWidgetIndex === 1 && (
                        <div className="flex flex-col gap-8 px-6">
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2 font-Inter text-[#5d5d5d] text-sm font-semibold leading-tight">
                                    <p>Please upload your contact list in CSV format.</p>
                                    <p>In order to match an accurate list of people with your data we suggest that each row have either:</p>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 md:gap-8 w-full md:space-between">
                                <TableSearchSVG />
                                <div className="my-auto flex items-center justify-center">
                                    <p className="font-Inter text-[#050505] text-base font-normal leading-tight">OR</p>
                                </div>
                                <TableLinkedinSVG />
                            </div>
                            <div className="flex flex-col">
                                <div className="flex px-6 py-4 justify-between items-center rounded-lg border border-[#e7e7e7] max-w-[682px] gap-10">
                                    <div className="flex flex-col gap-2">
                                        <p className="font-Inter text-left text-[#050505] text-sm font-semibold leading-normal">
                                            Use our template for the most accurate results
                                        </p>
                                        <span className="font-Inter text-left text-[#5d5d5d] text-sm font-medium leading-tight">
                                            Download our CSV template for correct column format.
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => {
                                            window.open(S3_URL.KOMPASS_BULK_TEMPLATE, "_blank");
                                        }}
                                        className=" w-fit py-2 font-InterMedium px-4 flex items-center gap-2 text-white bg-[#050505]  whitespace-nowrap rounded-lg text-sm hover:opacity-80 transition-opacity duration-300"
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_1348_8973" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#ffffff" />
                                            </mask>
                                            <g mask="url(#mask0_1348_8973)">
                                                <path
                                                    d="M10 13L6 9L7.0625 7.9375L9.25 10.125V3H10.75V10.125L12.9375 7.9375L14 9L10 13ZM5.49417 16C5.08139 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.8531 15.2656 15.5592 15.5594C15.2653 15.8531 14.9119 16 14.4992 16H5.49417Z"
                                                    fill="#ffffff"
                                                />
                                            </g>
                                        </svg>
                                        Fuse AI CSV Template
                                    </button>
                                </div>
                                <div className="h-[33px] w-fit mt-5 p-2 bg-[#F4F3F0] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="flex-col justify-center items-start gap-2 flex">
                                        <div className="text-primary-black text-xs font-normal font-['Inter'] leading-[16.80px]">
                                            Note: You will only be charged for accurate matches.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {enrichOption === 0 && currentWidgetIndex === 2 && (
                        <div className="w-full flex flex-col items-center justify-center text-gray-950 px-6">
                            <div className="flex w-full flex-col gap-10">
                                <div className="flex flex-col gap-2">
                                    <span className="font-Inter text-left text-[#050505] text-sm font-semibold leading-tight">
                                        List name
                                    </span>
                                    <input
                                        onChange={(e) => {
                                            setListName(e.currentTarget.value);
                                        }}
                                        className="outline-none max-w-[630px] py-3 px-6 font-Inter text-sm w-full placeholder:text-[#B0B0B0] font-medium border-[e7e7e7] border bg-white rounded-[8px]"
                                        placeholder="List name"
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    {attachedFileInfo ? (
                                        <FileUploaderWidget attachedFileName={attachedFileInfo?.name} />
                                    ) : (
                                        <FileUploaderWidget onFileContent={handleFileContent} />
                                    )}
                                    <div className="flex flex-row items-center gap-1">
                                        <InformationSVG />

                                        <span className="text-[#888888] text-xs font-normal font-['Inter'] leading-none">
                                            The column names in uploaded file must precisely match the ones specified in the provided
                                            template.
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div className="flex flex-row items-center gap-1 font-Inter text-sm font-medium">
                                        <Checkbox
                                            checked={isEmailIncluded}
                                            onChange={(event) => {
                                                setEmailIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon className="text-accents-mainGreen" fontSize="small" />}
                                        />
                                        <span className={isEmailIncluded ? "text-current" : "text-[#888888]"}>Email</span>
                                    </div>
                                    <div className="flex flex-row items-center gap-1 font-Inter text-sm font-medium">
                                        <Checkbox
                                            checked={isPhoneIncluded}
                                            onChange={(event) => {
                                                setPhoneIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon className="text-accents-mainGreen" fontSize="small" />}
                                        />
                                        <span className={isPhoneIncluded ? "text-current" : "text-[#888888]"}>Phone</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {enrichOption === 1 && (
                        <div className="w-full flex flex-col items-center justify-center text-gray-950 px-6">
                            <div className="flex w-full flex-col gap-6">
                                <div className="flex flex-col gap-2">
                                    <span className="font-Inter text-left text-[#050505] text-sm font-semibold leading-tight">
                                        List name
                                    </span>
                                    <input
                                        onChange={(e) => {
                                            setListName(e.currentTarget.value);
                                        }}
                                        className="outline-none max-w-[630px] py-3 px-6 font-Inter text-sm w-full placeholder:text-[#B0B0B0] font-medium border-[e7e7e7] border bg-white rounded-[8px]"
                                        placeholder="List name"
                                    />
                                </div>
                                <div className="flex flex-col border border-[#e7e7e7] rounded-lg max-w-[630px]">
                                    <div className="text-sm font-medium text-[#5D5D5D] rounded-t-lg border-b border-b-[#e7e7e7] font-Inter bg-[#F4F3F0] p-3 w-full">
                                        Enter LinkedIn URL (Max. 25) (One per line)
                                    </div>
                                    <textarea
                                        onChange={(e) => {
                                            extractLinkedinUrlsFromText(e.currentTarget.value);
                                        }}
                                        className="outline-none resize-none font-Inter p-3 text-sm font-medium w-full placeholder:text-[#B0B0B0] bg-white rounded-b-lg"
                                        placeholder={`https://www.linkedin.com/in/maikal-yamauchi/\nhttps://www.linkedin.com/in/imogen-low/\n....`}
                                        rows={7}
                                    />
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div className="flex flex-row items-center gap-1 font-Inter text-sm font-medium">
                                        <Checkbox
                                            checked={isEmailIncluded}
                                            onChange={(event) => {
                                                setEmailIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon className="text-accents-mainGreen" fontSize="small" />}
                                        />
                                        <span className={isEmailIncluded ? "text-current" : "text-[#888888]"}>Email</span>
                                    </div>
                                    <div className="flex flex-row items-center gap-1 font-Inter text-sm font-medium">
                                        <Checkbox
                                            checked={isPhoneIncluded}
                                            onChange={(event) => {
                                                setPhoneIncluded(event.currentTarget.checked);
                                            }}
                                            className="w-5 h-5"
                                            size="small"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: "#888888" }} fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon className="text-accents-mainGreen" fontSize="small" />}
                                        />
                                        <span className={isPhoneIncluded ? "text-current" : "text-[#888888]"}>Phone</span>
                                    </div>
                                </div>
                                <div className="h-[29px] w-fit py-2 px-4 bg-[#f6f6f6] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="flex-col justify-center items-start gap-2 flex">
                                        <div className="text-[#090c05] text-xs font-normal font-['Inter']">
                                            Note: You will only be charged for accurate matches.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col gap-4 border-t border-t-[#e7e7e7] w-full mt-5">
                        {enrichOption === 0 && currentWidgetIndex === 1 && (
                            <div className="flex flex-row mt-6 ml-6 justify-start gap-4">
                                <Button
                                    onClick={() => {
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-primary-black text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-primary-header transition-opacity"
                                >
                                    Go back
                                </Button>
                                <Button
                                    onClick={() => {
                                        setCurrentWidgetIndex(2);
                                    }}
                                    className="main-black-button !normal-case"
                                >
                                    Proceed to upload
                                </Button>
                            </div>
                        )}

                        {enrichOption === 0 && currentWidgetIndex === 2 && (
                            <div className="flex flex-row mt-6 ml-6 justify-start gap-4">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        setCurrentWidgetIndex(1);
                                    }}
                                    className="bg-white w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-[#888888] text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-stone-50 transition-opacity"
                                >
                                    Go back
                                </Button>
                                <Button disabled={isLoading} onClick={onFinishClicked} className="main-black-button !normal-case">
                                    Finish
                                </Button>
                            </div>
                        )}
                        {enrichOption === 1 && (
                            <div className="flex flex-row mt-6 ml-6 justify-start gap-4">
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        navigate("/bulkenrich");
                                    }}
                                    className="bg-white w-[80px] border border-[#d1d1d1] font-Inter rounded-lg text-[#888888] text-sm font-medium leading-tight tracking-tight py-1.5 px-3 normal-case hover:bg-stone-50 transition-opacity"
                                >
                                    Go back
                                </Button>
                                <Button disabled={isLoading} onClick={onFinishClicked} className="main-black-button !normal-case">
                                    Finish
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmDialog
                open={visibleConfirmDlg}
                message={alertMessage()}
                handleClose={() => {
                    setVisibleConfirmDlg(false);
                }}
                handleOK={() => {
                    setVisibleConfirmDlg(false);
                    onBulkConfirmed();
                }}
            />
            <LimitModal
                isOpen={alertConfirmDlg}
                onClose={() => {
                    navigate("/bulkenrich");
                    setVisibleAlertDlg(false);
                }}
                type={userInfo?.permission === 1 ? "teamMember" : "default"}
            />
        </>
    );
}
