import useKompassRouter from "@/hooks/useKompassRouter";
import { useEffect, useState } from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useNavigate } from "react-router-dom";

export default ({ firstName, lastName, title, company }) => {
    const [initial, setInitial] = useState("");
    const { kompassNavigate } = useKompassRouter();

    useEffect(() => {
        let name = firstName + " " + lastName;
        const nameParts = name?.split(" ");
        const initials = nameParts.map((part) => part.charAt(0)).join("");
        setInitial(initials.toUpperCase());
    }, []);
    return (
        <div>
            <button
                onClick={() => kompassNavigate("/directory")}
                className="flex items-center text-primary-teal text-sm py-1 px-2 mt-5 rounded-lg hover:opacity-80"
            >
                <ChevronLeftOutlinedIcon color="inherit" />
                Back to Lists
            </button>
            <div className="flex flex-row justify-start gap-5 w-full mt-10">
                <div className="flex items-center border border-[#e7e7e7] justify-center md:h-[110px] md:w-[110px] h-[70px] w-[70px] min-w-[70px] max-lg:rounded-[10px] rounded-[15px]">
                    <h1 className="p-4 font-[700] md:text-[40px] text-[30px]">{initial}</h1>
                </div>
                <div className="flex flex-col ">
                    <h1 className="font-[600] text-[16px] md:text-[20px]">{`${firstName} ${lastName}'s Email & Phone Number`}</h1>
                    <p className="font-[500] text-[12px] md:text-[14px] text-dgray-6 ">{`${title} at ${company.name}.`}</p>
                    <button
                        onClick={() => kompassNavigate("/signin")}
                        className="px-8 hidden md:block py-2 text-sm rounded-lg w-fit bg-primary-black text-white mt-auto hover:opacity-80"
                    >{`View ${firstName}'s contact data for free`}</button>
                </div>
            </div>
            <button
                onClick={() => kompassNavigate("/signin")}
                className="px-8 md:hidden py-2 text-sm rounded-lg w-fit bg-primary-black text-white mt-7 hover:opacity-80"
            >{`View ${firstName}'s contact data for free`}</button>
        </div>
    );
};
