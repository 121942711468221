/**
 * Sorts an array of objects based on a specific property and order.
 *
 * @param {Array} data - The array of objects to sort.
 * @param {string} property - The property to sort by.
 * @param {boolean} isAsc - Determines whether to sort in ascending order.
 * @returns {Array} - The sorted array.
 */
export const sortTableData = (data, property, isAsc) => {
    return [...data].sort((a, b) => {
        if (typeof a[property] === "string" && typeof b[property] === "string") {
            // Sort strings case-insensitively
            return isAsc ? a[property].localeCompare(b[property]) : b[property].localeCompare(a[property]);
        } else if (typeof a[property] === "number" && typeof b[property] === "number") {
            // Sort numbers
            return isAsc ? a[property] - b[property] : b[property] - a[property];
        }
        return 0; // If data type mismatches, don't sort
    });
};

export const triggerDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "downloaded_file"; // Default to "downloaded_file" if no name is provided
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link element
};
