import { isNumber } from "lodash";
import React from "react";

const TrendCard = ({ title, value, percentage, icon }) => {
    return (
        <div className="flex flex-col w-[320px] rounded-[8px] border border-[#E7E7E7] p-4 ">
            <div className="flex flex-row items-center w-full justify-between whitespace-nowrap pb-4 border-b border-[#E7E7E7]">
                <div className="text-center text-[#050505] text-xl font-semibold font-['Inter']">{value}</div>
                {isNumber(percentage) && (
                    <div className="flex gap-1 justify-start items-center p-1 text-[#050505] bg-[#D3E1DB] bg-opacity-10 rounded-[4px]">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_125_3644" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
                                <rect width="10" height="10" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_125_3644)">
                                <path
                                    d="M2.53003 8L1.99878 7.46875L6.46753 3H3.99878V2.25H7.74878V6H6.99878V3.53125L2.53003 8Z"
                                    fill="#050505"
                                />
                            </g>
                        </svg>

                        <div className="text-center text-[#050505] text-xs font-normal font-['Inter']">{percentage.toFixed(2)}%</div>
                    </div>
                )}
            </div>

            <div className="text-[#6d6d6d] text-sm font-medium font-['Inter'] pt-4 leading-[21px]">{title}</div>
        </div>
    );
};

export default TrendCard;
