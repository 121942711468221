import { ReactComponent as Logo } from "../assets/image/icons/fuse landing page/fuse-logo.svg";
import LinkedInIcon from "../assets/image/icons/LinkedIn.svg";
import TwitterIcon from "../assets/image/icons/Twitter.svg";
import { Link } from "react-router-dom";
import { goToTop } from "@/utils/common";
import { HashLink } from "react-router-hash-link";

export default function LandingFooter(props) {
    return (
        <div className=" justify-center bg-white px-12 pb-6 pt-12">
            <div className="mb-6 w-full">
                <div className="flex flex-wrap gap-12 justify-between text-[#787878]">
                    <div className="">
                        <Link to="/" className="flex h-[36px] w-[197.71px] items-center text-center">
                            <Logo className="!w-28" />
                        </Link>
                        <div className="font-InterMedium mt-6 w-80 text-sm text-[#787878]">
                            Gather verified email addresses & phone numbers directly from LinkedIn, reach out, and see when they open your
                            emails.
                        </div>
                        <div className="mt-10 flex flex-row gap-4 text-white">
                            <a
                                href={"https://www.linkedin.com/company/tryfuseai/"}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-[#e7e7e7] hover:opacity-80"
                            >
                                <img src={LinkedInIcon} alt="LinkedInIcon" width={10} height={10} />
                            </a>
                            <a
                                href={"https://x.com/tryfuseai?s=21&t=U8uO3G_ueNJiXtFN1o0qFg"}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-[#e7e7e7] hover:opacity-80"
                            >
                                <img src={TwitterIcon} alt="TwitterIcon" width={10} height={10} />
                            </a>
                            {/* <a
                                href={"https://www.instagram.com/tryfuseai?igsh=MXQxOWV2Nno4dHNoaQ=="}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-[#e7e7e7] hover:opacity-80"
                            >
                                <img src={TwitterIcon} alt="TwitterIcon" width={10} height={10} />
                            </a> */}
                        </div>
                    </div>
                    <div className="">
                        <div className="font-InterBold text-md text-primary-black">Product</div>
                        <HashLink to="/#product">
                            <div className="font-Inter my-5 cursor-pointer text-sm hover:opacity-80">Intent Signals</div>
                        </HashLink>
                        <HashLink to="/#product">
                            <div className="font-Inter my-5 cursor-pointer text-sm hover:opacity-80">Prospect</div>
                        </HashLink>
                        <HashLink to="/#product">
                            <div className="font-Inter my-5 cursor-pointer text-sm  hover:opacity-80">Engage</div>
                        </HashLink>
                    </div>
                    <div className="">
                        <div className="font-InterBold text-md text-primary-black">Resources</div>
                        <Link to="/faq" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">FAQ</div>
                        </Link>
                        <Link to="/pricing" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Pricing</div>
                        </Link>
                        <Link to="/directory" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Directory</div>
                        </Link>
                    </div>

                    <div className="">
                        <div className="font-InterBold text-md text-primary-black">Company</div>
                        <Link to="/terms-and-conditions" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Legal Terms & Conditions</div>
                        </Link>
                        <Link to="/privacy-policy" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Privacy Policy</div>
                        </Link>
                        <Link to="/sending-policy" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Sending Policy</div>
                        </Link>
                        <Link to="/opt-out" onClick={goToTop}>
                            <div className="font-Inter mt-5 cursor-pointer text-sm hover:opacity-80">Opt Out of the Email List</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
