import { useState, useContext, useEffect } from "react";
import { AuthContext } from "@/context/AuthContext";
import MainLayout from "@/components/MainLayout";
import ProfileTabComponent from "@/components/settings/ProfileTabComponent";
import BillingTabComponent from "@/components/settings/BillingTabComponent";
import MainTitleBar from "@/components/MainTitleBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { LayoutContext } from "@/context/LayoutContext";
import AccountsComponent from "@/components/settings/AccountsCompont";
import { useSearchParams } from "react-router-dom";

const tabDetails = [
    {
        label: "Profile",
        currentTabIndex: 0,
    },
    {
        label: "Billing",
        currentTabIndex: 1,
    },
    {
        label: "Accounts",
        currentTabIndex: 2,
    },
];

export default function SettingsScene() {
    const { isTeamMember } = useContext(AuthContext);
    const { open } = useContext(LayoutContext);
    const [currentTabIndex, setCurrentTabIndex] = useState(isTeamMember ? 0 : 2);
    const [activeTab, setActiveTab] = useState(isTeamMember ? 0 : 2);
    const [searchParams] = useSearchParams();

    const handleChange = (_, newValue) => {
        setActiveTab(newValue);

        if (newValue < tabDetails.length) {
            setCurrentTabIndex(tabDetails[newValue].currentTabIndex);
        }
    };

    useEffect(() => {
        console.log("Sidebar open state:", open);
    }, [open]);

    useEffect(() => {
        if (searchParams.toString()) {
            setActiveTab(2);
            setCurrentTabIndex(2);
        }
    }, [searchParams]);

    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                <div className="flex items-end justify-start gap-2">
                    <p>Account and Settings</p>
                </div>
            </MainTitleBar>
            {/* 
            <BaseContainer>
                <div className="grid grid-cols-1 md:grid-cols-4">
                    <div className="col-span-1">
                        <SettingsNavBar
                            isTeamMember={isTeamMember}
                            currentTabIndex={currentTabIndex}
                            setCurrentTabIndex={setCurrentTabIndex}
                        />
                    </div>
                    <div className="col-span-3">
                        {currentTabIndex === 0 && <ProfileTabComponent />}
                        {currentTabIndex === 1 && <AccountTabComponent />}
                        {!isTeamMember && currentTabIndex === 2 && <BillingTabComponent />}
                    </div>
                </div>
            </BaseContainer> */}
            <div className="flex flex-col grow">
                <Box sx={{ width: "100%" }}>
                    {/* {loading && <BackdropLoader clsName="!z-[9999]" active={true}></BackdropLoader>} */}
                    <Box
                        sx={{
                            borderBottom: "1px solid #E7E7E7",
                            width: "100%", // changed from 100vw
                            fontFamily: "Inter",

                            paddingLeft: !open ? "1.8%" : "2%",
                        }}
                    >
                        <Tabs
                            value={activeTab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            disableRipple
                            sx={{
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#050505",
                                    height: "3px",
                                },
                                "& .MuiTabs-flexContainer": {
                                    alignItems: "center",
                                },
                            }}
                        >
                            {tabDetails.map((tab, index) => (
                                <Tab
                                    key={index}
                                    label={tab.label}
                                    disableRipple
                                    sx={{
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        fontFamily: "Inter",
                                        margin: "0 8px 0 0",
                                        padding: "0",
                                        color: activeTab === index ? "#050505" : "#6D6D6D",
                                        minWidth: "auto",
                                        "&.Mui-selected": {
                                            color: "#050505",
                                        },
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Box>
                {currentTabIndex === 0 && <ProfileTabComponent />}
                {!isTeamMember && currentTabIndex === 1 && <BillingTabComponent />}
                {currentTabIndex === 2 && <AccountsComponent searchParams={searchParams} />}
            </div>
        </MainLayout>
    );
}
