import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import useAuth from "@/hooks/useAuth";
import { ChatIcon, ContentCopyIcon, VolumeUpIcon, UploadIcon } from "@/components/ai_intelligence/Icons";
import useKompassSearch from "@/hooks/useKompassSearch";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import {
    continentList,
    countryList,
    departmentList,
    industryList,
    levelList,
    numberOfEmployeesList,
    revenueList,
    subDepartmentList,
} from "@/utils/filter";
import { getCountriesByContinent } from "@/utils/common";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";

const LoadingIndicator = () => (
    <div className="flex space-x-1">
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></span>
        <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-400"></span>
    </div>
);

export default function AiIntelligenceModal({
    toggleModal,
    flexSearch = false,
    setIsSearchClicked = () => {},
    handleSearchByFilter = () => {},
    setContactFilter = () => {},

    // NEW PROP: Callback to apply extracted filters to SingleListScene
    listFlex = false,
    applyExtractFiltersPDL = () => {},
}) {
    const [view, setView] = useState(1);
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { getAuthToken } = useAuth();
    const [threadId, setThreadId] = useState(null);
    const [isModalExpanded, setIsModalExpanded] = useState(false);
    const [isSvgShrunk, setIsSvgShrunk] = useState(false);
    const [flexSearchInput, setFlexSearchInput] = useState(null);

    const { getFiltersByFlexSearch } = useKompassSearch();
    const {
        setIndustryName,
        setRevenue,
        setNumberOfEmployees,
        setLevel,
        setTitle,
        setCountry,
        setStatesList,
        setPersonalLocation,
        setCompanyLocation,
        setDept,
        setSubDepartment,
        setState,
        setHqContinent,
        setHqCountry,
        setContinent,
        planInfo,
        setIsFromSavedFiltersPage,
    } = useKompassFilters();

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));
    const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find((obj) => obj.label.toLowerCase() === country?.toLowerCase());
        return countryObject?.value;
    };

    const handleCountryChange = (selectedCountries, selectedRegion, validFilters) => {
        setStatesList([]);
        const countryLabelValuePairs = countryList
            .filter((c) => selectedCountries.includes(c.value))
            .map((c) => ({ ...c, status: "include" }));
        setCountry(countryLabelValuePairs);
        validFilters.location_country = countryLabelValuePairs;

        if (selectedCountries.length > 0) {
            const allStates = selectedCountries.flatMap((country) => {
                const countryIsoCode = getIsoCode(country);
                return State.getStatesOfCountry(countryIsoCode).map((st) => ({
                    label: st.name,
                    value: st.name.toLowerCase(),
                }));
            });
            const selectedStates = allStates.filter((st) => selectedRegion.includes(st.value)).map((st) => ({ ...st, status: "include" }));
            setState(selectedStates);
            validFilters.location_region = selectedStates;
            setStatesList(allStates);
        } else {
            setStatesList([]);
        }
    };

    const messageEndRef = useRef(null);

    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleSpeak = (msgText) => {
        console.log("Speak clicked for:", msgText);
    };

    const handleCopy = (msgText) => {
        navigator.clipboard.writeText(msgText).catch(() => console.error("Failed to copy to clipboard."));
    };

    const handleRefresh = (msgId) => {
        console.log("Refresh clicked for bot message ID:", msgId);
    };

    const sendMessageToAPI = async (query) => {
        const userId = "1234567";
        const authToken = await getAuthToken();
        let API_URL = "";
        let body = {
            query: query,
            userId: userId,
        };

        if (!threadId) {
            API_URL = "https://ma0u0t3rk2.execute-api.us-east-1.amazonaws.com/prod/agents/chat/new";
        } else {
            API_URL = `https://ma0u0t3rk2.execute-api.us-east-1.amazonaws.com/prod/agents/chat/${threadId}`;
        }

        try {
            const response = await fetch(API_URL, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }

            const data = await response.json();
            if (!threadId && data.threadId) {
                setThreadId(data.threadId);
            }
            return data.message;
        } catch (error) {
            console.error("Error sending message to API:", error);
            return "Sorry, there was an error processing your request.";
        }
    };

    const handleSendMessage = async () => {
        const cleanInput = userInput.trim();
        if (!cleanInput) return;

        const newUserMessage = {
            id: Date.now(),
            sender: "user",
            text: cleanInput,
        };
        setMessages((prev) => [...prev, newUserMessage]);
        setUserInput("");

        if (view === 1) {
            setView(2);
        }

        const placeholderId = Date.now() + 1;
        const placeholderMessage = {
            id: placeholderId,
            sender: "bot",
            text: "",
            isLoading: true,
        };
        setMessages((prev) => [...prev, placeholderMessage]);

        setIsLoading(true);

        const botResponse = await sendMessageToAPI(cleanInput);

        setMessages((prev) => prev.map((msg) => (msg.id === placeholderId ? { ...msg, text: botResponse, isLoading: false } : msg)));

        setIsLoading(false);
    };

    // Function to animate word-by-word display
    const animateResponse = (text) => {
        if (typeof text !== "string") return null;

        // This will hold the final chunks of text, with correct bolding and link rendering
        const segments = [];
        let currentSegment = "";
        let isInBold = false;

        // Loop through the text character by character
        for (let i = 0; i < text.length; i++) {
            const char = text[i];

            // If we encounter ** (bold marker), toggle the bold state
            if (char === "*" && text[i + 1] === "*") {
                // Flush any text accumulated so far
                if (currentSegment) {
                    segments.push({ text: currentSegment, isBold: isInBold });
                    currentSegment = "";
                }
                isInBold = !isInBold; // Toggle the bold state
                i++; // Skip the next '*' as we've already processed it
            } else if (char === "[") {
                // Flush any text accumulated before processing the link
                if (currentSegment) {
                    segments.push({ text: currentSegment, isBold: isInBold });
                    currentSegment = "";
                }

                // Check for a hyperlink in the format [text](link)
                const linkStart = i + 1;
                const closingBracketIndex = text.indexOf("]", linkStart);
                const openingParenthesisIndex = text.indexOf("(", closingBracketIndex);
                const closingParenthesisIndex = text.indexOf(")", openingParenthesisIndex);

                if (
                    closingBracketIndex > linkStart &&
                    openingParenthesisIndex > closingBracketIndex &&
                    closingParenthesisIndex > openingParenthesisIndex
                ) {
                    const linkText = text.slice(linkStart, closingBracketIndex);
                    const linkUrl = text.slice(openingParenthesisIndex + 1, closingParenthesisIndex);

                    // Add the link segment
                    segments.push({ text: linkText, isLink: linkUrl });
                    // Move the index past the entire link markup
                    i = closingParenthesisIndex;
                } else {
                    // If the format isn't correct, treat the "[" as normal text
                    currentSegment += char;
                }
            } else {
                currentSegment += char;
            }
        }

        // Push the final segment if it exists
        if (currentSegment) {
            segments.push({ text: currentSegment, isBold: isInBold });
        }

        // Animate each segment
        return segments.map((segment, index) => (
            <motion.span key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.1, duration: 0.3 }}>
                {segment.isBold ? (
                    <strong>{segment.text}</strong>
                ) : segment.isLink ? (
                    <a href={segment.isLink} target="_blank" rel="noopener noreferrer" className="text-blue-600">
                        {segment.text}
                    </a>
                ) : (
                    segment.text
                )}
            </motion.span>
        ));
    };

    const handleModalToggle = () => {
        setIsModalExpanded(!isModalExpanded);
        setIsSvgShrunk(!isSvgShrunk);
    };

    // This is your existing flexSearch method that calls "getFiltersByFlexSearch"
    // and then sets them globally (the original approach):
    const handleFlexSearch = async () => {
        if (!flexSearchInput) {
            toast.error("Please enter a search term");
            return;
        }
        setIsLoading(true);
        setIsSearchClicked(true);
        const res = await getFiltersByFlexSearch(flexSearchInput);
        if (res.status && res.data.response) {
            const response = res.data.response;
            const validFilters = {};

            if (response.industry?.length) {
                validFilters.industry = industryList
                    .filter((i) => response.industry.includes(i.value))
                    .map((i) => ({ ...i, status: "include" }));
                setIndustryName(validFilters.industry);
            }
            if (response.job_company_inferred_revenue?.length) {
                validFilters.job_company_inferred_revenue = revenueList
                    .filter((r) => response.job_company_inferred_revenue.includes(r.value))
                    .map((r) => ({ ...r, status: "include" }));
                setRevenue(validFilters.job_company_inferred_revenue);
            }
            if (response.job_company_location_continent?.length) {
                validFilters.job_company_location_continent = continentList
                    .filter((c) => response.job_company_location_continent.includes(c.value))
                    .map((c) => ({ ...c, status: "include" }));
                setHqContinent(validFilters.job_company_location_continent);
            }
            if (response.job_company_location_country?.length) {
                validFilters.job_company_location_country = countryList
                    .filter((c) => response.job_company_location_country.includes(c.value))
                    .map((c) => ({ ...c, status: "include" }));
                setHqCountry(validFilters.job_company_location_country);
            }
            if (response.job_company_size?.length) {
                validFilters.job_company_size = numberOfEmployeesList
                    .filter((n) => response.job_company_size.includes(n.value))
                    .map((n) => ({ ...n, status: "include" }));
                setNumberOfEmployees(validFilters.job_company_size);
            }
            if (response.job_title_levels?.length) {
                validFilters.job_title_levels = levelList
                    .filter((l) => response.job_title_levels.includes(l.value))
                    .map((l) => ({ ...l, status: "include" }));
                setLevel(validFilters.job_title_levels);
            }
            if (response.job_title_role?.length) {
                validFilters.job_title_role = departmentList
                    .filter((d) => response.job_title_role.includes(d.value))
                    .map((d) => ({ ...d, status: "include" }));
                setDept(validFilters.job_title_role);
            }
            if (response.job_title_sub_role?.length) {
                validFilters.job_title_sub_role = subDepartmentList
                    .filter((s) => response.job_title_sub_role.includes(s.value))
                    .map((s) => ({ ...s, status: "include" }));
                setSubDepartment(validFilters.job_title_sub_role);
            }
            if (response.location_continent?.length) {
                validFilters.location_continent = continentList.filter((c) => response.location_continent.includes(c.value))[0];
                setContinent(validFilters.location_continent);
            }
            if (response.location_country?.length) {
                handleCountryChange(response.location_country, response.location_region, validFilters);
            }
            if (response.job_title?.length) {
                validFilters.job_title = response.job_title;
                setTitle(response.job_title);
            }

            if (Object.keys(validFilters).length === 0) {
                setIsLoading(false);
                return;
            }
            if (validFilters.location_continent && typeof validFilters.location_continent?.value === "string") {
                validFilters.location_country = await getCountriesByContinent(validFilters.location_continent.value);
                delete validFilters.location_continent;
            }
            setContactFilter({ params: { ...validFilters, subscriptionName: planInfo.name } });
            setIsFromSavedFiltersPage(true);
            await handleSearchByFilter({ params: { ...validFilters, subscriptionName: planInfo.name } });
        }
        setIsLoading(false);
        toggleModal();
    };

    // =================================
    // NEW METHOD: handleExtractFiltersPDL
    // =================================
    async function handleExtractFiltersPDL(description) {
        if (!description) {
            toast.error("Please enter a description before extracting filters.");
            return;
        }
        setIsLoading(true);
        try {
            const authToken = await getAuthToken();
            const response = await fetch("https://wc486q4xk0.execute-api.us-east-1.amazonaws.com/dev/campaigns/build/extractFiltersPDL", {
                method: "POST",
                headers: {
                    accept: "*/*",
                    "content-type": "text/plain;charset=UTF-8",
                    authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({ description }),
            });
            const data = await response.json();

            if (data.status && data.data?.response) {
                // pass them to SingleListScene so it can set filters and re-filter
                applyExtractFiltersPDL(data.data.response);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
        // We can optionally close the modal:
        toggleModal();
    }

    return (
        <div
            style={{
                height: isModalExpanded ? "calc(100vh - 200px)" : "auto",
                width: isModalExpanded ? "calc(100vw - 300px)" : "560px",
            }}
            className="w-[560px] bg-white rounded-2xl shadow-[0px_4px_34px_0px_rgba(0,0,0,0.16)] flex flex-col"
        >
            {/* Top Bar */}
            <div className="flex justify-between items-center px-6 py-4 bg-white rounded-tl-2xl rounded-tr-2xl border-b border-[#e7e7e7]">
                <div className="text-[#050505] text-base font-semibold">How can I help you today?</div>
                <div className="flex gap-4">
                    <div onClick={handleModalToggle}>
                        {!isSvgShrunk ? (
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <mask id="mask0_178_11146" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                    <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_178_11146)">
                                    <path d="M3 17V11H4.5V14.4375L14.4375 4.5H11V3H17V9H15.5V5.5625L5.5625 15.5H9V17H3Z" fill="#1C1B1F" />
                                </g>
                            </svg>
                        ) : (
                            <svg width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    fill="#555"
                                    d="M8.67198031,10.5613195 C9.05857964,10.5613195 9.37198031,10.8747202 9.37198031,11.2613195 L9.37198031,17.2613195 C9.37198031,17.6479189 9.05857964,17.9613195 8.67198031,17.9613195 C8.28538099,17.9613195 7.97198031,17.6479189 7.97198031,17.2613195 L7.971,12.9073195 L1.19914449,19.7956288 C0.928099848,20.0712986 0.484900222,20.0750481 0.20923042,19.8040034 C-0.0664393831,19.5329588 -0.0701888169,19.0897591 0.200855825,18.8140893 L6.938,11.9603195 L2.67198031,11.9613195 C2.28538099,11.9613195 1.97198031,11.6479189 1.97198031,11.2613195 C1.97198031,10.8747202 2.28538099,10.5613195 2.67198031,10.5613195 L8.67198031,10.5613195 Z M19.792666,0.200855825 C20.0683358,0.471900467 20.0720852,0.915100092 19.8010406,1.19076989 L13.0638964,8.04453971 L17.3299161,8.04353971 C17.7165154,8.04353971 18.0299161,8.35694038 18.0299161,8.74353971 C18.0299161,9.13013903 17.7165154,9.44353971 17.3299161,9.44353971 L11.3299161,9.44353971 C10.9433167,9.44353971 10.6299161,9.13013903 10.6299161,8.74353971 L10.6299161,2.74353971 C10.6299161,2.35694038 10.9433167,2.04353971 11.3299161,2.04353971 C11.7165154,2.04353971 12.0299161,2.35694038 12.0299161,2.74353971 L12.0308964,7.09753971 L18.8027519,0.20923042 C19.0737965,-0.0664393831 19.5169962,-0.0701888169 19.792666,0.200855825 Z"
                                />
                            </svg>
                        )}
                    </div>
                    <div onClick={toggleModal}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <mask id="mask0_178_11150" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                <rect width="20" height="20" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_178_11150)">
                                <path
                                    d="M6.0625 15L5 13.9375L8.9375 10L5 6.0625L6.0625 5L10 8.9375L13.9375 5L15 6.0625L11.0625 10L15 13.9375L13.9375 15L10 11.0625L6.0625 15Z"
                                    fill="#1C1B1F"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

            {/* Chat History */}
            <div className="flex-1 p-6 bg-white overflow-y-auto max-h-[calc(100vh-200px)]">
                {messages.length === 0 ? (
                    <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">
                        Need help with something? Ask away!
                    </div>
                ) : (
                    messages.map((msg) => {
                        const isBot = msg.sender === "bot";
                        return (
                            <div key={msg.id} className="flex flex-col space-y-2">
                                {isBot ? (
                                    <div className="flex items-start space-x-2">
                                        <ChatIcon />
                                        <div className="max-w-[70%] p-3 rounded-[8px] bg-white">
                                            {msg.isLoading ? (
                                                <LoadingIndicator />
                                            ) : (
                                                <div className="whitespace-pre-line leading-relaxed">{animateResponse(msg.text)}</div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="max-w-[70%] p-3 rounded-[8px] bg-[#F6F6F6] self-end">{msg.text}</div>
                                )}
                            </div>
                        );
                    })
                )}
                <div ref={messageEndRef} />
            </div>

            {/* Input Section */}
            <div className="w-full flex-shrink-0 p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Ask anything"
                        className="w-full border h-[48px] border-[#E7E7E7] rounded-[8px] pl-6 pr-16 py-2 outline-none text-[1rem]"
                        value={flexSearch ? flexSearchInput || "" : userInput}
                        onChange={(e) => (flexSearch ? setFlexSearchInput(e.target.value) : setUserInput(e.target.value))}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                flexSearch
                                    ? listFlex
                                        ? handleExtractFiltersPDL(flexSearchInput || userInput)
                                        : handleFlexSearch()
                                    : handleSendMessage();
                        }}
                        disabled={isLoading}
                    />

                    {/* Send button */}
                    <IconButton
                        onClick={flexSearch ? (listFlex ? handleExtractFiltersPDL : handleFlexSearch) : handleSendMessage}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: 36,
                            height: 36,
                            padding: "0px !important",
                            borderRadius: "9999px",
                            "&:hover": { backgroundColor: "#333" },
                            color: "white",
                        }}
                        disabled={isLoading}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_178_11180)">
                                <circle cx="12" cy="12" r="12" fill="#C5C5C5" />
                                <mask id="mask0_178_11180" maskUnits="userSpaceOnUse" x="5" y="4" width="15" height="16">
                                    <rect x="5.00024" y="4.80005" width="14.4" height="14.4" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_178_11180)">
                                    <path
                                        d="M6.80005 16.8004V7.20044L18.2 12.0004L6.80005 16.8004ZM8.00005 15.0004L15.11 12.0004L8.00005 9.00044V11.1004L11.6 12.0004L8.00005 12.9004V15.0004Z"
                                        fill="white"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_178_11180">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </IconButton>
                </div>

                {isLoading && <div className="mt-2 text-gray-500">Processing your request...</div>}

                {/* 
                   EXAMPLE: 
                   A new button to show how to call the "extractFiltersPDL" 
                   method with whatever text the user has typed 
                */}
                {/* <div className="mt-4 flex gap-2">
                    <button
                        onClick={() => handleExtractFiltersPDL(flexSearchInput || userInput)}
                        className="bg-blue-500 text-white px-3 py-2 rounded-md"
                    >
                        Extract Filters (In-List)
                    </button>
                </div> */}
            </div>
        </div>
    );
}
