import { KeyboardIcon } from "./ai_intelligence/Icons";
import BaseContainer from "./BaseContainer";
import { useModal } from "@/context/ModalContext"; // Import the useModal hook

const MainTitleBar = ({ children }) => {
    const { toggleModal } = useModal(); // Use modal context

    return (
        <div className="z-[52] w-full bg-white flex items-center border-b border-[#e7e7e7] filter font-Inter text-2xl font-medium h-fit py-4 leading-[1.2]">
            <div className="w-full flex items-center justify-between">
                {/* Title */}
                <BaseContainer>{children}</BaseContainer>

                {/* Simple Avatar/Badge */}
                <div className="w-[49px] mr-6 h-8 bg-[#F4F3F0] rounded flex items-center justify-center gap-1.5">
                    <KeyboardIcon onClick={toggleModal} /> {/* Toggle modal on click */}
                    <div className="text-[#050505] text-[1.15rem] font-semibold">I</div>
                </div>
            </div>
        </div>
    );
};

export default MainTitleBar;
