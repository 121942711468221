import React, { useState } from "react";
import { TextField, Chip, Box } from "@mui/material";

const EmailInput = ({ onEmailsChange }) => {
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === ",") {
            event.preventDefault();
            addEmail(inputValue.trim());
        }
    };

    const addEmail = (email) => {
        if (email && isValidEmail(email) && !emails.includes(email)) {
            const updatedEmails = [...emails, email];
            setEmails(updatedEmails);
            setInputValue("");
            if (onEmailsChange) {
                onEmailsChange(updatedEmails);
            }
        }
    };

    const removeEmail = (emailToRemove) => {
        const updatedEmails = emails.filter((email) => email !== emailToRemove);
        setEmails(updatedEmails);
        if (onEmailsChange) {
            onEmailsChange(updatedEmails);
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <Box sx={{ height: "100%", position: "relative", display: "flex", flexDirection: "row" }}>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 1,
                    border: "none",
                }}
            >
                {emails.map((email, index) => (
                    <Chip
                        key={index}
                        label={email}
                        onDelete={() => removeEmail(email)}
                        sx={{
                            backgroundColor: "#fff",
                            color: "#050505",
                            fontWeight: 500,
                            fontFamily: "Inter",
                            fontSize: 14,
                        }}
                    />
                ))}
                <div className="inline-block relative grow">
                    <TextField
                        variant="standard"
                        placeholder="Add email"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        sx={{
                            flex: 1,
                            height: "100%",
                            flexDirection: "row",
                            width: "100%",
                            minWidth: "140px",
                            paddingRight: "12px",
                            paddingLeft: "12px",
                            "& .MuiInputBase-input": {
                                fontFamily: "Inter",
                                width: "100%",
                                fontSize: 14,
                                fontWeight: 500,
                                color: "#050505",
                            },
                        }}
                    />
                    {inputValue && !isValidEmail(inputValue) && (
                        <Box sx={{ position: "absolute", left: "12px", bottom: "-12px", color: "red", fontSize: "12px" }}>
                            Invalid email address
                        </Box>
                    )}
                </div>
            </Box>
        </Box>
    );
};

export default EmailInput;
