import * as React from "react";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { SAVED_LABELS } from "@/utils/common";
import { ReactComponent as MoreIcon } from "@/assets/image/search/more-dots.svg";
import { ReactComponent as CopyIcon } from "@/assets/image/search/copy.svg";
import { ReactComponent as DeleteIcon } from "@/assets/image/search/delete.svg";
import { ReactComponent as DropdownIcon } from "@/assets/image/search/arrow-dropdown.svg";

export const SavedSearchesItem = ({ savedItem, entriesObjects, handleDeleteItem, handleDuplicateItem, handleSetFilters, isLoading }) => {
    const [anchorMenuElement, setAnchorMenuElement] = useState(null);
    const [anchorDropdownElement, setAnchorDropdownElement] = useState(null);

    const handleMenuClose = () => {
        setAnchorMenuElement(null);
    };

    const handleDropDownClose = () => {
        setAnchorDropdownElement(null);
    };

    const menuItemStyles = {
        px: "16px",
        py: "8px",
        pt: "16px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
    };
    const FilterDisplay = ({ value }) => {
        // Check if the value is an array
        if (Array.isArray(value)) {
            return value.map((item, index) => (
                <p
                    key={index}
                    className={`font-Inter text-[13px] text-stone-950 font-normal leading-[1.2] ${item.status === "exclude" ? "bg-stone-250" : "bg-[#D3E1DB]"} p-2 rounded`}
                >
                    {typeof item === "object" && item !== null ? item.label : item}
                </p>
            ));
        }

        // Check if the value is an object with min and max properties
        if (typeof value === "object" && (value.min || value.max)) {
            return (
                <p className="font-Inter text-[13px] text-stone-950 font-normal leading-[1.2] bg-[#D3E1DB] p-2 rounded">
                    {value.min !== undefined && value.max !== undefined
                        ? `${value.min} - ${value.max}`
                        : value.min !== undefined
                          ? `${value.min}`
                          : `${value.max}`}
                </p>
            );
        }

        // Otherwise, assume it's a string
        return <p className="font-Inter text-[13px] text-stone-950 font-normal leading-[1.2] bg-[#D3E1DB] p-2 rounded">{value}</p>;
    };

    return (
        <>
            <div className="flex items-center justify-between pt-[10px]">
                <div className="flex items-center gap-1">
                    <button
                        className="font-Inter text-[16px] 2xl:text-[18px] font-medium leading-[1.2] text-stone-950 hover:underline focus:underline"
                        onClick={() => handleSetFilters(savedItem?.filters)}
                    >
                        {savedItem.title}
                    </button>
                </div>
                <button
                    onClick={(event) => {
                        setAnchorMenuElement(event.currentTarget);
                    }}
                >
                    <MoreIcon className="w-[16px] h-[16px]" />
                </button>
                <Menu
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorMenuElement}
                    open={Boolean(anchorMenuElement)}
                    onClose={handleMenuClose}
                    sx={{ borderRadius: "8px" }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        key="Duplicate"
                        disabled={isLoading}
                        onClick={async () => {
                            await handleDuplicateItem({ ...savedItem });
                            handleMenuClose();
                        }}
                        sx={menuItemStyles}
                    >
                        <CopyIcon />
                        <Typography variant="inherit">Duplicate</Typography>
                    </MenuItem>
                    <MenuItem
                        key="Delete"
                        disabled={isLoading}
                        onClick={async () => {
                            await handleDeleteItem(savedItem._id);
                            handleMenuClose();
                        }}
                        sx={{ ...menuItemStyles, color: "#EC5151" }}
                    >
                        <DeleteIcon />
                        <Typography variant="inherit">Delete</Typography>
                    </MenuItem>
                </Menu>
            </div>
            <div className="py-[10px]">
                <button
                    onClick={(e) => setAnchorDropdownElement(e.currentTarget)}
                    className="rounded bg-stone-250 px-[8px] py-[5px] font-Inter text-[14px] font-normal leading-[1.2] flex items-center gap-[4px]"
                >
                    {entriesObjects.length} filters added
                    <DropdownIcon />
                </button>
                <Menu
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorDropdownElement}
                    open={Boolean(anchorDropdownElement)}
                    onClose={handleDropDownClose}
                    sx={{ borderRadius: "8px" }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {entriesObjects.map((item) => {
                        const [key, value] = item;

                        return (
                            <MenuItem
                                key={key}
                                disableRipple={true}
                                sx={{
                                    ...menuItemStyles,
                                    cursor: "default",
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    },
                                    "&:focus": {
                                        backgroundColor: "transparent",
                                        outline: "none",
                                    },
                                }}
                            >
                                <div className="p-2 border-stone-250 border-[1px] rounded-[4px] max-w-[350px]">
                                    <div className="flex flex-wrap items-center gap-3">
                                        <p className="font-Inter text-[13px] text-stone-950 font-normal leading-[1.2]">
                                            {SAVED_LABELS[key]}:
                                        </p>
                                        <FilterDisplay value={value} />
                                    </div>
                                </div>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        </>
    );
};
