import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function OpportunityTableRow(props) {
    const { row, isItemSelected, labelId, setSelected } = props;

    const navigate = useNavigate();

    return (
        <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={props.key}
            selected={false}
            sx={{
                background: isItemSelected ? "#F6F6F6" : "white",
                "& .MuiTableCell-root:not(:last-child)": { borderRight: "1px solid #E0E0E0" },
                "& .MuiTableCell-root": {
                    border: "none",
                    borderTop: props.index === 0 ? "none" : 1,
                    borderColor: "#E0E0E0",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
            }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                    sx={{
                        "&.Mui-checked": {
                            color: "#2D7A89",
                        },
                    }}
                    onChange={() => {
                        setSelected((prev) => (isItemSelected ? prev.filter((id) => id !== row._id) : [...prev, row._id]));
                    }}
                />
            </TableCell>
            {["Prospecting", "Sales Qualified", "Proposal", "Negotiation", "Onboarding", "Closed Won", "Closed Lost"].map((col) => (
                <TableCell
                    sx={{
                        "&:hover": {
                            background: "#f4f3f0",
                        },
                    }}
                >
                    <div
                        className="flex items-center gap-2 cursor-pointer w-fit"
                        onClick={() => navigate(`/crm/opportunities/${row[col]?._id}`)}
                    >
                        {row[col]?.company && row[col]?.company.website && (
                            <img
                                className="h-5 w-5 rounded-md"
                                src={`https://logo.clearbit.com/${row[col]?.company.website}`}
                                alt="company"
                                onError={(e) => (e.target.style.display = "none")}
                            />
                        )}
                        <p className="hover:underline"> {row[col]?.company?.name || ""}</p>
                    </div>
                </TableCell>
            ))}
        </TableRow>
    );
}
