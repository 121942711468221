import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import UploadFile from "./UploadFile";
import useNotes from "@/hooks/crm/useNotes";
import { debounce } from "lodash";

export default function NotesDetailsModal({ open, setOpen, rowData, setRefreshUI }) {
    const [noteTitle, setNoteTitle] = useState(rowData?.title || "");
    const [noteDescription, setNoteDescription] = useState(rowData?.description || "");

    const { updateTimelineNote } = useNotes();

    useEffect(() => {
        if (rowData) {
            setNoteTitle(rowData.title || "");
            setNoteDescription(rowData.content || "");
        }
    }, [rowData]);

    // Debounced save function
    const saveNotesDetails = React.useCallback(
        debounce(async (field, value) => {
            try {
                await updateTimelineNote(rowData._id, { [field]: value });
                setRefreshUI((prev) => !prev);
            } catch (error) {
                console.error(`Failed to update ${field}`, error);
            }
        }, 500),
        [rowData?._id]
    );

    const handleInputChange = (field, value) => {
        if (field === "title") setNoteTitle(value);
        if (field === "description") setNoteDescription(value);
        saveNotesDetails(field, value);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <div className="min-w-[600px]">
                    <div className="flex items-center justify-between p-4 border-b border-[#E7E7E7]">
                        <h2 className="font-semibold">Note Details</h2>
                        <div onClick={toggleDrawer(false)}>
                            <CloseIcon className="text-dgray-500 cursor-pointer hover:text-dgray-600" />
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="flex flex-col gap-4">
                            <h2 className="font-semibold">Note Title</h2>
                            <input
                                type="text"
                                value={noteTitle}
                                onChange={(e) => handleInputChange("title", e.target.value)}
                                className="py-2 px-4 border rounded-lg w-full"
                            />

                            <h2 className="font-semibold">Description</h2>
                            <textarea
                                rows="4"
                                value={noteDescription}
                                onChange={(e) => handleInputChange("description", e.target.value)}
                                className="py-2 px-4 border rounded-lg w-full"
                            />
                        </div>
                        <h1 className="font-semibold mt-5">Details</h1>
                        <div className="border p-3 border-stone-250 rounded-lg mt-1 text-sm">
                            <div className=" grid grid-cols-2 gap-x-5">
                                <div className="text-dgray-6 text-sm space-y-4 ">
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <ModeEditOutlineOutlinedIcon className="!w-4 !h-4" /> Created By
                                    </p>
                                    <p className="flex items-center gap-1">
                                        {" "}
                                        <CalendarTodayOutlinedIcon className="!w-4 !h-4" /> Last Update
                                    </p>
                                </div>
                                <div className="text-sm space-y-4 font-medium">
                                    <p>John Doe</p>
                                    <p>July 21, 2024</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <UploadFile note={rowData} setRefreshUI={setRefreshUI} />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
