import React, { createContext, useContext, useState } from "react";

// Create a Modal Context
const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen((prev) => !prev);
    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    return <ModalContext.Provider value={{ isOpen, toggleModal, closeModal, openModal }}>{children}</ModalContext.Provider>;
};
