import React, { useState } from "react";
import { Button } from "@material-tailwind/react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import useAIEngagement from "@/hooks/useAIEngagement";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        width: "458px",
    },
}));

export default function AddNewAccountDialog({ open, handleNo }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(false);

    const { connectWithThirdPartyService, getUnipileConnectLink } = useAIEngagement();

    const getConnectedWithService = async (type) => {
        try {
            setLoading(true);

            let authLink = null;

            // Fetch auth link based on the type
            if (type === "linkedin") {
                const { data } = await getUnipileConnectLink();
                authLink = data;
            } else {
                const response = await connectWithThirdPartyService({ authType: type });
                authLink = response?.data?.authUrl;
            }

            // Open the auth link in a new tab if it exists
            if (authLink) {
                const anchor = document.createElement("a");
                anchor.href = authLink;
                anchor.target = "_blank";
                anchor.click();
                anchor.remove();
            } else {
                toast.error("Failed to retrieve the auth link. Please try again.");
                console.error("Auth link retrieval failed.");
            }
        } catch (error) {
            toast.error("An error occurred while connecting to the service.");
            console.error("Error retrieving the auth link: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={handleNo}>
            <div className="flex gap-[16px] justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className=" text-[#050505] text-[18px] font-semibold font-['Inter']">Please choose which account you want to add</div>
                <button onClick={handleNo} className="flex justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <div className="flex justify-center px-6 py-4 gap-4">
                    <Button
                        disabled={loading}
                        className="px-[16px] py-[8px] border border-[#e7e7e7] text-center capitalize rounded-[8px] main-text-black-500 bg-white 2xl:px-2 hover:bg-stone-50 transition-colors"
                        onClick={() => getConnectedWithService("gmail")}
                    >
                        Gmail
                    </Button>
                    <Button
                        disabled={loading}
                        className="px-[16px] py-[8px] border border-[#e7e7e7] text-center capitalize rounded-[8px] main-text-black-500 bg-white 2xl:px-2 hover:bg-stone-50 transition-colors"
                        onClick={() => getConnectedWithService("outlook")}
                    >
                        Outlook
                    </Button>
                    <Button
                        disabled={loading}
                        className="px-[16px] py-[8px] border border-[#e7e7e7] text-center capitalize rounded-[8px] main-text-black-500 bg-white 2xl:px-2 hover:bg-stone-50 transition-colors"
                        onClick={() => getConnectedWithService("linkedin")}
                    >
                        LinkedIn
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
