import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./selectDropdown.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const menuItemStyles = {
    fontFamily: "Inter",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
    "&.Mui-selected": {
        backgroundColor: "#f4f3f0 !important",
    },
    "&.Mui-selected:hover": {
        backgroundColor: "#f4f3f0 !important",
    },
    "&:hover": {
        backgroundColor: "#f4f3f0",
    },
};

export default function SelectDropdown({ options, selectedOption, onChange, defaultValue = null, renderValue, ...props }) {
    const handleOptionClick = (event) => {
        onChange(event.target.value);
    };
    const [open, setOpen] = useState(false);

    return (
        <Select
            className=""
            value={selectedOption}
            onChange={handleOptionClick}
            displayEmpty
            renderValue={renderValue}
            defaultValue={defaultValue}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            IconComponent={(props) => (
                <ExpandMoreIcon
                    {...props}
                    className={`h-5 w-5 transition-transform duration-100 ml-0 mr-2 ${open ? "rotate-180" : "rotate-0"}`}
                />
            )}
            {...props}
            sx={{
                "& .MuiSelect-select": {
                    padding: "8px 0px 8px 16px",
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingRight: "8px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E7E7E7",
                    borderRadius: "8px !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E7E7E7",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E7E7E7",
                },
            }}
        >
            {options.map((option) => (
                <MenuItem value={option?.value} sx={menuItemStyles} key={option?.value}>
                    <div className="text-center text-[#050505] text-sm font-['Inter'] capitalize">{option?.label}</div>
                </MenuItem>
            ))}
        </Select>
    );
}
