import { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import HtmlEditor from "@/components/common/HtmlEditor";
import useLinkedinAutomation from "@/hooks/useLinkedinAutomation";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "@/components/icons/InboxIcon";

export const NewChat = ({ setComposeNewChatOpen, setActiveChatId, allLinkedinAccounts, refetchChatsList }) => {
    const { startChat, getAccountRelations } = useLinkedinAutomation();
    const [selectedProfileId, setSelectedProfileId] = useState("");
    const [senderAccountId, setSenderAccountId] = useState("");
    const [messageText, setMessageText] = useState("");
    const [allRelations, setAllRelations] = useState([]);
    const [isSendButtonDisabled, setSendButtonDisabled] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const getAccountRelationsRef = useRef();
    getAccountRelationsRef.current = getAccountRelations;

    useEffect(() => {
        const fetchAllRelations = async () => {
            try {
                if (!senderAccountId) return;
                const res = await getAccountRelationsRef.current(senderAccountId);
                const relationsList = res?.data;

                if (res.status && relationsList?.length > 0) {
                    setAllRelations(relationsList);
                }

                if (!res.status) {
                    toast.error("Error while fetching account relations, please reload the page and try again");
                }
            } catch (error) {
                console.log("Error: ", error);
            }
        };

        fetchAllRelations();
    }, [senderAccountId]);

    const accountOptions = useMemo(
        () =>
            allLinkedinAccounts?.map((account) => ({
                label: account.email,
                value: account.accountId,
            })) || [],
        [allLinkedinAccounts]
    );

    useEffect(() => {
        if (accountOptions.length > 0) {
            setSenderAccountId(accountOptions[0].value);
        }
    }, [accountOptions]);

    const handleStartChat = async () => {
        if (!messageText?.trim() || !senderAccountId || !selectedProfileId) return;

        const parser = new DOMParser();
        const doc = parser.parseFromString(messageText, "text/html");
        const plainText = doc.body.textContent || "";

        if (!plainText?.trim()) return;

        setSendButtonDisabled(true);

        try {
            const res = await startChat(senderAccountId, plainText, [selectedProfileId]);

            if (!res.status) {
                toast.error("Something went wrong, try again");
            } else {
                toast.success("Message successfully sent!");
                setComposeNewChatOpen(false);
                setActiveChatId(null);
                await refetchChatsList();
            }
        } catch (error) {
            console.log("Error: ", error);
            toast.error(error?.message || "Something went wrong, try again");
        } finally {
            setSendButtonDisabled(false);
        }
    };

    const handleGoBack = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        } else {
            setComposeNewChatOpen(false);
        }
    };

    return (
        <div className="w-full font-Inter col-span-3">
            <div className="flex p-[24px] h-[76px] items-center gap-[4px]" onClick={handleGoBack}>
                <BackIcon />
                <span className="cursor-pointer text-[#2D7A89] text-[14px] font-medium font-Inter">Back</span>
            </div>
            <div className="w-full p-[24px]">
                <div className="w-full h-full rounded-[8px] border border-[#E7E7E7]">
                    <div className="px-[16px] py-[12px] w-full bg-[#f6f6f6]">
                        <p className="text-accents-mainBlack text-[16px] font-semibold font-Inter">New Message</p>
                    </div>
                    <div className="w-full py-[24px]">
                        <div className="h-[38px] border-b border-[#E7E7E7] flex gap-[16px] px-[16px] items-center">
                            <span className="text-[#888] text-[14px] font-medium font-Inter">To</span>
                            <div className="">
                                <Autocomplete
                                    options={allRelations}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    isOptionEqualToValue={(option, value) => option.profileId === value.profileId}
                                    disabled={!senderAccountId}
                                    onChange={(event, newValue) => setSelectedProfileId(newValue ? newValue.profileId : null)}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder={
                                                senderAccountId
                                                    ? "Please choose linkedin profile"
                                                    : "Please choose sender first to see your connections"
                                            }
                                            {...params}
                                            variant="outlined"
                                            sx={{
                                                minWidth: 320,
                                                "& .MuiOutlinedInput-root": {
                                                    padding: "0 !important",
                                                },
                                                "& .MuiAutocomplete-input": {
                                                    padding: "0 !important",
                                                    fontFamily: "Inter !important",
                                                    fontSize: "14px !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: "none",
                                                },
                                            }}
                                        />
                                    )}
                                    sx={{
                                        "& .MuiAutocomplete-option": {
                                            fontFamily: "Inter !important",
                                            fontSize: "14px",
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="h-[38px] border-b border-[#E7E7E7] flex gap-[16px] px-[16px] items-center">
                            <span className="text-[#888] text-[14px] font-medium font-Inter">From</span>
                            <div className="">
                                <Select
                                    value={senderAccountId || ""}
                                    onChange={(event) => {
                                        const selected = accountOptions?.find((opt) => opt.value === event.target.value);
                                        setSenderAccountId(selected?.value || null);
                                    }}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <span style={{ color: "#b2b2b2" }}>Select an account</span>; // Placeholder styling
                                        }
                                        return accountOptions.find((option) => option.value === selected)?.label || "";
                                    }}
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        padding: 0,
                                        paddingBottom: "1px",
                                        minWidth: 320,
                                        "& .MuiSelect-select": {
                                            padding: "0px",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    {accountOptions?.map((option) => (
                                        <MenuItem
                                            sx={{
                                                fontFamily: "Inter",
                                            }}
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="min-h-[208px]">
                        <HtmlEditor value={messageText} onChange={(value) => setMessageText(value)} />
                    </div>
                </div>
                <button
                    className="mt-[14px] w-[117px] h-[36px] flex items-center justify-center bg-accents-mainBlack hover:opacity-80 transition-all rounded-[8px] text-white"
                    onClick={handleStartChat}
                    disabled={isSendButtonDisabled}
                >
                    Start Chat
                </button>
            </div>
        </div>
    );
};
