import QuestionTag from "./QuestionTag";
import { FAQ_LIST } from "@/utils/constants";
import { PRICING_FAQ_LIST } from "@/utils/constants";
import { useLocation } from "react-router-dom";

export default function QuestionPanel() {
    return (
        <div className="flex flex-col items-center overflow-hidden border rounded-lg border-[#e7e7e7] ">
            {FAQ_LIST.map((FAQ, index) => (
                <QuestionTag question={FAQ.question} answer={FAQ.answer} isLast={index === FAQ_LIST.length - 1} />
            ))}
        </div>
    );
}
