import { useContext, useState, useRef } from "react";
import { Button } from "@material-tailwind/react";
import PhoneInput from "react-phone-input-2";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import Google from '@/components/auth/Google';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGoogleLogin } from "@react-oauth/google";
import ReCAPTCHA from "react-google-recaptcha";

import { AuthContext } from "@/context/AuthContext";

import AuthLayout from "@/components/AuthLayout";

import { ReactComponent as Logo } from "@/assets/image/icons/fuse landing page/fuse-logo.svg";
import { ReactComponent as HidePassword } from "@/assets/image/icons/hide.svg";
import GoogleIcon from "@/assets/image/icons/google.svg";
import Text from "@/components/Text";

import "@/components/phone-input/style.css";

import { COGNITO_GOOGLE_DEFAULT_PASSWORD } from "@/utils/constants";

import useKompassRouter from "@/hooks/useKompassRouter";

const schema = yup.object().shape({
    email: yup.string().email("Email must be a valid Email").required("Email is required"),
    userName: yup.string().required("Last Name is required"),
    // companyName: yup.string().required("Company Name is required"),
    // mobile: yup.string().required('Mobile Number is required'),
    agreement: yup.boolean().required().oneOf([true], "Please accept the KompassAI Terms of Service before continuing").default(true),
    password: yup
        .string()
        .required("Password is required")
        .min(10, "Password must be at least 10 characters")
        .max(32, "Password must be at most 32 characters")
        .matches(/[a-z]+/, "password must contain at least 1 lowercase character")
        .matches(/[A-Z]+/, "password must contain at least 1 uppercase character")
        .matches(/[@$!%*#?&]+/, "password must contain at least one special character")
        .matches(/\d+/, "password must contain at least one number"),
    // confirmPassword: yup.string().required('Confirm password is required')
});

export default function SignUp() {
    const { kompassNavigate } = useKompassRouter();

    const authContext = useContext(AuthContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [isLoading, setisLoading] = useState(false);
    const [isGoogleAuth, setIsGoogleAuth] = useState(false);
    const [mobile, setMobile] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const captchaRef = useRef(null);

    const gotoSignin = () => {
        kompassNavigate("/signin");
    };

    const onSubmitHandler = (data) => {
        if (data.confirmPassword !== data.password) toast.error("Please check your confirm password", { theme: "colored" });
        if (data.mobile === "") toast.error("Please check your mobile number", { theme: "colored" });
        handleRegister(data);
    };

    const handleRegister = async (data) => {
        const captchaToken = captchaRef.current.getValue();
        if (captchaToken === "" || captchaToken == null) {
            toast.error("Please check your captcha", { theme: "colored" });
            return;
        }

        try {
            setisLoading(true);

            data = { ...data, mobile, isGoogleAuth };
            await authContext.signUpWithEmail(data.email.toLowerCase(), data.password, data, {
                captcha: captchaToken,
            });

            setisLoading(false);

            kompassNavigate("/verification");
        } catch (err) {
            setisLoading(false);

            console.log(err);
            if (err instanceof Error) {
                if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
                    toast.error("Recaptcha verification failed", { theme: "colored" });
                } else if (err.message.includes("Missing Captcha Value")) {
                    toast.error("Missing Captcha Value", { theme: "colored" });
                } else if (err.message.includes("User doesn't exist")) {
                    toast.error("User doesn't exist", { theme: "colored" });
                } else {
                    toast.error(err.message, { theme: "colored" });
                }
                captchaRef.current.reset();
            }
        }
    };

    // const googleSignup = useGoogleLogin({
    //     onSuccess: async (codeResponse) => {
    //         try {
    //             setisLoading(true);
    //             const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: `Bearer  ${codeResponse.access_token}`,
    //                     Accept: "application/json",
    //                 },
    //             });
    //             const _resData = await response.json();
    //             setisLoading(false);
    //             setIsGoogleAuth(true);

    //             reset({
    //                 email: _resData.email,
    //                 userName: _resData.name,
    //                 password: COGNITO_GOOGLE_DEFAULT_PASSWORD,
    //                 confirmPassword: COGNITO_GOOGLE_DEFAULT_PASSWORD,
    //             });
    //         } catch (err) {
    //             setisLoading(false);
    //             setIsGoogleAuth(false);
    //             console.log(err);
    //             if (err instanceof Error) {
    //                 if (err.message.includes("PreAuthentication failed with error Recaptcha verification failed.")) {
    //                     toast.error("Recaptcha verification failed", { theme: "colored" });
    //                 } else if (err.message.includes("Missing Captcha Value")) {
    //                     toast.error("Missing Captcha Value", { theme: "colored" });
    //                 } else if (err.message.includes("User doesn't exist")) {
    //                     toast.error("User doesn't exist", { theme: "colored" });
    //                 } else {
    //                     toast.error(err.message, { theme: "colored" });
    //                 }
    //                 captchaRef.current.reset();
    //             }
    //         }
    //     },
    //     onError: (error) => console.log("Login Failed:", error),
    // });

    return (
        <AuthLayout>
            <div className="w-full rounded-xl bg-white mt-20">
                <Text variant="Header4" className="!text-2xl text-start" data-qa="signup-page-auth-title">
                    Create a free account
                </Text>
                <p className="text-dgray-6 mt-2 text-base font-medium">Get free contact details of decision-markers</p>
                {/* <hr className="my-8 h-px bg-stone-250 w-full" /> */}
                <form onSubmit={handleSubmit(onSubmitHandler)} className="auth-form mt-8">
                    <div className="w-full">
                        {/* <div className="auth-label">Email address</div> */}
                        <div className="auth-input-container">
                            <input
                                {...register("email")}
                                data-qa="signup-email-input"
                                className="auth-input"
                                placeholder="Email address"
                                disabled={isLoading || isGoogleAuth}
                            />
                        </div>
                        {errors.email && (
                            <p data-qa="signup-email-error" className="auth-error">
                                {errors.email.message?.toString()}
                            </p>
                        )}
                    </div>
                    <div className="w-full">
                        {/* <div className="auth-label">Your full name</div> */}
                        <div className="auth-input-container">
                            <input
                                {...register("userName")}
                                data-qa="signup-fullname-input"
                                className="auth-input h-[46px]"
                                placeholder="Full name"
                                disabled={isLoading || isGoogleAuth}
                            />
                        </div>
                        {errors.userName && (
                            <p data-qa="signup-fullname-error" className="auth-error">
                                {errors.userName.message?.toString()}
                            </p>
                        )}
                    </div>
                    {/* <div className="w-full">
                        <div className="auth-label ">Company name</div>
                        <div className="auth-input-container">
                            <input {...register("companyName")} className="auth-input" placeholder="e.g. KompassAI" disabled={isLoading} />
                        </div>
                        {errors.companyName && <p className="auth-error">{errors.companyName.message?.toString()}</p>}
                    </div> */}
                    <div className="w-full">
                        {/* <div className="auth-label ">Phone number</div> */}
                        <div className="auth-input-container">
                            <PhoneInput
                                data-qa="signup-phone-input"
                                className="font-Inter w-full rounded-xl text-[16px] py-[5px] outline-none !h-[46px]"
                                placeholder="Enter phone number"
                                country="us"
                                value={mobile}
                                onChange={setMobile}
                                disabled={isLoading}
                                countryCodeEditable={false}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        {isGoogleAuth === false ? (
                            <>
                                {/* <div className="auth-label ">Choose a password</div> */}
                                <div className="auth-input-container">
                                    <input
                                        {...register("password")}
                                        data-qa="signup-password-input"
                                        type={!showPassword ? "password" : "text"}
                                        className="auth-input h-[46px]"
                                        placeholder="Password"
                                        disabled={isLoading}
                                    />
                                    <span
                                        data-qa="password-hide-icon"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                    >
                                        <HidePassword
                                            fill={showPassword ? "#E7436A" : "#929292"}
                                            className="absolute top-1/2 transform -translate-y-1/2 right-4"
                                        />
                                    </span>
                                </div>
                                {errors.password && (
                                    <p data-qa="signup-password-error" className="auth-error">
                                        {errors.password.message?.toString()}
                                    </p>
                                )}

                                {/* <div className="auth-label mt-4">Confirm your password</div> */}
                                <div className="auth-input-container mt-4">
                                    <input
                                        {...register("confirmPassword")}
                                        data-qa="signup-confirm-password-input"
                                        type={!showPassword ? "password" : "text"}
                                        className="auth-input h-[46px]"
                                        placeholder="Confirm Password"
                                        disabled={isLoading}
                                    />
                                </div>
                                {errors.confirmPassword && <p className="auth-error">{errors.confirmPassword.message?.toString()}</p>}
                            </>
                        ) : null}
                    </div>
                    <div className="flex w-full flex-row items-center justify-center rounded-[40px] border-none border-[#E8E7E7] bg-white p-[4px_8px]">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_KOMPASSAI_RECAPTCHA_SITE_KEY_CHECKBOX} ref={captchaRef} Size="Compact" />
                    </div>
                    <Button
                        type="submit"
                        data-qa="signup-submit-button"
                        className="submit-button !h-[46px] hover:opacity-80"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                        <div className="text-white text-sm font-normal font-['Inter'] capitalize leading-tight tracking-tight">
                            Get Started
                        </div>
                    </Button>
                    {/* <div>
                        <div className=" text-center mt-6 text-dgray-6 text-sm font-normal font-['Inter'] leading-tight">
                            Or sign up with your work email
                        </div>
                        <Button className="google-button mt-2 !shadow-none !py-2 !h-[46px] hover:!bg-dgray-50">
                            <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="Google Icon" />
                            <div className="text-[#050505] text-sm font-medium font-['Inter'] capitalize leading-tight tracking-tight">
                                Signup via Google
                            </div>
                        </Button>
                    </div> */}
                    <div className="my-5 flex w-full justify-center text-dgray-6 text-sm  font-['Inter'] leading-tight">
                        Already have an account?
                        <span
                            onClick={gotoSignin}
                            data-qa="signup-link"
                            className="ml-1 text-primary-teal hover:opacity-85 font-medium cursor-pointer select-none"
                        >
                            Sign In
                        </span>
                    </div>

                    <div className="text-sm font-normal font-['Inter'] w-full text-dgray-6 ">
                        By creating an account, I agree to the{" "}
                        <Link
                            to="/terms-and-conditions"
                            className="text-primary-teal cursor-pointer font-medium select-none hover:opacity-85"
                            data-qa="terms-of-service-link"
                        >
                            Terms of service
                        </Link>{" "}
                        and{" "}
                        <Link
                            to="/privacy-policy"
                            data-qa="privacy-policy-link"
                            className="text-primary-teal font-medium cursor-pointer select-none hover:opacity-85"
                        >
                            Privacy policy
                        </Link>
                        . I also agree to receive emails and communication relating to FuseAI services and offers.
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}
