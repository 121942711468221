import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { LinearProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ScrollArea } from "@/components/common/ScrollArea";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useFiles from "@/hooks/crm/useFiles";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function UploadModal({ open, setOpen, setRefreshUI, type = "opportunity", typeId }) {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [loading, setLoading] = useState(false);
    const { uploadFile } = useFiles();
    const { opportunityId } = useParams();

    const getFileIconAndColor = (file) => {
        if (file.type.includes("image")) {
            return {
                icon: <InsertPhotoOutlinedIcon className="!h-5 !w-5" />,
                color: "green",
            };
        }
        return {
            icon: <AttachFileOutlinedIcon className="!h-5 !w-5" />,
            color: "gray",
        };
    };

    const onDrop = useCallback((acceptedFiles) => {
        const filesWithProgress = acceptedFiles.map((file) => ({ file, progress: 0 }));
        // show error if any file contains more than 5MB
        const largeFiles = filesWithProgress.filter((file) => file.file.size > 5 * 1024 * 1024);
        if (largeFiles.length > 0) {
            toast.error("File size should be less than 5MB");
            return;
        }
        // filter any duplicate files
        const uniqueFiles = filesWithProgress.filter((file) => !filesToUpload.some((f) => f.file.name === file.file.name));
        setFilesToUpload(uniqueFiles);
    }, []);

    const onUploadProgress = (file, progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFilesToUpload((prevFiles) => prevFiles.map((f) => (f.file === file ? { ...f, progress } : f)));
    };

    const uploadFilesToServer = async () => {
        let fail = false;
        for (const { file } of filesToUpload) {
            setLoading(true);
            try {
                await uploadFile(typeId, type, file, (event) => onUploadProgress(file, event), opportunityId);
            } catch (error) {
                fail = true;
                console.error("Error uploading file:", error);
            }
            setLoading(false);
        }
        if (fail) {
            toast.error("Failed to upload some files");
            return;
        }
        setRefreshUI((prev) => !prev);
        toast.success(`${filesToUpload.length} files uploaded successfully`);
        setOpen(false);
        setFilesToUpload([]);
    };

    const removeFile = (file) => {
        setFilesToUpload((prev) => prev.filter((f) => f.file !== file));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm" scroll="body">
                <DialogTitle>
                    Upload file
                    <IconButton onClick={() => setOpen(false)} sx={{ position: "absolute", right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="flex flex-col gap-5 mt-1 py-5">
                        <div className="h-[200px] px-5">
                            <label
                                {...getRootProps()}
                                className={`relative flex flex-col items-center justify-center w-full h-full p-6 border-2 ${
                                    isDragActive ? "border-blue-500" : "border-gray-300"
                                } border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100`}
                            >
                                <div className="text-center">
                                    <FileUploadOutlinedIcon />
                                    <p className="text-base font-semibold mt-4">
                                        <span className="text-blue-500">Click to upload</span> or Drag & drop
                                    </p>
                                    <p className="text-dgray-500 text-sm mt-2">Supports CSV, JPEG, PNG, or PDF (Max.5MB)</p>
                                </div>
                            </label>
                            <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
                        </div>
                        {filesToUpload.length > 0 && (
                            <ScrollArea className="h-[220px] px-5">
                                <div>
                                    <div className="space-y-2">
                                        {filesToUpload.map(({ file, progress }, index) => (
                                            <div
                                                key={index}
                                                className="flex justify-between gap-2 rounded-lg overflow-hidden border border-stone-250 pr-3"
                                            >
                                                <div className="flex items-center flex-1 p-2">
                                                    <div>{getFileIconAndColor(file).icon}</div>
                                                    <div className="w-full ml-2 space-y-1">
                                                        <div className="text-sm flex justify-between">
                                                            <p className="text-muted-foreground">{file.name.slice(0, 25)}</p>
                                                            <span className="text-xs">{progress}%</span>
                                                        </div>
                                                        <LinearProgress variant="determinate" value={progress} className="!h-1" />
                                                        {/* size */}
                                                        <div className="flex items-center text-xs text-muted-foreground">
                                                            <p>{formatFileSize(file.size)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button onClick={() => removeFile(file)} className="text-gray-500 pl-3">
                                                    <CloseOutlinedIcon className="!w-5 !h-5" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ScrollArea>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="flex items-center gap-5 p-2">
                        <button
                            onClick={() => setOpen(false)}
                            disabled={loading}
                            className="border border-stone-250 rounded-lg py-2 px-4 bg-dgray-200 hover:opacity-90 disabled:opacity-50"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={loading}
                            onClick={uploadFilesToServer}
                            className="flex items-center gap-2 border border-black rounded-lg py-2 px-4 bg-black text-white hover:opacity-90 disabled:opacity-50"
                        >
                            {loading && <CircularProgress size={14} color="inherit" />}
                            Upload
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} B`; // Bytes for very small sizes
    } else if (sizeInBytes < 1024 * 1024) {
        return `${(sizeInBytes / 1024).toFixed(0)} KB`; // Convert to KB
    } else {
        return `${(sizeInBytes / 1024 / 1024).toFixed(1)} MB`; // Convert to MB
    }
};
