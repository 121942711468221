import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { sortTableData } from "../utils";
import { CircularProgress, TableCell, TableRow, Box, Pagination, PaginationItem } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import useNotes from "@/hooks/crm/useNotes";
import NotesTableHeader, { headCells } from "./NotesTableHeader"; // Import headCells
import NotesTableRow from "./NotesTableRow";

const idealRow = ["_id", "title", "opportunity", "content", "createdAt", "updatedAt", "createdBy"];

const formatNote = (note) => {
    const formattedNote = {};
    idealRow.forEach((key) => {
        formattedNote[key] = note[key] || "";
    });
    return formattedNote;
};

export default function NotesTable({ data, setData, selected, setSelected, loading, newRowId, setNewRowId }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("title");
    const [opportunityOptions, setOpportunityOptions] = React.useState([]);

    // -- NEW PAGINATION STATES --
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    const { createNote, updateNote, getOpportunities } = useNotes();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedData = sortTableData(data, property, isAsc);
        setData(sortedData);

        // Reset pagination to first page whenever we sort
        setPage(1);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleSave = async (updatedRow) => {
        // validate the row
        if (Object.values(updatedRow).every((value) => value === "")) {
            return;
        }

        // if no value is changed
        if (data.some((row) => row._id === updatedRow._id && Object.keys(updatedRow).every((key) => row[key] === updatedRow[key]))) {
            return;
        }
        try {
            if (updatedRow._id === newRowId) {
                const createdNote = await createNote(updatedRow);
                let resData = formatNote(createdNote.data);
                setData((prev) => prev.map((row) => (row._id === newRowId ? resData : row)));
                setNewRowId(null);
            } else {
                await updateNote(updatedRow);
                setData((prev) => prev.map((row) => (row._id === updatedRow._id ? updatedRow : row)));
            }
        } catch (error) {
            console.error("Error saving note:", error);
        }
    };

    React.useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const opportunities = await getOpportunities();
                if (opportunities) {
                    setOpportunityOptions(
                        opportunities.data.map((opportunity) => ({
                            _id: opportunity._id,
                            title: opportunity.title,
                        }))
                    );
                }
            } catch (error) {
                console.error("Error fetching opportunities:", error);
            }
        };

        fetchOpportunities();
    }, [getOpportunities]);

    // -- PAGINATION CALCULATIONS --
    const totalPages = Math.ceil(data.length / rowsPerPage);

    // Memoize the visible rows for performance; this also ensures
    // we slice after sorting.
    const visibleRows = React.useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return data.slice(startIndex, startIndex + rowsPerPage);
    }, [data, page, rowsPerPage]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <div className="border mt-5 rounded-[8px] border-[#e7e7e7]">
                <TableContainer sx={{ borderRadius: "8px" }}>
                    <Table
                        sx={{
                            minWidth: {
                                xs: 300,
                                lg: 750,
                            },
                            borderCollapse: "separate",
                        }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <NotesTableHeader
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={data.length}
                            selected={selected}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    padding: "5px 12px",
                                    fontFamily: "Inter",
                                    fontSize: 14,
                                    border: "1px solid #E8E7E7",
                                },
                            }}
                        >
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={headCells.length + 1} style={{ textAlign: "center", height: "200px" }}>
                                        <CircularProgress size={24} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <NotesTableRow
                                            key={row._id}
                                            isItemSelected={isItemSelected}
                                            row={row}
                                            index={index}
                                            labelId={labelId}
                                            onSave={handleSave}
                                            setSelected={setSelected}
                                            opportunityOptions={opportunityOptions}
                                            focusOnField={row._id === newRowId ? "title" : null}
                                            headCells={headCells} // Pass headCells as a prop
                                        />
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {/* PAGINATION */}
            {data.length > rowsPerPage && (
                <Box className="mt-4 flex w-full justify-end pb-4 mr-[24px]">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
}
