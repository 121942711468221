import React, { useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell as MuiTableCell,
    TableBody,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Chip,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import {
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
} from "@mui/icons-material";
import ConfirmDelete from "./ConfirmDelete";
import useTeam from "@/hooks/useTeam";
import { ReactComponent as NameIcon } from "@/assets/image/team/name.svg";
import { ReactComponent as EmailIcon } from "@/assets/image/team/email.svg";
import { ReactComponent as CreditsIcon } from "@/assets/image/team/monthly-credits.svg";
import { ReactComponent as StatusIcon } from "@/assets/image/team/status.svg";
import { ReactComponent as RoleIcon } from "@/assets/image/team/role.svg";
import { ReactComponent as CRMIcon } from "@/assets/image/team/crm-permissions.svg";
import { ReactComponent as ActionsIcon } from "@/assets/image/team/actions.svg";
import { ReactComponent as VisibilityIcon } from "@/assets/image/team/visibility.svg";
import { ReactComponent as EditIcon } from "@/assets/image/team/edit.svg";
import { ReactComponent as AssignIcon } from "@/assets/image/team/assign.svg";
import { ReactComponent as RemoveIcon } from "@/assets/image/team/remove.svg";
import AssignCreditsModal from "./AssignCreditsModal";
import { useCredits } from "@/hooks/useCredits";

// Keep your existing comparator and sorting logic:
function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === "string") {
        // e.g. for name/email
        return b[orderBy].localeCompare(a[orderBy]);
    } else {
        // e.g. for numeric fields
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array = [], comparator) {
    const stabilizedThis = array.map((el, i) => [el, i]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// -- Styled cells that mimic the "new design" style (like your billing table) --
const StyledHeadCell = styled(MuiTableCell)(({ theme }) => ({
    backgroundColor: "#f4f3f0",
    fontWeight: "bold",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    padding: "12px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
}));

const StyledBodyCell = styled(MuiTableCell)(() => ({
    verticalAlign: "middle",
    padding: "12px",
    height: "53px",
    borderRight: "1px solid #e7e7e7",
    "&:last-child": {
        borderRight: "none",
    },
}));

// Example columns for your new design. Adjust as needed for your real table.
const columns = [
    {
        id: "name",
        label: "Name",
        width: 150,
        sortable: true,
        icon: <NameIcon />,
    },
    {
        id: "email",
        label: "Email",
        width: 150,
        sortable: false,
        icon: <EmailIcon />,
    },
    {
        id: "creditLimit",
        label: "Monthly Credits",
        width: 150,
        sortable: true,
        icon: <CreditsIcon />,
    },
    {
        id: "status",
        label: "Status",
        width: 100,
        sortable: true,
        icon: <StatusIcon />,
    },
    {
        id: "role",
        label: "Role",
        width: 100,
        sortable: false,
        icon: <RoleIcon />,
    },
    {
        id: "crm_permissions",
        label: "CRM Permissions",
        width: 150,
        sortable: false,
        icon: <CRMIcon />,
    },
    {
        id: "actions",
        label: "Actions",
        width: 100,
        sortable: false,
        icon: <ActionsIcon />,
    },
];

export default function EnhancedTable({ data = [], setData, sort, creditInfo, updateCreditInfo }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(1);
    const rowsPerPage = 10; // or your preferred rowsPerPage
    const { refetchCredits } = useCredits();

    const [openConfirm, setOpenConfirm] = useState(null);

    // For the new popover menu:
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);

    const [openAssign, setOpenAssign] = useState(false); // assign credits

    const [isLoading, setIsLoading] = useState(false);
    // const [edit, setEdit] = useState(null);

    // Hooks for deleting & updating:
    const { deleteMember, updateTeamMember } = useTeam();

    // Menu handling
    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedMember(row);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Confirm delete
    const handleRemoveMemberClick = () => {
        setOpenConfirm(selectedMember);
        handleMenuClose();
    };

    // When user chooses to "Assign Credits" from the menu:
    const handleAssignCreditsClick = () => {
        // If user not active, block
        if (selectedMember?.status !== "ACTIVE") {
            toast.error("User must accept the invitation before credits can be assigned.");
            handleMenuClose();
            return;
        }
        setOpenAssign(true);
        handleMenuClose();
    };

    // The actual delete method
    const handleDeleteTeamMember = async () => {
        if (!openConfirm) return;
        setIsLoading(true);
        try {
            await deleteMember({ id: openConfirm._id });
            setData((prev) => prev.filter((item) => item._id !== openConfirm._id));
            toast.success(`Team member ${openConfirm.name} has been removed successfully`, {
                theme: "colored",
            });
        } catch (error) {
            toast.error("Error deleting team member, please try again!", {
                theme: "colored",
            });
        } finally {
            setOpenConfirm(null);
            setIsLoading(false);
        }
    };

    // "Assign Credits" confirm method
    const handleAssignCredits = async (member, credits) => {
        setIsLoading(true);
        try {
            let numericValue = parseInt(credits, 10);
            if (Number.isNaN(numericValue)) numericValue = 0;

            const response = await updateTeamMember({
                id: member._id,
                creditLimit: numericValue,
            });

            if (response.status === false) {
                toast.error(response.data ?? response?.message, {
                    theme: "colored",
                });
            } else {
                // Update local data
                setData((prev) =>
                    prev.map((item) => {
                        if (item._id === member._id) {
                            return { ...item, ...response.data };
                        }
                        return item;
                    })
                );

                // Update creditInfo locally
                updateCreditInfo(numericValue);
                await refetchCredits();

                toast.success(`Team member ${member.name} has been updated successfully`, {
                    theme: "colored",
                });
            }
        } catch (err) {
            toast.error("Error assigning credits. Please try again.", {
                theme: "colored",
            });
        } finally {
            setIsLoading(false);
            setOpenAssign(false);
        }
    };

    // Sorting handler
    const handleRequestSort = (columnId) => {
        const isAsc = orderBy === columnId && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(columnId);
    };

    // Do sorting using stableSort / getComparator:
    const sortedRows = React.useMemo(() => {
        if (!orderBy) return data;
        return stableSort(data, getComparator(order, orderBy));
    }, [data, order, orderBy]);

    // Pagination
    const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
    const currentRows = sortedRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const handleChangePage = (event, newValue) => setPage(newValue);

    // Render sorting arrows
    const renderSortingIcons = (col) => {
        if (!col.sortable) return null;
        const isActive = orderBy === col.id;

        return (
            <Box
                sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    marginLeft: "4px",
                    cursor: "pointer",
                    position: "relative",
                    top: "2px",
                }}
            >
                <ArrowDropUpIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "asc" ? "#000" : "#999",
                        marginBottom: "-12px",
                    }}
                />
                <ArrowDropDownIcon
                    fontSize="small"
                    sx={{
                        color: isActive && order === "desc" ? "#000" : "#999",
                    }}
                />
            </Box>
        );
    };

    return (
        <Box sx={{ width: "100%", mt: 2 }}>
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: "none",
                    borderTop: "1px solid #e7e7e7",
                    borderLeft: "1px solid #e7e7e7",
                    borderRight: "1px solid #e7e7e7",
                    borderRadius: "8px",
                    overflowX: "auto",
                }}
            >
                <Table
                    sx={{
                        borderCollapse: "separate",
                        tableLayout: "fixed",
                        minWidth: 1200,
                    }}
                    aria-labelledby="teamTable"
                >
                    {/* HEAD */}
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <StyledHeadCell
                                    key={col.id}
                                    sx={{ width: col.width }}
                                    onClick={col.sortable ? () => handleRequestSort(col.id) : undefined}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <div className="flex flex-row justify-start items-center gap-2">
                                            {col.icon}
                                            <div className="text-[#5d5d5d] text-sm font-medium font-['Inter'] leading-[21px]">
                                                {col.label}
                                            </div>
                                        </div>
                                        {renderSortingIcons(col)}
                                    </Box>
                                </StyledHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* BODY */}
                    <TableBody>
                        {currentRows && currentRows.length > 0 ? (
                            currentRows.map((row) => (
                                <TableRow
                                    key={row._id}
                                    sx={{
                                        "&:hover": {
                                            background: "#f4f3f0",
                                        },
                                    }}
                                >
                                    {/* Name */}
                                    <StyledBodyCell>
                                        <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">{row.name}</div>
                                    </StyledBodyCell>

                                    {/* Email */}
                                    <StyledBodyCell>
                                        <Tooltip title={row.email} arrow>
                                            <div
                                                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                                className="text-[#050505] text-sm break-all font-medium font-['Inter'] leading-[21px]"
                                            >
                                                {row.email}
                                            </div>
                                        </Tooltip>
                                    </StyledBodyCell>

                                    {/* Monthly Credits (or creditLimit) */}
                                    <StyledBodyCell>
                                        <div className="text-left text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">
                                            {row?.availableCredit ?? 0}/{row.creditLimit ?? 0}
                                        </div>
                                    </StyledBodyCell>

                                    {/* Status */}
                                    <StyledBodyCell>
                                        <Chip
                                            label={row.status}
                                            color="primary"
                                            sx={
                                                row.status === "ACTIVE"
                                                    ? {
                                                          color: "#1C4E38",
                                                          backgroundColor: "#EAF1EE",
                                                          fontWeight: 400,
                                                          paddingX: "0.5rem",
                                                          height: "29px",
                                                          paddingY: "0.25rem",
                                                          borderRadius: "0.25rem",
                                                          fontSize: "0.75rem",
                                                          "& .MuiChip-label": {
                                                              paddingX: 0,
                                                          },
                                                      }
                                                    : {
                                                          color: "#FF782C",
                                                          backgroundColor: "#FFEEDE",
                                                          fontWeight: 400,
                                                          paddingX: "0.5rem",
                                                          height: "29px",
                                                          paddingY: "0.25rem",
                                                          borderRadius: "0.25rem",
                                                          fontSize: "0.75rem",
                                                          "& .MuiChip-label": {
                                                              paddingX: 0,
                                                          },
                                                      }
                                            }
                                        />
                                    </StyledBodyCell>

                                    {/* Role */}
                                    <StyledBodyCell>
                                        <div className="h-[29px] px-2 py-1 bg-[#f6f6f6] rounded justify-start items-center gap-6 inline-flex">
                                            <div className="text-center text-[#050505] text-xs font-normal font-['Inter'] leading-[21px]">
                                                Member
                                            </div>
                                        </div>
                                    </StyledBodyCell>

                                    {/* CRM Permissions */}
                                    <StyledBodyCell>
                                        <div className="h-[29px] px-2 py-1 bg-[#f6f6f6] rounded justify-start items-center gap-2 inline-flex">
                                            <VisibilityIcon />
                                            <div className="text-center text-[#050505] text-xs font-normal font-['Inter'] leading-[21px]">
                                                View Only Access
                                            </div>
                                        </div>
                                    </StyledBodyCell>

                                    {/* Actions */}
                                    <StyledBodyCell>
                                        <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                                            <EditIcon />
                                        </IconButton>
                                    </StyledBodyCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <StyledBodyCell colSpan={columns.length} align="center">
                                    No data available
                                </StyledBodyCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            {sortedRows.length > rowsPerPage && (
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                        renderItem={(item) => <PaginationItem components={{ first: FirstPageIcon, last: LastPageIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                fontFamily: "Inter, sans-serif",
                                color: "#050505",
                                border: "1px solid #e7e7e7",
                                borderRadius: "4px",
                            },
                            "& .MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#2D7A89",
                                color: "#fff",
                                borderRadius: "4px",
                            },
                        }}
                    />
                </Box>
            )}

            {/* The "More" menu with two actions */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right", // Align the menu's right edge with the anchor's right edge
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right", // Align the menu's top with the anchor's bottom
                }}
                sx={{
                    "& .MuiMenu-list": {
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    },
                    "& .MuiMenuItem-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                        paddingLeft: 0,
                    },
                    "& .MuiPaper-root": {
                        height: "114px", // Set a fixed height
                        width: "337px", // Optionally, set a fixed width
                        overflowY: "auto", // Add scroll if content overflows
                        transform: "translateX(-10px)", // Move the menu left by 10px
                    },
                }}
            >
                <MenuItem onClick={handleAssignCreditsClick}>
                    <div className="h-[37px] px-4 py-2 hover:bg-[#f4f3f0] rounded-lg justify-start items-center inline-flex">
                        <div className="justify-start items-start gap-2 flex">
                            <div className="w-5 h-5 ">
                                <AssignIcon />
                            </div>
                            <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">Assign Credits</div>
                        </div>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleRemoveMemberClick}>
                    <div className="h-[37px] px-4 py-2 hover:bg-[#f4f3f0] rounded-lg justify-start items-center inline-flex">
                        <div className="justify-start items-start gap-2 flex">
                            <div className="w-5 h-5 ">
                                <RemoveIcon />
                            </div>
                            <div className="text-[#050505] text-sm font-medium font-['Inter'] leading-[21px]">Remove team member</div>
                        </div>
                    </div>
                </MenuItem>
            </Menu>

            {/* ConfirmDelete dialog for removing user */}
            <ConfirmDelete
                open={openConfirm}
                handleClose={() => setOpenConfirm(null)}
                handleConfirm={handleDeleteTeamMember}
                isLoading={isLoading}
            />

            {/* AssignCreditsModal dialog */}
            <AssignCreditsModal
                open={openAssign}
                handleClose={() => setOpenAssign(false)}
                member={selectedMember}
                handleAssignCredits={handleAssignCredits}
                isLoading={isLoading}
            />
        </Box>
    );
}
