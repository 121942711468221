import React, { useState } from "react";
import LandingLayout from "@/components/LandingLayout";
import PersonDirectory from "./person_directory/PersonDirectory";
import AlphabetGrid from "./alphabet_grid/AlphabetGrid";
import ContactList from "./contacts/ContactList";
import { Helmet } from "react-helmet";

function DirectoryScene() {
    const [selectedLetter, setSelectedLetter] = useState("A");

    return (
        <>
            <Helmet>
                <title>Directory | FuseAI</title>
                <link rel="canonical" href="https://tryfuse.ai/directory" />
            </Helmet>
            <LandingLayout>
                <div className="bg-primary-header">
                    <PersonDirectory />
                    <AlphabetGrid selectedLetter={selectedLetter} setSelectedLetter={setSelectedLetter} />
                    <ContactList selectedLetter={selectedLetter} />
                </div>
            </LandingLayout>
        </>
    );
}

export default DirectoryScene;
