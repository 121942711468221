import { TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
function Link({ register, error, value, index, inputKey, labelKey, removeLink }) {
    const [editLabel, setLabelEdit] = useState(false);

    return (
        <>
            <div className="w-full flex flex-col relative">
                <div className="w-full">
                    {editLabel ? (
                        <div className="flex items-center gap-2">
                            <div className=" pb-1 gap-2 relative flex w-fit border-b-1 border-black items-center  bg-white">
                                <input
                                    defaultValue={value.label}
                                    placeholder="Link label"
                                    className="outline-none border-none font-InterMedium text-base flex w-full font-medium"
                                    name="label"
                                    {...register(labelKey)}
                                ></input>
                            </div>
                            <button onClick={() => setLabelEdit(false)}>
                                <Tooltip title="Finish editing">
                                    <DoneIcon className="text-black cursor-pointer" />
                                </Tooltip>
                            </button>
                        </div>
                    ) : (
                        <label className="font-InterMedium text-base w-full font-medium h-[27px] flex items-center gap-2">
                            {value.label || `Webhook link ${index + 1}`}
                            <Tooltip title="Edit label">
                                <EditIcon onClick={() => setLabelEdit(true)} className="text-black cursor-pointer" />
                            </Tooltip>
                        </label>
                    )}
                </div>

                <div className="flex gap-2 w-full items-center justify-center">
                    <div className="mt-2 relative flex w-full flex-row items-center border-[1px] border-stone-200 bg-white rounded-lg">
                        <input
                            defaultValue={value.link}
                            className="auth-input !placeholder-stone-350 !placeholder-opacity-50"
                            placeholder="https://hooks.zapier.com/hooks/catch/1663415457/dvgasfggw/"
                            name="inputKey"
                            {...register(inputKey)}
                        ></input>
                    </div>
                    <button type="button" onClick={() => removeLink(index)}>
                        <Tooltip title="Remove webhook link">
                            <DeleteIcon className="text-red-500 "></DeleteIcon>
                        </Tooltip>
                    </button>
                </div>
            </div>
            {error &&
                Object.keys(error).length &&
                Object.values(error).map((error) => {
                    return <p className="font-normal text-sm text-red-500">{error.message}</p>;
                })}
        </>
    );
}

export default Link;
