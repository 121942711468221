import React, { useState, useEffect } from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { ReactComponent as ListIcon } from "@/assets/image/crm/opportunities/listIcon.svg";
import SelectComponent from "@/components/crm/opportunities/SelectComponent";
import Actions from "@/components/crm/opportunities/Actions";
import OpportunityTable from "@/components/crm/opportunities/table/OpportunityTable";
import useOpportunities from "@/hooks/crm/useOpportunities";
import MainLoader from "@/components/MainLoader";
import StageTable from "@/components/crm/opportunities/stage-table/StageTable";
import RecordsTabs from "@/components/crm/RecordsTabs";
import MainTitle from "@/components/MainTitle";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

const idealRow = ["_id", "title", "value", "createdBy", "dueDate", "company", "status", "pointOfContact"];

const formatOpportunity = (opportunity) => {
    const formattedOpportunity = {};
    idealRow.forEach((key) => {
        formattedOpportunity[key] = opportunity[key] || "";
    });
    return formattedOpportunity;
};

const formatOpportunities = (opportunities) => {
    return opportunities.map(formatOpportunity).reverse();
};

export default function OpportunitiesScene() {
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [newRowId, setNewRowId] = useState(null);
    const [refreshUI, setRefreshUI] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState("All");

    const { getOpportunities } = useOpportunities();

    const handleAddRow = () => {
        if (selectedValue !== "All") setSelectedValue("All");
        if (newRowId) return;
        const newRow = formatOpportunity({
            _id: Math.random().toString(),
            title: "",
            value: "",
            createdBy: "",
            dueDate: "",
            company: "",
            status: "",
            pointOfContact: "",
        });
        setData((prev) => [newRow, ...prev]); // Add the new row to the top
        setNewRowId(newRow._id); // Track the new row ID for focus
    };

    const fetchOpportunities = async () => {
        setLoading(true);
        try {
            const opportunities = await getOpportunities();
            const formattedData = formatOpportunities(opportunities.data);
            const oppData = formattedData.map((opp) => {
                return {
                    ...opp,
                    pointOfContact: opp.pointOfContact
                        ? { _id: opp.pointOfContact._id, fullName: opp.pointOfContact.firstName + " " + opp.pointOfContact.lastName }
                        : "",
                };
            });
            setData(oppData);
        } catch (error) {
            console.error("Error fetching opportunities:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchOpportunities();
    }, [refreshUI]);

    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                {/* <MainTitle text="Records" /> */}
                <p>Records</p>
            </MainTitleBar>
            <RecordsTabs />
            {loading && <MainLoader />}
            <div className="p-6">
                {data.length > 0 ? (
                    <div>
                        <div className=" flex items-center">
                            <SelectComponent
                                id="opp-select"
                                options={["All", "By Stage"]}
                                selectedValue={selectedValue}
                                onSelectionChange={(event) => {
                                    setSelectedValue(event.target.value);
                                    setRefreshUI(!refreshUI);
                                }}
                                bgColor="#fff"
                                sx={{ width: "250px" }}
                            />
                            <div className="ml-auto">
                                <Actions
                                    setSelected={setSelected}
                                    setRefreshUI={setRefreshUI}
                                    selected={selected}
                                    onAddRow={handleAddRow}
                                />
                            </div>
                        </div>
                        <div className="pb-20">
                            {selectedValue == "All" ? (
                                <OpportunityTable
                                    data={data}
                                    setData={setData}
                                    selected={selected}
                                    setSelected={setSelected}
                                    loading={loading}
                                    newRowId={newRowId}
                                    setNewRowId={setNewRowId}
                                />
                            ) : (
                                <StageTable setSelectedIds={setSelected} refreshUI={refreshUI} />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center mt-16 items-center ">
                        {/* <ListIcon /> */}
                        <h2 className="text-md font-semibold mt-2">Ready to start? Add your first Opportunity!</h2>
                        <p className="mt-4 text-dgray-600 text-sm font-medium">
                            Tap the button below to add your first opportunity and keep everything organised.
                        </p>
                        <button
                            onClick={handleAddRow}
                            className="bg-black text-white py-2 px-4 rounded-md mt-8 text-sm font-semibold hover:opacity-80"
                        >
                            Add
                        </button>
                    </div>
                )}
            </div>
            <FuseAgentModal />
        </MainLayout>
    );
}
