import React, { useState, useEffect } from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { ReactComponent as EmptyStateIcon } from "@/assets/image/crm/notes/emptyState.svg";
import MainLoader from "@/components/MainLoader";
import useNotes from "@/hooks/crm/useNotes";
import NotesTable from "@/components/crm/notes/NotesTable";
import Actions from "@/components/crm/notes/Actions";
const idealRow = ["_id", "title", "opportunity", "content", "createdBy", "createdAt", "updatedAt"];

const formatNote = (note) => {
    const formattedNote = {};
    idealRow.forEach((key) => {
        formattedNote[key] = note[key] || "";
    });
    return formattedNote;
};

const formatNotes = (notes) => {
    return notes.map(formatNote).reverse();
};

export default function NotesScene() {
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [newRowId, setNewRowId] = useState(null);
    const [refreshUI, setRefreshUI] = useState(false);
    const [loading, setLoading] = useState(false);

    const { getNotes } = useNotes();

    const handleAddRow = () => {
        if (newRowId) return;
        const newRow = formatNote({
            _id: Math.random().toString(),
            title: "",
            content: "",
            createdBy: "",
            opportunity: "",
            createdAt: "",
            updatedAt: "",
        });
        setData((prev) => [newRow, ...prev]); // Add the new row to the top
        setNewRowId(newRow._id); // Track the new row ID for focus
    };

    const fetchNotes = async () => {
        setLoading(true);
        try {
            const notes = await getNotes();
            // const notesData = notes.data.map((note) => note.opportunity;
            const formattedData = formatNotes(notes.data);
            setData(formattedData);
        } catch (error) {
            console.error("Error fetching notes:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchNotes();
    }, [refreshUI]);

    return (
        <div>
            <div>
                {loading && <MainLoader />}
                {data.length > 0 ? (
                    <div>
                        <div className="flex justify-start">
                            <div className="">
                                <Actions
                                    setSelected={setSelected}
                                    setRefreshUI={setRefreshUI}
                                    selected={selected}
                                    onAddRow={handleAddRow}
                                />
                            </div>
                        </div>
                        <div className="pb-20">
                            <NotesTable
                                data={data}
                                setData={setData}
                                selected={selected}
                                setSelected={setSelected}
                                loading={loading}
                                newRowId={newRowId}
                                setNewRowId={setNewRowId}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center min-h-[calc(100vh-100px)]">
                        {/* <EmptyStateIcon /> */}
                        <h2 className="text-lg font-semibold mt-2">Ready to start? Add your first Note!</h2>
                        <p className="mt-4 text-dgray-600 text-sm">
                            Tap the button below to add your first note and keep everything organised.
                        </p>
                        <button
                            onClick={handleAddRow}
                            className="bg-black text-white py-2 px-4 rounded-md mt-8 text-sm font-semibold hover:opacity-85"
                        >
                            Add
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
