import React from "react";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { kompassColors } from "theme/palette";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",
        borderRadius: 6,
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 6,
            borderColor: kompassColors.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

export default function SelectDropdown({ options, selectedOption, onChange, defaultValue = null, renderValue, ...props }) {
    const handleOptionClick = (event) => {
        onChange(event.target.value);
    };

    return (
        <Select
            className=""
            value={selectedOption}
            onChange={handleOptionClick}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            input={<BootstrapInput />}
            renderValue={renderValue}
            defaultValue={defaultValue}
            {...props}
            sx={{
                "& .MuiInputBase-input": {
                    ":focus": {
                        boxShadow: "none",
                        borderColor: "none",
                        borderRadius: 0,
                    },
                    ":focus-visible": {
                        boxShadow: "none",
                        borderColor: "none",
                        borderRadius: 0,
                    },
                    padding: "0px 16px",
                    border: "none",
                },
            }}
        >
            {options.map((option) => (
                <MenuItem value={option?.value}>
                    <div className="text-center main-text-black-500 lowercase">{option?.label}</div>
                </MenuItem>
            ))}
        </Select>
    );
}
