import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled, createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { CircularProgress } from "@mui/material";
import useAutocomplete from "@/hooks/useAutocomplete";
import ClearIcon from "@mui/icons-material/Clear";

// Helper function to get a unique identifier for each option
const getOptionId = (option) => {
    if (typeof option === "string") {
        return option;
    } else {
        return option.meta?.id || option.name;
    }
};

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 7,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#80bdff",
        border: "none",
        fontSize: 14,
        fontFamily: "Inter",
        padding: "1px 60px 1px 4px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 7,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
            fontFamily: "Inter",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
});

export default function AutocompleteInput({
    value = "",
    onNameFilterChange,
    searchBy,
    placeholder = null,
    isUseAPI = true,
    initialOptions = [],
    isWebsiteAutocomplete = false,
    clearTrigger,
    multiple = false,
}) {
    const { searchAutoComplete } = useAutocomplete();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState(initialOptions);
    const [filterText, setFilterText] = React.useState(multiple ? "" : value);
    const [apiLoading, setApiLoading] = React.useState(false);
    const [lastTimerId, setLastTimerId] = React.useState(null);

    // State to manage selected values
    // Initialize selectedValues based on whether multiple selections are allowed
    const [selectedValues, setSelectedValues] = React.useState(
        multiple ? (Array.isArray(value) ? value.map((item) => getOptionId(item)) : []) : value ? getOptionId(value) : ""
    );

    // State to manage selected options
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    const capitalizeWords = (input) => {
        return input
            .split(", ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(", ");
    };

    // Add this useEffect hook
    React.useEffect(() => {
        setSelectedValues(
            multiple ? (Array.isArray(value) ? value.map((item) => getOptionId(item)) : []) : value ? getOptionId(value) : ""
        );
    }, [value, multiple]);

    React.useEffect(() => {
        const newSelectedOptions = selectedValues.map((name) => {
            // Find the option in options
            let option = selectedOptions.find((opt) => opt.name === name);
            if (option) {
                return option;
            } else {
                // Option not in current options, create a placeholder option
                return { name: name, meta: { name } };
            }
        });
        setSelectedOptions(newSelectedOptions);
    }, [selectedValues, options]); // Added 'options' as a dependency

    // Update selectedOptions when options change
    React.useEffect(() => {
        setSelectedOptions((prevSelectedOptions) => {
            return prevSelectedOptions.map((selectedOption) => {
                const optionInOptions = options.find((opt) => getOptionId(opt) === getOptionId(selectedOption));
                return optionInOptions || selectedOption;
            });
        });
    }, [options]);

    React.useEffect(() => {
        if (!open) {
            // Only reset options when closing if there's no filter text
            if (!filterText) {
                setOptions(initialOptions);
            }
        }
    }, [open]);

    React.useEffect(() => {
        setFilterText("");
    }, [clearTrigger]);

    React.useEffect(() => {
        if (!multiple) {
            setFilterText(value?.trim() && !isWebsiteAutocomplete ? capitalizeWords(value) : value);
        }
    }, [value]);

    React.useEffect(() => {
        if (filterText || (filterText?.length > 0 && isUseAPI)) {
            if (lastTimerId) clearTimeout(lastTimerId);

            const newLastTimerId = setTimeout(() => {
                loadDataByFilter();
            }, 500);
            setLastTimerId(newLastTimerId);
        }
    }, [filterText]);

    const theme = createTheme({
        palette: {
            primary: {
                main: "#D3E1DB",
                contrastText: "#000000",
                contrast: "#D3E1DB",
                light: "#D3E1DB",
            },
        },
    });

    const loadDataByFilter = async () => {
        setApiLoading(true);

        const response = await searchAutoComplete({
            params: {
                query: filterText || "",
            },
            searchParam: searchBy,
        });

        const fetchedOptions = response?.data
            ? response.data.map((item) => {
                  // Set option.name based on isWebsiteAutocomplete
                  const name = isWebsiteAutocomplete ? item.meta?.website || item.name : item.name;

                  return {
                      ...item,
                      name, // Ensure option.name is set correctly
                  };
              })
            : [];
        // Only add "Select All" if there are other options and multiple is true
        let newOptions = fetchedOptions;

        if (multiple && fetchedOptions.length > 0) {
            const selectAllOption = {
                name: "Select All",
                meta: {
                    id: "select-all",
                },
            };
            newOptions = [selectAllOption, ...fetchedOptions];
        }

        // Merge selectedOptions into options
        // const combinedOptions = [...selectedOptions, ...newOptions];

        // // Remove duplicates based on getOptionId
        // const uniqueOptions = combinedOptions.reduce(
        //   (acc, option) => {
        //     const id = getOptionId(option);
        //     if (!acc.map.has(id)) {
        //       acc.map.set(id, option);
        //       acc.list.push(option);
        //     }
        //     return acc;
        //   },
        //   { map: new Map(), list: [] }
        // ).list;

        // Merge selectedOptions into newOptions to ensure they are included
        const missingSelectedOptions = selectedOptions.filter(
            (selectedOption) => !newOptions.some((opt) => getOptionId(opt) === getOptionId(selectedOption))
        );

        if (missingSelectedOptions.length > 0) {
            newOptions = [...newOptions, ...missingSelectedOptions];
        }
        setOptions(newOptions);

        setApiLoading(false);
    };

    // Function to check if all options are selected
    const isAllSelected = () => {
        const selectableOptionIds = options.filter((option) => option.name !== "Select All").map(getOptionId);

        const selectedOptionIds = selectedOptions.map(getOptionId);

        return selectableOptionIds.length > 0 && selectableOptionIds.every((id) => selectedOptionIds.includes(id));
    };

    // Modify the handleChange function
    const handleChange = (event, newValue, reason) => {
        if (multiple) {
            if (newValue.some((option) => option.name === "Select All")) {
                if (!isAllSelected()) {
                    // Select all options except "Select All"
                    const allOptions = options.filter((option) => option.name !== "Select All");
                    setSelectedOptions(allOptions);
                    onNameFilterChange(allOptions.map((option) => option.name));
                } else {
                    // Deselect all
                    setSelectedOptions([]);
                    onNameFilterChange([]);
                }
            } else {
                // Remove "Select All" if present and update selected options
                const updatedOptions = newValue.filter((option) => option.name !== "Select All");

                setSelectedOptions(updatedOptions);
                onNameFilterChange(updatedOptions.map((option) => option.name));
            }
            // Clear the input field after selection
            setFilterText("");
        } else {
            // Single selection
            const selectedOption = newValue ? newValue : null;
            setSelectedOptions(selectedOption ? [selectedOption] : []);
            onNameFilterChange(selectedOption ? selectedOption.name : "");
            setFilterText(selectedOption ? selectedOption.name : "");
        }
    };

    return (
        <StyledAutocomplete
            multiple={multiple}
            sx={{ paddingY: 0 }}
            disableCloseOnSelect={multiple}
            value={multiple ? selectedOptions : selectedOptions[0] || null}
            options={options}
            disablePortal
            getOptionLabel={(option) => {
                if (typeof option === "string") {
                    return option;
                }
                return `${option.name}\u200B${option.meta?.id || ""}`;
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, option, { selected }) => {
                const isOptionSelected =
                    option.name === "Select All"
                        ? isAllSelected()
                        : selectedOptions.some((selectedOption) => getOptionId(selectedOption) === getOptionId(option));
                const { key, className, ...restProps } = props;
                return (
                    <>
                        <li
                            key={key}
                            {...restProps}
                            className={`${className} font-Inter hover:!bg-dgray-100 rounded-lg px-2 ml-2 mr-0.5 text-sm ${isOptionSelected ? "!bg-dgray-100 font-[600]" : "bg-transparent"}`}
                        >
                            {option.name}
                            {option?.count && <p className="ml-auto">({option?.count.toLocaleString("en-US")})</p>}
                        </li>
                    </>
                );
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
                if (isUseAPI && options.length === 0) {
                    loadDataByFilter();
                }
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={apiLoading}
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important" }}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {apiLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13 && filterText?.length > 3) {
                            loadDataByFilter();
                        }
                    }}
                />
            )}
            renderTags={(value, getTagProps) => {
                return selectedOptions?.map((option, index) => (
                    <ThemeProvider theme={theme} key={getOptionId(option)}>
                        <Chip
                            label={option?.name ?? option}
                            size="small"
                            color="primary"
                            {...getTagProps({ index })}
                            deleteIcon={
                                <ClearIcon
                                    sx={{
                                        color: "black", // Black cross
                                        backgroundColor: "transparent", // No background
                                        borderRadius: "50%",
                                        width: 15,
                                        height: 15,
                                    }}
                                />
                            }
                            sx={{
                                maxWidth: "100px",
                                height: "auto", // Allow height to grow
                                "& .MuiChip-deleteIcon": {
                                    margin: "0 8px 0 -4px",
                                },
                                "& .MuiChip-label": {
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontFamily: "Inter",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                    paddingRight: "12px",
                                    lineHeight: "1.2", // Add line height for wrapped text
                                },
                                borderRadius: "4px", // Border radius for Chip
                            }}
                        />
                    </ThemeProvider>
                ));
            }}
            onChange={handleChange}
            inputValue={filterText}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === "input" || reason === "clear") {
                    setFilterText(newInputValue);
                }
            }}
            // Modify just the filterOptions prop
            filterOptions={(options, state) => {
                // Return all options without filtering when we have API results
                if (apiLoading || options.length > initialOptions.length) {
                    return options;
                }

                const inputValue = state.inputValue.toLowerCase();
                return options.filter((option) => {
                    if (option.name === "Select All") {
                        return true;
                    }
                    const optionLabel = option.name.toLowerCase();
                    return optionLabel.includes(inputValue);
                });
            }}
        />
    );
}
