import React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { getShortProfileName } from "@/utils/common";

export const SidebarUserItem = ({ label, open, setOpen, authContext, onClick = () => {} }) => {
    // Handler for clicking on the avatar (to open a profile popup, for example)
    const handleAvatarClick = (event) => {
        event.stopPropagation();
        onClick(event);
    };

    // Handler for toggling the sidebar
    const handleToggleClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    // Define a hover style for the toggle icon container
    const iconHoverStyle = {
        // Change the SVG fill color on hover
        "& svg rect": {
            fill: "#e6e6e6", // Darker fill color on hover
        },
    };

    return (
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                disableRipple
                sx={{
                    display: "flex",
                    flexDirection: open ? "row" : "column",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                    borderRadius: 2,
                    padding: "4px 8px",
                    marginTop: "24px",
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "transparent" },
                }}
            >
                {/* User Avatar */}
                <div
                    className="font-Inter shrink-0 inline-flex h-[24px] w-[24px] items-center justify-center rounded-full bg-[#2D7A89] text-[14px] font-semibold text-white mui-icon"
                    onClick={handleAvatarClick}
                >
                    {getShortProfileName(authContext?.userInfo?.userName)}
                </div>
                {/* Username text */}
                <ListItemText
                    className="!font-Inter mui-item-text !text-[20px] text-[#7E7E7E] flex"
                    primary={label}
                    primaryTypographyProps={{
                        style: {
                            fontWeight: 400,
                            fontFamily: "Inter",
                            fontSize: "14px",
                            margin: 0,
                        },
                    }}
                    sx={{
                        opacity: open ? 1 : 0,
                        transition: "opacity 150ms",
                        width: open ? "auto" : 0,
                    }}
                />
                {/* Toggle Arrows with a refined hover effect */}
                {open ? (
                    <Box
                        onClick={handleToggleClick}
                        sx={{
                            fontSize: 16,
                            cursor: "pointer",
                            borderRadius: "50%",
                            p: "2px",
                            ":hover": iconHoverStyle,
                        }}
                    >
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" fill="#F4F3F0" />
                            <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="#888888" />
                            <path
                                d="M12.9688 16L7.96875 11L12.9688 6L14.0312 7.0625L10.0938 11L14.0312 14.9375L12.9688 16Z"
                                fill="#888888"
                            />
                        </svg>
                    </Box>
                ) : (
                    <Box
                        onClick={handleToggleClick}
                        sx={{
                            fontSize: 16,
                            cursor: "pointer",
                            borderRadius: "50%",
                            p: "2px",
                            ":hover": iconHoverStyle,
                        }}
                    >
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
                            <rect x="1" y="0.5" width="21" height="21" rx="10.5" fill="#F4F3F0" />
                            <rect x="1" y="0.5" width="21" height="21" rx="10.5" stroke="#888888" />
                            <path
                                d="M9.53125 6L14.5312 11L9.53125 16L8.46875 14.9375L12.4062 11L8.46875 7.0625L9.53125 6Z"
                                fill="#888888"
                            />
                        </svg>
                    </Box>
                )}
            </ListItemButton>
        </ListItem>
    );
};
