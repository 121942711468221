import { useEffect, useMemo, useState } from "react";
import { FilterSvg, ReloadEmails, CampaignIcon, EmailAccountIcon, DateRangeIcon } from "@/components/icons/InboxIcon";
import Pagination from "@mui/material/Pagination";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import { PaginationItem } from "@mui/material";
import DateRange from "@/components/common/DateRange";
import RelativeModal from "../inbox/RelativeModal";

export const AllChats = ({
    refetchChatsList,
    allCampaigns,
    allLinkedinAccounts,
    chatsData,
    setActiveChatId,
    setSingleChatGeneralData,
    isLoading,
    setComposeNewChatOpen,
}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [isCampaignDropdownOpen, setCampaignDropdownOpen] = useState(false);
    const [isAccountDropdownOpen, setAccountDropdownOpen] = useState(false);
    const [chosenCampaignId, setChosenCampaignId] = useState(null);
    const [chosenLinkedinAccount, setChosenLinkedinAccount] = useState(null);
    const [chosenDateRange, setChosenDateRange] = useState(null);
    const [filteredChats, setFilteredChats] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [imageLoadStatus, setImageLoadStatus] = useState({});
    const itemsPerPage = 5;

    const applyFilters = () => {
        const account = allLinkedinAccounts.find((a) => a.email === chosenLinkedinAccount);
        const [startDate, endDate] = chosenDateRange || [];

        const normalizeEndDate = (date) => {
            const normalized = new Date(date);
            normalized.setHours(23, 59, 59, 999); // Set time to the end of the day
            return normalized;
        };

        const normalizeStartDate = (date) => {
            const normalized = new Date(date);
            normalized.setHours(0, 0, 0, 0); // Set time to midnight
            return normalized;
        };

        const filtered = chatsData.filter((chat) => {
            const matchesAccount = !account || chat.accountId === account.accountId;
            const matchesCampaign = !chosenCampaignId || chat.campaignId === chosenCampaignId;

            const matchesDateRange =
                !startDate ||
                !endDate ||
                (new Date(chat.timestamp) >= normalizeStartDate(new Date(startDate)) &&
                    new Date(chat.timestamp) <= normalizeEndDate(new Date(endDate)));

            return matchesAccount && matchesCampaign && matchesDateRange;
        });

        setFilteredChats(filtered);
        setOpenFilter(false);
    };

    const getChosenCampaignName = () => {
        const chosenCampaign = allCampaigns?.find((campaign) => campaign._id === chosenCampaignId);
        return chosenCampaign?.campaignName;
    };

    const handleImageLoad = (id, status) => {
        setImageLoadStatus((prev) => ({
            ...prev,
            [id]: status, // Update the status of the specific image
        }));
    };

    useEffect(() => {
        if (chatsData?.length > 0) {
            setFilteredChats(chatsData);
        }
    }, [chatsData]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredChats.slice(startIndex, endIndex); // Slice data for the current page
    }, [currentPage, filteredChats]);

    return (
        <div className="mb-4 h-full border-r border-[#E7E7E7] sm:mb-1 col-span-2">
            <div className="border-b border-[#E7E7E7] px-[24px] h-[76px] flex items-center justify-between">
                <span className="text-[#050505] text-[14px] font-medium font-Inter">InMail</span>
                {/* <span onClick={() => setOpenFilter(true)}><FilterSvg /></span> */}
                <div className="flex gap-[16px] items-center">
                    <button className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center" onClick={refetchChatsList}>
                        <ReloadEmails />
                    </button>
                    <div className="relative w-[20px] h-[20px] flex items-center justify-center">
                        <button className="cursor-pointer" onClick={() => setOpenFilter((prev) => !prev)}>
                            <FilterSvg />
                        </button>
                        <RelativeModal open={openFilter} onClose={() => setOpenFilter(false)} className="min-w-[377px] inbox">
                            <div className="px-[24px] py-[16px] w-full flex items-center justify-between">
                                <span className="text-[#050505] text-[14px] font-semibold font-Inter">Filter Options</span>
                                <button
                                    className="cursor-pointer text-[#2D7A89] text-[14px] font-medium font-Inter"
                                    onClick={() => {
                                        setChosenCampaignId(null);
                                        setChosenLinkedinAccount(null);
                                        setChosenDateRange(null);
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                            <div className="flex flex-col items-center justify-center gap-4 pt-[24px]">
                                <div className="w-full px-[24px]">
                                    <div className="mb-2 flex items-center gap-[8px]">
                                        <CampaignIcon />
                                        <p className="text-[14px] font-medium font-Inter ">Email Campaign</p>
                                    </div>
                                    <div className="relative w-full">
                                        <div
                                            className="border rounded-md px-3 pt-2 pb-3 flex justify-between items-center cursor-pointer"
                                            style={{ borderColor: "#E8E7E7" }}
                                            onClick={() => setCampaignDropdownOpen((prev) => !prev)}
                                        >
                                            <span className="text-gray-700 font-Inter text-[14px]">{getChosenCampaignName()}</span>
                                            <svg
                                                className="h-5 w-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        {isCampaignDropdownOpen && (
                                            <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg z-10">
                                                {allCampaigns.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-Inter text-[14px]"
                                                        onClick={() => {
                                                            setChosenCampaignId(option?._id);
                                                            setCampaignDropdownOpen(false);
                                                        }}
                                                    >
                                                        {option?.campaignName}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full px-[24px]">
                                    <div className="mb-2 flex items-center gap-[8px]">
                                        <EmailAccountIcon />
                                        <p className="text-[14px] font-medium font-Inter ">Linkedin Account</p>
                                    </div>
                                    <div className="relative w-full">
                                        <div
                                            className="border rounded-md px-3 pt-2 pb-3 flex justify-between items-center cursor-pointer"
                                            style={{ borderColor: "#E8E7E7" }}
                                            onClick={() => setAccountDropdownOpen((prev) => !prev)}
                                        >
                                            <span className="text-gray-700 font-Inter text-[14px]">{chosenLinkedinAccount}</span>
                                            <svg
                                                className="h-5 w-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        {isAccountDropdownOpen && (
                                            <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg z-10">
                                                {allLinkedinAccounts.map(
                                                    (option, index) =>
                                                        option?.email && (
                                                            <li
                                                                key={index}
                                                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-Inter text-[14px]"
                                                                onClick={() => {
                                                                    setChosenLinkedinAccount(option?.email);
                                                                    setAccountDropdownOpen(false);
                                                                }}
                                                            >
                                                                {option?.email}
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full px-[24px]">
                                    <div className="mb-2 flex items-center gap-[8px]">
                                        <DateRangeIcon />
                                        <p className="text-[14px] font-medium font-Inter ">Reply Date Range</p>
                                    </div>
                                    <DateRange value={chosenDateRange} onChange={(value) => setChosenDateRange(value)} downIcon={true} />
                                </div>
                                <div className="w-full p-[16px]">
                                    <button
                                        className="capitalize w-full bg-[#050505] h-[48px] px-[24px] py-[12px] rounded-[8px] hover:opacity-80 transition-all text-white text-inter text-[14px] font-medium"
                                        onClick={applyFilters}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </RelativeModal>
                    </div>
                </div>
            </div>
            <div className="mailList_container_small">
                {paginatedData?.length > 0 &&
                    paginatedData?.map((chat, ind) => {
                        return (
                            <div
                                className={`p-[24px] flex gap-[8px] w-full cursor-pointer border-b border-[#E8E7E7] hover:bg-[#F4F3F0] ${chat?.unreadMessagesCount <= 0 ? "bg-[#F4F3F0]" : "bg-white"}`}
                                key={ind}
                                onClick={() => {
                                    setActiveChatId(chat?.chatId);
                                    setSingleChatGeneralData({
                                        senderProfileUrl: chat?.senderProfileUrl,
                                        senderProfilePicture: chat?.senderProfilePicture,
                                        senderName: chat?.senderName,
                                        bgColor: chat?.bgColor,
                                        subject: chat?.subject,
                                        chatName: chat?.chatName,
                                    });
                                    setComposeNewChatOpen(false);
                                }}
                            >
                                <div className="flex h-[28px] w-[28px] shrink-0 rounded-full overflow-hidden">
                                    {chat?.senderProfilePicture && imageLoadStatus[chat.chatId] !== false ? (
                                        <img
                                            src={chat.senderProfilePicture}
                                            alt="Profile"
                                            className="relative object-contain object-center"
                                            onLoad={() => handleImageLoad(chat.chatId, true)}
                                            onError={() => handleImageLoad(chat.chatId, false)}
                                            onClick={() => chat?.senderProfileUrl && window.open(chat.senderProfileUrl, "_blank")}
                                            style={{
                                                display: imageLoadStatus[chat.chatId] ? "block" : "none",
                                            }}
                                        />
                                    ) : (
                                        <span
                                            onClick={() => chat?.senderProfileUrl && window.open(chat.senderProfileUrl, "_blank")}
                                            className="w-[28px] h-[28px] flex items-center justify-center capitalize text-[12px] text-accents-mainBlack"
                                            style={{
                                                backgroundColor: chat?.bgColor,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            {chat?.senderName?.charAt(0)?.toUpperCase() ?? ""}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col gap-[8px] grow">
                                    {chat?.senderName && (
                                        <div className="h-[28px] flex items-center">
                                            <p
                                                className={`${chat?.isRead ? "text-[14px] font-medium font-Inter text-[#050505]" : "sender"}`}
                                            >
                                                {chat?.senderName || ""}
                                            </p>
                                        </div>
                                    )}
                                    <div className="min-h-[28px] flex items-center">
                                        <p className="text-[14px] font-medium font-Inter text-[#050505] title">{chat?.subject || ""}</p>
                                    </div>
                                </div>
                                <div
                                    className={`flex flex-col items-end ${chat?.unreadMessagesCount > 0 ? "justify-between" : "justify-end"}`}
                                >
                                    {chat?.unreadMessagesCount > 0 && (
                                        <div className="flex rounded-[50%] w-[21px] h-[21px] justify-center items-center bg-[#2D7A89] mr-3 text-white text-[12px] font-semibold">
                                            <p>{chat.unreadMessagesCount}</p>
                                        </div>
                                    )}
                                    <span className="text-[#5D5D5D] text-[12px] font-Inter self-end">{chat?.sentDateAndTime || ""}</span>
                                </div>
                            </div>
                        );
                    })}
                {!isLoading && filteredChats?.length <= 0 && <p className="p-[24px] text-[12px] font-Inter">No messages found</p>}
            </div>
            {!isLoading && filteredChats?.length > itemsPerPage && (
                <div className="flex w-full justify-center py-4">
                    <Pagination
                        className="font-Inter"
                        count={Math.ceil(filteredChats?.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, newPage) => {
                            setCurrentPage(newPage);
                        }}
                        showFirstButton
                        showLastButton
                        renderItem={(item) => <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-text": {
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: 1.43,
                                color: "#090C05",
                            },
                            "& .MuiPaginationItem-text.Mui-selected": {
                                color: "#fff",
                                backgroundColor: "black",
                                "&:hover": {
                                    backgroundColor: "gray",
                                },
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
};
