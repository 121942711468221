import React from "react";

function Description() {
    return (
        <article className="mt-16 px-6 w-full text-center">
            <h2 className=" font-semibold">Uncover Direct Contact Information for B2B Professionals</h2>
            <div className="mt-4 font-medium text-sm text-dgray-6 sm:w-3/5 mx-auto">
                <p>
                    Easily find email addresses, phone numbers, and other key details using our powerful search platform. Access a
                    comprehensive database of over 800 million professional profiles, providing you with unrivaled direct contact
                    information.
                </p>
            </div>
        </article>
    );
}

export default Description;
