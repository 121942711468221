import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";

import { pageContentWidth } from "@/utils/common";
import useStripe from "@/hooks/useStripe";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useQuery } from "@tanstack/react-query";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import { UserSlider } from "@/components/usersslider/UserSlider";

import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import BackIcon from "@/assets/image/crm/transcript/back-arrow.svg";
import { ReactSVG } from "react-svg";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import useBillingStore from "@/store/useBillingStore";
import BackdropLoader from "@/components/common/BackdropLoader";
import { ReactComponent as StripeIcon } from "@/assets/image/logos/stripe.svg";
import MainTitleBar from "@/components/MainTitleBar";
import useKompassRouter from "@/hooks/useKompassRouter";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function BillingPayScene() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { selectedPrices, users } = useBillingStore();
    const [clientSecret, setClientSecret] = useState("");
    const [id, setId] = useState(0);
    const { kompassNavigate } = useKompassRouter();
    const [stripeError, setStripeError] = useState("");

    const { getPaymentLink } = useStripe();

    //WARN: if index file has React.StrictMode wrapper in it, this will break, as it would try to do it twice
    //and doing that breaks the Stripe embed, shouldn't affect production, as it's turned off by default in build
    const loadPaymentLink = async () => {
        setLoading(true);
        setStripeError("");
        if (selectedPrices) {
            const filteredPrices = Object.values(selectedPrices).filter((t) => !!t?.lookup_key);
            let res = await getPaymentLink(filteredPrices, users);
            if (!res.status) {
                setLoading(false);
                setStripeError(res.message);
            }
            if (res?.data?.client_secret) {
                setLoading(false);
                setClientSecret((cs) => {
                    if (cs) {
                        window.clearTimeout(id);
                        setId(
                            setTimeout(() => {
                                setClientSecret(res?.data?.client_secret);
                            }, 500)
                        );
                        return "";
                    }
                    return res?.data?.client_secret;
                });
            }
            setLoading(false);
        }
    };

    const subscriptionInfoFunction = useHTTPRequest(`/billing/getSubscriptionInfo`);

    useQuery({
        queryKey: ["Subscription"],
        queryFn: () => subscriptionInfoFunction(),
    });

    useEffect(() => {
        loadPaymentLink();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, selectedPrices]);

    //Extra precautions against multiple Stripe forms
    useEffect(() => {
        setClientSecret("");
        return () => {
            setClientSecret("");
            window.clearTimeout(id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const redirectToSuccessPage = () => {
        navigate("/home");
    };
    return (
        <MainLayout containerClassName="items-center">
            <MainTitleBar>
                <p>Payment</p>
            </MainTitleBar>

            {loading && <BackdropLoader active={true}></BackdropLoader>}
            <div className="flex w-full px-6">
                <button
                    onClick={() => kompassNavigate(-1)}
                    className="flex items-center text-primary-teal text-sm py-1 px-2 mt-5 rounded-lg hover:bg-primary-header"
                >
                    <ChevronLeftOutlinedIcon color="inherit" />
                    Back
                </button>
            </div>

            <div className="px-6 w-full">
                <UserSlider />
            </div>

            {!stripeError.length && clientSecret && (
                <div className="my-10 flex h-full w-full items-center bg-white">
                    <BaseContainer width={pageContentWidth}>
                        <div id="checkout">
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                                <EmbeddedCheckout onSuccess={() => redirectToSuccessPage()} successUrl={`${window.location.origin}/home`} />
                            </EmbeddedCheckoutProvider>
                        </div>
                    </BaseContainer>
                </div>
            )}
            {!stripeError && !clientSecret && (
                <div style={{ maxWidth: pageContentWidth }} className={`mx-8 mt-8 flex h-[600px] w-full items-center justify-center px-5`}>
                    <div className={`flex h-full w-full items-center justify-center rounded-lg border-1 border-stone-250`}>
                        <StripeIcon></StripeIcon>
                    </div>
                </div>
            )}
            {(stripeError.length || !selectedPrices) && (
                <div className="flex w-full flex-col items-center justify-center">
                    <div className="my-24 flex max-w-[500px] flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                        <div className="font-Inter flex flex-col self-stretch text-center text-[16px] lg:text-[20px] font-extrabold">
                            Sorry, we got problems
                        </div>
                        <div className="font-Inter flex-wrap text-center text-[16px] lg:text-[20px]">{stripeError}</div>
                        <div className="mt-10 text-center">
                            <Button
                                onClick={() => navigate(-1)}
                                className="font-Inter h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-[16px] lg:text-[20px] font-medium capitalize text-white"
                            >
                                Go back
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </MainLayout>
    );
}
