import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { CircularProgress } from "@mui/material";
import SelectDropdown from "@/components/common/SelectDropdown";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { ReactComponent as LockSVGIcon } from "@/assets/image/icons/icon-lock.svg";
import { ReactComponent as PersonSVGIcon } from "@/assets/image/icons/icon-account-circle.svg";
import { ReactComponent as DeleteSVGIcon } from "@/assets/image/icons/icon-delete.svg";
import { ReactComponent as EditIcon } from "@/assets/image/profile/edit.svg";
import useAuth from "@/hooks/useAuth";
import * as Constants from "@/utils/constants";
import { levelList, industryList, emailEnrichmentPreferencesList } from "@/utils/filter";
import { useAuthContext } from "@/context/AuthContext";
import MainLoader from "../MainLoader";
import ChangePreferencesDialog from "./ChangePreferencesDialog";

export default function ProfileTabComponent() {
    const { getUserInfo, updateBasicInfo, deleteAccount } = useAuth();
    const getUserInfoRef = useRef();
    getUserInfoRef.current = getUserInfo;
    const authContext = useAuthContext();
    const [initialValues, setInitialValues] = useState(null);
    const [isEditable, setEditable] = useState(false);
    const [isVisibleResetPassword, setVisibleResetPassword] = useState(false);
    const [isVisibleDeleteAccount, setVisibleDeleteAccount] = useState(false);
    const [isVisibleChangePreferences, setVisibleChangePreferences] = useState(false);
    const [emailEnrichmentPreference, setEmailEnrichmentPreference] = useState(null);
    const [isDeleted, setDeleteAccount] = useState(false);
    const validationSchema = Yup.object().shape({
        userName: Yup.string(),
        mobile: Yup.string(),
        jobRole: Yup.string(),
        industry: Yup.string(),
    });
    const obfuscateEmail = (email) => {
        if (!email) return "";
        const parts = email.split("@");
        if (parts.length > 1) {
            const obfuscatedPart = parts[0].charAt(0) + parts[0].slice(1).replace(/./g, "*");
            return obfuscatedPart + "@" + parts[1];
        }
        return email;
    };

    useEffect(() => {
        getUserInfoRef
            .current()
            .then((response) => {
                setInitialValues(response?.userInfo);
            })
            .catch((error) => {
                toast.error(error?.message, { theme: "colored" });
            });
    }, []);

    useEffect(() => {
        if (!initialValues) return;
        const { arePersonalEmailsPriority, databaseType } = initialValues;
        if (arePersonalEmailsPriority) {
            setEmailEnrichmentPreference("personal_premium");
        } else if (databaseType === "premium") {
            setEmailEnrichmentPreference("work_premium");
        } else {
            setEmailEnrichmentPreference("work_basic");
        }
    }, [initialValues]);

    const handleUpdateBasicInfo = async (values, { setSubmitting }) => {
        if (!isEditable) return;

        setSubmitting(true);

        await updateBasicInfo({
            userName: values["userName"],
            mobile: values["mobile"],
            jobRole: values["jobRole"],
            industry: values["industry"],
        });
        await authContext.setAttribute({
            Name: "custom:userName",
            Value: values["userName"],
        });

        toast.info(Constants.KompassString.SettingChangedSuccessfully, {
            theme: "colored",
            hideProgressBar: false,
        });
        setTimeout(() => {
            authContext.signOut();
        }, 1000 * 5);

        setSubmitting(false);

        setEditable(false);
    };

    const handleDeleteAccount = async () => {
        setVisibleDeleteAccount(false);
        setDeleteAccount(true);

        try {
            await deleteAccount();
            await authContext.signOut();
        } catch (error) {
            toast.error(error?.message, { theme: "colored" });
        }

        setDeleteAccount(false);
    };

    const handleUpdateEmailEnrichmentPreference = async () => {
        try {
            let params;

            if (emailEnrichmentPreference === "personal_premium") {
                params = {
                    arePersonalEmailsPriority: true,
                    databaseType: "premium",
                };
            } else if (emailEnrichmentPreference === "work_premium") {
                params = {
                    arePersonalEmailsPriority: false,
                    databaseType: "premium",
                };
            } else if (emailEnrichmentPreference === "work_basic") {
                params = {
                    arePersonalEmailsPriority: false,
                    databaseType: "basic",
                };
            }

            await updateBasicInfo(params);
            setVisibleChangePreferences(false);

            toast.info("Your email enrichment preferences has been changed", {
                theme: "colored",
                hideProgressBar: false,
            });
        } catch (error) {
            toast.error(error?.message, { theme: "colored" });
        }
    };

    return (
        <>
            {initialValues && (
                <>
                    <div className="p-6 flex gap-8 w-full flex-col text-gray-950 font-Inter">
                        <div className="flex flex-col gap-4">
                            <div className="text-[#050505] text-base font-['Inter'] font-semibold">Profile</div>
                            <div className="text-sm text-stone-350 ">Manage your profile and preferences here</div>
                        </div>
                        <div className="flex w-full flex-row justify-between ">
                            <div className="flex w-[50%] flex-col gap-8">
                                <div className="flex flex-col gap-4">
                                    <div className="flex w-full flex-col gap-8 items-center justify-start rounded-xl border border-stone-250 p-6">
                                        <div className="flex w-full flex-row items-center gap-4">
                                            {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150">
                                        <LockSVGIcon className="h-5 w-5 fill-current text-blue-500" />
                                    </div> */}
                                            <div className="flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                                <div className="text-[#050505] font-['Inter'] text-base font-semibold">Account Details</div>
                                                {/* <div className="text-sm text-stone-350">
                                            {initialValues?.isGoogleAuth && !initialValues?.isPasswordFilled ? (
                                                <p>
                                                    Your account is currently registered with a Google account. If you want to add a
                                                    password, please use the 'Add Password' feature.
                                                </p>
                                            ) : (
                                                <p>This is how you access your account</p>
                                            )}
                                        </div> */}
                                            </div>
                                        </div>
                                        <div className="flex w-full flex-col items-start justify-start gap-6">
                                            <div className="flex w-full flex-col items-start justify-center gap-2">
                                                {/* <div className="inline-flex items-center justify-between self-stretch">
                                            <p className="font-Inter text-base font-medium">Email address</p>
                                            <p className="font-Inter text-sm text-stone-350 text-right">You will use it to login</p>
                                        </div> */}
                                                <div className={`inline-flex items-center justify-start gap-2 self-stretch bg-white `}>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        className="font-Inter leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light"
                                                        placeholder="anna.a@pinedev.eu"
                                                        disabled={true}
                                                        value={obfuscateEmail(initialValues?.email)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex w-full flex-col items-start justify-center gap-2">
                                                <div className="inline-flex items-center justify-between self-stretch">
                                                    {/* <p className="font-Inter text-base font-medium">Password</p> */}
                                                </div>
                                                <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="font-Inter shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light"
                                                        placeholder="Minimum of 6 characters"
                                                        disabled={true}
                                                        value={"********"}
                                                    />
                                                </div>
                                                <div className="inline-flex gap-3 justify-end w-full">
                                                    <div className="inline-flex gap-2.5 px-2">
                                                        <button
                                                            className={`font-Inter text-sm text-[#2d7a89] hover:text-accents-mainBlack transition-colors`}
                                                            onClick={() => setVisibleResetPassword(true)}
                                                        >
                                                            {initialValues?.isGoogleAuth && !initialValues?.isPasswordFilled
                                                                ? `Add Password`
                                                                : `Update Password`}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full rounded-xl border border-stone-250 bg-white p-6">
                                        <div className="flex w-full flex-col xs:flex-col xs:justify-between xs:items-start gap-4">
                                            <div className="flex flex-row items-center justify-between w-full">
                                                <div className="flex gap-4">
                                                    {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150">
                                                        <PersonSVGIcon className=" h-5 w-5 fill-current text-blue-500" />
                                                    </div> */}
                                                    <div className="flex shrink grow basis-0 flex-col">
                                                        <div className="text-[#050505] text-base font-['Inter'] font-semibold">
                                                            Enrichment preferences
                                                        </div>
                                                        {/* <p className="text-sm text-stone-350">Set your email enrichment preferences here</p> */}
                                                    </div>
                                                </div>
                                                <button
                                                    className={`rounded-[8px] bg-[#050505] px-[12px] py-[6px] text-sm font-medium text-white hover:opacity-80 transition-opacity`}
                                                    onClick={() => setVisibleChangePreferences(true)}
                                                >
                                                    Set new preferences
                                                </button>
                                            </div>
                                            <SelectDropdown
                                                className="w-full border border-stone-250 h-[36px] rounded-[8px]"
                                                options={emailEnrichmentPreferencesList}
                                                value={emailEnrichmentPreference}
                                                onChange={(value) => {
                                                    setEmailEnrichmentPreference(value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full rounded-xl border border-stone-250 bg-white p-6">
                                        <div className="flex w-full flex-col xs:flex-row xs:justify-between xs:items-center gap-4">
                                            <div className="flex gap-4">
                                                {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150">
                                                    <DeleteSVGIcon className=" h-5 w-5 fill-current text-blue-500" />
                                                </div> */}
                                                <div className="flex w-full shrink grow basis-0 flex-col">
                                                    <div className="text-[#050505] text-base font-['Inter'] font-semibold">
                                                        Delete account
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className={`rounded-[8px] bg-[#050505] px-[12px] py-[6px] text-sm font-medium text-white hover:opacity-80 transition-opacity`}
                                                onClick={() => setVisibleDeleteAccount(true)}
                                                disabled={isDeleted}
                                            >
                                                {isDeleted ? <CircularProgress size={10} /> : null}
                                                Delete account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUpdateBasicInfo}>
                                {({ setFieldValue, values, isSubmitting }) => (
                                    <Form className="flex w-[47%] flex-col">
                                        <div className="flex w-full flex-col items-center justify-start gap-8 rounded-xl border border-stone-250 bg-white p-6">
                                            <div className="flex w-full flex-row items-center gap-4">
                                                {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150">
                                                    <PersonSVGIcon className="h-5 w-5 fill-current text-blue-500" />
                                                </div> */}
                                                <div className="flex grow flex-col">
                                                    <div className="text-[#050505] text-base font-semibold font-['Inter']">Basic Info</div>
                                                    {/* <p className="text-sm text-stone-350">Update your personal information here</p> */}
                                                </div>
                                                <div className="inline-flex gap-2.5 px-2">
                                                    <button
                                                        type="button"
                                                        className={`font-Inter leading-tight inline-flex text-base font-normal text-blue-500 ${isEditable ? "hidden" : ""}`}
                                                        onClick={() => setEditable(true)}
                                                    >
                                                        <EditIcon className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className={`font-Inter leading-tight inline-flex text-base font-normal text-blue-500 ${isEditable ? "" : "hidden"}`}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? <CircularProgress size={20} /> : null}
                                                        <p className="ml-1">Save changes</p>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex w-full flex-col items-start justify-start gap-6">
                                                <div className="flex w-full flex-col items-start justify-center gap-2">
                                                    {/* <p className="font-Inter text-base font-medium">Full name</p> */}
                                                    <div className="inline-flex w-full items-center justify-start gap-2 self-stretch bg-white">
                                                        <Field
                                                            type="text"
                                                            name="userName"
                                                            className="font-Inter w-full shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light"
                                                            placeholder="John Doe"
                                                            disabled={!isEditable || isSubmitting}
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="userName"
                                                        component="div"
                                                        className="font-Inter leading-tight text-sm font-normal text-red-500"
                                                    />
                                                </div>
                                                <div className="flex w-full  flex-col items-start justify-center gap-2">
                                                    {/* <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between self-stretch">
                                                        <p className="text-base font-medium">Contact number</p>
                                                        <p className="text-sm text-stone-350">
                                                            Used for communication regarding your account
                                                        </p>
                                                    </div> */}
                                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                                        <PhoneInput
                                                            className="font-Inter leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-1 text-sm font-light"
                                                            placeholder="Enter phone number"
                                                            inputClass="!h-[30px]"
                                                            value={values?.mobile}
                                                            defaultValue={initialValues?.mobile}
                                                            onChange={(value) => {
                                                                setFieldValue("mobile", value);
                                                            }}
                                                            disabled={!isEditable || isSubmitting}
                                                            addInternationalOption={false}
                                                            onlyCountries={["us"]}
                                                            country={"us"}
                                                            countryCodeEditable={false}
                                                        />
                                                    </div>
                                                    <ErrorMessage
                                                        name="contactMobile"
                                                        component="div"
                                                        className="font-Inter leading-tight text-sm font-normal text-red-500"
                                                    />
                                                </div>
                                                <div className="flex w-full flex-col items-start justify-center gap-2 ">
                                                    <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between self-stretch">
                                                        <div className="text-[#050505] text-base font-semibold font-['Inter']">
                                                            Job Level
                                                        </div>
                                                    </div>

                                                    <SelectDropdown
                                                        className="w-full border border-stone-250 h-[36px] rounded-[8px]"
                                                        options={levelList}
                                                        defaultValue={initialValues?.jobRole ?? ""}
                                                        selectedOption={values?.jobRole}
                                                        onChange={(value) => {
                                                            setFieldValue("jobRole", value);
                                                        }}
                                                        disabled={!isEditable || isSubmitting}
                                                    />
                                                </div>
                                                <div className="flex w-full flex-col items-start justify-center gap-2">
                                                    <div className="flex flex-col xs:flex-row xs:items-center xs:justify-between self-stretch">
                                                        <div className="text-[#050505] text-base font-semibold font-['Inter']">
                                                            Industry
                                                        </div>
                                                    </div>

                                                    <SelectDropdown
                                                        className="w-full border border-stone-250 h-[36px] rounded-[8px]"
                                                        options={industryList}
                                                        defaultValue={initialValues?.industry ?? ""}
                                                        selectedOption={values?.industry}
                                                        onChange={(value) => setFieldValue("industry", value)}
                                                        disabled={!isEditable || isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <ChangePasswordDialog
                        isGoogleAuth={initialValues?.isGoogleAuth}
                        isPasswordFilled={initialValues?.isPasswordFilled}
                        open={isVisibleResetPassword}
                        close={() => {
                            setVisibleResetPassword(false);
                        }}
                    />

                    <ConfirmDeleteDialog
                        open={isVisibleDeleteAccount}
                        handleNo={() => setVisibleDeleteAccount(false)}
                        handleYes={handleDeleteAccount}
                    />

                    <ChangePreferencesDialog
                        open={isVisibleChangePreferences}
                        handleNo={() => setVisibleChangePreferences(false)}
                        handleYes={handleUpdateEmailEnrichmentPreference}
                    />
                </>
            )}
            {!initialValues && (
                <div className="w-full main-loader-wrapper relative">
                    <MainLoader isAllPage={false} />
                </div>
            )}
        </>
    );
}
