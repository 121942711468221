import { React } from "react";
import cn from "classnames";

const badgeTheme = (theme) => {
    const className = cn(
        "relative w-fit items-center gap-1 relative flex rounded-[30px] text-sm py-[3.5px] px-[10px]",
        theme === "Rejected" && "bg-[#FFE0D1] text-[#B86C49]",
        (theme === "Created" || theme === "Updated") && "bg-[#C9F3D4] text-[#2A763D]",
        theme === "Recreated" && "bg-[#FDF8D1] text-[#A19642]"
        // theme === "info" && "bg-[#DCEAFE] text-[#4873FA]",
    );
    return className;
};

function StatusBadge({ status, text }) {
    return (
        <div className="m-auto font-InterMedium flex w-full min-w-fit max-w-[106px] justify-center items-start text-[12px]">
            <div className={badgeTheme(status)}>{text}</div>
        </div>
    );
}

export default StatusBadge;
