import React, { useRef, useState } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EditTaskPopover from "./EditTaskPopover";
import dayjs from "dayjs";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import useTasks from "@/hooks/crm/useTasks";
import { toast } from "react-toastify";

const TaskCard = ({ task, setRefreshUI }) => {
    const [open, setOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { deleteTask } = useTasks();
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        setOpen(false);
    };

    const getStatusStyles = (status) => {
        switch (status) {
            case "To Do":
                return "text-[#2E3DAA] bg-[#ECEEFF]";
            case "In Progress":
                return "text-[#FF782C] bg-[#FFF6E5]";
            case "Done":
                return "text-[#2D7A89] bg-[#E1ECEE]";
            default:
                return "text-gray-600 bg-gray-100";
        }
    };

    const statusIcons = {
        "To Do": <FormatListBulletedIcon className="!w-4" />,
        "In Progress": <ChecklistIcon className="!w-4" />,
        Done: <CheckCircleOutlineOutlinedIcon className="!w-4" />,
    };

    const handleDeleteTask = async (task) => {
        setDeleteLoading(true);
        try {
            await deleteTask(task._id);
            setRefreshUI((prev) => !prev);
            toast.success("Task deleted successfully");
        } catch (error) {
            console.log(error);
            toast.error("Failed to delete task");
        }
        setDeleteLoading(false);
    };

    return (
        <div className="space-y-2 border border-stone-250 rounded-[8px] p-5">
            {/* Header */}
            <div className="flex items-center mb-3 gap-2">
                <p className="font-medium text-[18px] break-all">{task.title}</p>
                <button ref={anchorRef} onClick={() => setOpen(true)} aria-label={`Edit task: ${task.title}`} className="ml-auto">
                    <BorderColorIcon fontSize="small" className="text-dgray-400 cursor-pointer hover:text-gray-500" />
                </button>
                <button onClick={() => handleDeleteTask(task)} className="text-dgray-400" disabled={deleteLoading}>
                    {deleteLoading ? (
                        <CircularProgress size={16} color="inherit" />
                    ) : (
                        <DeleteOutlineOutlinedIcon fontSize="small" className="text-dgray-400 cursor-pointer hover:text-gray-500" />
                    )}
                </button>
                <EditTaskPopover setRefreshUI={setRefreshUI} taskData={task} handleClose={handleClose} open={open} anchorRef={anchorRef} />
            </div>

            {/* Task Status */}
            {task.status && (
                <div className="flex items-center">
                    <p className="text-dgray-500">Status:</p>
                    <div
                        className={`ml-3 py-0.5 px-2 flex items-center gap-2 !text-xs font-medium rounded-lg border ${getStatusStyles(
                            task.status
                        )}`}
                    >
                        {statusIcons[task.status]}
                        {task.status}
                    </div>
                </div>
            )}

            {/* Due Date */}
            {task.dueDate && (
                <div className="flex items-center">
                    <p className="text-dgray-500">Due Date:</p>
                    <p className="ml-3">{dayjs(task.dueDate).format("D MMMM, YYYY")}</p>
                </div>
            )}

            {/* Assignee */}
            {task.assignedTo?.name && (
                <div className="flex items-center">
                    <p className="text-dgray-500">Assignee:</p>
                    <div className="ml-3 flex items-center">
                        <p>{task.assignedTo?.name}</p>
                    </div>
                </div>
            )}

            {/* Creation Date */}
            <div className="flex items-center">
                <p className="text-dgray-500">Creation Date:</p>
                <p className="ml-3">{dayjs(task.createdAt).format("D MMMM, YYYY")}</p>
            </div>
        </div>
    );
};

export default TaskCard;
