import React, { useEffect, useState } from "react";
import { CircularProgress, Pagination } from "@mui/material";
import AllTabs from "@/components/ai_engagement/AllTabs";
import MainLayout from "@/components/MainLayout";
import { useNavigate } from "react-router-dom";
import useAIEngagement from "@/hooks/useAIEngagement";
import CampaignRow from "@/components/ai_engagement/campaignsTab/CampaignRow";
import NoMailBoxButton from "@/components/ai_engagement/NoMailBoxButton";
import MainTitleBar from "@/components/MainTitleBar";

export default function Campaigns() {
    const navigate = useNavigate();
    const { getCampaignsAnalytics, getEmailsData, getUnipileAuthData } = useAIEngagement();
    const [campaignsData, setCampaignsData] = useState([]);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getCampaignsAnalytics();
            if (response.status) {
                setCampaignsData(response.data);
            }
            setLoading(false);
        };

        const fetchAccountsList = async () => {
            setLoading(true);
            try {
                const emailsResponse = await getEmailsData();
                const emailsData = emailsResponse?.data;
                const activeEmails = emailsData?.filter((email) => email.isActive);

                const linkedinResponse = await getUnipileAuthData();
                const linkedinAccountsData = linkedinResponse?.data?.accounts;
                const activelinkedinAccounts = linkedinAccountsData?.filter((account) => account.isActive);

                if (activeEmails?.length <= 0 && activelinkedinAccounts?.length <= 0) {
                    setIsNoMailBox(true);
                }
            } catch (error) {
                console.log("FetchAccountsList error: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAccountsList();
        fetchData();
    }, []);

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const paginatedData = Array.isArray(campaignsData) ? campaignsData.slice((page - 1) * rowsPerPage, page * rowsPerPage) : [];
    const startItem = (page - 1) * rowsPerPage + 1;
    const endItem = Math.min(page * rowsPerPage, campaignsData?.length);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white pb-10 font-Inter">
                <AllTabs />
                {loading ? (
                    <div className="flex justify-center items-center h-[500px]">
                        <CircularProgress />
                    </div>
                ) : isNoMailBox ? (
                    <NoMailBoxButton />
                ) : (
                    <div className="relative overflow-x-auto custom-scrollbar-section p-[24px] font-Inter">
                        <table className="w-full text-left rtl:text-right min-w-[880px]">
                            <thead className="bg-dgray-100 py-3">
                                <tr>
                                    <th scope="col" className="px-4 py-3 rounded-tl-lg rounded-bl-lg main-text-black-600">
                                        Sending Account
                                    </th>
                                    <th className="px-4 py-2 main-text-black-600">Report</th>
                                    <th colSpan={6} className="rounded-tr-lg rounded-br-lg">
                                        <div className="flex">
                                            <div
                                                onClick={() => navigate("/ai-engagement/create-campaign")}
                                                className="ml-auto mr-5 main-text-black-600 !text-[12px] cursor-pointer gap-1 flex items-center w-auto rounded-md border my-1 border-black hover:bg-dgray-50 transition-all py-0.5 px-3"
                                            >
                                                <span>+</span>Create New Campaign
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData?.length === 0 ? (
                                    <tr>
                                        <td colSpan={8} className="text-center py-5 h-[500px] font-Inter">
                                            No campaigns found
                                        </td>
                                    </tr>
                                ) : (
                                    paginatedData.map((row, index) => (
                                        <CampaignRow
                                            key={index}
                                            id={row.campaignId}
                                            title={row.campaignName}
                                            status={row.campaignStatus}
                                            createdAt={row.createdAt}
                                            analytics={row.analytics}
                                            setCampaignsData={setCampaignsData}
                                            ctaLink={row.ctaLink}
                                            campaignsData={campaignsData}
                                        />
                                    ))
                                )}
                            </tbody>
                        </table>
                        {campaignsData?.length > 0 && !loading && (
                            <div className="flex items-center gap-4 mt-7 mb-16 font-Inter">
                                <p className="whitespace-nowrap font-Inter main-text-black-500 !text-[12px]">
                                    Showing {startItem}-{endItem} of {campaignsData?.length} items
                                </p>
                                <Pagination
                                    count={Math.ceil(campaignsData?.length / rowsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    sx={{
                                        ml: "auto",
                                        "& .MuiPaginationItem-root": {
                                            fontFamily: "Inter, sans-serif",
                                            color: "#050505",
                                            border: "1px solid #e7e7e7",
                                            borderRadius: "4px",
                                        },
                                        "& .MuiPaginationItem-root.Mui-selected": {
                                            backgroundColor: "#2D7A89",
                                            color: "#fff",
                                            borderRadius: "4px",
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </MainLayout>
    );
}
