import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as PersonIcon } from "@/assets/image/ai_engagement/previewContacts/person.svg";
import { ReactComponent as LocationIcon } from "@/assets/image/ai_engagement/previewContacts/location.svg";
import { ReactComponent as LevelIcon } from "@/assets/image/ai_engagement/previewContacts/level.svg";
import { ReactComponent as CompanyIcon } from "@/assets/image/ai_engagement/previewContacts/company.svg";
import { ReactComponent as WebsiteIcon } from "@/assets/image/ai_engagement/previewContacts/website.svg";
import { ReactComponent as HQLocationIcon } from "@/assets/image/ai_engagement/previewContacts/hq-location.svg";
import { ReactComponent as IndustryIcon } from "@/assets/image/ai_engagement/previewContacts/industry.svg";

export default function PreviewContacts({ open, setOpen, previewData }) {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl" scroll="body">
            <DialogTitle sx={{ m: 0, p: 2 }} className="!text-4xl !font-semibold text-center" id="customized-dialog-title">
                Preview Contacts
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <div className="relative overflow-x-auto mt-5 custom-scrollbar-section">
                    <table className="w-full text-left rtl:text-righ">
                        <thead className="text-black">
                            <tr>
                                <th scope="col" className="px-6 py-3 flex items-center">
                                    <div className="flex items-center">
                                        <PersonIcon className="w-3 h-3 mr-2" />
                                        <p>Name</p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                    <div className="flex items-center">
                                        <LocationIcon className="w-3 h-3 mr-2" />
                                        <p>Department</p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                    <div className="flex items-center">
                                        <LevelIcon className="w-4 h-4 mr-2" />
                                        <p>Level</p>
                                    </div>
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                  Country
                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center">
                                        <CompanyIcon className="w-3 h-3 mr-2" />
                                        <p>Company</p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                    <div className="flex items-center">
                                        <WebsiteIcon className="w-4 h-4 mr-2" />
                                        <p>Website</p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap ">
                                    <div className="flex items-center">
                                        <HQLocationIcon className="w-3 h-3 mr-2" />
                                        <p>HQ Location</p>
                                    </div>
                                </th>
                                {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Company Head Count
                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center">
                                        <IndustryIcon className="w-3 h-3 mr-2" />
                                        <p>Industry</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {previewData?.length > 0 ? (
                                previewData.map((row, index) => (
                                    <tr key={index} className="bg-white border-b">
                                        <td className="px-6 py-4 whitespace-nowrap">{row.full_name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{row.job_title_role}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{row.job_title_levels[0]}</td>
                                        {/* <td className="px-6 py-4 whitespace-nowrap">{row.location_name}</td> */}
                                        <td className="px-6 py-4 whitespace-nowrap">{row.job_company_name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{row.job_company_website}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{row.location_name}</td>
                                        {/* <td className="px-6 py-4 whitespace-nowrap text-center">{row.headCount}</td> */}
                                        <td className="px-6 py-4 whitespace-nowrap">{row.industry}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center py-4 h-[400px]">
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="flex justify-center">
                    <button
                        className="font-Inter flex py-2 my-7 w-[350px] items-center justify-center rounded-md bg-black text text-white"
                        onClick={() => setOpen(false)}
                    >
                        Close Preview
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
