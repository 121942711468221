import React from "react";

export default function EmailDeliverReport({ payload }) {
    const tableData = payload || [];
    return (
        <div className="mt-[40px] campaign-card">
            <h1 className="main-text-black-600 !text-[16px]">Email Deliverability Report</h1>

            <div className="relative overflow-x-auto mt-5">
                <table className="w-full text-left rtl:text-right text-dgray-500">
                    <thead className="text-dgray-700 bg-dgray-100">
                        <tr>
                            <th scope="col" className="main-text-black-600 px-6 py-3 rounded-tl-lg rounded-bl-lg">
                                Leads Contacted
                            </th>
                            <th scope="col" className="main-text-black-600 px-6 py-3">
                                Sent
                            </th>
                            <th scope="col" className="main-text-black-600 px-6 py-3">
                                Opened
                            </th>
                            <th scope="col" className="main-text-black-600 px-6 py-3">
                                Replied
                            </th>
                            <th scope="col" className="main-text-black-600 px-6 py-3">
                                Clicked
                            </th>
                            <th scope="col" className="main-text-black-600 px-6 py-3">
                                Bounced
                            </th>
                        </tr>
                    </thead>
                    <tbody className="overflow-y-auto custom-scrollbar-section pr-5">
                        {tableData.map((row, index) => (
                            <tr key={index} className="bg-white border-b">
                                <td className="px-6 py-4">{row.account}</td>
                                <td className="px-6 py-4">{row.sent}</td>
                                <td className="px-6 py-4">{row.opened}</td>
                                <td className="px-6 py-4">{row.replied}</td>
                                <td className="px-6 py-4">{row.clicked}</td>
                                <td className="px-6 py-4">{row.bounced}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
