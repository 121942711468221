// RecordsScene.jsx
import { useState } from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { Tabs, Tab, Box } from "@mui/material";

import TasksScene from "./TasksScene";
import NotesScene from "./NotesScene";
import FuseAgentModal from "@/components/fuse_agent/FuseAgentModal";

export default function ActionsScene() {
    const [activeTab, setActiveTab] = useState(0);

    const handleChange = (_event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <MainLayout containerClassName="overflow-auto" overflow="overflow-auto" margin="">
            <MainTitleBar>
                <p>Actions</p>
            </MainTitleBar>

            {/* Tabs */}
            <Box
                sx={{
                    borderBottom: "1px solid #E7E7E7",
                    width: "100%",
                    fontFamily: "Inter",
                    pl: "24px",
                }}
            >
                <Tabs
                    value={activeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#050505",
                            height: "3px",
                        },
                        "& .MuiTab-root": {
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            margin: "0 8px 0 0",
                            padding: "0",
                            minWidth: "auto",
                            color: "#6D6D6D",
                            "&.Mui-selected": {
                                color: "#050505",
                            },
                        },
                    }}
                >
                    <Tab sx={{ marginRight: "24px" }} label="Tasks" />
                    <Tab label="Notes" />
                </Tabs>
            </Box>

            {/* Here: make a container that can scroll if needed */}
            <Box>
                <Box
                    sx={{
                        m: 3,
                        maxWidth: "100%", // do not exceed parent width
                        overflowX: "auto", // horizontal scroll
                        overflowY: "auto", // vertical scroll if you also want vertical
                    }}
                >
                    {activeTab === 0 && <TasksScene />}
                    {activeTab === 1 && <NotesScene />}
                </Box>
            </Box>
            <FuseAgentModal />
        </MainLayout>
    );
}
