import AllTabs from "@/components/ai_engagement/AllTabs";
import React, { useEffect, useRef, useState } from "react";
import MainLayout from "@/components/MainLayout";
import Overview from "@/components/ai_engagement/globalAnalyticsTab/Overview";
import EmailSentChart from "@/components/ai_engagement/globalAnalyticsTab/EmailSentChart";
import EmailDeliverReport from "@/components/ai_engagement/globalAnalyticsTab/EmailDeliverReport";
import useAIEngagement from "@/hooks/useAIEngagement";
import MainTitleBar from "@/components/MainTitleBar";
import { CircularProgress } from "@mui/material";

export default function GlobalAnalytics() {
    const [isLoading, setIsLoading] = useState(false);
    const { getGlobalAnalytics } = useAIEngagement();
    const getGlobalAnalyticsRef = useRef();
    getGlobalAnalyticsRef.current = getGlobalAnalytics;

    const [apiData, setApiData] = useState({});

    // API CALL, fetching data
    useEffect(() => {
        const fetchGlobalAnalyticsData = async () => {
            try {
                setIsLoading(true);
                const res = await getGlobalAnalyticsRef.current();
                setApiData(res?.data || {});
            } catch (error) {
                console.log("Error in fetchGlobalAnalyticsData", error);
                setApiData({});
            } finally {
                setIsLoading(false);
            }
        };
        fetchGlobalAnalyticsData();
    }, []);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white">
                <AllTabs />
                {isLoading ? (
                    <div className="flex justify-center items-center h-[500px]">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="p-[24px]">
                        <Overview payload={apiData} />
                        <EmailSentChart />
                        <EmailDeliverReport payload={apiData.emailDeliverabilityReport} />
                    </div>
                )}
            </div>
        </MainLayout>
    );
}
